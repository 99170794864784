import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import Axios from "axios";
// import {loadStripe} from '@stripe/stripe-js';
import {Backdrop, Tooltip} from "@mui/material";
import jwt_decode from "jwt-decode";
import {Check, Close, ContentCopy, ExpandMore} from "@mui/icons-material";
import SharedFooterGNFT from "./components/SharedFooterGNFT";

// const stripe = loadStripe(stripePublicKey);

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const ToastGNFT = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFontTitleToast',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    },
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFont ChampFontTitle',
        content: 'ChampFont ChampFontContent',
        confirmButton: 'ChampFont ChampFontButton',
        cancelButton: 'ChampFont ChampFontButton',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    }
    // confirmButtonTextColor: "#3b3b3b",
})

const columns =[
    { id: 'name', label: 'Name', minWidth: 10 },
    { id: 'image', label: 'Image', minWidth: 10, maxWidth: 65 },
    { id: 'certi', label: 'Certificate', minWidth: 100, maxWidth: 65 },
    { id: 'bid', label: 'Donation', minWidth: 10 },
    { id: 'status', label: 'Status', minWidth: 10 },
]

let jwtDecoded;

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class MyAccountGNFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            reservations:[],
            collapsePersonal:true,
            collapsePayment:true,
            disableEdit:true,
            allValueModified:[],
            missingDataPersonal:false,
            missingDataPayment:false,
            jwtDecoded:{}
        };
    }
    _emitConnectedGNFT;
    componentWillUnmount(){
        this._emitConnectedGNFT.remove()
        window.removeEventListener('resize', this.resizePage)
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentDidMount() {
        let td = new URLSearchParams(window.location.search).get("td");
        if(td !== null) {
            this.setState({
                td
            })
        }
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedGNFT = global.emitter.addListener("emitConnectedGNFT",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("GNFT_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedGNFT", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("GNFT_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    this.setState({
                        jwtDecoded,
                        connected:true
                    })
                    global.emitter.emit("onRouteChangedGNFT", {
                        jwt: this.state.jwt,
                    })
                    this.getInfosGNFT()
                    this.getMyReservations()
                }else{
                    global.emitter.emit("askConnectionGNFT",{disableOutsideClick:true})
                }
            })
        })
    }

    getInfosGNFT(){
        /*Appel Axios getInfosGNFT*/
        Axios.post(url+"getInfosGNFT",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "done"){
                let nom = response.data.nom || "", prenom = response.data.prenom || "",cni = response.data.cni || "",jdd = response.data.jdd || "",
                    ddn = response.data.ddn || "",adresse = response.data.adresse || "",postal = response.data.postal || "",ville = response.data.ville || "",
                    email = response.data.email || "",tel = response.data.tel || "",iban = response.data.iban || "",bic = response.data.bic || ""
                let missingDataPersonal = nom === "" || prenom === "" || ddn === "" || adresse === "" || postal === "" || ville === "" || email === "" || tel === ""
                let missingDataPayment = iban === "" || bic === ""
                iban = iban.replace(
                    /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})/,
                    '$1 $2 $3 $4 $5 $6 $7'
                );
                this.setState({
                    champsNom_old:nom,
                    champsNom:nom,
                    champsPrenom_old:prenom,
                    champsPrenom:prenom,
                    cniBool_old:cni,
                    cniBool:cni,
                    jddBool_old:jdd,
                    jddBool:jdd,
                    champsDDN_old:ddn,
                    champsDDN:ddn,
                    champsAdresse_old:adresse,
                    champsAdresse:adresse,
                    champsPostal_old:postal,
                    champsPostal:postal,
                    champsCity_old:ville,
                    champsCity:ville,
                    champsEmail_old:email,
                    champsEmail:email,
                    champsTel_old:tel,
                    champsTel:tel,
                    champsIBAN_old:iban,
                    champsIBAN:iban,
                    champsBIC_old:bic,
                    champsBIC:bic,
                    missingDataPersonal,
                    missingDataPayment
                })
            }else{
                SwalDefault.fire(
                    'Error',
                    response.data.resultat,
                )
            }
        })
    }

    getMyReservations(){
        /*Appel Axios getMyReservations & stock response.data.reservations dans un state*/
        Axios.post(url+"getMyReservations",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "done"){
                this.setState({
                    reservations:response.data.reservations
                })
            }else{
                SwalDefault.fire(
                    'Error',
                    response.data.resultat,
                )
            }
        })
    }

    handleChange(event){
        let targetName = event.currentTarget.name
        this.setState({
            [targetName] : event.currentTarget.value
        },()=>{
            this.checkIfDisableEdit(targetName)
        })
    }

    handleChangeOnlyString(event){
        let targetName = event.currentTarget.name
        /*Allow alphabet, space and - */
        const onlyString = event.target.value.replace(/[^a-zA-ZÀ-ÿ -]/g, '');
        this.setState({
            [targetName] : onlyString
        },()=>{
            this.checkIfDisableEdit(targetName)
        })
    }

    handleChangeOnlyNum(event){
        let targetName = event.currentTarget.name
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        this.setState({
            [targetName] : onlyNums
        },()=>{
            this.checkIfDisableEdit(targetName)
        })
    }

    handleChangePhone(event){
        let targetName = event.currentTarget.name
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if(onlyNums.length < 10){
            this.setState({
                [targetName] : onlyNums
            },()=>{
                this.checkIfDisableEdit(targetName)
            })
        } else if (onlyNums.length === 10){
            const number = onlyNums.replace(
                /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                '$1 $2 $3 $4 $5'
            );
            this.setState({
                [targetName] : number
            },()=>{
                this.checkIfDisableEdit(targetName)
            })
        }
    }

    handleChangeIBAN(event){
        let targetName = event.currentTarget.name
        const dataIban = event.target.value.replaceAll(" ","")
        if(dataIban.length < 27){
            this.setState({
                [targetName] : dataIban
            },()=>{
                this.checkIfDisableEdit(targetName)
            })
        } else if (dataIban.length === 27){
            const number = dataIban.replace(
                /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})/,
                '$1 $2 $3 $4 $5 $6 $7'
            );
            this.setState({
                [targetName] : number
            },()=>{
                this.checkIfDisableEdit(targetName)
            })
        }
    }

    handleChangeFile(event){
        let targetName = event.currentTarget.name === "champsCNI" ? "cniBool" : "jddBool"
        if (event.currentTarget.name === "champsCNI"){
            /*Check if file is an image (png / jpg / jpeg) else return*/
            if(event.currentTarget.files[0].type !== "image/png" && event.currentTarget.files[0].type !== "image/jpeg" && event.currentTarget.files[0].type !== "image/jpg"){
                SwalDefault.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Your ID Card must be an image (png / jpg / jpeg)',
                })
                /* clear input */
                event.currentTarget.value = ""
                return
            }
        }else{
            /*Check if file is a pdf else return*/
            if(event.currentTarget.files[0].type !== "application/pdf"){
                SwalDefault.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Your proof of residence must be a pdf',
                })
                /* clear input */
                event.currentTarget.value = ""
                return
            }
        }
        this.setState({
            [targetName] : !!event.currentTarget.files[0]
        },()=>{
            this.checkIfDisableEdit(targetName)
        })
    }

    checkIfDisableEdit(valueModified){
        if(valueModified === "all"){
            this.setState({
                disableEdit:true,
                allValueModified:[]
            })
        }else{
            /*Check if oldValues are different from new values*/
            let disableEdit = true
            let allValueModifiedTemp = this.state.allValueModified
            let verif = this.state[valueModified+"_old"] !== this.state[valueModified]
            if(verif){
                allValueModifiedTemp.push(valueModified)
            }else{
                let index = allValueModifiedTemp.indexOf(valueModified)
                if(index > -1){
                    allValueModifiedTemp.splice(index,1)
                }
            }
            if(allValueModifiedTemp.length > 0){
                disableEdit = false
            }
            this.setState({
                disableEdit: disableEdit,
                allValueModified:allValueModifiedTemp
            })
        }
    }

    async editAccount(e) {
        e.preventDefault()
        // let cniToRead = document.getElementById("champsCNI").files ? document.getElementById("champsCNI").files[0]:undefined
        // let jddToRead = document.getElementById("champsJDD").files ? document.getElementById("champsJDD").files[0]:undefined
        let self = this
        // editAccountNext(cniToRead,jddToRead)
        editAccountNext()
        /*Useless for now (read buffer)*/
        // if(cniToRead !== undefined || jddToRead !== undefined){
        //     let cniBuffer, jddBuffer
        //     if(cniToRead !== undefined){
        //         cniBuffer = await readFileAndGetBuffer(cniToRead)
        //     }
        //     if (jddToRead !== undefined){
        //         jddBuffer = await readFileAndGetBuffer(jddToRead)
        //     }
        //     async function readFileAndGetBuffer(fileToRead) {
        //         return new Promise((resolve, reject) => {
        //             const reader = new FileReader()
        //             reader.onload = () => {
        //                 const binaryStr = reader.result
        //                 let bufferOfFile = Buffer.from(binaryStr, "base64")
        //                 resolve(bufferOfFile)
        //             }
        //             reader.onerror = reject
        //             reader.readAsArrayBuffer(fileToRead)
        //         })
        //     }
        //     editAccountNext(cniBuffer,jddBuffer)
        // }else{
        //     editAccountNext()
        // }


        function editAccountNext (){
        // function editAccountNext (cniBuffer,jddBuffer){
            const iban = self.state.champsIBAN ? self.state.champsIBAN.replaceAll(" ",""):self.state.champsIBAN
            const dateSplit = self.state.champsDDN.split("-")
            if(!!iban && iban.length !== 27){
                SwalDefault.fire({
                    title: 'Error!',
                    text: "Your IBAN is not valid.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }else if(!!self.state.champsTel && self.state.champsTel.length !== 14){
                SwalDefault.fire({
                    title: 'Error!',
                    text: "Your phone number is not valid. (10 digits required)",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }else if(parseInt(dateSplit[0]) < 1900 || parseInt(dateSplit[0]) > new Date().getFullYear()-18){
                if(parseInt(dateSplit[0]) < 1900){
                    SwalDefault.fire({
                        title: 'Error!',
                        text: "Your birth date is not valid. (Year below 1900)",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }else if(parseInt(dateSplit[0]) > new Date().getFullYear()-18){
                    SwalDefault.fire({
                        title: 'Error!',
                        text: "Your birth date is not valid. (You must be 18)",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }else{
                self.setState({displayLoading:true})
                // new Promise(async resolve => {
                //     if (!!cniBuffer){
                //         const formData = new FormData();
                //
                //         formData.append('file', cniBuffer);
                //         formData.append('purpose', 'identity_document');
                //
                //         resolve(Axios.post('https://files.stripe.com/v1/files',formData, {headers: {Authorization: `Bearer ${stripePublicKey}`}}))
                //     }else{
                //         resolve()
                //     }
                // }).then((cniBufferResponse) => {
                //     new Promise(async resolve => {
                //         if (!!jddBuffer){
                //             const formData = new FormData();
                //             formData.append('file', jddBuffer);
                //             formData.append('purpose', 'account_requirement');
                //
                //             resolve(Axios.post('https://files.stripe.com/v1/files',formData, {headers: {'Authorization': `Bearer ${stripePublicKey}`}}))
                //         }else{
                //             resolve()
                //         }
                //     }).then(async (jddBufferResponse) => {

                    // })
                // })
                let obj = {
                    first_name:self.state.champsPrenom ? self.state.champsPrenom.trim(): undefined,
                    last_name:self.state.champsNom ? self.state.champsNom.trim() : undefined,
                    phone:self.state.champsTel ? (("+33"+self.state.champsTel.slice(1,self.state.champsTel.length).replaceAll(" ",""))):undefined,
                    email:self.state.champsEmail ? self.state.champsEmail.trim() : undefined,
                    address: {
                        city:self.state.champsCity ? self.state.champsCity.trim() : undefined,
                        country:"FR",
                        line1:self.state.champsAdresse ? self.state.champsAdresse.trim() : undefined,
                        postal_code:self.state.champsPostal ? self.state.champsPostal.trim() : undefined,
                    },
                }
                if(!!self.state.champsDDN){
                    obj.dob = {
                        day:self.state.champsDDN && self.state.champsDDN.split("-")[2],
                        month:self.state.champsDDN && self.state.champsDDN.split("-")[1],
                        year:self.state.champsDDN && self.state.champsDDN.split("-")[0]
                    }
                }
                // if (!!cniBufferResponse || !!jddBufferResponse){
                //     obj.verification = {}
                //     if (!!cniBufferResponse){
                //         obj.verification.document = {}
                //         obj.verification.document.front = cniBufferResponse.data.id
                //     }else{
                //         obj.verification.additional_document = {}
                //         obj.verification.additional_document.front = jddBufferResponse.data.id
                //     }
                // }

                // try{
                //
                // }catch (e) {
                //     SwalDefault.fire({
                //         title: 'Warning !',
                //         text: "Your informations cannot be confirmed.",
                //         icon: 'error',
                //         confirmButtonText: 'Ok'
                //     })
                //     self.setState({displayLoading:false})
                // }

                // const accountToken =  await (await stripe).createToken('account',  {
                //     account:{
                //         business_type: 'individual',
                //         tos_shown_and_accepted: true,
                //         individual:obj,
                //     },
                // })

                Axios.post(url+"editInfosGNFT",{
                    email:self.state.champsEmail ? self.state.champsEmail.trim() : undefined,
                    iban,
                    bic:self.state.champsBIC ? self.state.champsBIC : undefined,
                    tel:self.state.champsTel ? self.state.champsTel:undefined,
                    adresse:self.state.champsAdresse ? self.state.champsAdresse.trim() : undefined,
                    nom:self.state.champsNom ? self.state.champsNom.trim() : undefined,
                    prenom:self.state.champsPrenom ? self.state.champsPrenom.trim() : undefined,
                    postal:self.state.champsPostal ? self.state.champsPostal.trim() : undefined,
                    ddn:self.state.champsDDN ? self.state.champsDDN : undefined,
                    ville:self.state.champsCity ? self.state.champsCity.trim() : undefined,
                    // cni:!!cniBufferResponse,
                    // jdd:!!jddBufferResponse,
                    // tokenId:accountToken.token.id
                },{headers:{Authorization:"Bearer " + self.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                        self.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(self.state.jwt)
                        })
                    }
                    if(response.data.resultat === "done" || response.data.resultat === "Votre iban n'est pas valide."){
                        if(response.data.resultat !== "Votre iban n'est pas valide."){
                            Swal.fire({
                                title: 'Erreur!',
                                text: response.data.resultat,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }
                        let missingDataPersonal = self.state.champsNom === "" || self.state.champsPrenom === "" || self.state.champsDDN === "" || self.state.champsAdresse === "" || self.state.champsPostal === "" || self.state.champsCity === "" || self.state.champsEmail === "" || self.state.champsTel === ""
                        // let missingDataPayment = !self.state.cniBool || !self.state.jddBool || self.state.champsIBAN === "" || self.state.champsBIC === ""
                        let missingDataPayment = self.state.champsIBAN === "" || self.state.champsBIC === ""
                        self.setState({
                            champsNom_old:self.state.champsNom,
                            champsPrenom_old:self.state.champsPrenom,
                            champsEmail_old:self.state.champsEmail,
                            champsAdresse_old:self.state.champsAdresse,
                            champsPostal_old:self.state.champsPostal,
                            champsCity_old:self.state.champsCity,
                            champsTel_old:self.state.champsTel,
                            champsDDN_old:self.state.champsDDN,
                            champsBIC_old:self.state.champsBIC,
                            champsIBAN_old:self.state.champsIBAN,
                            // cniBool_old:self.state.cniBool,
                            // jddBool_old:self.state.jddBool,
                            missingDataPersonal,
                            missingDataPayment
                        })
                        ToastGNFT.fire({
                            title:"Informations modified",
                            icon:"success"
                        })
                        self.checkIfDisableEdit("all")
                    }else{
                        SwalDefault.fire({
                            title: 'Error!',
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                    self.setState({displayLoading:false})
                })
            }
        }
    }

    generateCertificate(reservation){
        /*call Axios : generateCertificate avec idToken de la reservation et le nom de la bouteille*/
        this.setState({displayLoading:true})
        /*set timer of 10s and catch if timed out*/
        let timerCertif = setTimeout(()=>{
            this.setState({displayLoading:false})
            ToastGNFT.fire({
                title:"An error occured while generating the certificate",
                icon:"error"
            })
        },10000)

        Axios.post(url+"generateCertificateGNFT",{
            idToken:reservation.idTokenBottle
        },{headers:{Authorization:"Bearer " + this.state.jwt},responseType:"arraybuffer"}).then(response=>{
            let file = new Blob([response.data], {type: 'application/pdf'});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = "certificate_"+reservation.bottle.name+".pdf";
            link.click();
            this.setState({displayLoading:false})
            clearTimeout(timerCertif)
            ToastGNFT.fire({
                title:"Certificate generated",
                icon:"success"
            })
        }).catch(e=>{
            this.setState({displayLoading:false})
            clearTimeout(timerCertif)
            ToastGNFT.fire({
                title:"An error occured while generating the certificate",
                icon:"error"
            })
        })
    }

    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", flexDirection:"column" ,height:"calc(100vh - 40px)",width:"100%"}}>
                    <Backdrop
                        sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: 999999999 }}
                        open={this.state.displayLoading}
                    >
                        <div className={"flexCenter"} style={{flexDirection:"column"}}>
                            <div className={"loaderTextGNFT ChampFont"}>Loading</div>
                            <h2 className={"ChampFont"} style={{color:"#FFBB41FF"}}>Please wait a few seconds</h2>
                        </div>
                    </Backdrop>
                    <div className={"flexCenter"} style={{height:"calc(100vh - 70px)", marginTop:70, width:"100%", overflow:this.state.showBrowserView ? "hidden":"auto", justifyContent:"space-between"}}>
                        <div className={"flexCenter"} style={{flex:"1 1 10%", height:"100%", zIndex:99,background: "linear-gradient(125deg, rgba(42,44,36,1) 0%, rgba(15,18,0,1) 100%)", flexDirection:"column", justifyContent:"flex-start"}}>
                            <div style={{display:"flex", justifyContent:"space-between", alignItems:"flex-start", width:"100%", maxHeight:"calc(100vh - 70px)", flexDirection:!this.state.showBrowserView && "column", overflow:"auto"}}>
                                <form onSubmit={(e)=>{this.editAccount(e)}} className={"flexCenter"} style={{width: "100%", height:"100%", marginLeft:this.state.showBrowserView && 80, flexDirection:"column", alignItems:"flex-start", justifyContent:"space-between"}}>
                                    <h1 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:60, marginBottom:15}}>
                                        My informations
                                    </h1>
                                    <h2 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", margin:5}}>Username : <span style={{color:"#FFF", margin:5}}>{this.state.jwtDecoded.pseudo}</span></h2>
                                    <div className={"flexCenter"}>
                                        <h2 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", margin:5}}>Hash : <span style={{fontSize:15, fontStyle:"italic",color:"#FFF", margin:5}}>{this.state.jwtDecoded.empreinte && (this.state.jwtDecoded.empreinte.substring(0,4) + " ... " + this.state.jwtDecoded.empreinte.slice(-4))}</span></h2>
                                        <Tooltip title={"Copy my hash"}>
                                            <button type={"button"} onClick={()=>{
                                                ToastGNFT.fire({
                                                    title:"Hash copied to clipboard.",
                                                    icon:"success"
                                                })
                                                navigator.clipboard.writeText(this.state.jwtDecoded.empreinte)
                                            }} className={"buttonGNFT flexCenter"} style={{padding:5, width:50, display:"flex"}}>
                                                <ContentCopy style={{cursor:"pointer", width:25, height:25}}/>
                                            </button>
                                        </Tooltip>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"95%", alignItems:"flex-start", justifyContent:"flex-start", height:"100%", overflow:"visible", flexDirection:"column"}}>
                                        <div onClick={()=>{this.setState({collapsePersonal:!this.state.collapsePersonal, collapsePayment:true})}}  className={"flexCenter"} style={{width:'100%', cursor:"pointer", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"}}>
                                            <h2 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", marginBottom:15, cursor:"pointer"}}>
                                                <ExpandMore style={{color:"#FFBB41FF", width:30, height:30, cursor:"pointer", transition:"all .5s cubic-bezier(.25,.8,.25,1)", transform:this.state.collapsePersonal && "rotate(-90deg)"}} />
                                                Personal informations :
                                            </h2>
                                            <h3 className={"ChampFont flexCenter"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, marginBottom:0, marginTop:0}}>
                                                These informations are used to identify you and may be used to contact you
                                            </h3>
                                            <h3 className={"ChampFont flexCenter"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, marginBottom:15, marginTop:0}}>
                                                {this.state.missingDataPersonal ? (
                                                    <>
                                                        Some informations are missing
                                                        <Close style={{width:25, height:25, marginLeft:5, color:"#FF4136FF"}}/>
                                                    </>
                                                ):(
                                                    <>
                                                        You have filled in all your informations
                                                        <Check style={{width:25, height:25, marginLeft:5, color:"#4BB543FF"}}/>
                                                    </>
                                                )}
                                            </h3>
                                        </div>
                                        <div className={"flexCenter collapsableDivGNFT"} style={{transform:this.state.collapsePersonal ? "scaleY(0)":"scaleY(1)", maxHeight:this.state.collapsePersonal?"0%":"100%", opacity:this.state.collapsePersonal?0:1}}>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    First Name :
                                                    <br style={{display:!!this.state.champsPrenom_old && "none"}}/>
                                                    <span style={{color:"red", fontSize:18,display:!!this.state.champsPrenom_old && "none"}}>CAUTION : Field editable only once. </span>
                                                </h3>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    Last Name :
                                                    <br style={{display:!!this.state.champsNom_old && "none"}}/>
                                                    <span style={{color:"red", fontSize:18,display:!!this.state.champsNom_old && "none"}}>CAUTION : Field editable only once. </span>

                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input disabled={!!this.state.champsPrenom_old} autoComplete={"off"} name={"champsPrenom"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsPrenom} onChange={(e)=>{this.handleChangeOnlyString(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginRight:10, opacity:!!this.state.champsPrenom_old && 0.6}} type={"text"} placeholder={"First Name"}/>
                                                <input disabled={!!this.state.champsNom_old} autoComplete={"off"} name={"champsNom"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsNom} onChange={(e)=>{this.handleChangeOnlyString(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginLeft:10, opacity:!!this.state.champsNom_old && 0.6}} type={"text"} placeholder={"Last Name"}/>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"100%", textAlign:"left"}}>
                                                    Email :
                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input autoComplete={"off"} name={"champsEmail"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsEmail} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%"}} type={"email"} placeholder={"Email"}/>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    Phone number :
                                                </h3>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    Birth Date :
                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input autoComplete={"off"} name={"champsTel"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsTel} onChange={(e)=>{this.handleChangePhone(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginRight:10}} type={"tel"} placeholder={"Phone number"}/>
                                                <input autoComplete={"off"} name={"champsDDN"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsDDN} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginLeft:10}} type={"date"} placeholder={"Birth Date"}/>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"100%", textAlign:"left"}}>
                                                    Address :
                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input autoComplete={"off"} name={"champsAdresse"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsAdresse} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%"}} type={"text"} placeholder={"Address"}/>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    Postal Code :
                                                </h3>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    City :
                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input autoComplete={"off"} name={"champsPostal"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsPostal} onChange={(e)=>{this.handleChangeOnlyNum(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginRight:10}} type={"text"} placeholder={"Postal Code"}/>
                                                <input autoComplete={"off"} name={"champsCity"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsCity} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginLeft:10}} type={"text"} placeholder={"City"}/>
                                            </div>
                                        </div>
                                        <div onClick={()=>{this.setState({collapsePayment:!this.state.collapsePayment, collapsePersonal:true})}} className={"flexCenter"} style={{width:'100%', cursor:"pointer", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start"}}>
                                            <h2 className={"ChampFont flexCenter"}style={{color:"#ffbb41", fontWeight:"bold", marginBottom:15, cursor:"pointer"}}>
                                                <ExpandMore style={{color:"#FFBB41FF", width:30, height:30, cursor:"pointer", transition:"all .5s cubic-bezier(.25,.8,.25,1)", transform:this.state.collapsePayment && "rotate(-90deg)"}}/>
                                                Payment related informations :
                                            </h2>
                                            <h3 className={"ChampFont flexCenter"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, marginBottom:0, marginTop:0}}>
                                                These informations are necessary to buy and sell bottles on our platform.
                                            </h3>
                                            <h3 className={"ChampFont flexCenter"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, marginBottom:15, marginTop:0}}>
                                                {this.state.missingDataPayment ? (
                                                    <>
                                                        Some informations are missing
                                                        <Close style={{width:25, height:25, marginLeft:5, color:"#FF4136FF"}}/>
                                                    </>
                                                ):(
                                                    <>
                                                        You have filled in all your informations
                                                        <Check style={{width:25, height:25, marginLeft:5, color:"#4BB543FF"}}/>
                                                    </>
                                                )}
                                            </h3>
                                        </div>
                                        <div className={"flexCenter collapsableDivGNFT"} style={{transform:this.state.collapsePayment ? "scaleY(0)":"scaleY(1)", maxHeight:this.state.collapsePayment?"0%":"100%", opacity:this.state.collapsePayment?0:1}}>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:0, width:"70%", textAlign:"left"}}>
                                                    IBAN :
                                                </h3>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"25%", textAlign:"left"}}>
                                                    BIC :
                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input autoComplete={"off"} name={"champsIBAN"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsIBAN} onChange={(e)=>{this.handleChangeIBAN(e)}} style={{border:"1px solid #FFBB41FF", width:"75%", marginRight:10}} type={"text"} placeholder={"IBAN"}/>
                                                <input autoComplete={"off"} name={"champsBIC"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsBIC} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"25%", marginLeft:10}} type={"text"} placeholder={"BIC"}/>
                                            </div>
                                            {/*<h3 className={"ChampFont"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, marginBottom:0, marginTop:5}}>*/}
                                            {/*    Id Card (image .png, .jpg, .jpeg) :*/}
                                            {/*</h3>*/}
                                            {/*<div className={"flexCenter"} style={{width:"100%"}}>*/}
                                            {/*    {this.state.cniBool_old ? (*/}
                                            {/*        <h3 id={"champsCNI"} className={"ChampFont textLikeInputGNFT flexCenter"}>*/}
                                            {/*            You already uploaded your ID card*/}
                                            {/*            <CheckCircle style={{width:35, height:35, marginLeft:5, color:"#4BB543FF"}}/>*/}
                                            {/*        </h3>*/}
                                            {/*    ):(*/}
                                            {/*        <input multiple={false} onChange={(e)=>{this.handleChangeFile(e)}} id={"champsCNI"} autoComplete={"off"} name={"champsCNI"} className={"champsTexteGNFTInscription ChampFont"} style={{border:"1px solid #FFBB41FF", width:"100%"}} type={"file"} placeholder={"ID card"}/>*/}
                                            {/*    )}*/}
                                            {/*</div>*/}
                                            {/*<h3 className={"ChampFont"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, marginBottom:0, marginTop:5}}>*/}
                                            {/*    Proof of Residence (.pdf):*/}
                                            {/*</h3>*/}
                                            {/*<div className={"flexCenter"} style={{width:"100%"}}>*/}
                                            {/*    {this.state.jddBool_old ? (*/}
                                            {/*        <h3 id={"champsJDD"} className={"ChampFont textLikeInputGNFT flexCenter"}>*/}
                                            {/*            You already uploaded your proof of residence*/}
                                            {/*            <CheckCircle style={{width:35, height:35, marginLeft:5, color:"#4BB543FF"}}/>*/}
                                            {/*        </h3>*/}
                                            {/*    ):(*/}
                                            {/*        <input multiple={false} onChange={(e)=>{this.handleChangeFile(e)}} id={"champsJDD"} autoComplete={"off"} name={"champsJDD"} className={"champsTexteGNFTInscription ChampFont"} style={{border:"1px solid #FFBB41FF", width:"100%"}} type={"file"} placeholder={"Proof of residence"}/>*/}
                                            {/*    )}*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className={"flexCenter"} style={{width:"80%", justifyContent:"flex-start", marginTop:20, marginBottom:20}}>
                                            <button disabled={this.state.disableEdit || this.state.displayLoading} type={"submit"} style={{width:"fit-content", marginLeft:5}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Edit my account</h2></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <SharedFooterGNFT/>
            </>
        )
    }
}

export default withRouter(MyAccountGNFT)