import React from 'react';
import {PayPalButtons} from "@paypal/react-paypal-js";

class PayPalButtonEnglish extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false,
            hidePaypalButton : true,
            buttonClicked:false,
            orderPaypalIDGenerated:undefined,
            orderApproved:false
        };
        this.createOrder = this.createOrder.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    timeoutId = null;

    componentDidMount() {
        this.setState({ready: true});
        this.timeoutId = setTimeout(()=>{ //Le bouton réagit mal si on clique dessus trop vite il est caché pendant 1s
            this.setState({hidePaypalButton: false});
        },1500)
    }

    componentWillUnmount() {
        this.setState({ready: false});
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    createOrder() {
        if (this.props.onCreateOrder && !this.state.orderPaypalIDGenerated) {
            return new Promise((resolve, reject) => {
                this.props.onCreateOrder().then((orderPaypalID) => {
                    this.setState({buttonClicked:true, orderPaypalIDGenerated:orderPaypalID})
                    resolve(orderPaypalID);
                });
            })
        }else{
            this.setState({buttonClicked:true})
            return this.state.orderPaypalIDGenerated
        }
    }

    onApprove(data, actions) {
        if(this.props.onApproveOrder){ //Cette fonction ne fait que afficher le backdrop sur la page (dans SMSTO)
            this.setState({orderApproved:true})
            this.props.onApproveOrder()
        }
        return actions.order.capture().then(function(details) {
            window.location.href = "/GrapeNFT/PaymentStatus?statut=success&token="+details.id
        });
    }

    onCancel(data, actions) {
        this.setState({buttonClicked:false})
    }

    render() {
        return (
            <React.Fragment>
                {this.state.ready ? (
                    <React.Fragment>
                        <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                            {this.state.orderApproved && (
                                <h3 style={{textAlign:"center", color:"#FFF"}} className={"loadingText ChampFont"}>Payment processing</h3>
                            )}
                            <div style={{transform:this.state.hidePaypalButton ? "scale(0)":"scale(1)",height:this.state.hidePaypalButton ? 1:"100%"}} className={"paypalButtonContainer"}>
                                <PayPalButtons locale={"fr_US"} className={"paypalButtonComponent"} createOrder={this.createOrder} onApprove={this.onApprove} onCancel={this.onCancel} on />
                                <button onClick={()=>{window.location.reload()}} style={{width:"100%", maxWidth:750, minWidth:200, maxHeight:55, backgroundColor:"#FF4136FF", padding:"3%", fontWeight:500, fontSize:18, display:this.state.buttonClicked ? "none":"block"}} className={"buttonStripe"}>Cancel</button>
                            </div>
                            <h3 style={{display:this.state.hidePaypalButton ? "block":"none",fontWeight:500, color:"#FFF"}} className={"loadingText ChampFont"}>Loading payment</h3>
                        </div>

                    </React.Fragment>
                ) : (
                    <h3 style={{fontWeight:500, color:"#FFF"}} className={"loadingText ChampFont"}>Loading payment</h3>
                )
                }
            </React.Fragment>
        );
    }
}

export default PayPalButtonEnglish;
