import React, {Component} from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip
} from '@mui/material';
import {withRouter} from '../../../shared/NavigationUtils';
import Axios from "axios";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {sha256} from "js-sha256";
import config from "../../../json/config.json";
import tooltipContenu from "../../../json/contenu.json";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {isBrowser} from "react-device-detect";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import logoEkoco from "../assetsEkoco/Ekoco.png";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
let pseudo,clef,hash,lienInscription = "";

class BasCarboneBlockchain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialog : false,
            pseudo : null,
            clef: null,
            marginLeftContact:20,
            marginRightContact:10,
            displayed:false,
            displayed2:false,
            openLoad:false,
            goToTopDisplay:"none",
            goToTopOpacity:0,
            ChampsEmailPro:"",
            ChampsMessagePro:"",
            flexDirectionCCM:"column",
            marginBottomAvantFooter:20,
            displayQRCode:false,
            // captchaVerified:false
        };
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
        document.removeEventListener('scroll', this.trackScrolling)
    }

    componentDidMount() {
        if (this.props.location.link !== undefined){
            document.getElementById(this.props.location.link).scrollIntoView()
        }
        document.addEventListener('scroll', this.trackScrolling);
        window.addEventListener('resize', this.resizePage)
        if (window.location.search !== '' && window.location.search.substring(1,7) !== "scroll"){ //Se connecter via URL, utile pour APP Ekoco
            let param = window.location.search;
            param = param.split('&');
            pseudo = (param[0].split('='))[1]; hash = (param[1].split('='))[1];
            Axios.post(url+"BCBConnexionClient", {
                empreinte : hash,
            },{headers:{Authorization:"Bearer " + sessionStorage.getItem("EKOCO_jwt")}}).then(response => {
                if (response.data.resultat === 'Utilisateur introuvable.'){
                    Swal.fire({
                        title: 'Erreur!',
                        text: "L'utilisateur est introuvable.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        this.props.navigate('/Ekoco/BasCarboneBlockchain', { replace: true })
                    })

                }else{
                    if (response.data.jwt) {
                        sessionStorage.setItem("EKOCO_jwt", response.data.jwt);
                        this.setState({
                            jwt: response.data.jwt
                        })
                    }
                    this.props.navigate('/Ekoco/Statistiques')
                }
            }).catch(error => {console.log(error);});
        }else if (window.location.search.substring(1,7) === "scroll"){
            setTimeout(()=>{document.getElementById((window.location.search.split("=")[1]).toString()).scrollIntoView()},600)
        }
        if (isBrowser){
            this.setState({
                marginLeftContact:50,
                marginRightContact:100,
                flexDirectionCCM:"row",
                marginBottomAvantFooter:100
            })
        }
    }
    trackScrolling = () => {
        if (window.pageYOffset>=200){
            this.setState({
                goToTopDisplay:"flex",
            })
        }else{
            this.setState({
                goToTopDisplay:"none",
            })
        }
    };

    resizePage = () => {
        this.setState({windowWidth:document.body.offsetWidth})
        if (document.body.offsetWidth < 770){
            this.setState({
                flexDirectionCCM:"column"
            })
        }else{
            this.setState({
                flexDirectionCCM:"row"
            })
        }
    }

    hideContact(){
        this.setState({
            displayed2:false,
        })
    }
    displayContact(){
        this.setState({
            displayed2:true,
        })
    }
    handleChange = (event) =>{ //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }
    handleClickDialog = () =>{
        lienInscription="Pas de compte ? Inscrivez-vous !"
        this.setState({
            displayed : true
        });
    };
    handleCloseDialog = () =>{
        this.setState({
            displayed : false,
            displayed2: false
        });
    };
    handleValidation(){
        return true;
    };

    handleSubmitMailPro(e){
        e.preventDefault();
        const data= new FormData(e.target);
        let emailSender = data.get('ChampsEmailPro'), message = data.get('ChampsMessagePro');
        Axios.post(url+"contactEkoco", {
            email : emailSender,
            sujet : "Contact d'entreprise",
            message : message,
        }).then(() => {
            this.setState({
                ChampsEmailPro:"",
                ChampsMessagePro:"",
                // captchaVerified:true
            })
            Toast.fire({
                title:"Email envoyé avec succès.",
                icon:"success"
            })
        }).catch(() => {
            Swal.fire({
                title: 'Erreur!',
                text: "Erreur lors de l'envoie de l'email.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        });
    }

    handleSubmitContact=(event) => { //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        event.preventDefault();
        const data= new FormData(event.target);
        let emailSender = data.get('champsEmail'), sujet = data.get('champsSujet'), message = data.get('champsMessage');
        event.preventDefault();
        if(this.handleValidation()){
            this.setState({
                openLoad:true
            })
            Axios.post(url+"contactEkoco", {
                email : emailSender,
                sujet : sujet,
                message : message,
            }).then(() => {
                this.setState({
                    openLoad:false
                })
                Toast.fire({
                    title:"Email envoyé avec succès.",
                    icon:"success"
                })
                this.handleCloseDialog()
            }).catch(() => {
                this.setState({
                    openLoad:false
                })
                Swal.fire({
                    title: 'Erreur!',
                    text: "Erreur lors de l'envoie de l'email.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            });
        }
    }
    handleSubmit(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        let data= new FormData(event.target);
        pseudo = data.get('champsPseudo'); clef = data.get('champsClef');
        event.preventDefault();
        if(()=>{this.handleValidation(clef, pseudo)}){
            let empreinte = sha256(pseudo.concat(clef)) //Hashage avec protocole sha256
            Axios.post(url+"BCBConnexionClient", {
                empreinte
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                if (response.data.resultat === 'Utilisateur introuvable.'){
                    Swal.fire({
                        title: 'Erreur!',
                        text: "L'utilisateur est introuvable.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                    this.setState({
                        displayed:false
                    })
                }else{
                    if (response.data.jwt){
                        sessionStorage.setItem("EKOCO_jwt",response.data.jwt)
                    }
                    Toast.fire({
                        title:"Connexion réussie!",
                        icon:"success"
                    })
                    this.props.navigate('/Ekoco/Statistiques')
                }
            }).catch(error => {console.log(error);});
        }
    }
    // changeCaptchaState(){
    //     this.setState({captchaVerified:true})
    // }

    render(){ //Affichage sur la page
        return (
            <div id={"topOfThePage"} style={{display:"flex",height:'100%', width:'100%', flexDirection:"column",transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.displayed2}
                    onClose={()=>{this.hideContact()}}
                >

                    <DialogTitle>Contactez nos administrateurs <span style={{color:"#6abfa2", fontWeight:"bold"}}>Ekoco</span> !</DialogTitle>
                    <form onSubmit={(e)=>{this.handleSubmitContact(e)}}>
                        {/*{!this.state.captchaVerified && <Captcha verifCaptcha={()=>{this.changeCaptchaState()}}/> }*/}
                        <DialogContent>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsEmail"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} required={true} type={"email"} label="Entrez votre email" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmailContact}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsSujet"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} id="outlined-basic champsPseudo" required={true} label="Entrez votre sujet" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipSujet}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsMessage"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} multiline={true} rows={6} rowsMax={10} id="outlined-basic champsPrenom" required={true} label="Entrez un message" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipMessage}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <Button style={{backgroundColor:"#5ecaab",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5}} variant={"outlined"} type={"submit"} >Envoyer</Button>
                        </DialogContent>
                    </form>
                </Dialog>
                <Dialog
                    open={this.state.displayQRCode}
                    onClose={()=>{this.setState({displayQRCode:false})}}

                >
                    <DialogContent style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                        <CloseIcon style={{position:"absolute", right:15, top:15, cursor:"pointer"}} onClick={()=>{this.setState({displayQRCode:false})}} />
                        <h1>Scannez ou cliquez moi !</h1>
                        <a href="https://play.google.com/store/apps/details?id=com.ekoco" target={"_blank"} style={{textAlign:"center", paddingBottom:50}}>
                            <img style={{width:"100%"}} src={require("../assetsEkoco/QRCODEPlayStore.png")} alt="QR_Code"/>
                        </a>
                    </DialogContent>
                </Dialog>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Le mail va être envoyé dans quelques instants ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
                <Dialog
                    open={this.state.displayed}
                    onClose={()=>{this.handleCloseDialog()}}
                >
                    <DialogTitle style={{color:"#2c2e35"}}>Connectez-vous à <span style={{color:"#6abfa2", fontWeight:"bold"}}>Ekoco</span> !</DialogTitle>
                    <form onSubmit={(e)=>{this.handleSubmit(e)}}>
                        <DialogContent>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField  name={"champsPseudo"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" required={true} label="Pseudo" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField autoComplete="off" name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsClef" required={true} type={this.state.showPassword ? "text":"password"} label="Clef Unique" variant="outlined"
                                           InputProps={{
                                               endAdornment:
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           aria-label="toggle password visibility"
                                                           onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                       >
                                                           {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                       </IconButton>
                                                   </InputAdornment>
                                           }}
                                />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                    <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <p>Pas de compte ? <a style={{color:'blue', textDecoration:"underline blue", cursor:"pointer"}} onClick={()=>{this.setState({displayQRCode:true})}}>Téléchargez l'application Ekoco !</a></p>
                            <Button style={{backgroundColor:"#5ecaab",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5}} type={"submit"}>Se connecter</Button>
                        </DialogContent>
                    </form>
                </Dialog>


                {isBrowser === true ? (
                    <div onClick={()=>{document.getElementById("topOfThePage").scrollIntoView()}} style={{zIndex:5,display:this.state.goToTopDisplay,opacity:0.4, width:"8vh",height:"8vh",backgroundColor:"grey", position:"fixed",marginRight:30,marginTop:840, borderRadius:15, alignSelf:"flex-end",justifyContent:"center",alignItems:"center", cursor:"pointer"}}>
                        <KeyboardArrowUpIcon style={{fontSize:45}}/>
                    </div>
                ):("")}

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end"}}>
                    <Button style={{padding:0, borderRadius:5}} onClick={()=>{this.handleClickDialog()}} > <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}> <img style={{width:30, margin:0}} src={require('../assetsEkoco/BOUTON_CONNEXION_APPLI MOBILE.png')} alt="Bouton connexion"/><p style={{color:'#b0a9a8',textTransform:"none", margin:0}} >Se connecter</p> </div>  </Button>
                    <Button onClick={()=>{this.displayContact()}} style={{marginLeft:this.state.marginLeftContact, marginRight:this.state.marginRightContact, backgroundColor:"#5ecaab", color:"white", padding:"10px 30px", fontWeight:"bold", zIndex:2}}>Contact</Button>
                </div>
                {isBrowser === true ? (
                    <>
                        {this.state.windowWidth<650 ? (""):(<img style={{width:"14vw", margin:"20px 75vw 0px 10vw", position:"absolute"}} src={logoEkoco} alt="Logo Ekoco"/>)}
                    </>
                    ):(
                    <>
                        <img style={{width:"20vw", margin:"20px 75vw 0px 10vw", position:"absolute"}} src={require('../assetsEkoco/Ekoco.png')} alt="Logo Ekoco"/>
                    </>
                )}
                {/*DEBUT CONTENT*/}
                <div id={"centrePageAccueil"} style={{display:"flex", flexDirection:"column"}}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <img style={{width:"100%", margin:0}} src={require('../assetsEkoco/BANDEAU_HEADER.png')} alt="Bandeau header"/>
                        <div style={{position:"absolute", marginTop:"7vw", marginLeft:"90vw"}}>
                            <img style={{width:"6vw"}} src={require('../assetsEkoco/FEDER-com-autorite-coul.png')} alt="logoFEDER"/>
                        </div>
                        <div style={{position:"absolute", marginTop:"2vw", marginLeft:"11vw"}}>
                            <p style={{fontSize:"4vw", fontWeight:"bold", marginBottom:0,color:"#2c2e35"}}>L'application qui fait</p>
                            <p style={{fontSize:"4vw", fontWeight:"bold",color:"#5ecaab",margin:0}}>DU BIEN</p>
                            <p style={{fontSize:"4vw", fontWeight:"bold",color:"#5ecaab",margin:0}}>À LA PLANÈTE</p>
                        </div>
                        <img style={{width:"23vw", margin:0,position:"absolute",marginLeft:"11vw",marginTop:"27vw"}} src={require('../assetsEkoco/VISUEL_APPLI_MOBILE.png')} alt="Apperçu teléphone"/>
                    </div>
                    <div id={"downloadApp"} style={{display:"flex", flexDirection:"column"}}>
                        <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center", color:'#b0a9a8'}}>
                            {isBrowser === true ? (
                                    <>
                                        <p className={"arialMedium"} style={{fontSize:"2vw",margin:0}}>Téléchargez gratuitement</p>
                                        <p className={"arialMedium"} style={{fontSize:"2vw",margin:0}}>l'application mobile</p>
                                        <a href="https://play.google.com/store/apps/details?id=com.ekoco" target={"_blank"} style={{textAlign:"center", paddingBottom:50}}>
                                            <img style={{width:"25vw", margin:0, marginTop:5}} src={require('../assetsEkoco/TELECHARGEMENT_APPLI.png')} alt="Image téléchargement"/>
                                        </a>

                                    </>
                                ):(
                                    <>
                                        <p className={"arialMedium"} style={{fontSize:"3vw",margin:0}}>Téléchargez gratuitement</p>
                                        <p className={"arialMedium"} style={{fontSize:"3vw",margin:0}}>L'application mobile</p>
                                        <a href="https://play.google.com/store/apps/details?id=com.ekoco" target={"_blank"} style={{textAlign:"center", paddingBottom:50}}>
                                            <img style={{width:"45vw", margin:0, marginTop:5}} src={require('../assetsEkoco/TELECHARGEMENT_APPLI.png')} alt="Image téléchargement"/>
                                        </a>

                                    </>
                                )}
                        </div>
                    </div>
                    <div id={"cestQuoi"} style={{backgroundColor:"#5ecaab",overflow:"hidden", display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginTop:20}}>

                        {isBrowser === true ? (
                            <>
                                <div style={{backgroundColor:"#47977e",marginTop:70, width:"42%",display:"flex", justifyContent:"center",alignItems:"center",padding:15,borderRadius:10}}>
                                    <p className={"arialExtraBold"} style={{color:"#FFFFFF",fontSize:"3.5vw",margin:0}}>ÉKO<span style={{color:"#2c2e35",margin:0}}>CO</span> C'EST QUOI ?</p>
                                </div>
                                <p className={"arialBold"} style={{color:"#FFFFFF",fontSize:"2vw",marginBottom:0}}>ÉKOCO<span style={{color:"#2c2e35"}}> est une application qui protège la planète.</span></p>
                                <p className={"arialArrow"} style={{color:"#FFFFFF",fontSize:"1.5vw",marginBottom:0}}>Vous permettant de maîtriser votre production de Co2 en limitant les moyens</p>
                                <p className={"arialArrow"} style={{color:"#FFFFFF",fontSize:"1.5vw",margin:0}}>de transports polluants, elle récompensera de vos efforts en tokens,</p>
                                <p className={"arialArrow"} style={{color:"#FFFFFF",fontSize:"1.5vw",margin:0}}>monnaie que vous pourrez offrir à l’association de votre choix.</p>
                            </>
                        ):(
                            <>
                                <div style={{backgroundColor:"#47977e",marginTop:20, width:"80%",display:"flex", justifyContent:"center",alignItems:"center",padding:15,borderRadius:10}}>
                                    <p className={"arialExtraBold"} style={{color:"#FFFFFF",fontSize:"6vw",margin:0}}>ÉKO<span style={{color:"#2c2e35",margin:0}}>CO</span> C'EST QUOI ?</p>
                                </div>
                                <p className={"arialBold"} style={{color:"#FFFFFF",fontSize:"3.3vw",marginBottom:0}}>ÉKOCO<span style={{color:"#2c2e35"}}> est une application qui protège la planète.</span></p>
                                <p className={"arialArrow"} style={{color:"#FFFFFF",fontSize:"3.3vw",marginBottom:0}}>Vous permettant de maîtriser votre</p>
                                <p className={"arialArrow"} style={{color:"#FFFFFF",fontSize:"3.3vw",margin:0}}>production de Co2 en limitant les moyens</p>
                                <p className={"arialArrow"} style={{color:"#FFFFFF",fontSize:"3.3vw",margin:0}}>de transports polluants, elle récompensera </p>
                                <p className={"arialArrow"} style={{color:"#FFFFFF",fontSize:"3.3vw",margin:0}}> vos efforts en tokens, monnaie que </p>
                                <p className={"arialArrow"} style={{color:"#FFFFFF",fontSize:"3.3vw",margin:0}}>vous pourrez offrir à l’association de votre choix.</p>
                            </>
                        )}
                        <div style={{backgroundColor:"#57b99a",display:"flex", justifyContent:"center",alignItems:"center", marginTop:30, padding:"2vw 15vw 2vw 15vw"}}>
                            {isBrowser === true ? (
                                <>
                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                        <img style={{width:"10vw", margin:0}} src={require('../assetsEkoco/EKOCO_C EST_PICTO1.png')} alt="Image app mobile"/>
                                        <p className={"arialMedium"} style={{color:"#FFFFFF", fontSize:"1vw"}}>Une application mobile</p>
                                    </div>
                                    <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                        <img style={{width:"10vw", margin:0}} src={require('../assetsEkoco/EKOCO_C EST_PICTO2.png')} alt="Image plateforme web"/>
                                        <p className={"arialMedium"} style={{color:"#FFFFFF", fontSize:"1vw"}}>Une plateforme web</p>
                                    </div>
                                    <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                        <img style={{width:"10vw", margin:0}} src={require('../assetsEkoco/EKOCO_C EST_PICTO3.png')} alt="Image tokens récompenses"/>
                                        <p className={"arialMedium"} style={{color:"#FFFFFF", fontSize:"1vw"}}>Des tokens en récompense</p>
                                    </div>
                                </>
                            ):(
                                <>
                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:20,marginLeft:20}}>
                                        <img style={{width:"22vw", margin:0}} src={require('../assetsEkoco/EKOCO_C EST_PICTO1.png')} alt="Image app mobile"/>
                                        <p className={"arialMedium"} style={{color:"#FFFFFF", fontSize:"3vw",textAlign:"center"}}>Une application mobile</p>
                                    </div>
                                    <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:20,marginLeft:20}}>
                                        <img style={{width:"22vw", margin:0}} src={require('../assetsEkoco/EKOCO_C EST_PICTO2.png')} alt="Image plateforme web"/>
                                        <p className={"arialMedium"} style={{color:"#FFFFFF", fontSize:"3vw",textAlign:"center"}}>Une plateforme web</p>
                                    </div>
                                    <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:20,marginLeft:20}}>
                                        <img style={{width:"22vw", margin:0}} src={require('../assetsEkoco/EKOCO_C EST_PICTO3.png')} alt="Image tokens récompenses"/>
                                        <p className={"arialMedium"} style={{color:"#FFFFFF", fontSize:"3vw", textAlign:"center"}}>Des tokens en récompense</p>
                                    </div>
                                </>
                            )}

                        </div>
                        {isBrowser === true ? (
                            <>
                                <img style={{margin:0, position:"absolute",right:"7vw",paddingBottom:"38.6vw",width:"18vw"}} src={require('../assetsEkoco/FEMME_PLANETE.png')} alt="Image téléchargement"/>
                                <a style={{textDecoration:"none"}} href="https://www.spuro.eu/ekoco" target={"_blank"}><Button style={{marginTop:30,marginBottom:50, backgroundColor:"#FFFFFF", color:"#85878b", textTransform:"none", borderRadius:100, fontWeight:"bold", fontSize:"1.3vw"}}>En savoir plus</Button></a>
                            </>
                            ):(
                            <>
                                <a style={{textDecoration:"none"}} href="https://www.spuro.eu/ekoco" target={"_blank"}><Button style={{marginTop:30,marginBottom:30, backgroundColor:"#FFFFFF", color:"#85878b", textTransform:"none", borderRadius:100, fontWeight:"bold", fontSize:"3.3vw"}}>En savoir plus</Button></a>
                            </>
                            )
                        }
                    </div>
                    <div id={"commentCaMarche"} style={{backgroundColor:"#FFFFFF",overflow:"hidden", display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginTop:20}}>
                        {isBrowser === true ? (
                            <div style={{backgroundColor:"#62c7ce",marginTop:70, width:"50%",display:"flex", justifyContent:"center",alignItems:"center",padding:15,borderRadius:10}}>
                                <p className={"arialExtraBold"} style={{color:"#2b4978", fontWeight:"bold",fontSize:"3.5vw",margin:0}}>COMMENT ÇA MARCHE ?</p>
                            </div>
                        ):(
                            <div style={{backgroundColor:"#62c7ce",marginTop:20, width:"80%",display:"flex", justifyContent:"center",alignItems:"center",padding:15,borderRadius:10}}>
                                <p className={"arialBold"} style={{color:"#2b4978", fontWeight:"bold",fontSize:"6vw",margin:0}}>COMMENT ÇA MARCHE ?</p>
                            </div>
                        )}

                        <div style={{display:"flex", justifyContent:"center",alignItems:"center", marginTop:40}}>
                            <div style={{display:"flex", flexDirection:this.state.flexDirectionCCM, justifyContent:"center",alignItems:"center"}}>
                                {isBrowser === true ? (
                                    <>
                                        <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                                <img style={{width:"15vw", margin:0}} src={require('../assetsEkoco/COMMENT_CA_MARCHE1.png')} alt="Image app mobile"/>
                                                <p className={"arialArrow"} style={{color:"#2b4978", fontSize:"2vw", textAlign:"center"}}>Téléchargez l'application sur votre mobile.</p>
                                            </div>
                                            <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                                <img style={{width:"15vw", margin:0}} src={require('../assetsEkoco/COMMENT_CA_MARCHE2.png')} alt="Image plateforme web"/>
                                                <p className={"arialArrow"} style={{color:"#2b4978", fontSize:"2vw",textAlign:"center"}}>Créez votre compte personnel.</p>
                                            </div>
                                        </div>
                                        <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                                            <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                                <img style={{width:"15vw", margin:0}} src={require('../assetsEkoco/COMMENT_CA_MARCHE3.png')} alt="Image tokens récompenses"/>
                                                <p className={"arialArrow"} style={{color:"#2b4978", fontSize:"2vw",textAlign:"center"}}>Démarrez et économisez du Co2.</p>
                                            </div>
                                            <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                                <img style={{width:"15vw", margin:0}} src={require('../assetsEkoco/COMMENT_CA_MARCHE4.png')} alt="Image tokens récompenses"/>
                                                <p className={"arialArrow"} style={{color:"#2b4978", fontSize:"2vw",textAlign:"center"}}>Gagnez des tokens et offrez-les !</p>
                                            </div>
                                        </div>
                                    </>
                                ):(
                                    <>
                                        <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                                <img style={{width:"30vw", margin:0}} src={require('../assetsEkoco/COMMENT_CA_MARCHE1.png')} alt="Image app mobile"/>
                                                <p className={"arialArrow"} style={{color:"#2b4978", fontSize:"3vw", textAlign:"center"}}>Téléchargez l'application sur votre mobile.</p>
                                            </div>
                                            <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                                <img style={{width:"30vw", margin:0}} src={require('../assetsEkoco/COMMENT_CA_MARCHE2.png')} alt="Image plateforme web"/>
                                                <p className={"arialArrow"} style={{color:"#2b4978", fontSize:"3vw",textAlign:"center"}}>Créez votre compte personnel.</p>
                                            </div>
                                        </div>
                                        <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                                            <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                                <img style={{width:"30vw", margin:0}} src={require('../assetsEkoco/COMMENT_CA_MARCHE3.png')} alt="Image tokens récompenses"/>
                                                <p className={"arialArrow"} style={{color:"#2b4978", fontSize:"3vw",textAlign:"center"}}>Démarrez et économisez du Co2.</p>
                                            </div>
                                            <div  style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginRight:40,marginLeft:40}}>
                                                <img style={{width:"30vw", margin:0}} src={require('../assetsEkoco/COMMENT_CA_MARCHE4.png')} alt="Image tokens récompenses"/>
                                                <p className={"arialArrow"} style={{color:"#2b4978", fontSize:"3vw",textAlign:"center"}}>Gagnez des tokens et offrez-les !</p>
                                            </div>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                        {isBrowser === true ? (
                            <a style={{textDecoration:"none"}} href="https://www.spuro.eu/ekoco" target={"_blank"}>
                                <Button style={{marginTop:30,marginBottom:50, backgroundColor:"#ececed", color:"#85878b", textTransform:"none", borderRadius:100, fontWeight:"bold", fontSize:"1.3vw"}}>En savoir plus</Button>
                            </a>
                        ):(
                            <a style={{textDecoration:"none"}} href="https://www.spuro.eu/ekoco" target={"_blank"}>
                                <Button style={{marginTop:30,marginBottom:30, backgroundColor:"#ececed", color:"#85878b", textTransform:"none", borderRadius:100, fontWeight:"bold", fontSize:"3.3vw"}}>En savoir plus</Button>
                            </a>
                        )}

                    </div>
                    <div id={"reporting"} style={{backgroundColor:"#ffe8df", display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginTop:20}}>
                        {isBrowser === true ? (
                            <>
                                <div style={{backgroundColor:"#ff805e",marginTop:70, width:"50%",display:"flex", justifyContent:"center",alignItems:"center",padding:15,borderRadius:10}}>
                                    <p className={"arialExtraBold"} style={{color:"#FFFFFF",fontSize:"3.5vw",margin:0}}>UN REPORTING DÉTAILLÉ</p>
                                </div>
                                <div style={{display:"flex", justifyContent:"center",alignItems:"center",flexDirection:"column", marginTop:40}}>
                                    <p className={"arialArrow"} style={{color:"#2b4978", marginBottom:0, fontSize:"1.8vw",}}>Ekoco utilise votre GPS intégré à votre téléphone</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"1.8vw",}}>de pair avec une puissante Intelligence Artificielle</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"1.8vw",}}>pour retranscrire le plus précisément votre empreinte</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"1.8vw",}}>carbone, et vous récompenser comme il se doit !</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"1.8vw",}}>Vous pourrez observer vos statistiques et</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"1.8vw",}}>votre économie de Co2 totale.</p>
                                </div>
                                <img style={{width:"30vw", margin:0, position:"absolute",left:"3vw",paddingTop:200}} src={require('../assetsEkoco/REPORTING.png')} alt="Reportirng Ekoco"/>
                                <a style={{textDecoration:"none"}} href="https://play.google.com/store/apps/details?id=com.ekoco" target={"_blank"}><Button style={{marginTop:30,marginBottom:30, backgroundColor:"#FFFFFF", color:"#85878b", textTransform:"none", borderRadius:100, fontWeight:"bold", fontSize:"1.3vw"}}>Je télécharge Ekoco</Button></a>
                            </>
                        ):(
                            <>
                                <div style={{backgroundColor:"#ff805e",marginTop:20, width:"80%",display:"flex", justifyContent:"center",alignItems:"center",padding:15,borderRadius:10}}>
                                    <p className={"arialExtraBold"} style={{color:"#FFFFFF", fontSize:"5.5vw",margin:0}}>UN REPORTING DÉTAILLÉ</p>
                                </div>
                                <div style={{display:"flex", justifyContent:"center",alignItems:"center",flexDirection:"column", marginTop:40}}>
                                    <p className={"arialArrow"} style={{color:"#2b4978", marginBottom:0, fontSize:"3.2vw",}}>Ekoco utilise votre GPS intégré à votre téléphone</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"3.2vw",}}>de pair avec une puissante Intelligence Artificielle</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"3.2vw",}}>pour retranscrire le plus précisément votre empreinte</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"3.2vw",}}>carbone, et vous récompenser comme il se doit !</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"3.2vw",}}>Vous pourrez observer vos statistiques et</p>
                                    <p className={"arialArrow"} style={{color:"#2b4978", margin:0, fontSize:"3.2vw",}}>votre économie de Co2 totale.</p>
                                </div>
                                <img style={{width:"30vw", margin:0, marginTop:30, position:"absolute",left:"-1vw",paddingTop:200}} src={require('../assetsEkoco/REPORTING.png')} alt="Reportirng Ekoco"/>
                                <a style={{textDecoration:"none"}} href="https://play.google.com/store/apps/details?id=com.ekoco" target={"_blank"}><Button style={{marginTop:30,marginBottom:50, backgroundColor:"#FFFFFF", color:"#85878b", textTransform:"none", borderRadius:100, fontWeight:"bold", fontSize:"3.3vw"}}>Je télécharge Ekoco</Button></a>
                            </>
                        )}

                    </div>
                    <div id={"pourEntreprise"} style={{backgroundColor:"#FFFFFF", display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center",marginTop:20, marginBottom:this.state.marginBottomAvantFooter}}>
                        {isBrowser === true ? (
                            <>
                                <div style={{backgroundColor:"#2b4978",marginTop:70,zIndex:3, width:"50%",display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center",padding:15,borderRadius:10}}>
                                    <p className={"arialExtraBold"} style={{color:"#FFFFFF",fontSize:"3.5vw",margin:0}}>ÉKO<span style={{color:"#5fcaa8"}}>CO</span></p>
                                    <p className={"arialExtraBold"} style={{color:"#FFFFFF",fontSize:"3.5vw",margin:0}}>POUR LES ENTREPRISES</p>
                                </div>
                                <form style={{backgroundColor:"#FFFFFF", display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center", width:"100%"}} onSubmit={(e)=>{this.handleSubmitMailPro(e)}}>
                                    <TextField type={"email"} required={true} style={{backgroundColor:"#f2f2f2",width:"45%", marginTop:50}} onChange={this.handleChange} name={"ChampsEmailPro"} label={"Email"} variant="outlined" > </TextField>
                                    {this.state.ChampsEmailPro.length > 0 ? (<TextField required={true} style={{backgroundColor:"#f2f2f2",width:"45%", marginTop:20}} rows={4} multiline={"true"} onChange={this.handleChange} name={"ChampsMessagePro"} label={"Votre message"} variant="outlined" > </TextField>):("")}
                                    {this.state.ChampsMessagePro.length > 0 &&
                                        <>
                                            {/*{!this.state.captchaVerified && <Captcha verifCaptcha={()=>{this.changeCaptchaState()}}/> }*/}
                                            <Button style={{padding:"10px", width:"25%",backgroundColor:"#2b4978",color:"white", fontWeight:"bold", marginTop:20}} variant={"outlined"} type={"submit"} >Envoyer</Button>
                                        </>
                                    }
                                </form>
                            </>
                        ):(
                            <>
                                <div style={{backgroundColor:"#2b4978",marginTop:20,zIndex:3, width:"80%",display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center",padding:15,borderRadius:10}}>
                                    <p style={{color:"#FFFFFF", fontWeight:"bold",fontSize:"6vw",margin:0}}>ÉKO<span style={{color:"#5fcaa8"}}>CO</span></p>
                                    <p style={{color:"#FFFFFF", fontWeight:"bold",fontSize:"6vw",margin:0}}>POUR LES ENTREPRISES</p>
                                </div>
                                <form style={{backgroundColor:"#FFFFFF", display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center", width:"100%"}} onSubmit={(e)=>{this.handleSubmitMailPro(e)}}>
                                <TextField type={"email"} required={true} style={{backgroundColor:"#f2f2f2",width:"75%", marginTop:50}} onChange={this.handleChange} name={"ChampsEmailPro"} label={"Email"} variant="outlined" > </TextField>
                                {this.state.ChampsEmailPro.length > 0 && (<TextField required={true} style={{backgroundColor:"#f2f2f2",width:"75%", marginTop:20}} rows={4} multiline={"true"} onChange={this.handleChange} name={"ChampsMessagePro"} label={"Votre message"} variant="outlined" > </TextField>)}
                                {this.state.ChampsMessagePro.length > 0 &&(
                                    <>
                                        {/*{!this.state.captchaVerified && <Captcha verifCaptcha={()=>{this.changeCaptchaState()}}/> }*/}
                                        <Button style={{padding:"10px", width:"25%",backgroundColor:"#2b4978",color:"white", fontWeight:"bold", marginTop:20}} variant={"outlined"} type={"submit"} >Envoyer</Button>
                                    </>
                                )}
                                </form>
                            </>
                            )}
                    </div>
                </div>
                {/*Footer*/}
                <div style={{width:"100%", height:"100%",paddingTop:10, backgroundColor:"#f5f6f6", display:"flex",flexWrap:"wrap", marginTop:50, justifyContent:"center", alignItems:"center", zIndex:4}}>
                    <div style={{width:"90%", height:"90%",display:"flex", flexWrap:"wrap"}}>
                        {isBrowser === true ? (
                            <div style={{display:"flex", flex:3, width:250, height:250, justifyContent:"center", alignItems:"center"}}>
                                <img style={{width:285,height:200, textAlign:"center"}} src={require("../assetsEkoco/Ekoco.png")} alt="LogoEkoco"/>
                            </div>
                        ):("")}
                        <div style={{display:"flex", flex:3, flexDirection:"column", justifyContent:"center"}}>
                            <h1 className={"arialExtraBold"} style={{margin:0,color:"#2c2e35"}}>L’application qui fait</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>DU BIEN</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>À LA PLANÈTE</h1>
                        </div>
                        <div style={{display:"flex",flex:4}}>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} onClick={()=>{document.getElementById("cestQuoi").scrollIntoView()}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO c’est quoi ?</p>
                                <p className={"arialArrow"} onClick={()=>{document.getElementById("commentCaMarche").scrollIntoView()}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Comment ça marche ?</p>
                                <p className={"arialArrow"} onClick={()=>{document.getElementById("reporting").scrollIntoView()}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Un reporting détaillé</p>
                                <p className={"arialArrow"} onClick={()=>{document.getElementById("pourEntreprise").scrollIntoView()}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO pour les entreprises</p>
                            </div>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/PolitiqueDeConfidentialite")}}>Politique de confidentialité</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/ConditionsGeneralesDutilisation")}}>CGU</p>
                                <p className={"arialArrow"} onClick={()=>{this.displayContact()}} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Contacts</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Utilisation des cookies</p>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default withRouter(BasCarboneBlockchain);