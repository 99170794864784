import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import {Close, ContentPaste, Edit, Info, KeyboardReturn, Preview, Warning} from "@mui/icons-material";
import Dropzone from "react-dropzone";
import jwt_decode from "jwt-decode";
import {Avatar, Backdrop, CircularProgress, TextField, Tooltip} from "@mui/material";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';


const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Design","Développement","Marketing","Informatique et logiciels","Développement personnel","Business","Photographie","Musique","Divers"
]
const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let socketIO; //Initialisation de l'instance du socket
let jwtDecoded;


class Profil extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    setInitialStates(){
        return{
            avatarBuffer:null,
            crop: {
                unit: 'px',
                x: 0,
                y: 0,
                width: 250,
                height: 250
            },
            croppedBuffer:null,
            empreinteAvatar:null,
            modifyAvatar:false,
            hoverImageEdit:false,
            refreshRandom:"",
            showBrowserView:true,
            displayExternal:true,
            emp:null,
            videosPreview:[],
            videosPreviewVente:[],
            empToGet:"",
            displayAdditionnalInfos:false
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        let emp = new URLSearchParams(window.location.search).get("emp");
        if(emp !== null){
            this.setState({
                emp,
            })
        }
        this.setState({
            jwt:sessionStorage.getItem("BUYRESALE_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    this.setState({
                        empreinteAvatar:jwtDecoded.empreinte,
                    })
                    global.emitter.emit("onRouteChangedBuyResale", {
                        jwt: this.state.jwt,
                    })
                    if(!!emp && emp !== "missingInfos"){
                        if(jwtDecoded.empreinte === emp){
                            this.setState({displayExternal:false})
                            this.getInfos()
                        }
                    }else{
                        this.setState({displayExternal:false})
                        this.getInfos()
                    }
                    this.getUserInfos(emp ? (emp !== "missingInfos" ? emp:jwtDecoded.empreinte) : jwtDecoded.empreinte)
                }else{
                    sessionStorage.removeItem("BUYRESALE_jwt")
                    global.emitter.emit("onRouteChangedBuyResale", {
                        jwt: null,
                    })
                    if(emp === null){
                        window.location.replace("/BuyResale/Accueil")
                    }else{
                        this.getUserInfos(emp)
                    }
                }
            })
        })
    }

    getUserInfos(emp){
        Axios.post(url+"getProfil",{empreinte:emp},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                this.setState({
                    descValue:response.data.description||"",
                    pseudoValue:response.data.pseudo,
                    videosPreview:response.data.videos,
                    videosPreviewVente:response.data.ventes,
                    empToGet:emp
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Une erreur est survenue.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    window.location.replace("/BuyResale/Accueil")
                })
            }
        })
    }

    getInfos(){
        Axios.post(url+"getInfos",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                console.log(response.data)
                this.setState({
                    defaultEmailValue:response.data.email||"",
                    emailValue:response.data.email||"",
                    defaultIbanValue: response.data.iban ? response.data.iban.replace(
                        /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})/,
                        '$1 $2 $3 $4 $5 $6 $7'
                    ):response.data.iban||"",
                    ibanValue:response.data.iban ? response.data.iban.replace(
                        /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})/,
                        '$1 $2 $3 $4 $5 $6 $7'
                    ):response.data.iban||"",
                    defaultBicValue: response.data.bic||"",
                    bicValue:response.data.bic||"",
                    defaultTelValue: response.data.tel||"",
                    telValue:response.data.tel||"",
                    statusMaritalValue:response.data.statusMarital,
                    defaultStatusMaritalValue:response.data.statusMarital,
                    adresseValue:response.data.adresse||"",
                    defaultAdresseValue:response.data.adresse||"",
                    nomValue:response.data.nom||"",
                    defaultNomValue:response.data.nom||"",
                    prenomValue:response.data.prenom||"",
                    defaultPrenomValue:response.data.prenom||"",
                    pseudoValue:response.data.pseudo||"",
                    defaultVilleValue:response.data.ville||"",
                    villeValue:response.data.ville||"",
                    defaultPostalValue:response.data.postal||"",
                    postalValue:response.data.postal||"",
                    defaultDobValue:response.data.ddn||"",
                    dobValue:response.data.ddn||"",
                    descValue:response.data.description||"",
                    defaultDescValue:response.data.description||"",


                    allInfosCompleted:response.data.allInfosCompleted,
                    displayAdditionnalInfos:response.data.displayAdditionnalInfos
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Une erreur est survenue.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    handleChange(event) {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    handleChangePhone(event){
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if(onlyNums.length < 10){
            this.setState({
                [event.currentTarget.name] : onlyNums
            })
        } else if (onlyNums.length === 10){
            const number = onlyNums.replace(
                /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                '$1 $2 $3 $4 $5'
            );
            this.setState({
                [event.currentTarget.name] : number
            })
        }
    }

    handleChangeIBAN(event){
        const dataIban = event.target.value.replaceAll(" ","")
        if(dataIban.length < 27){
            this.setState({
                [event.currentTarget.name] : dataIban
            })
        } else if (dataIban.length === 27){
            const number = dataIban.replace(
                /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})/,
                '$1 $2 $3 $4 $5 $6 $7'
            );
            this.setState({
                [event.currentTarget.name] : number
            })
        }
    }

    cropComplete = (finalCrop) =>{
        this.getCroppedImg(this.state.avatarBuffer)
    }

    getCroppedImg(imageParam) {
        let image = new Image()
        image.src = imageParam
        //On cap la height a 300 mais pas la width -> on cherche a savoir la width de l'image sur notre page
        let imageMinia = document.getElementById('imageMinia');
        let realWidth = imageMinia.clientWidth
        let realHeight = imageMinia.clientHeight

        const canvas = document.createElement("canvas");
        const scaleX = image.width / realWidth;
        const scaleY = image.height / realHeight;
        canvas.width = 250;
        canvas.height =  250;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            this.state.crop.x * scaleX,
            this.state.crop.y * scaleY,
            this.state.crop.width * scaleX,
            this.state.crop.height * scaleY,
            0,
            0,
            250,
            250
        )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImage = new File([u8arr], filename, {type:mime});
        let self = this
        const reader = new FileReader()
        reader.onload = () => {
            self.setState({croppedBuffer:reader.result})
        }
        reader.readAsArrayBuffer(croppedImage)
    }

    validateAvatar(){
        this.setState({clickedAvatarButton:true})
        Axios.post(url+"uploadAvatar",{buffer:Buffer.from(this.state.croppedBuffer,"base64")},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            this.setState({clickedAvatarButton:false})
            if(response.data.resultat === "done"){
                this.setState({modifyAvatar:false,refreshRandom:"?refresh="+new Date().getTime(), avatarBuffer:null,croppedBuffer:null})
                global.emitter.emit("modifyAvatar")
                Toast.fire({
                    title:"Avatar modifié",
                    icon:"success"
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Une erreur est survenue.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    async modifyInfos(e){
        e.preventDefault()
        if (this.state.displayAdditionnalInfos || this.state.emp === "missingInfos"){ //On a déjà un compte stripe ou on veut en créer un
            const iban = this.state.ibanValue ? this.state.ibanValue.replaceAll(" ",""):this.state.ibanValue||""
            if(iban.length !== 27){
                Swal.fire({
                    title: 'Erreur!',
                    text: "L'IBAN renseigné n'est pas valide.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }else{
                this.setState({showBackdrop:true})

                try{
                    Axios.post(url+"editInfos",{
                        email:this.state.emailValue && this.state.emailValue.trim(),
                        iban,
                        bic:this.state.bicValue,
                        tel:this.state.telValue,
                        adresse:this.state.adresseValue && this.state.adresseValue.trim(),
                        nom:this.state.nomValue && this.state.nomValue.trim(),
                        prenom:this.state.prenomValue && this.state.prenomValue.trim(),
                        postal:this.state.postalValue && this.state.postalValue.trim(),
                        ddn:this.state.dobValue,
                        ville:this.state.villeValue && this.state.villeValue.trim(),
                        description:this.state.descValue && this.state.descValue.trim(),
                    },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                        if(response.data.jwt){
                            sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                            this.setState({
                                jwt:response.data.jwt
                            },()=>{
                                jwtDecoded = jwt_decode(this.state.jwt)
                            })
                        }
                        if(response.data.resultat === "done" || response.data.resultat === "Votre iban n'est pas valide."){
                            if(response.data.resultat === "Votre iban n'est pas valide."){
                                Swal.fire({
                                    title: 'Erreur!',
                                    text: "Votre iban n'est pas valide",
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                })
                                this.setState({ibanValue:this.state.defaultIbanValue})
                            }else{
                                Toast.fire({
                                    title:"Informations modifiées",
                                    icon:"success"
                                })
                            }
                            this.setState({
                                defaultEmailvalue:this.state.emailValue,
                                defaultIbanValue:response.data.resultat !== "Votre iban n'est pas valide." ? this.state.ibanValue:this.state.defaultIbanValue,
                                defaultBicValue:this.state.bicValue,
                                defaultTelValue:this.state.telValue,
                                defaultAdresseValue:this.state.adresseValue,
                                defaultNomValue:this.state.nomValue,
                                defaultPrenomValue:this.state.prenomValue,
                                defaultVilleValue:this.state.villeValue,
                                defaultDobValue:this.state.dobValue,
                                defaultPostalValue:this.state.postalValue,
                                defaultDescValue:this.state.descValue,
                                displayAdditionnalInfos:this.state.prenomValue && true,
                            })
                        }else{
                            Swal.fire({
                                title: 'Erreur!',
                                text: response.data.resultat,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }
                        this.setState({showBackdrop:false})
                    })
                }catch (e) {
                    Swal.fire({
                        title: 'Attention !',
                        text: "Vos informations ne peuvent pas être confirmées.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                    this.setState({showBackdrop:false})
                }
            }
        }else{
            Axios.post(url+"editInfos",{
                email:this.state.emailValue && this.state.emailValue.trim(),
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if(response.data.resultat === "done"){
                    this.setState({
                        defaultEmailvalue:this.state.emailValue,
                    })
                    Toast.fire({
                        title:"Informations modifiées",
                        icon:"success"
                    })
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: response.data.resultat,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
                this.setState({showBackdrop:false})
            })
        }
    }

    getMaxDateProfil(){
        let date = new Date()
        date.setDate(date.getDate())
        date = date.toISOString().split("T")[0]
        return date
    }

    allDone(){
        let email = this.state.emailValue,
            iban = this.state.ibanValue && this.state.displayAdditionnalInfos,
            bic = this.state.bicValue && this.state.displayAdditionnalInfos,
            tel = this.state.telValue,
            adr = this.state.adresseValue,
            // statMar = this.state.statusMaritalValue,
            nom = this.state.nomValue,
            prenom = this.state.prenomValue,
            ville = this.state.villeValue,
            postal = this.state.postalValue,
            dob = this.state.dobValue,
            cC = this.state.checkConfirm,
            desc = this.state.descValue;
        return !!(email && tel && adr && nom && prenom && ville && postal && dob && cC && iban && bic && desc);
    }

    validButton(){
        let email = this.state.defaultEmailValue === this.state.emailValue,
            iban = this.state.defaultIbanValue === this.state.ibanValue,
            bic = this.state.defaultBicValue === this.state.bicValue,
            tel = this.state.defaultTelValue === this.state.telValue,
            adr = this.state.defaultAdresseValue === this.state.adresseValue,
            nom = this.state.defaultNomValue === this.state.nomValue,
            prenom = this.state.defaultPrenomValue === this.state.prenomValue,
            ville = this.state.defaultVilleValue === this.state.villeValue,
            postal = this.state.defaultPostalValue === this.state.postalValue,
            dob = this.state.defaultDobValue === this.state.dobValue,
            desc = this.state.defaultDescValue === this.state.descValue;
        console.log("defaultDescValue",this.state.defaultDescValue , "descValue",this.state.descValue)
        console.log(email,iban,bic,tel,adr,nom,prenom,ville,postal,dob,desc)
        if(email && iban && bic && tel && adr && nom && prenom && ville && postal && dob && desc){
            return true
        }else{
            return false
        }
    }

    generateProfileLink(){
        let urlLink = baseUrl+(suffix===":42000/" ? ":3000":"")
        navigator.clipboard.writeText(urlLink+"/BuyResale/Profil?emp="+jwtDecoded.empreinte)
        Toast.fire({
            icon: 'info',
            title: 'Lien vers votre profil copié !'
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.showBackdrop}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100, marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            {!this.state.displayExternal ? ( //Mode modif
                                <>
                                    <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                        <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Mon profil :</h1>
                                        <div style={{background: "linear-gradient(49deg, rgba(114,170,204,1) 0%, rgba(250,182,3,1) 100%)", width:185, height:3, borderRadius:100, marginBottom:20}}></div>
                                        {this.state.displayAdditionnalInfos && (
                                            <button onClick={()=>{this.setState({emp:jwtDecoded.empreinte,displayExternal:true})}} className={"divButtonBuyResale flexCenter"}><Preview style={{marginRight:10, color:"#FFFFFF", transform:"scale(1.3)"}}/> Prévisualiser mon profil</button>
                                        )}
                                    </div>
                                    <div style={{width:"100%", display:"flex",marginTop:this.state.showBrowserView&&20, alignItems:this.state.showBrowserView ? "flex-start":"center", justifyContent:"center", flexDirection:this.state.showBrowserView ? "row":"column"}}>
                                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column", width:"100%"}}>
                                            <h3>Mon Avatar :</h3>
                                            {this.state.modifyAvatar ? (
                                                <>
                                                    <div className={"flexCenter"}>
                                                        <div style={{flex:1}}>
                                                            {this.state.avatarBuffer === null && (
                                                                <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                                                    acceptedFiles.forEach((file)=>{
                                                                        if(file.size >= sizeLimitFile){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                target:"#dialogUpload",
                                                                                title: 'Le fichier est trop lourd (>10GB) !'
                                                                            })
                                                                        }else if(file.name.includes("##")){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                target:"#dialogUpload",
                                                                                title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                            })
                                                                        }else if(!file.type.includes("image")){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                target:"#dialogUpload",
                                                                                title: 'Le fichier uploadé doit être une image !'
                                                                            })
                                                                        }else if (file.name.includes(".zip")){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                target:"#dialogUpload",
                                                                                title: "L'enregistrement de zip n'est pas autorisé."
                                                                            })
                                                                        }else{
                                                                            let self = this;
                                                                            const reader = new FileReader()
                                                                            reader.onload = () => {
                                                                                let img = new Image;
                                                                                img.onload = function() {
                                                                                    self.setState({avatarBuffer:reader.result},()=>{
                                                                                        let imageMinia = document.getElementById('imageMinia');
                                                                                        let minDim = imageMinia.clientHeight > imageMinia.clientWidth ? imageMinia.clientWidth:imageMinia.clientHeight
                                                                                        self.setState({
                                                                                            crop: {
                                                                                                unit: 'px',
                                                                                                x: 0,
                                                                                                y: 0,
                                                                                                width: minDim,
                                                                                                height: minDim
                                                                                            }
                                                                                        })
                                                                                        self.getCroppedImg(self.state.avatarBuffer)
                                                                                    })
                                                                                };
                                                                                img.src = reader.result;
                                                                            }
                                                                            reader.readAsDataURL(file)
                                                                            Toast.fire({
                                                                                icon: 'success',
                                                                                target:"#dialogUpload",
                                                                                title: "Fichier(s) correctement déposé(s), prêt à l'enregistrement."
                                                                            })
                                                                        }
                                                                    })
                                                                }}>
                                                                    {({getRootProps, getInputProps}) => (
                                                                        <section style={{textAlign:"center"}}>
                                                                            <div className={"flexCenter"} id={this.state.acceptedFile===null &&"hoverMoving"} {...getRootProps()} style={{width:250, height:250, margin:"0 20px 0 20px",borderRadius:10,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold",cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                                                <input {...getInputProps()} />
                                                                                <>
                                                                                    <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFile && "#bbbbbb"}}>{this.state.avatarBuffer ? "Avatar déposée.":"Déposez votre avatar ici."}</h3>
                                                                                </>
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                            )}
                                                        </div>
                                                        <div style={{textAlign:"center"}}>
                                                            {this.state.avatarBuffer && (
                                                                // <img style={{width:"60%",maxHeight:270, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={`data:${"image"+"Miniature"};base64,${Buffer.from(this.state.avatarBuffer).toString("base64")}`} alt="miniature"/>
                                                                <ReactCrop
                                                                    crop={this.state.crop}
                                                                    onChange={(pixelCrop)=>{this.onCropChange(pixelCrop)}}
                                                                    circularCrop={true}
                                                                    aspect={1}
                                                                    keepSelection={true}
                                                                    onComplete={(pixelCrop)=>{this.cropComplete(pixelCrop)}}
                                                                >
                                                                    <img id={"imageMinia"} style={{width:"100%",maxHeight:250, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={this.state.avatarBuffer} alt="avatar"/>
                                                                </ReactCrop>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className={"flexCenter"} style={{marginTop:20}}>
                                                        <button disabled={!this.state.croppedBuffer || this.state.clickedAvatarButton} onClick={()=>{this.validateAvatar()}} className={"divButtonBuyResale"}>Valider l'avatar</button>
                                                        <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyAvatar:false,avatarBuffer:null,croppedBuffer:null, crop: {unit: 'px',x: 0, y: 0, width: 250, height: 250}})}} style={{marginLeft:5, backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)", padding:10}}><Close style={{color:"#FFFFFF"}}/></button>
                                                    </div>
                                                </>
                                            ):(
                                                <>
                                                    {this.state.empreinteAvatar && (
                                                        <>
                                                            {this.state.hoverImageEdit && (
                                                                <div style={{position:"relative", width:"fit-content"}}>
                                                                    <Edit style={{position:"absolute", color:"white",pointerEvents:"none", zIndex:1, marginLeft:0, marginRight:0, left:-12, right:0, top:125, transform:"scale(1.5)", textAlign:"center"}}/>
                                                                </div>
                                                            )}
                                                            <div style={{width:"fit-content", height:"fit-content", borderRadius:"50%", boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding:10}}>
                                                                <Avatar
                                                                    variant={"circular"}
                                                                    onClick={()=>{this.setState({modifyAvatar:true,hoverImageEdit:false})}}
                                                                    onMouseEnter={()=>{this.setState({hoverImageEdit:true})}}
                                                                    onMouseLeave={()=>{this.setState({hoverImageEdit:false})}}
                                                                    className={"imageProfilPreview"}
                                                                    src={`${url}images/avatar/${this.state.empreinteAvatar}.png${this.state.refreshRandom}`}
                                                                    alt={jwtDecoded && jwtDecoded.pseudo.toUpperCase()}
                                                                    style={{width:250, height:250, fontSize:90}}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {this.state.displayAdditionnalInfos && (
                                                <TextField multiline={true} rows={5} rowsMax={15} style={{width:"75%", marginTop:15}} name={"descValue"} defaultValue={this.state.descValue} value={this.state.descValue} onChange={(e)=>{this.handleChange(e)}} label="Description du profil" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                            )}
                                        </div>
                                        <form onSubmit={(e)=>{this.modifyInfos(e)}} className={"flexCenter"} style={{flex:5, flexDirection:"column", width:"100%", maxWidth:750}}>
                                            <h3>Mes informations :</h3>
                                            <TextField required={true} disabled={true} style={{width:"100%", marginBottom:15, color:"#000", backgroundColor:"#f3f3f3",borderRadius:3}} name={"pseudoValue"} defaultValue={this.state.pseudoValue} value={this.state.pseudoValue} label="Identifiant" variant="outlined" InputLabelProps={{ shrink: true }} inputProps={{ style: { color: "#000" } }}/>
                                            {(this.state.displayAdditionnalInfos || this.state.emp === "missingInfos") && (
                                                <>
                                                    <div style={{width:"100%", display:"flex", alignItems:"center",marginBottom:(!!this.state.defaultPrenomValue && !!this.state.defaultNomValue) && 15, justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                        <TextField required={true} disabled={!!this.state.defaultPrenomValue} style={{width:this.state.showBrowserView ? "34%":"100%", color:!!this.state.defaultPrenomValue&&"#000",marginBottom:!this.state.showBrowserView && 15, backgroundColor:!!this.state.defaultPrenomValue &&"#f3f3f3",borderRadius:3}} name={"prenomValue"} defaultValue={this.state.prenomValue} value={this.state.prenomValue} onChange={(e)=>{this.handleChange(e)}} label="Prénom" variant="outlined" InputLabelProps={{ shrink: true }} inputProps={{ style: { color: "#000" } }}/>
                                                        <TextField required={true} disabled={!!this.state.defaultNomValue} style={{width:this.state.showBrowserView ? "64%":"100%", color:!!this.state.defaultNomValue && "#000", backgroundColor:!!this.state.defaultNomValue && "#f3f3f3",borderRadius:3}} name={"nomValue"} defaultValue={this.state.nomValue} value={this.state.nomValue} onChange={(e)=>{this.handleChange(e)}} label="Nom" variant="outlined" InputLabelProps={{ shrink: true }} inputProps={{ style: { color:"#000" } }}/>
                                                    </div>
                                                    {(!this.state.defaultPrenomValue || !this.state.defaultNomValue) && (
                                                        <p className={"flexCenter"} style={{marginBottom: 0,color:"red", marginTop:0, fontSize:12}}> <Warning/> Le nom et prénom ne peuvent plus être modifiés ultérieurement</p>
                                                    )}
                                                </>
                                            )}

                                            <TextField required={true} style={{width:"100%", marginBottom:15}} type={"email"} name={"emailValue"} defaultValue={this.state.emailValue} value={this.state.emailValue} onChange={(e)=>{this.handleChange(e)}} label="Email" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                            {(this.state.displayAdditionnalInfos || this.state.emp === "missingInfos") && (
                                                <>
                                                    <TextField type={"date"} required={true} style={{width:"100%", marginBottom:15}} name={"dobValue"} value={this.state.dobValue} onChange={(e)=>{this.handleChange(e)}} label="Date de naissance" variant="outlined" InputLabelProps={{
                                                        shrink: true
                                                    }}inputProps={{ max:`${this.getMaxDateProfil()}`}}></TextField>
                                                    <TextField required={true} style={{width:"100%", marginBottom:15}} name={"telValue"} defaultValue={this.state.telValue} value={this.state.telValue} onChange={(e)=>{this.handleChangePhone(e)}} label="Téléphone" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                    <TextField required={true} style={{width:"100%", marginBottom:15}} name={"adresseValue"} defaultValue={this.state.adresseValue} value={this.state.adresseValue} onChange={(e)=>{this.handleChange(e)}} label="Adresse postale" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                    <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                        <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"postalValue"} defaultValue={this.state.postalValue} value={this.state.postalValue} onChange={(e)=>{this.handleChange(e)}} label="Code postal" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                        <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"villeValue"} defaultValue={this.state.villeValue} value={this.state.villeValue} onChange={(e)=>{this.handleChange(e)}} label="Ville" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                    </div>
                                                    <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                        <div className={"flexCenter"} style={{width:this.state.showBrowserView ? "69%":"100%"}}>
                                                            <TextField style={{width:"100%", marginBottom: !this.state.showBrowserView && 15}} name={"ibanValue"} defaultValue={this.state.ibanValue} value={this.state.ibanValue} onChange={(e)=>{this.handleChangeIBAN(e)}} label="IBAN personnel" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                            <Tooltip title={"Votre IBAN sera utilisé afin de vous transférer les recettes de vos ventes de concepts directement sur votre compte bancaire. Les transferts ont lieu de manière hebdomadaire."}>
                                                                <Info style={{color:"#a2a2a2",marginLeft:5}}/>
                                                            </Tooltip>
                                                        </div>
                                                        <TextField style={{width:this.state.showBrowserView ? "29%":"100%"}} name={"bicValue"} defaultValue={this.state.bicValue} value={this.state.bicValue} onChange={(e)=>{this.handleChange(e)}} label="BIC personnel" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                    </div>
                                                </>
                                            )}

                                            {this.state.jwtDecodedAdmin ? (
                                                <>
                                                    <h2>Pas de modification de compte en tant qu'admin</h2>
                                                </>
                                            ):(
                                                <>
                                                    {(this.validButton() && this.allDone()) ? (
                                                        <button className={"divButtonBtoBDesign"} style={{marginTop:20}} onClick={()=>{this.props.navigate("/BuyResale/Catalogue")}} >Profil complété - Explorez le catalogue</button>
                                                    ):(
                                                        <>
                                                            {!this.validButton() && (
                                                                <button disabled={this.validButton()} className={"divButtonBtoBDesign"} style={{marginTop:20}} type={"submit"} >Valider mes informations</button>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </form>
                                    </div>
                                </>
                            ):( //Mode prévisu
                                <>
                                    {this.state.emp !== null && (
                                        <>
                                            {(jwtDecoded && (this.state.emp === jwtDecoded.empreinte)) ? (
                                                    <>
                                                        <button style={{alignSelf:"start", padding:10, marginTop:10, marginBottom:10}} className={"divButtonBuyResale flexCenter"} onClick={()=>{this.setState({displayExternal:false})}}><KeyboardReturn style={{marginRight:10, color:"#FFFFFF"}}/> Modifier mon profil</button>
                                                        <button style={{alignSelf:"start", marginBottom:20, padding:10}} onClick={()=>{this.generateProfileLink()}} className={"divButtonBuyResale flexCenter"}> <ContentPaste style={{marginRight:10, color:"#FFFFFF"}}/> Copier le lien vers mon profil</button>
                                                    </>
                                                ):(
                                                <button style={{alignSelf:"start", marginBottom:20, padding:5, fontSize:14, marginTop:10}} className={"divButtonBuyResale flexCenter"} onClick={()=>{this.props.navigate(-1)}}><KeyboardReturn style={{marginRight:5, transform:"scale(0.8)"}}/> Retour</button>
                                            )}
                                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexDirection:!this.state.showBrowserView && "column"}}>
                                                <div style={{width:"fit-content", height:"fit-content", borderRadius:"50%", boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding:10}}>
                                                    <Avatar
                                                        variant={"circular"}
                                                        src={`${url}images/avatar/${this.state.emp}.png`}
                                                        alt={this.state.pseudoValue && this.state.pseudoValue.toUpperCase()}
                                                        style={{width:200, height:200, fontSize:60}}
                                                    />                                                </div>
                                                <div style={{width:"fit-content", height:"100%", marginLeft:30}}>
                                                    <h1 style={{marginBottom:10}} className={"textShadowBorder"}>{this.state.pseudoValue}</h1>
                                                    <h3 style={{fontStyle:this.state.descValue === "undefined" && "italic"}}>{(this.state.descValue === "undefined" || this.state.descValue === "") ? `Nous n'avons aucune information sur ${this.state.pseudoValue} mais nous sommes sûrs que son contenu est de qualité !`:this.state.descValue}</h3>
                                                </div>
                                            </div>
                                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", flexDirection:"column"}}>
                                                <h3>Découvrez mes formations : </h3>
                                                <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                                    {this.state.videosPreview.length !== 0 ? (
                                                        <>
                                                            {this.state.videosPreview.map(vid=>(
                                                                <div onAuxClick={(e)=>{if(e.button===1){window.open("/BuyResale/Token?id="+vid.idToken, "_blank")}}} onClick={()=>{this.props.navigate("/BuyResale/Token?id="+vid.idToken)}} className={"divMyVideo"} style={{flexDirection:"column", background: "rgba(0,0,0,.5)",display:"flex", alignItems:"center",justifyContent:"flex-start", overflow:"hidden", border:!vid.verified && "2px solid #ff8100"}}>
                                                                    <div style={{width:'100%', position:"relative"}}>
                                                                        <div style={{height:31, textAlign:"center",position:"absolute", zIndex:999, width:"100%", background: "rgba(0,0,0,.6)"}}>
                                                                            <h4 style={{margin:5, textAlign:"center", color:"white"}} >{vid.titre.length>32 ? (vid.titre.slice(0,28)+"..."):(vid.titre)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{position:"absolute",direction:"rtl", alignSelf:"flex-end", padding:2, zIndex:9999,borderRadius:10, width :"98%", marginBottom:10, top:175}}>
                                                                        <h4 className={"textShadowBorder"} style={{color:"#FFFFFF", margin:0,fontWeight:"normal"}}>€ <span style={{fontWeight:"bold"}}>{vid.prixU}</span> </h4>
                                                                    </div>
                                                                    <div style={{overflow:"hidden", width:"100%",height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                        <img style={{width:"100%", position:"absolute"}} src={`${vid.srvDoc}images/miniatures/${vid.idToken}.png`} alt={`${vid.fileName.split("##")[1]}`}/>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ):(
                                                        <>
                                                            <h4 style={{fontWeight:"normal"}}>Aucune vidéo disponible...</h4>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", flexDirection:"column"}}>
                                                <h3>Mon catalogue de NFT : </h3>
                                                <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                                    {this.state.videosPreviewVente.length !== 0 ? (
                                                        <>
                                                            {this.state.videosPreviewVente.map(vid=>(
                                                                <div onAuxClick={(e)=>{if(e.button===1){window.open("/BuyResale/Token?id="+vid.idToken+"&emp="+this.state.empToGet, "_blank")}}} onClick={()=>{this.props.navigate("/BuyResale/Token?id="+vid.idToken+"&emp="+this.state.empToGet)}} className={"divMyVideo"} style={{flexDirection:"column", background: "rgba(0,0,0,.5)",display:"flex", alignItems:"center",justifyContent:"flex-start", overflow:"hidden", border:!vid.verified && "2px solid #ff8100"}}>
                                                                    <div style={{width:'100%', position:"relative"}}>
                                                                        <div style={{height:31, textAlign:"center",position:"absolute", zIndex:999, width:"100%", background: "rgba(0,0,0,.6)"}}>
                                                                            <h4 style={{margin:5, textAlign:"center", color:"white"}} >{vid.titre.length>32 ? (vid.titre.slice(0,28)+"..."):(vid.titre)}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{position:"absolute",direction:"rtl", alignSelf:"flex-end", padding:2, zIndex:9999,borderRadius:10, width :"98%", marginBottom:10, top:175}}>
                                                                        <h4 className={"textShadowBorder"} style={{color:"#FFFFFF", margin:0,fontWeight:"normal"}}>€ <span style={{fontWeight:"bold"}}>{vid.prixU}</span> </h4>
                                                                    </div>
                                                                    <div style={{overflow:"hidden", width:"100%",height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                        <img style={{width:"100%", position:"absolute"}} src={`${vid.srvDoc}images/miniatures/${vid.idToken}.png`} alt={`${vid.fileName.split("##")[1]}`}/>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                    ):(
                                                        <>
                                                            <h4 style={{fontWeight:"normal"}}>Aucune vidéo disponible...</h4>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Profil)