import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {Button, Dialog, DialogContent, DialogTitle, TextField} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Axios from "axios";
import config from "../json/config.json";

import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let listeAdminFormat = ""

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded;

class IntegriteDuCodeSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email:'',
            emailBool:false,
            dialogueContactAdmin:false,
            connected:false,
            stateDrawer:true
        };
    }

    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt", {},
                {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if (response.data.resultat !== true){
                    this.props.navigate('/Administration/ConnexionAdmin')
                }
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                global.emitter.emit("onConnexionAdmin",{
                    entrepriseName:response.data.entrepriseName,
                    pseudo:jwtDecoded.pseudo
                })
                global.emitter.emit("onRouteChanged",{
                    pageName:'Email',
                    pseudo:jwtDecoded.pseudo,
                })
                this.setState({
                    email : jwtDecoded.email,
                    oldEmail : jwtDecoded.email
                })
                if (jwtDecoded.admin===true){
                    this.setState({
                        connected:true
                    }, ()=>{
                        this.getListeAdmin()
                    })
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Vous n'êtes pas autorisé à accéder à cette page",
                        icon: 'error',
                        target:".MuiDialog-root",
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        this.props.navigate("/Administration/AccueilAdmin")
                    })
                }
            })
        })
    }

    getListeAdmin(){
        listeAdminFormat = ""
        Axios.post(url+"getListeAdmin",{},
            {headers:{Authorization:"Bearer " + this.state.jwt}
        }).then((response)=>{
            response.data.listAdmin.forEach(email => {
                if(!listeAdminFormat.includes(email)){
                    listeAdminFormat += "<p style='margin: 0px'>"+email+"</p>"
                }
            })
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: 'Serveur injoignable. Veuillez réessayer plus tard.',
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    modifEmailBool(e){
        e.preventDefault()
        if (this.state.emailBool){
            if (this.state.email !== this.state.oldEmail){
                Axios.post(url+"modifEmail", {
                    email : this.state.email
                },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                    this.setState({oldEmail:this.state.email})
                    if(response.data.jwt){
                        sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === "Email modifié."){
                        Toast.fire({
                            icon:"success",
                            title:"Votre email a correctement été modifié."
                        })
                    }
                    setTimeout(()=>{this.getListeAdmin()},400)
                }).catch(()=>{
                    Toast.fire({
                        icon:"error",
                        title:"Echec de l'envoi de l'email. Veuillez réessayer plus tard."
                    })
                })
            }
        }
        this.setState({
            emailBool : !(this.state.emailBool)
        })
    }

    sendEmailAllAdmin(e){
        e.preventDefault();
        const data= new FormData(e.target);
        this.setState({
            openLoad:true
        })
        Axios.post(url+"contactAllAdmin", {
            message:data.get('champsMessage'),
            sujet:data.get('champsSujet'),
            emailSender:this.state.email,
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(() => {
            this.setState({openLoad:false})
            Toast.fire({
                icon:"success",
                title:"L'email va être envoyé dans les plus brefs délais."
            })
            this.handleCloseDialog()
        }).catch(() => {
            this.setState({openLoad:false})
            Toast.fire({
                icon:"error",
                title:"Echec de l'envoi de l'email. Veuillez réessayer plus tard."
            })
        });
    }

    openDialogueContactAdmin(){
        setTimeout(()=>{
            Swal.fire({
                title:"Liste des administrateurs et gestionnaires :",
                html: listeAdminFormat,
                confirmButtonText: 'Ok',
            }).then(()=>{
                this.setState({dialogueContactAdmin:true})
            })
        },100)
    }
    handleCloseDialog(){
        this.setState({dialogueContactAdmin:false})
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.dialogueContactAdmin}
                    onClose={()=>{this.handleCloseDialog()}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Contacter tous les admins et gestionnaires :</DialogTitle>
                    <form onSubmit={(e)=>{this.sendEmailAllAdmin(e)}} style={{display:"flex", flexDirection:"column", justifyItems:"center"}}>
                        <DialogContent>
                            <div className={"inputHelpDiv"}>
                                <TextField name={"champsSujet"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" required={true} label="Entrez votre sujet" variant="outlined" />
                            </div>
                            <div className={"inputHelpDiv"}>
                                <TextField name={"champsMessage"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} multiline={true} rows={6} rowsMax={10} id="outlined-basic champsPrenom" required={true} label="Entrez un message" variant="outlined" />
                            </div>
                        </DialogContent>
                        <Button style={{margin:"10px"}} variant={"outlined"} type={"submit"} color="primary">Envoyer</Button>
                    </form>
                </Dialog>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto",padding:20,backgroundColor:"white",display:"flex", flexDirection:"column",alignItems:"center", justifyContent:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center", padding:10}}>

                            <h3>Modifier mon email :</h3>
                            <form onSubmit={(e)=>{this.modifEmailBool(e)}} style={{display:"flex"}}>
                                <TextField style={{minWidth:300}} variant={"outlined"} label={"Email"} value={this.state.email} type={"email"} name={'email'} onChange={(e)=>this.handleChange(e)} disabled={!this.state.emailBool}/>
                                <Button type={"submit"}>
                                    {this.state.emailBool ? (
                                        <CheckIcon/>
                                    ):(
                                        <EditIcon/>
                                    )}
                                </Button>
                            </form>
                        </div>
                        {this.state.connected && (
                            <Button onClick={()=>{this.openDialogueContactAdmin()}}>Contacter tous les administrateurs</Button>
                        )}
                    </div>
                </div>
            </div>


        );
    }
}

export default withRouter(IntegriteDuCodeSource)