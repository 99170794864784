import React, {Component} from 'react';
import {ContentState, convertFromHTML, convertToRaw, EditorState} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import sanitizeHtml from 'sanitize-html';
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import {withRouter} from '../shared/NavigationUtils';
import {isBrowser} from "react-device-detect";
import {Check, Edit, Visibility, VisibilityOff} from '@mui/icons-material';
import Axios from "axios";
import config from "../json/config.json";

import Swal from "sweetalert2";
import Dropzone from "react-dropzone";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let columns = [
    { id: 'Parametre', label: 'Paramètre', minWidth: 300 },
    { id: 'Valeur', label: 'Valeur', minWidth: 150 },
    { id: 'Action', label: 'Action', width: 30 },
];

let jwtDecoded;

class Parametres extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDrawer : false,
            parametres: {},
            smtp:{},
            fileNamelogo:null,
            fileNamepub:null,
            fileNamelogoEtude:null,
            binarylogo:null,
            binarypub:null,
            binarylogoEtude:null,
            oldPassword:"",
            editorState: EditorState.createEmpty(),
            signatureHTML:"",
            uploadedImages:[],
            stateDrawer:true
        };
    }

    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt", {},
                {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat !== true){
                    this.props.navigate('/Administration/ConnexionAdmin')
                }else{
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:jwtDecoded.pseudo
                    })
                    global.emitter.emit("onRouteChanged",{
                        pageName:'Paramètres',
                        pseudo:jwtDecoded.pseudo,
                    })
                    if (jwtDecoded.admin===true){
                        this.getAxiosListParam()
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Vous n'êtes pas autorisé à accéder à cette page",
                            icon: 'error',
                            target:".MuiDialog-root",
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/Administration/AccueilAdmin")
                        })
                    }
                }
            })
        })
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }else{
            columns = [
                { id: 'Parametre', label: 'Paramètre', width: 100 },
                { id: 'Valeur', label: 'Valeur', width: 20 },
                { id: 'Action', label: 'Action', width: 10 },
            ];
        }
    }

    getAxiosListParam(){
        Axios.post(url+"getParametres",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat[0].Signature){
                let blocksFromHTML = convertFromHTML(response.data.resultat[0].Signature);
                const stateForEditor = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap,
                );
                this.setState({
                    editorState:EditorState.createWithContent(stateForEditor),
                    signaturePureHTML:sanitizeHtml(response.data.resultat[0].Signature)
                })
            }

            this.setState({
                parametres:response.data.resultat[0],
                smtp: response.data.resultat[1],
            },()=>{
                Object.entries(response.data.resultat[0]).forEach(elt=>{
                    this.setState({[elt[0]+"bool"]:false})
                })
                Object.entries(response.data.resultat[1]).forEach(elt=>{
                    this.setState({[elt[0]+"bool"]:false})
                })
            })
        }).catch((err)=>{
            console.log(err)
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeParam(event){
        let tempParam = this.state.parametres
        tempParam[event.currentTarget.name] = event.currentTarget.value
        this.setState({
            parametres:tempParam
        })
    }

    handleChangeSMTP(event){
        let tempSMTP = this.state.smtp
        tempSMTP[event.currentTarget.name] = event.currentTarget.value
        this.setState({
            smtp:tempSMTP
        })
    }

    handleChangeSelect(event){
        this.setState({
            [event.target.name]:event.target.value
        })
    }

    onEditorStateChange(editorState,param){
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        let markup = draftToHtml(rawContentState);
        let paramTemp = this.state.parametres

        paramTemp[param] = markup.replaceAll(`"`,`'`)
        this.setState({
            editorState,
            parametres : paramTemp
        });
    };

    modifValueParam(event){
        event.preventDefault()
        let name = event.currentTarget.name;
        this.setState({[name+"bool"]:!this.state[name+"bool"]})

        if (this.state[name+"bool"] === true){ //Enregistrer dans le bon fichier
            if(name+"bool" === "Logo KageDocbool"){
                this.sendLogo("Logo KageDoc")
            }else if(name+"bool" === "Fichier de pubbool"){
                this.sendLogo("Fichier de pub")
            }else if(name+"bool" === "Logo de l'étudebool"){
                this.sendLogo("Logo de l'étude")
            }else{
                this.sendParam()
            }
        }
    }

    modifValueSMTP(event){
        event.preventDefault()
        let name = event.currentTarget.name;
        this.setState({[name+"bool"]:!this.state[name+"bool"]})

        if (this.state[name+"bool"] === true){ //Enregistrer dans le bon fichier
            this.sendSMTP()
        }
    }

    sendParam(){
        let signature =  this.state.parametres["Signature"], params = this.state.parametres
        signature = signature.replaceAll("</p>","")
        signature = signature.replaceAll("<p>","<br>")
        signature = signature.replace("<br>","<p>")
        signature = signature + "</p>"

        //Ajoute www. dans les liens si besoin
        let editSign = signature.split("https://")
        for (let i = 1; i < editSign.length; i++){
            if (editSign[i].substring(0,4) !== "www."){
                editSign[i] = "www."+editSign[i]
            }
        }
        signature = editSign.join("https://")
        editSign = signature.split("http://")
        for (let i = 1; i < editSign.length; i++){
            if (editSign[i].substring(0,4) !== "www."){
                editSign[i] = "www."+editSign[i]
            }
        }
        signature = editSign.join("http://")

        params["Signature"] = signature
        this.setState({parametres:params}, ()=>{
            Axios.post(url+"modifParametres", {
                verifMdpAdmin:this.state.oldPassword,
                parametres:this.state.parametres,
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                global.emitter.emit("onConnexionAdmin",{
                    entrepriseName:this.state.parametres.nomEntreprise,
                    pseudo:jwtDecoded.pseudo,
                })

                if(response.data.resultat === "Mauvaise confirmation."){
                    Swal.fire({
                        text: "L'ancien mot de passe administrateur est erroné.",
                        icon: 'error',
                        confirmButtonText: "Ok"
                    })
                }else{

                    let blocksFromHTML = convertFromHTML(signature);
                    const stateForEditor = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap,
                    );
                    this.setState({
                        editorState:EditorState.createWithContent(stateForEditor),
                        signaturePureHTML:sanitizeHtml(signature)
                    })

                    Toast.fire({
                        icon: 'success',
                        title: 'Paramètre modifié.'
                    })
                }
            }).catch((err)=>{
                console.log(err)
            })
        })

    }

    sendSMTP(){
        Axios.post(url+"modifSMTP", {
            verifMdpSMTP:this.state.oldPasswordSMTP,
            smtp:this.state.smtp,
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "Mauvaise confirmation."){
                Swal.fire({
                    text: "L'ancien mot de passe SMTP est erroné.",
                    icon: 'error',
                    confirmButtonText: "Ok"
                })
            }else{
                Toast.fire({
                    icon: 'success',
                    title: 'Paramètre modifié.'
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    sendLogo(edited){
        let tempParam = this.state.parametres, newFile, fileName;
        if(this.state[`fileName${edited}`] !== tempParam[edited] && !!this.state[`fileName${edited}`]){
            tempParam[edited] = this.state[`fileName${edited}`]
            newFile = this.state[`binary${edited}`]
            if (edited === "Logo KageDoc"){
                fileName = "logo."+this.state[`fileName${edited}`].split(".")[1]
            }else if (edited === "Fichier de pub"){
                fileName = "pub."+this.state[`fileName${edited}`].split(".")[1]
            }else if (edited === "Logo de l'étude"){
                fileName = "logoEtude."+this.state[`fileName${edited}`].split(".")[1]
            }

            this.setState({
                parametres:tempParam
            })

            Axios.post(url+"modifLogo", {
                newLogo:newFile,
                parametres:tempParam,
                fileName:fileName,
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                Toast.fire({
                    icon: 'success',
                    title: 'Modifié avec succès.'
                })
            }).catch((err)=>{
                console.log(err)
            })
        }
    }

    openImage(param,extension){
        if (param === "Logo KageDoc"){
            window.open(url+'images/logo.'+extension, "_blank")
        }else if(param === "Fichier de pub"){
            window.open(url+'images/pub.'+extension, "_blank")
        }else if(param === "Logo de l'étude"){
            window.open(url+'images/logoEtude.'+extension, "_blank")
        }
    }

    render() { //Affichage sur la page
        const { classes } = this.props;
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto",height:"70vh",minHeight:"300px",backgroundColor:"white",display:"flex",flexDirection:"column",alignContent:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <div style={{padding:40}}>
                            <h4 style={{marginTop:0, paddingTop:0}}>Paramètres généraux</h4>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    style={{ minWidth: column.minWidth, width:column.width,maxWidth:column.maxWidth, fontWeight:"bold" }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(Object.entries(this.state.parametres)).map(param=>{
                                            return(
                                                <TableRow key={param[0]}>
                                                    {columns.map(column=>{
                                                        return(
                                                            <TableCell key={column.id}>
                                                                {/*Si c'est la colonne du bouton action*/}
                                                                {column.id === 'Action' ? (
                                                                    <Button style={{color:"black"}} onClick={(event)=>{this.modifValueParam(event)}} name={param[0]}>{this.state[param[0]+'bool'] ? (<Check/>):(<Edit/>)}</Button>
                                                                ) : (
                                                                    <>
                                                                        {column.id === "Parametre" ? (
                                                                            <p>{param[0]}</p>
                                                                        ):(
                                                                            <>
                                                                                {this.state[param[0]+"bool"] ? (
                                                                                    <>
                                                                                        {param[0] === "Mot de passe administrateur" ? (
                                                                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                                                                <TextField style={{margin:10}} type={this.state.showPassword2 ? "text":"password"} name={"oldPassword"} variant="outlined" value={this.state.value} label={"Ancien mot de passe"} onChange={(e)=>{this.handleChange(e)}}
                                                                                                           InputProps={{
                                                                                                               endAdornment:
                                                                                                                   <InputAdornment position="end">
                                                                                                                       <IconButton
                                                                                                                           aria-label="toggle password visibility"
                                                                                                                           onClick={()=>{this.setState({showPassword2:!this.state.showPassword2})}}
                                                                                                                       >
                                                                                                                           {this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                                                                                                       </IconButton>
                                                                                                                   </InputAdornment>
                                                                                                           }}
                                                                                                />
                                                                                                <TextField style={{margin:10}} type={this.state.showPassword ? "text":"password"} name={param[0]} variant="outlined" defaultValue={""} value={this.state.value} label={"Nouveau mot de passe"} onChange={(e)=>{this.handleChangeParam(e)}}
                                                                                                           InputProps={{
                                                                                                               endAdornment:
                                                                                                                   <InputAdornment position="end">
                                                                                                                       <IconButton
                                                                                                                           aria-label="toggle password visibility"
                                                                                                                           onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                                                                                       >
                                                                                                                           {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                                                                                       </IconButton>
                                                                                                                   </InputAdornment>
                                                                                                           }}
                                                                                                />
                                                                                            </div>
                                                                                        ):(
                                                                                            <>
                                                                                                {(param[0] === "Logo KageDoc" || param[0] === "Fichier de pub" || param[0] === "Logo de l'étude") ? (
                                                                                                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                                                        <Dropzone multiple={false} maxFiles={1} onDrop={(acceptedFiles) => {
                                                                                                            acceptedFiles.forEach((file) => {
                                                                                                                const reader = new FileReader()
                                                                                                                reader.onload = () => {
                                                                                                                    const binaryStr = reader.result
                                                                                                                    this.setState({
                                                                                                                        ["fileName"+param[0]]:file.name,
                                                                                                                        ["binary"+param[0]]:Buffer.from(binaryStr,"base64"),
                                                                                                                    })
                                                                                                                }
                                                                                                                reader.readAsArrayBuffer(file)
                                                                                                            })
                                                                                                        }}>
                                                                                                            {({getRootProps, getInputProps}) => (
                                                                                                                <section>
                                                                                                                    <div {...getRootProps()}>
                                                                                                                        <input {...getInputProps()} />
                                                                                                                        {this.state["fileName"+param[0]] ? (
                                                                                                                            <>
                                                                                                                                <p style={{cursor:"pointer"}}>Fichier choisi : <span style={{fontWeight:"bold"}}>{this.state["fileName"+param[0]]}</span></p>

                                                                                                                            </>
                                                                                                                        ):(
                                                                                                                            <>
                                                                                                                                <Button style={{cursor:"pointer", textTransform:"none"}} variant={"outlined"}>Modifiez votre fichier ici.</Button>
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                </section>
                                                                                                            )}
                                                                                                        </Dropzone>
                                                                                                    </div>
                                                                                                ):(
                                                                                                    <>
                                                                                                        {param[0] === "Pièces jointes par défaut" ? (
                                                                                                            <FormControl>
                                                                                                                <Select
                                                                                                                    value={this.state["Pièces jointes par défaut"] || param[1]}
                                                                                                                    onChange={(event)=>{
                                                                                                                        let tempParam = this.state.parametres
                                                                                                                        tempParam["Pièces jointes par défaut"] = event.target.value
                                                                                                                        this.setState({
                                                                                                                            parametres:tempParam
                                                                                                                        })
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <MenuItem name={"Pièces jointes par défaut"} value={"Logo KageDoc et Logo de l'étude"}>Logo KageDoc et Logo de l'étude</MenuItem>
                                                                                                                    <MenuItem name={"Pièces jointes par défaut"} value={"Logo KageDoc uniquement"}>Logo KageDoc uniquement</MenuItem>
                                                                                                                </Select>
                                                                                                            </FormControl>
                                                                                                        ):(
                                                                                                            <>
                                                                                                                {param[0] === "Signature" ? (
                                                                                                                    <div style={{border:"1px solid black"}}>
                                                                                                                        <Editor
                                                                                                                            editorState={this.state.editorState}
                                                                                                                            toolbarClassName="toolbarClassName"
                                                                                                                            wrapperClassName="wrapperClassName"
                                                                                                                            editorClassName="editorClassName"
                                                                                                                            onEditorStateChange={(e)=>{this.onEditorStateChange(e,param[0])}}
                                                                                                                            localization={{
                                                                                                                                locale: 'fr',
                                                                                                                            }}
                                                                                                                            toolbar={{
                                                                                                                                options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link'],
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                ):(
                                                                                                                    <TextField name={param[0]} variant="outlined" value={this.state.parametres[param[0]]} onChange={(e)=>{this.handleChangeParam(e)}}/>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                ):(
                                                                                    <>
                                                                                        {param[0] === "Mot de passe administrateur" ? (
                                                                                            <p>●●●●●●●●●●●●●●●●●●●●</p>
                                                                                        ):(
                                                                                            <>
                                                                                                {(param[0] === "Logo KageDoc" || param[0] === "Fichier de pub" || param[0] === "Logo de l'étude") ? (
                                                                                                    <Tooltip title={"Cliquez pour prévisualiser"}>
                                                                                                        <p onClick={()=>{this.openImage(param[0], param[1].split(".")[1])}} style={{cursor:"pointer"}}>{param[1]}</p>
                                                                                                    </Tooltip>
                                                                                                ):(
                                                                                                    <>
                                                                                                        {param[0] === "Signature" ? (
                                                                                                            <div dangerouslySetInnerHTML={{ __html: this.state.signaturePureHTML }} />
                                                                                                        ):(
                                                                                                            <p>{param[1]}</p>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>

                                                                                )}
                                                                            </>

                                                                        )}
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                {!Object.keys(this.state.parametres).length>0 && (
                                    <div style={{width:"100%"}}>
                                        <Skeleton style={{margin:20,marginTop:40}}/>
                                        <Skeleton style={{margin:20}} />
                                        <Skeleton style={{margin:20}} />
                                    </div>
                                )}
                            </TableContainer>
                        </div>
                        <div style={{padding:40}}>
                            <h4 style={{marginTop:0, paddingTop:0}}>Compte SMTP</h4>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column1) => (
                                                <TableCell
                                                    key={column1.id}
                                                    align={column1.align}
                                                    style={{ minWidth: column1.minWidth, width:column1.width, fontWeight:"bold" }}
                                                >
                                                    {column1.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(Object.entries(this.state.smtp)).map(smtp=>{
                                            return(
                                                <TableRow key={smtp[0]}>
                                                    {columns.map(column=>{
                                                        return(
                                                            <TableCell key={column.id}>
                                                                {column.id === 'Action' ? (
                                                                    <Button style={{color:"black"}} onClick={(event)=>{this.modifValueSMTP(event)}} name={smtp[0]}>{this.state[smtp[0]+'bool'] ? (<Check/>):(<Edit/>)}</Button>
                                                                ) : (
                                                                    <>
                                                                        {column.id === "Parametre" ? (
                                                                            <p>{smtp[0]}</p>
                                                                        ):(
                                                                            <>
                                                                                {this.state[smtp[0]+"bool"] ? (
                                                                                    <>
                                                                                        {smtp[0] === "Mot de passe" ? (
                                                                                            <div style={{display:"flex",flexDirection:"column"}}>
                                                                                                <TextField style={{margin:10}} autoComplete={false} type={this.state.showPasswordSMTP2 ? "text":"password"} name={"oldPasswordSMTP"} variant="outlined" value={this.state.value} label={"Ancien mot de passe"} onChange={(e)=>{this.handleChange(e)}}
                                                                                                           InputProps={{
                                                                                                               endAdornment:
                                                                                                                   <InputAdornment position="end">
                                                                                                                       <IconButton
                                                                                                                           aria-label="toggle password visibility"
                                                                                                                           onClick={()=>{this.setState({showPasswordSMTP2:!this.state.showPasswordSMTP2})}}
                                                                                                                       >
                                                                                                                           {this.state.showPasswordSMTP2 ? <Visibility /> : <VisibilityOff />}
                                                                                                                       </IconButton>
                                                                                                                   </InputAdornment>
                                                                                                           }}
                                                                                                />
                                                                                                <TextField style={{margin:10}} autoComplete={false} type={this.state.showPasswordSMTP ? "text":"password"} name={smtp[0]} variant="outlined" defaultValue={""} value={this.state.value} label={"Nouveau mot de passe"} onChange={(e)=>{this.handleChangeSMTP(e)}}
                                                                                                           InputProps={{
                                                                                                               endAdornment:
                                                                                                                   <InputAdornment position="end">
                                                                                                                       <IconButton
                                                                                                                           aria-label="toggle password visibility"
                                                                                                                           onClick={()=>{this.setState({showPasswordSMTP:!this.state.showPasswordSMTP})}}
                                                                                                                       >
                                                                                                                           {this.state.showPasswordSMTP ? <Visibility /> : <VisibilityOff />}
                                                                                                                       </IconButton>
                                                                                                                   </InputAdornment>
                                                                                                           }}
                                                                                                />
                                                                                            </div>
                                                                                        ):(
                                                                                            <TextField name={smtp[0]} variant="outlined" value={this.state.smtp[smtp[0]]} onChange={(e)=>{this.handleChangeSMTP(e)}}/>
                                                                                        )}
                                                                                    </>
                                                                                ):(
                                                                                    <>
                                                                                        {smtp[0] === "Mot de passe" ? (
                                                                                            <p>●●●●●●●●●●●●●●●●●●●●</p>
                                                                                        ):(
                                                                                            <p>{smtp[1]}</p>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>

                                                                        )}
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>

                                </Table>
                                {!Object.keys(this.state.smtp).length>0 && (
                                    <div style={{width:"100%"}}>
                                        <Skeleton style={{margin:20,marginTop:40}}/>
                                        <Skeleton style={{margin:20}} />
                                        <Skeleton style={{margin:20}} />
                                    </div>
                                )}
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Parametres)