import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {ArrowCircleDown, KeyboardReturn} from "@mui/icons-material";

let listFAQ = ["Qu'est ce qu'un STO ?","Notre place de marché","Comment créer une levée de fonds","Comment acheter des actions","Comment vendre ses actions"]


class FAQSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{
            selectedQuestion:""
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if (document.body.offsetWidth <= 950) {
            this.setState({showBrowserView: false})
        }
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <div style={{width:"fit-content",display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                    <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Frequently Asked Questions :</h1>
                                    <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:"100%", height:3, borderRadius:100, marginBottom:10}}></div>
                                </div>
                                <div className={"flexCenter"}>
                                    <button style={{padding:8, fontSize:14, marginRight:10}} onClick={()=>{this.props.navigate(-1)}} className={"divButtonBuyResale flexCenter"}><KeyboardReturn style={{marginRight:10, color:"#FFFFFF"}}/> Retour</button>
                                </div>
                            </div>
                            <div style={{width:"80%",display:"flex" ,alignItems:"flex-start", justifyContent:"flex-start", flexWrap:"wrap", marginTop:20}}>
                                <div className={"flexCenter"} style={{flex:1, flexDirection:"column", alignItems:"flex-start"}}>
                                    <h3>Général</h3>
                                    <h3>Profil</h3>
                                    <h3>Levées de fonds</h3>
                                    <h3>Transactions</h3>
                                </div>
                                <div style={{flex:2,flexDirection:"column",display:"flex" ,alignItems:"flex-start", justifyContent:"flex-start"}}>
                                    {listFAQ.map(elt=>(
                                        <div style={{flexDirection:"column",display:"flex" ,alignItems:"flex-start", justifyContent:"flex-start", marginBottom:5}}>
                                            <div style={{userSelect: "none"}} onClick={(e)=>{this.setState({selectedQuestion:this.state.selectedQuestion === e.target.id ? "":e.target.id})}} className={"flexCenter"}>
                                                <h3 id={elt} className={"titleFAQ"}>{elt}</h3>
                                                <ArrowCircleDown id={elt} style={{marginLeft:5, cursor:"pointer", color:"#73BDECFF", transform:this.state.selectedQuestion === elt ? "rotate(-180deg)":"rotate(0deg)",transition: "all .8s cubic-bezier(.25,.8,.25,1)"}}/>
                                            </div>
                                            <div style={{width:"100%", height:"100%",opacity:this.state.selectedQuestion === elt ? 1:0,maxHeight:this.state.selectedQuestion === elt ? 200:0, overflow:"hidden", transition:"all .8s cubic-bezier(.25,.8,.25,1)"}}>
                                                {elt === "Qu'est ce qu'un STO ?"&&(
                                                    <>
                                                        Qu'est ce qu'un STO ?
                                                    </>
                                                )}
                                                {elt === "Notre place de marché"&&(
                                                    <>
                                                        Notre place de marché
                                                    </>
                                                )}
                                                {elt === "Comment créer une levée de fonds"&&(
                                                    <>
                                                        Comment créer une levée de fonds
                                                    </>
                                                )}
                                                {elt === "Comment acheter des actions"&&(
                                                    <>
                                                        Comment acheter des actions
                                                    </>
                                                )}
                                                {elt === "Comment vendre ses actions"&&(
                                                    <>
                                                        Comment vendre ses actions
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FAQSTO)