import React, {Component} from "react";
import {Link} from "react-router-dom";
import logoSpuroSolo from "../../assets/logoSpuroSolo.png";

class LogoSpuro extends Component {

    render(){ //Affichage sur la page
        return (
            <Link className={"flexCenter"} to={"/Administration/AccueilAdmin"} style={{width:300, position:"absolute", left:-30,textTransform:"none", textDecoration:"none"}}>
                <img style={{width:60}} src={logoSpuroSolo} alt="logoSpuro"/>
                <div style={{display:"flex",justifyContent:"center",alignItems:"flex-start",flexDirection:"column"}}>
                    <p style={{fontFamily:"arial", color:"#FFFFFF",fontWeight:"bold",fontSize:30,margin:0}}>Spuro</p>
                    <p style={{fontFamily:"arial", color:"#F3B02A",fontSize:17,margin:0,marginLeft:-10}}>Blockchain Platform</p>
                </div>
            </Link>
        );
    }
}
export default LogoSpuro