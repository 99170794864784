import React, {Component} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Radio,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {withRouter} from '../../../shared/NavigationUtils';
import MenuIcon from "@mui/icons-material/Menu";
import {isMobile} from "react-device-detect";
import Axios from "axios";
import config from "../../../json/config.json";

import tooltipContenu from "../../../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {sha256} from "js-sha256";
import Swal from "sweetalert2";
import DescriptionIcon from '@mui/icons-material/Description';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {BusinessCenter, MailLock, TaskAlt} from "@mui/icons-material";
import FolderIcon from '@mui/icons-material/Folder';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HelpCenter from "../../../Components/HelpCenter";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const allAcceptedPath = [
    "/kagesecur/accueil", "/kagesecur/sansvalidation", "/kagesecur/avecvalidation", "/kagesecur/verification",
    "/kagesecur/tracabilite", "/kagesecur/signaturecollaborative", "/kagesecur/miseendemeure", "/kagesecur/tableaudebord",
    "/kagesecur/listedesclients","/kagesecur/gestiondesapprobateurs", "/kagesecur/inscriptionclientkageSecur",
    "/kagesecur/preuveviamail","/kagesecur/listezonetemporaire","/kagesecur/sekuremail","/kagesecur/sekuremailmessage"
]

class SharedHeaderKageSecur extends Component {

    constructor(props) {
        super(props);
        this.state={
            openMenuEnregistrement:"none",
            openMenuAnalyse:"none",
            openMenuGestion:"none",
            openMenuAccount:"none",
            openMenuCollabo:"none",
            user:"none",
            displayConnexion:false,
            openDialogueModifEmail:false,
            openDialogueAjoutClient:false,
            champsIdentifiant:"",
            champsClef:"",
            champsNewEmail:"",
            displayed:false,
            openDialogueInvalidationCompte:false,
            displayMenuMobile:false,
            showBrowserView:true,
            freeClient:true,
            relance:false,
            readyToClick:true,
            sekurEmailClient:false,
            jwtDecoded:{},
        };
    }

    _onRouteChangedKageSecur;
    _askConnection;
    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentDidMount() {
        let jwt = sessionStorage.getItem("KAGESECUR_jwt")
        if(jwt){
            let jwtDecoded = jwt_decode(jwt)
            this.setState({jwtDecoded})
        }
        const queryParams = new URLSearchParams(window.location.search);
        let oc = queryParams.get("oc") //OpenConnexion = true ? ouvre dialog de connexion
        if(oc){
            this.setState({
                displayConnexion:true
            })
        }
        if(isMobile || document.body.offsetWidth <= 1150){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this._onRouteChangedKageSecur = global.emitter.addListener("onRouteChangedKageSecur",(data)=>{
            this.setState({
                user:data.user,
                pseudo:data.pseudo
            })
        })

        this._askConnection = global.emitter.addListener("askConnection",()=>{
            this.setState({
                displayConnexion:true
            })
        })

    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    
    componentWillUnmount(){
        if(this._onRouteChangedKageSecur){this._onRouteChangedKageSecur.remove()}
        window.removeEventListener('resize', this.resizePage)
    }

    decoFunction(){
        this.setState({user:"none",displayMenuMobile:false, readyToClick:true})
        sessionStorage.removeItem("KAGESECUR_jwt")
        Toast.fire({
            icon: 'success',
            title: 'Déconnexion.'
        })
        this.props.navigate("/KageSecur/Accueil")
    }

    handleConnexion(e){
        // console.log("here")
        this.setState({showPassword:false})
        e.preventDefault()
        let empreinte = sha256((this.state.champsIdentifiant.trim()).concat(this.state.champsClef.trim()));
        Axios.post(url+"connexionKS",{
            empreinte:empreinte,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt,
                    jwtDecoded:jwt_decode(response.data.jwt)
                })
            }
            this.setState({displayConnexion:false},()=>{
                if (response.data.resultat === "Connecté"){
                    this.setState({
                        user:response.data.gestionnaire ? "gestionnaire" : "client",
                        openMenuAccount:"none",
                        pseudo:this.state.champsIdentifiant,
                    })
                    if (response.data.freeClient){
                        if (window.location.pathname.toLowerCase() !== "/kagesecur/tableaudebord" && window.location.pathname.toLowerCase() !== "/kagesecur/signaturecollaborative" && window.location.pathname.toLowerCase() !== "/kagesecur/accueil"  && window.location.pathname.toLowerCase() !== "/kagesecur/listezonetemporaire" && window.location.pathname.toLowerCase() !== "/kagesecur/preuveviamail"){
                            Swal.fire({
                                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                                icon: 'warning',
                                confirmButtonText: 'Ok'
                            }).then(()=>{
                                this.props.navigate("/KageSecur/Accueil", { replace: true })
                            })
                        }
                    }else{
                        Toast.fire({
                            icon: 'success',
                            title: 'Connecté au compte "'+this.state.champsIdentifiant+'"'
                        })
                    }
                    global.emitter.emit("emitConnected")
                }else{
                    Swal.fire({
                        title: 'Utilisateur introuvable.',
                        text: "Cette combinaison d'identifiant et de clef unique n'existe pas.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{this.setState({displayConnexion:true,readyToClick:true})})
                }
            })
        }).catch(()=>{
            this.setState({displayConnexion:false},()=> {
                Swal.fire({
                    title: 'Erreur!',
                    text: "Serveur injoignable.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
        })
    }

    changeEmail(e){
        e.preventDefault()
        Axios.post(url+"modifEmail", {
            hash : sha256(this.state.champsIdentifiant.trim().concat(this.state.champsClef.trim())),
            email : this.state.champsNewEmail,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "Email modifié."){
                Toast.fire({
                    icon:"success",
                    title:"Votre email a correctement été modifié."
                })
                this.setState({
                    champsIdentifiant:"",
                    champsClef:"",
                    champsNewEmail:"",
                    openDialogueModifEmail:false
                })
            }else{
                Toast.fire({
                    icon:"error",
                    title:response.data.resultat,
                    target:".MuiDialog-root",
                })
            }
        }).catch(()=>{
            Toast.fire({
                icon:"error",
                title:"Echec de l'envoi de l'email. Veuillez réessayer plus tard."
            })
        })
    }

    invalidateAccount(e){
        e.preventDefault()
        if(this.state.champsIdentifiant === this.state.pseudo){
            let verifBool = false
            this.setState({openDialogueInvalidationCompte:false})
            Swal.fire({
                title: 'Attention!',
                text: "Veuillez saisir le mot 'invalider' pour confirmer l'invalidation de votre compte.",
                icon: 'info',
                input: 'text',
                inputAttributes: {
                    autocapitalize: 'off'
                },
                confirmButtonText: "Oui j'invalide",
                confirmButtonColor:"red",
                showCancelButton:true,
                cancelButtonText:"Annuler",
                cancelButtonColor:"#369DD7",
                preConfirm: (value) => {
                    if (value && value === "invalider") {
                        verifBool = true
                    }else{
                        Toast.fire({
                            icon: "error",
                            title: "Vérification incorrecte."
                        })
                    }
                }
            }).then((result)=>{
                if(result.isConfirmed && verifBool){
                    Axios.post(url+"deleteAccount", {
                        empreinte : sha256(this.state.champsIdentifiant.concat(this.state.champsClef)),
                    },{headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).then((response)=>{
                        if(response.data.jwt){
                            sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                            this.setState({
                                jwt:response.data.jwt
                            })
                        }
                        if(response.data.resultat !== "Ce compte a bien été invalidé."){
                            Swal.fire({
                                title: 'Erreur!',
                                text: response.data.resultat,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }else{
                            Swal.fire({
                                title: 'Succès!',
                                text: 'Vous disposez de 30 jours afin de récupérer vos document.',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            })
                        }
                    }).catch(()=>{
                        Toast.fire({
                            icon:"error",
                            title:"Le serveur ne réponds pas."
                        })
                    })
                }
            })
        }else{
            this.setState({openDialogueInvalidationCompte:false})
            Swal.fire({
                title: 'Erreur!',
                text: "Votre identifiant ne correspond pas à votre compte.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }

    }

    addClient(e){
        e.preventDefault()
        Axios.post(url+"checkEmail", {
            email : this.state.champsNewEmail,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).then(response =>{
            this.setState({openDialogueAjoutClient:false}, ()=> {
                new Promise(async resolve => {
                    if (response.data.exist){
                        Swal.fire({
                            title: "Un client possède déjà cet email, voulez-vous quand même l'ajouter ?",
                            showDenyButton: true,
                            showLoaderOnConfirm: true,
                            denyButtonColor: '#787878',
                            confirmButtonText: 'Ajouter',
                            denyButtonText: `Annuler`,
                            customClass: 'swal-offset-top',
                        }).then(inputValue => {
                            resolve(inputValue.isConfirmed)
                        })
                    }else{
                        resolve(true)
                    }
                }).then(keepGoing=>{
                    if (keepGoing){
                        const urlToAsk = url+(this.state.sekurEmailClient ? "emailToSekurEmailClient":"emailToNewClient")
                        Axios.post(urlToAsk, {
                            email : this.state.champsNewEmail,
                            pseudoGestionnaire:this.state.pseudo,
                            free: this.state.freeClient,
                            relance: this.state.relance,
                        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).then((response)=>{
                            if(response.data.jwt){
                                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                                this.setState({
                                    jwt:response.data.jwt
                                })
                            }
                            let link = response.data.link
                            this.setState({freeClient:true, relance:false, link},()=>{
                                Swal.fire({
                                    title: 'Succès!',
                                    html:
                                        '<p style="margin-bottom: 0px; display: flex;align-items: flex-start;font-weight: normal; justify-content: center" >Email correctement envoyé</p>' +
                                        '<button disabled type="button" style="cursor: default; margin-bottom:0px; margin-top: 10px" id="buttonCopyClipboard" class="swal2-confirm swal2-styled">Chargement...</button>',
                                    didRender: () => {
                                        setTimeout(()=>{
                                            let button = document.getElementById('buttonCopyClipboard');
                                            button.textContent = 'Copier le lien manuellement 📋';
                                            button.disabled=false
                                            button.style.cursor="pointer"
                                            button.onclick = () => {
                                                navigator.clipboard.writeText(this.state.link);
                                                button.textContent = 'Lien copié ✔';
                                                button.style.backgroundColor = '#4BB543FF';
                                            };
                                        },500)
                                    },
                                    icon: 'success',
                                    showConfirmButton: false,
                                });
                            })
                        })
                    }else{
                        this.setState({freeClient:true, relance:false})
                        Toast.fire({
                            icon: 'warning',
                            title: "Annulation d'invitation de client"
                        })
                    }
                })
            })
        })
    }

    goToPage(clickedPage){
        window.location.pathname !== clickedPage ? this.props.navigate(clickedPage):window.location.reload()
        this.setState({displayMenuMobile:false})
    }

    render() {
        
        return(
            <>
                <div style={{backgroundColor:"#FFFFFF", width:"99.5%",height:"99.5vh", position:"absolute", display:this.state.displayMenuMobile ? "flex":"none" , zIndex:9999}}>
                    <div style={{marginTop:100, width:"100%",zIndex:99999}}>
                        {!(this.state.jwtDecoded.freeClient && this.state.jwtDecoded.sekurEmail) && (
                            <>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%',transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                            <DescriptionIcon/>
                                            <Typography style={{marginLeft:15,fontWeight:"bold"}}>Enregistrement d'un document</Typography>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                            <p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/KageSecur/SansValidation')}}>Avec votre signature</p>
                                            <p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/KageSecur/AvecValidation')}}>Avec signature d'un tiers</p>
                                            {/*<p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/KageSecur/MiseEnDemeure')}}>Mise en demeure</p>*/}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                            </>
                        )}
                        <p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/TableauDeBord")}}> <FolderIcon style={{marginLeft:8, marginRight:14}}/>Tableau de bord</p>
                        {!(this.state.jwtDecoded.freeClient && this.state.jwtDecoded.sekurEmail) && (
                            <>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%',transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                            <VisibilityIcon/>
                                            <Typography style={{marginLeft:15,fontWeight:"bold"}}>Analyse d'un document</Typography>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                            <p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/KageSecur/Verification')}}>Vérification</p>
                                            <p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/KageSecur/Tracabilite')}}>Traçabilité</p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                                <p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/SignatureCollaborative")}}> <TaskAlt style={{marginLeft:8, marginRight:14}}/> Signature</p>
                            </>
                        )}
                        {this.state.user === "gestionnaire" ? (
                            <>
                                <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                                <p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/SekurEmail")}}> <MailLock style={{marginLeft:8, marginRight:14}}/> SekurEmail</p>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%',transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                            <BusinessCenter/>
                                            <Typography style={{marginLeft:15,fontWeight:"bold"}}>Gestion</Typography>
                                        </div>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                            <p className={'buttonDrawerAdmin'} onClick={()=>{this.setState({openDialogueAjoutClient:true,displayMenuMobile:false})}} style={{padding:10}}>Ajouter un client</p>
                                            <p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage("/KageSecur/ListeDesClients")}} style={{padding:10}}>Liste des clients</p>
                                            {/*<p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage("/KageSecur/GestionDesApprobateurs")}} style={{padding:10}}>Gérer les signataires</p>*/}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ):(
                            <>
                                <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                                <p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/SekurEmail")}}> <MailLock style={{marginLeft:8, marginRight:14}}/> SekurEmail</p>
                            </>
                        )
                        }
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <div style={{ display:"flex", justifyContent:"start", width:'100%',transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                    <AccountCircleIcon/>
                                    <Typography style={{marginLeft:15,fontWeight:"bold"}}>Mon compte</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                    <p className={'buttonDrawerAdmin'} onClick={()=>{this.setState({openDialogueModifEmail:true,displayMenuMobile:false})}} style={{padding:10}}>Modifier mon email</p>
                                    <p className={'buttonDrawerAdmin'} onClick={()=>{this.setState({openDialogueInvalidationCompte:true,displayMenuMobile:false})}} style={{padding:10}}>Invalider mon compte</p>
                                    <p className={'buttonDrawerAdmin'} onClick={()=>{this.decoFunction()}} style={{padding:10, color:"#ff3629"}}>Déconnexion</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                <div style={{position:"absolute", display:"flex", height:90, backgroundColor:"white", width:"100%", alignItems:"center", boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", zIndex:9999}}>
                    <Dialog
                        open={this.state.displayConnexion}
                        onClose={()=>{this.setState({displayConnexion:false,readyToClick:true})}}
                    >
                        <DialogTitle>Connectez-vous à KageDoc !</DialogTitle>
                        <form autoComplete="off" onSubmit={(e)=>{this.handleConnexion(e)}}>
                            <DialogContent>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsIdentifiant"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsIdentifiant" required={true} label="Identifiant" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField autoComplete="off" name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsClef" required={true} type={this.state.showPassword ? "text":"password"} label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }} />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <a style={{color:"blue", textDecoration:"underline",cursor:"pointer"}} onClick={()=>{
                                    Swal.fire({
                                        text: "Munissez-vous de votre code de récupération de clef unique et contactez votre administrateur.",
                                        icon: 'info',
                                        target:".MuiDialog-root",
                                        confirmButtonText: 'Ok'
                                    })
                                }}>Clef unique oubliée ?</a>
                                <Button style={{backgroundColor:"#369dd7",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Se connecter</Button>
                            </DialogContent>
                        </form>
                    </Dialog>
                    <Dialog
                        open={this.state.openDialogueModifEmail}
                        onClose={()=>{this.setState({openDialogueModifEmail:false})}}
                    >
                        <DialogTitle>Modifier votre email</DialogTitle>
                        <form autoComplete="off" onSubmit={(e)=>{this.changeEmail(e)}}>
                            <DialogContent>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField  name={"champsIdentifiant"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} label="Identifiant" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField autoComplete="new-password" name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"password"} label="Clef Unique" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsNewEmail"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"email"} label="Nouvel email" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>
                                        <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button style={{backgroundColor:"#369dd7",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Modifier</Button>
                            </DialogContent>
                        </form>
                    </Dialog>
                    <Dialog
                        open={this.state.openDialogueInvalidationCompte}
                        onClose={()=>{this.setState({openDialogueInvalidationCompte:false})}}
                    >
                        <DialogTitle>Invalider mon compte</DialogTitle>
                        <form autoComplete="off" onSubmit={(e)=>{this.invalidateAccount(e)}}>
                            <h4 style={{marginLeft:28}}>ATTENTION cette action est <span style={{fontWeight:"bold"}}>permanente</span></h4>
                            <DialogContent>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField  name={"champsIdentifiant"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} label="Identifiant" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={this.state.showPasswordInvalidate ? "text":"password"} label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPasswordInvalidate:!this.state.showPasswordInvalidate})}}
                                                           >
                                                               {this.state.showPasswordInvalidate ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }}/>
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button style={{backgroundColor:"#369dd7",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>INVALIDER MON COMPTE</Button>
                            </DialogContent>
                        </form>
                    </Dialog>
                    <Dialog
                        open={this.state.openDialogueAjoutClient}
                        onClose={()=>{this.setState({openDialogueAjoutClient:false})}}
                    >
                        <DialogTitle>Ajouter un client</DialogTitle>
                        <form onSubmit={(e)=>{this.addClient(e)}}>
                            <DialogContent>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsNewEmail"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"email"} label="Email du client" variant="outlined" />
                                    {/*<Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>*/}
                                    {/*    <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />*/}
                                    {/*</Tooltip>*/}
                                </div>
                                <div style={{display:"flex", marginBottom:-10}}>
                                    {/*<Checkbox*/}
                                    {/*    checked={this.state.freeClient}*/}
                                    {/*    onChange={()=>{*/}
                                    {/*        this.setState({freeClient:!this.state.freeClient, sekurEmailClient:this.state.freeClient && false})*/}
                                    {/*    }}*/}
                                    {/*    style ={{color: "#52A8D9FF", margin:0}}*/}
                                    {/*/>*/}
                                    <p>Le client sera </p>
                                    <div className={"flexCenter"}>
                                        <Radio
                                            name="clientType"
                                            value="withSubscription"
                                            checked={!this.state.freeClient}
                                            onChange={() => {
                                                this.setState({freeClient:false, sekurEmailClient:this.state.freeClient && false})
                                            }}
                                            style={{ color: "#52A8D9FF", margin: 0 }}
                                        />
                                        <p>
                                            <span style={{ fontWeight: "bold" }}>avec abonnement</span>
                                        </p>
                                    </div>
                                    <div className={"flexCenter"}>
                                        <Radio
                                            name="clientType"
                                            value="withoutSubscription"
                                            checked={this.state.freeClient}
                                            onChange={() => {
                                                this.setState({freeClient:true, sekurEmailClient:this.state.freeClient && false})
                                            }}

                                            style={{ color: "#52A8D9FF", margin: 0 }}
                                        />
                                        <p>
                                            <span style={{ fontWeight: "bold" }}>sans abonnement</span>
                                        </p>
                                    </div>
                                </div>
                                <div className={"flexCenter"} style={{justifyContent:"flex-start", marginBottom:-10}}>
                                    <Checkbox
                                        checked={this.state.sekurEmailClient}
                                        onChange={()=>{
                                            this.setState({sekurEmailClient:!this.state.sekurEmailClient, freeClient:this.state.sekurEmailClient && false})
                                        }}
                                        style ={{color: "#52A8D9FF", margin:0}}
                                    />
                                    <p>Le client sera un client <span style={{fontWeight:"bold"}}>SekurEmail</span></p>
                                </div>
                                <div className={"flexCenter"} style={{justifyContent:"flex-start"}}>
                                    <Checkbox
                                        checked={this.state.relance}
                                        onChange={()=>{
                                            this.setState({relance:!this.state.relance})
                                        }}
                                        style ={{color: "#52A8D9FF", margin:0}}
                                    />
                                    <p>Mail de relance</p>
                                </div>
                                <Button style={{backgroundColor:"#369dd7",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Ajouter</Button>
                            </DialogContent>
                        </form>
                    </Dialog>
                    {this.state.showBrowserView ? (
                        <>
                            <div onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/Accueil', "_blank")}}} onClick={()=>{this.goToPage("/KageSecur/Accueil")}} style={{display:"flex",cursor:"pointer", justifyContent:"center", alignItems:"center", marginLeft:20}}>
                                <img style={{textAlign:"center", width:60,marginRight:5}} src={require("../../../assets/PICTO_KAGESECUR_RVB.png")} alt="logoKageSecure"/>
                                <div style={{display:"flex",flexDirection:"column"}}>
                                    <p style={{fontSize:21,margin:0, fontWeight:"1000",fontFamily:"arial",lineHeight:"25px"}}><span style={{color:"#64a7db"}}>KAGE</span><span style={{color:"#F19028"}}>DOC</span></p>
                                    <p style={{display:"block",fontSize:12,margin:0,marginLeft:0,width:135, fontWeight:"bold",fontFamily:"arialLight"}}>La preuve et la sécurité</p>
                                </div>
                            </div>
                            <div style={{display:"flex",width:"100%", justifyContent:"space-evenly", alignItems:"center", marginLeft:0}}>
                                {this.state.user === "none" ? (
                                    <>
                                    <Button variant={"contained"} style={{backgroundColor:"#52a8d9",cursor:"pointer", right:50 ,position:"absolute", marginBottom:10, marginTop:10, textTransform:"none"}} onClick={()=>{this.state.readyToClick && (this.setState({displayConnexion:true,readyToClick:false}))}}>
                                        <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#FFFFFF"}}>CONNEXION</p>
                                    </Button>
                                    </>
                                ):(
                                    <>
                                        <div id={"menuEnregistrement"} onMouseEnter={()=>{this.setState({openMenuEnregistrement:"block"})}} onMouseLeave={()=>{this.setState({openMenuEnregistrement:"none"})}} style={{display:(this.state.jwtDecoded.freeClient && this.state.jwtDecoded.sekurEmail) ? "none":"flex", flexDirection:"column", cursor:"pointer"}}>
                                            <p className={"menuBR"}>Enregistrement</p>
                                            <div style={{position:"relative"}}>
                                                <div className={"divOpenMenuBR"} style={{display:this.state.openMenuEnregistrement, position:"absolute", backgroundColor:"white", width:250, borderRadius:10, border:"1px solid black"}}>
                                                    <div onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/SansValidation', "_blank")}}}  onClick={()=>{this.goToPage("/KageSecur/SansValidation")}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"10px 10px 0px 0px"}}>
                                                        <p>Avec votre signature</p>
                                                    </div>
                                                    <div onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/AvecValidation', "_blank")}}} onClick={()=>{this.goToPage("/KageSecur/AvecValidation")}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 10px 10px"}}>
                                                        <p>Avec signature d'un tiers</p>
                                                    </div>
                                                    {/*<div onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/MiseEnDemeure', "_blank")}}} onClick={()=>{this.goToPage("/KageSecur/MiseEnDemeure")}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 10px 10px"}}>*/}
                                                    {/*    <p>Mise en demeure</p>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <p className={"menuBR"} style={{cursor:"pointer", fontWeight:400}} onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/TableauDeBord', "_blank")}}} onClick={()=>{this.goToPage("/KageSecur/TableauDeBord")}}>Tableau de bord</p>

                                        <div id={"menuAnalyse"} onMouseEnter={()=>{this.setState({openMenuAnalyse:"block"})}} onMouseLeave={()=>{this.setState({openMenuAnalyse:"none"})}} style={{display:(this.state.jwtDecoded.freeClient && this.state.jwtDecoded.sekurEmail) ? "none":"flex", flexDirection:"column", cursor:"pointer"}}>
                                            <p className={"menuBR"}>Analyse</p>
                                            <div style={{position:"relative"}}>
                                                <div style={{display:this.state.openMenuAnalyse, position:"absolute", backgroundColor:"white", width:250, borderRadius:10,border:"1px solid black"}}>
                                                    <div onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/Verification', "_blank")}}} onClick={()=>{this.goToPage("/KageSecur/Verification")}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"10px 10px 0px 0px"}}>
                                                        <p>Vérification</p>
                                                    </div>
                                                    <div onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/Tracabilite', "_blank")}}} onClick={()=>{this.goToPage("/KageSecur/Tracabilite")}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 10px 10px"}}>
                                                        <p>Traçabilité</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className={"menuBR"} style={{cursor:"pointer", fontWeight:400, display:(this.state.jwtDecoded.freeClient && this.state.jwtDecoded.sekurEmail) && "none"}} onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/SignatureCollaborative', "_blank")}}}  onClick={()=>{this.goToPage("/KageSecur/SignatureCollaborative")}}>Signature</p>
                                        {this.state.user === "gestionnaire" ?
                                            <>
                                                <p className={"menuBR"} onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/SekurEmail', "_blank")}}}   onClick={()=>{this.goToPage("/KageSecur/SekurEmail")}}>SekurEmail</p>
                                                <div id={"menuGestion"} onMouseEnter={()=>{this.setState({openMenuGestion:"block"})}} onMouseLeave={()=>{this.setState({openMenuGestion:"none"})}} style={{display:"flex", flexDirection:"column"}}>
                                                    <p className={"menuBR"}>Gestion</p>
                                                    <div style={{position:"relative"}}>
                                                        <div style={{display:this.state.openMenuGestion, position:"absolute", backgroundColor:"white", right:-20, width:250, borderRadius:10,border:"1px solid black"}}>
                                                            <div onClick={()=>{this.setState({openDialogueAjoutClient:true})}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"10px 10px 0px 0px"}}>
                                                                <p>Ajouter un client</p>
                                                            </div>
                                                            <div onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/ListeDesClients', "_blank")}}} onClick={()=>{this.goToPage("/KageSecur/ListeDesClients")}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 10px 10px"}}>
                                                                <p>Liste des clients</p>
                                                            </div>
                                                            {/*<div onClick={()=>{this.goToPage("/KageSecur/GestionDesApprobateurs")}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 10px 10px"}}>*/}
                                                            {/*    <p>Gérer les signataires</p>*/}
                                                            {/*</div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <p className={"menuBR"} style={{cursor:"pointer", fontWeight:400}} onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/SekurEmail', "_blank")}}}  onClick={()=>{this.goToPage("/KageSecur/SekurEmail")}}>SekurEmail</p>
                                            </>
                                        }
                                        <div id={"menuAccount"} onMouseEnter={()=>{this.setState({openMenuAccount:"block"})}} onMouseLeave={()=>{this.setState({openMenuAccount:"none"})}} style={{display:"flex", flexDirection:"column", cursor:"pointer"}}>
                                            <div style={{textAlign:"center"}}>
                                                <AccountCircleIcon style={{cursor:"pointer", transform:"scale(1.5)",color:"#4fa8da"}}/>
                                                <p style={{margin:0, textAlign:"center"}}>{this.state.pseudo}</p>
                                            </div>
                                            <div style={{position:"relative"}}>
                                                <div style={{display:this.state.openMenuAccount, position:"absolute", backgroundColor:"white",right:-20, width:250, borderRadius:10,border:"1px solid black"}}>
                                                    <div onClick={()=>{this.setState({openDialogueModifEmail:true})}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"10px 10px 0px 0px"}}>
                                                        <p>Modifier mon email</p>
                                                    </div>
                                                    <div onClick={()=>{this.setState({openDialogueInvalidationCompte:true})}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 0px 0px"}}>
                                                        <p>Invalider mon compte</p>
                                                    </div>
                                                    <div onClick={()=>{this.decoFunction()}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 10px 10px"}}>
                                                        <p style={{color:"#ff3629"}}>Déconnexion</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <HelpCenter/>
                                    </>
                                )}
                            </div>
                            <div style={{backgroundColor: "#4FA8DA", position:"absolute", left: 0, top: 90, width: 40, height: "calc(100vh - 90px)"}}/>
                            <div style={{backgroundColor:"#F19028", position:"absolute", right:0, top:90, width:40, height: "calc(100vh - 90px)"}}/>
                        </>
                    ):(
                        <>
                            <img onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/Accueil', "_blank")}}} onClick={()=>{this.goToPage("/KageSecur/Accueil")}} style={{textAlign:"center", height:70, marginLeft:20, cursor:"pointer", zIndex:99999}} src={require("../../../assets/PICTO_KAGESECUR_RVB.png")} alt="logoKageSecure"/>
                            <div style={{display:"flex",width:"100%", justifyContent:"flex-end", alignItems:"center", marginRight:40, zIndex:9999}}>
                                {this.state.user === "none" ? (
                                    <>
                                        <Button variant={"contained"} style={{backgroundColor:"#52a8d9",cursor:"pointer", right:50 ,position:"absolute", marginBottom:10, marginTop:10, textTransform:"none"}} onClick={()=>{this.state.readyToClick && (this.setState({displayConnexion:true,readyToClick:false}))}}>
                                            <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#FFFFFF"}}>CONNEXION</p>
                                        </Button>
                                    </>
                                ):(
                                    <>
                                        <MenuIcon onClick={()=>{this.setState({displayMenuMobile:!this.state.displayMenuMobile})}} style={{transform:"scale(1.5)",cursor:"pointer", transition : "all .5s cubic-bezier(.25,.8,.25,1)", zIndex:99999}} />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>

            </>
        )
    }
}

export default withRouter(SharedHeaderKageSecur)