import React, {Component} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import config from "../../../json/config.json";
import {isBrowser} from "react-device-detect";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import SharedDrawerEkocoOR from "./components/SharedDrawerEkocoOR";
import SharedFooterEkocoOR from "./components/SharedFooterEkocoOR";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let email, iban, bic, token, pseudo, hash;

let jwtDecoded

const detailParcours = [
    {
        id:0,
        image:"https://ekoco.fr/back/ORassets/RuelleDesChats/1_RDC.jpg",
        name:"Les incontournables"
    },
    {
        id:1,
        image:"https://ekoco.fr/back/ORassets/LiliDameAuChapeau/1_LL.jpg",
        name:"Sculptures et statues"
    },
    {
        id:2,
        image:"https://ekoco.fr/back/ORassets/CathedraleStPierreStPaul/1_CSPSP.jpg",
        name:"Edifices religieux"
    },
    {
        id:3,
        image:"https://ekoco.fr/back/ORassets/CoeurDeTroyes/1_CDT.jpg",
        name:"Spots photos"
    },
    {
        id:4,
        image:"https://ekoco.fr/back/ORassets/PlaceDeLaLiberation/1_PDLL.jpg",
        name:"Le tour de Troyes"
    },
    {
        id:5,
        image:"https://ekoco.fr/back/ORassets/ParcoursTEST.png",
        name:"Parcours TEST"
    },
]

let listAllParcours = [
    {
        timestamp: 1693821441,
        parcoursId: 2,
    },
    {
        timestamp: 1693921441,
        parcoursId: 3,
    }
]

class VisionneuseFichierOR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bicValue : null, ibanValue : null, emailValue : null,displayed:false,displayed2:false,
            marginLeftContact:20,
            marginRightContact:10,
            statsContentWidth:"95%",
            statsContentMinWidth:"0%",
            statsContentMaxWidth:"100%",
            marginTopContent:0,
            showBrowserView:true,
            albumDetail:undefined,
            albumId:null
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950 || !isBrowser){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)

        let albumId = new URLSearchParams(window.location.search).get("album")
        if(albumId){
            this.setState({albumId})
            let jwtURL = new URLSearchParams(window.location.search).get("jwt")
            if(jwtURL){
                sessionStorage.setItem("EKOCO_jwt", jwtURL)
            }
            let jwt = jwtURL ? jwtURL:sessionStorage.getItem("EKOCO_jwt")

            console.log("jwt",jwt)

            if(jwt){
                this.setState({
                    jwt: jwt
                }, () => {
                    jwtDecoded = jwt_decode(this.state.jwt)
                    Axios.post(url + "verifyJwt", {}, {headers: {Authorization: "Bearer " + jwt}}).then(response => {
                        this.setState({openLoad:false})
                        console.log(response.data)
                        if (response.data.jwt) {
                            sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                            this.setState({
                                jwt: response.data.jwt
                            }, () => {
                                jwtDecoded = jwt_decode(this.state.jwt)
                            })
                        }
                        if (response.data.resultat === true) {
                            this.setState({
                                pseudo: jwtDecoded.pseudo
                            }, () => {
                                this.getParcours();
                            })
                        } else {
                            Swal.fire({
                                title: 'Erreur',
                                text: "Connexion impossible, veuillez relancer ce site depuis l'application Ekoco ou connectez-vous directement sur le site.",
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            }).then(() => {
                                sessionStorage.removeItem("EKOCO_jwt")
                                this.props.navigate("/Ekoco/OctobreRose/Connexion")
                            })
                        }
                    })
                })
            }else{
                this.setState({openLoad:false})
                Swal.fire({
                    title: 'Erreur',
                    text: "Connexion impossible, veuillez relancer ce site depuis l'application Ekoco ou connectez-vous directement sur le site.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#ec5ba1'
                }).then(()=>{
                    this.props.navigate("/Ekoco/OctobreRose/Connexion")
                })
            }

            if (this.state.showBrowserView){
                this.setState({
                    marginLeftContact:50,
                    marginRightContact:100,
                    statsContentWidth:"50%",
                    statsContentMinWidth:400,
                    statsContentMaxWidth:450,
                    marginTopContent:50,
                })
            }
        }else{
            /*swal error & goback*/
            Swal.fire({
                title: 'Erreur',
                text: "Cet album n'existe pas.",
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#ec5ba1'
            }).then(()=>{
                this.props.navigate(-1)
            })
        }

    }

    displayContact(){
        this.setState({
            displayed:true,
        })
    }

    getParcours(){
        Axios.post(url+"getEndedParcoursOR",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.resultat === "done"){
                let albumDetail = response.data.endedParcours[this.state.albumId]
                console.log(albumDetail)
                this.setState({albumDetail})
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: "Une erreur est survenue, veuillez réessayer plus tard.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#ec5ba1'
                })
            }
        }).catch((err)=>{
            console.log(err)
            Swal.fire({
                title: 'Erreur',
                text: "Une erreur est survenue, veuillez réessayer plus tard.",
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#ec5ba1'
            })
        })
    }

    handleCloseDialog = () => {
        this.setState({
            displayed : false,
            displayed2 : false
        });
    };
    handleOpenDialog = () => {
        this.setState({
            displayed2 : true
        });
    };

    handleChange=(event) => { //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }

    handleValidation=()=>{ //Fonction servant a la vérification des données avant l'envoi du formulaire
        return true
    }

    downloadAllImages(){
        // let allImages = this.state.albumDetail.photos
        // allImages.forEach((elt)=>{
        //     window.open(elt+"?tokenJwt="+this.state.jwt, "_blank")
        // })
        let linkToDl = this.state.albumDetail.photos[0].split("/")
        linkToDl.pop()
        linkToDl.push("album.zip")
        linkToDl = linkToDl.join("/")

        window.open(linkToDl+"?tokenJwt="+this.state.jwt, "_blank")
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>
                <SharedDrawerEkocoOR/>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end"}}>
                    <p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold"}}>{this.state.pseudo}</p>
                    <AccountCircleIcon style={{color:"#b0a9a8", marginRight:50}}/>
                </div>
                {/*DEBUT RENDER STATIQUE*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)", justifyContent:"flex-start", alignItems:"center", flexDirection:"column", minHeight:"80vh"}}>
                    <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", flexDirection:"column",marginBottom:20,width:"100%",height:'100%', marginTop:50, zIndex:2, overflow:"hidden"}}>
                        {this.state.albumDetail ? (
                            <>
                                <button onClick={()=>{this.downloadAllImages()}} style={{width:"fit-content", marginTop:15, backgroundImage:"none", backgroundColor:"#EC5BA1FF"}} className={"buttonSK"}><h2 style={{margin:0, fontWeight:600, color:"#FFF"}}>Télécharger l'album entier</h2></button>
                                <div className={"flexCenter"} style={{flexWrap:"wrap", width:"95%", justifyContent:"space-evenly"}}>
                                    {this.state.albumDetail.photos.map(elt=>(
                                        <img onClick={()=>{window.open(elt+"?tokenJwt="+this.state.jwt, "_blank","noopener")}} id={"hoverMoving"} style={{width:this.state.showBrowserView ? "20%":"95%", objectFit:"contain", margin:25, borderRadius:5, boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}} src={elt+"?tokenJwt="+this.state.jwt} alt={"Photo album"}/>
                                    ))}
                                    {this.state.albumDetail.photos.length === 0 && (
                                        <p style={{color:"#3A374C",fontWeight:"bold", fontSize:this.state.showBrowserView ?20:"4vw", margin:0, marginBottom:20, textAlign:"center", whiteSpace:"pre-line"}}>Aucune photo n'est disponible pour ce parcours.</p>
                                    )}
                                </div>
                            </>
                        ):(
                            <p style={{color:"black",fontWeight:"bold", fontSize:this.state.showBrowserView ?20:"4vw", margin:0, marginBottom:20, textAlign:"center", whiteSpace:"pre-line"}}>Aucune photo n'est disponible pour ce parcours.</p>
                        )}
                    </div>
                </div>
                {/*FIN RENDER STATIQUE*/}
                {/*FOOTER*/}
                <SharedFooterEkocoOR/>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Chargement en cours ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default  withRouter(VisionneuseFichierOR);