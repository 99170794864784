import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import Axios from "axios";
import config from "../../json/config.json";
import {isMobile} from "react-device-detect";
import jwt_decode from "jwt-decode";
import {Button, CircularProgress} from "@mui/material";
import {CheckCircleOutline, KeyboardArrowDown, PanToolAlt} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let inter;


const titresExplication = [
    "Avantages pour les entreprises",
    "Avantages fonctionnels",
    "Avantages pour les investisseurs"
]

let jwtDecoded;
class AccueilSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mousePosition:[0,0],
            showBrowserView:true,
            pageWidth:document.body.offsetWidth,
            scrollPosition:0,
            showAnim1:false,
            showEndAnim1:false,
            displayExplication:0,
            autoExplication:true
        };
    }

    resizePage = () => {
        this.setState({pageWidth:document.body.offsetWidth})
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    trackMouse = (e) => {
        if(window.scrollY < 800){
            let mouseX
            if(e.screenX > 1920){
                mouseX = e.screenX-1920
            }else if (e.screenX < 0 ){
                mouseX = -e.screenX
            }else{
                mouseX = e.screenX
            }
            let mouseY = e.screenY
            this.setState({mousePosition:[mouseX,mouseY]})
        }
    }

    trackScroll = (e) => {
        if((window.scrollY < 800 && window.scrollY > 400) && !this.state.showAnim1){
            this.setState({showAnim1:true})
            setTimeout(()=>{this.setState({showEndAnim1:true})},3100)
        }else if((window.scrollY < 1450 && window.scrollY > 1050) && this.state.displayExplication === 0){
            this.setState({displayExplication:2})
            this.intervalAutoExplication("start")
        }
    }

    goToPage(clickedPage){
        window.history.replaceState(null, null, window.location.pathname);
        window.location.pathname !== clickedPage ? this.props.navigate(clickedPage):window.location.reload()
        this.setState({displayMenuMobile:false})
    }

    componentWillUnmount(){
        this._willUnmount = true;
        if(this._interval){
            clearInterval(this._interval)
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        window.addEventListener("mousemove", this.trackMouse);
        window.addEventListener("scroll", this.trackScroll);
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            if(jwtDecoded!=={}){
                Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === true){
                        jwtDecoded = jwt_decode(this.state.jwt)
                        global.emitter.emit("onRouteChangedStockMarketSTO", {
                            jwt: this.state.jwt,
                        })
                    }else{
                        sessionStorage.removeItem("SMSTO_jwt")
                        global.emitter.emit("onRouteChangedStockMarketSTO", {
                            jwt: null,
                        })
                    }
                })
            }
        })
    }
    intervalAutoExplication(todo){
        if(todo === "start"){
            inter = setInterval(()=>{
                this.setState({displayExplication:this.state.displayExplication === 1 ? 3:this.state.displayExplication-1})
            },8000)
        }else if(todo === "stop"){
            clearInterval(inter)
        }
    }

    changeExplication(num){
        if(this.state.autoExplication){
            this.intervalAutoExplication("stop")
            this.setState({autoExplication:false})
        }
        this.setState({displayExplication:num})
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,minHeight:"100vh",width:"100%", transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"flex-start",flexDirection:"column"}}>
                    {/*Première div LOGO*/}
                    <div style={{width:50, height:50, backgroundColor:"rgba(0,0,0,0.5)", position:"fixed"}}>
                        <p>^</p>
                    </div>
                    <div id={"divPres"} className={"flexCenter"}>
                        <div style={{position:"relative", width:"100%"}}>
                            {this.state.showBrowserView && (
                                <>
                                    <img style={{textAlign:"center", position:"absolute", right:0, height:487, top:45,userSelect: "none"}} src={require("../../assets/backgroundBR1.png")} alt="backgroundBuyResale"/>
                                    <img style={{textAlign:"center", position:"absolute", height:600,userSelect: "none"}} src={require("../../assets/backgroundBR2.png")} alt="backgroundBuyResale"/>
                                </>
                            )}
                        </div>
                        <div className={"flexCenter divHoverPres"} style={{flex:8,flexDirection:"column", transform:this.state.showBrowserView && ("perspective(1000px) rotateY("+((this.state.mousePosition[0]-(document.body.offsetWidth/2))*40/document.body.offsetWidth)+"deg) rotateX("+((this.state.mousePosition[1]-(document.body.offsetHeight/5))*120/document.body.offsetHeight)+"deg) scale3d(1, 1, 1)")}}>
                            <img style={{textAlign:"center", width:this.state.showBrowserView ?550:"70%",userSelect: "none"}} src={require("../../assets/LOGO_STO_blanc.png")} alt="logoBuyResale"/>
                            <h1 style={{color:"#FFFFFF", marginBottom:0,marginTop:40, fontWeight:400, textAlign:"center", fontSize:!this.state.showBrowserView&&"5vw"}}>Plateforme Française de tokenisation</h1>
                            <h1 style={{color:"#FFFFFF", marginTop:5,marginBottom:0, fontWeight:400, textAlign:"center", fontSize:!this.state.showBrowserView&&"5vw"}}>d’instruments financiers en monnaie fiduciaire</h1>
                        </div>
                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                            <Button
                                onClick={()=>{document.getElementById("divPrevRevente").scrollIntoView()}}
                                style={{color:"#FFFFFF", borderColor:"#FFFFFF"}} variant={"outlined"}
                            >
                                En savoir plus
                            </Button>
                            <KeyboardArrowDown style={{color:"#FFFFFF"}}/>
                        </div>
                    </div>
                    <div id={"divPrevRevente"} style={{height:!this.state.showBrowserView&&"100%"}} className={"flexCenter"}>
                        <h1 style={{color:"#000000", textAlign:"center", fontWeight:100, fontSize:48, marginTop:0}}>Investir avec des actifs numériques</h1>
                        <div className={"flexCenter"} style={{width:"100%", height:"100%"}}>
                            <div className={"flexCenter"} style={{flex:1}}>
                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                    <img style={{width:this.state.showBrowserView ? "30vw":"95%", maxWidth:500}} src={require("../../assets/man-relaxing-on-weekend-4539176-3816378.png")} alt="illustration"/>
                                    <h2 style={{color:"#000000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"65%"}}>Investissez facilement grâce à des actifs numériques qui pourront être conservés, utilisés ou échangés sur la blockchain.</h2>
                                </div>
                            </div>
                            {this.state.pageWidth > 1450 && (
                                <div className={"flexCenter"} style={{flex:1, flexDirection:"column", width:"100%"}}>
                                    <div className={`containerEntrepriseCard flexCenter ${this.state.showAnim1 && "anim2sto"}`} style={{width:550, height:250}}>
                                        <div style={{flex:1, height:"100%"}}>
                                            <h2 style={{margin:5, marginBottom:15}}>Entreprise innovante</h2>
                                            <img style={{width: 270, borderRadius:10}} src={require("../../assets/Couv-Viavoice-jeunes-entreprise-1024x684.jpg")} alt="Illustration entreprise"/>
                                        </div>
                                        <div style={{flex:1, height:"100%"}}>
                                            <h4>Début : 01/01/23 - Fin : 30/04/23</h4>
                                            <p style={{margin:5, marginBottom:60}}>Émettez des titres de capital gérés par la blockchain.</p>
                                            <div className={"flexCenter"} style={{margin:5,marginTop:20}}>
                                                <p style={{margin:0, textAlign:"center"}}>Actifs STO restants : <span style={{fontWeight:"bold"}}>{this.state.showEndAnim1 ? "20000":"24000"}/60000</span></p>
                                                <div style={{position:"relative"}}>
                                                    <div className={"flexCenter"} style={{width:41, height:41, borderRadius:"50%", backgroundColor:"transparent", border:"17px solid #F1F1F1F1", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                                                        <CircularProgress style={{width:75, height:75, position:"absolute"}} variant={"determinate"} value={this.state.showEndAnim1 ? 60:65} thickness={10}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{position:"relative",marginRight:70}}>
                                        {!this.state.showEndAnim1 ? (
                                            <>
                                                <button className={`divButtonBuyResale ${this.state.showAnim1 && "anim3sto"}`} style={{transform:"translateY(-150px)", position:"absolute",opacity:this.state.showAnim1 ? 1:0, pointerEvents: "none"}}>Investir</button>
                                                <PanToolAlt sx={{ stroke: "#ffffff", strokeWidth: 1 }} className={this.state.showAnim1 && "anim1sto"} style={{position:"absolute",color:"#000000",transition:"all 1s cubic-bezier(.25,.8,.25,1)", opacity:this.state.showAnim1 ? 1:0, marginLeft:27}}/>
                                            </>
                                        ):(
                                            <div style={{transform:"translateY(-150px) translateX(-20px) scale(2.5)", position:"absolute", textAlign:"center"}}>
                                                <p style={{color:"#FFFFFF",width:120,fontSize:7, fontWeight:"bold", marginBottom:0}}>Actifs numériques achetés !</p>
                                                <CheckCircleOutline style={{color:"#41ff00"}}/>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                            <Button
                                onClick={()=>{document.getElementById("divInvestir").scrollIntoView()}}
                                style={{color:"#000", borderColor:"#000"}} variant={"outlined"}
                            >
                                En savoir plus
                            </Button>
                            <KeyboardArrowDown style={{color:"#000"}}/>
                        </div>
                    </div>
                    <div id={"divInvestir"} style={{height:!this.state.showBrowserView&&"100%"}} className={"flexCenter"}>
                        <h1 style={{textAlign:"center", color:"#ffffff", fontWeight:100, fontSize:48, marginTop:0}}>Les différents avantages de notre solution</h1>
                        <div className={"flexCenter"} style={{width:"100%", height:"100%"}}>
                            <div className={"flexCenter"} style={{flex:"1 1 15%", width:"100%", justifyContent:"space-evenly"}}>
                                <div className={"flexCenter"} style={{flexDirection:"column", flex:1, display:!this.state.showBrowserView&&"none"}}>
                                    <div className={`divContainerExplicationImage`} style={{transform: (this.state.displayExplication === 1 ? "rotate(135deg)":this.state.displayExplication === 2 ? "rotate(39deg)":"rotate(270deg)")}}>
                                        <img style={{transform: (this.state.displayExplication === 1 ? "rotate(225deg)":this.state.displayExplication === 2 ? "rotate(320deg)":"rotate(90deg)"), opacity: (this.state.displayExplication === 1 ? 1:this.state.displayExplication === 2 ? 0.5:0.5)}} onClick={()=>{this.changeExplication(1)}} className={`imageExplication ${this.state.displayExplication === 1 && "imageExplicationClicked"}`} src={require("../../assets/sto_Avantages_Entreprises.png")} alt="sto_Avantages_Entreprises"/>
                                        <img style={{transform: (this.state.displayExplication === 1 ? "rotate(225deg)":this.state.displayExplication === 2 ? "rotate(320deg)":"rotate(90deg)"), opacity: (this.state.displayExplication === 1 ? 0.5:this.state.displayExplication === 2 ? 1:0.5),right:0}} onClick={()=>{this.changeExplication(2)}} className={`imageExplication ${this.state.displayExplication === 2 && "imageExplicationClicked"}`} src={require("../../assets/sto_Avanatges_fonctionnels.png")} alt="sto_Avanatges_fonctionnels"/>
                                        <img style={{transform: (this.state.displayExplication === 1 ? "rotate(225deg)":this.state.displayExplication === 2 ? "rotate(320deg)":"rotate(90deg)"), opacity: (this.state.displayExplication === 1 ? 0.5:this.state.displayExplication === 2 ? 0.5:1),bottom:10, left:"calc(50% - 75px)"}} onClick={()=>{this.changeExplication(3)}} className={`imageExplication ${this.state.displayExplication === 3 && "imageExplicationClicked"}`} src={require("../../assets/sto_Avantages_investisseurs.png")} alt="sto_Avantages_investisseurs"/>
                                    </div>
                                </div>
                                <div className={"flexCenter"} style={{flex:1, flexDirection:"column", margin:15, backgroundColor:"#FFF", borderRadius:10, boxShadow:"rgba(0, 0, 0, 0.2) 0px 60px 40px -7px"}}>
                                    <h2 style={{color:"#000",marginBottom:0, textAlign:"center", width:"95%", fontWeight:600, fontSize:25}}>{titresExplication[this.state.displayExplication !== 0 ? this.state.displayExplication - 1:1]}</h2>
                                    {(this.state.displayExplication === 1) ? ( //Avantage pour les entreprises
                                        <div className={"flexCenter"} style={{flexDirection:"column", height:350}}>
                                            <h3 style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>• Cessions des titres <span style={{fontWeight:"bold"}}>faciles et dématérialisées</span></h3>
                                            <h3 style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>• <span style={{fontWeight:"bold"}}>Tokenisation</span> des actionnaires existants</h3>
                                            <h3 style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>• Levées de fonds <span style={{fontWeight:"bold"}}>sécurisées et rapide</span></h3>
                                            <h3 style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>• Transactions <span style={{fontWeight:"bold"}}>simplifiées et sûres</span></h3>
                                            <h3 style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>• Registres d'actionnaires <span style={{fontWeight:"bold"}}>dynamiques</span></h3>
                                            <button style={{marginTop:30}} onClick={()=>{window.open("https://smsto.site/avantages-pour-les-entreprises/","_blank")}} className={"divButtonBuyResale"}>En découvrir plus sur ces avantages</button>
                                        </div>
                                    ):this.state.displayExplication === 2 || this.state.displayExplication === 0 ? ( //Avantage fonctionnel
                                        <div className={"flexCenter"} style={{flexDirection:"column",height:350}}>
                                            <h3 className={"flexCenter"} style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>
                                                <img style={{width:40, marginRight:5}} src={require("../../assets/sto_NFT_cadenas.png")} alt="sto_NFT_cadenas"/>
                                                Sécurisation des actifs numérique grâce aux STOs
                                            </h3>
                                            <h3 className={"flexCenter"} style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>
                                                <img style={{width:40, marginRight:5}} src={require("../../assets/sto_Personnage_microphone.png")} alt="sto_Personnage_microphone"/>
                                                Gestion des droits des détenteurs de jetons tel que les dividendes
                                            </h3>
                                            <h3 className={"flexCenter"} style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>
                                                <img style={{width:40, marginRight:5}} src={require("../../assets/sto_NFT_flexibilite.png")} alt="sto_NFT_flexibilite"/>
                                                Flexibilité des STOs comparé aux actions traditionnelles
                                            </h3>
                                            <h3 className={"flexCenter"} style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>
                                                <img style={{width:40, marginRight:5}} src={require("../../assets/sto_NFT_parachute.png")} alt="sto_NFT_parachute"/>
                                                Minimisation des risques en respectant des réglementations pertinents
                                            </h3>
                                            <button style={{marginTop:30}} onClick={()=>{window.open("https://smsto.site/avantages-fonctionnels/","_blank")}} className={"divButtonBuyResale"}>En découvrir plus sur ces avantages</button>
                                        </div>
                                    ):this.state.displayExplication === 3 && ( //Avantage investisseur
                                        <div className={"flexCenter"} style={{flexDirection:"column",height:350}}>
                                            <h3 style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>• Prix attractifs</h3>
                                            {this.state.showBrowserView ? (
                                                <p style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"80%"}}>C’est un <span style={{fontWeight:"bold"}}>marché privilégié</span> par la plupart des investisseurs institutionnels. En effet, quelle que soit la taille de la transaction, les prix proposés en OTC (Over The Counter) sont généralement <span style={{fontWeight:"bold"}}>plus attractifs</span> que sur un marché organisé.</p>
                                            ):(
                                                <p style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"80%"}}>Quelle que soit la taille de la transaction, les prix proposés en OTC (Over The Counter) sont <span style={{fontWeight:"bold"}}>plus attractifs</span> que sur un marché organisé.</p>
                                            )}
                                            <h3 style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"100%"}}>• Solution souple</h3>
                                            {this.state.showBrowserView ? (
                                                <p style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"80%"}}>Une entreprise peut opter pour des <span style={{fontWeight:"bold"}}>bons de participation</span> qui offrent les même droits économiques que les actions, hormis le vote. Nos <span style={{fontWeight:"bold"}}>STOs</span> permettent de simplifier ce système pour l'investisseur afin de pouvoir <span style={{fontWeight:"bold"}}>s'intégrer au capital</span> d'une entreprise sans s'occuper <span style={{fontWeight:"bold"}}>d'aucune démarche</span> administrative.</p>
                                            ):(
                                                <p style={{color:"#000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"80%"}}>Nos <span style={{fontWeight:"bold"}}>STOs</span> permettent de simplifier le processur pour l'investisseur afin de pouvoir <span style={{fontWeight:"bold"}}>s'intégrer au capital</span> d'une entreprise sans s'occuper <span style={{fontWeight:"bold"}}>d'aucune démarche</span> administrative.</p>
                                            )}
                                            <button style={{marginTop:30, marginBottom:10}} onClick={()=>{window.open("https://smsto.site/avantages-pour-les-investisseurs/","_blank")}} className={"divButtonBuyResale"}>En découvrir plus sur ces avantages</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                            <Button
                                onClick={()=>{document.getElementById("divPrevMaVideo").scrollIntoView()}}
                                variant={"outlined"}
                                style={{color:"#FFFFFF", borderColor:"#FFFFFF"}}
                            >
                                En savoir plus
                            </Button>
                            <KeyboardArrowDown style={{color:"#FFFFFF"}}/>
                        </div>
                    </div>
                    <div id={"divPrevMaVideo"} style={{height:!this.state.showBrowserView&&"100%"}} className={"flexCenter"}>
                        <div className={"flexCenter"} style={{flex:8, flexDirection:"column", zIndex:2}}>
                            <h1 style={{color:"#FFFFFF", textAlign:"center", fontWeight:100, fontSize:48, marginTop:0}}>Quelques informations supplémentaires</h1>
                            <div className={"flexCenter"} style={{height:"100%", width:"100%", flexDirection:"column"}}>
                                <h2 style={{color:"#FFF", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"65%"}}>Découvrez en détail le fonctionnement de notre plateforme et sur les STOs.</h2>
                                <Button
                                    onClick={()=>{window.open("https://smsto.site/","_blank")}}
                                    style={{color:"#FFFFFF", borderColor:"#FFFFFF", marginBottom:10, marginTop:20}} variant={"outlined"}
                                >
                                    cliquez ici
                                </Button>
                                <h2 style={{color:"#FFF", fontWeight:"normal",marginBottom:30, textAlign:"center", width:"65%"}}>Opérationnel sur la blockchain Galaksio</h2>
                                <div onAuxClick={(e)=>{if(e.button===1){window.open("https://galaksio-blockchain.com/","_blank")}}} onClick={()=>{window.open("https://www.kagesecur.com/","_blank")}} style={{display:"flex",cursor:"pointer", justifyContent:"center", alignItems:"center", transform:"scale(1.6)", marginBottom:25}}>
                                    <img style={{textAlign:"center", width:110}} src={require("../../assets/logoGalaksioColumnBlack.png")} alt="Logo_GALAKSIO"/>
                                </div>
                                <h2 style={{color:"#FFF", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"65%"}}>Vous aussi rejoignez la plateforme Stock Market STO et investissez dans des entreprises prometteuses.</h2>
                                <Button
                                    onClick={()=>{this.goToPage("/StockMarketSTO/Inscription")}}
                                    style={{color:"#FFFFFF", borderColor:"#FFFFFF", marginBottom:20, marginTop:20}} variant={"outlined"}
                                >
                                    Je m'inscris
                                </Button>
                            </div>
                        </div>
                        <div style={{position:"relative", width:"100%"}}>
                            <img style={{textAlign:"center", width:600, height:300, position:"absolute", top:-300,userSelect: "none", display:!this.state.showBrowserView&&"none"}} src={require("../../assets/fouleDos.png")} alt="illustrationCommunauté"/>
                        </div>
                    </div>
                    <div id={"divFooter"} className={"flexCenter"} style={{width:"100%"}}>
                        {this.state.showBrowserView && (
                            <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                                <img style={{textAlign:"center", width: 300,marginRight:5,userSelect: "none"}} src={require("../../assets/Logo_SMSTO.png")} alt="LOGO_SMSTO"/>
                            </div>
                        )}
                        <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                            <div style={{width:this.state.showBrowserView ?"70%":"95%",display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"flex-start"}}>
                                <h3 style={{margin:10, marginLeft:0}}>Nous contacter :</h3>
                                <p style={{margin:0}}>Tel: (+33) 6 08 82 67 53</p>
                                <p style={{margin:0}}>Adresse : 9 rue Gustave Eiffel 10430 Rosière prés de Troyes</p>
                                <a href={"https://smsto.site/contact/"} target={"_blank"} style={{margin:0}}>Nous contacter par mail</a>
                            </div>
                        </div>
                        <div className={"flexCenter"} style={{flex:1, height:"100%", flexDirection:"column", overflowX:"hidden"}}>
                            <h3 onClick={()=>{this.props.navigate("/StockMarketSTO/PDCSTO")}} className={"textCGU"} style={{margin:5, fontWeight:"normal"}}>Politique de confidentialité</h3>
                            <h3 onClick={()=>{this.props.navigate("/StockMarketSTO/CGUSTO")}} className={"textCGU"} style={{margin:5, fontWeight:"normal"}}>Conditions générales d'utilisation</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AccueilSTO)