import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import Axios from "axios";
import config from "../json/config.json";

import {Check, Close, Visibility} from "@mui/icons-material";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let columns = [
    { id: 'name', label: 'Url', minWidth: 10 },
    { id: 'status', label: 'Status', minWidth: 10 },
    { id: 'details', label: 'Details', minWidth: 10 },
];

let columnsDetail = [
    { id: 'fichier', label: 'Nom du fichier', minWidth: 10 },
    { id: 'hash', label: 'Hash', minWidth: 10 },
];

let jwtDecoded;

class IntegriteDuCodeSource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayAdministration : false,
            arrayIntegrite : [],
            resultatIntegrite : 'En attente ...',
            openLoad:false,
            dataCS:{resultat:{"":""},problems:{}},
            detailDialog:false,
            stateDrawer:true
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt", {},
                {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat !== true){
                    this.props.navigate('/ConnexionAdmin')
                }else{
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:this.state.pseudo
                    })
                    global.emitter.emit("onRouteChanged",{
                        pageName:'Intégrité du code source',
                        pseudo:this.state.pseudo,
                    })
                    this.checkCodeSource();
                }

            })
        })
    }

    checkCodeSource(){
        this.setState({openLoad:true})
        Axios.post(url+"integriteCS",{},
            {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            this.setState({dataCS:response.data,openLoad:false})
            // Object.keys(response.data.resultat).forEach((server)=>{
            //     if(!!response.data.problems[server]){
            //         console.log("prob")
            //     }else{
            //         console.log("ok")
            //     }
            // })
        }).catch(()=>{this.setState({openLoad:false})})
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.detailDialog}
                    onClose={()=>{this.setState({detailDialog:false})}}
                >
                    <DialogTitle style={{display:"flex"}}>
                       Detail des codes sources du serveur {this.state.serverToDetail}
                    </DialogTitle>
                    <DialogContent style={{display:"flex", flexDirection:"column", justifyItems:"center"}}>
                        {this.state.serverToDetail && (
                            <>
                                <TableContainer style={{backgroundColor:"white",overflow:"auto"}}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columnsDetail.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        style={{ minWidth: column.minWidth, fontWeight:"bold" }}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(Object.keys(this.state.dataCS.resultat[this.state.serverToDetail]).map(file=>{
                                                return(
                                                    <TableRow key={file[0]}>
                                                        {columnsDetail.map((column)=>{
                                                            return(
                                                                <TableCell style={{textAlign:"left"}} key={column.id}>
                                                                    {column.id === 'fichier' ? (
                                                                        <p>{file}</p>
                                                                    ):(
                                                                        <p style={{color:!!this.state.dataCS.problems && !!this.state.dataCS.problems[this.state.serverToDetail] && !!this.state.dataCS.problems[this.state.serverToDetail][file] ? "red":"green"}} >{this.state.dataCS.resultat[this.state.serverToDetail][file]}</p>
                                                                    )}
                                                                </TableCell>
                                                            )
                                                        })}
                                                    </TableRow>
                                                )
                                            }))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                    </DialogContent>
                </Dialog>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div className={"flexCenter"} style={{maxWidth:'90%', flexDirection:"column", overflow:"auto"}}>
                        <TableContainer style={{backgroundColor:"white",overflow:"auto",maxHeight:450}}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                style={{ minWidth: column.minWidth, fontWeight:"bold" }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(Object.keys(this.state.dataCS.resultat)).map(node=>{
                                        return(
                                            <TableRow key={node[0]}>
                                                {columns.map((column)=>{
                                                    return(
                                                        <TableCell style={{textAlign:"center"}} key={column.id}>
                                                            {column.id === 'details' ? (
                                                                <IconButton
                                                                    onClick={()=>{this.setState({detailDialog:true,serverToDetail:node})}}
                                                                >
                                                                    <Visibility/>
                                                                </IconButton>
                                                            ):(
                                                                <>
                                                                    {column.id === 'status' ? (
                                                                        <>
                                                                            {!!this.state.dataCS.problems[node] ?
                                                                                <div style={{width:0,height:0,borderColor:"#ff0000", marginLeft:20}} className={"iconCodeSource"} >
                                                                                    <Close style={{transform:"scale(1)"}}/>
                                                                                </div>
                                                                                :
                                                                                <div style={{width:0,height:0,borderColor:"#35be00",marginLeft:20}} className={"iconCodeSource"} >
                                                                                    <Check style={{transform:"scale(1)"}}/>
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    ):(
                                                                        <p>{node}</p>
                                                                    )}
                                                                </>
                                                            )}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div style={{display:"flex",marginTop:20}}>
                            <Button onClick={()=>{this.checkCodeSource()}} variant={"contained"} style={{backgroundColor:"#FFF", color:"#000"}}>Vérifier manuellement l'intégrité du code source</Button>
                        </div>
                    </div>

                </div>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Mise à jour des résultats...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>


        );
    }
}

export default withRouter(IntegriteDuCodeSource)