import React, {Component} from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    TextField,
    Tooltip
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import config from "../../../json/config.json";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {isBrowser} from "react-device-detect";
import Swal from "sweetalert2";
import tooltipContenu from "../../../json/contenu.json";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let pseudo, listeAssoc = [], nbToken, valoToken, iban, bic, countAssoc = 0, assoc, hash, jwt;


const useStyles = {
    inputColor:{
        color:"#5ecaab",
        fontWeight:"bold",
        fontSize:30,
    },
    radioColor:{
        color:"#5ecaab",
    },
    inputColorIB:{
        color:"#5ecaab",
        fontWeight:"bold",
        fontSize:20,
    }
};

let jwtDecoded

class GestionTokens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assoc : true, BCCValue : '', nbToken : 0,openLoad:false, valoToken:'',
            nomAssoc:'Aucune association', urlAssoc:'',
            pointer:'',
            marginLeftContact:20,
            marginRightContact:10,
            statsContentWidth:"95%",
            statsContentMinWidth:"0%",
            statsContentMaxWidth:"100%",
            marginTopContent:0,
            giveAssocBool:true,
            giveAssocNonBg:"transparent",
            giveAssocNonColor:"#5ecaab",
            giveAssocOuiBg:"#5ecaab",
            giveAssocOuiColor:"white",
        };
        this.sendDemandeToken = this.sendDemandeToken.bind(this);
    }

    componentDidMount() {
        listeAssoc = [];
        this.setState({
            jwt: sessionStorage.getItem("EKOCO_jwt"),
        }, () => {
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url + "verifyJwt", {}, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
                if (response.data.jwt) {
                    sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    }, () => {
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true) {
                    this.setState({
                        pseudo: jwtDecoded.pseudo
                    }, () => {
                        this.requeteAxios();
                    })
                } else {
                    this.props.navigate('/Ekoco/BasCarboneBlockchain', { replace: true })
                }
            })
        })
        if (isBrowser){
            this.setState({
                bicValue : null, ibanValue : null, emailValue : null,displayed:false,
                marginLeftContact:50,
                marginRightContact:100,
                statsContentWidth:"50%",
                statsContentMinWidth:400,
                statsContentMaxWidth:450,
                marginTopContent:50,
            })
        }
    };

    requeteAxios(){
        Axios.post(url+"getGestionToken", {}
        ,{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
            if (response.data.jwt) {
                sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                jwt = response.data.jwt
            }
            nbToken = response.data.resultat[0][0]; iban = response.data.resultat[0][1]; bic = response.data.resultat[0][2]; valoToken = response.data.resultat[0][3]
            let nbTokenInt = nbToken.split(".")[0], nbTokenFloat = nbToken.split(".")[1]
            this.setState({
                ibanValue : iban,
                bicValue : bic,
                valoToken:valoToken,
                nbToken:nbToken, nbTokenInt:nbTokenInt, nbTokenFloat:nbTokenFloat
            })
            response.data.resultat[1].forEach(assoc =>{
                listeAssoc.push([assoc[0], assoc[1]])
            })
            if (listeAssoc.length > 0){
                this.setState({nomAssoc : listeAssoc[0][0], urlAssoc : listeAssoc[0][1]})
            }
            global.emitter.emit("onRouteChangedEkoco",{
                page:'GestionTokens'
            });
        }).catch(error => {
            console.log(error);
        });
    }

    clickNextAssoc(){
        countAssoc++
        if (countAssoc === listeAssoc.length){countAssoc = 0} //A CHANGER EN FONCTION DU TABLEAU QUE TU M'ENVOIS
        this.changeA()
    };
    clickPreviousAssoc(){
        countAssoc--;
        if (countAssoc === -1){countAssoc = listeAssoc.length -1}
        this.changeA()
    };
    changeA(){
        if (listeAssoc.length > 0) {
            this.setState({
                nomAssoc : listeAssoc[countAssoc][0],
                urlAssoc : listeAssoc[countAssoc][1]
            })
        }
    };

    handleChange=(event) => { //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        let value = event.target.value;
        if (event.target.name === 'BCCValue'){value = value.replace(',','.')}
        this.setState({
            [event.target.name]:value
        });
    }
    handleGiveAssoc(e){
        if (e.currentTarget.name === 'giveAssocOui'){
            this.setState({
                giveAssocNonBg:"transparent",
                giveAssocNonColor:"#5ecaab",
                giveAssocOuiBg:"#5ecaab",
                giveAssocOuiColor:"white",
                giveAssocBool:true
            })
        }else{
            this.setState({
                giveAssocOuiBg:"transparent",
                giveAssocOuiColor:"#5ecaab",
                giveAssocNonBg:"#5ecaab",
                giveAssocNonColor:"white",
                giveAssocBool:false
            })
        }
    }
    sendDemandeToken(){
        this.setState({
            openLoad:true
        })
        if(this.state.giveAssocBool && this.state.nomAssoc === "Aucune association"){
            this.setState({openLoad:false})
            Swal.fire({
                title: 'Erreur!',
                text: "Impossible de choisir cette association.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }else {
            if (this.state.BCCValue !== '' && ((this.state.giveAssocBool === false && this.state.ibanValue !== '' && this.state.ibanValue !== 'Non enregistré' && this.state.bicValue !== 'Non enregistré' && this.state.bicValue !== '') || (this.state.giveAssocBool === true))) {
                if (this.state.giveAssocBool === true) {
                    assoc = listeAssoc[countAssoc][0]
                } else if (this.state.giveAssocBool === false) {
                    assoc = 'null'
                }
                if (parseFloat(nbToken) - parseFloat(this.state.BCCValue) >= 0 && this.state.BCCValue !== '' && this.state.BCCValue !== null) {
                    Axios.post(url + "transaction", {
                        valorisation: this.state.valoToken,
                        nbToken: this.state.BCCValue,
                        iban: this.state.ibanValue,
                        bic: this.state.bicValue,
                        forAsso: this.state.giveAssocBool,
                        assoc: assoc,
                    }, {headers: {Authorization: "Bearer " + this.state.jwt}}).then((response) => {
                        if (response.data.jwt) {
                            sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                            jwt = response.data.jwt
                        }
                        setTimeout(function () {
                            this.props.navigate("/Ekoco/Statistiques")
                        }.bind(this), 2000);
                    }).catch(error => {
                        console.log(error);
                    });
                } else {
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Valeur de token non recevable.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                    this.setState({
                        openLoad: false
                    })
                }
            } else {
                Swal.fire({
                    title: 'Erreur!',
                    text: "Veuillez remplir tous les champs.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.setState({
                    openLoad: false
                })
            }
        }

    };

    handleSubmitContact=(event) => {
        const data= new FormData(event.target);
        let emailSender = data.get('champsEmail'), sujet = data.get('champsSujet'), message = data.get('champsMessage');
        event.preventDefault();
        this.setState({
            openLoad:true
        })
        Axios.post(url+"contact", {
            email : emailSender,
            sujet : sujet,
            message : message,
        }).then(response => {
            this.setState({
                openLoad:false
            })
            Swal.fire({
                title: 'Succès!',
                text: response.data,
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            this.handleCloseDialog()
        }).catch(() => {
            this.setState({
                openLoad:false
            })
            Swal.fire({
                title: 'Erreur!',
                text: "Erreur lors de l'envoi de l'email.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        });
    }

    handleCloseDialog(){
        this.setState({displayed:false})
    }

    sendFooterLink(e){
        this.props.navigate(
            {
                pathname: '/ekoco/BasCarboneBlockchain',
                link: e.target.id
            }
        )
    }

    render(){ //Affichage sur la page
        const { classes } = this.props;
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>
                <Dialog
                    open={this.state.displayed}
                    onClose={()=>{this.setState({displayed:false})}}
                >
                    <DialogTitle>Contactez nos administrateurs <span style={{color:"#6abfa2", fontWeight:"bold"}}>Ekoco</span> !</DialogTitle>
                    <form onSubmit={(e)=>{this.handleSubmitContact(e)}} noValidate autoComplete="off">
                        <DialogContent>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsEmail"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} id="champsClef" required={true} type={"email"} label="Entrez votre email" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmailContact}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsSujet"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} id="champsPseudo" required={true} label="Entrez votre sujet" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipSujet}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsMessage"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} multiline={true} rows={6} rowsMax={10} id="champsPrenom" required={true} label="Entrez un message" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipMessage}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <Button style={{padding:"10px", width:"100%",backgroundColor:"#5ecaab",color:"white"}} variant={"outlined"} type={"submit"} >Envoyer</Button>
                        </DialogContent>
                    </form>
                </Dialog>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end"}}>
                    <p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold"}}>{pseudo}</p>
                    <AccountCircleIcon style={{color:"#b0a9a8"}}/>
                    <Button onClick={()=>{this.setState({displayed:true})}} style={{marginLeft:this.state.marginLeftContact, marginRight:this.state.marginRightContact, backgroundColor:"#5ecaab", color:"white", padding:"10px 30px", fontWeight:"bold", zIndex:2}}>Contact</Button>
                </div>
                {/*CONTENT*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)"}}>
                    {isBrowser === true ? (
                        <div style={{display:"flex",flex:3, justifyContent:"flex-end", alignItems:"flex-end", zIndex:2}}>
                            <img style={{width:300, height:304}} src={require("../assetsEkoco/EKOCO_C EST_PICTO3.png")} alt={"Ekoco c'est quoi ?"}/>
                        </div>
                    ):('')}
                    <div style={{marginTop:this.state.marginTopContent,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",flex:2}}>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <p style={{fontWeight:"bold", fontSize:39, marginBottom:0}}>Gérez vos tokens</p>
                            <p style={{fontWeight:"bold", fontSize:39, marginTop:0}}>avec simplicité !</p>
                        </div>
                        <div style={{backgroundColor:"#e39e35", width:this.state.statsContentWidth, minWidth:this.state.statsContentMinWidth, maxWidth:this.state.statsContentMaxWidth, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:30, paddingTop:30, borderRadius:10}}>
                            <p style={{color:"white",fontWeight:"bold", fontSize:30, margin:0}}>Capital tokens</p>
                            <p style={{color:"white",fontWeight:"bold", fontSize:40, margin:0,marginBottom:5}}>{this.state.nbTokenInt},{this.state.nbTokenFloat}</p>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex",flexDirection:"column", width:"100%"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold", marginLeft:10}}>Valorisation du token</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                        <p style={{color:"#5ecaab", fontWeight:"bold", fontSize:30, margin:0}}>1 token = {this.state.valoToken}€</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex",flexDirection:"column", width:"100%"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold", marginLeft:10}}>Combien de token voulez-vous utiliser ?</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, alignItems:"center", justifyContent:"flex-end", marginRight:5, marginBottom:10}}>
                                        <TextField style={{width:80, marginBottom:12, marginRight:10, border:0}} name={"BCCValue"} value={this.state.BCCValue || ''} onChange={(e)=>{this.handleChange(e)}} placeholder={"xxxx"}
                                            InputProps={
                                                {style:{color:"#5ecaab",fontWeight:"bold",fontSize:30}}
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex",flexDirection:"column", width:"100%"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold", marginLeft:10}}>Voulez-vous donner à une association ?</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, alignItems:"center", justifyContent:"flex-end", marginRight:5, marginBottom:10}}>
                                        <Button name={"giveAssocOui"} onClick={(e)=>{this.handleGiveAssoc(e)}} style={{backgroundColor:this.state.giveAssocOuiBg, color:this.state.giveAssocOuiColor, border:"1px #5ecaab solid", fontWeight:"bold", margin:5}}>Oui</Button>
                                        <Button name={"giveAssocNon"} onClick={(e)=>{this.handleGiveAssoc(e)}} style={{backgroundColor:this.state.giveAssocNonBg, color:this.state.giveAssocNonColor, border:"1px #5ecaab solid", fontWeight:"bold", margin:5}}>Non</Button>
                                    </div>
                                </div>
                            </div>

                            {this.state.giveAssocBool ? (
                                <div style={{backgroundColor:"white",height:170, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                    <div style={{display:"flex",flexDirection:"column", width:"100%"}}>
                                        <div style={{display:"flex",flex:1}}>
                                            <p style={{color:"#b0a9a8", fontWeight:"bold", marginLeft:10}}>Associations :</p>
                                        </div>
                                        <div style={{display:"flex",flex:2,justifyContent:"center", alignItems:"center", marginBottom:20}}>
                                            <Button onClick={()=>{this.clickPreviousAssoc()}}>
                                                <ChevronLeftIcon/>
                                            </Button>
                                            <Paper elevation={3}>
                                                <div style={{display:"flex", alignItems:"center", minWidth:100, justifyContent:"space-between"}}>
                                                    <p style={{color:"#5ecaab", fontWeight:"bold", fontSize:20, margin:5}}>{this.state.nomAssoc}</p>
                                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipURL}>
                                                        <a href={"https://"+this.state.urlAssoc} target={"_blank"} style={{textDecoration:"none"}}><HelpOutlineIcon style={{marginLeft:5, marginRight:5, color:"#b0a9a8"}}/></a>
                                                    </Tooltip>
                                                </div>
                                            </Paper>
                                            <Button onClick={()=>{this.clickNextAssoc()}}>
                                                <ChevronRightIcon/>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ):(
                                <div style={{backgroundColor:"white",height:170, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                    <div style={{display:"flex",flexDirection:"column", width:"100%"}}>
                                        <div style={{display:"flex",flex:1}}>
                                            <p style={{color:"#b0a9a8", fontWeight:"bold", marginLeft:10}}>Coordonnées bancaires :</p>
                                        </div>
                                        <div style={{display:"flex",flex:2,flexDirection:"column",justifyContent:"center", alignItems:"center", marginBottom:20}}>
                                            <TextField label={"Iban"} style={{width:"90%", marginBottom:5 }} InputProps={{style:{color:"#5ecaab",fontWeight:"bold",fontSize:20}}} name={"ibanValue"} value={this.state.ibanValue === "Non enregistré" ? (""):(this.state.ibanValue) } onChange={(e)=>{this.handleChange(e)}} placeholder={this.state.ibanValue === "Non enregistré" ? ("Non enregistré"):("")}/>
                                            <TextField label={"Bic"} style={{width:"90%" }} InputProps={{style:{color:"#5ecaab",fontWeight:"bold",fontSize:20}}} name={"bicValue"} value={this.state.bicValue === "Non enregistré" ? (""):(this.state.bicValue)} onChange={(e)=>{this.handleChange(e)}} placeholder={this.state.bicValue === "Non enregistré" ? ("Non enregistré"):("")}/>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Button disabled={true} onClick={(e)=>{this.sendDemandeToken(e)}} style={{backgroundColor:true ? "#737373":"#5ecaab",padding:10, color:true ? "#ebebeb":"#FFFFFF", fontWeight:"bold", margin:5}}>Convertir mes tokens</Button>
                        </div>
                    </div>
                    {isBrowser === true ? (
                        <div style={{display:"flex",flex:3}}>
                            <img style={{width:450, height:419}} src={require("../assetsEkoco/STATISTIQUE2.png")} alt={"Ekoco statistiques"}/>
                        </div>
                    ):('')}
                </div>

                {/*FOOTER*/}
                <div style={{width:"100%", height:"100%",paddingTop:10, backgroundColor:"#f5f6f6", display:"flex",flexWrap:"wrap", marginTop:50, justifyContent:"center", alignItems:"center", zIndex:4}}>
                    <div style={{width:"90%", height:"90%",display:"flex", flexWrap:"wrap"}}>
                        {isBrowser === true ? (
                            <div style={{display:"flex", flex:3, width:250, height:250, justifyContent:"center", alignItems:"center"}}>
                                <img style={{width:285,height:200, textAlign:"center"}} src={require("../assetsEkoco/Ekoco.png")} alt="LogoEkoco"/>
                            </div>
                        ):("")}
                        <div style={{display:"flex", flex:3, flexDirection:"column", justifyContent:"center"}}>
                            <h1 className={"arialExtraBold"} style={{margin:0,color:"#2c2e35"}}>L’application qui fait</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>DU BIEN</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>À LA PLANÈTE</h1>
                        </div>
                        <div style={{display:"flex",flex:4}}>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=cestQuoi")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO c’est quoi ?</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=commentCaMarche")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Comment ça marche ?</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=reporting")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Un reporting détaillé</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=pourEntreprise")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO pour les entreprises</p>
                            </div>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/PolitiqueDeConfidentialite")}}>Politique de confidentialité</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/ConditionsGeneralesDutilisation")}}>CGU</p>
                                <p className={"arialArrow"} onClick={()=>{this.displayContact()}} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Contacts</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Utilisation des cookies</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Vos tokens sont en train d'être transferés ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default  withRouter(GestionTokens)