import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import {Add, BarChart as BCIcon, CheckBox, CheckBoxOutlineBlank, Delete} from "@mui/icons-material";
import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from 'recharts';

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const timeZoneOffsetHour = -(new Date().getTimezoneOffset()/60)

const columns = [
    { id: 'nom', label: 'Nom du vote', minWidth: "33%" },
    { id: 'dateDebut', label: 'Début du vote', minWidth: "33%" },
    { id: 'dateFin', label: 'Fin du vote', minWidth: "33%" },
];

const columnsGroup = [
    { id: 'nom', label: 'Nom du groupe', minWidth: "40%" },
    { id: 'description', label: 'Description', minWidth: "40%" },
    { id: 'status', label: '', minWidth: "20%" },
];

class GererVotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            listAllVotes:[],
            allGroups:[],
        };
    }

    setInitialStates(){
        return{
            addNewVoteDialog:false,
            selectedGroups:[],
            newVoteQuestions:[""],
            newVoteReponses:[["",""]],
            modifVoteQuestions:[""],
            modifVoteReponses:[["",""]],
            openDialogModifVote:false,
            selectedVote:{},
            allowModif:true,
            voteDone:false,
            showStatsDialog:false,
            dataVote:[]
        }
    }

    componentDidMount() {
        this.setState({
            hash: sessionStorage.getItem("SUFFRAGE_hash"),
            pseudo: sessionStorage.getItem("SUFFRAGE_pseudo"),
            jwt: sessionStorage.getItem("SUFFRAGE_jwt")
        },  () => {
            this.checkConnexion()
        })
    }

    sortBy(prop) {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    checkConnexion(){
        Axios.post(url+"connexionSuffrage",{
            empreinte:this.state.hash,
            hashJwt:this.state.hash
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "Connecté"){
                global.emitter.emit("onRouteChangedSuffrage",{
                    user:response.data.gestionnaire ? "gestionnaire":"client",
                    pseudo:sessionStorage.getItem("SUFFRAGE_pseudo")
                })
                this.getAllVotes()
                this.getAllClientAndGroup()
            }else{
                Toast.fire({
                    title: 'Utilisateur introuvable.',
                    icon: 'error',
                })
                window.location.replace("/Suffrage/Accueil")
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    getAllVotes(){
        Axios.post(url + "getAllVotes", {
            empreinte: sessionStorage.getItem("SUFFRAGE_hash"),
            hashJwt: sessionStorage.getItem("SUFFRAGE_hash")
        }, {headers: {authorization: "Bearer " + this.state.jwt}}).then(response => {
            if (response.data.resultat === 'done') {
                this.setState({listAllVotes: response.data.votes})
            } else {
                Toast.fire({
                    title: "Vous n'êtes pas autorisé à accéder à ces données.",
                    icon: 'error',
                })
            }
        }).catch(error => {
            console.log(error)
        });
    }

    getAllClientAndGroup(){
        Axios.post(url+"getGroupsAndClients",{
            empreinte:this.state.hash,
            hashJwt:this.state.hash
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                this.setState({allClients:response.data.users,allGroups:response.data.groups.sort(this.sortBy("nom"))})
            }else{
                Toast.fire({
                    title: 'Une erreur est survenue.',
                    icon: 'error',
                })
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    openDialogNewVote(){
        this.setState({addNewVoteDialog:true,allowModif:true,selectedGroups:[]})
        this.state.allGroups.forEach(group=>{
            this.setState({[group.idGroup+"select"]:false})
        })
    }

    onSelectVote(vote){
        this.state.allGroups.forEach(group=>{
            this.setState({[group.idGroup+"select"]:false})
        })
        let cloneSelectedGroup = [], actualDate = new Date(), dateVote = new Date(vote.dateDebut)
        actualDate = actualDate.getTime()
        dateVote = dateVote.getTime()
        if(dateVote < actualDate){
            this.setState({allowModif:false})
        }else{
            this.setState({allowModif:true})
        }
        if(new Date(vote.dateFin).getTime() > actualDate){
            this.setState({voteDone:false})
        }else{
            this.setState({voteDone:true})
        }
        vote.groups.forEach(group=>{
            cloneSelectedGroup.push(group)
            this.setState({[group.idGroup+"select"]:true})
        })
        let cloneVote = JSON.parse(JSON.stringify(vote))
        cloneVote.dateFin = new Date(cloneVote.dateFin)
        cloneVote.dateDebut = new Date(cloneVote.dateDebut)
        cloneVote.dateFin = new Date(cloneVote.dateFin.setHours((cloneVote.dateFin.getHours()+timeZoneOffsetHour))).toISOString() //On ajoute la différence de timezone au TS et on le reconverti en bon format (...T...Z)
        cloneVote.dateDebut = new Date(cloneVote.dateDebut.setHours((cloneVote.dateDebut.getHours()+timeZoneOffsetHour))).toISOString()
        this.setState({openDialogModifVote:true,selectedVote:cloneVote,selectedGroups:cloneSelectedGroup,modifVoteQuestions:vote.questions,modifVoteReponses:vote.reponses})
    }

    onSelectGroup(group){
        if(this.state.allowModif){
            let cloneSelectedGroups = this.state.selectedGroups
            if(cloneSelectedGroups.findIndex(elt => elt.idGroup === group.idGroup) === -1){
                cloneSelectedGroups.push(group)
            }else{
                cloneSelectedGroups.splice(cloneSelectedGroups.findIndex(elt => elt.idGroup === group.idGroup),1)
            }
            this.setState({
                [group.idGroup+"select"]:!this.state[group.idGroup+"select"],
                selectedGroups:cloneSelectedGroups
            })
        }
    }

    addToVote(index,action){
        if(this.state.allowModif){
            if(action === "question"){
                let cloneNewVoteReponses = this.state.newVoteReponses
                let cloneNewVoteQuestions = this.state.newVoteQuestions
                cloneNewVoteReponses.push(["",""])
                cloneNewVoteQuestions.push("")
                this.setState({
                    newVoteReponses:cloneNewVoteReponses
                },()=>{
                    this.setState({
                        newVoteQuestions:cloneNewVoteQuestions
                    })
                })
            }else{
                let cloneNewVoteReponses = this.state.newVoteReponses
                cloneNewVoteReponses[index].push("")
                this.setState({
                    newVoteReponses:cloneNewVoteReponses
                })
            }
        }
    }

    addToVoteEdit(index,action){
        if(this.state.allowModif){
            if(action === "question"){
                let cloneNewVoteReponses = this.state.modifVoteReponses
                let cloneNewVoteQuestions = this.state.modifVoteQuestions
                cloneNewVoteReponses.push(["",""])
                cloneNewVoteQuestions.push("")
                this.setState({
                    modifVoteReponses:cloneNewVoteReponses
                },()=>{
                    this.setState({
                        modifVoteQuestions:cloneNewVoteQuestions
                    })
                })
            }else{
                let cloneNewVoteReponses = this.state.modifVoteReponses
                cloneNewVoteReponses[index].push("")
                this.setState({
                    modifVoteReponses:cloneNewVoteReponses
                })
            }
        }
    }

    deleteFromVote(index,type){
        if(type === "question"){
            let cloneNewVoteReponses = this.state.modifVoteReponses.slice()
            let cloneNewVoteQuestions = this.state.modifVoteQuestions.slice()
            cloneNewVoteReponses.splice(index,1)
            cloneNewVoteQuestions.splice(index,1)
            this.setState({
                modifVoteQuestions:cloneNewVoteQuestions,
                modifVoteReponses:cloneNewVoteReponses
            })
        }else{
            const indexs = index.split("_")
            let cloneNewVoteReponses = this.state.modifVoteReponses.slice()
            cloneNewVoteReponses[indexs[0]].splice(indexs[1],1)
            this.setState({
                modifVoteReponses:cloneNewVoteReponses
            })
        }
    }
    deleteFromVoteEdit(index,type){
        if(type === "question"){
            let cloneNewVoteReponses = this.state.modifVoteReponses.slice()
            let cloneNewVoteQuestions = this.state.modifVoteQuestions.slice()
            cloneNewVoteReponses.splice(index,1)
            cloneNewVoteQuestions.splice(index,1)
            this.setState({
                modifVoteQuestions:cloneNewVoteQuestions,
                modifVoteReponses:cloneNewVoteReponses
            })
        }else{
            const indexs = index.split("_")
            let cloneNewVoteReponses = this.state.modifVoteReponses.slice()
            cloneNewVoteReponses[indexs[0]].splice(indexs[1],1)
            this.setState({
                modifVoteReponses:cloneNewVoteReponses
            })
        }
    }

    createVote(e){
        e.preventDefault()
        if (this.state.selectedGroups.length > 0){

            const timeHD = parseInt(this.state.champsHDVote.split(":")[0])-(parseInt(timeZoneOffsetHour))+":"+this.state.champsHDVote.split(":")[1]
            const timeHF = parseInt(this.state.champsHFVote.split(":")[0])-(parseInt(timeZoneOffsetHour))+":"+this.state.champsHFVote.split(":")[1]

            const fullTimeD=this.state.champsDDVote.split("T")[0] +"T"+timeHD+":00.000Z"
            const fullTimeF=this.state.champsDFVote.split("T")[0] +"T"+ timeHF+":00.000Z"

            Axios.post(url+"createVote",{
                empreinte:this.state.hash,
                nomVote:this.state.champsTitreVote,
                questions:this.state.newVoteQuestions,
                reponses:this.state.newVoteReponses,
                groups:this.state.selectedGroups,
                dateDebut:fullTimeD,
                dateFin:fullTimeF,
                hashJwt:this.state.hash
            },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                this.setState({addNewVoteDialog:false,...this.setInitialStates()})
                if (response.data.resultat === "done"){
                    Swal.fire({
                        title: 'Vote créé !',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                    let cloneListAllVotes = this.state.listAllVotes.slice()
                    cloneListAllVotes.push(response.data.newVote)
                    this.setState({listAllVotes:cloneListAllVotes})
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Une erreur est survenue.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }).catch(()=>{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Serveur injoignable.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
        }else{
            Swal.fire({
                text: "Veuillez choisir au moins un groupe.",
                icon: 'warning',
                target:".targetSwalSuffrage",
                confirmButtonText: 'Ok'
            })
        }
    }

    modifVote(e){
        e.preventDefault()
        let cloneSelectedVote = this.state.selectedVote
        let timeHD = this.state.champsHDVote ? (parseInt(this.state.champsHDVote.split(":")[0])-(parseInt(timeZoneOffsetHour)))+":"+this.state.champsHDVote.split(":")[1]:(parseInt(cloneSelectedVote.dateDebut.split("T")[1].split(":")[0])-(parseInt(timeZoneOffsetHour)))+":"+cloneSelectedVote.dateDebut.split("T")[1].split(":")[1]+":"+cloneSelectedVote.dateDebut.split("T")[1].split(":")[2]
        let timeHF = this.state.champsHFVote ? (parseInt(this.state.champsHFVote.split(":")[0])-(parseInt(timeZoneOffsetHour)))+":"+this.state.champsHFVote.split(":")[1]:(parseInt(cloneSelectedVote.dateFin.split("T")[1].split(":")[0])-(parseInt(timeZoneOffsetHour)))+":"+cloneSelectedVote.dateFin.split("T")[1].split(":")[1]+":"+cloneSelectedVote.dateFin.split("T")[1].split(":")[2]
        this.state.champsTitreVote && (cloneSelectedVote.nom = this.state.champsTitreVote)
        cloneSelectedVote.questions = this.state.modifVoteQuestions
        cloneSelectedVote.reponses = this.state.modifVoteReponses
        cloneSelectedVote.groups = this.state.selectedGroups
        cloneSelectedVote.dateDebut = (this.state.champsDDVote ? this.state.champsDDVote : cloneSelectedVote.dateDebut.split("T")[0]) +"T"+ (this.state.champsHDVote? timeHD+":00.000Z" : timeHD)
        cloneSelectedVote.dateFin = (this.state.champsDFVote ? this.state.champsDFVote : cloneSelectedVote.dateFin.split("T")[0]) +"T"+ (this.state.champsHFVote? timeHF+":00.000Z" : timeHF)

        Axios.post(url+"editVote",{
            empreinte:this.state.hash,
            donneesVote:cloneSelectedVote,
            hashJwt:this.state.hash
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            this.setState({openDialogModifVote:false,...this.setInitialStates()})
            if (response.data.resultat === "done"){
                Swal.fire({
                    title: 'Vote modifié !',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    this.getAllVotes()
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Une erreur est survenue.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeVote(event, type, indexQuestion, indexReponse){
        if (type === "question"){
            let questionClone = this.state.newVoteQuestions.slice()
            questionClone[indexQuestion] = event.currentTarget.value
            this.setState({
                newVoteQuestions : questionClone
            })
        }else{
            let responseClone = this.state.newVoteReponses.slice()
            responseClone[indexQuestion][indexReponse] = event.currentTarget.value
            this.setState({
                newVoteReponses : responseClone
            })
        }
    }

    handleChangeVoteModif(event, type, indexQuestion, indexReponse){
        if (type === "question"){
            let questionClone = this.state.modifVoteQuestions.slice()
            questionClone[indexQuestion] = event.currentTarget.value
            this.setState({
                modifVoteQuestions : questionClone
            })
        }else{
            let responseClone = this.state.modifVoteReponses.slice()
            responseClone[indexQuestion][indexReponse] = event.currentTarget.value
            this.setState({
                modifVoteReponses : responseClone
            })
        }
    }

    deleteVote(){
        Swal.fire({
            title: 'Attention!',
            text: "Voulez-vous vraiment supprimer ce vote ?",
            icon: 'warning',
            target:".targetSwalSuffrage",
            confirmButtonText: "Oui je le supprime",
            confirmButtonColor:"red",
            showCancelButton:true,
            cancelButtonText:"Retour",
            cancelButtonColor:"#6600ff"
        }).then((result)=>{
            if(result.isConfirmed){
                Axios.post(url+"deleteVote",{
                    empreinte:this.state.hash,
                    idVote:this.state.selectedVote.idVote,
                    hashJwt:this.state.hash
                },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        })
                    }
                    if (response.data.resultat === "done"){
                        let cloneListAllVotes = this.state.listAllVotes.slice()
                        cloneListAllVotes.splice(cloneListAllVotes.findIndex(elt => elt.idVote === this.state.selectedVote.idVote),1)
                        this.setState({listAllVotes:cloneListAllVotes})
                        this.setState({
                            openDialogModifVote:false,
                        },()=>{
                            this.setState({
                                selectedVote: {}
                            })
                        })
                        Toast.fire({
                            title: 'Vote correctement supprimé.',
                            icon: 'success',
                        })

                    }else{
                        Toast.fire({
                            title: 'Une erreur est survenue.',
                            target:".targetSwalSuffrage",
                            icon: 'error',
                        })
                    }
                }).catch(()=>{
                    this.setState({addNewVoteDialog:false})
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Serveur injoignable.",
                        target:".targetSwalSuffrage",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
    }

    showStatsVote(){
        Axios.post(url + "getVoteStats", {
            empreinte: sessionStorage.getItem("SUFFRAGE_hash"),
            idVote:this.state.selectedVote.idVote,
            reponses:this.state.reponseArray,
            hashJwt: sessionStorage.getItem("SUFFRAGE_hash")
        }, {headers: {authorization: "Bearer " + this.state.jwt}}).then(response => {
            if(response.data.stats.length === 0){
                Toast.fire({
                    title: "Aucune votant n'a participé à ce vote. Impossible de voir les résultats",
                    target:".targetSwalSuffrage",
                    icon: 'info',
                })
            }else{
                let stats = []
                let arrayVoteAnswer = response.data.stats
                let reponsesPossibles = this.state.modifVoteReponses.slice(),
                    questions = this.state.modifVoteQuestions.slice()
                questions.forEach((q,indexQ) => {
                    stats.push({question:q, reponses:[]})
                    reponsesPossibles[indexQ].forEach((r,indexR) => {
                        stats[indexQ].reponses.push({
                            label:r,
                            total:arrayVoteAnswer[indexQ][indexR] || 0
                        })
                    })
                })
                if (response.data.resultat === 'done') {
                    this.setState({dataVote:stats,showStatsDialog:true})
                } else {
                    Toast.fire({
                        title: "Vous n'êtes pas autorisé à accéder à ces données.",
                        target:".targetSwalSuffrage",
                        icon: 'error',
                    })
                }
            }
        }).catch(error => {
            console.log(error)
        });
    }

    handleClose(){
        //Remettre tous les states des groupes à false
        this.setState({selectedGroups:[]})
        this.state.allGroups.forEach(group => {
            this.setState({[group.idGroup + "select"]: false})
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%",overflow:"auto", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>

                <Dialog //CREATION VOTE
                    fullScreen={true}
                    open={this.state.addNewVoteDialog}
                    onClose={()=>{this.handleClose();this.setState({addNewVoteDialog:false})}}
                    style={{height:"calc(100% - 110px)",minWidth:1160,width:"98%",marginLeft:"1%",marginRight:"1%",marginTop:100,overflow:"auto"}}
                    className={"targetSwalSuffrage"}
                >
                    <DialogTitle>Création d'un vote</DialogTitle>
                    <DialogContent>

                        <form onSubmit={(e)=>{this.createVote(e)}}>
                            <div style={{cursor:"pointer",position:"absolute", right:10,top:5,display:"flex",justifyContent:"center",alignItems:"center",width:350}}>
                                <Button type={"submit"} style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:10}}>Créer le vote</Button>
                                <Button onClick={()=>{this.setState({addNewVoteDialog:false,newVoteQuestions:[""],newVoteReponses:[["",""]]})}} style={{backgroundColor:"#808080",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:10}} >Annuler</Button>
                            </div>
                            <div style={{display:"flex",height:"calc(100vh - 200px)",width:"100%", justifyContent:"center",alignItems:"center"}}>
                                <div style={{flex:0.4,height:"calc(100vh - 200px)",display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                                    <div style={{flex:0.8,width:"100%",height:"calc(40vh - 200px)",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <div style={{overflowY:"auto",minWidth:456,minHeight:"26vh",maxHeight:"30vh",width:"90%",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                            <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Informations du vote</h2></div>
                                            <div style={{justifyContent:"center",display:"flex",alingItems:"center",height:"100%",flexDirection:"column",padding:"10px 0 10px 0"}}>
                                                <TextField autoComplete={false} name={"champsTitreVote"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"text"} label="Titre du vote" variant="outlined" />
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                                                    <TextField style={{width:"100%",marginRight:5}} name={"champsDDVote"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"date"} label="Date de début du vote" variant="outlined"
                                                               InputLabelProps={{ shrink: true }}/>
                                                    <TextField style={{width:"100%",marginLeft:5}} name={"champsHDVote"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"time"} label="Horaire de début du vote" variant="outlined"
                                                               InputLabelProps={{ shrink: true }}/>
                                                </div>
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                                                    <TextField style={{width:"100%",marginRight:5}} name={"champsDFVote"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"date"} label="Date de fin du vote" variant="outlined"
                                                               InputLabelProps={{ shrink: true }}/>
                                                    <TextField style={{width:"100%",marginLeft:5}} name={"champsHFVote"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"time"} label="Horaire de fin du vote" variant="outlined"
                                                               InputLabelProps={{ shrink: true }}/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{flex:1.2,width:"100%",height:"calc(60vh - 200px)",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <div style={{overflowY:"auto",minWidth:456,minHeight:"42vh",width:"90%",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                            <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Selectionnez les groupes concernés</h2></div>
                                            <TableContainer style={{overflowY:"auto"}}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            {columnsGroup.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{ minWidth: column.minWidth,textAlign:"center", fontWeight:"bold"}}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.allGroups.map((row) => {
                                                            return (
                                                                <TableRow onClick={()=>{this.onSelectGroup(row)}} style={{cursor:"pointer", backgroundColor:this.state[row.idGroup + "select"] && "rgb(102,0,255)"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                    {columnsGroup.map((column) => {
                                                                        return (
                                                                            <>
                                                                                <TableCell style={{textAlign:"center", color:this.state[row.idGroup + "select"] && "white"}} key={column.id}>
                                                                                    {column.id === "status" ? (
                                                                                        <>
                                                                                            {this.state[row.idGroup + "select"] ? (
                                                                                                <CheckBox/>
                                                                                            ):(
                                                                                                <CheckBoxOutlineBlank/>
                                                                                            )}
                                                                                        </>
                                                                                    ):(
                                                                                        <>
                                                                                            {row[column.id]}
                                                                                        </>
                                                                                    )}
                                                                                </TableCell>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                                <div style={{flex:0.6,height:"calc(100vh - 200px)", display:"flex", justifyContent:"center",alignItems:"center" }}>
                                    <div style={{overflowY:"auto",minWidth:600,height:"96%",width:"90%",marginTop:-10,backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                        <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff",marginBottom:10}}><h2 style={{margin:5}}>Contenu du vote</h2></div>
                                        {this.state.newVoteQuestions.map((question,indexQuestion)=>(
                                            <div style={{display:"flex",justifyContent:"center",alignItems:"flex-end",flexDirection:"column",width:'100%'}}>
                                                <TextField style={{width:"90%",marginRight:50}} multiline={true} className={"champsTexte"} value={this.state.newVoteQuestions[indexQuestion]} onChange={(e)=>{this.handleChangeVote(e, "question", indexQuestion, "")}} required={true} type={"text"} label={"Question "+(indexQuestion+1)} variant="outlined"
                                                           InputProps={{
                                                               endAdornment:
                                                                   <>
                                                                       {this.state.newVoteQuestions.length>1 && (
                                                                           <InputAdornment position="end">
                                                                               <IconButton
                                                                                   onClick={()=>{this.deleteFromVote(indexQuestion,"question")}}
                                                                               >
                                                                                   <Delete/>
                                                                               </IconButton>
                                                                           </InputAdornment>
                                                                       )}
                                                                   </>
                                                           }}/>
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"flex-end",width:"100%",flexDirection:"column"}}>
                                                    {this.state.newVoteReponses[indexQuestion].map((reponse,indexReponse)=>(
                                                        <TextField style={{width:"80%",marginRight:50}} multiline={true} className={"champsTexte"} value={this.state.newVoteReponses[indexQuestion][indexReponse]} onChange={(e)=>{this.handleChangeVote(e, "reponse", indexQuestion, indexReponse)}} required={true} type={"text"} label={"Réponse "+(indexReponse+1)} variant="outlined"
                                                                   InputProps={{
                                                                       endAdornment:
                                                                           <>
                                                                                {this.state.newVoteReponses[indexQuestion].length>2 && (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton
                                                                                            onClick={()=>{this.deleteFromVote(indexQuestion+"_"+indexReponse,"reponse")}}
                                                                                        >
                                                                                            <Delete/>
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )}
                                                                           </>
                                                                   }}/>
                                                    ))}
                                                </div>
                                                <Button className={"buttonAddAnswer"} style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"50%",margin:"0 25% 10px 25%", fontWeight:"bold"}} onClick={()=>{this.addToVote(indexQuestion,"reponse")}}>Ajouter une réponse</Button>
                                            </div>
                                        ))}
                                            <Button style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"50%",margin:"0 25% 10px 25%", fontWeight:"bold"}} onClick={()=>{this.addToVote("noIndex","question")}}>Ajouter une question</Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>


                <Dialog //VISIONNER LES STATS D'UN VOTE
                    fullScreen={true}
                    open={this.state.showStatsDialog}
                    onClose={()=>{this.setState({showStatsDialog:false,selectedGroups:[]})}}
                    style={{height:"calc(100% - 110px)",minWidth:1160,width:"98%",marginLeft:"1%",marginRight:"1%",marginTop:100,overflow:"auto"}}
                >
                    <DialogTitle>Statistiques du vote</DialogTitle>
                    <DialogContent >

                        <div style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
                            <div style={{cursor:"pointer",position:"absolute", right:10,top:5,display:"flex",justifyContent:"center",alignItems:"center",width:300}}>
                                <Button onClick={()=>{this.setState({showStatsDialog:false,selectedGroups:[]})}} style={{backgroundColor:"#808080",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:10}} >Retour</Button>
                            </div>
                            <div style={{display:"flex",height:"calc(100vh - 200px)",width:"50%", justifyContent:"center",alignItems:"center", flexDirection:"column"}}>

                                {this.state.modifVoteQuestions.map((question,indexQuestion)=>(
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",width:'100%'}}>
                                        <div style={{overflow:"auto",margin:10,borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",display:"flex",justifyContent:"center",alignItems:"flex-end",flexDirection:"column",width:'100%'}}>
                                            <div style={{width:"100%",justifyContent:"flex-start",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff",marginBottom:10}}><h2 style={{margin:5}}>{"Question n°"+(indexQuestion+1)+" : "+question}</h2></div>
                                            <div style={{display:"flex",justifyContent:"center",alignItems:"flex-start",width:"100%",flexDirection:"column"}}>
                                                <BarChart
                                                    width={500}
                                                    height={300}
                                                    data={this.state.dataVote[indexQuestion]&&this.state.dataVote[indexQuestion].reponses}
                                                    margin={{
                                                        top: 5,
                                                        right: 30,
                                                        left: 20,
                                                        bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="label" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Bar dataKey="total" fill="#8884d8" />
                                                </BarChart>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </DialogContent>
                </Dialog>


                <Dialog //MODIF VOTE
                    fullScreen={true}
                    open={this.state.openDialogModifVote}
                    onClose={()=>{this.handleClose();this.setState({openDialogModifVote:false})}}
                    style={{height:"calc(100% - 110px)",minWidth:1160,width:"98%",marginLeft:"1%",marginRight:"1%",marginTop:100,overflow:"auto"}}
                    className={"targetSwalSuffrage"}
                >
                    <DialogTitle>Modifier un vote</DialogTitle>
                    <DialogContent>
                        <form onSubmit={(e)=>{this.modifVote(e)}}>
                            <div style={{cursor:"pointer",position:"absolute", right:10,top:5,display:"flex",justifyContent:"center",alignItems:"center",width:!this.state.allowModif ? (this.state.voteDone ? 800:250):800}}>
                                {this.state.voteDone ? (
                                    <>
                                        <Button onClick={()=>{this.showStatsVote()}} style={{backgroundColor:"#6600FFFF",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:10}}>Voir les résultats <BCIcon style={{marginLeft:10}}/></Button>
                                        <Button onClick={()=>{this.deleteVote()}} style={{backgroundColor:"#FF1313FF",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:10}}>Supprimer ce vote <Delete style={{marginLeft:10}}/></Button>
                                    </>
                                ):(
                                    <>
                                        {this.state.allowModif && (
                                            <>
                                                <Button type={"submit"} style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:10}}>Modifier le vote</Button>
                                                <Button onClick={()=>{this.deleteVote()}} style={{backgroundColor:"#FF1313FF",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:10}}>Supprimer ce vote <Delete style={{marginLeft:10}}/></Button>
                                            </>
                                        )}
                                    </>
                                )}
                                <Button onDoubleClick={()=>{this.showStatsVote()}} onClick={()=>{this.setState({openDialogModifVote:false})}} style={{backgroundColor:"#808080",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:10}} >Retour</Button>
                            </div>
                            <div style={{display:"flex",height:"calc(100vh - 200px)",width:"100%", justifyContent:"center",alignItems:"center"}}>
                                <div style={{flex:0.4,height:"calc(100vh - 200px)",display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                                    <div style={{flex:0.8,width:"100%",height:"calc(40vh - 200px)",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <div style={{overflowY:"auto",minWidth:456,minHeight:"26vh",maxHeight:"30vh",width:"90%",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                            <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Informations du vote</h2></div>
                                            <div style={{justifyContent:"center",display:"flex",alingItems:"center",height:"100%",flexDirection:"column",padding:"10px 0 10px 0"}}>
                                                <TextField autoComplete={false} name={"champsTitreVote"} disabled={!this.state.allowModif} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} defaultValue={this.state.selectedVote.nom} type={"text"} label="Titre du vote" variant="outlined" />
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                                                    <TextField style={{width:"100%",marginRight:5}} disabled={!this.state.allowModif} name={"champsDDVote"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} defaultValue={this.state.selectedVote.dateDebut && this.state.selectedVote.dateDebut.split("T")[0]} type={"date"} label="Date de début du vote" variant="outlined"
                                                               InputLabelProps={{ shrink: true }}/>
                                                    <TextField style={{width:"100%",marginLeft:5}} disabled={!this.state.allowModif} name={"champsHDVote"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} defaultValue={this.state.selectedVote.dateDebut && this.state.selectedVote.dateDebut.split("T")[1].substring(0,5)} type={"time"} label="Horaire de début du vote" variant="outlined"
                                                               InputLabelProps={{ shrink: true }}/>
                                                </div>
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                                                    <TextField style={{width:"100%",marginRight:5}} disabled={!this.state.allowModif} name={"champsDFVote"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} defaultValue={this.state.selectedVote.dateFin && this.state.selectedVote.dateFin.split("T")[0]} type={"date"} label="Date de fin du vote" variant="outlined"
                                                               InputLabelProps={{ shrink: true }}/>
                                                    <TextField style={{width:"100%",marginLeft:5}} disabled={!this.state.allowModif} name={"champsHFVote"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} defaultValue={this.state.selectedVote.dateFin && this.state.selectedVote.dateFin.split("T")[1].substring(0,5)} type={"time"} label="Horaire de fin du vote" variant="outlined"
                                                               InputLabelProps={{ shrink: true }}/>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{flex:1.2,width:"100%",height:"calc(60vh - 200px)",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <div style={{overflowY:"auto",minWidth:456,minHeight:"42vh",width:"90%",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                            <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Selectionnez les groupes concernés</h2></div>
                                            <TableContainer style={{overflowY:"auto"}}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            {columnsGroup.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{ minWidth: column.minWidth,textAlign:"center", fontWeight:"bold"}}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.allGroups.map((row) => {
                                                            return (
                                                                <TableRow onClick={()=>{this.onSelectGroup(row)}} style={{cursor:"pointer", backgroundColor:this.state[row.idGroup + "select"] && "rgb(102,0,255)"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                    {columnsGroup.map((column) => {
                                                                        return (
                                                                            <>
                                                                                <TableCell style={{textAlign:"center", color:this.state[row.idGroup + "select"] && "white"}} key={column.id}>
                                                                                    {column.id === "status" ? (
                                                                                        <>
                                                                                            {this.state[row.idGroup + "select"] ? (
                                                                                                <CheckBox/>
                                                                                            ):(
                                                                                                <CheckBoxOutlineBlank/>
                                                                                            )}
                                                                                        </>
                                                                                    ):(
                                                                                        <>
                                                                                            {row[column.id]}
                                                                                        </>
                                                                                    )}
                                                                                </TableCell>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                                <div style={{flex:0.6,height:"calc(100vh - 200px)", display:"flex", justifyContent:"center",alignItems:"center" }}>
                                    <div style={{overflowY:"auto",minWidth:600,height:"96%",width:"90%",marginTop:-10,backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                        <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff",marginBottom:10}}><h2 style={{margin:5}}>Contenu du vote</h2></div>
                                        {this.state.modifVoteQuestions.map((question,indexQuestion)=>(
                                            <div style={{display:"flex",justifyContent:"center",alignItems:"flex-end",flexDirection:"column",width:'100%'}}>
                                                <TextField style={{width:"90%",marginRight:50}} disabled={!this.state.allowModif} multiline={true} className={"champsTexte"} defaultValue={this.state.modifVoteQuestions[indexQuestion]} value={this.state.modifVoteQuestions[indexQuestion]} onChange={(e)=>{this.handleChangeVoteModif(e, "question", indexQuestion, "")}} required={true} type={"text"} label={"Question "+(indexQuestion+1)} variant="outlined"
                                                           InputProps={{
                                                               endAdornment:
                                                                   <>
                                                                       {this.state.modifVoteQuestions.length>1 && (
                                                                           <InputAdornment position="end">
                                                                               <IconButton
                                                                                   onClick={()=>{this.deleteFromVoteEdit(indexQuestion,"question")}}
                                                                                   disabled={!this.state.allowModif}
                                                                               >
                                                                                   <Delete/>
                                                                               </IconButton>
                                                                           </InputAdornment>
                                                                       )}
                                                                   </>
                                                           }}/>
                                                <div style={{display:"flex",justifyContent:"center",alignItems:"flex-end",width:"100%",flexDirection:"column"}}>
                                                    {this.state.modifVoteReponses[indexQuestion].map((reponse,indexReponse)=>(
                                                        <TextField style={{width:"80%",marginRight:50}} disabled={!this.state.allowModif} multiline={true} className={"champsTexte"} defaultValue={this.state.modifVoteReponses[indexQuestion][indexReponse]} value={this.state.modifVoteReponses[indexQuestion][indexReponse]} onChange={(e)=>{this.handleChangeVoteModif(e, "reponse", indexQuestion, indexReponse)}} required={true} type={"text"} label={"Réponse "+(indexReponse+1)} variant="outlined"
                                                                   InputProps={{
                                                                       endAdornment:
                                                                           <>
                                                                               {this.state.modifVoteReponses[indexQuestion].length>2 && (
                                                                                   <InputAdornment position="end">
                                                                                       <IconButton
                                                                                           onClick={()=>{this.deleteFromVoteEdit(indexQuestion+"_"+indexReponse,"reponse")}}
                                                                                           disabled={!this.state.allowModif}
                                                                                       >
                                                                                           <Delete/>
                                                                                       </IconButton>
                                                                                   </InputAdornment>
                                                                               )}
                                                                           </>
                                                                   }}/>
                                                    ))}
                                                </div>
                                                <Button className={"buttonAddAnswer"} style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"50%",margin:"0 25% 10px 25%", fontWeight:"bold"}} onClick={()=>{this.addToVoteEdit(indexQuestion,"reponse")}}>Ajouter une réponse</Button>
                                            </div>
                                        ))}
                                        <Button style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"50%",margin:"0 25% 10px 25%", fontWeight:"bold"}} onClick={()=>{this.addToVoteEdit("noIndex","question")}}>Ajouter une question</Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>

                <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                    <div style={{overflowY:"auto",maxHeight:"60vh",height:"40vh",width:"70vw",minWidth:"800px",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                        <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Liste des votes</h2></div>
                        <TableContainer style={{overflowY:"auto"}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth,textAlign:"center", fontWeight:"bold"}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={"AjouterUnGroupe"}>
                                        <TableCell onClick={()=>{this.openDialogNewVote()}} colSpan={columns.length+1} style={{textAlign:"center"}} key={"ajoutGroupe"}>
                                            <p style={{display:"flex",justifyContent:"center",alignItems:"center", margin:0}}>Ajouter un vote <Add/></p>
                                        </TableCell>
                                    </TableRow>
                                    {this.state.listAllVotes.map((row) => {
                                        return (
                                            <TableRow onClick={()=>{this.onSelectVote(row)}} style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columns.map((column) => {
                                                    return (
                                                        <>
                                                            <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                {column.id === "nom" ? (
                                                                    <>
                                                                        {row[column.id]}
                                                                    </>
                                                                ):(
                                                                    <>
                                                                        {(new Date(row[column.id]).toLocaleString("fr-FR", {timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone})).replace(","," à")}
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                        </>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(GererVotes)