import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import PayPalButton from "../../Components/PaypalButton";
import config from "../../json/config.json";

import Axios from "axios";
import Swal from "sweetalert2";
import {
    CalendarMonth,
    Call,
    Close,
    Description,
    Edit,
    FormatListBulleted,
    Image as ImageIcon,
    Info,
    InsertDriveFile,
    KeyboardReturn,
    Visibility,
} from "@mui/icons-material";
import Dropzone from "react-dropzone";
import jwt_decode from "jwt-decode";
import {Backdrop, CircularProgress, InputAdornment, LinearProgress, Slider, TextField, Tooltip} from "@mui/material";
import ReactCrop from "react-image-crop";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Download from "@mui/icons-material/Download";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const {baseUrl, paypalPublicKey, suffix} = config;
const url = baseUrl + suffix
let jwtDecoded;

class EntrepriseSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true,
            connected:false
        };
        this.createOrderPaypal = this.createOrderPaypal.bind(this);

    }

    setInitialStates(){
        return{
            illusBuffer:null,
            illusBufferLOGO:null,
            crop: {
                unit: 'px',
                x: 0,
                y: 0,
                width: 448,
                height: 300
            },
            cropLOGO: {
                unit: 'px',
                x: 0,
                y: 0,
                width: 200,
                height: 200
            },
            croppedBuffer:null,
            croppedBufferLOGO:null,
            empreinteAvatar:null,
            empreinteAvatarLOGO:null,
            modifyIllus:true,
            modifyIllusLOGO:true,
            entreprise:{},
            modifyFundRaisingBool:false,
            allFiles:[],
            valueSlider:null,
            listInvestor:[],
            actionAlreadyBought:0,
            phaseNew:1,
            showBackdrop:false,
            clickedNewLDF:false,
            disableButtonDlAllFiles:false,
            displayPaypalPayment:false
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: this.state.jwt,
                    })
                    this.setState({connected:true, admin: jwtDecoded.admin})
                    if(this.state.td === "new"){
                        this.verifyAccountCompleted()
                        this.getInfos()
                    }else{
                        this.getCompanyProof()
                        this.getFundRaising(this.state.td)
                        this.getListInvestor(this.state.td)
                    }
                }else{
                    global.emitter.emit("askConnexionSMSTO",{disableOutsideClick:true})
                }
            })
        })
    }
    _emitConnectedBR
    init(){
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        let td = new URLSearchParams(window.location.search).get("td");
        if(td !== null){
            this.setState({
                td,
            })
        }else{
            Swal.fire({
                title: 'Erreur !',
                text: "IdToken de l'entreprise non valide.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            this.props.navigate("/StockMarketSTO/Accueil")
        }
        this._emitConnectedBR = global.emitter.addListener("emitConnectedSMSTO",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("SMSTO_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedStockMarketSTO", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    componentDidMount() {
        this.init()
    }

    getInfos(){
        Axios.post(url+"getInfosSMSTO",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                this.setState({
                    defaultEmailValue:response.data.email,
                    emailValue:response.data.email,
                    defaultTelValue: response.data.tel,
                    telValue:response.data.tel,
                    statusMaritalValue:response.data.statusMarital,
                    defaultStatusMaritalValue:response.data.statusMarital,
                    adresseValue:response.data.adresse,
                    defaultAdresseValue:response.data.adresse,
                    nomValue:response.data.nom,
                    defaultNomValue:response.data.nom,
                    prenomValue:response.data.prenom,
                    defaultPrenomValue:response.data.prenom,
                    pseudoValue:response.data.pseudo,
                    defaultVilleValue:response.data.ville,
                    villeValue:response.data.ville,
                    defaultPostalValue:response.data.postal,
                    postalValue:response.data.postal,
                    defaultDobValue:response.data.ddn,
                    dobValue:response.data.ddn,
                    defaultWebsiteValue:response.data.website,
                    websiteValue:response.data.website
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    getListInvestor(){
        Axios.post(url+"getInvestors",{siren:this.state.td},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let listInvestors = response.data.investors
                let indexInvestor = listInvestors.findIndex(elt=>elt.empreinte === jwtDecoded.empreinte)
                if (listInvestors.length > 0){
                    if(indexInvestor !== -1){
                        this.setState({actionAlreadyBought:listInvestors[indexInvestor].boughtActions})
                    }
                    this.setState({listInvestor:response.data.investors.sort((a, b) => (a.boughtActions < b.boughtActions) ? 1 : -1)})
                }else{
                    this.setState({listInvestor:[]})

                }
            }else{
                this.setState({listInvestor:response.data.resultat})
            }
        })
    }

    getCompanyProof(){
        Axios.post(url+"getCompanyProof",{siren:this.state.td},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let allFilesTemp = response.data.allFiles
                let allFilesObj = []
                allFilesTemp.forEach(elt=>{
                    const ext = elt.split(".")[elt.split(".").length-1]
                    let type = "fichier"
                    const listImages = ["png","jpg","jpeg","gif"]
                    if (listImages.includes(ext)){
                        type = "image"
                    }else if(ext === "pdf"){
                        type = "pdf"
                    }else{
                        type = "fichier"
                    }
                    allFilesObj.push({type:type,fileName:elt})
                })
                this.setState({allFiles:allFilesObj})
            }
        })
    }

    verifyAccountCompleted(){
        Axios.post(url+"verifyCompletedAccount",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat !== "complete"){
                Swal.fire({
                    title: 'Attention !',
                    text: "Il manque des informations a votre compte. Veuillez les remplir avant d'effectuer une action sur StockMarketSTO",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
                this.props.navigate("/StockMarketSTO/Profil")
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeDesc(event){
        if(event.currentTarget.value.length < 500){
            this.setState({
                [event.currentTarget.name] : event.currentTarget.value
            })
        }else{
            Toast.fire({
                icon: 'error',
                title: 'Taille maximale atteinte pour la description (500 caractères)'
            })
        }
    }

    handleChangePhone(event){
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if(onlyNums.length < 10){
            this.setState({
                [event.currentTarget.name] : onlyNums
            })
        } else if (onlyNums.length === 10){
            const number = onlyNums.replace(
                /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                '$1 $2 $3 $4 $5'
            );
            this.setState({
                [event.currentTarget.name] : number
            })
        }
    }

    getMinMaxDateLDF(td){
        let toReturn, entreprise
        if(td === "debutMin"){ //Minimum de la date de début : 1 semaine après aujourd'hui
            let debutDateMin = new Date()
            debutDateMin.setDate(debutDateMin.getDate())
            debutDateMin = debutDateMin.toISOString().split("T")[0]
            toReturn = debutDateMin
        }else if(td === "debutMax"){ //Maximum de la date de début
            if(this.state.finValue){ //Si on a déjà mis une date de fin : date de fin - 1 jour (pour laisser 1 jour de marge minimum)
                let debutDateMax = new Date(this.state.finValue)
                if(!isNaN(debutDateMax.getTime())){
                    debutDateMax.setDate(debutDateMax.getDate()-1)
                    debutDateMax = debutDateMax.toISOString().split("T")[0]
                    toReturn = debutDateMax
                }else{
                    toReturn = ""
                }
            }else{ //Sinon pas de maximum
                toReturn = ""
            }
        }else if(td === "finMin"){ //Minimum de la date de fin
            if(this.state.debutValue){ //Si on a une date de début : date de début + 1 (pour laisser 1 jour de marge minimum)
                let finDateMin = new Date(this.state.debutValue)
                if(!isNaN(finDateMin.getTime())){
                    finDateMin.setDate(finDateMin.getDate()+1)
                    finDateMin = finDateMin.toISOString().split("T")[0]
                    toReturn = finDateMin
                }else{
                    toReturn = ""
                }
            }else{ //Sinon : 1 semaine et 1 jour après aujourd'hui (pour laisser 1 jour de marge minimum)
                let finDateMin = new Date()
                finDateMin.setDate(finDateMin.getDate()+1)
                finDateMin = finDateMin.toISOString().split("T")[0]
                toReturn = finDateMin
            }
        }else if(td === "finMax"){ //Pas de date maximum pour la date de fin
            toReturn = ""
        }

        return this.state.noMoreVerif ? "":toReturn
        // return "" //POUR TESTER
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    handleChangeIBAN(event){
        const dataIban = event.target.value.replaceAll(" ","")
        if(dataIban.length < 27){
            this.setState({
                [event.currentTarget.name] : dataIban
            })
        } else if (dataIban.length === 27){
            const number = dataIban.replace(
                /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})/,
                '$1 $2 $3 $4 $5 $6 $7'
            );
            this.setState({
                [event.currentTarget.name] : number
            })
        }else{
            this.setState({
                [event.currentTarget.name] : dataIban
            })
        }
    }

    cropComplete = (todo) =>{
        this.getCroppedImg(this.state["illusBuffer"+todo],todo)
    }

    onCropChange = (crop,todo) => {
        this.setState({ ["crop"+todo]:crop });
    };

    getCroppedImg(imageParam,todo) {
        let image = new Image()
        image.src = imageParam
        //On cap la height a 300 mais pas la width -> on cherche a savoir la width de l'image sur notre page
        let imageMinia = document.getElementById('imageMinia');
        let realWidth = imageMinia.clientWidth
        let realHeight = imageMinia.clientHeight

        const canvas = document.createElement("canvas");
        const scaleX = image.width / realWidth;
        const scaleY = image.height / realHeight;
        canvas.width = (todo === "LOGO" ? 200:448);
        canvas.height = (todo === "LOGO" ? 200:300);
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            this.state["crop"+todo].x * scaleX,
            this.state["crop"+todo].y * scaleY,
            this.state["crop"+todo].width * scaleX,
            this.state["crop"+todo].height * scaleY,
            0,
            0,
            todo === "LOGO" ? 200:448,
            todo === "LOGO" ? 200:300
        )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.jpg',todo)
            }
        })
    }

    dataURLtoFile(dataurl, filename,todo) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImage = new File([u8arr], filename, {type:mime});
        let self = this
        const reader = new FileReader()
        reader.onload = () => {
            self.setState({["croppedBuffer"+todo]:reader.result})
        }
        reader.readAsArrayBuffer(croppedImage)
    }

    validateCandidature(e){
        this.setState({clickedNewLDF:true})
        e.preventDefault()
        let nomEntreprise = this.state.nomEntrepriseValue,
            codePostal = this.state.codePostalValue,
            siren = this.state.sirenValue,
            iban = this.state.ibanValue ? this.state.ibanValue.replaceAll(" ",""):this.state.ibanValue,
            bic = this.state.bicValue,
            dateDebut = this.state.debutValue,
            dateFin = this.state.finValue,
            actionValue = this.state.priceActionValue,
            numberActions = this.state.nombreActionValue,
            seuilMin = this.state.seuilMinValue,
            minActions = this.state.nbrActionMinValue,
            maxActions = this.state.nbrActionMaxValue,
            desc = this.state.descriptionValue,
            email = this.state.emailCompanyValue,
            tel = this.state.telCompanyValue,
            website = this.state.websiteValue

        Axios.post(url+"startFundRaising",{nomEntreprise,codePostal,siren,iban,bic,dateDebut,dateFin,numberActions,actionValue,seuilMin,minActions,maxActions,email,tel,website,desc,buffer:Buffer.from(this.state.croppedBuffer,"base64"), logo:Buffer.from(this.state.croppedBufferLOGO,"base64")},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "done"){
                Swal.fire({
                    title: 'Succès !',
                    text: "Votre demande a été transmise à nos services.",
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    showDenyButton:true,
                    denyButtonColor:"#73BDECFF",
                    denyButtonText:"Ajouter des documents"
                }).then((result)=>{
                    if(result.isDenied){
                        this.props.navigate("/StockMarketSTO/EchangeFichier?td="+this.state.sirenValue)
                    }else{
                        this.props.navigate("/StockMarketSTO/Entreprise?td="+this.state.sirenValue)
                        this.setState({...this.setInitialStates})
                        this.setState({modifyFundRaisingBool:true},()=>{
                            this.init()
                        })
                    }
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text:  response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    this.setState({clickedNewLDF:false})
                })
            }
        })
    }

    getFundRaising(siren){
        Axios.post(url+"getFundRaisingInfos",{siren},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "done"){
                let entrepriseTemp = response.data.infos
                if ((!entrepriseTemp.owner && entrepriseTemp.status !== "inProgress" ) && this.state.admin !== true){
                    Swal.fire({
                        title: 'Attention !',
                        text: "Vous n'avez pas accès à cette levée de fonds.",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        this.props.navigate(-1)
                    })
                }else{
                    if(entrepriseTemp.owner){
                        let ibanValue = entrepriseTemp.iban,
                            bicValue = entrepriseTemp.bic,
                            debutValue = entrepriseTemp.dateDebut,
                            finValue = entrepriseTemp.dateFin,
                            priceActionValue = entrepriseTemp.actionValue,
                            nombreActionValue = entrepriseTemp.numberActions,
                            seuilMinValue = entrepriseTemp.seuilMin,
                            nbrActionMinValue = entrepriseTemp.minActions,
                            nbrActionMaxValue = entrepriseTemp.maxActions,
                            descriptionValue = entrepriseTemp.desc,
                            emailCompanyValue = entrepriseTemp.email,
                            telCompanyValue = entrepriseTemp.tel,
                            websiteValue = entrepriseTemp.website;
                        this.setState({
                            ibanValue,bicValue,debutValue,finValue,priceActionValue,nombreActionValue,seuilMinValue,nbrActionMinValue,nbrActionMaxValue,descriptionValue,emailCompanyValue,telCompanyValue,websiteValue
                        })
                        this.setState({
                            defaultIbanValue:ibanValue,defaultBicValue:bicValue,defaultDebutValue:debutValue,
                            defaultFinValue:finValue,defaultPriceActionValue:priceActionValue,defaultNombreActionValue:nombreActionValue,
                            defaultSeuilMinValue:seuilMinValue,defaultNbrActionMinValue:nbrActionMinValue,defaultNbrActionMaxValue:nbrActionMaxValue,
                            defaultDescriptionValue:descriptionValue,defaultEmailCompanyValue:emailCompanyValue,defaultTelCompanyValue:telCompanyValue,
                            defaultWebsiteValue:websiteValue
                        })
                    }

                    /*On calcule le nombre de jours entre le début et la fin de la levée de fonds*/
                    const diffInMsTotal   = new Date(entrepriseTemp.dateFin) - new Date(entrepriseTemp.dateDebut)
                    entrepriseTemp.joursTotaux = (diffInMsTotal / (1000 * 60 * 60 * 24))+1

                    /*On calcul le nombre de jours entre aujourd'hui et la fin de la levée de fonds*/
                    const diffInMs   = new Date(entrepriseTemp.dateFin) - new Date(Date.now())
                    entrepriseTemp.joursRestants = parseInt(diffInMs / (1000 * 60 * 60 * 24))+1

                    /*Calcul pourcentage du de l'avancement de la levée de fonds (date)*/
                    entrepriseTemp.percentDate = (entrepriseTemp.status === "notStarted" || entrepriseTemp.status === "unconfirmed") ? ( /*Si ça n'as pas encore démarré*/
                        0
                    ):(
                        entrepriseTemp.status === "done" ? ( /*Si la levée de fonds est terminée*/
                            100
                        ):(
                            parseInt((entrepriseTemp.joursTotaux - entrepriseTemp.joursRestants)*100/entrepriseTemp.joursTotaux) /*Si c'est en cours*/
                        )
                    )
                    /*Calcul pourcentage du de l'avancement de la levée de fonds (actions achetées)*/
                    entrepriseTemp.numberActions = parseInt(entrepriseTemp.numberActions)
                    entrepriseTemp.percentReserved = (this.state.entreprise.status === "notStarted" || this.state.entreprise.status === "unconfirmed") ? ( /*Si ça n'as pas encore démarré*/
                        0
                    ):(
                        parseFloat(entrepriseTemp.reservedActions*100/entrepriseTemp.numberActions) /*Si c'est en cours ou terminé*/
                    )
                    entrepriseTemp.percentReserved = Math.ceil(entrepriseTemp.percentReserved)
                    /*Format Date Début*/
                    entrepriseTemp.dateDebut = entrepriseTemp.dateDebut.split("-")
                    entrepriseTemp.dateDebut = entrepriseTemp.dateDebut.reverse()
                    entrepriseTemp.dateDebut = entrepriseTemp.dateDebut.join("/")

                    /*Format date Fin*/
                    entrepriseTemp.dateFin = entrepriseTemp.dateFin.split("-")
                    entrepriseTemp.dateFin = entrepriseTemp.dateFin.reverse()
                    entrepriseTemp.dateFin = entrepriseTemp.dateFin.join("/")

                    this.setState({entreprise:entrepriseTemp,modifyIllus:false,modifyIllusLOGO:false})
                }
            }else{
                Swal.fire({
                    title: 'Erreur !',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.props.navigate("/StockMarketSTO/Accueil")
            }
        })
    }

    modifyFundRaising(e){
        e.preventDefault()
        let iban = this.state.ibanValue ? this.state.ibanValue.replaceAll(" ",""):this.state.ibanValue,
            bic = this.state.bicValue,
            dateDebut = this.state.debutValue,
            dateFin = this.state.finValue,
            actionValue = this.state.priceActionValue,
            numberActions = this.state.nombreActionValue,
            seuilMin = this.state.seuilMinValue,
            minActions = this.state.nbrActionMinValue,
            maxActions = this.state.nbrActionMaxValue,
            desc = this.state.descriptionValue,
            email = this.state.emailCompanyValue,
            tel = this.state.telCompanyValue,
            website = this.state.websiteValue,
            buffer = this.state.croppedBuffer ? (Buffer.from(this.state.croppedBuffer,"base64")):null,
            logo = this.state.croppedBufferLOGO ? (Buffer.from(this.state.croppedBufferLOGO,"base64")):null,
            siren=this.state.td

        Axios.post(url+"editFundRaising",{siren,seuilMin,minActions,maxActions,iban,bic,dateDebut,dateFin,numberActions,actionValue,tel,email,desc, website,buffer,logo},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "done"){
                this.setState({
                    defaultIbanValue:this.state.ibanValue,defaultBicValue:this.state.bicValue,defaultDebutValue:this.state.debutValue,
                    defaultFinValue:this.state.finValue,defaultPriceActionValue:this.state.priceActionValue,defaultNombreActionValue:this.state.nombreActionValue,
                    defaultSeuilMinValue:this.state.seuilMinValue,defaultNbrActionMinValue:this.state.nbrActionMinValue,defaultNbrActionMaxValue:this.state.nbrActionMaxValue,
                    defaultDescriptionValue:this.state.descriptionValue,defaultEmailCompanyValue:this.state.emailCompanyValue,defaultTelCompanyValue:this.state.telCompanyValue,
                    defaultWebsiteValue:this.state.websiteValue
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Informations de la levée de fonds modifiées !'
                })
                let entrepriseTemp = this.state.entreprise;
                /*Format Date Début*/
                dateDebut = dateDebut.split("-")
                dateDebut = dateDebut.reverse()
                dateDebut = dateDebut.join("/")

                /*Format date Fin*/
                dateFin = dateFin.split("-")
                dateFin = dateFin.reverse()
                dateFin = dateFin.join("/")

                let object1 = {seuilMin,minActions,maxActions,iban,bic,dateDebut,dateFin,numberActions,actionValue,desc},
                    finalObject= {...entrepriseTemp, ...object1}
                this.setState({entreprise:finalObject, croppedBuffer:null, croppedBufferLOGO:null})
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Une erreur est survenue.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    validButtonModify(){
        let iban = this.state.defaultIbanValue === this.state.ibanValue,
            bic = this.state.defaultBicValue === this.state.bicValue,
            email = this.state.defaultEmailCompanyValue === this.state.emailCompanyValue,
            datedebut = this.state.defaultDebutValue === this.state.debutValue,
            datefin = this.state.defaultFinValue === this.state.finValue,
            pa = this.state.defaultPriceActionValue === this.state.priceActionValue,
            na = this.state.defaultNombreActionValue === this.state.nombreActionValue,
            smin = this.state.defaultSeuilMinValue === this.state.seuilMinValue,
            nbrmin = this.state.defaultNbrActionMinValue === this.state.nbrActionMinValue,
            nbrmax = this.state.defaultNbrActionMaxValue === this.state.nbrActionMaxValue,
            web = this.state.defaultWebsiteValue === this.state.websiteValue,
            desc = this.state.defaultDescriptionValue === this.state.descriptionValue;
        if(iban && bic && datedebut && datefin && pa && na && smin && email && nbrmin && nbrmax && web && desc && (!this.state.croppedBufferLOGO || this.state.croppedBufferLOGO && this.state.modifyIllusLOGO) && (!this.state.croppedBuffer || this.state.croppedBuffer && this.state.modifyIllus)){
            return true
        }else{
            return false
        }
    }

    downloadFile(file){
        // console.log(url+"smsto/"+this.state.td+"/"+file+"?jwt="+this.state.jwt+"&siren="+this.state.td)
        // Axios.get(url+"smsto/"+this.state.td+"/"+file+"?jwt="+this.state.jwt+"&siren="+this.state.td, {
        //     responseType: 'blob',
        // }).then(response => {
        //     console.log(response)
        //     console.log(file)
        //     console.log(response.data)
        //     // let blob = new Blob([Buffer.from(response.data, "utf8")], {type: "application/zip"});
        //     // let link = document.createElement('a');
        //     // link.href = window.URL.createObjectURL(blob);
        //     // link.download = file;
        //     // link.click();
        //
        //
        //     // create file link in browser's memory
        //     const href = URL.createObjectURL(response.data);
        //
        //     // create "a" HTLM element with href to file & click
        //     const link = document.createElement('a');
        //     link.href = href;
        //     link.setAttribute('download', file); //or any other extension
        //     document.body.appendChild(link);
        //     link.click();
        //
        //     // clean up "a" element & remove ObjectURL
        //     document.body.removeChild(link);
        //     URL.revokeObjectURL(href);
        //
        // })
        window.open(url+"smsto/"+this.state.td+"/"+file+"?jwt="+this.state.jwt+"&siren="+this.state.td)
    }

    dlAllFiles(){
        this.setState({disableButtonDlAllFiles:true})
        // let zipFile = new AdmZip();
        // new Promise(async resolve => {
        //     resolve(await this.downloadEachFile(this.state.allFiles.slice(), zipFile))
        // }).then(zip=>{
        //     console.log(zip)
        //     //
        //     // const href = URL.createObjectURL(response.data);
        //     //
        //     // // create "a" HTLM element with href to file & click
        //     // const link = document.createElement('a');
        //     // link.href = href;
        //     // link.setAttribute('download', this.state.entreprise.nomEntreprise+".zip"); //or any other extension
        //     // document.body.appendChild(link);
        //     // link.click();
        //     //
        //     // // clean up "a" element & remove ObjectURL
        //     // document.body.removeChild(link);
        //     // URL.revokeObjectURL(href);
        //
        //     let willSendthis = zip.toBuffer(); //On transforme le zip en Buffer Uint8Array pour le télécharger dans le front plus tard.
        //     let blob = new Blob([willSendthis], {type: "application/zip"});
        //     let link = document.createElement('a');
        //     link.href = window.URL.createObjectURL(blob);
        //     link.download = this.state.entreprise.nomEntreprise+".zip";
        //     link.click();
        // })

        window.open(url+"downloadAsZip?jwt="+this.state.jwt+"&siren="+this.state.td)

    }
    async downloadEachFile(files, zipFile){
        return new Promise(async resolve => {
            if (files.length === 0){
                resolve(zipFile)
            }else{
                Axios.get(url+"smsto/"+this.state.td+"/"+files[0].fileName+"?jwt="+this.state.jwt+"&siren="+this.state.td, {
                    responseType: 'arraybuffer',
                }).then(response => {
                    zipFile.addFile(files[0].fileName, response.data, "Nom du fichier : "+files[0].fileName)
                    files.shift()
                    resolve(this.downloadEachFile(files, zipFile))
                })

                // Axios.get(url+"smsto/"+this.state.td+"/"+files[0].fileName+"?jwt="+this.state.jwt+"&siren="+this.state.td, {
                //     responseType: 'arraybuffer',
                // }).then(response => {
                //     zipFile.addFile(files[0].fileName, response.data,"Nom du fichier : "+files[0].fileName) //On ajoute les .txt dans le zip
                //     files.shift()
                //     resolve(this.downloadEachFile(files, zipFile))
                // })
            }
        })
    }

    handleSliderChange(e){
        this.setState({valueSlider:e.target.value})
    }

    handleSliderInputChange(e){
        if(Number(e.target.value) <= (this.state.entreprise.maxActions - this.state.actionAlreadyBought) && Number(e.target.value) >= 0){
            this.setState({valueSlider:Number(e.target.value)})
        }
    }

    handleBlurInputSlider(e){
        if (e.target.value < (Number(this.state.entreprise.minActions)-this.state.actionAlreadyBought) || e.target.value < 0) {
            this.setState({valueSlider:(Number(this.state.entreprise.minActions)-this.state.actionAlreadyBought) <= 0 ? 1 :Number(this.state.entreprise.minActions)-this.state.actionAlreadyBought})
        } else if (e.target.value > Number(this.state.entreprise.maxActions)-this.state.actionAlreadyBought) {
            this.setState({valueSlider:(Number(this.state.entreprise.maxActions)-this.state.actionAlreadyBought) <= 0 ? 1 :Number(this.state.entreprise.maxActions)-this.state.actionAlreadyBought})
        }
    }

    createOrderPaypal() {
        this.setState({showBackdrop: true})
        return new Promise((resolve, reject) => {
            Axios.post(url + "investment", {
                siren: this.state.entreprise.siren,
                boughtActions: this.state.valueSlider
            }, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
                if (response.data.jwt) {
                    sessionStorage.setItem("SMSTO_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    }, () => {
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === "done") {
                    this.setState({showBackdrop: false})
                    resolve(response.data.orderPaypalID)
                } else {
                    this.setState({showBackdrop: false})
                    reject(response.data.resultat)
                    Swal.fire({
                        title: 'Erreur!',
                        text: response.data.resultat,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(() => {
                        if (response.data.resultat === "Veuillez remplir toutes vos informations personnelles.") {
                            this.props.navigate("/StockMarketSTO/Profil")
                        }
                    })
                }
            })
        })
    }

    getMaxDateProfil(){
        let date = new Date()
        date.setDate(date.getDate())
        date = date.toISOString().split("T")[0]
        return date
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.showBackdrop}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <div style={{width:"fit-content",display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                    <h1 style={{marginBottom:10}} className={"textShadowBorder"}>
                                        {this.state.td === "new" ? "Créer ma levée de fonds :":"Levée de fonds de "+(this.state.entreprise.nomEntreprise ||"") + " :"}
                                        <span style={{color:"orange", position:"absolute", textShadow:"none", marginLeft:10}}>{this.state.entreprise.status === "unconfirmed" && "En cours de modération"}</span>
                                    </h1>
                                    <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:"100%", height:3, borderRadius:100, marginBottom:20}}></div>
                                </div>
                                {this.state.td !== "new" && (
                                    <div className={"flexCenter"}>
                                        <button style={{padding:8, fontSize:14, marginRight:10}} onClick={()=>{this.props.navigate(-1)}} className={"divButtonBuyResale flexCenter"}><KeyboardReturn style={{marginRight:10, color:"#FFFFFF"}}/> Retour</button>

                                        {(this.state.entreprise.owner && (this.state.entreprise.status !=="inProgress" && this.state.entreprise.status !=="done"))&& ( /*Si je suis le créateur de cette levée de fonds alors on peut la modifier*/
                                            <>
                                                {this.state.modifyFundRaisingBool ? ( /*Si je suis en train de visionner ou de modifier la levée de fonds*/
                                                    <button style={{padding:8, fontSize:14}} onClick={()=>{this.setState({modifyFundRaisingBool:false})}} className={"divButtonBuyResale flexCenter"}><Visibility style={{marginRight:10, color:"#FFFFFF"}}/> Prévisualiser</button>
                                                ):(
                                                    <button style={{padding:8, fontSize:14}} onClick={()=>{this.setState({modifyFundRaisingBool:true})}} className={"divButtonBuyResale flexCenter"}><Edit style={{marginRight:10, color:"#FFFFFF"}}/> Modifier</button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                            {this.state.td === "new" ? (
                                <div style={{width:"100%", display:"flex",marginTop:this.state.showBrowserView&&20, alignItems:this.state.showBrowserView ? "flex-start":"center", justifyContent:"center", flexDirection:this.state.showBrowserView ? "row":"column-reverse"}}>
                                    <div className={"flexCenter"} style={{flex:5, flexDirection:"column", width:"100%", maxWidth:750}}>
                                        <h3 onDoubleClick={()=>{this.setState({noMoreVerif:true})}}>Informations de la levée de fonds :</h3>
                                        <form className={"flexCenter"} style={{flexDirection:"column",width:"100%"}} onSubmit={(e)=>{this.validateCandidature(e)}} >
                                            <TextField required={true} style={{width:"100%", marginBottom:15}} name={"nomEntrepriseValue"} value={this.state.nomEntrepriseValue} onChange={(e)=>{this.handleChange(e)}} label="Nom de l'entreprise" variant="outlined"/>
                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                <TextField type={"date"} required={true} style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"debutValue"} value={this.state.debutValue} onChange={(e)=>{this.handleChange(e)}} label="Début de la levée de fonds" variant="outlined" InputLabelProps={{
                                                    shrink: true
                                                }}inputProps={{ min:`${this.getMinMaxDateLDF("debutMin")}`,max:`${this.getMinMaxDateLDF("debutMax")}`}}
                                                />
                                                <TextField type={"date"} required={true} style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"finValue"} value={this.state.finValue} onChange={(e)=>{this.handleChange(e)}} label="Fin de la levée de fonds" variant="outlined" InputLabelProps={{
                                                    shrink: true
                                                }}inputProps={{ min:`${this.getMinMaxDateLDF("finMin")}`,max:`${this.getMinMaxDateLDF("finMax")}`}}
                                                />
                                            </div>
                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                <TextField required={true} style={{width:this.state.showBrowserView ? "69%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"ibanValue"} defaultValue={this.state.ibanValue} value={this.state.ibanValue} onChange={(e)=>{this.handleChangeIBAN(e)}} label="IBAN de l'entreprise" variant="outlined"/>
                                                <TextField required={true} style={{width:this.state.showBrowserView ? "29%":"100%"}} name={"bicValue"} defaultValue={this.state.bicValue} value={this.state.bicValue} onChange={(e)=>{this.handleChange(e)}} label="BIC de l'entreprise" variant="outlined"/>
                                            </div>
                                            <TextField required={true} style={{width:"100%", marginBottom:15}} name={"sirenValue"} value={this.state.sirenValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Numéro SIREN" variant="outlined"/>
                                            <TextField required={true} style={{width:"100%", marginBottom:15}} name={"codePostalValue"} value={this.state.codePostalValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Code postal" variant="outlined"/>
                                            <TextField required={true} style={{width:"100%", marginBottom:15}} name={"telCompanyValue"} value={this.state.telCompanyValue} onChange={(e)=>{this.handleChangePhone(e)}} label="Téléphone de l'entreprise" variant="outlined"/>
                                            <TextField required={true} style={{width:"100%", marginBottom:15}} name={"websiteValue"} value={this.state.websiteValue} onChange={(e)=>{this.handleChange(e)}} label="Site web de l'entreprise" variant="outlined"/>
                                            <TextField required={true} style={{width:"100%", marginBottom:15}} name={"emailCompanyValue"} value={this.state.emailCompanyValue} onChange={(e)=>{this.handleChange(e)}} label="Email de l'entreprise" variant="outlined"/>
                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"nombreActionValue"} value={this.state.nombreActionValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Nombre d'actions en vente" variant="outlined"/>
                                                <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"priceActionValue"} value={this.state.priceActionValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Valeur d'une action en €" variant="outlined"
                                                           InputProps={{
                                                               endAdornment:
                                                                   <InputAdornment position="end">
                                                                       <p style={{color:"#000000"}}>€</p>
                                                                   </InputAdornment>
                                                           }}
                                                />
                                            </div>
                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                <TextField required={true} style={{width:"100%"}} name={"seuilMinValue"} defaultValue={this.state.seuilMinValue} value={this.state.seuilMinValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Objectif minimal de la levée de fonds" variant="outlined"
                                                           InputProps={{
                                                               endAdornment:
                                                                   <InputAdornment position="end">
                                                                       <p style={{color:"#000000"}}>€</p>
                                                                   </InputAdornment>
                                                           }}
                                                />
                                                <Tooltip title={"Ce champs correspond à la limite minimale que vous acceptez pour votre levée de fonds. Si cet objectif n'est pas atteint, les investisseurs seront remboursés."}>
                                                    <Info style={{marginLeft:5, color:"#a2a2a2"}}/>
                                                </Tooltip>
                                            </div>
                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"nbrActionMinValue"} defaultValue={this.state.nbrActionMinValue} value={this.state.nbrActionMinValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Nombre d'actions minimum par personne" variant="outlined"/>
                                                <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"nbrActionMaxValue"} defaultValue={this.state.nbrActionMaxValue} value={this.state.nbrActionMaxValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Nombre d'actions maximum par personne" variant="outlined"/>
                                            </div>
                                            <TextField multiline={true} rowsMax={5} rows={5} required={true} style={{width:"100%", marginBottom: !this.state.showBrowserView && 15}} name={"descriptionValue"} defaultValue={this.state.descriptionValue} value={this.state.descriptionValue} onChange={(e)=>{this.handleChangeDesc(e)}} label="Description" variant="outlined"/>

                                            <button disabled={!this.state.croppedBuffer || !this.state.croppedBufferLOGO || this.state.modifyIllus || this.state.modifyIllusLOGO || this.state.clickedNewLDF} type={"submit"} className={"divButtonBuyResale"} style={{marginTop:20}}>Envoyer ma demande de levée de fonds</button>
                                        </form>
                                    </div>
                                    <div className={"flexCenter"} style={{flexDirection:"column",flex:1}}>
                                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                                            <h3 className={"flexCenter"}>Déposez une image d'illustration : <Tooltip title={"L'image doit être un multiple de 448x300"}><Info style={{marginLeft:5, color:"#a2a2a2"}}/></Tooltip></h3>
                                            {(this.state.croppedBuffer && !this.state.modifyIllus) ?(
                                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                    <img src={`data:${"illustration.png"};base64,${Buffer.from(this.state.croppedBuffer).toString('base64')}`} alt={"illustration"}/>
                                                    <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyIllus:true,illusBuffer:null,croppedBuffer:null, crop: {unit: 'px',x: 0, y: 0, width: 300, height: 300}})}} style={{marginLeft:5, padding:14.5, marginTop:10}}>Retirer l'illustration</button>
                                                </div>
                                            ):(
                                                <>
                                                    <div className={"flexCenter"}>
                                                        <div style={{flex:1}}>
                                                            {this.state.illusBuffer === null && (
                                                                <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                                                    acceptedFiles.forEach((file)=>{
                                                                        if(file.size >= sizeLimitFile){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                title: 'Le fichier est trop lourd (>10GB) !'
                                                                            })
                                                                        }else if(file.name.includes("##")){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                            })
                                                                        }else if(!file.type.includes("image")){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                title: 'Le fichier uploadé doit être une image !'
                                                                            })
                                                                        }else if (file.name.includes(".zip")){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                title: "L'enregistrement de zip n'est pas autorisé."
                                                                            })
                                                                        }else{
                                                                            let self = this;
                                                                            const reader = new FileReader()
                                                                            reader.onload = () => {
                                                                                let img = new Image;
                                                                                img.onload = function() {
                                                                                    self.setState({illusBuffer:reader.result},()=>{
                                                                                        let imageMinia = document.getElementById('imageMinia');
                                                                                        let imgWidth, imgHeight
                                                                                        //On cherche si c'est la width ou la height qui limite le crop
                                                                                        imgWidth = imageMinia.clientHeight/0.67 > imageMinia.clientWidth ? imageMinia.clientWidth:imageMinia.clientHeight/0.67
                                                                                        imgHeight = imgWidth === imageMinia.clientWidth ? imageMinia.clientHeight*0.67:imageMinia.clientHeight
                                                                                        self.setState({
                                                                                            crop: {
                                                                                                unit: 'px',
                                                                                                x: 0,
                                                                                                y: 0,
                                                                                                width: imgWidth,
                                                                                                height: imgHeight
                                                                                            }
                                                                                        },()=>{
                                                                                            self.getCroppedImg(self.state.illusBuffer,"")
                                                                                        })
                                                                                    })
                                                                                };
                                                                                img.src = reader.result;
                                                                            }
                                                                            reader.readAsDataURL(file)
                                                                        }
                                                                    })
                                                                }}>
                                                                    {({getRootProps, getInputProps}) => (
                                                                        <section style={{textAlign:"center"}}>
                                                                            <div className={"flexCenter"} id={this.state.acceptedFile===null &&"hoverMoving"} {...getRootProps()} style={{width:200, height:200, margin:"0 20px 0 20px",borderRadius:10,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold",cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                                                <input {...getInputProps()} />
                                                                                <>
                                                                                    <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFile && "#bbbbbb"}}>{this.state.illusBuffer ? "Illustration déposée.":"Déposez votre illustration ici."}</h3>
                                                                                </>
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>

                                                            )}
                                                        </div>
                                                        <div style={{textAlign:"center"}}>
                                                            {this.state.illusBuffer && (
                                                                // <img style={{width:"60%",maxHeight:270, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={`data:${"image"+"Miniature"};base64,${Buffer.from(this.state.illusBuffer).toString("base64")}`} alt="miniature"/>
                                                                <ReactCrop
                                                                    crop={this.state.crop}
                                                                    onChange={(pixelCrop)=>{this.onCropChange(pixelCrop,"")}}
                                                                    circularCrop={false}
                                                                    aspect={400/267}
                                                                    keepSelection={true}
                                                                    onComplete={(pixelCrop)=>{this.cropComplete("")}}

                                                                >
                                                                    <img id={"imageMinia"} style={{width:"100%",maxHeight:300, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={this.state.illusBuffer} alt="Image d'illustration"/>
                                                                </ReactCrop>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {this.state.croppedBuffer && (
                                                        <div className={"flexCenter"} style={{marginTop:20}}>
                                                            <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyIllus:false,illusBuffer:null})}} style={{marginLeft:5, padding:14.5}}>Valider l'illustration</button>
                                                            <button className={"divButtonBuyResale"} onClick={()=>{this.setState({illusBuffer:null,croppedBuffer:null})}} style={{marginLeft:5, backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)", padding:10}}><Close style={{color:"#FFFFFF"}}/></button>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        {/*DROPZONE LOGO*/}
                                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                                            <h3 className={"flexCenter"}>Déposez votre logo : <Tooltip title={"Privilégier des images 1:1 (carrés)"}><Info style={{marginLeft:5, color:"#a2a2a2"}}/></Tooltip></h3>
                                            {(this.state.croppedBufferLOGO && !this.state.modifyIllusLOGO) ?(
                                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                    <img width={200} src={`data:${"logo.png"};base64,${Buffer.from(this.state.croppedBufferLOGO).toString('base64')}`} alt={"logo"}/>
                                                    <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyIllusLOGO:true,illusBufferLOGO:null,croppedBufferLOGO:null, crop: {unit: 'px',x: 0, y: 0, width: 300, height: 300}})}} style={{marginLeft:5, padding:14.5, marginTop:10}}>Retirer le logo</button>
                                                </div>
                                            ):(
                                                <>
                                                    <div className={"flexCenter"}>
                                                        <div style={{flex:1}}>
                                                            {this.state.illusBufferLOGO === null && (
                                                                <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                                                    acceptedFiles.forEach((file)=>{
                                                                        if(file.size >= sizeLimitFile){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                title: 'Le fichier est trop lourd (>10GB) !'
                                                                            })
                                                                        }else if(file.name.includes("##")){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                            })
                                                                        }else if(!file.type.includes("image")){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                title: 'Le fichier uploadé doit être une image !'
                                                                            })
                                                                        }else if (file.name.includes(".zip")){
                                                                            Toast.fire({
                                                                                icon: 'error',
                                                                                title: "L'enregistrement de zip n'est pas autorisé."
                                                                            })
                                                                        }else{
                                                                            let self = this;
                                                                            const reader = new FileReader()
                                                                            reader.onload = () => {
                                                                                let img = new Image;
                                                                                img.onload = function() {
                                                                                    self.setState({illusBufferLOGO:reader.result},()=>{
                                                                                        let imageMinia = document.getElementById('imageMinia');
                                                                                        let minDim = imageMinia.clientHeight > imageMinia.clientWidth ? imageMinia.clientWidth:imageMinia.clientHeight
                                                                                        self.setState({
                                                                                            cropLOGO: {
                                                                                                unit: 'px',
                                                                                                x: 0,
                                                                                                y: 0,
                                                                                                width: minDim,
                                                                                                height: minDim
                                                                                            }
                                                                                        },()=>{
                                                                                            self.getCroppedImg(self.state.illusBufferLOGO,"LOGO")
                                                                                        })
                                                                                    })
                                                                                };
                                                                                img.src = reader.result;
                                                                            }
                                                                            reader.readAsDataURL(file)
                                                                        }
                                                                    })
                                                                }}>
                                                                    {({getRootProps, getInputProps}) => (
                                                                        <section style={{textAlign:"center"}}>
                                                                            <div className={"flexCenter"} id={this.state.acceptedFileLOGO===null &&"hoverMoving"} {...getRootProps()} style={{width:200, height:200, margin:"0 20px 0 20px",borderRadius:10,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold",cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                                                <input {...getInputProps()} />
                                                                                <>
                                                                                    <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFileLOGO && "#bbbbbb"}}>{this.state.illusBufferLOGO ? "Logo déposé.":"Déposez votre logo ici."}</h3>
                                                                                </>
                                                                            </div>
                                                                        </section>
                                                                    )}
                                                                </Dropzone>
                                                            )}
                                                        </div>
                                                        <div style={{textAlign:"center"}}>
                                                            {this.state.illusBufferLOGO && (
                                                                // <img style={{width:"60%",maxHeight:270, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={`data:${"image"+"Miniature"};base64,${Buffer.from(this.state.illusBuffer).toString("base64")}`} alt="miniature"/>
                                                                <ReactCrop
                                                                    crop={this.state.cropLOGO}
                                                                    onChange={(pixelCrop)=>{this.onCropChange(pixelCrop,"LOGO")}}
                                                                    circularCrop={false}
                                                                    aspect={1}
                                                                    keepSelection={true}
                                                                    onComplete={(pixelCrop)=>{this.cropComplete("LOGO")}}
                                                                >
                                                                    <img id={"imageMinia"} style={{width:"100%",maxHeight:300, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={this.state.illusBufferLOGO} alt="Image d'illustration"/>
                                                                </ReactCrop>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {this.state.croppedBufferLOGO && (
                                                        <div className={"flexCenter"} style={{marginTop:20}}>
                                                            <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyIllusLOGO:false,illusBufferLOGO:null, cropLOGO: {unit: 'px',x: 0, y: 0, width: 300, height: 300}})}} style={{marginLeft:5, padding:14.5}}>Valider le logo</button>
                                                            <button className={"divButtonBuyResale"} onClick={()=>{this.setState({illusBufferLOGO:null,croppedBufferLOGO:null, cropLOGO: {unit: 'px',x: 0, y: 0, width: 300, height: 300}})}} style={{marginLeft:5, backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)", padding:10}}><Close style={{color:"#FFFFFF"}}/></button>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ):(
                                <>
                                    {this.state.connected && (
                                        <>
                                            {this.state.modifyFundRaisingBool ? (
                                                <div style={{width:"100%", display:"flex",marginTop:this.state.showBrowserView&&20, alignItems:this.state.showBrowserView ? "flex-start":"center", justifyContent:"center", flexDirection:this.state.showBrowserView ? "row":"column-reverse"}}>
                                                    <div className={"flexCenter"} style={{flex:5, flexDirection:"column", width:"100%", maxWidth:750}}>
                                                        <h3>Informations de la levée de fonds :</h3>
                                                        <form className={"flexCenter"} style={{flexDirection:"column",width:"100%"}} onSubmit={(e)=>{this.modifyFundRaising(e)}} >
                                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                                <TextField type={"date"} required={true} style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"debutValue"} value={this.state.debutValue} onChange={(e)=>{this.handleChange(e)}} label="Début de la levée de fonds" variant="outlined"
                                                                           InputLabelProps={{ shrink: true }}
                                                                           inputProps={{ min:`${this.getMinMaxDateLDF("debutMin")}`,max:`${this.getMinMaxDateLDF("debutMax")}`}}
                                                                />
                                                                <TextField type={"date"} required={true} style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"finValue"} value={this.state.finValue} onChange={(e)=>{this.handleChange(e)}} label="Fin de la levée de fonds" variant="outlined"
                                                                           InputLabelProps={{ shrink: true }}
                                                                           inputProps={{ min:`${this.getMinMaxDateLDF("finMin")}`,max:`${this.getMinMaxDateLDF("finMax")}`}}
                                                                />
                                                            </div>
                                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                                <TextField required={true} style={{width:this.state.showBrowserView ? "69%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"ibanValue"} defaultValue={this.state.ibanValue} value={this.state.ibanValue} onChange={(e)=>{this.handleChangeIBAN(e)}} label="IBAN de l'entreprise" variant="outlined"/>
                                                                <TextField required={true} style={{width:this.state.showBrowserView ? "29%":"100%"}} name={"bicValue"} defaultValue={this.state.bicValue} value={this.state.bicValue} onChange={(e)=>{this.handleChange(e)}} label="BIC de l'entreprise" variant="outlined"/>
                                                            </div>
                                                            <TextField required={true} style={{width:"100%", marginBottom:15}} name={"telCompanyValue"} value={this.state.telCompanyValue} onChange={(e)=>{this.handleChangePhone(e)}} label="Téléphone de l'entreprise" variant="outlined"/>
                                                            <TextField required={true} style={{width:"100%", marginBottom:15}} name={"websiteValue"} value={this.state.websiteValue} onChange={(e)=>{this.handleChange(e)}} label="Site web de l'entreprise" variant="outlined"/>
                                                            <TextField required={true} style={{width:"100%", marginBottom:15}} name={"emailCompanyValue"} value={this.state.emailCompanyValue} onChange={(e)=>{this.handleChange(e)}} label="Email de l'entreprise" variant="outlined"/>
                                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                                <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"nombreActionValue"} value={this.state.nombreActionValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Nombre d'actions en vente" variant="outlined"/>
                                                                <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"priceActionValue"} value={this.state.priceActionValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Valeur d'une action en €" variant="outlined"
                                                                           InputProps={{
                                                                               endAdornment:
                                                                                   <InputAdornment position="end">
                                                                                       <p style={{color:"#000000"}}>€</p>
                                                                                   </InputAdornment>
                                                                           }}
                                                                />
                                                            </div>
                                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                                <TextField required={true} style={{width:"100%"}} name={"seuilMinValue"} defaultValue={this.state.seuilMinValue} value={this.state.seuilMinValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Objectif minimal de la levée de fonds" variant="outlined"
                                                                           InputProps={{
                                                                               endAdornment:
                                                                                   <InputAdornment position="end">
                                                                                       <p style={{color:"#000000"}}>€</p>
                                                                                   </InputAdornment>
                                                                           }}
                                                                />
                                                                <Tooltip title={"Ce champs correspond à la limite minimale que vous acceptez pour votre levée de fonds. Si cet objectif n'est pas atteint, les investisseurs seront remboursés."}>
                                                                    <Info style={{marginLeft:5, color:"#a2a2a2"}}/>
                                                                </Tooltip>
                                                            </div>
                                                            <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                                <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"nbrActionMinValue"} defaultValue={this.state.nbrActionMinValue} value={this.state.nbrActionMinValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Nombre d'actions minimum par personne" variant="outlined"/>
                                                                <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"nbrActionMaxValue"} defaultValue={this.state.nbrActionMaxValue} value={this.state.nbrActionMaxValue} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label="Nombre d'actions maximum par personne" variant="outlined"/>
                                                            </div>
                                                            <TextField multiline={true} rowsMax={5} rows={5} required={true} style={{width:"100%", marginBottom: !this.state.showBrowserView && 15}} name={"descriptionValue"} defaultValue={this.state.descriptionValue} value={this.state.descriptionValue} onChange={(e)=>{this.handleChangeDesc(e)}} label="Description" variant="outlined"/>

                                                            <button disabled={this.validButtonModify()}  type={"submit"} className={"divButtonBuyResale"} style={{marginTop:20}}>Valider mes modifications</button>
                                                        </form>
                                                        <h3>Accès à la zone de dépôt de fichiers :</h3>
                                                        <button onClick={()=>{this.props.navigate("/StockMarketSTO/EchangeFichier?td="+this.state.entreprise.siren+"&r=true")}} className={"divButtonBuyResale"}>Dépôt de fichiers</button>
                                                    </div>
                                                    <div className={"flexCenter"} style={{flexDirection:"column",flex:1}}>
                                                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                                                            <h3 className={"flexCenter"}>Image d'illustration : <Tooltip title={"Privilégier des images 16:9 ou 16:10"}><Info style={{marginLeft:5, color:"#a2a2a2"}}/></Tooltip></h3>
                                                            {(!this.state.modifyIllus) ?(
                                                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                    {this.state.croppedBuffer ? (
                                                                        <img src={`data:${"illustration.png"};base64,${Buffer.from(this.state.croppedBuffer).toString('base64')}`} alt={"illustration"}/>
                                                                    ):(
                                                                        <img src={`${url}images/smsto_entreprise/${this.state.td}/illustration.png`} alt={"illustration"}/>
                                                                    )}
                                                                    <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyIllus:true})}} style={{marginTop:15, padding:14.5}}>Modifier l'illustration</button>
                                                                </div>
                                                            ):(
                                                                <>
                                                                    <div className={"flexCenter"}>
                                                                        <div style={{flex:1}}>
                                                                            {this.state.illusBuffer === null && (
                                                                                <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                                                                    acceptedFiles.forEach((file)=>{
                                                                                        if(file.size >= sizeLimitFile){
                                                                                            Toast.fire({
                                                                                                icon: 'error',
                                                                                                title: 'Le fichier est trop lourd (>10GB) !'
                                                                                            })
                                                                                        }else if(file.name.includes("##")){
                                                                                            Toast.fire({
                                                                                                icon: 'error',
                                                                                                title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                                            })
                                                                                        }else if(!file.type.includes("image")){
                                                                                            Toast.fire({
                                                                                                icon: 'error',
                                                                                                title: 'Le fichier uploadé doit être une image !'
                                                                                            })
                                                                                        }else if (file.name.includes(".zip")){
                                                                                            Toast.fire({
                                                                                                icon: 'error',
                                                                                                title: "L'enregistrement de zip n'est pas autorisé."
                                                                                            })
                                                                                        }else{
                                                                                            let self = this;
                                                                                            const reader = new FileReader()
                                                                                            reader.onload = () => {
                                                                                                let img = new Image;
                                                                                                img.onload = function() {
                                                                                                    self.setState({illusBuffer:reader.result},()=>{
                                                                                                        let imageMinia = document.getElementById('imageMinia');
                                                                                                        let imgWidth, imgHeight
                                                                                                        imgWidth = imageMinia.clientHeight/0.67 > imageMinia.clientWidth ? imageMinia.clientWidth:imageMinia.clientHeight/0.67
                                                                                                        imgHeight = imgWidth === imageMinia.clientWidth ? imageMinia.clientHeight*0.67:imageMinia.clientHeight
                                                                                                        self.setState({
                                                                                                            crop: {
                                                                                                                unit: 'px',
                                                                                                                x: 0,
                                                                                                                y: 0,
                                                                                                                width: imgWidth,
                                                                                                                height: imgHeight
                                                                                                            }
                                                                                                        },()=>{
                                                                                                            self.getCroppedImg(self.state.illusBuffer,"")
                                                                                                        })
                                                                                                    })
                                                                                                };
                                                                                                img.src = reader.result;
                                                                                            }
                                                                                            reader.readAsDataURL(file)
                                                                                            Toast.fire({
                                                                                                icon: 'success',
                                                                                                title: "Fichier(s) correctement déposé(s), prêt à l'enregistrement."
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                                    {({getRootProps, getInputProps}) => (
                                                                                        <section style={{textAlign:"center"}}>
                                                                                            <div className={"flexCenter"} id={this.state.acceptedFile===null &&"hoverMoving"} {...getRootProps()} style={{width:200, height:200, margin:"0 20px 0 20px",borderRadius:10,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold",cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                                                                <input {...getInputProps()} />
                                                                                                <>
                                                                                                    <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFile && "#bbbbbb"}}>{this.state.illusBuffer ? "Illustration déposée.":"Déposez votre illustration ici."}</h3>
                                                                                                </>
                                                                                            </div>
                                                                                        </section>
                                                                                    )}
                                                                                </Dropzone>
                                                                            )}
                                                                        </div>
                                                                        <div style={{textAlign:"center"}}>
                                                                            {this.state.illusBuffer && (
                                                                                // <img style={{width:"60%",maxHeight:270, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={`data:${"image"+"Miniature"};base64,${Buffer.from(this.state.illusBuffer).toString("base64")}`} alt="miniature"/>
                                                                                <ReactCrop
                                                                                    crop={this.state.crop}
                                                                                    onChange={(pixelCrop)=>{this.onCropChange(pixelCrop,"")}}
                                                                                    circularCrop={false}
                                                                                    aspect={400/267}
                                                                                    keepSelection={true}
                                                                                    onComplete={(pixelCrop)=>{this.cropComplete("")}}

                                                                                >
                                                                                    <img id={"imageMinia"} style={{width:"100%",maxHeight:300, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={this.state.illusBuffer} alt="Image d'illustration"/>
                                                                                </ReactCrop>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {this.state.croppedBuffer && (
                                                                        <div className={"flexCenter"} style={{marginTop:20}}>
                                                                            <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyIllus:false,illusBuffer:null, crop: {unit: 'px',x: 0, y: 0, width: 300, height: 300}})}} style={{marginLeft:5, padding:14.5}}>Valider l'illustration</button>
                                                                            <button className={"divButtonBuyResale"} onClick={()=>{this.setState({illusBuffer:null,croppedBuffer:null, crop: {unit: 'px',x: 0, y: 0, width: 300, height: 300}})}} style={{marginLeft:5, backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)", padding:10}}><Close style={{color:"#FFFFFF"}}/></button>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                        {/*DROPZONE LOGO*/}
                                                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                                                            <h3 className={"flexCenter"}>Logo : <Tooltip title={"Privilégier des images 1:1 (carrés)"}><Info style={{marginLeft:5, color:"#a2a2a2"}}/></Tooltip></h3>
                                                            {(!this.state.modifyIllusLOGO) ?(
                                                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                    {this.state.croppedBufferLOGO ? (
                                                                        <img width={200} src={`data:${"logo.png"};base64,${Buffer.from(this.state.croppedBufferLOGO).toString('base64')}`} alt={"logo"}/>
                                                                    ):(
                                                                        <img style={{width:200}} src={`${url}images/smsto_entreprise/${this.state.td}/logo.png`} alt={"logo"}/>
                                                                    )}
                                                                    <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyIllusLOGO:true})}} style={{marginTop:15, padding:14.5}}>Modifier le logo</button>
                                                                </div>
                                                            ):(
                                                                <>
                                                                    <div className={"flexCenter"}>
                                                                        <div style={{flex:1}}>
                                                                            {this.state.illusBufferLOGO === null && (
                                                                                <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                                                                    acceptedFiles.forEach((file)=>{
                                                                                        if(file.size >= sizeLimitFile){
                                                                                            Toast.fire({
                                                                                                icon: 'error',
                                                                                                title: 'Le fichier est trop lourd (>10GB) !'
                                                                                            })
                                                                                        }else if(file.name.includes("##")){
                                                                                            Toast.fire({
                                                                                                icon: 'error',
                                                                                                title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                                            })
                                                                                        }else if(!file.type.includes("image")){
                                                                                            Toast.fire({
                                                                                                icon: 'error',
                                                                                                title: 'Le fichier uploadé doit être une image !'
                                                                                            })
                                                                                        }else if (file.name.includes(".zip")){
                                                                                            Toast.fire({
                                                                                                icon: 'error',
                                                                                                title: "L'enregistrement de zip n'est pas autorisé."
                                                                                            })
                                                                                        }else{
                                                                                            let self = this;
                                                                                            const reader = new FileReader()
                                                                                            reader.onload = () => {
                                                                                                let img = new Image;
                                                                                                img.onload = function() {
                                                                                                    self.setState({illusBufferLOGO:reader.result},()=>{
                                                                                                        let imageMinia = document.getElementById('imageMinia');
                                                                                                        let minDim = imageMinia.clientHeight > imageMinia.clientWidth ? imageMinia.clientWidth:imageMinia.clientHeight
                                                                                                        self.setState({
                                                                                                            cropLOGO: {
                                                                                                                unit: 'px',
                                                                                                                x: 0,
                                                                                                                y: 0,
                                                                                                                width: minDim,
                                                                                                                height: minDim
                                                                                                            }
                                                                                                        },()=>{
                                                                                                            self.getCroppedImg(self.state.illusBufferLOGO,"LOGO")

                                                                                                        })
                                                                                                    })
                                                                                                };
                                                                                                img.src = reader.result;
                                                                                            }
                                                                                            reader.readAsDataURL(file)
                                                                                            Toast.fire({
                                                                                                icon: 'success',
                                                                                                title: "Fichier(s) correctement déposé(s), prêt à l'enregistrement."
                                                                                            })
                                                                                        }
                                                                                    })
                                                                                }}>
                                                                                    {({getRootProps, getInputProps}) => (
                                                                                        <section style={{textAlign:"center"}}>
                                                                                            <div className={"flexCenter"} id={this.state.acceptedFileLOGO===null &&"hoverMoving"} {...getRootProps()} style={{width:200, height:200, margin:"0 20px 0 20px",borderRadius:10,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold",cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                                                                <input {...getInputProps()} />
                                                                                                <>
                                                                                                    <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFileLOGO && "#bbbbbb"}}>{this.state.illusBufferLOGO ? "Logo déposé.":"Déposez votre logo ici."}</h3>
                                                                                                </>
                                                                                            </div>
                                                                                        </section>
                                                                                    )}
                                                                                </Dropzone>
                                                                            )}
                                                                        </div>
                                                                        <div style={{textAlign:"center"}}>
                                                                            {this.state.illusBufferLOGO && (
                                                                                // <img style={{width:"60%",maxHeight:270, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={`data:${"image"+"Miniature"};base64,${Buffer.from(this.state.illusBuffer).toString("base64")}`} alt="miniature"/>
                                                                                <ReactCrop
                                                                                    crop={this.state.cropLOGO}
                                                                                    onChange={(pixelCrop)=>{this.onCropChange(pixelCrop,"LOGO")}}
                                                                                    circularCrop={false}
                                                                                    aspect={1}
                                                                                    keepSelection={true}
                                                                                    onComplete={(pixelCrop)=>{this.cropComplete("LOGO")}}
                                                                                >
                                                                                    <img id={"imageMinia"} style={{width:"100%",maxHeight:300, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={this.state.illusBufferLOGO} alt="Image d'illustration"/>
                                                                                </ReactCrop>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {this.state.croppedBufferLOGO && (
                                                                        <div className={"flexCenter"} style={{marginTop:20}}>
                                                                            <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyIllusLOGO:false,illusBufferLOGO:null, cropLOGO: {unit: 'px',x: 0, y: 0, width: 300, height: 300}})}} style={{marginLeft:5, padding:14.5}}>Valider le logo</button>
                                                                            <button className={"divButtonBuyResale"} onClick={()=>{this.setState({illusBufferLOGO:null,croppedBufferLOGO:null, cropLOGO: {unit: 'px',x: 0, y: 0, width: 300, height: 300}})}} style={{marginLeft:5, backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)", padding:10}}><Close style={{color:"#FFFFFF"}}/></button>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ):(
                                                <div className={"flexCenter"} style={{flexDirection:"column",width:'100%'}}>
                                                    <div style={{width:"100%", height:"fit-content", marginTop:20,marginBottom:30}}>
                                                        <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"space-around", flexWrap:"wrap",flexDirection:!this.state.showBrowserView&&"column"}}>
                                                            <div className={"flexCenter"} style={{width:this.state.showBrowserView&&"29%", height:"100%", flexDirection:"column", alignItems:"flex-start", justifyContent:"space-between"}}>
                                                                <h2 className={"flexCenter"} style={{margin:0, marginBottom:5}}>
                                                                    <img style={{width:45, marginRight:10}} src={`${url}images/smsto_entreprise/${this.state.td}/logo.png`} alt="logo"/>
                                                                    {this.state.entreprise.nomEntreprise}
                                                                </h2>
                                                                <img style={{width:"100%", minWidth:260, maxWidth:500, marginLeft:0,borderRadius:5}} src={`${url}images/smsto_entreprise/${this.state.td}/illustration.png`} alt="illusSpuro"/>
                                                            </div>
                                                            <div className={"flexCenter"} style={{width:this.state.showBrowserView && "39%",height:305,flexDirection:"column", justifyContent:"space-between"}}>
                                                                <div className={"flexCenter"}>
                                                                    <CalendarMonth style={{marginRight:3}}/>
                                                                    <h2 style={{margin:0, fontWeight:"normal"}}> Début le <span style={{fontWeight:"bold"}}>{this.state.entreprise.dateDebut}</span> - Fin le <span style={{fontWeight:"bold"}}> {this.state.entreprise.dateFin}</span> </h2>
                                                                </div>
                                                                <div style={{width:"100%"}}>
                                                                    <h3 style={{width:"100%", textAlign:"left", margin:0}}>Description :</h3>
                                                                    <h4 style={{width:"100%", textAlign:"left", margin:0, fontWeight:"normal", fontStyle:this.state.entreprise.desc === "" && "italic"}}>{this.state.entreprise.desc || "Aucune description n'est disponible pour cette levée de fonds"}</h4>
                                                                </div>
                                                                <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                                                    <LinearProgress style={{width:"90%", height:30, borderRadius:5}} variant="determinate" value={this.state.entreprise.percentDate} />
                                                                    <div className={"flexCenter"} style={{position:"relative", width:"90%"}}>
                                                                        {this.state.entreprise.percentDate === 100 && this.state.entreprise.status === "done" ? (
                                                                            <div style={{width:`${this.state.entreprise.percentDate}%`, textAlign:"center"}}>
                                                                                <p style={{position:"absolute",width:"inherit", margin:0, top:-25, color:"#FFF"}}>Levée de fonds terminée</p>
                                                                            </div>
                                                                        ):(
                                                                            <>
                                                                                {this.state.entreprise.percentDate > 0 && (
                                                                                    <div style={{width:`${this.state.entreprise.percentDate}%`, textAlign:"center"}}>
                                                                                        <p style={{position:"absolute",width:"inherit", margin:0, top:-25, color:"#FFF"}}>{this.state.entreprise.joursTotaux-this.state.entreprise.joursRestants <= 0 ? ("Levée de fonds terminée"):(this.state.entreprise.joursTotaux-this.state.entreprise.joursRestants + " jours")}</p>
                                                                                    </div>
                                                                                )}
                                                                                {100-this.state.entreprise.percentDate > 0 && (
                                                                                    <div style={{width:`${100-this.state.entreprise.percentDate}%`, textAlign:"center"}}>
                                                                                        <p style={{position:"absolute",width:"inherit", margin:0, top:-25, color:"#FFF"}}>{this.state.entreprise.joursRestants > this.state.entreprise.joursTotaux ? ("Levée de fonds non démarrée"):(this.state.entreprise.joursRestants+" jours")}</p>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}

                                                                    </div>
                                                                    <div className={"flexCenter"} style={{width:"90%", justifyContent:"flex-start"}}>
                                                                        <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                                            <div style={{backgroundColor:"#1976d2", width:20, height:20, borderRadius:5, marginRight:5}}/>
                                                                            <p>Temps écoulé</p>
                                                                        </div>
                                                                        <div className={"flexCenter"} style={{width:"fit-content", marginLeft:20}}>
                                                                            <div style={{backgroundColor:"#a7caed", width:20, height:20, borderRadius:5, marginRight:5}}/>
                                                                            <p>Temps restant</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{width:"100%", height:"fit-content", marginTop:20,marginBottom:30}}>
                                                        {this.state.displayPaypalPayment ? (
                                                                <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"space-around", flexWrap:"wrap",flexDirection:!this.state.showBrowserView && "column"}}>
                                                                    <div style={{width:'100%', backgroundColor:"#dcdcdc",height:2, marginTop:10, marginBottom:20, borderRadius:10}}/>
                                                                    <div className={"flexCenter"} style={{width:!this.state.showBrowserView?"100%":"35%",height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                                                        <div className={"divReceipt flexCenter"} style={{width:!this.state.showBrowserView&&"95%", padding:!this.state.showBrowserView&&"2%", marginTop:0}}>
                                                                            <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                                                                <h2 style={{margin:0, fontWeight:600, marginBottom:20}}>Récapitulatif d'achat :</h2>
                                                                                <div className={"flexCenter"} style={{width:'95%', justifyContent:"space-between"}}>
                                                                                    <h2 className={"flexCenter"} style={{margin:0, fontWeight:100}}>x{this.state.valueSlider} <img style={{width:31, marginLeft:4}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/> </h2>
                                                                                    <h2 style={{margin:0, fontWeight:100}}>Actions <span style={{fontWeight:600}}>{this.state.entreprise.nomEntreprise}</span></h2>
                                                                                </div>
                                                                                <div style={{width:"95%", height:2, margin:30, marginBottom:20,borderBottom:"1px dashed #969696",}}/>
                                                                            </div>
                                                                            <div className={"flexCenter"} style={{justifyContent:"flex-end", width:"100%"}}>
                                                                                <h2 className={"flexCenter"} style={{fontSize:34, margin:0}}>Total : {parseFloat(this.state.valueSlider)*parseFloat(this.state.entreprise.actionValue)}<img style={{width:35, marginLeft:4,marginTop:4}} src={require("../../assets/euroIcon.png")} alt="EURO_ICON"/></h2>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={"flexCenter"} style={{width:!this.state.showBrowserView?"100%":"35%",height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                                                        <h2 style={{margin:10, marginBottom:30, fontWeight:100}}>Payez avec paypal ou par carte bancaire</h2>
                                                                        <PayPalScriptProvider options={{ "client-id": paypalPublicKey, currency: "EUR" }}>
                                                                            <PayPalButton onCreateOrder={this.createOrderPaypal} onApproveOrder={()=>{this.setState({showBackdrop:true})}} />
                                                                        </PayPalScriptProvider>
                                                                    </div>
                                                                </div>
                                                        ):(
                                                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"space-around", flexWrap:"wrap",flexDirection:!this.state.showBrowserView && "column"}}>
                                                                <div style={{width:'100%', backgroundColor:"#dcdcdc",height:2, marginTop:10, marginBottom:20, borderRadius:10}}/>
                                                                <div className={"flexCenter"} style={{width:!this.state.showBrowserView ?"100%":"35%",height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                                                        <div className={"flexCenter"} style={{width:"80%", justifyContent:"center", flexDirection:"row-reverse"}}>
                                                                            <div className={"flexCenter"} style={{width:"fit-content",marginLeft:20}}>
                                                                                <p>Actions achetées</p>
                                                                                <div style={{backgroundColor:"#1976d2", width:20, height:20, borderRadius:5, marginLeft:5}}/>
                                                                            </div>
                                                                            <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                                                <p>Actions disponibles</p>
                                                                                <div style={{backgroundColor:"#a7caed", width:20, height:20, borderRadius:5, marginLeft:5}}/>
                                                                            </div>
                                                                        </div>
                                                                        <div className={"flexCenter"} style={{transform:"scale(1.2)",margin:35,width:95, height:95, borderRadius:"50%", backgroundColor:"transparent", border:"27px solid #a7caed", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                                                                            <CircularProgress style={{width:150, height:150, position:"absolute"}} variant={"determinate"} value={this.state.entreprise.percentReserved} thickness={8}/>
                                                                            {100-this.state.entreprise.percentReserved === 0 ? (
                                                                                <p style={{margin:0,textAlign:"center",fontSize:12}}>Levée de fonds terminée.</p>
                                                                            ):(
                                                                                /*100 - percent reserved -> actions dispo*/
                                                                                <div style={{width:210,height:210,position:"absolute", display:"flex", justifyContent:"center", alignItems:"flex-start", transform:`rotate(${(100-(100-this.state.entreprise.percentReserved)/2)/100}turn)`}}>
                                                                                    <p style={{display:100-this.state.entreprise.percentReserved === 100 && "none",margin:0, transform:`rotate(${(((100-this.state.entreprise.percentReserved)/2)/100)}turn)`}}>{100-this.state.entreprise.percentReserved}%</p>
                                                                                </div>
                                                                            )}
                                                                            {this.state.entreprise.reservedActions === 0 ? (
                                                                                <p style={{margin:0,textAlign:"center",fontSize:12}}>Aucune action achetée.</p>
                                                                            ):(
                                                                                /*percent reserved -> actions réservées*/
                                                                                <div style={{width:210,height:210,position:"absolute", display:"flex", justifyContent:"center", alignItems:"flex-start", transform:`rotate(${(this.state.entreprise.percentReserved/2)/100}turn)`}}>
                                                                                    <p style={{display:this.state.entreprise.percentReserved === 100 && "none",margin:0, transform:`rotate(${100-((this.state.entreprise.percentReserved/2)/100)}turn)`}}>{this.state.entreprise.percentReserved}%</p>
                                                                                </div>
                                                                            )}
                                                                            {/*<p style={{position:"absolute" ,display:60>5 ? "block":"none", transform:"translate(10px 10px)"}} >60%</p>*/}
                                                                        </div>
                                                                        <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start",marginTop:10}}>
                                                                            <h2 className={"flexCenter"} style={{margin:0, fontWeight:100}}>
                                                                                Tokens disponibles : <span style={{fontSize:34, marginLeft:10, fontWeight:600}}>{this.state.entreprise.numberActions-this.state.entreprise.reservedActions}</span>
                                                                                <img style={{width:40, marginTop:4, marginLeft:5}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                                            </h2>
                                                                            <h2 className={"flexCenter"} style={{margin:0, fontWeight:100}}>
                                                                                Tokens achetées : <span style={{fontSize:34, marginLeft:10, fontWeight:600}}>{this.state.entreprise.reservedActions}</span>
                                                                                <img style={{width:40, marginTop:4, marginLeft:5}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={"flexCenter"} style={{width:!this.state.showBrowserView?"100%":"35%",height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                                                        <div className={"flexCenter"}>
                                                                            <h2 style={{margin:10, fontWeight:100}}>Taux d'échange :</h2>
                                                                            <Tooltip title={"1 STO vaut "+this.state.entreprise.actionValue+"€"}>
                                                                                <h2 className={"flexCenter"} style={{margin:0, fontSize:34, fontWeight:600}}>
                                                                                    1<img style={{width:31, marginTop:4, marginRight:10}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/> <span style={{fontSize:22, marginRight:10}}>vaut</span> {this.state.entreprise.actionValue}<img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                                                </h2>
                                                                            </Tooltip>
                                                                        </div>
                                                                        <div className={"flexCenter"} style={{width:"100%", justifyContent:"center"}}>
                                                                            {this.state.entreprise.minActions && this.state.entreprise.maxActions ? (
                                                                                <>
                                                                                    <h2 style={{margin:10, fontWeight:100}}>Investissement autorisé entre</h2>
                                                                                    <div className={"flexCenter"}>
                                                                                        <Tooltip title={"Investissement minimum de "+this.state.entreprise.minActions+(this.state.entreprise.minActions > 1 ? "STOs" : "STO")+" et maximum de "+this.state.entreprise.maxActions+"STOs"}>
                                                                                            <h2 className={"flexCenter"} style={{margin:0, fontWeight:600}}>
                                                                                                <span style={{fontSize:34}}>{this.state.entreprise.minActions}</span>
                                                                                                <img style={{width:31, marginTop:4}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                                                                <p style={{margin:10}}>et</p>
                                                                                                <span style={{fontSize:34}}> {this.state.entreprise.maxActions}</span>
                                                                                                <img style={{width:31, marginTop:4}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                                                            </h2>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </>
                                                                            ):(
                                                                                <>
                                                                                    <h2 style={{margin:10}}>Limites d'investissement non renseignées</h2>
                                                                                </>
                                                                            )}

                                                                        </div>
                                                                        <div className={"flexCenter"} style={{flexDirection:"column", width:"80%",borderRadius:10 ,padding:30,paddingTop:10,marginTop:50,boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                                                                            <h2 className={"flexCenter"} style={{width:"100%", justifyContent:"flex-start", marginTop:0}}>
                                                                                Acheter des actions
                                                                                {this.state.entreprise.status !=="inProgress" && (
                                                                                    <p style={{fontSize:12, marginLeft:5}}>(pas encore disponible)</p>
                                                                                )}
                                                                            </h2>
                                                                            <div className={"flexCenter"} style={{marginTop:10,width:'100%'}}>
                                                                                <Slider
                                                                                    style={{marginRight:10}}
                                                                                    value={typeof this.state.valueSlider === 'number' ? this.state.valueSlider : (Number(this.state.entreprise.minActions)-this.state.actionAlreadyBought) <= 0 ? 1 : (Number(this.state.entreprise.minActions)-this.state.actionAlreadyBought)}
                                                                                    onChange={(e)=>{this.handleSliderChange(e)}}
                                                                                    min={(Number(this.state.entreprise.minActions)-this.state.actionAlreadyBought) <= 0 ? 1 : (Number(this.state.entreprise.minActions)-this.state.actionAlreadyBought)}
                                                                                    max={(Number(this.state.entreprise.maxActions)-this.state.actionAlreadyBought) <= 0 ? 1 : (Number(this.state.entreprise.maxActions)-this.state.actionAlreadyBought)}
                                                                                    disabled={this.state.entreprise.status !=="inProgress" || this.state.actionAlreadyBought === Number(this.state.entreprise.maxActions)}
                                                                                    valueLabelDisplay="auto"
                                                                                />
                                                                                <TextField
                                                                                    label={"Actions"}
                                                                                    variant={"outlined"}
                                                                                    style={{marginLeft:10}}
                                                                                    value={typeof this.state.valueSlider === 'number' ? this.state.valueSlider : 0}
                                                                                    size="small"
                                                                                    disabled={this.state.entreprise.status !=="inProgress" || this.state.actionAlreadyBought === Number(this.state.entreprise.maxActions)}
                                                                                    onChange={(e)=>{this.handleSliderInputChange(e)}}
                                                                                    onBlur={(e)=>{this.handleBlurInputSlider(e)}}
                                                                                    onFocus={event => {
                                                                                        event.target.select();
                                                                                    }}
                                                                                    inputProps={{
                                                                                        step:this.state.entreprise.minActions,
                                                                                        min: (Number(this.state.entreprise.minActions)-this.state.actionAlreadyBought) < 0 ? 1 : (Number(this.state.entreprise.minActions)-this.state.actionAlreadyBought),
                                                                                        max: (Number(this.state.entreprise.maxActions)-this.state.actionAlreadyBought) <= 0 ? 1 : (Number(this.state.entreprise.maxActions)-this.state.actionAlreadyBought),
                                                                                        type: 'number',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className={"flexCenter"} style={{width:'100%', justifyContent:"space-around"}}>
                                                                                <button onClick={()=>{this.setState({displayPaypalPayment:true})}} disabled={(this.state.valueSlider===null ||this.state.valueSlider===0 || this.state.valueSlider<(this.state.entreprise.minActions-this.state.actionAlreadyBought)) || this.state.entreprise.status !=="inProgress" || this.state.actionAlreadyBought === Number(this.state.entreprise.maxActions)} style={{marginTop:15, fontSize:22}} className={"divButtonBuyResale flexCenter"}>
                                                                                    Investir
                                                                                </button>
                                                                                {this.state.actionAlreadyBought === Number(this.state.entreprise.maxActions) && (
                                                                                    <h2>Maximum d'actions atteint</h2>
                                                                                )}
                                                                                <div className={"flexCenter"} style={{flexDirection:"column",display:(this.state.valueSlider===null || this.state.entreprise.status !=="inProgress") && "none"}}>
                                                                                    <h2 className={"flexCenter"} style={{margin:0, fontSize:34}}>
                                                                                        {(this.state.valueSlider||0)<(this.state.entreprise.minActions-this.state.actionAlreadyBought) ? (this.state.entreprise.minActions-this.state.actionAlreadyBought <= 0 ? 1 : this.state.entreprise.minActions-this.state.actionAlreadyBought):this.state.valueSlider}<img style={{width:31, marginTop:4, marginRight:10,marginLeft:3}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/> <span style={{fontSize:22, marginRight:10}}> / </span> {((this.state.valueSlider||0)<(this.state.entreprise.minActions-this.state.actionAlreadyBought) ? (this.state.entreprise.minActions-this.state.actionAlreadyBought <= 0 ? 1 : this.state.entreprise.minActions-this.state.actionAlreadyBought):this.state.valueSlider) * this.state.entreprise.actionValue}<img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                                                    </h2>
                                                                                </div>
                                                                            </div>
                                                                            <p style={{margin:0, marginTop:5, fontWeight:100, fontStyle:"italic", display:(this.state.valueSlider===null || this.state.entreprise.status !=="inProgress") && "none"}}>En cliquant sur "investir", j'atteste avoir lu et visionné les documents téléchargeables ci-dessous.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        )}
                                                    </div>
                                                    <div style={{width:"100%", height:"fit-content",marginTop:30, display:"flex", justifyContent:"flex-start",alignItems:"flex-start", flexDirection:"column"}}>
                                                        <div style={{width:'100%', backgroundColor:"#dcdcdc",height:2, marginTop:10, marginBottom:20, borderRadius:10}}/>
                                                        <div className={"flexCenter"}>
                                                            <h2 className={"flexCenter"}> <Call style={{marginRight:5}}/> Contact</h2>
                                                        </div>
                                                        <div style={{width:"100%",height:"fit-content", display:"flex", justifyContent:"space-around",alignItems:!this.state.showBrowserView ? "center":"flex-start", flexDirection:!this.state.showBrowserView && "column"}}>
                                                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                <h3 style={{fontWeight:100}}>Identité</h3>
                                                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                                    <h4 style={{margin:0}}>{this.state.entreprise.prenom} {this.state.entreprise.nom}</h4>
                                                                </div>
                                                            </div>
                                                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                <h3 style={{fontWeight:100}}>Téléphone</h3>
                                                                <h4 style={{margin:0}}>{this.state.entreprise.tel}</h4>
                                                            </div>
                                                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                <h3 style={{fontWeight:100}}>Email</h3>
                                                                <h4 style={{margin:0}}>{this.state.entreprise.email}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.listInvestor !== "Vous n'avez pas accès à ces données." && (
                                                        <div style={{width:"100%", height:"fit-content",marginTop:30, display:"flex", justifyContent:"flex-start",alignItems:"flex-start", flexDirection:"column"}}>
                                                            <div style={{width:'100%', backgroundColor:"#dcdcdc",height:2, marginTop:!this.state.showBrowserView ? 10:40, marginBottom:20, borderRadius:10}}/>
                                                            <div className={"flexCenter"}>
                                                                <h2 className={"flexCenter"}> <FormatListBulleted style={{marginRight:5}}/> Liste des investisseurs</h2>
                                                            </div>
                                                            <div style={{overflow:"auto",width:"fit-content",height:"fit-content",maxHeight:400, display:"flex", justifyContent:"flex-start",alignItems:"flex-start",flexWrap:"wrap"}}>
                                                                {this.state.listInvestor.length === 0 && (
                                                                    <p>Aucun investisseur pour le moment.</p>
                                                                )}
                                                                {this.state.listInvestor.map(elt=>(
                                                                    <>
                                                                        {elt.boughtActions > 0 &&(
                                                                            <div style={{width: "fit-content", padding:"5px 10px 5px 10px", borderRadius:10, border:"1px solid black", margin:10}}>
                                                                                <p className={"flexCenter"} style={{margin:0}}>{elt.displayedName} : <span className={"flexCenter"} style={{fontWeight:"bold", fontSize:18, marginLeft:5}}>{elt.boughtActions} <img style={{width:25, marginLeft:5}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/></span></p>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div style={{width:"100%", height:"fit-content",marginTop:this.state.listInvestor.length === 0 && 30, display:"flex", justifyContent:"flex-start",alignItems:"flex-start", flexDirection:"column"}}>
                                                        <div style={{width:'100%', backgroundColor:"#dcdcdc",height:2, marginTop:50, marginBottom:20, borderRadius:10}}/>

                                                        <div className={"flexCenter"}>
                                                            <h2 className={"flexCenter"}> <Description style={{marginRight:5}}/> Fichiers téléchargeables</h2>
                                                            <button disabled={this.state.allFiles.length === 0 || this.state.disableButtonDlAllFiles} onClick={()=>{this.dlAllFiles()}} style={{padding:10, marginLeft:20}} className={"divButtonBuyResale"}>Tout télécharger</button>
                                                        </div>
                                                        <div style={{overflow:"auto",width:"fit-content",height:"fit-content",maxHeight:400, display:"flex", justifyContent:"flex-start",alignItems:"flex-start",flexWrap:"wrap"}}>
                                                            {this.state.allFiles.length === 0 && (
                                                                <p>Aucun fichiers déposés.</p>
                                                            )}
                                                            {this.state.allFiles.map(elt=>(
                                                                <div onClick={()=>{this.downloadFile(elt.fileName)}} className={"divUploadFileEchangeCompact flexCenter"} style={{flexDirection:"column", justifyContent:"space-between"}}>
                                                                    <p style={{width:"95%",margin:"5%", textAlign:"center",wordWrap: "break-word", overflow:"hidden", maxHeight:63}}>{elt.fileName}</p>
                                                                    {elt.type === "image" && (
                                                                        <ImageIcon style={{width:"25%", height:"25%"}}/>
                                                                    )}
                                                                    {elt.type === "pdf" && (
                                                                        <PictureAsPdf style={{width:"25%", height:"25%"}}/>
                                                                    )}
                                                                    {elt.type === "fichier" && (
                                                                        <InsertDriveFile style={{width:"25%", height:"25%"}}/>
                                                                    )}
                                                                    <div className={"flexCenter"} style={{height:"30%", width:"100%"}}>
                                                                        <div className={"flexCenter darkenHover"} style={{width:"100%", height:"100%"}}>
                                                                            <Download/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EntrepriseSTO)