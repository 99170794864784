import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import Axios from "axios";
import jwt_decode from "jwt-decode";
import {
    AddCircle,
    ArrowForward,
    Check,
    Close,
    ContentCopy,
    Delete,
    Edit,
    Info,
    NotInterested
} from "@mui/icons-material";
import {Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let columns = [
    { id: 'name', label: 'Name', minWidth: 10 },
    { id: 'image', label: 'Image', minWidth: 10 },
    { id: 'year', label: 'Year', minWidth: 10 },
    { id: 'volume', label: 'Volume (L)', minWidth: 10 },
    { id: 'price', label: 'Price (€)', minWidth: 10 },
    { id: 'action', label: 'Action', minWidth: 10 },
];

const variations = [
    {"id":"variation1-square","name":"Orange 1"},
    {"id":"variation2-square","name":"Orange 2"},
    {"id":"variation3-square","name":"Black 1"},
    {"id":"variation5-square","name":"Black 2"},
    {"id":"variation4-square","name":"Grey 1"},
    {"id":"variation6-square","name":"Grey 2"}
]

const ToastGNFT = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFontTitleToast',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    },
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFont ChampFontTitle',
        content: 'ChampFont ChampFontContent',
        confirmButton: 'ChampFont ChampFontButton',
        cancelButton: 'ChampFont ChampFontButton',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    }
    // confirmButtonTextColor: "#3b3b3b",
})

let jwtDecoded;

class AddBottlesGNFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            addDialog:false,
            creationMode:1,
            brands:[],
            packs:[],
            randNumber:0,
            bottleInPack:[],
            oldBottleInpack:[],
            packPhase:1,
            displayEditImage:false,
            oldChampsNumberTickets:null,
            displayHelpDialog:false,
            champsBouteillePrice:100,
            champsBouteilleVolume:0.75
        };
    }
    _emitConnectedGNFT;
    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.resizePage)
        this._emitConnectedGNFT.remove()
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedGNFT = global.emitter.addListener("emitConnectedGNFT",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("GNFT_jwt")}, ()=>{
                global.emitter.emit("onRouteChangedGNFT", {
                    jwt: this.state.jwt,
                })
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                },()=>{
                    this.connexion()
                })
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("GNFT_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    /*Si on est pas admin alors on redirige vers la page home*/
                    if(jwtDecoded.admin !== true){
                        this.props.navigate("/GrapeNFT/Home")
                    }else{
                        this.setState({connected:true})
                        global.emitter.emit("onRouteChangedGNFT", {
                            jwt: this.state.jwt,
                        })
                        this.getAllBrands()
                        this.getAllPacks()
                    }
                }else{
                    global.emitter.emit("askConnectionGNFT",{disableOutsideClick:true})
                }
            })
        })
    }

    createBrand(e){
        e.preventDefault()
        this.setState({displayLoading:true})
        let fileToRead = e.target[7] ? (e.target[7].files !== undefined ? e.target[7].files[0]:undefined):undefined
        const reader = new FileReader()
        reader.onload = () => {
            const binaryStr = reader.result
            let image = Buffer.from(binaryStr, "base64")
            let dataToSend = {
                nom:this.state.champsNom,
                tel:this.state.champsTel,
                email:this.state.champsEmail,
                adresse:this.state.champsAddress,
                desc:this.state.champsDesc,
                commission:this.state.champsCommission,
                image,
                site:this.state.champsWebsite
            }
            Axios.post(url+"createBrand",dataToSend,{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if(response.data.resultat === "done") {
                    this.setState({addDialog: false})
                    let brands = this.state.brands
                    brands.push(response.data.newBrand)
                    this.setState({brands})
                    SwalDefault.fire({
                        title: 'Success',
                        text: "Brand created",
                        confirmButtonText: 'Ok',
                    }).then((result) => {
                        this.setState({
                            champsNom: "",
                            champsTel: "",
                            champsEmail: "",
                            champsAddress: "",
                            champsDesc: "",
                            champsCommission: "",
                            champsWebsite: "",
                        })
                    })
                }
                this.setState({displayLoading:false})
            })
        }
        reader.readAsArrayBuffer(fileToRead)
    }

    editBrand(e){
        e.preventDefault()
        this.setState({displayLoading:true})
        let fileToRead = e.target[7] ? (e.target[7].files !== undefined ? e.target[7].files[0]:undefined):undefined
        let self = this
        let image = undefined
        if(fileToRead !== undefined){
            const reader = new FileReader()
            reader.onload = () => {
                const binaryStr = reader.result
                image = Buffer.from(binaryStr, "base64")
                editBrandNext()
            }
            reader.readAsArrayBuffer(fileToRead)
        }else{
            editBrandNext()
        }

        function editBrandNext(){
            let newImage = false
            if(image !== undefined){
                newImage = true
            }
            let dataToSend = {
                nom:self.state.champsNom,
                tel:self.state.champsTel,
                email:self.state.champsEmail,
                adresse:self.state.champsAddress,
                desc:self.state.champsDesc,
                commission:self.state.champsCommission,
                image,
                newImage,
                site:self.state.champsWebsite
            }
            Axios.post(url+"editBrand",{...dataToSend,idToken:self.state.brandToEdit.idToken},{headers:{Authorization:"Bearer " + self.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    self.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(self.state.jwt)
                    })
                }
                if(response.data.resultat === "done") {
                    let randNumber = (Math.random()*100000).toFixed(0)
                    self.setState({addDialog: false,randNumber})
                    SwalDefault.fire({
                        title: 'Success',
                        text: "Brand edited",
                        confirmButtonText: 'Ok',
                    }).then((result) => {
                        let brands = self.state.brands
                        /*get index de brand brandToEdit dans brands*/
                        let index = brands.findIndex(brand => brand.idToken === self.state.brandToEdit.idToken)
                        /*ajouter les valeurs de dataToSend dans brands[index]*/
                        delete dataToSend.newImage
                        delete dataToSend.image
                        brands[index] = {...brands[index],...dataToSend}
                        self.setState({
                            brands,
                            champsNom: "",
                            champsTel: "",
                            champsEmail: "",
                            champsAddress: "",
                            champsDesc: "",
                            champsCommission: "",
                            champsWebsite: "",
                        })
                    })
                }
                self.setState({displayLoading:false})
            })
        }
    }

    deleteBrand(brandToDelete){
        SwalDefault.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b3b3b',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({displayLoading:true})
                Axios.post(url+"deleteBrand",{idToken:brandToDelete.idToken},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if(response.data.resultat === "done") {
                        SwalDefault.fire(
                            'Deleted!',
                            'Your brand has been deleted.',
                            'success'
                        ).then((result) => {
                            this.getAllBrands()
                        })
                    }else{
                        SwalDefault.fire({
                            title: 'Error',
                            text: response.data.resultat,
                            confirmButtonText: 'Ok',
                        })
                    }
                    this.setState({displayLoading:false})
                })
            }
        })
    }

    getAllBrands(){
        Axios.post(url+"getBrands",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            this.setState({displayLoading:false})
            if(response.data.resultat === "done") {
                this.setState({
                    brands:response.data.brands
                })
            }
        })
    }

    getAllPacks(){
        Axios.post(url+"getPacks",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            this.setState({displayLoading:false})
            if(response.data.resultat === "done") {
                this.setState({
                    packs:response.data.packs
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    createPack(e){
        e.preventDefault()
        this.setState({displayLoading:true})
        /*Contacter l'axios suivant : createPack : pack (objet), image (buffer), bottles (tableau d'objets)*/
        let fileToRead =this.state.imagePack
        let self = this
        let image = undefined
        if(fileToRead !== undefined){
            const reader = new FileReader()
            reader.onload = () => {
                const binaryStr = reader.result
                image = Buffer.from(binaryStr, "base64")
                createPackNext()
            }
            reader.readAsArrayBuffer(fileToRead)
        }else{
            createPackNext()
        }

        function createPackNext(){
            let dataToSend = {
                pack:{
                    name:self.state.champsPackNom,
                    brand:self.state.champsPackBrand,
                    desc:self.state.champsPackDesc,
                },
                bottles:self.state.bottleInPack,
                image
            }
            Axios.post(url+"createPack",dataToSend,{headers:{Authorization:"Bearer " + self.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    self.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                self.setState({displayLoading:false})
                if(response.data.resultat === "done") {
                    let randNumber = (Math.random()*100000).toFixed(0)
                    self.setState({addDialog: false,randNumber})
                    SwalDefault.fire({
                        title: 'Success',
                        text: "Pack created",
                        confirmButtonText: 'Ok',
                    }).then((result) => {
                        self.setState({
                            champsPackNom: "",
                            champsPackBrand: "",
                            champsPackDesc: "",
                            imagePack: undefined,
                            bottleInPack: [],
                        })
                        self.getAllPacks()
                    })
                }
            })

        }

    }

    deletePack(packToDelete){
        /*Contacter l'axios suivant : deletePack : id (string)*/
        this.setState({displayLoading:true})
        Axios.post(url+"deletePack",{idToken:packToDelete.idToken},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "done") {
                this.getAllPacks()
                SwalDefault.fire({
                    title: 'Success',
                    text: "Pack deleted",
                    confirmButtonText: 'Ok',
                })
            }else{
                SwalDefault.fire({
                    title: 'Error',
                    text: response.data.resultat,
                    confirmButtonText: 'Ok',
                })
            }
            this.setState({displayLoading:false})
        })
    }

    async addBottleToPack(e){
        if(!this.state.champsBouteilleName || !this.state.champsBouteilleVolume || !this.state.champsBouteilleYear || !this.state.champsBouteillePrice){
            SwalDefault.fire({
                title: 'Error',
                text: "Please fill in all the fields",
                confirmButtonText: 'Ok',
            })
            return
        }
        e.preventDefault()
        const fileInput = document.getElementsByName("champsImageIndividualBottle")[0];
        const fileToRead = fileInput.files[0] ? fileInput.files[0] : undefined;
        let self = this
        let image = undefined
        if(fileToRead !== undefined){
            const reader = new FileReader()
            reader.onload = () => {
                const binaryStr = reader.result
                image = Buffer.from(binaryStr, "base64")
                addBottleNext()
            }
            reader.readAsArrayBuffer(fileToRead)
        }else{
            addBottleNext()
        }

        function addBottleNext(){
            let bottle = {
                name:self.state.champsBouteilleName,
                volume:self.state.champsBouteilleVolume,
                year:self.state.champsBouteilleYear,
                price:self.state.champsBouteillePrice,
                image
            }

            let bottleInPack = JSON.parse(JSON.stringify(self.state.bottleInPack))

            bottleInPack.push(bottle)
            self.setState({
                bottleInPack,
                addingBottleToPack:false,
                champsBouteilleName:"", champsBouteilleVolume:"0.75", champsBouteilleYear:"",champsBouteillePrice:"100"
            })
        }
    }

    startEditPack(pack){
        /*Appeler l'axios getOnePack avec data : id du pack*/
        Axios.post(url+"getOnePack", {idTokenPack:pack.idToken},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            let bottleInPack = []
            if(response.data.resultat === "done") {
                bottleInPack = JSON.parse(JSON.stringify(response.data.bottles))
            }
            this.setState({addDialog:true,packPhase:1, packToEdit:pack, bottleInPack:bottleInPack, oldBottleInpack:bottleInPack, champsPackNom:pack.name,champsPackDesc:pack.desc, champsPackBrand:pack.brand,imagePack:null})
        })
    }

    editPack(e){
        e.preventDefault()
        this.setState({displayLoading:true})
        /*Contacter l'axios suivant : createPack : pack (objet), image (buffer), bottles (tableau d'objets)*/
        let fileToRead =this.state.imagePack
        let self = this
        let image = undefined
        if(fileToRead !== undefined){
            const reader = new FileReader()
            reader.onload = () => {
                const binaryStr = reader.result
                image = Buffer.from(binaryStr, "base64")
                editPackNext()
            }
            reader.readAsArrayBuffer(fileToRead)
        }else{
            editPackNext()
        }

        function editPackNext(){
            let addedBottles = [], deletedBottles = [];
            self.state.bottleInPack.forEach(bottle=>{

                let found = false
                self.state.oldBottleInpack.forEach(oldBottle=>{
                    if(bottle.name === oldBottle.name && bottle.volume === oldBottle.volume && bottle.year === oldBottle.year){
                        found = true
                    }
                })
                if(!found){
                    addedBottles.push(bottle)
                }
            })
            self.state.oldBottleInpack.forEach(oldBottle=>{
                let found = false
                self.state.bottleInPack.forEach(bottle=>{
                    if(bottle.name === oldBottle.name && bottle.volume === oldBottle.volume && bottle.year === oldBottle.year){
                        found = true
                    }
                })
                if(!found){
                    deletedBottles.push(oldBottle)
                }
            })
            let dataToSend = {
                pack:{
                    idToken:self.state.packToEdit.idToken,
                    name:self.state.champsPackNom,
                    brand:self.state.champsPackBrand,
                    desc:self.state.champsPackDesc,
                },
                addedBottles,
                deletedBottles,
                image
            }
            Axios.post(url+"editPack",dataToSend,{headers:{Authorization:"Bearer " + self.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    self.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(self.state.jwt)
                    })
                }
                if(response.data.resultat === "done") {
                    let randNumber = (Math.random()*100000).toFixed(0)
                    self.setState({addDialog: false,randNumber})
                    SwalDefault.fire({
                        title: 'Success',
                        text: "Pack edited",
                        confirmButtonText: 'Ok',
                    }).then((result) => {
                        self.setState({
                            champsPackNom: "",
                            champsPackBrand: "",
                            champsPackDesc: "",
                            imagePack: undefined,
                            bottleInPack: [],
                        })
                        self.getAllPacks()
                    })
                }else{
                    SwalDefault.fire({
                        title: 'Error',
                        text: response.data.resultat,
                        confirmButtonText: 'Ok',
                    })
                }
                self.setState({displayLoading:false})
            })
        }
    }

    testPayment(){
        Axios.post(url+"testPayment",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            window.open(response.data.url,"_blank")
        })
    }

    closeDialog(){
        this.setState({
            displayHelpDialog:false, addDialog:false, packPhase:1, brandToEdit:null, packToEdit:null, champsNom:"",champsEmail:"",champsTel:"",champsAddress:"",champsWebsite:"",champsDesc:"", champsCommission:"", champsPackDesc:"", champsPackNom:"",champsPackBrand:"",bottleInPack:[]
        })
    }

    render() { //Affichage sur la page
        return (
            <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", overflowX:"hidden", flexDirection:"column" ,height:"100%",width:"100%"}}>
                <div onClick={()=>{this.closeDialog()}} style={{display:(!this.state.addDialog && !this.state.displayHelpDialog) && "none", alignItems:"flex-start"}} className={"backdrop-dialog-catalog-GNFT"}/>
                <Backdrop
                    sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: 99999999 }}
                    open={this.state.displayLoading}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <div className={"loaderTextGNFT ChampFont"}>Loading</div>
                        <h2 className={"ChampFont"} style={{color:"#FFBB41FF"}}>Please wait a few seconds</h2>
                    </div>
                </Backdrop>
                <div style={{flexDirection:"column",opacity:this.state.displayHelpDialog ? 1:0, transform:this.state.displayHelpDialog ? "scale(1)":"scale(0)", boxShadow:"rgb(50 50 93 / 25%) 1px 20px 60px -12px inset, rgb(0 0 0 / 30%) 0px 0px 50px 8px inset", justifyContent:"flex-start",width:!this.state.showBrowserView && "100vw",height:!this.state.showBrowserView && "calc(100vh - 70px)", top:!this.state.showBrowserView && 70, left:!this.state.showBrowserView && 0}} className={"flexCenter dialog-catalog-GNFT"}>
                    <div style={{width:"100%", height:1, position:"relative"}}>
                        <Close onClick={()=>{this.closeDialog()}} style={{position:"absolute", color:"#3b3b3b", right:0, width:45, height:45, cursor:"pointer"}}/>
                    </div>
                    <div className={"flexCenter"} style={{width:"100%", height:"100%", overflow:"auto"}}>
                        {this.state.creationMode === 1 && (
                            <div className={"flexCenter"} style={{width:"80%", height:"90%",  flexDirection:"column", justifyContent:"space-between", alignItems:"flex-start"}}>
                                <h1 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:600, margin:0}}> How to create a brand</h1>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - Creating a brand is very important for the GrapeNFT system, it allows for the efficient and automatic sharing of brand revenues, as well as guiding the customer during their purchase.</h2>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - You will need to provide a certain amount of information such as: a name, a phone number, an email, an address, a description, a commission, a website url, and an illustration. </h2>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - The illustration will be displayed in the corner of the pack illustration to indicated which brand this bottle is associated to. </h2>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - It is GREATLY recommended to provide an image in the following format :  </h2>
                                <div className={"flexCenter"} style={{justifyContent:"space-around", width:"100%"}}>
                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                        <img style={{width:200}} src={require("../../assets/squareFormatExplanation.png")} alt={"squareFormatExplanation"}/>
                                        <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:600, margin:0}}> Square format </h2>
                                    </div>
                                    <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:600, margin:0}}> AND </h2>
                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                        <img style={{width:200}} src={require("../../assets/NoBackgroundExplanation.png")} alt={"squareFormatExplanation"}/>
                                        <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:600, margin:0}}> Background removed </h2>
                                    </div>
                                </div>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> These settings will allow you to have a nice looking catalog and bottle display.</h2>
                            </div>
                        )}
                        {this.state.creationMode === 2 && (
                            <div className={"flexCenter"} style={{width:"80%", height:"90%",  flexDirection:"column", justifyContent:"space-between", alignItems:"flex-start"}}>
                                <h1 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:600, margin:0}}> How to create a pack</h1>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - Creating a pack allows you to group similar bottles together in the same slot in the catalog, which can be handy for maintaining maximum clarity on the site. However, if you only want to display unique bottles in your catalog, you are free to add only one bottle per pack.</h2>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - You will need to provide a certain amount of information such as: a name, the brand associated to this pack, a description, and an illustration. </h2>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - You will then be prompted to add bottles to this pack, these bottles are the NFTs that are being sold to the client. </h2>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - For each bottle you will need to indicate a name, a volume, a year, a price and an illustration. </h2>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - Beware not to confuse the PACK illustration and the BOTTLES illustrations : the pack illustration is only used in the catalog whereas the bottle illustration is part of the NFT sold with the bottle. </h2>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> - It is GREATLY recommended to provide an image in the following format :  </h2>
                                <div className={"flexCenter"} style={{justifyContent:"space-around", width:"100%"}}>
                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                        <img style={{width:200}} src={require("../../assets/SquareFormatBottleExplanation.png")} alt={"squareFormatExplanation"}/>
                                        <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:600, margin:0}}> Square format </h2>
                                    </div>
                                    <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:600, margin:0}}> AND </h2>
                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                        <img style={{width:200}} src={require("../../assets/GoodBackgroundBottleExplanation.png")} alt={"squareFormatExplanation"}/>
                                        <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:600, margin:0}}> Light background added </h2>
                                    </div>
                                </div>
                                <h2 className={"ChampFont flexCenter"} style={{color:"#00", fontWeight:100, margin:0}}> These settings will allow you to have a nice looking catalog and bottle display.</h2>
                            </div>
                        )}
                    </div>
                </div>

                <div style={{flexDirection:"column",opacity:this.state.addDialog ? 1:0, transform:this.state.addDialog ? "scale(1)":"scale(0)", boxShadow:"rgb(50 50 93 / 25%) 1px 20px 60px -12px inset, rgb(0 0 0 / 30%) 0px 0px 50px 8px inset", justifyContent:"flex-start",width:!this.state.showBrowserView && "100vw",height:!this.state.showBrowserView && "calc(100vh - 70px)", top:!this.state.showBrowserView && 70, left:!this.state.showBrowserView && 0}} className={"flexCenter dialog-catalog-GNFT"}>
                    <div style={{width:"100%", height:1, position:"relative"}}>
                        <Close onClick={()=>{this.closeDialog()}} style={{position:"absolute", color:"#3b3b3b", right:0, width:45, height:45, cursor:"pointer"}}/>
                    </div>
                    <div className={"flexCenter"} style={{width:"100%", height:"100%", flexDirection:"column"}}>
                        {this.state.creationMode === 1 && (
                            <>
                                <h1 className={"ChampFont"}>
                                    {this.state.brandToEdit ? "Edit a brand" : "Add a brand"}
                                </h1>
                                <form onSubmit={(e)=>{!this.state.brandToEdit ? (this.createBrand(e)):(this.editBrand(e))}} className={"flexCenter"} style={{width:"80%", flexDirection:"column", justifyContent:"space-between"}}>
                                    <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                        <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                            Name
                                            <input name={"champsNom"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsNom} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"text"} placeholder={"Name"}/>
                                        </label>
                                        <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                            Phone number
                                            <input name={"champsTel"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsTel} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"tel"} placeholder={"Phone number"}/>
                                        </label>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                        <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                            Email
                                            <input name={"champsEmail"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsEmail} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"email"} placeholder={"Email"}/>
                                        </label>
                                        <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                            Address
                                            <input name={"champsAddress"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsAddress} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"text"} placeholder={"Address"}/>
                                        </label>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                        <label className={"flexCenter ChampFont"} style={{width:"100%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                            Description
                                            <textarea name={"champsDesc"} draggable={false} rows={3} required className={"champsTexteGNFT ChampFont"} value={this.state.champsDesc} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF",resize: "none", width:"98%", margin:"5px 0px 5px 0px"}} placeholder={"Description"}></textarea>
                                        </label>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                        <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                            Commission (%)
                                            <input name={"champsCommission"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsCommission} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"text"} placeholder={"Commission (%)"}/>
                                        </label>
                                        <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                            Website
                                            <input name={"champsWebsite"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsWebsite} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"url"} placeholder={"Website"}/>
                                        </label>
                                    </div>
                                    {this.state.brandToEdit ? (
                                        <>
                                            {this.state.displayEditImage ? (
                                                <div className={"flexCenter"} style={{position:"relative", width:"100%"}}>
                                                    <label className={"flexCenter ChampFont"} style={{width:"100%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                                        Brand illustration
                                                        <input name={"champsImage"} required={!this.state.brandToEdit} className={"champsTexteGNFT ChampFont"} style={{border:"1px solid #232323FF", color:"#232323FF", width:"98%", margin:"5px 0px 5px 0px"}} type={"file"} placeholder={"Image"}/>
                                                    </label>
                                                    <Close style={{cursor:"pointer", position:"absolute", right:15, width:35, height:35}} onClick={()=>{this.setState({displayEditImage:false})}} />
                                                </div>
                                            ):(
                                                <button className={"buttonGNFT"} style={{width:"100%", marginTop:10, marginBottom:20, backgroundColor:"#FFBB41FF", color:"#232323FF"}} onClick={()=>{this.setState({displayEditImage:true})}}> <h2 style={{margin:0}} className={"ChampFont flexCenter"}>Edit Image <Edit style={{marginLeft:10}}/></h2></button>
                                            )}
                                        </>
                                    ):(
                                        <label className={"flexCenter ChampFont"} style={{width:"100%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                            Brand illustration
                                            <input name={"champsImage"} required={!this.state.brandToEdit} className={"champsTexteGNFT ChampFont"} style={{border:"1px solid #232323FF", color:"#232323FF", width:"98%", marginTop:3}} type={"file"} placeholder={"Image"}/>
                                        </label>
                                    )}
                                    <button className={"buttonGNFT"} style={{width:"100%", marginTop:10, marginBottom:20, backgroundColor:"#FFBB41FF", color:"#232323FF"}}> <h2 style={{margin:0}} className={"ChampFont flexCenter"}>{this.state.brandToEdit ? `Validate Edit` : "Create"} <Check style={{marginLeft:10}}/></h2></button>
                                </form>
                            </>
                        )}
                        {this.state.creationMode === 2 && (
                            <>
                                <h1 className={"ChampFont"}>
                                    {this.state.packToEdit ? "Edit a pack" : "Add a pack"}
                                </h1>

                                {this.state.packPhase === 1 ? (
                                    <form onSubmit={(e)=>{e.preventDefault(); this.setState({packPhase:2,imagePack:(e.target[3].files && e.target[3].files[0])})}} className={"flexCenter"} style={{width:"80%", flexDirection:"column", justifyContent:"space-between"}}>
                                        <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                            <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                                Name
                                                <input name={"champsPackNom"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsPackNom} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"text"} placeholder={"Name"}/>
                                            </label>
                                            {/*<select name={"champsVariationColor"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsVariationColor} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"25%"}} placeholder={"Variation"}>*/}
                                            {/*    <option style={{fontWeight:600}} value={""}>Choose a variation ...</option>*/}
                                            {/*    {variations.map((varia)=>(*/}
                                            {/*        <option value={varia.id}>{varia.name}</option>*/}
                                            {/*    ))}*/}
                                            {/*</select>*/}
                                            <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                                Brand
                                                <select name={"champsPackBrand"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsPackBrand} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} placeholder={"Brand"}>
                                                    <option style={{fontWeight:600}} value={""}>Choose a brand ...</option>
                                                    {this.state.brands.map((brand)=>(
                                                        <option value={brand.idToken}>{brand.nom}</option>
                                                    ))}
                                                </select>
                                            </label>

                                        </div>
                                        <label className={"flexCenter ChampFont"} style={{width:"100%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                            Description
                                            <textarea name={"champsPackDesc"} draggable={false} rows={5} required className={"champsTexteGNFT ChampFont"} value={this.state.champsPackDesc} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF",resize: "none", width:"100%", margin:"5px 0px 5px 0px"}} placeholder={"Description"}></textarea>
                                        </label>
                                        {this.state.packToEdit ? (
                                            <>
                                                {this.state.displayEditImage ? (
                                                    <div className={"flexCenter"} style={{position:"relative", width:"100%"}}>
                                                        <label className={"flexCenter ChampFont"} style={{width:"100%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                                            Pack illustration
                                                            <input required={!this.state.packToEdit} className={"champsTexteGNFT ChampFont"} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"file"} placeholder={"Image"}/>
                                                        </label>
                                                        <Close style={{cursor:"pointer", position:"absolute", right:15, width:35, height:35}} onClick={()=>{this.setState({displayEditImage:false})}} />
                                                    </div>
                                                ):(
                                                    <button className={"buttonGNFT"} style={{width:"100%", marginTop:10, marginBottom:20, backgroundColor:"#FFBB41FF", color:"#232323FF"}} onClick={()=>{this.setState({displayEditImage:true})}}> <h2 style={{margin:0}} className={"ChampFont flexCenter"}>Edit Image <Edit style={{marginLeft:10}}/></h2></button>
                                                )}
                                            </>
                                        ):(
                                            <label className={"flexCenter ChampFont"} style={{width:"100%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                                Pack illustration
                                                <input required={!this.state.packToEdit} className={"champsTexteGNFT ChampFont"} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"file"} placeholder={"Image"}/>
                                            </label>
                                        )}
                                        <button className={"buttonGNFT"} style={{width:"100%", marginTop:10, marginBottom:20, backgroundColor:"#FFBB41FF", color:"#232323FF"}}> <h2 style={{margin:0}} className={"ChampFont flexCenter"}>Next <ArrowForward style={{marginLeft:10}} /></h2></button>
                                    </form>
                                ):(
                                    <form onSubmit={(e)=>{!this.state.packToEdit ? (this.createPack(e)):(this.editPack(e))}} className={"flexCenter"} style={{width:"80%", flexDirection:"column", justifyContent:"space-between"}}>
                                        <>
                                            {this.state.bottleInPack.length === 0 ? (
                                                <div className={"flexCenter"} style={{width:'100%'}}>
                                                    <h2 className={"ChampFont"}>No bottles in this pack ...</h2>
                                                </div>
                                            ):(
                                                <TableContainer style={{backgroundColor:"transparent",overflow:"auto", maxHeight:this.state.addingBottleToPack ? 230:325}}>
                                                    <Table>
                                                        <TableHead style={{backgroundColor:"transparent"}}>
                                                            <TableRow style={{backgroundColor:"transparent"}}>
                                                                {columns.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={"center"}
                                                                        style={{ minWidth: column.minWidth,maxWidth:column.maxWidth, fontWeight:"bold", backgroundColor:"transparent" }}
                                                                    >
                                                                        <h2 className={"ChampFont"} style={{margin:5, fontWeight:"bold"}}>{column.label}</h2>
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {(this.state.bottleInPack).map((bottle, index)=>{
                                                                return(
                                                                    <TableRow key={"bottleRow_"+index}>
                                                                        {columns.map((column)=>{
                                                                            return(
                                                                                <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                                    {column.id === 'action' ?
                                                                                        (
                                                                                            <Delete onClick={()=>{
                                                                                                let bottleInPackTemp = JSON.parse(JSON.stringify(this.state.bottleInPack));
                                                                                                bottleInPackTemp.splice(index,1);
                                                                                                this.setState({bottleInPack:bottleInPackTemp})
                                                                                            }} style={{cursor:"pointer"}} />
                                                                                        ):(
                                                                                            <>
                                                                                                {column.id === 'image' ? (
                                                                                                    <div className={"flexCenter"} style={{width:"100%"}}>
                                                                                                        {bottle.idToken !== undefined ? (
                                                                                                            <img className={"hoverImageRecap"} onClick={()=>{bottle.image && (window.open(url+"GrapeNFT/"+bottle.idToken+"_image.png"))}} style={{maxWidth:55,display:"flex", marginRight:5, borderRadius:10, cursor:"pointer"}} src={url+"GrapeNFT/"+bottle.idToken+"_image.png"} alt="Champagne Bottle"
                                                                                                                 onError={({ currentTarget }) => {
                                                                                                                     currentTarget.onerror = null; // prevents looping
                                                                                                                     console.log("error",currentTarget.src )
                                                                                                                     currentTarget.src= require("../../assets/defaultUnknownBottle.png");
                                                                                                                 }}
                                                                                                            />
                                                                                                        ):(
                                                                                                            <p>/</p>
                                                                                                            )}

                                                                                                    </div>
                                                                                                ):(
                                                                                                    <h2 className={"ChampFont"} style={{margin:5}}>{bottle[column.id] || "Ø"}</h2>
                                                                                                )}
                                                                                            </>
                                                                                        )
                                                                                    }

                                                                                </TableCell>
                                                                            )
                                                                        })}
                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </>
                                        {this.state.addingBottleToPack ? (
                                            <div className={"flexCenter"} style={{flexDirection:"column", width:"100%"}}>
                                                <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                                    <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                                        Bottle name
                                                        <input name={"champsBouteilleName"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsBouteilleNom} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"text"} placeholder={"Bottle name"}/>
                                                    </label>
                                                    <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                                        Bottle year
                                                        <input name={"champsBouteilleYear"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsBouteilleAnnee} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"text"} placeholder={"Bottle year"}/>
                                                    </label>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                                    <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                                        Bottle volume L
                                                        <input name={"champsBouteilleVolume"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsBouteilleVolume} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"text"} placeholder={"Bottle volume (L)"}/>
                                                    </label>
                                                    <label className={"flexCenter ChampFont"} style={{width:"48%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", fontSize:20, margin:0}}>
                                                        Bottle price €
                                                        <input name={"champsBouteillePrice"} required className={"champsTexteGNFT ChampFont"} value={this.state.champsBouteillePrice} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", width:"100%", margin:"5px 0px 5px 0px"}} type={"text"} placeholder={"Sell price"}/>
                                                    </label>
                                                </div>
                                                <input name={"champsImageIndividualBottle"} className={"champsTexteGNFT ChampFont"} style={{border:"1px solid #232323FF", color:"#232323FF",width:"98%"}} type={"file"} placeholder={"Image"}/>
                                                <div className={"flexCenter"} style={{justifyContent:"space-between", width:"25%"}}>
                                                    <button onClick={()=>{this.setState({addingBottleToPack:false,champsBouteilleName:"", champsBouteilleVolume:"", champsBouteilleYear:"",champsBouteillePrice:""})}} className={"buttonGNFT"} style={{width:"fit-content", marginTop:10, marginBottom:20, backgroundColor:"#FF4136FF", color:"#232323FF"}}> <h2 style={{margin:0}} className={"ChampFont flexCenter"}>Cancel <NotInterested style={{marginLeft:5, color:"#232323FF"}}/></h2></button>
                                                    <button onClick={(e)=>{this.addBottleToPack(e)}} className={"buttonGNFT"} style={{width:"fit-content", marginTop:10, marginBottom:20, backgroundColor:"#4BB543FF", color:"#232323FF"}}> <h2 style={{margin:0}} className={"ChampFont flexCenter"}>Add <AddCircle style={{marginLeft:5, color:"#232323FF"}}/></h2></button>
                                                </div>
                                            </div>
                                        ):(
                                            <button onClick={()=>{this.setState({addingBottleToPack:true})}} className={"buttonGNFT"} style={{width:"fit-content", marginTop:10, marginBottom:20, backgroundColor:"#FFBB41FF", color:"#232323FF"}}> <h2 style={{margin:0}} className={"ChampFont flexCenter"}>Add a bottle <AddCircle style={{marginLeft:5,color:"#000"}}/></h2></button>
                                        )}
                                        <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                            <button onClick={()=>{this.setState({packPhase:1})}} className={"buttonGNFT"} style={{width:"48%", marginTop:10, marginBottom:20, backgroundColor:"#FFBB41FF", color:"#232323FF"}}> <h2 style={{margin:0}} className={"ChampFont"}>Back</h2></button>
                                            <button disabled={this.state.addingBottleToPack || this.state.bottleInPack.length===0} className={"buttonGNFT"} style={{width:"48%", marginTop:10, marginBottom:20, backgroundColor:"#FFBB41FF", color:"#232323FF"}}> <h2 style={{margin:0}} className={"ChampFont"}>{this.state.packToEdit ? "Edit" : "Create"}</h2></button>
                                        </div>

                                    </form>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={"flexCenter"} style={{height:"calc(100vh - 70px)", marginTop:70, width:"100%", overflow:"hidden",overflowY:"auto", justifyContent:"space-between"}}>
                    <div className={"flexCenter"} style={{flex:"1 1 10%", height:"100%", zIndex:99,background: "linear-gradient(125deg, rgba(42,44,36,1) 0%, rgba(15,18,0,1) 100%)", flexDirection:"column", justifyContent:"flex-start"}}>
                        {/*<button style={{display:!this.state.connected && "none"}} onClick={()=>{this.testPayment()}}>test payments</button>*/}

                        <div className={"flexCenter"} style={{width:"100%", height:80}}>
                            <div onClick={()=>{this.setState({creationMode:1})}} className={"selectOptionDivGNFT"} style={{backgroundColor:this.state.creationMode === 1 && "#FFF"}}>
                                <h2 className={"ChampFont"} style={{color:this.state.creationMode === 1 ? "#3b3b3b":"#FFF", margin:0}}>Brands</h2>
                            </div>
                            <div onClick={()=>{this.setState({creationMode:2})}} className={"selectOptionDivGNFT"} style={{backgroundColor:this.state.creationMode === 2 && "#FFF"}}>
                                <h2 className={"ChampFont"} style={{color:this.state.creationMode === 2 ? "#3b3b3b":"#FFF", margin:0}}>Packs</h2>
                            </div>
                        </div>
                        <div className={"flexCenter"} style={{width:"100%", textAlign:"left", justifyContent:"flex-start", flexDirection:!this.state.showBrowserView && "column"}}>
                            <h1 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:60, margin:15,marginLeft:this.state.showBrowserView ? 30:0}}>
                                Manage {this.state.creationMode === 1 ? "brands" : "packs"}
                            </h1>
                            <button disabled={!this.state.connected} onClick={()=>{this.setState({addDialog:true})}} style={{width:"fit-content", padding:!this.state.showBrowserView && 10, backgroundColor:"#FFBB41FF"}} className={"buttonGNFT flexCenter"}>
                                <h2 style={{margin:0,color:"#FFF", fontWeight:600}} className={"flexCenter ChampFont"}>Add a {this.state.creationMode === 1 ? "brand":"pack"} <AddCircle style={{color:"#FFF", width:30, height:30, marginLeft:10}}/></h2>
                            </button>
                        </div>
                        <div className={"flexCenter"} style={{width:"100%", textAlign:"left", justifyContent:"flex-start"}}>
                            <h2 onClick={()=>{this.setState({displayHelpDialog:true})}} className={"ChampFont flexCenter"} style={{color:"#FFBB41FF",maxWidth:"80%", fontWeight:100, cursor:"pointer", margin:0, marginLeft:this.state.showBrowserView ? 30:0}}> <Info style={{color:"#FFBB41FF", marginRight:5}} />How to create a {this.state.creationMode === 1 ? "brand" : "pack"} ?</h2>
                        </div>

                        <div className={"flexCenter div-catalogue-GNFT"} style={{width:"100%", margin:0}}>
                            {this.state.creationMode === 1 && (
                                <>
                                    {/*<div onClick={()=>{this.setState({addDialog:true})}} style={{margin:30}} className={"divBrandOrPack flexCenter"}>*/}
                                    {/*    <h2 className={"ChampFont flexCenter"} >Add a brand <AddCircle style={{marginLeft:10}}/> </h2>*/}
                                    {/*</div>*/}
                                    {this.state.brands.map((brand, index)=>(
                                        <div style={{margin:30, flexDirection:"column", cursor:"default"}} className={"divBrandOrPackDisplay flexCenter"}>
                                            <div className={"divBrandOrPackDisplayInner"}>
                                                <div className={"divBrandOrPackDisplayFront"}>
                                                    <h2 style={{marginTop:10, marginBottom:30}} className={"ChampFont"} >{brand.nom}</h2>
                                                    <img style={{width:"80%", maxHeight:200}} src={url+"GrapeNFT/"+brand.idToken+"_image.png?rand="+this.state.randNumber} alt="champagne"/>
                                                </div>
                                                <div className={"divBrandOrPackDisplayBack"}>
                                                    <h3 style={{margin:10}} className={"ChampFont"} >{brand.adresse}</h3>
                                                    <p style={{margin:10, maxHeight:54, overflowY:"auto"}} className={"ChampFont"} >{brand.desc}</p>
                                                    <h3 style={{margin:10}} className={"ChampFont"} >{brand.email}</h3>
                                                    <h3 style={{margin:10}} className={"ChampFont"} >{brand.site}</h3>
                                                    <h3 style={{margin:10}} className={"ChampFont"} >{brand.tel}</h3>
                                                    <div className={"flexCenter"} style={{width:'100%', justifyContent:"space-evenly"}}>
                                                        <button onClick={()=>{this.deleteBrand(brand)}} style={{width:"45%", backgroundColor:"red", color:"#FFF"}} className={"buttonGNFT"}>Delete</button>
                                                        <button onClick={()=>{this.setState({addDialog:true, brandToEdit:brand, champsNom:brand.nom,champsEmail:brand.email,champsTel:brand.tel,champsAddress:brand.adresse,champsWebsite:brand.site,champsDesc:brand.desc,champsCommission:brand.commission})}} style={{width:"45%"}} className={"buttonGNFT"}>Edit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                            {this.state.creationMode === 2 && (
                                <>
                                    {/*<div onClick={()=>{this.setState({addDialog:true, packToEdit:null,packPhase:1,bottleInPack:[]})}} style={{margin:30}} className={"divBrandOrPack flexCenter"}>*/}
                                    {/*    <h2 className={"ChampFont flexCenter"} >Add a pack  <AddCircle style={{marginLeft:10}}/></h2>*/}
                                    {/*</div>*/}
                                    {this.state.packs.map((pack, index)=>(
                                        <div style={{margin:30, flexDirection:"column", cursor:"default"}} className={"divBrandOrPackDisplay flexCenter"}>
                                            <div className={"divBrandOrPackDisplayInner"}>
                                                <div className={"divBrandOrPackDisplayFront"}>
                                                    <div style={{width:"100%", position:"relative"}}>
                                                        <h3 className={"ChampFont"} style={{margin:20, color:"#000", position:"absolute", zIndex:99, textAlign:"left", width:pack.type === "bottle" && "60%", maxHeight:44, overflowY:"auto", fontWeight:700}}>{pack.name}</h3>
                                                    </div>
                                                    <div style={{position:"relative", width:'100%'}}>
                                                        {/*<img onLoad={()=>{this.setState({["imgLoadedBrand_"+index]:true})}} style={{width:"30%", filter:"brightness(1)", position:"absolute", right:5, top:5, maxHeight:50, display:this.state["imgLoadedBrand_"+index] ? "flex":"none"}} src={url+"GrapeNFT/"+item.brand+"_image.png"} alt="brand"/>*/}
                                                        <img style={{width:"30%", filter:"brightness(1)",zIndex:9, position:"absolute", right:5, top:5,display:this.state["imgLoadedBrand_"+index] ? "none":"flex"}} src={url+"GrapeNFT/"+pack.brand+"_imagelowres.png"} alt="brand"/>
                                                    </div>
                                                    <div className={"flexCenter"} style={{height:"100%", position:"relative", width:"100%"}}>
                                                        <img style={{maxHeight:"100%", maxWidth:"100%", position:"absolute", transform:"scale(1)",display:this.state["imgLoaded_"+index] ? "none":"flex"}} src={url+"GrapeNFT/"+pack.idToken+"_imagelowres.png"} alt="Champagne Bottle"/>
                                                    </div>
                                                </div>
                                                <div className={"divBrandOrPackDisplayBack"}>
                                                    <h3 style={{margin:10}} className={"ChampFont"} >Brand : {this.state.brands[this.state.brands.findIndex(elt => elt.idToken === pack.brand)].nom ||"Unknown"}</h3>
                                                    <p style={{margin:10, maxHeight:54, overflowY:"auto"}} className={"ChampFont"} >{pack.desc}</p>
                                                    <h3 style={{margin:10}} className={"ChampFont"} >Bottles : {pack.number}</h3>
                                                    {/*<h3 style={{margin:10}} className={"ChampFont"} >{pack.site}</h3>*/}
                                                    {/*<h3 style={{margin:10}} className={"ChampFont"} >{pack.tel}</h3>*/}
                                                    <div className={"flexCenter"} style={{width:'100%', justifyContent:"space-evenly"}}>
                                                        <button onClick={()=>{this.deletePack(pack)}} style={{width:"45%", backgroundColor:"red", color:"#FFF"}} className={"buttonGNFT"}>Delete</button>
                                                        <button onClick={()=>{this.startEditPack(pack)}} style={{width:"45%"}} className={"buttonGNFT"}>Edit</button>
                                                    </div>
                                                    <button onClick={()=>{
                                                        navigator.clipboard.writeText(baseUrl+ "/GrapeNFT/Catalog?td=" + pack.idToken);
                                                        ToastGNFT.fire({
                                                            icon: 'success',
                                                            title: "Link copied to clipboard"
                                                        })
                                                    }} style={{width:"95%", margin:"2.5%", display:"flex", justifyContent:"center", alignItems:"center"}} className={"buttonGNFT"}>Copy link <ContentCopy style={{margin:0,marginLeft:5}}/> </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(AddBottlesGNFT)