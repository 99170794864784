import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {sha256} from "js-sha256";
import Axios from "axios";
import {loadStripe} from '@stripe/stripe-js';
import {Backdrop} from "@mui/material";
import {isMobile} from "react-device-detect";


const {baseUrl, stripePublicKey, suffix} = config;
const stripe = loadStripe(stripePublicKey);
const url = baseUrl + suffix

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFont ChampFontTitle',
        content: 'ChampFont ChampFontContent',
        confirmButton: 'ChampFont ChampFontButton',
        cancelButton: 'ChampFont ChampFontButton',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    }
})
class InscriptionGNFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            bottleArray:[],
            bottleSelected:null,
            phase:1,
            champsNom:""
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    _emitConnectedGNFT;
    componentWillUnmount(){
        window.removeEventListener('resize', this.resizePage)
        this._emitConnectedGNFT.remove()
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedGNFT = global.emitter.addListener("emitConnectedGNFT",()=>{ //On prévient que le client s'est connecté
            let td = new URLSearchParams(window.location.search).get("td");
            this.props.navigate("/Grape-NFT/Catalog"+(td && ("?td="+td)))
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyString(event){
        let targetName = event.currentTarget.name
        /*Allow alphabet, space and - */
        const onlyString = event.target.value.replace(/[^a-zA-ZÀ-ÿ -]/g, '');
        this.setState({
            [targetName] : onlyString
        })
    }

    handleChangeOnlyNum(event){
        let targetName = event.currentTarget.name
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        this.setState({
            [targetName] : onlyNums
        })
    }

    handleChangePhone(event){
        let targetName = event.currentTarget.name
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if(onlyNums.length < 10){
            this.setState({
                [targetName] : onlyNums
            })
        } else if (onlyNums.length === 10){
            const number = onlyNums.replace(
                /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                '$1 $2 $3 $4 $5'
            );
            this.setState({
                [targetName] : number
            })
        }
    }

    signUp(e){
        /*log all values of input*/
        console.log(this.state)

        e.preventDefault()
        if((this.state.champsPassword.trim()).match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!.@#$%^&*?-])[A-Za-z\d!.@#$%^&*?-]{12,}$/)) {
            if(this.state.champsEmail.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)){
                if((this.state.champsIdentifiant.trim()).match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{4,29}$/)){
                    if(this.state.champsPasswordBis.trim() === this.state.champsPassword.trim()){
                        const dateSplit = this.state.champsDDN.split("-")
                        if(!!this.state.champsTel && this.state.champsTel.length !== 14){
                            SwalDefault.fire({
                                title: 'Error!',
                                text: "Your phone number is not valid. (10 digits required)",
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }else if(parseInt(dateSplit[0]) < 1900 || parseInt(dateSplit[0]) > new Date().getFullYear()-18){
                            if(parseInt(dateSplit[0]) < 1900){
                                SwalDefault.fire({
                                    title: 'Error!',
                                    text: "Your birth date is not valid. (Year below 1900)",
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                })
                            }else if(parseInt(dateSplit[0]) > new Date().getFullYear()-18){
                                SwalDefault.fire({
                                    title: 'Error!',
                                    text: "Your birth date is not valid. (You must be 18)",
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                })
                            }
                        }else{
                            this.setState({displayLoading:true})
                            let obj = {
                                first_name:this.state.champsPrenom ? this.state.champsPrenom.trim(): undefined,
                                last_name:this.state.champsNom ? this.state.champsNom.trim() : undefined,
                                phone:this.state.champsTel ? (("+33"+this.state.champsTel.slice(1,this.state.champsTel.length).replaceAll(" ",""))):undefined,
                                email:this.state.champsEmail ? this.state.champsEmail.trim() : undefined,
                                address: {
                                    city:this.state.champsCity ? this.state.champsCity.trim() : undefined,
                                    country:"FR",
                                    line1:this.state.champsAdresse ? this.state.champsAdresse.trim() : undefined,
                                    postal_code:this.state.champsPostal ? this.state.champsPostal.trim() : undefined,
                                },
                            }
                            if(!!this.state.champsDDN){
                                obj.dob = {
                                    day:this.state.champsDDN && this.state.champsDDN.split("-")[2],
                                    month:this.state.champsDDN && this.state.champsDDN.split("-")[1],
                                    year:this.state.champsDDN && this.state.champsDDN.split("-")[0]
                                }
                            }
                            new Promise(async resolve => {
                                resolve((await stripe).createToken('account',  {
                                    account:{
                                        business_type: 'individual',
                                        tos_shown_and_accepted: true,
                                        individual: obj
                                    }
                                }))
                            }).then(accountToken => {
                                let hash = sha256((this.state.champsIdentifiant.trim()).concat(this.state.champsPassword.trim())) //Hashage avec protocole sha256
                                Axios.post(url + "inscriptionGNFT_old", {
                                    empreinte: hash,
                                    pseudo: this.state.champsIdentifiant.trim(),
                                    clefToEncrypt: this.state.champsPassword.trim(),
                                    email:this.state.champsEmail ? this.state.champsEmail.trim() : undefined,
                                    tel:this.state.champsTel ? this.state.champsTel:undefined,
                                    adresse:this.state.champsAdresse ? this.state.champsAdresse.trim() : undefined,
                                    nom:this.state.champsNom ? this.state.champsNom.trim() : undefined,
                                    prenom:this.state.champsPrenom ? this.state.champsPrenom.trim() : undefined,
                                    postal:this.state.champsPostal ? this.state.champsPostal.trim() : undefined,
                                    ddn:this.state.champsDDN ? this.state.champsDDN : undefined,
                                    ville:this.state.champsCity ? this.state.champsCity.trim() : undefined,
                                    accountToken: accountToken.token.id
                                }).then(response => {
                                    if (response.data.resultat === 'done') {
                                        this.setState({displayLoading:false})
                                        SwalDefault.fire({
                                            title: 'Success!',
                                            text: "Your account has been created !",
                                            confirmButtonText: 'Ok'
                                        }).then(() => {
                                            global.emitter.emit("emitInscrisGNFT", {
                                                identifiant: this.state.champsIdentifiant,
                                                password: this.state.champsPassword
                                            })
                                        })
                                    } else {
                                        this.setState({forceDisable: false,displayLoading:false})
                                        SwalDefault.fire({
                                            title: 'Error !',
                                            text: response.data.resultat,
                                            icon: 'error',
                                            confirmButtonText: 'Ok'
                                        })
                                    }
                                }).catch(error => {
                                    console.log(error);
                                });
                            })
                        }
                    }else{
                        this.setState({forceDisable:false, phase:1})
                        SwalDefault.fire({
                            title: 'Error !',
                            text: "Your private key verification is not valid.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                }else{
                    this.setState({forceDisable:false, phase:1})
                    SwalDefault.fire({
                        title: 'Error !',
                        text: "Your username can only contain letters a-z, numbers 0-9, underscores (_), and periods.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }else{
                this.setState({forceDisable:false, phase:1})
                SwalDefault.fire({
                    title: 'Error !',
                    text: "Your email is in the wrong format.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }else{
            this.setState({forceDisable:false, phase:1})
            let error;
            if (this.state.champsPassword.trim().includes(" ")){
                error = "No spaces allowed."
            }else if (!/[!@#\$%\^&\*\?-]/.test(this.state.champsPassword.trim())){
                error = "A symbol is required."
            }else if (!/\d/.test(this.state.champsPassword.trim())){
                error = "A number is required."
            }else if (!/[A-Z]/.test(this.state.champsPassword.trim())){
                error = "An uppercase character is required."
            }else if (!/[a-z]/.test(this.state.champsPassword.trim())){
                error = "A lowercase character is required."
            }else if (this.state.champsPassword.trim().length < 12){
                error = "Your private key must contain at least 12 characters."
            }
            SwalDefault.fire({
                title: 'Error !',
                text: "Your private key does not meet security criteria. " + error,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }

    render() { //Affichage sur la page
        return (
            <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", overflowX:"hidden", flexDirection:"column" ,height:"100%",width:"100%"}}>
                <Backdrop
                    sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.displayLoading}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <div className={"loaderTextGNFT ChampFont"}>Loading</div>
                        <h2 className={"ChampFont"} style={{color:"#FFBB41FF"}}>Please wait a few seconds while we create your account</h2>
                    </div>
                </Backdrop>
                <div className={"flexCenter"} style={{height:"calc(100vh - 70px)", marginTop:70, width:"100%", overflow:"hidden", justifyContent:"space-between"}}>
                    <div className={"flexCenter"} style={{flex:"1 1 10%", overflow:!this.state.showBrowserView && "auto", height:"100%", alignItems:!this.state.showBrowserView&&"flex-start", zIndex:99,background: "linear-gradient(125deg, rgba(42,44,36,1) 0%, rgba(15,18,0,1) 100%)"}}>
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", width:this.state.showBrowserView ? "100%":"95%"}}>
                            <form onSubmit={(e)=>{
                                if(this.state.phase === 1 ) {
                                    e.preventDefault()
                                    this.setState({phase:2})
                                }else{
                                    this.signUp(e)
                                }
                            }} className={"flexCenter"} style={{width:this.state.showBrowserView ? "50%":"95%", marginLeft:this.state.showBrowserView && 80, flexDirection:"column", alignItems:"flex-start"}}>
                                <h1 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:60, marginBottom:15}}>
                                    Sign up to make a donation
                                </h1>
                                {this.state.phase === 1 ? (
                                    <>
                                        <div className={"flexCenter"} style={{width:"100%", flexDirection:!this.state.showBrowserView && "column", justifyContent:"space-between"}}>
                                            <div style={{position:"relative", width:!this.state.showBrowserView?"95%":"46%", marginBottom:!this.state.showBrowserView&&15}}>
                                                <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#21231d"}}>
                                                    <h3 className={"ChampFont"} style={{color:"#FFF", margin:"0 5px 0 5px"}}>Username</h3>
                                                </div>
                                                <input autoComplete={"off"} name={"champsIdentifiant"} required={true} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsIdentifiant} onChange={(e)=>{this.handleChange(e)}} style={{width:"100%",border:"1px solid #FFBB41FF"}} type={"text"} placeholder={"Username"}/>
                                            </div>
                                            <div style={{position:"relative", width:!this.state.showBrowserView?"95%":"46%"}}>
                                                <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#21231d"}}>
                                                    <h3 className={"ChampFont"} style={{color:"#FFF", margin:"0 5px 0 5px"}}>Email</h3>
                                                </div>
                                                <input autoComplete={"off"} name={"champsEmail"} required={true} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsEmail} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%"}} type={"email"} placeholder={"Email"}/>
                                            </div>
                                        </div>
                                        <div className={"flexCenter"} style={{flexWrap:"wrap", color:"#FFF", width:"100%", justifyContent:"flex-start", alignItems:"flex-start", marginLeft:10}}>
                                            <p className={"ChampFont"} style={{margin:5, padding:0,fontSize:16, fontWeight:"bold"}}>Security rules - Your private key must contain : </p>
                                            <p className={"ChampFont"} style={{margin:5, padding:0, fontSize:14}}>- At least 12 characters</p>
                                            <p className={"ChampFont"} style={{margin:5, padding:0, fontSize:14}}>- At least one uppercase and lowercase character</p>
                                            <p className={"ChampFont"} style={{margin:5, padding:0, fontSize:14}}>- At least one number</p>
                                            <p className={"ChampFont"} style={{margin:5, padding:0, fontSize:14}}>- At least one symbol (!.@#$%^&*?-)</p>
                                            <p className={"ChampFont"} style={{margin:5, padding:0, fontSize:14, marginBottom:8}}>- No spaces</p>
                                        </div>
                                        <div className={"flexCenter"} style={{width:"100%", flexDirection:!this.state.showBrowserView && "column", justifyContent:"space-between",marginTop:10}}>
                                            <div style={{position:"relative", width:!this.state.showBrowserView?"95%":"46%", marginBottom:!this.state.showBrowserView&&15}}>
                                                <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#21231d"}}>
                                                    <h3 className={"ChampFont"} style={{color:"#FFF", margin:"0 5px 0 5px"}}>Private Key</h3>
                                                </div>
                                                <input autoComplete={"off"} name={"champsPassword"}  required={true} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsPassword} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%"}} type={this.state.showPassword ? "text":"password"} placeholder={"Private Key"}/>
                                                <div style={{position:"absolute", width:"fit-content", right:10, top:14}}>
                                                    {this.state.showPassword ? (
                                                        <VisibilityIcon style={{cursor:"pointer", color:"#FFBB41FF"}} onClick={()=>{this.setState({showPassword:false})}}/>
                                                    ):(
                                                        <VisibilityOffIcon style={{cursor:"pointer", color:"#FFBB41FF"}} onClick={()=>{this.setState({showPassword:true})}}/>
                                                    )}
                                                </div>
                                            </div>
                                            <div style={{position:"relative", width:!this.state.showBrowserView?"95%":"46%"}}>
                                                <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#21231d"}}>
                                                    <h3 className={"ChampFont"} style={{color:"#FFF", margin:"0 5px 0 5px"}}>Private Key Verification</h3>
                                                </div>
                                                <input autoComplete={"off"} name={"champsPasswordBis"}  required={true} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsPasswordBis} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%"}} type={this.state.showPassword2 ? "text":"password"} placeholder={"Private Key Verification"}/>
                                                <div style={{position:"absolute", width:"fit-content", right:10, top:14}}>
                                                    {this.state.showPassword2 ? (
                                                        <VisibilityIcon style={{cursor:"pointer", color:"#FFBB41FF"}} onClick={()=>{this.setState({showPassword2:false})}}/>
                                                    ):(
                                                        <VisibilityOffIcon style={{cursor:"pointer", color:"#FFBB41FF"}} onClick={()=>{this.setState({showPassword2:true})}}/>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"flexCenter"} style={{width:"80%", justifyContent:this.state.showBrowserView ? "flex-start":"center", marginTop:20}}>
                                            <button disabled={this.state.displayLoading || !this.state.champsIdentifiant || !this.state.champsEmail || !this.state.champsPassword|| !this.state.champsPasswordBis} style={{width:"fit-content"}} type={"submit"} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Next</h2></button>
                                        </div>
                                    </>
                                ):(
                                    <>
                                        <div className={"flexCenter collapsableDivGNFT"} style={{transform:this.state.collapsePersonal ? "scaleY(0)":"scaleY(1)", maxHeight:this.state.collapsePersonal?"0%":"100%", opacity:this.state.collapsePersonal?0:1}}>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    First Name :
                                                    <br/>
                                                    <span style={{color:"red", fontSize:18}}>CAUTION : Field editable only once. </span>
                                                </h3>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    Last Name :
                                                    <br/>
                                                    <span style={{color:"red", fontSize:18}}>CAUTION : Field editable only once. </span>

                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input required={true} autoComplete={"off"} name={"champsPrenom"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsPrenom} onChange={(e)=>{this.handleChangeOnlyString(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginRight:10}} type={"text"} placeholder={"First Name"}/>
                                                <input required={true} autoComplete={"off"} name={"champsNom"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsNom} onChange={(e)=>{this.handleChangeOnlyString(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginLeft:10}} type={"text"} placeholder={"Last Name"}/>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    Phone number :
                                                </h3>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    Birth Date :
                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input required={true} autoComplete={"off"} name={"champsTel"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsTel} onChange={(e)=>{this.handleChangePhone(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginRight:10}} type={"tel"} placeholder={"Phone number"}/>
                                                <input required={true} autoComplete={"off"} name={"champsDDN"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsDDN} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginLeft:10}} type={"date"} placeholder={"Birth Date"}/>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"100%", textAlign:"left"}}>
                                                    Address :
                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input required={true} autoComplete={"off"} name={"champsAdresse"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsAdresse} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%"}} type={"text"} placeholder={"Address"}/>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    Postal Code :
                                                </h3>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", fontWeight:100, marginBottom:0, marginTop:5, marginLeft:10, width:"50%", textAlign:"left"}}>
                                                    City :
                                                </h3>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <input required={true} autoComplete={"off"} name={"champsPostal"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsPostal} onChange={(e)=>{this.handleChangeOnlyNum(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginRight:10}} type={"text"} placeholder={"Postal Code"}/>
                                                <input required={true} autoComplete={"off"} name={"champsCity"} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsCity} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%", marginLeft:10}} type={"text"} placeholder={"City"}/>
                                            </div>
                                        </div>
                                        <h2 className={"ChampFont"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100}}>
                                            By signing up, you agree to  <a target={"_blank"} href={"https://stripe.com/fr/legal/connect-account"} style={{color:"#ffbb41", fontWeight:600, cursor:"pointer"}}>Stripe's Terms of Service</a> and our <a target={"_blank"} href={"https://stripe.com/fr/legal/connect-account"} style={{color:"#ffbb41", fontWeight:600, cursor:"pointer"}}>Privacy Policy</a>
                                        </h2>
                                        <div className={"flexCenter"} style={{width:"fit-content"}}>
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:this.state.showBrowserView ? "flex-start":"center", marginTop:20}}>
                                                <button disabled={this.state.displayLoading} style={{width:"fit-content", marginRight:20}} onClick={()=>{this.setState({phase:1})}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Previous</h2></button>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:this.state.showBrowserView ? "flex-start":"center", marginTop:20}}>
                                                <button disabled={this.state.displayLoading} type={"submit"} style={{width:"fit-content"}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Sign Up</h2></button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </form>
                            <div className={"flexCenter"} style={{position:"relative", width:"50%", alignItems:"flex-end", display:!this.state.showBrowserView && "none"}}>
                                <img style={{width:"78%", zIndex:21, marginTop:250, minWidth:550}} src={require("../../assets/CB-1.webp")} alt="Champagne Bottle"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(InscriptionGNFT)