import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {isMobile} from "react-device-detect";
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import {Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; //On import le style de l'éditeur qui se trouve dans node_modules
import {convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Close} from "@mui/icons-material";
import Dropzone from "react-dropzone";
import jwt_decode from "jwt-decode";


const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const sizeLimitFile = 10737418240 // 10GB

const formatImage =["jpg","jpeg","png","gif","eps","svg"]

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded;

class SecurEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            authentification:false
        };
    }

    setInitialStates(){
        return{
            stateDrawer:true,
            fromName:"",
            fromEmail:"",
            toName:"",
            toEmail:"",
            subject:"",
            htmlContent:"",
            attachmentsToSend:[],
            editorState: EditorState.createEmpty(),
            displayDialogPiecesJointes:false,
            totalSizePiecesJointes:0,
            showBrowserView:true,
            loadingProcess:false,
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        let pf = new URLSearchParams(window.location.search).get("pf");
        if(!!pf){
            pf = pf.replaceAll("%d%","/")
            this.setState({pf:pf})
        }
        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    console.log(jwtDecoded)
                    if (jwtDecoded.freeClient && !jwtDecoded.sekurEmail){
                        Swal.fire({
                            text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/KageSecur/Accueil", { replace: true })
                        })
                    }else {
                        this.setState({authentification: true})
                        global.emitter.emit("onRouteChangedKageSecur", {
                            user: jwtDecoded.gestionnaire ? "gestionnaire" : "client",
                            pseudo: jwtDecoded.pseudo
                        })
                    }
                }else{
                    window.location.replace("/KageSecur/Accueil")
                }
            })
        })
    }

    onEditorStateChange(editorState){
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        let markup = draftToHtml(rawContentState);

        this.setState({
            editorState,
            htmlContent : markup
        });
    };

    handleChange(event) {
        if(event.nativeEvent.data !== "/"){
            this.setState({
                [event.currentTarget.name]: event.currentTarget.value
            })
        }
    }

    sendEML(){
        if (this.state.fromEmail !== "" && this.state.fromName !== "" && this.state.toEmail !== "" && this.state.toName !== "" && this.state.subject !== "" && this.state.htmlContent !== ""){
            const infoMail = {
                from: this.state.fromName !== "" ? {
                    name:this.state.fromName,
                    email:this.state.fromEmail,
                } : this.state.fromEmail,
                to: this.state.toName !== "" ? {
                    name:this.state.toName,
                    email:this.state.toEmail,
                } : this.state.toEmail,
                subject:this.state.subject,
                htmlContent:this.state.htmlContent,
                attachmentsToSend:this.state.attachmentsToSend
            }
            this.setState({loadingProcess:true})
            Axios.post(url+"sendAndSavedMail", {
                infoMail:infoMail
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                this.setState({loadingProcess:false})
                if(response.data.resultat === "done"){
                    Swal.fire({
                        title:"Email envoyé et sauvegardé !",
                        text: "Vous pourrez retrouver votre email et sa preuve d'envoi dans votre tableau de bord",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        this.setState({...this.setInitialStates()})
                    })
                }
            }).catch((err)=>{
                console.log(err)
            })
        }else{
            Swal.fire({
                title:"Champs manquants",
                text: "Veuillez remplir l'intégralité des champs de texte avant d'envoyer le mail.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.displayDialogPiecesJointes}
                    onClose={()=>{this.setState({displayDialogPiecesJointes: false})}}
                    style={{height:"calc(100% - 90px)",marginTop:90}}
                >
                    <DialogTitle>Ajouter ou retirer des pièces jointes : </DialogTitle>
                    <DialogContent style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
                        <>
                            <div className={"flexCenter"} >
                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                    <Dropzone multiple={true} onDrop={(acceptedFiles) => {
                                        let size = 0;
                                        let attachmentsToSend = this.state.attachmentsToSend.slice()
                                        acceptedFiles.forEach((file,index) => {
                                            const reader = new FileReader()
                                            reader.onload = () => {
                                                const binaryStr = reader.result
                                                attachmentsToSend.push({name:file.name,buffer:Buffer.from(binaryStr, "base64")})
                                                attachmentsToSend.forEach(elt=>{
                                                    size+=elt.buffer.length
                                                })
                                                if (size > 20971520){
                                                    attachmentsToSend.splice(-1,1)
                                                    alert("La taille des pièces jointes cumulées ne doit pas dépasser 20Mo.")
                                                }
                                                if(acceptedFiles.length-1 === index){
                                                    this.setState({attachmentsToSend:attachmentsToSend})
                                                }
                                            }
                                            reader.readAsArrayBuffer(file)

                                        })
                                    }}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()} id={this.state.uploadingFile ? "nohover":"hoverMoving"} style={{padding:30,borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",marginBottom:5, color:"black", cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                    <input {...getInputProps()} />
                                                    <p>Déposez vos pièces jointes ici.</p>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            </div>
                            <div style={{width:450}}>
                                {this.state.attachmentsToSend.map(elt=>(
                                    <p className={"flexCenter"} style={{margin:3}}>{elt.name} <Close style={{cursor:"pointer"}} onClick={()=>{
                                        let attachmentsToSend = this.state.attachmentsToSend.slice()
                                        attachmentsToSend.splice(attachmentsToSend.findIndex(file => file.name === elt.name),1)
                                        this.setState({attachmentsToSend:attachmentsToSend})
                                    }}/></p>
                                ))}
                            </div>
                            <div  className={"flexCenter"}>
                                <Button style={{backgroundColor:"#52a8d9",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({displayDialogPiecesJointes:false})}}>Valider</Button>
                            </div>
                        </>
                    </DialogContent>
                </Dialog>
                <div style={{marginTop:90,height:this.state.showBrowserView ? "calc(100%-90px)":"calc(80%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                    {this.state.showBrowserView ? (
                        <>
                            <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110}} src={require("../../assets/DocumentSecureOrange.png")} alt="imageDocument"/>
                            <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20}} src={require("../../assets/SecurEmailBleuCropped.png")} alt="imageAuction"/>
                            <h1 style={{marginTop:50}}><span style={{color:"#52a8d9"}}>Sekur</span><span style={{color:"#f18f29"}}>Email</span></h1>
                        </>
                    ):(
                        <h3 style={{marginTop:50,textAlign:"center"}}><span style={{color:"#52a8d9"}}>Sekur</span><span style={{color:"#f18f29"}}>Email</span></h3>
                    )}
                    {this.state.authentification && (
                        <div style={{overflowY:"auto",overflowX:"hidden",marginBottom:30,width:this.state.showBrowserView?"50%":"90%",backgroundColor:"white",display:"flex",flexDirection:"column",alignContent:"center",borderRadius:8,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", padding:35}}>
                            <div style={{display:"flex"}}>
                                <p>De:</p>
                                <TextField
                                    name={"fromName"}
                                    variant="outlined"
                                    value={this.state.fromName}
                                    onChange={(e)=>{this.handleChange(e)}}
                                    label={"Nom de l'émetteur"}
                                    style={{margin:5, width:"35%"}}
                                />
                                <TextField
                                    name={"fromEmail"}
                                    variant="outlined"
                                    value={this.state.fromEmail}
                                    onChange={(e)=>{this.handleChange(e)}}
                                    label={"Email de l'émetteur"}
                                    style={{margin:5, width:"60%"}}
                                />
                            </div>
                            <div style={{display:"flex"}}>
                                <p>À:</p>
                                <TextField
                                    name={"toName"}
                                    variant="outlined"
                                    value={this.state.toName}
                                    onChange={(e)=>{this.handleChange(e)}}
                                    label={"Nom du récepteur"}
                                    style={{margin:5, width:"35%"}}
                                />
                                <TextField
                                    name={"toEmail"}
                                    variant="outlined"
                                    value={this.state.toEmail}
                                    onChange={(e)=>{this.handleChange(e)}}
                                    label={"Email du récepteur"}
                                    style={{margin:5, width:"60%"}}
                                />
                            </div>
                            <div style={{display:"flex"}}>
                                <p>Objet:</p>
                                <TextField
                                    name={"subject"}
                                    variant="outlined"
                                    value={this.state.subject}
                                    onChange={(e)=>{this.handleChange(e)}}
                                    label={"Objet de l'email"}
                                    style={{margin:5}}
                                />
                                <Button variant={"contained"} style={{margin:5, marginLeft:15, backgroundColor:"#52A8D9FF"}} onClick={()=>{this.setState({displayDialogPiecesJointes:true})}}>Gérer les pièces jointes</Button>
                            </div>
                            <div>
                                {this.state.attachmentsToSend.length>0&&<p>Pièces jointes :</p>}
                                {this.state.attachmentsToSend.map((elt,index) => (
                                    <>
                                        {index === 2 ? (
                                            <p style={{margin:0}}> (+{this.state.attachmentsToSend.length - 2})</p>
                                        ):(
                                            <>
                                                {index < 2 &&(
                                                    <p style={{margin:0}}>- {elt.name}</p>
                                                )}
                                            </>
                                        )}
                                    </>
                                ))}
                            </div>
                            <div className={"editorMail"} style={{display:"flex", border:"1px solid black", marginTop:30}}>
                                <Editor
                                    editorState={this.state.editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={(e)=>{this.onEditorStateChange(e)}}
                                    localization={{
                                        locale: 'fr',
                                    }}
                                    toolbar={{
                                        options: ['inline', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link'],
                                    }}
                                />
                            </div>
                            <Button disabled={!(this.state.fromEmail !== "" && this.state.fromName !== "" && this.state.toEmail !== "" && this.state.toName !== "" && this.state.subject !== "" && this.state.htmlContent !== "") || this.state.loadingProcess} variant={"contained"} style={{marginTop:5,backgroundColor:!(this.state.fromEmail !== "" && this.state.fromName !== "" && this.state.toEmail !== "" && this.state.toName !== "" && this.state.subject !== "" && this.state.htmlContent !== "") ? "#A9A9A9":"#52a8d9", marginBottom:10, textTransform:"none"}}  onClick={()=>{this.sendEML()}}>
                                <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:1, fontSize:16, color:"#FFFFFF"}}>
                                    {this.state.loadingProcess ? (
                                        <CircularProgress style={{color:"white"}}/>
                                    ):(
                                        "Envoyer"
                                    )}
                                </p>
                            </Button>
                            <i>La signature du serveur sera ajoutée à la fin de l'email.</i>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(SecurEmail)