import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import Axios from "axios";
import {Backdrop} from "@mui/material";
import jwt_decode from "jwt-decode";
import {Cancel, CheckCircle} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFont ChampFontTitle',
        content: 'ChampFont ChampFontContent',
        confirmButton: 'ChampFont ChampFontButton',
        icon: "SwalGNFT-icon",
    }
    // confirmButtonTextColor: "#3b3b3b",
})

let jwtDecoded = {}

class PaymentStatusGNFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            bottleArray:[],
            bottleSelected:null,
            displayLoading:true
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    _emitConnectedGNFT;
    componentWillUnmount(){
        window.removeEventListener('resize', this.resizePage)
        this._emitConnectedGNFT.remove()
    }

    componentDidMount() {
        let token = new URLSearchParams(window.location.search).get("token"),
            statut = new URLSearchParams(window.location.search).get("statut")
        if(token !== null) {
            this.setState({
                token,statut
            })
        }else{
            SwalDefault.fire({
                title: 'Error!',
                text: "Payment not found.",
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.props.navigate("/GrapeNFT/Catalog")
            })
        }
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedGNFT = global.emitter.addListener("emitConnectedGNFT",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("GNFT_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedGNFT", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("GNFT_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                global.emitter.emit("onRouteChangedGNFT", {
                    jwt: this.state.jwt,
                })
                this.finalisePayment()
            })
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    finalisePayment(){
        Axios.post(url+"finalisePaymentGNFT",{resource:{id:this.state.token}},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            this.setState({displayLoading:false})
            if (response.data.resultat === "done"){
                this.setState({price:response.data.price, name:response.data.name})
            }else if (response.data.resultat === "Payment has been cancelled, please contact an administrator for a refund."){
                this.setState({statut:"failure", displayResponse:response.data.resultat})
            }else{
                SwalDefault.fire({
                    title: 'Error!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    this.props.navigate("/GrapeNFT/Catalog")
                })
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", overflowX:"hidden", flexDirection:"column" ,height:"100%",width:"100%"}}>
                <Backdrop
                    sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: 999999999 }}
                    open={this.state.displayLoading}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <div className={"loaderTextGNFT ChampFont"}>Loading</div>
                        <h2 className={"ChampFont"} style={{color:"#FFBB41FF"}}>Please wait a few seconds while we load your payment</h2>
                    </div>
                </Backdrop>
                <div className={"flexCenter"} style={{height:"calc(100vh - 70px)", marginTop:70, width:"100%", overflow:"hidden", justifyContent:"space-between"}}>
                    <div className={"flexCenter"} style={{flex:"1 1 10%", overflow:!this.state.showBrowserView && "auto", height:"100%", alignItems:!this.state.showBrowserView&&"flex-start", zIndex:99,background: "linear-gradient(125deg, rgba(42,44,36,1) 0%, rgba(15,18,0,1) 100%)"}}>
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", width:this.state.showBrowserView ? "100%":"95%"}}>

                                <form onSubmit={(e)=>{this.signUp(e)}} className={"flexCenter"} style={{width:this.state.showBrowserView ? "50%":"95%", marginLeft:this.state.showBrowserView && 80, flexDirection:"column", alignItems:"flex-start"}}>
                                    {!this.state.displayLoading && (
                                        <>
                                            <h1 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:60, marginBottom:15}}>
                                                Payment Status
                                                {this.state.statut === "success" ? (
                                                    <CheckCircle style={{marginLeft:10, width:50,height:50, color:"#4BB543FF"}}/>
                                                ):(
                                                    <Cancel style={{marginLeft:10, width:50,height:50, color:"#FF4136FF"}}/>
                                                )}
                                            </h1>
                                            <h2 className={"ChampFont"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100}}>
                                                {this.state.statut === "success" ? (
                                                    "Congratulations, your payment has been successfully completed. You will receive an email with your invoice shortly."
                                                ):(
                                                    <React.Fragment>
                                                        {this.state.displayResponse ? (
                                                            `${this.state.displayResponse}`
                                                        ):(
                                                            "Unfortunately, your payment has not been completed. Please try again later."
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </h2>
                                            <h1 className={"ChampFont"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, display:this.state.displayResponse && "none"}}>
                                                {this.state.name} - {this.state.price}€
                                            </h1>
                                            <div className={"flexCenter"} style={{width:"80%", justifyContent:this.state.showBrowserView ? "flex-start":"center", marginTop:20}}>
                                                <button onClick={()=>{this.props.navigate("/GrapeNFT/Catalog")}} style={{width:"fit-content"}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Go to the catalog</h2></button>
                                            </div>
                                        </>
                                    )}
                                </form>
                            <div className={"flexCenter"} style={{position:"relative", width:"50%", alignItems:"flex-end", display:!this.state.showBrowserView && "none"}}>
                                <img style={{width:"78%", zIndex:21, marginTop:250, minWidth:550}} src={require("../../assets/CB-1.webp")} alt="Champagne Bottle"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(PaymentStatusGNFT)