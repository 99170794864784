import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {isMobile} from "react-device-detect";

import Axios from "axios";
import config from "../../json/config.json";
import {sha256} from "js-sha256";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import ForwardIcon from "@mui/icons-material/Forward";
import CryptoJS from "crypto-js";
import {Button, CircularProgress} from "@mui/material";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const sizeLimitFile = 10737418240 // Soit 2GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
})

let jwtDecoded;

class TracabiliteKageSecur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{
            hashFile : '',
            fileName : '',
            acceptedFile : null,
            listAllTrace:[],
            signataires:[],
            chargementFichier:false,
            cancelDropZone:false
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    if (jwtDecoded.freeClient){
                        Swal.fire({
                            text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/KageSecur/Accueil", { replace: true })
                        })
                    }else {
                        global.emitter.emit("onRouteChangedKageSecur", {
                            user: jwtDecoded.gestionnaire ? "gestionnaire" : "client",
                            pseudo: jwtDecoded.pseudo
                        })
                        this.trace()
                    }
                }else{
                    global.emitter.emit("askConnection")
                    const self = this;
                    loop()
                    function loop(){
                        if (!!sessionStorage.getItem("KAGESECUR_hash")){
                            self.trace()
                        }else{
                            setTimeout(()=>{
                                loop()
                            },1000)
                        }
                    }
                }
            })
        })
    }

    trace(){
        const queryParams = new URLSearchParams(window.location.search);
        if (!!queryParams.get("hf")){
            Axios.post(url+"tracing", {
                hashFile:queryParams.get("hf"),
            }, {headers:{Authorization:"Bearer " + sessionStorage.getItem("KAGESECUR_jwt")}}).catch((err)=>{console.log(err)}).then((response)=> {
                if(response.data.resultat === "Ce document n'est pas enregistré dans notre blockchain" || response.data.resultat.length === 0){
                    this.setState({hashFile:""})
                    Toast.fire({
                        icon: 'error',
                        title: response.data.resultat.length === 0 ?
                            "Votre document n'a pas encore été validé par un signataire, mais est bien présent dans notre blockchain."
                            :
                            "Ce document n'est pas enregistré dans notre blockchain."
                    })
                }else{
                    this.setState({
                        listAllTrace:response.data.resultat,
                        signataires:response.data.signataires,
                        hashFile:queryParams.get("hf"),
                    })
                }
            })
        }
    }

    handleChange(event) {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    traceHash(){
        Axios.post(url+"tracing", {
            hashFile:sha256(this.state.hashFile),
            hashJwt:this.state.hash
        }, {headers:{Authorization:"Bearer " + this.state.jwt}}).catch((err)=>{console.log(err)}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if(response.data.resultat === "Ce document n'est pas enregistré dans notre blockchain" || response.data.resultat.length === 0){
                let att = response.data.resultat.length === 0
                this.setState({...this.setInitialStates()})
                Swal.fire({
                    title: att ? "Veuillez patienter..." : "Erreur",
                    text: att ? "Votre document n'a pas encore été validé par un signataire, mais est bien présent dans notre blockchain." : response.data.resultat,
                    icon: att ? "warning" : "error",
                    confirmButtonText: 'Ok'
                })
            }else{
                Toast.fire({
                    icon: 'success',
                    title: 'Document détecté dans la blockchain.'
                })
                this.setState({
                    listAllTrace:response.data.resultat,
                    signataires:response.data.signataires,
                })
            }

        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                {this.state.showBrowserView ? (
                    <div style={{marginTop:90,height:"calc(100%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                        <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110}} src={require("../../assets/tracabilitéOrange.png")} alt="imageDocument"/>
                        <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20}} src={require("../../assets/histoireBlue.png")} alt="imageAuction"/>
                        <h1 style={{marginTop:50}}>Tracez <span style={{color:"#52a8d9"}}>l'histoire</span> de <span style={{color:"#f18f29"}}>votre document</span></h1>
                        <div style={{display:"flex", width:"100%",height:"70%", justifyContent:"center", alignItems:"center"}}>
                            <div style={{width:"50%", display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                    acceptedFiles.forEach((file) => {
                                        if(file.size >= sizeLimitFile){
                                            Toast.fire({
                                                icon: 'error',
                                                title: 'Le fichier est trop lourd (>2GB) !'
                                            })
                                        }else {
                                            this.setState({chargementFichier:true, dropzoneProgress:0,cancelDropZone:false, hashFile:"",listAllTrace:[]})
                                            let stream = file.stream()
                                            let re = stream.getReader()
                                            let progressDropzone = 0
                                            let SHA = CryptoJS.algo.SHA256.create()
                                            let self = this
                                            new ReadableStream({
                                                start(controller) {
                                                    function push() {
                                                        re.read().then( ({done, value}) => {
                                                            if (done) {
                                                                const finalHash = SHA.finalize().toString()
                                                                self.setState({chargementFichier:false,hashFile:finalHash,dropzoneProgress:100},()=>{
                                                                    self.traceHash()
                                                                })
                                                                controller.close();
                                                                return;
                                                            }
                                                            let wordBuffer = CryptoJS.lib.WordArray.create(value);
                                                            SHA.update(wordBuffer);
                                                            progressDropzone = parseFloat(progressDropzone+(value.length/file.size)*100)
                                                            self.setState({dropzoneProgress:progressDropzone})
                                                            setTimeout(()=>{
                                                                if (!self.state.cancelDropZone){
                                                                    push();
                                                                }else{
                                                                    self.setState({...self.setInitialStates()})
                                                                }
                                                            },10)
                                                        })
                                                    }
                                                    push();
                                                }
                                            });
                                        }
                                    })
                                }}>
                                    {({getRootProps, getInputProps}) => (
                                        <section style={{display:"flex",alignItems:"center",justifyContent:"center", flexDirection:"column"}}>
                                            <div {...getRootProps()} id={this.state.hashFile === "" ? "hoverMoving":"nohover"} style={{padding:"10vw", paddingTop:this.state.hashFile === '' ? ("8vw"):("6vw"), paddingBottom:this.state.hashFile === '' ? ("8vw"):("6vw"),borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:this.state.hashFile===""?("black"):("white"),fontWeight:"bold", cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:this.state.hashFile===""?("transparent"):("#369dd7")}}>
                                                <input {...getInputProps()} />
                                                {this.state.hashFile === '' ? (
                                                    <>
                                                        {this.state.chargementFichier === true ? (
                                                            <div style={{position:"relative"}}>
                                                                <CircularProgress size={150}/>
                                                                <h2 style={{position:"absolute", top:36,left:48}}>{parseInt(this.state.dropzoneProgress)}%</h2>
                                                            </div>
                                                        ):(
                                                            <h3>Déposez votre fichier ici.</h3>
                                                        )}
                                                    </>
                                                ):(
                                                    <h3>Traçabilité du document confirmée.</h3>
                                                )}
                                            </div>
                                            {this.state.chargementFichier === true &&
                                                <Button variant={"contained"} style={{textTransform:"none", marginTop:25,backgroundColor:"#52a8d9", color:"#FFFFFF"}} onClick={()=>{this.setState({cancelDropZone:true})}}>Arrêter le processus</Button>
                                            }
                                            {this.state.listAllTrace.length > 0 && (
                                                <div style={{backgroundColor:"white",display:"flex",justifyContent:"center",marginTop:20,alignItems:"center",borderRadius:5,width:"100%",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                                                    <div style={{marginBottom:15}}>
                                                        <h3 style={{marginBottom:3}}>Hash du document :</h3>
                                                        <span style={{fontSize:13, marginTop:0}}>{this.state.hashFile}</span>
                                                        <h4 style={{marginBottom:3}}>Id Token : </h4>
                                                        <span style={{fontSize:13, marginTop:0}}>{this.state.listAllTrace[0].idToken}</span>
                                                        <h4 style={{marginBottom:3}}>Signataire(s) : </h4>
                                                        <span style={{fontSize:13, marginTop:0}}>{this.state.signataires.length>0 ?this.state.signataires.join(" - "):"Aucun"}</span>
                                                    </div>

                                                </div>
                                            )}
                                        </section>
                                    )}
                                </Dropzone>
                            </div>
                            <div style={{width:"50%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                {this.state.listAllTrace.length > 0 ? (
                                    <div style={{marginTop:50,height: "100%", width: "70%",display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                                        <div style={{overflowY:"scroll", maxHeight:"65vh"}} >
                                            {this.state.listAllTrace.map((trace,index) => (
                                                <div style={{display:"flex", justifyContent:"center",alignItems:"center", flexDirection:"column",marginRight:40,marginLeft:40}}>
                                                    {trace.type==="sekuremail" && console.log(JSON.parse(trace.to).name, trace.to.email)}
                                                    <div style={{backgroundColor:"white",padding:15,display:"flex",outline: "2px dashed #4FA8DA",flexDirection:"column",justifyContent:"center",marginTop:trace.type === "transfer" ? 20:10, marginBottom:20,alignItems:"center",borderRadius:5,width:"100%",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                                                        {trace.type === "transfer" && (
                                                            <>
                                                                <p style={{marginBottom:5}}>Identification du possesseur : </p>
                                                                <p style={{marginTop:0, marginBottom:5,fontWeight:"bold"}}>{trace.pseudo}</p>
                                                                <p style={{marginBottom:5}}>Empreinte du possesseur : </p>
                                                                <p style={{marginTop:0, fontWeight:"bold"}}>{trace.empreinte}</p>
                                                                <p>Date de l'obtention : <span style={{fontWeight:"bold"}}>{new Date(parseFloat(trace.date)).toLocaleString('fr-FR', { timeZone: 'UTC' })} UTC</span></p>
                                                                {!isNaN(Number(trace.value)) ? (
                                                                    <p>Prix échangé : <span style={{fontWeight:"bold"}}>{trace.value} STC</span></p>
                                                                ):(
                                                                    <p>Moyen d'obtention : <span style={{fontWeight:"bold"}}>{trace.value}</span></p>
                                                                )}
                                                            </>
                                                        )}
                                                        {trace.type === "signature" && (
                                                            <p style={{textAlign:"center"}}><span style={{fontWeight:"bold"}}>{trace.pseudo}</span> a signé le document à <span style={{fontWeight:"bold"}}>{trace.labelRecepteur}</span> le <span style={{fontWeight:"bold"}}>{new Date(parseFloat(trace.date)).toLocaleString("fr-FR", {timeZone:"UTC"}).replace(","," à")} UTC</span></p>
                                                        )}
                                                        {(trace.type === "sekuremail" && trace.delivered) && (
                                                            <p style={{textAlign:"center"}}>L'email envoyé via <span style={{fontWeight:"bold"}}>SekurEmail</span> a été consulté par <span style={{fontWeight:"bold"}}>{JSON.parse(trace.to).email}</span> le <span style={{fontWeight:"bold"}}>{new Date(parseFloat(trace.date)).toLocaleString("fr-FR", {timeZone:"UTC"}).replace(","," à")} UTC</span></p>
                                                        )}
                                                        {trace.type === "vlt" && (
                                                            <p style={{textAlign:"center"}}><span style={{fontWeight:"bold"}}>{trace.labelRecepteur}</span> a consulté la preuve envoyée par <span style={{fontWeight:"bold"}}>{trace.pseudoEmetteur}</span> le <span style={{fontWeight:"bold"}}>{new Date(parseFloat(trace.date)).toLocaleString("fr-FR" , {timeZone:"UTC"}).replace(","," à")} UTC</span></p>
                                                        )}
                                                    </div>
                                                    {trace.type === "vlt" && (
                                                        <>
                                                            {this.state.listAllTrace.length-1 > index && (
                                                                <>
                                                                    {this.state.listAllTrace[index+1].type === "transfer" &&
                                                                    (
                                                                        <ForwardIcon style={{transform:"rotate(90deg) scale(2)", color:"#52a8d9"}}/>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ):(
                                    <>
                                        <h3>Fonctionnement de la traçabilité :</h3>
                                        <div>
                                            <p style={{fontSize:17, margin:20, marginRight: 80}} >Une fois votre document déposé, une <span style={{color:"#52a8d9", fontWeight:"bold"}}>recherche</span> permet de tracer l'historique des <span style={{color:"#52a8d9", fontWeight:"bold"}}>transactions effectuées</span> avec ce document. Vous pourrez observer à qui a appartenu ce document depuis le début de son <span style={{color:"#52a8d9", fontWeight:"bold"}}>dépôt</span> sur notre système.</p>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                ):(
                    <div style={{marginTop:90,height:"calc(100%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                        <h2 style={{marginTop:20, textAlign:"center"}}>Tracez <span style={{color:"#52a8d9"}}>l'histoire</span> de <span style={{color:"#f18f29"}}>votre document</span></h2>
                        {this.state.hashFile ===""? (
                            <div style={{width:"90%", display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                    acceptedFiles.forEach((file) => {
                                        if(file.size >= sizeLimitFile){
                                            Toast.fire({
                                                icon: 'error',
                                                title: 'Le fichier est trop lourd (>2GB) !'
                                            })
                                        }else {
                                            this.setState({chargementFichier:true, dropzoneProgress:0,cancelDropZone:false, hashFile:"",listAllTrace:[]})
                                            let stream = file.stream()
                                            let re = stream.getReader()
                                            let progressDropzone = 0
                                            let SHA = CryptoJS.algo.SHA256.create()
                                            let self = this
                                            new ReadableStream({
                                                start(controller) {
                                                    function push() {
                                                        re.read().then( ({done, value}) => {
                                                            if (done) {
                                                                const finalHash = SHA.finalize().toString()
                                                                self.setState({chargementFichier:false,hashFile:finalHash,dropzoneProgress:100},()=>{
                                                                    self.traceHash()
                                                                })
                                                                controller.close();
                                                                return;
                                                            }
                                                            let wordBuffer = CryptoJS.lib.WordArray.create(value);
                                                            SHA.update(wordBuffer);
                                                            progressDropzone = parseFloat(progressDropzone+(value.length/file.size)*100)
                                                            self.setState({dropzoneProgress:progressDropzone})
                                                            setTimeout(()=>{
                                                                if (!self.state.cancelDropZone){
                                                                    push();
                                                                }else{
                                                                    self.setState({...self.setInitialStates()})
                                                                }
                                                            },10)
                                                        })
                                                    }
                                                    push();
                                                }
                                            });
                                        }
                                    })
                                }}>
                                    {({getRootProps, getInputProps}) => (
                                        <section style={{display:"flex",alignItems:"center",justifyContent:"center", flexDirection:"column"}}>
                                            <div {...getRootProps()} id={this.state.hashFile === "" ? "hoverMoving":"nohover"} style={{padding:"8vw",paddingTop:"16vw",paddingBottom:"16vw",borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:this.state.hashFile===""?("black"):("white"),fontWeight:"bold", cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:this.state.hashFile===""?("transparent"):("#369dd7")}}>
                                                <input {...getInputProps()} />
                                                {this.state.hashFile === '' ? (
                                                    <>
                                                        {this.state.chargementFichier === true ? (
                                                            <div style={{position:"relative"}}>
                                                                <CircularProgress size={150}/>
                                                                <h2 style={{position:"absolute", top:36,left:48}}>{parseInt(this.state.dropzoneProgress)}%</h2>
                                                            </div>
                                                        ):(
                                                            <h3>Déposez votre fichier ici.</h3>
                                                        )}
                                                    </>
                                                ):(
                                                    <h3>Traçabilité du document confirmée.</h3>
                                                )}
                                            </div>
                                            {this.state.chargementFichier === true &&
                                            <Button variant={"contained"} style={{textTransform:"none", marginTop:25,backgroundColor:"#52a8d9", color:"#FFFFFF"}} onClick={()=>{this.setState({cancelDropZone:true})}}>Arrêter le processus</Button>
                                            }
                                        </section>
                                    )}
                                </Dropzone>
                                <h3 style={{marginTop:60}}>Fonctionnement de la traçabilité :</h3>
                                <div>
                                    <p style={{fontSize:17, margin:20, marginTop:8}} >Une fois votre document déposé, une <span style={{color:"#52a8d9", fontWeight:"bold"}}>recherche</span> permet de tracer l'historique des <span style={{color:"#52a8d9", fontWeight:"bold"}}>transactions effectuées</span> avec ce document. Vous pourrez observer à qui a appartenu ce document depuis le début de son <span style={{color:"#52a8d9", fontWeight:"bold"}}>dépôt</span> sur notre système.</p>
                                </div>
                            </div>
                        ):(
                            <div style={{width:"90%", display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                {this.state.listAllTrace.length > 0 && (
                                    <div style={{marginTop:0,height: "100%", width:"100%",display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                                        {this.state.listAllTrace.length > 0 && (
                                            <div style={{backgroundColor:"white",display:"flex",justifyContent:"center",marginTop:20,alignItems:"center",borderRadius:5,width:"100%",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                                                <div style={{marginBottom:15, width:"100%",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center", margin:10}}>
                                                    <h3 style={{marginBottom:3}}>Hash du document :</h3>
                                                    <span style={{fontSize:13, marginTop:0, width:"100%", textAlign:"center"}}>{this.state.hashFile.substring(0,this.state.hashFile.length/2)}</span>
                                                    <span style={{fontSize:13, marginTop:0, width:"100%", textAlign:"center"}}>{this.state.hashFile.substring(this.state.hashFile.length/2,this.state.hashFile.length)}</span>
                                                    <h4 style={{marginBottom:3}}>Id Token : </h4>
                                                    <span style={{fontSize:13, marginTop:0}}>{this.state.listAllTrace[0].idToken}</span>
                                                    <h4 style={{marginBottom:3}}>Signataire(s) : </h4>
                                                    <span style={{fontSize:13, marginTop:0}}>{this.state.signataires.length>0 ?this.state.signataires.join(" - "):"Aucun"}</span>
                                                </div>

                                            </div>
                                        )}
                                        <div style={{marginTop:0,height: "100%", width:"100%",display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} >
                                            {this.state.listAllTrace.map((trace,index) => (
                                                <div style={{
                                                    width: "90%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                    maxHeight: "65vh"
                                                }}>
                                                    <div style={{
                                                        textAlign: "center",
                                                        backgroundColor: "white",
                                                        padding: 15,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        outline: "2px dashed #4FA8DA",
                                                        marginTop: trace.type === "transfer" ? 20 : 10,
                                                        marginBottom: 20,
                                                        alignItems: "center",
                                                        borderRadius: 5,
                                                        width: "100%",
                                                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                                                    }}>
                                                        {trace.type === "transfer" && (
                                                            <>
                                                                <p style={{marginBottom: 5}}>Identification du
                                                                    possesseur : </p>
                                                                <p style={{
                                                                    marginTop: 0,
                                                                    marginBottom: 5,
                                                                    fontWeight: "bold"
                                                                }}>{trace.pseudo}</p>
                                                                <p style={{marginBottom: 5}}>Empreinte du possesseur
                                                                    : </p>
                                                                <div>
                                                                    <p style={{
                                                                        margin: 0,
                                                                        fontWeight: "bold",
                                                                        fontSize: 15
                                                                    }}>{trace.empreinte.substring(0, trace.empreinte.length / 2)}</p>
                                                                    <p style={{
                                                                        marginTop: 0,
                                                                        fontWeight: "bold",
                                                                        fontSize: 15
                                                                    }}>{trace.empreinte.substring(this.state.hashFile.length / 2, this.state.hashFile.length)}</p>
                                                                </div>
                                                                <p>Date de l'obtention : <span
                                                                    style={{fontWeight: "bold"}}>{new Date(parseFloat(trace.date)).toLocaleString("fr-FR", {timeZone:"UTC"}).replace(","," à")} UTC</span>
                                                                </p>
                                                                {!isNaN(Number(trace.value)) ? (
                                                                    <p>Prix échangé : <span
                                                                        style={{fontWeight: "bold"}}>{trace.value} STC</span>
                                                                    </p>
                                                                ) : (
                                                                    <p>Moyen d'obtention : <span
                                                                        style={{fontWeight: "bold"}}>{trace.value}</span>
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                        {trace.type === "signature" && (
                                                            <p style={{textAlign:"center"}}><span style={{fontWeight:"bold"}}>{trace.pseudo}</span> a signé le document à <span style={{fontWeight:"bold"}}>{trace.labelRecepteur}</span> le <span style={{fontWeight:"bold"}}>{new Date(parseFloat(trace.date)).toLocaleString("fr-FR", {timeZone:"UTC"}).replace(","," à")} UTC</span></p>
                                                        )}
                                                        {trace.type === "vlt" && (
                                                            <p style={{textAlign:"center"}}><span style={{fontWeight:"bold"}}>{trace.labelRecepteur}</span> a consulté la preuve envoyée par <span style={{fontWeight:"bold"}}>{trace.pseudoEmetteur}</span> le <span style={{fontWeight:"bold"}}>{new Date(parseFloat(trace.date)).toLocaleString("fr-FR" , {timeZone:"UTC"}).replace(","," à")} UTC</span></p>
                                                        )}
                                                        {(trace.type === "sekuremail" && trace.delivered) && (
                                                            <p style={{textAlign:"center"}}>L'email envoyé via <span style={{fontWeight:"bold"}}>SekurEmail</span> a été consulté par <span style={{fontWeight:"bold"}}>{JSON.parse(trace.to).email}</span> le <span style={{fontWeight:"bold"}}>{new Date(parseFloat(trace.date)).toLocaleString("fr-FR", {timeZone:"UTC"}).replace(","," à")} UTC</span></p>
                                                        )}
                                                    </div>
                                                    {trace.type === "vlt" && (
                                                        <>
                                                            {this.state.listAllTrace.length - 1 > index && (
                                                                <>
                                                                    {this.state.listAllTrace[index + 1].type === "transfer" && (
                                                                        <ForwardIcon style={{
                                                                            transform: "rotate(90deg) scale(2)",
                                                                            color: "#52a8d9"
                                                                        }}/>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(TracabiliteKageSecur)