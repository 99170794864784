import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {Button, IconButton, InputAdornment, TextField} from '@mui/material';
import {isBrowser} from "react-device-detect";
import {sha256} from "js-sha256";

import {ChevronRight, Visibility, VisibilityOff} from '@mui/icons-material';

import Axios from "axios";

import config from "../json/config.json";

import Swal from "sweetalert2";
import LogoSpuro from "./components/LogoSpuro";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const SwalCustom = Swal.mixin({
    confirmButtonColor: '#3085d6',
})

class ConnexionAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identifiantValue : "",
            passwordValue : "",
        };
    }

    componentDidMount() {
        this.setState({
            identifiantValue: sessionStorage.getItem('SPURO_pseudo') ||""
        })
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    submitConnexion(e){
        e.preventDefault()
        if(this.state.identifiantValue.trim() === "" || this.state.passwordValue.trim() === ""){
            SwalCustom.fire({
                title: 'Erreur!',
                text: "Veuillez remplir tous les champs.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return
        }
        const empreinte = sha256((this.state.identifiantValue.trim()).concat(this.state.passwordValue.trim())) //Hashage avec protocole sha256
        Axios.post(url+'connexionAdmin', {
            empreinte : empreinte,
        },{headers:{Authorization:"Bearer " + "nojwt"}}).then((response)=>{
            if (response.data.resultat === 'Connecté.'){
                Toast.fire({
                    icon: 'success',
                    title: 'Connecté au compte "'+this.state.identifiantValue+'"'
                })
                global.emitter.emit("onConnexionAdmin",{
                    entrepriseName:response.data.entrepriseName,
                    pseudo:this.state.identifiantValue
                })
                if(response.data.jwt){
                    sessionStorage.setItem('SPURO_jwt', response.data.jwt)
                }
                this.props.navigate('/Administration/AccueilAdmin')
            }else{
                SwalCustom.fire({
                    title: 'Erreur!',
                    text: "Administrateur introuvable.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }).catch(()=>{
            SwalCustom.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{height: 70, width:"100vw", backgroundColor:"#337ab7", textAlign:"center", boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)', zIndex:10}}>
                    <LogoSpuro/>
                    <h2 style={{color:"white"}}>Connexion</h2>
                </div>
                <div style={{display:"flex" ,backgroundColor:"#72aacc",minHeight:"calc(100vh - 70px)",width:"100%", justifyContent:"center",alignItems:"center"}}>
                    <div style={{overflowY:"auto",minHeight:"30vh",width:550,backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <h4>Entrez vos informations personnelles.</h4>
                        <form onSubmit={(e)=>{this.submitConnexion(e)}} style={{display:"flex",flexDirection:"column",width:"85%", marginBottom:20}} noValidate autoComplete="off">
                            <TextField required={true} style={{width:"100%", marginBottom:10}} value={this.state.identifiantValue} name={"identifiantValue"} onChange={(event)=>{this.handleChange(event)}} label="Identifiant" variant="outlined" />
                            <TextField autoComplete="new-password" required={true} type={this.state.showPassword ? "text":"password"}  name={"passwordValue"} onChange={(event)=>{this.handleChange(event)}} label="Clef Unique" variant="outlined"
                                       InputProps={{
                                           endAdornment:
                                               <InputAdornment position="end">
                                                   <IconButton
                                                       aria-label="toggle password visibility"
                                                       onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                   >
                                                       {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                   </IconButton>
                                               </InputAdornment>
                                       }}
                            />
                            <Button type={"submit"} style={{marginTop:10,color:"black",borderColor:"black"}} variant="outlined" >Se connecter <ChevronRight/></Button>
                        </form>
                    </div>
                </div>
            </div>


        );
    }
}

export default withRouter(ConnexionAdmin)