import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import {Backdrop, CircularProgress} from "@mui/material";
import TileConceptComponent from "./TileConceptComponent";

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Design","Développement","Marketing","Informatique et logiciels","Développement personnel","Business","Photographie","Musique","Divers"
]
const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class MesConcepts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{
            myConcepts:[]
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("BTOBDESIGN_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedBtoBDesign", {
                        jwt: this.state.jwt,
                    })
                    this.getMyConcepts()
                }else{
                    Swal.fire({
                        title: 'Veuillez vous reconnecter',
                        text: "Votre session a expiré, veuillez vous reconnecter.",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        sessionStorage.removeItem("BTOBDESIGN_jwt")
                        global.emitter.emit("onRouteChangedBtoBDesign", {
                            jwt: null,
                        })
                        window.location.replace("/BtoBDesign/Accueil")
                    })
                }
            })
        })
    }

    getMyConcepts(){
        Axios.post(url+"getMyConcepts",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            console.log(response.data)
            if (response.data.resultat === "done"){
                this.setState({myConcepts:response.data.myConcepts,jwtDecoded})
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: response.data.resultat,
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    diapoIllus(elt,todo){
        let diplayIllus = this.state[elt.ref+"_displayIllus"] || 1
        if(todo === "previous"){
            this.setState({
                [elt.ref+"_displayIllus"]:diplayIllus === 1 ? (elt.numberImages):(diplayIllus-1)
            })
        }else{
            this.setState({
                [elt.ref+"_displayIllus"]:diplayIllus === elt.numberImages ? (1):(diplayIllus+1)
            })
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.displayBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <div style={{width:"fit-content", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                    <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Mes concepts :</h1>
                                    <div style={{background: "linear-gradient(49deg, rgb(54, 73, 130) 0%, rgb(142, 160, 215) 100%)", width:"100%", height:3, borderRadius:100, marginBottom:20}}></div>
                                </div>
                            </div>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", marginBottom:10}}>
                                <button onClick={()=>{this.props.navigate("/BtoBDesign/Concept?td=new")}} className={"divButtonBtoBDesign"}>Créer un concept</button>
                            </div>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                {this.state.myConcepts.length > 0 ? (
                                    <>
                                        {this.state.myConcepts.map(elt=>(
                                            <TileConceptComponent navigate={this.props.navigate} concept={elt} dai={this.state.jwtDecoded ? (elt.pseudo === this.state.jwtDecoded.pseudo) ? "mine":"notmine":"notmine"}></TileConceptComponent>
                                        ))}
                                    </>
                                ):(
                                    <p>Vous ne possédez actuellement aucun concept. Allez voir <button className={"divButtonBtoBDesign"} onClick={()=>{this.props.navigate("/BtoBDesign/Catalogue")}}>le catalogue</button> !</p>
                                )}
                            </div>
                        </div>

                        {/*<div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>*/}
                        {/*    <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>*/}
                        {/*        <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Vidéos possédées :</h1>*/}
                        {/*        <div style={{background: "linear-gradient(49deg, rgba(114,170,204,1) 0%, rgba(250,182,3,1) 100%)", width:286, height:3, borderRadius:100}}></div>*/}
                        {/*    </div>*/}
                        {/*    <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>*/}
                        {/*        <div style={{marginTop:20}}>*/}
                        {/*            <h2 style={{marginBottom:0}}>Vous ne possedez encore aucune vidéo ...</h2>*/}
                        {/*            <h2 style={{marginTop:10}}>Allez faire un tour sur le <Button onClick={()=>{this.props.navigate("/BtoBDesign/Catalogue")}} variant={"outlined"} style={{textTransform:"none"}}><h2 style={{margin:0}}>catalogue</h2></Button></h2>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MesConcepts)