import React, {Component} from 'react';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Button,
    CircularProgress,
    Typography
} from '@mui/material';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import SecurityIcon from '@mui/icons-material/Security';
import ListIcon from '@mui/icons-material/List';
import AppsIcon from "@mui/icons-material/Apps";
import WebIcon from '@mui/icons-material/Web';
import ExtensionIcon from "@mui/icons-material/Extension";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import {EnergySavingsLeaf, Shop} from "@mui/icons-material";
import {isMobile} from "react-device-detect";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BusinessIcon from '@mui/icons-material/Business';
import LanguageIcon from '@mui/icons-material/Language';
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import LogoSpuro from "./LogoSpuro";
import packageData from '../../../package.json';
import {withRouter} from '../../shared/NavigationUtils';
import AdmZip from "adm-zip"


const { date_version } = packageData;

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let allAcceptedPath = [
    "/Administration/Email", "/Securite/IntegriteDesRegistres", "/Administration/ListeDesNodes", "/Administration/Parametres",
    "/Administration/IntegriteDuCodeSource", "/Administration/Registre", "/Administration/Administration/Parametres",
    "/Administration/GestionDePreuve/Administration/Parametres", "/Administration/Parametres", "/Administration/Synthese", "/Administration/AchatToken",
    "/Administration/TransfertToken","/Administration/ConnaitreMonEmpreinte", "/Administration/TransfertsEnAttente",
    "/Administration/gestionDePreuve/Administration/CreationClient", "/Administration/AccueilAdmin", "/Administration/MesTokens",
    "/Administration/sc_loan/PropositionDePret", "/Administration/sc_loan/ListeDesPrets",
    "/Administration/InvaliderMonCompte", "/Administration/OubliClefUnique", "/Administration/CreationGestionnaire",
    "/Administration/ListeGestionnaires","/KageSecur/Administration/ListeDesClients","/Administration/DemoStripe", "/Administration/DemoEnvoiMail"
]

class SharedDrawerAdmin extends Component {

    constructor(props) {
        super(props);
        this.state={
            openDrawer : true,
            entrepriseName:'',
            pseudo:'',
            openLoad:false,
            apps:{},
            checkForBackup:true,
            currentPath:""
        }
    }

    _onRouteChangedSubscription;
    _onConnexionAdminSubscription;

    resizePage = () => {
        if(document.body.offsetWidth <= 1150 && this.state.openDrawer === true){
            this.handleDisplayDrawer(false)
        }else if(document.body.offsetWidth > 1150 && this.state.openDrawer === false){
            this.handleDisplayDrawer(true)
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 1150){
            this.handleDisplayDrawer(false)
        }
        window.addEventListener('resize', this.resizePage)
        document.body.onmousedown = e => { if (e.button === 1) return false; }; //empeche de scroll avec un click molette
        this._onRouteChangedSubscription = global.emitter.addListener("onRouteChanged",(data)=>{
            this.setState({
                pageName:data.pageName,
                pseudo:data.pseudo
            })
        })
        this._onConnexionAdminSubscription = global.emitter.addListener("onConnexionAdmin",(data)=>{
            this.setState({
                entrepriseName:data.entrepriseName,
                pseudo:data.pseudo,
            })
        })
        this.getApplis()
        let currentPath = this.props.location.pathname.toLowerCase()
        this.setState({currentPath})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        let currentPath = this.props.location.pathname.toLowerCase()
        if(this.state.currentPath !== currentPath){
            this.setState({currentPath})
        }
    }
    openSecurityDialog(){
        Swal.fire({
            title: 'Avez-vous fait une copie des registres récemment ?',
            html: "<p>Par soucis de sécurité, il est <span style='font-weight: bold'>fortement</span> recommandé de sauvegarder les registres sur son ordinateur toutes les semaines environ.</p>",
            icon: 'info',
            confirmButtonText: 'Sauvegarder les registres',
            showCancelButton: true,
            cancelButtonText:"Plus tard",
            allowOutsideClick:false
        }).then((result)=>{
            if(result.isConfirmed){
                this.download()
            }
        })
    }

    download(){
        this.setState({
            openLoad:true,
            hash:sessionStorage.getItem("SPURO_hash")
        }, ()=>{
            Axios.post(url+"connexionAdmin", {
                hash : this.state.hash,
                hashJwt:this.state.hash
            },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwt")}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                }
                if (response.data.resultat === "Connecté.") {
                    Axios.post(url+"getAllRegistres",
                        {hashJwt : this.state.hash}
                        ,{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwt")}}
                    ).then((response)=> {
                        if(response.data.jwt){
                            sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                        }
                        let zipFile = new AdmZip();
                        new Promise(async resolve => {
                            resolve(await this.downloadEachReg(response.data.resultat, zipFile))
                        }).then(zip=>{
                            let willSendthis = zip.toBuffer(); //On transforme le zip en Buffer Uint8Array pour le télécharger dans le front plus tard.
                            let blob = new Blob([willSendthis], {type: "application/zip"});
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            //Manipulation de la date pour l'afficher dans un bon format
                            let todayDate = new Date(Date.now()).toLocaleString()
                            todayDate = todayDate.split(" ")[0]
                            todayDate = todayDate.split("/")
                            let firstElt = todayDate[0]
                            todayDate[0] = todayDate.pop()
                            todayDate.push(firstElt)
                            todayDate = todayDate.toString()
                            todayDate = todayDate.replaceAll(",","-")
                            //On télécharge le registre en .zip dans le front
                            link.download = "SauvegardeRegistre-"+todayDate+".zip";
                            link.click();
                            this.setState({
                                openLoad:false,
                            })
                        })
                    })
                }
            })
        })
    }
    async downloadEachReg(allReg, zipFile){
        return new Promise(async resolve => {
            Axios.post(url+"AffichageRegistre",{
                    regToRead:allReg[0],
                    hashJwt:this.state.hash
                },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwt")}}
            ).then(response=> {
                zipFile.addFile(allReg[0].toString(), (this.registreToDlBackup(response.data.resultat)).toString(),"add file to zip") //On ajoute les .txt dans le zip
                allReg.shift()
                if (allReg.length>0){
                    resolve(this.downloadEachReg(allReg, zipFile))
                }else{
                    if(response.data.jwt){
                        sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        })
                    }
                    resolve(zipFile)
                }
            })
        })
    }

    registreToDlBackup(regToConvert){
        let resultat = '';
        for (let i = 0; i<regToConvert.length;i++){
            resultat += regToConvert[i];
            resultat += '\n';
        }
        return resultat
    }

    getApplis(){
        Axios.post(url+"getModules").then((response)=>{
            this.setState({apps:response.data})
        })
    }

    componentWillUnmount(){
        if(this._onRouteChangedSubscription){this._onRouteChangedSubscription.remove()}
        if(this._onConnexionAdminSubscription){this._onConnexionAdminSubscription.remove()}
        window.removeEventListener('resize', this.resizePage)
    }

    handleDisplayDrawer(forceState){
        this.setState({
            openDrawer :forceState?forceState: !(this.state.openDrawer)
        },()=>{
            global.emitter.emit("openDrawerModif",{
                stateDrawer:this.state.openDrawer,
            })
        })
    }
    decoFonction(){
        sessionStorage.removeItem('SPURO_pseudo')
        sessionStorage.removeItem('SPURO_email')
        sessionStorage.removeItem('SPURO_hash')
        sessionStorage.removeItem('SPURO_jwt')
        this.props.navigate('/Administration/ConnexionAdmin')
    }

    goToPage(clickedPage){
        clickedPage === window.location.pathname ? window.location.reload() : this.props.navigate(clickedPage)
    }

    render() {
        if(this.state.currentPath.includes("connexion")){
            return null
        }
        return(
            <div>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Téléchargement du registre en cours, veuillez patienter...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
                <div style={{display:"flex", alignItems:"center",justifyContent:"center", position:"fixed", zIndex:12,height: 70, width:"100vw", backgroundColor:"#337ab7", textAlign:"center", boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                    {this.state.openDrawer && (
                        <LogoSpuro/>
                    )}

                    <div style={{display:"flex", flexDirection:"column"}}>
                        <h2 style={{color:"white", margin:0}}>{this.state.pageName}</h2>
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <p style={{color:"white", margin:0, display:"flex",alignItems:"center", marginRight:5}}><AccountCircleIcon style={{marginRight:5}}/>{this.state.pseudo}</p>
                            <p style={{color:"white", margin:0, display:"flex",alignItems:"center", marginLeft:5}}><BusinessIcon style={{marginRight:5}}/>{this.state.entrepriseName}</p>
                        </div>
                    </div>
                </div>
                <div id={"drawerID"} style={{width:this.state.openDrawer?250:65,minWidth:this.state.openDrawer?250:65,height:"100vh",position:"fixed",display:"flex",zIndex:"11",top:"0px" ,overflowY:"auto",overflowX:"hidden",transition : "all 300ms", flexDirection:"column", justifyContent:"start", alignItems:"end",boxShadow:'0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                    {this.state.openDrawer ?(
                        <div style={{width:'100%', display:"flex", justifyContent:"flex-end", marginTop:85}}>
                            <Button style={{width:25}} onClick={()=>{this.handleDisplayDrawer()}}><ChevronLeftIcon/></Button>
                        </div>
                    ):(
                        <Button style={{marginTop:85}} onClick={()=>{this.handleDisplayDrawer()}}><MenuIcon/></Button>
                    )}
                    <div style={{display:"flex",width:"100%"}}>{/*ADMINISTRATION*/}
                        {this.state.openDrawer ? (
                            <div style={{width:"100%"}}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%'}}>
                                            <SettingsIcon/>
                                            <Typography style={{marginLeft:15,fontWeight:"bold"}}>Administration</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/Parametres', "_blank")}}} onClick={()=>{this.goToPage('/Administration/Parametres')}} className={'buttonDrawerAdmin'} >Paramètres</p>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/Email', "_blank")}}}  className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage("/Administration/Email")}}>Email administrateur</p>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/ListeDesNodes', "_blank")}}}  className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/ListeDesNodes')}}>Liste des nodes</p>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/OubliClefUnique', "_blank")}}}  className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/OubliClefUnique')}}>Clef unique oubliée</p>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/CreationGestionnaire', "_blank")}}}  className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/CreationGestionnaire')}}>Créer un gestionnaire</p>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/ListeGestionnaires', "_blank")}}}  className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/ListeGestionnaires')}}>Liste admins et gestionnaires</p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ):(
                            <div style={{width:"100%", textAlign:"center",marginTop:'15px'}}>
                                <SettingsIcon className={"TouchableIcons"} onClick={()=>{this.handleDisplayDrawer()}}/>
                            </div>
                        )}
                    </div>
                    <div style={{display:"flex",width:"100%"}}>{/*SECURITE*/}
                        {this.state.openDrawer ? (
                            <div style={{width:"100%"}}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%'}}>
                                            <SecurityIcon/>
                                            <Typography style={{marginLeft:15,whiteSpace: "nowrap",fontWeight:"bold"}}>Sécurité</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/Registre', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/Registre')}}>Registre</p>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/IntegriteDesRegistres', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/IntegriteDesRegistres')}}>Intégrité des registres</p>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/IntegriteDuCodeSource', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/IntegriteDuCodeSource')}}>Intégrité du code source</p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ):(
                            <div style={{width:"100%", textAlign:"center",marginTop:'15px'}}>
                                <SecurityIcon className={"TouchableIcons"} onClick={()=>{this.handleDisplayDrawer()}}/>
                            </div>
                        )}
                    </div>
                    <div style={{display:"flex",width:"100%"}}>{/*SmartContracts*/}
                        {this.state.openDrawer ? (
                            <div style={{width:"100%"}}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%'}}>
                                            <ExtensionIcon/>
                                            <Typography style={{marginLeft:15,whiteSpace: "nowrap",fontWeight:"bold"}}>SmartContracts</Typography>
                                        </div>
                                    </AccordionSummary>
                                    {this.state.apps.pret && (
                                        <AccordionDetails>
                                            <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                                <Accordion style={{width:'100%'}}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                    >
                                                        <div style={{fontFamily:"Arial", fontSize:15, fontWeight:"bold", marginLeft:10}}>
                                                            Lettre de crédit
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                                            <p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/sc_loan/PropositionDePret')}} onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/sc_loan/PropositionDePret', "_blank")}}}> <AddCircleOutlineIcon style={{width:15, marginRight:15}}/> Proposer un contrat</p>
                                                            <p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/sc_loan/ListeDesPrets')}} onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/sc_loan/ListeDesPrets', "_blank")}}}> <ListIcon style={{width:15, marginRight:15}}/> Liste des contrats</p>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </AccordionDetails>
                                    )}
                                </Accordion>
                            </div>
                        ):(
                            <div style={{width:"100%", textAlign:"center", marginTop:'15px'}}>
                                <ExtensionIcon className={"TouchableIcons"} onClick={()=>{this.handleDisplayDrawer()}}/>
                            </div>
                        )}
                    </div>
                    <div style={{display:"flex",width:"100%"}}>{/*FONCTIONNALITES*/}
                        {this.state.openDrawer ? (
                            <div style={{width:"100%"}}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%'}}>
                                            <WebIcon/>
                                            <Typography style={{marginLeft:15,whiteSpace: "nowrap",fontWeight:"bold"}}>Fonctionnalités</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/InvaliderMonCompte', "_blank")}}}  className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/InvaliderMonCompte')}}>Invalider mon compte</p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ):(
                            <div style={{width:"100%", textAlign:"center",marginTop:'15px'}}>
                                <WebIcon className={"TouchableIcons"} onClick={()=>{this.handleDisplayDrawer()}}/>
                            </div>
                        )}
                    </div>
                    {this.state.apps.gestionToken && (
                        <div style={{display:"flex",width:"100%"}}>{/*Tokens*/}
                            {this.state.openDrawer ? (
                                <div style={{width:"100%"}}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <div style={{ display:"flex", justifyContent:"start", width:'100%'}}>
                                                <MonetizationOnIcon/>
                                                <Typography style={{marginLeft:15,whiteSpace: "nowrap",fontWeight:"bold"}}>Tokens</Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                                <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/ConnaitreMonEmpreinte', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/ConnaitreMonEmpreinte')}}> <EmojiObjectsIcon style={{width:15, marginRight:15}}/> Mon empreinte</p>
                                                <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/MesTokens', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/MesTokens')}}> <ListIcon style={{width:15, marginRight:15}}/> Mes Tokens</p>
                                                <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/AchatToken', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/AchatToken')}}> <MonetizationOnIcon style={{width:15, marginRight:15}}/> Acheter des STCs</p>
                                                <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/TransfertToken', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/TransfertToken')}}> <ShowChartIcon style={{width:15, marginRight:15}}/> Transférer des tokens</p>
                                                <p onAuxClick={(e)=>{if(e.button===1){window.open('/Administration/TransfertsEnAttente', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Administration/TransfertsEnAttente')}}> <SyncAltIcon style={{width:15, marginRight:15}}/> Transferts en attente</p>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ):(
                                <div style={{width:"100%", textAlign:"center",marginTop:'15px'}}>
                                    <MonetizationOnIcon className={"TouchableIcons"} onClick={()=>{this.handleDisplayDrawer()}}/>
                                </div>
                            )}
                        </div>
                    )}
                    <div style={{display:"flex",width:"100%"}}>{/*APPLICATIONS*/}
                        {this.state.openDrawer ? (
                            <div style={{width:"100%"}}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%'}}>
                                            <AppsIcon/>
                                            <Typography style={{marginLeft:15,fontWeight:"bold"}}>Applications</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                            {this.state.apps.kageSecure && (
                                                <Accordion style={{width:'100%'}}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <div style={{fontFamily:"Arial", fontSize:15, fontWeight:"bold", marginLeft:10}}>
                                                            KageDoc
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/KageSecur/Accueil', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/KageSecur/Accueil')}}> <LanguageIcon style={{width:15, marginRight:15}}/> Site internet</p>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                            {this.state.apps.ekoco && (
                                                <Accordion style={{width:'100%'}}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                        <div style={{fontFamily:"Arial", fontSize:15, fontWeight:"bold", marginLeft:10}}>
                                                            Ekoco
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Ekoco/BasCarboneBlockchain', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Ekoco/BasCarboneBlockchain')}}> <EnergySavingsLeaf style={{width:15, marginRight:15}}/> BasCarboneBlockchain</p>
                                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Ekoco/Synthese', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Ekoco/Synthese')}}> <ShowChartIcon style={{width:15, marginRight:15}}/> Synthèse</p>
                                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Ekoco/Parametres', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Ekoco/Parametres')}}> <SettingsIcon style={{width:15, marginRight:15}}/> Paramètres</p>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                            {this.state.apps.suffrage && (
                                                <Accordion style={{width:'100%'}}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                    >
                                                        <div style={{fontFamily:"Arial", fontSize:15, fontWeight:"bold", marginLeft:10}}>
                                                            Suffrage
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/Suffrage/Accueil', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/Suffrage/Accueil')}}> <AccountCircleRoundedIcon style={{width:15, marginRight:15}}/> Site Suffrage</p>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                            {this.state.apps.buyResale && (
                                                <Accordion style={{width:'100%'}}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                    >
                                                        <div style={{fontFamily:"Arial", fontSize:15, fontWeight:"bold", marginLeft:10}}>
                                                            BuyResale
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                                            <p onAuxClick={(e)=>{if(e.button===1){window.open('/BuyResale/Accueil', "_blank")}}} className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/BuyResale/Accueil')}}> <Shop style={{width:15, marginRight:15}}/> Site BuyResale</p>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ):(
                            <div style={{width:"100%", textAlign:"center",marginTop:'15px'}}>
                                <AppsIcon className={"TouchableIcons"} onClick={()=>{this.handleDisplayDrawer()}}/>
                            </div>
                        )}
                    </div>
                    <hr style={{width:'100%', opacity:0.4, paddingRight:15}}/>
                    <div style={{display:"flex",width:"100%",height:"100%", justifyContent:"flex-end", marginBottom:5, flexDirection:"column"}}>
                        <div style={{display:"flex",width:"100%",justifyContent:"center"}}>{/*DECONNEXION*/}
                            {this.state.openDrawer ? (
                                <div style={{display:"flex",flexDirection:"column", alignItems:"center"}}>
                                    <Button style={{width:'100%', textTransform:'none', backgroundColor:"white"}}>
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%',paddingLeft:"8px"}} onClick={()=>{this.decoFonction()}}>
                                            <ExitToAppIcon style={{color:'#f44336'}}/>
                                            <Typography style={{color:'#f44336',marginLeft:"15px"}}>Déconnexion</Typography>
                                        </div>
                                    </Button>
                                    <p style={{margin:0, fontSize:10, color:"#b0a9a8"}}>V.{date_version}</p>
                                </div>
                            ):(
                                <div style={{width:"100%", textAlign:"center", marginTop:'15px'}}>
                                    <ExitToAppIcon className={"TouchableIcons"} style={{color:'#f44336'}} onClick={()=>{this.handleDisplayDrawer()}}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SharedDrawerAdmin)