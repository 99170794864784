import React, {Component} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import config from "../../../json/config.json";
import {isBrowser} from "react-device-detect";
import jwt_decode from "jwt-decode";
import SharedDrawerEkocoOR from "./components/SharedDrawerEkocoOR";
import SharedFooterEkocoOR from "./components/SharedFooterEkocoOR";
import Swal from "sweetalert2";

const {baseUrl, suffix, HelloAsso_client_id, HelloAsso_client_secret} = config;
const url = baseUrl + suffix
let email, iban, bic, token, pseudo, hash;

let jwtDecoded

class DonationOR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            openLoad:true,
            selectedDonation:0,
            valueDonation:"",
            checkoutIntentId:""
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950 || !isBrowser){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)

        let jwtURL = new URLSearchParams(window.location.search).get("jwt")
        if(jwtURL){
            sessionStorage.setItem("EKOCO_jwt", jwtURL)
        }
        let jwt = jwtURL ? jwtURL:sessionStorage.getItem("EKOCO_jwt")
        console.log(jwt)
        if(jwt){
            this.setState({
                jwt: jwt
            }, () => {
                jwtDecoded = jwt_decode(this.state.jwt)
                Axios.post(url + "verifyJwt", {}, {headers: {Authorization: "Bearer " + jwt}}).then(response => {
                    this.setState({openLoad:false})
                    if (response.data.jwt) {
                        sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                        this.setState({
                            jwt: response.data.jwt
                        }, () => {
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === true) {
                        this.setState({
                            pseudo: jwtDecoded.pseudo
                        })

                    } else {
                        Swal.fire({
                            title: 'Erreur',
                            text: "Connexion impossible, veuillez relancer ce site depuis l'application Ekoco ou connectez-vous directement sur le site.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            sessionStorage.removeItem("EKOCO_jwt")
                            this.props.navigate("/Ekoco/OctobreRose/Connexion")
                        })

                    }
                })
            })
        }else{
            this.setState({openLoad:false})
            Swal.fire({
                title: 'Erreur',
                text: "Connexion impossible, veuillez relancer ce site depuis l'application Ekoco ou connectez-vous directement sur le site.",
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#ec5ba1'
            }).then(()=>{
                sessionStorage.removeItem("EKOCO_jwt")
                this.props.navigate("/Ekoco/OctobreRose/Connexion")
            })
        }
    }



    selectDonations(indexDonation){
        this.setState({selectedDonation:indexDonation})
    }

    changeInputDonation(event) {
        /*force only numbers OR "," and "."*/
        let text = event.target.value.replace(/[^0-9.,]/g, '');
        // let text = event.target.value.replace(/[^0-9]/g, ''); //only nums
        // let text = event.target.value //everything
        this.setState({
            valueDonation:text
        })
    }

    displayContact(){
        this.setState({
            displayed:true,
        })
    }

    handleCloseDialog = () => {
        this.setState({
            displayed : false,
            displayed2 : false
        });
    };
    handleOpenDialog = () => {
        this.setState({
            displayed2 : true
        });
    };

    handleChange=(event) => { //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }

    handleValidation=()=>{ //Fonction servant a la vérification des données avant l'envoi du formulaire
        return true
    }

    donateOR(){
        this.setState({openLoad:true})
        let price = this.state.valueDonation ? this.state.valueDonation:this.state.selectedDonation === 0 ? 5:this.state.selectedDonation === 1 ? 20:50
        console.log(price)
        Axios.post(url + "donationOR", {price}, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
            this.setState({openLoad:false})
            if (response.data.jwt) {
                sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                this.setState({
                    jwt: response.data.jwt
                }, () => {
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done") {
                window.location.href = response.data.url
            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: "Une erreur est survenue, veuillez réessayer plus tard.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }).catch((err)=>{
            this.setState({openLoad:false})
        })
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>
                <SharedDrawerEkocoOR/>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end", zIndex:2, backgroundColor:"#FFF"}}>
                    <p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold"}}>{this.state.pseudo}</p>
                    <AccountCircleIcon style={{color:"#b0a9a8", marginRight:50}}/>
                </div>
                {/*DEBUT RENDER STATIQUE*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)", height:"80vh"}}>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute",left:50, top:"25vh"}}>
                            <img style={{width:350}} src={require("../assetsEkoco/telephoneFemme_rose.png")} alt={"Reporting"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", zIndex:1,position:"absolute", left:0, top:0}}>
                            <img style={{width:"80", objectFit:"contain"}} src={require("../assetsEkoco/topleft_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                    <div style={{marginTop:50,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",marginBottom:20,width:"100%", overflow:"hidden"}}>
                        <div style={{backgroundColor:this.state.showBrowserView ? "#FFF":"transparent", width:this.state.showBrowserView ? 500:"95%", padding:15, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:30, paddingTop:30, borderRadius:10, zIndex:2}}>
                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                <p className={"robotoFont"} style={{color:"#ec5ba1",fontWeight:1000, fontSize:30, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Donation</p>
                                <p className={"robotoFont"} style={{color:"#ec5ba1",fontWeight:400, fontSize:20, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Ligue contre le cancer - comité de l'Aube</p>
                                <div className={"flexCenter"} style={{width:"100%", flexDirection:"row", justifyContent:"space-between"}}>
                                    <div onClick={()=>{this.selectDonations(0)}} style={{cursor:"pointer",display:"flex", justifyContent:"center", margin:3, alignItems:"center",zIndex:999, backgroundColor:"#FFF", border:"5px solid #ec5ba1",maxWidth:120, width:"100%",borderWidth: this.state.selectedDonation === 0 ? 5:3, borderRadius: 20, borderColor:this.state.selectedDonation === 0 ? "#ec5ba1":"#3A374C", padding:20}}>
                                        <p style={{fontFamily:"Roboto", color:this.state.selectedDonation === 0 ? "#ec5ba1":"#3A374C", fontWeight:"bold", fontSize:36, margin:0}}>5€</p>
                                    </div>
                                    <div onClick={()=>{this.selectDonations(1)}} style={{cursor:"pointer",display:"flex", justifyContent:"center", margin:3, alignItems:"center",zIndex:999, backgroundColor:"#FFF", border:"5px solid #ec5ba1",maxWidth:120, width:"100%",borderWidth: this.state.selectedDonation === 1 ? 5:3, borderRadius: 20, borderColor:this.state.selectedDonation === 1 ? "#ec5ba1":"#3A374C", padding:20}}>
                                        <p style={{fontFamily:"Roboto", color:this.state.selectedDonation === 1 ? "#ec5ba1":"#3A374C", fontWeight:"bold", fontSize:36, margin:0}}>20€</p>
                                    </div>
                                    <div onClick={()=>{this.selectDonations(2)}} style={{cursor:"pointer",display:"flex", justifyContent:"center", margin:3, alignItems:"center",zIndex:999, backgroundColor:"#FFF", border:"5px solid #ec5ba1",maxWidth:120, width:"100%",borderWidth: this.state.selectedDonation === 2 ? 5:3, borderRadius: 20, borderColor:this.state.selectedDonation === 2 ? "#ec5ba1":"#3A374C", padding:20}}>
                                        <p style={{fontFamily:"Roboto", color:this.state.selectedDonation === 2 ? "#ec5ba1":"#3A374C", fontWeight:"bold", fontSize:36, margin:0}}>50€</p>
                                    </div>
                                </div>
                                <div style={{width:"100%",display:"flex", justifyContent:"space-around", alignItems:"center", marginTop:20, marginBottom:20, flexDirection:"row"}}>
                                    <div style={{width:"40%",display:"flex", justifyContent:"center", alignItems:"center", border:"1px solid #d9d9d9"}}/>
                                    <p style={{fontFamily:"Roboto", color:"#d9d9d9", fontWeight:"bold", fontSize:24, margin:0, marginBottom:8}}>ou</p>
                                    <div style={{width:"40%",display:"flex", justifyContent:"center", alignItems:"center", border:"1px solid #d9d9d9"}}/>
                                </div>
                                <p className={"robotoFont"} style={{color:"#3A374C",fontWeight:400, fontSize:20, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Montant personnalisé (5€ minimum)</p>
                                <input autoComplete={"off"} name={"valueDonation"} required className={"champsTexteSK champsTexteEkocoRose robotoFont"} value={this.state.valueDonation} onChange={(e)=>{this.changeInputDonation(e)}} style={{border:"1px solid #EC5BA1FF", color:"#EC5BA1FF"}} type={"text"} placeholder={"Montant de la donation"}/>
                                <button onClick={()=>{this.donateOR()}} style={{width:"fit-content", marginTop:15, backgroundImage:"none", backgroundColor:"#EC5BA1FF"}} className={"buttonSK robotoFont"}><h2 style={{margin:0, fontWeight:600, color:"#FFF"}}>Je donne</h2></button>
                            </div>
                        </div>
                    </div>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex",justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute", right:50, top:"25vh"}}>
                            <img style={{width:350, objectFit:"contain"}} src={require("../assetsEkoco/homme_rose.png")} alt={"Statistiques"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end" , zIndex:1,position:"absolute", right:0, bottom:0}}>
                            <img style={{width:"80%", objectFit:"contain"}} src={require("../assetsEkoco/bottomright_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                </div>
                {/*FIN RENDER STATIQUE*/}
                {/*FOOTER*/}
                <SharedFooterEkocoOR/>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Chargement en cours ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default  withRouter(DonationOR);