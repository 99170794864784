import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {isBrowser} from "react-device-detect";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import tooltipContenu from "../../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Dropzone from "react-dropzone";
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Tooltip
} from "@mui/material";
import {Check} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const steps = [
    'Votre identité',
    'Vos moyens de contact',
    'Votre clef unique',
];

class InscriptionClientSuffrage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identifiantValue : "",
            passwordValue : "",
            emailValue:"",
            verifValue:"",
            passwordBisValue:"",
            refCode:null,
            refCodeAuth:false,
            phase:0,
            numberCNIOCR:null,
            loadingCNI:false
        };
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.get("ref")){
            this.setState({refCode:queryParams.get("ref")})
        }
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    submitCreation(e){
        e.preventDefault()
        Swal.fire({
            icon:"info",
            text:"Il est primordial de ne pas perdre votre clef unique.\nIl ne s'agit pas d'un mot de passe, mais d'une clef de preuve.\nNous ne pourrons pas vous aider à récupérer l'accès à votre compte dans le cas où vous l'auriez perdu.",
            input: 'checkbox',
            inputValue: 0,
            inputPlaceholder:
                "J'ai bien compris l'importance de ma clef unique.",
            confirmButtonText:
                "Continuer",
            inputValidator: (result) => {
                return !result && 'Vous devez accepter les conditions !'
            }
        }).then(()=> {
            if(this.state.binaryCNI && this.state.binaryJDD){
                if(this.state.passwordValue.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!.@#$%^&*?-])[A-Za-z\d!.@#$%^&*?-]{12,}$/)) {
                    if(this.state.passwordBisValue.trim() === this.state.passwordValue.trim()){
                        Axios.post(url+"inscriptionSuffrage", {
                            prenom:this.state.valuePrenom.trim(),
                            nom:this.state.valueNom.trim(),
                            email:this.state.valueEmail.trim(),
                            tel:this.state.valueTelephone,
                            DDN:this.state.valueDDN,
                            CNI:this.state.numberCNIOCR,
                            adresse:this.state.valueAdresse,
                            cdp:this.state.passwordValue.trim(),
                            bufferCNI:this.state.binaryCNI,
                            filenameCNI:this.state.filenameCNI,
                            filenameJDD:this.state.filenameJDD,
                            bufferJDD:this.state.binaryJDD,
                            filenameVI:this.state.filenameVI,
                            bufferVI:this.state.binaryVI,
                        }).then(response => {
                            if (response.data.resultat === 'done'){
                                sessionStorage.removeItem("SUFFRAGE_hash")
                                sessionStorage.removeItem("SUFFRAGE_pseudo")
                                sessionStorage.removeItem("SUFFRAGE_jwt")
                                this.setState({phase:4})
                            }else{
                                Swal.fire({
                                    title: 'Erreur!',
                                    text: response.data.resultat,
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                })
                            }
                        }).catch(error => {
                            console.log(error)
                        });
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Vos deux clés uniques ne correspondent pas.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Votre clef unique ne respecte pas les critères de sécurité.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Veuillez saisir toutes les données nécessaire.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex",minHeight:"100vh",width:"100%", justifyContent:"center",alignItems:"center"}}>
                    <img style={{height:80,position:"absolute",top:20,left:20}} src={require("../../assets/logoSuffrage.png")} alt="logoSuffrage"/>
                    <div style={{overflowY:"auto",minHeight:"30vh",width:630,backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                        {(this.state.phase !== 0 && this.state.phase !== 4) && (
                            <Box className={"stepperSuffrageBox"} style={{width:'100%', marginBottom:15,marginTop:15}}>
                                <Stepper activeStep={this.state.phase-1} alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel className={"stepperSuffrage"} >
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                        )}
                        {this.state.phase === 0 && (
                            <>
                                <h3>Suffrage - l'application de vote en ligne</h3>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"flex-start",flexDirection:"column",padding:"0 15px 0 15px"}}>
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <div className={"numberInscSuffrageOutside"}>
                                            <div className={"numberInscSuffrage"}>
                                                <p style={{color:"#FFFFFF"}}>1</p>
                                            </div>
                                        </div>
                                        <p style={{marginLeft:10}}>Fournissez les informations nécessaires pour transmettre une demande de création de compte</p>
                                    </div>
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <div className={"numberInscSuffrageOutside"}>
                                            <div className={"numberInscSuffrage"}>
                                                <p style={{color:"#FFFFFF"}}>2</p>
                                            </div>
                                        </div>
                                        <p style={{marginLeft:10}}>Un membre de la blockchain Suffrage vous valide en moins de 48h</p>
                                    </div>
                                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        <div className={"numberInscSuffrageOutside"}>
                                            <div className={"numberInscSuffrage"}>
                                                <p style={{color:"#FFFFFF"}}>3</p>
                                            </div>
                                        </div>
                                        <p style={{marginLeft:10}}>Répondez à des votes en ligne depuis chez vous de manière <span style={{fontWeight:"bold", color:"#6600ff"}}>100%</span> sécurisé</p>
                                    </div>
                                </div>
                                <Button onClick={()=>{this.setState({phase:1})}}  style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"50%", fontWeight:"bold", margin:5, marginTop:10,marginBottom:15}}  variant="outlined" >Continuer<ChevronRightIcon/></Button>
                            </>
                        )}
                        {this.state.phase===1 && (
                            <>
                                <form onSubmit={(e)=>{
                                    e.preventDefault()
                                    if(this.state.filenameCNI && this.state.filenameJDD && this.state.filenameVI && this.state.binaryCNI && this.state.binaryJDD && this.state.binaryVI){
                                        this.setState({phase:2})
                                    }else{
                                        Toast.fire({
                                            title:"Veuillez déposer les documents demandés.",
                                            icon:"error"
                                        })
                                    }
                                }
                                } style={{display:"flex",flexDirection:"column",width:"85%", marginBottom:20}}>
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                            <TextField style={{width:"100%", marginBottom:10}} required={true} defaultValue={this.state.valuePrenom} name={"valuePrenom"} onChange={(event)=>{this.handleChange(event)}} label="Prénom" variant="outlined" />
                                            <Tooltip arrow={true} title={"Entrez votre prénom ici"}>
                                                <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                            </Tooltip>
                                            <TextField style={{width:"100%", marginBottom:10,marginLeft:5}} type={"date"} required={true} defaultValue={this.state.valueDDN} name={"valueDDN"} onChange={(event)=>{this.handleChange(event)}} label={"Date de naissance"} variant="outlined"
                                                       InputLabelProps={{ shrink: true }}/>
                                            <Tooltip arrow={true} title={"Entrez votre date de naissance ici"}>
                                                <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                            </Tooltip>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                            <TextField style={{width:"100%", marginBottom:10}} required={true} type={"text"} defaultValue={this.state.valueNom} name={"valueNom"} onChange={(event)=>{this.handleChange(event)}} label="Nom de famille" variant="outlined" />
                                            <Tooltip arrow={true} title={"Entrez votre nom de famille ici"}>
                                                <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                            </Tooltip>
                                        </div>
                                        <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                            <Dropzone multiple={false} maxFiles={1} onDrop={(acceptedFiles) => {
                                                if (acceptedFiles[0].size < 10000000){ //10MB
                                                    const reader = new FileReader()
                                                    reader.onload = () => {
                                                        const binaryStr = reader.result
                                                        this.setState({
                                                            binaryVI:Buffer.from(binaryStr,"base64"),
                                                            filenameVI:acceptedFiles[0].name
                                                        })
                                                    }
                                                    reader.readAsArrayBuffer(acceptedFiles[0])
                                                }else{
                                                    Toast.fire({
                                                        title:"Le fichier déposé est trop lourd.",
                                                        icon:"error"
                                                    })
                                                }
                                            }}>
                                                {({getRootProps, getInputProps}) => (
                                                    <Tooltip title={"Vous devez fournir une photo sur laquelle vous apparaissez en tenant un document justifiant votre identité a coté de votre visage pour que nos équipes puissent valider votre inscription."} placement="left">
                                                        <section className={"dropzoneClefOubliee"} style={{width:"32%", margin:15,marginRight:"3%",marginLeft:0,backgroundColor:this.state.binaryVI&&"rgb(90,255,0)"}}>
                                                            <div {...getRootProps()} style={{height:100,display:"flex",alignItems:"center",padding:"20px 5px 20px 5px",borderRadius:5, border:"solid black 1px", cursor:"pointer", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                                                <input {...getInputProps()} />
                                                                {this.state.binaryVI ? (
                                                                    <p style={{textAlign:"center"}}>Photo de votre CNI et visage correctement déposée.</p>
                                                                ):(
                                                                    <p style={{textAlign:"center"}}>Déposez une photo de votre carte nationale d'identité et de votre visage</p>
                                                                )}
                                                            </div>
                                                        </section>
                                                    </Tooltip>
                                                )}
                                            </Dropzone>
                                            <Dropzone multiple={false} maxFiles={1} onDrop={(acceptedFiles) => {
                                                if(acceptedFiles[0].name.split(".")[acceptedFiles[0].name.split(".").length-1].toLocaleLowerCase() ==="jpg" ||acceptedFiles[0].name.split(".")[acceptedFiles[0].name.split(".").length-1].toLocaleLowerCase() ==="png"){
                                                    if (acceptedFiles[0].size < 10000000){ //10MB
                                                        if(this.state.loadingCNI === false){
                                                            this.setState({loadingCNI:true})
                                                            const reader = new FileReader()
                                                            reader.onload = () => {
                                                                const binaryStr = reader.result
                                                                this.setState({
                                                                    filenameCNI:acceptedFiles[0].name
                                                                },()=>{
                                                                    Axios.post(url+"readCNI", {
                                                                        bufferCNI:Buffer.from(binaryStr,"base64")
                                                                    }).then(response => {
                                                                        this.setState({
                                                                            numberCNIOCR : response.data.resultat === "found" ? response.data.numberCNI:"inconnu",
                                                                            loadingCNI:false,
                                                                            binaryCNI:Buffer.from(binaryStr,"base64"),
                                                                        })
                                                                    }).catch(error => {
                                                                        console.log(error)
                                                                    });
                                                                })
                                                            }
                                                            reader.readAsArrayBuffer(acceptedFiles[0])
                                                        }else{
                                                            Toast.fire({
                                                                title:"Veuillez patienter pendant que nous traitons votre image.",
                                                                icon:"warning"
                                                            })
                                                        }
                                                    }else{
                                                        Toast.fire({
                                                            title:"Le fichier déposé est trop lourd : taille maximum 10MB.",
                                                            icon:"error"
                                                        })
                                                    }
                                                }else{
                                                    Toast.fire({
                                                        title:"Le fichier est au mauvais format : .png ou .jpg requis.",
                                                        icon:"error"
                                                    })
                                                }
                                            }}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className={"dropzoneClefOubliee"} style={{width:"32%",backgroundColor:this.state.binaryCNI&&"rgb(90,255,0)"}}>
                                                        <div {...getRootProps()} style={{height:100,display:"flex",alignItems:"center",justifyContent:"center",padding:"20px 5px 20px 5px",borderRadius:5, border:"solid black 1px", cursor:"pointer", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                                            <input {...getInputProps()} />
                                                            {this.state.binaryCNI ? (
                                                                <p style={{textAlign:"center"}}>Photo de votre CNI correctement déposée.</p>
                                                            ):(
                                                                <>
                                                                    {this.state.loadingCNI ?
                                                                        (
                                                                            <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                                                                                <CircularProgress/>
                                                                                <p style={{textAlign:"center"}}>Temps d'attente maximale : 15 secondes</p>
                                                                            </div>
                                                                        ):(
                                                                            <p style={{textAlign:"center"}}>Déposez une photo de votre carte nationale d'identité</p>
                                                                        )
                                                                    }
                                                                </>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            <Dropzone multiple={false} maxFiles={1} onDrop={(acceptedFiles) => {
                                                if (acceptedFiles[0].size < 10000000){ //10MB
                                                    const reader = new FileReader()
                                                    reader.onload = () => {
                                                        const binaryStr = reader.result
                                                        this.setState({
                                                            binaryJDD:Buffer.from(binaryStr,"base64"),
                                                            filenameJDD:acceptedFiles[0].name
                                                        })
                                                    }
                                                    reader.readAsArrayBuffer(acceptedFiles[0])
                                                }else{
                                                    Toast.fire({
                                                        title:"Le fichier déposé est trop lourd.",
                                                        icon:"error"
                                                    })
                                                }
                                            }}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section className={"dropzoneClefOubliee"} style={{width:"32%", margin:15,marginLeft:"3%",marginRight:0,backgroundColor:this.state.binaryJDD&&"rgb(90,255,0)"}}>
                                                        <div {...getRootProps()} style={{height:100,display:"flex",alignItems:"center",padding:"20px 5px 20px 5px",borderRadius:5, border:"solid black 1px", cursor:"pointer", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                                            <input {...getInputProps()} />
                                                            {this.state.binaryJDD ? (
                                                                <p style={{textAlign:"center"}}>Justificatif de domicile correctement déposé.</p>
                                                            ):(
                                                                <p style={{textAlign:"center"}}>Déposez un justificatif de domicile (quittance de loyer, facture de gaz ...)</p>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    {this.state.binaryCNI && (
                                        <>
                                            <p>Nous avons détécté que votre numéro de carte d'identité était le suivant, veuillez le changer s'il est erronné :</p>
                                            <TextField style={{width:"100%", marginBottom:10}} required={true} type={"text"} defaultValue={this.state.numberCNIOCR} name={"numberCNIOCR"} onChange={(event)=>{this.handleChange(event)}} label="Numéro de carte d'identité" variant="outlined" />
                                        </>
                                    )}
                                    <Button type={"submit"}  style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}}  variant="outlined" >Suivant<ChevronRightIcon/></Button>
                                </form>
                            </>


                        )}
                        {this.state.phase===2 && (
                            <>
                                <form onSubmit={(e)=>{e.preventDefault();this.setState({phase:3})}} style={{display:"flex",flexDirection:"column",width:"85%", marginBottom:20}}>
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                        <TextField style={{width:"100%", marginBottom:10}} required={true} type={"email"} defaultValue={this.state.valueEmail} name={"valueEmail"} onChange={(event)=>{this.handleChange(event)}} label="Email" variant="outlined" />
                                        <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>
                                            <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                        </Tooltip>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                        <TextField style={{width:"100%", marginBottom:10}} required={true} type={"text"} defaultValue={this.state.valueAdresse} name={"valueAdresse"} onChange={(event)=>{this.handleChange(event)}} label="Adresse" variant="outlined" />
                                        <Tooltip arrow={true} title={"L'adresse de votre domicile"}>
                                            <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                        </Tooltip>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                        <TextField style={{width:"100%", marginBottom:10}} required={true} type={"text"} defaultValue={this.state.valueTelephone} name={"valueTelephone"} onChange={(event)=>{this.handleChange(event)}} label="Numéro de téléphone" variant="outlined" />
                                        <Tooltip arrow={true} title={"Votre telephone nous permettra de vous contacter"}>
                                            <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                        </Tooltip>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                        <Button onClick={()=>{this.setState({phase:1})}} style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}}  variant="outlined" ><ChevronLeftIcon/>Précédent</Button>
                                        <Button type={"submit"}  style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}}  variant="outlined" >Suivant<ChevronRightIcon/></Button>
                                    </div>
                                </form>
                            </>
                        )}
                        {this.state.phase===3 && (
                            <>
                                <form autoComplete="off" onSubmit={(e)=>{this.submitCreation(e)}} style={{display:"flex",flexDirection:"column",width:"85%", marginBottom:20}}>
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                        <TextField autoComplete="new-password" style={{width:"100%", marginBottom:10}} required={true} type={this.state.showPassword ? "text":"password"} name={"passwordValue"} onChange={(event)=>{this.handleChange(event)}} label="Clef Unique" variant="outlined"
                                                   InputProps={{
                                                       endAdornment:
                                                           <InputAdornment position="end">
                                                               <IconButton
                                                                   aria-label="toggle password visibility"
                                                                   onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                               >
                                                                   {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                               </IconButton>
                                                           </InputAdornment>
                                                   }}
                                        />
                                        <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                            <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                        </Tooltip>
                                    </div>
                                    <p style={{margin:0, padding:0,fontSize:13}}>Règles de sécurité - Votre clef unique doit contenir : </p>
                                    <p style={{margin:0, padding:0, fontSize:11}}>- au moins une <span style={{fontWeight:"bold"}}> majuscule et minuscule</span></p>
                                    <p style={{margin:0, padding:0, fontSize:11}}>- au moins un <span style={{fontWeight:"bold"}}> chiffre </span></p>
                                    <p style={{margin:0, padding:0, fontSize:11}}>- au moins un <span style={{fontWeight:"bold"}}> symbole (!.@#$%^&*?-)</span></p>
                                    <p style={{margin:0, padding:0, fontSize:11}}>- aucun <span style={{fontWeight:"bold"}}> espace </span></p>
                                    <p style={{margin:0, padding:0, fontSize:11, marginBottom:8}}>- au moins 12 <span style={{fontWeight:"bold"}}> caractères </span></p>
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                        <TextField autoComplete="new-password" style={{width:"100%", marginBottom:10}} required={true} type={this.state.showPasswordVerif ? "text":"password"} name={"passwordBisValue"} onChange={(event)=>{this.handleChange(event)}} label="Confirmez votre Clef Unique" variant="outlined"
                                                   InputProps={{
                                                       endAdornment:
                                                           <InputAdornment position="end">
                                                               <IconButton
                                                                   aria-label="toggle password visibility"
                                                                   onClick={()=>{this.setState({showPasswordVerif:!this.state.showPasswordVerif})}}
                                                               >
                                                                   {this.state.showPasswordVerif ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                               </IconButton>
                                                           </InputAdornment>
                                                   }}
                                        />
                                        <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                            <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                        </Tooltip>
                                    </div>
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                        <Button onClick={()=>{this.setState({phase:2})}} style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}}  variant="outlined" ><ChevronLeftIcon/>Précédent</Button>
                                        <Button type={"submit"}  style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}}  variant="outlined" >Créer mon compte</Button>
                                    </div>
                                </form>
                            </>
                        )}
                        {this.state.phase === 4 && (
                            <>
                                <div style={{width:0,height:0}} className={"checkIconSuffrage"} >
                                    <Check style={{transform:"scale(1.3)"}}/>
                                </div>
                                <h3>Votre demande a bien été envoyée.</h3>
                                <p style={{textAlign:"center"}}>Vous allez bientôt reçevoir un mail contenant votre identifiant qui vous permettra de vous connecter.</p>
                                <Button onClick={()=>{this.props.navigate("/Suffrage/Accueil")}} style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"50%", fontWeight:"bold", margin:5, marginTop:10,marginBottom:15}} variant="outlined" >Retour à l'accueil</Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(InscriptionClientSuffrage)