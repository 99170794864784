import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import {isMobile} from "react-device-detect";
import jwt_decode from "jwt-decode";
import {Button} from "@mui/material";
import {KeyboardArrowDown, NavigateBefore, NavigateNext} from "@mui/icons-material";
import illusSVG1 from "../../assets/Questions-pana.svg"
import illusSVG2 from "../../assets/Prototyping process-pana.svg"

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded;
class AccueilBtoB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mousePosition:[0,0],
            showBrowserView:true,
            pageWidth:document.body.offsetWidth,
            scrollPosition:0,
            alreadyClicked:false
        };
    }

    resizePage = () => {
        this.setState({pageWidth:document.body.offsetWidth})
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    trackMouse = (e) => {
        if(window.scrollY < 800){
            let mouseX
            if(e.screenX > 1920){
                mouseX = e.screenX-1920
            }else if (e.screenX < 0 ){
                mouseX = -e.screenX
            }else{
                mouseX = e.screenX
            }
            let mouseY = e.screenY
            this.setState({mousePosition:[mouseX,mouseY]})
        }
    }

    goToPage(clickedPage){
        window.history.replaceState(null, null, window.location.pathname);
        window.location.pathname !== clickedPage ? this.props.navigate(clickedPage):window.location.reload()
        this.setState({displayMenuMobile:false})
    }

    componentWillUnmount(){
        this._willUnmount = true;
        if(this._interval){
            clearInterval(this._interval)
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        window.addEventListener("mousemove", this.trackMouse);
        window.addEventListener("scroll", this.trackScroll);

        this.setState({
            jwt:sessionStorage.getItem("BTOBDESIGN_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            if(jwtDecoded!=={}){
                Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === true){
                        jwtDecoded = jwt_decode(this.state.jwt)
                        global.emitter.emit("onRouteChangedBtoBDesign", {
                            jwt: this.state.jwt,
                        })
                    }else{
                        sessionStorage.removeItem("BTOBDESIGN_jwt")
                        global.emitter.emit("onRouteChangedBtoBDesign", {
                            jwt: null,
                        })
                    }
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    diapoIllus(todo){
        let diplayIllus = this.state.displayIllus || 1
        if(todo === "previous"){
            this.setState({
                displayIllus:diplayIllus === 1 ? (3):(diplayIllus-1)
            })
        }else{
            this.setState({
                displayIllus:diplayIllus === 3 ? (1):(diplayIllus+1)
            })
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,minHeight:"100vh",width:"100%", transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"flex-start",flexDirection:"column"}}>
                    {/*Première div LOGO*/}
                    <div id={"divPresBTOB"} className={"flexCenter"}>
                        <div style={{position:"relative", width:"100%"}}>
                            {this.state.showBrowserView && (
                                <>
                                    <img style={{textAlign:"center", position:"absolute", right:0, height:487, top:45,userSelect: "none"}} src={require("../../assets/backgroundBR1.png")} alt="backgroundBtoBDesign"/>
                                    <img style={{textAlign:"center", position:"absolute", height:600,userSelect: "none"}} src={require("../../assets/backgroundBR2.png")} alt="backgroundBtoBDesign"/>
                                </>
                            )}
                        </div>
                        <div className={"flexCenter divHoverPres"} style={{flex:8, flexDirection:"column", transform:this.state.showBrowserView && ("perspective(1000px) rotateY("+((this.state.mousePosition[0]-(document.body.offsetWidth/2))*40/document.body.offsetWidth)+"deg) rotateX("+((this.state.mousePosition[1]-(document.body.offsetHeight/5))*120/document.body.offsetHeight)+"deg) scale3d(1, 1, 1)")}}>
                            <img style={{textAlign:"center", width:this.state.showBrowserView ?450:"70%",userSelect: "none", maxWidth:440}} src={require("../../assets/LOGO_BtoB_blanc.png")} alt="logoBtoBDesign"/>
                            <h1 className={"textShadowBorder"} style={{color:"#FFFFFF", marginBottom:0,marginTop:60, fontWeight:400, textAlign:"center", fontSize:!this.state.showBrowserView&&"5vw"}}>Créez, utilisez, achetez, revendez, collectionnez</h1>
                            <h1 className={"textShadowBorder"} style={{color:"#FFFFFF", display:!this.state.showBrowserView && "none", marginBottom:0, marginTop:5, fontWeight:400, textAlign:"center", fontSize:!this.state.showBrowserView&&"5vw"}}>Partagez la valeur ajoutée à chaque mutation</h1>
                            <h1 className={"textShadowBorder flexCenter"} style={{color:"#FFFFFF", marginTop:5,marginBottom:0, fontWeight:400, textAlign:"center", fontSize:!this.state.showBrowserView&&"5vw", flexDirection:!this.state.showBrowserView&&"column", whiteSpace:"nowrap"}}>alimentée par la blockchain <img style={{width:250, marginLeft:10}} src={require("../../assets/logoGalaksioLine.png")} alt={"logo Galaksio"}/></h1>
                        </div>
                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                            <Button
                                onClick={()=>{document.getElementById("divPrevCatalogue").scrollIntoView()}}
                                style={{color:"#FFFFFF", borderColor:"#FFFFFF"}} variant={"outlined"}
                            >
                                En savoir plus
                            </Button>
                            <KeyboardArrowDown style={{color:"#FFFFFF"}}/>
                        </div>
                    </div>
                    {/*Div Présentation du catalogue*/}
                    <div id={"divPrevCatalogue"} className={"flexCenter"}>
                        <h1 style={{textAlign:"center", fontWeight:100, fontSize:48, marginTop:0}}>Mettez en vente vos créations</h1>
                        <div className={"flexCenter"} style={{width:"100%"}}>
                            <div className={"flexCenter"} style={{width:this.state.showBrowserView ?"50%":"80%"}}>
                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                    <img style={{width:this.state.showBrowserView ? "30vw":"75%", maxWidth:500}} src={illusSVG1} alt="illustration Design"/>
                                    <h2 style={{color:"#000000", fontWeight:"normal",marginBottom:0, textAlign:"center", width:"65%"}}>Mettez en vente vos concepts sur notre place de marché en quelques clics.</h2>
                                </div>
                            </div>
                            <div className={"flexCenter"} style={{width:"50%", display:!this.state.showBrowserView && "none"}}>
                                <div className={"flexCenter mainDivConcept"} style={{flexDirection:"column"}}>
                                    <div className={"flexCenter"} style={{flex:3,width:"100%", borderBottom:"1px solid #d5d5d5", flexDirection:"column", justifyContent:"flex-start", overflow:"hidden"}}>
                                        <div style={{position:"relative", width:"100%"}}>
                                            <div className={"flexCenter"} style={{position:"absolute", width:"100%", justifyContent:"space-between", top:88}}>
                                                <button onClick={()=>{this.diapoIllus("previous")}} style={{borderRadius:"50%", padding:2, marginLeft:5}} className={"divButtonBtoBDesign flexCenter"}><NavigateBefore style={{margin:0}} /></button>
                                                <button onClick={()=>{this.diapoIllus("next")}} style={{borderRadius:"50%", padding:2, marginRight:5}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                                            </div>
                                        </div>
                                        <img style={{width:"100%"}} src={require("../../assets/illusAccueilBtoB_"+(this.state.displayIllus||1)+".png")} alt={"illustration"}/>
                                    </div>
                                    <div style={{flex:2,width:"100%", borderBottom:"1px solid #d5d5d5",display:"flex", alignItems:"flex-start", flexDirection:"column", justifyContent:"space-evenly"}}>
                                        <h3 style={{margin:0, marginLeft:5,fontWeight:400}}>Plans architecte</h3>
                                        <div style={{border:"1px solid", marginLeft:5, borderColor:"#364982",borderRadius:10, width :"fit-content"}}>
                                            <p style={{margin:0,fontWeight:100, padding:3}}>
                                                Plan
                                            </p>
                                        </div>
                                        <p style={{fontSize:13, fontWeight:100, margin:0, marginLeft:5, marginRight:5, textAlign:"justify"}}>En achetant ce concept, vous obtiendrez le droit d'utilisation sur les plans d'achitecte et pourrez les utiliser à des fins commerciales ou bien pour un usage personel.</p>
                                    </div>
                                    <div className={"flexCenter"} style={{flex:1,width:"100%",justifyContent:"space-around"}}>
                                        <button onClick={()=>{this.props.navigate("/BtoBDesign/Inscription")}} className={"divButtonBtoBDesign"}>J'achète !</button>
                                        <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                            <div className={"flexCenter"}>
                                                <p style={{fontSize:24, fontWeight:600, margin:0}}>25</p>
                                                <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                            </div>
                                            <p style={{fontSize:14, fontWeight:600, margin:0}}>Droits d'utilisation</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Div présentation vente vidéo*/}
                    <div id={"divPrevRevente"} style={{backgroundColor:"#f6f6f6"}} className={"flexCenter"}>
                        <h1 style={{textAlign:"center", fontWeight:100, fontSize:48, marginTop:0}}>Informations supplémentaires</h1>
                        <div className={"flexCenter"} style={{width:"100%"}}>
                            <div className={"flexCenter"} style={{width:this.state.showBrowserView ? "60%":"100%", height:"100%"}}>
                                <div className={"flexCenter"} style={{height:"100%", width:"100%", flexDirection:"column", alignItems:"flex-start", justifyContent:"space-between"}}>
                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                        <h2 style={{fontWeight:"normal",marginBottom:0}}>Découvrez en détail le fonctionnement de notre plateforme.</h2>
                                        <Button className={"divButtonBtoBDesign"} onClick={()=>{window.open("https://galaksio-blockchain.com/btobdesign/","_blank")}} style={{color:"#FFF", margin:15}} variant={"outlined"}>
                                            cliquez ici
                                        </Button>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                        <h2 style={{fontWeight:"normal",marginBottom:0}}>Alimentée par la blockchain</h2>
                                        <div onAuxClick={(e)=>{if(e.button===1){window.open("https://galaksio-blockchain.com/","_blank")}}} onClick={()=>{window.open("https://www.kagesecur.com/","_blank")}} style={{display:"flex",cursor:"pointer", margin:15, justifyContent:"center", alignItems:"center", transform:"scale(1)"}}>
                                            <img style={{width:170, marginLeft:10}} src={require("../../assets/logoGalaksioColumnBlack.png")} alt={"logo Galaksio"}/>
                                        </div>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                        <h2 style={{fontWeight:"normal",marginBottom:0}}>Rejoignez la plateforme BtoBDesign et déposez ou achetez des concepts !</h2>
                                        <Button className={"divButtonBtoBDesign"} onClick={()=>{this.goToPage("/BtoBDesign/Inscription")}} style={{color:"#FFF", margin:15}} variant={"outlined"}>
                                            Je m'inscris
                                        </Button>
                                    </div>

                                </div>
                            </div>
                            {this.state.showBrowserView && (
                                <div className={"flexCenter"} style={{width:"40%"}}>
                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                        <img style={{width:this.state.showBrowserView ? "30vw":"95%", maxWidth:500}} src={illusSVG2} alt="illustration Design"/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div id={"divFooter"} className={"flexCenter"} style={{width:"100%"}}>
                        {this.state.showBrowserView && (
                            <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                                <img style={{textAlign:"center", width: 250,marginRight:5,userSelect: "none"}} src={require("../../assets/LOGO_BtoB.png")} alt="logoKageSecure"/>
                            </div>
                        )}
                        <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                            <div style={{width:this.state.showBrowserView ?"70%":"95%",display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"flex-start"}}>
                                <h3 style={{margin:10, marginLeft:0}}>Nous contacter :</h3>
                                <p style={{margin:0}}>Adresse : 9 Rue Gustave Eiffel 10430 Rosières-près-Troyes</p>
                                <p style={{margin:0}}>Email : spurocontact@spuro.eu</p>
                            </div>
                        </div>
                        <div className={"flexCenter"} style={{flex:1, height:"100%", flexDirection:"column", overflowX:"hidden"}}>
                            <h3 onClick={()=>{this.goToPage("/BtoBDesign/MentionsLegales")}} className={"textCGU"} style={{margin:5, fontWeight:"normal"}}>Mentions légales</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AccueilBtoB)