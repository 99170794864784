import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import {Backdrop, CircularProgress} from "@mui/material";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Design","Développement","Marketing","Informatique et logiciels","Développement personnel","Business","Photographie","Musique","Divers"
]
const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class ModerationAdminBTOB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true,
            jwtDecoded:null
        };
    }

    setInitialStates(){
        return{
            listAllConcepts:[]
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("BTOBDESIGN_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedBtoBDesign", {
                        jwt: this.state.jwt,
                    })
                    this.getListeConcepts()
                }else{
                    Swal.fire({
                        title: 'Veuillez vous reconnecter',
                        text: "Votre session a expiré, veuillez vous reconnecter.",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        sessionStorage.removeItem("BTOBDESIGN_jwt")
                        global.emitter.emit("onRouteChangedBtoBDesign", {
                            jwt: null,
                        })
                        window.location.replace("/BtoBDesign/Accueil")
                    })
                }
            })
        })
    }

    getListeConcepts(){
        Axios.post(url+"getConceptsToModerate",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            console.log(response.data)
            this.setState({displayBackdrop:false})
            if(response.data.jwt){
                sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            console.log(response.data)
            if (response.data.resultat === "done"){
                this.setState({listAllConcepts:response.data.concepts})
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    diapoIllus(elt,todo){
        let diplayIllus = this.state[elt.idToken+"_displayIllus"] || 1
        if(todo === "previous"){
            this.setState({
                [elt.idToken+"_displayIllus"]:diplayIllus === 1 ? (elt.numberImages):(diplayIllus-1)
            })
        }else{
            this.setState({
                [elt.idToken+"_displayIllus"]:diplayIllus === elt.numberImages ? (1):(diplayIllus+1)
            })
        }
    }

    gererConcept(elt,td){
        Axios.post(url+"moderateConcept",{
            acceptOrRefuse:td,
            concept:elt
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            console.log(response.data)
            this.setState({displayBackdrop:false})
            if(response.data.jwt){
                sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            console.log(response.data)
            if (response.data.resultat === "done"){
                let listAllConceptsTemps = JSON.parse(JSON.stringify(this.state.listAllConcepts))
                let indexToRemove = listAllConceptsTemps.findIndex(concept => concept.idToken === elt.idToken)
                console.log(indexToRemove)
                listAllConceptsTemps.splice(indexToRemove,1)
                console.log(listAllConceptsTemps)
                this.setState({listAllConcepts:listAllConceptsTemps})
                Toast.fire({
                    icon: 'success',
                    title: 'Concept modéré.'
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.displayBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"93%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column", alignItems:"flex-start"}}>
                            <div style={{width:"fit-content", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Modérer les concepts :</h1>
                                <div style={{background: "linear-gradient(49deg, rgb(54, 73, 130) 0%, rgb(142, 160, 215) 100%)", width:"100%", height:3, borderRadius:100, marginBottom:20}}></div>
                            </div>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                {this.state.listAllConcepts.length > 0 ? (
                                    <>
                                        {this.state.listAllConcepts.map(elt=>(
                                            <div className={"flexCenter mainDivConcept"} style={{flexDirection:"column"}}>
                                                <div className={"flexCenter"} style={{flex:3,width:"100%", borderBottom:"1px solid #d5d5d5", flexDirection:"column", justifyContent:"flex-start", overflow:"hidden", backgroundColor:"#D7D7D7FF"}}>
                                                    <div style={{position:"relative", width:"100%"}}>
                                                        <div className={"flexCenter"} style={{position:"absolute", width:"100%", justifyContent:"space-between", top:88}}>
                                                            <button onClick={()=>{this.diapoIllus(elt,"previous")}} style={{borderRadius:"50%", padding:2, marginLeft:5}} className={"divButtonBtoBDesign flexCenter"}><NavigateBefore style={{margin:0}} /></button>
                                                            <button onClick={()=>{this.diapoIllus(elt,"next")}} style={{borderRadius:"50%", padding:2, marginRight:5}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                                                        </div>
                                                    </div>
                                                    <img style={{maxWidth:"100%"}} src={`${url}BtoBConcept?empreinte=${elt.owner}&idToken=${elt.idToken}&file=${this.state[elt.idToken+"_displayIllus"]||1}_wm`} alt={"illustration"}/>
                                                </div>
                                                <div style={{flex:2,width:"100%", borderBottom:"1px solid #d5d5d5",display:"flex", alignItems:"flex-start", flexDirection:"column", justifyContent:"space-evenly"}}>
                                                    <h3 style={{margin:5,fontWeight:400}}>{elt.title}</h3>
                                                    <div style={{border:"1px solid", marginLeft:5, borderColor:"#364982",borderRadius:10, width :"fit-content"}}>
                                                        <p style={{margin:0,fontWeight:100, padding:3}}>
                                                            {elt.categorie || "inconnu"}
                                                        </p>
                                                    </div>
                                                    <p style={{fontSize:13, fontWeight:100, margin:0}}>{elt.desc.length > 150 ? elt.desc.slice(0,150) + " (...)":elt.desc}</p>
                                                </div>
                                                <div className={"flexCenter"} style={{flex:1,width:"100%",justifyContent:"space-around", borderBottom:"1px solid #d5d5d5"}}>
                                                    <button onClick={()=>{this.props.navigate("/BtoBDesign/Concept?td="+elt.idToken)}} className={"divButtonBtoBDesign"}>En savoir plus</button>
                                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                        <div className={"flexCenter"}>
                                                            <p style={{fontSize:24, fontWeight:600, margin:0}}>{elt.price}</p>
                                                            <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                        </div>
                                                        <p style={{fontSize:14, fontWeight:600, margin:0}}>{elt.contrat ==="recurrent" ? "Droits d'utilisation":"Possession complète"}</p>
                                                    </div>
                                                </div>
                                                <div className={"flexCenter"} style={{flex:1,width:"100%",justifyContent:"space-between"}}>
                                                    <button onClick={()=>{this.gererConcept(elt,"accept")}} style={{backgroundImage: "linear-gradient(to right, #41ff00 0%, #34cb00 51%, #41ff00 100%)", width:"49%"}} className={"divButtonBtoBDesign"}>Valider</button>
                                                    <button onClick={()=>{this.gererConcept(elt,"refuse")}} style={{backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)", width:"49%"}} className={"divButtonBtoBDesign"}>Refuser</button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ):(
                                    <p>Aucune concept créé ...</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModerationAdminBTOB)