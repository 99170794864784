// SharedUploadFunctions.js

export async function askPermToUpload(socketIO, socketPath, data){
    /* socketIO = instance du socket
       socketPath = path que le socket contact
       data = json regroupant les variables pour autoriser l'upload */
    return new Promise((resolve) => {
        socketIO.emit(socketPath, data, (response) => {
            resolve(response);
        });
    });
}

export function uploadChunk(socketIO, socketPath, data){
    /* socketIO = instance du socket
       socketPath = path que le socket contact
       data = json regroupant les variables de l'upload */
    return new Promise((resolve) => {
        socketIO.emit(socketPath, data, (response) => {
            resolve(response);
        });
    });
}

export function uploadEnded(socketIO, socketPath, data, ){
    /* socketIO = instance du socket
       socketPath = path que le socket contact
       data = json regroupant les variables signaler la fin de l'upload */
    return new Promise((resolve) => {
        socketIO.emit(socketPath, data, (response) => {
            resolve(response);
        });
    });
}