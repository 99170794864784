import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {Add, Delete} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const columnsListeGroupes = [
    { id: 'nom', label: 'Nom du groupe', minWidth: "30%" },
    { id: 'description', label: 'Description', minWidth: "30%" },
    { id: 'nombreMembres', label: 'Nombre de membres', minWidth: "10%" },
    { id: 'action', label: 'Action', minWidth: "30%" },
];

class GererGroupesClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identifiantValue : "",
            emailValue:"",
            verifValue:"",
            refCode:null,
            refCodeAuth:false,
            phase:0,
            allGroups:[],
            allClients:[],
            selectedGroup:{}
        };
    }

    componentDidMount() {
        this.setState({
            hash: sessionStorage.getItem("SUFFRAGE_hash"),
            pseudo: sessionStorage.getItem("SUFFRAGE_pseudo"),
            jwt: sessionStorage.getItem("SUFFRAGE_jwt")
        },  () => {
            this.checkConnexion()
        })
        // this.addUserToGroup()
    }

    sortBy(prop) {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    checkConnexion(){
        Axios.post(url+"connexionSuffrage",{
            empreinte:this.state.hash,
            hashJwt:this.state.hash
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "Connecté"){
                global.emitter.emit("onRouteChangedSuffrage",{
                    user:response.data.gestionnaire ? "gestionnaire":"client",
                    pseudo:sessionStorage.getItem("SUFFRAGE_pseudo")
                })
                this.getAllClientAndGroup()
            }else{
                Toast.fire({
                    title: 'Utilisateur introuvable.',
                    icon: 'error',
                })
                window.location.replace("/Suffrage/Accueil")
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    addNewGroup(e){
        e.preventDefault()
        Axios.post(url+"addGroup",{
            groupName:this.state.champsNomGroupe,
            groupDescription:this.state.champsDescriptionGroupe,
            empreinte:this.state.hash,
            hashJwt:this.state.hash
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                Toast.fire({
                    title: 'Groupe correctement créé.',
                    icon: 'success',
                })
                let cloneAllGroups = this.state.allGroups
                cloneAllGroups.push({description:this.state.champsDescriptionGroupe,nom:this.state.champsNomGroupe,idGroup:response.data.idGroup,content:[]})
                this.setState({addNewGroupDialog:false,allGroups:cloneAllGroups})
            }else{
                Toast.fire({
                    title: 'Une erreur est survenue.',
                    icon: 'error',
                })
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    getAllClientAndGroup(){
        Axios.post(url+"getGroupsAndClients",{
            empreinte:this.state.hash,
            hashJwt:this.state.hash
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                const users = response.data.users.sort(this.sortBy("nom")),
                    groups = response.data.groups.sort(this.sortBy("nom"))
                this.setState({allClients:users,allGroups:groups})
            }else{
                Toast.fire({
                    title: 'Une erreur est survenue.',
                    icon: 'error',
                })
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    onSelectGroup(groupe){
        this.setState({selectedGroup:groupe})
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeDragAndDrop(result){
        if(result.destination){
            if(result.destination.droppableId !== result.source.droppableId){
                let cloneOfGroup = this.state.selectedGroup
                if(result.source.droppableId === "clientsDansGroupes"){ //Suppression du client de ce groupe
                    cloneOfGroup.content.splice(cloneOfGroup.content.findIndex(elt => elt.empreinte+"inGroup" === result.draggableId),1)
                    this.addOrDeleteUserToGroup(result.draggableId.substring(0,result.draggableId.length-7),false)
                }else{ //Ajout du client dans ce groupe
                    if(cloneOfGroup.content.findIndex(elt => elt.empreinte ===result.draggableId) === -1){
                        cloneOfGroup.content.push(this.state.allClients[this.state.allClients.findIndex(elt => elt.empreinte ===result.draggableId)])
                        this.addOrDeleteUserToGroup(result.draggableId,true)
                    }else{
                        Toast.fire({
                            title: 'Ce votant est déjà présent dans ce groupe.',
                            icon: 'warning',
                        })
                    }
                }
            }
        }
    }

    deleteGroup(group,indexGroup){
        Swal.fire({
            title: 'Attention!',
            text: "Voulez-vous vraiment supprimer ce groupe ?",
            icon: 'warning',
            confirmButtonText: "Oui je le supprime",
            confirmButtonColor:"red",
            showCancelButton:true,
            cancelButtonText:"Retour",
            cancelButtonColor:"#6600ff"
        }).then((result)=>{
            if(result.isConfirmed){
                Axios.post(url+"deleteGroup",{
                    empreinte:this.state.hash,
                    idGroup:group.idGroup,
                    hashJwt:this.state.hash
                },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        })
                    }
                    if (response.data.resultat === "done"){
                        let cloneAllGroups = this.state.allGroups.slice()
                        cloneAllGroups.splice(indexGroup,1)
                        this.setState({allGroups:cloneAllGroups,selectedGroup:{}})
                        Toast.fire({
                            title: 'Groupe correctement supprimé.',
                            icon: 'success',
                        })
                    }else{
                        Toast.fire({
                            title: 'Une erreur est survenue.',
                            icon: 'error',
                        })
                    }
                }).catch(()=>{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Serveur injoignable.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
    }

    addOrDeleteUserToGroup(empreinteC,add){
        Axios.post(url+"addOrRemoveClientGroup",{
            empreinteG:sessionStorage.getItem("SUFFRAGE_hash"),
            empreinteC:empreinteC,
            groupName:this.state.selectedGroup.nom,
            idGroup:this.state.selectedGroup.idGroup,
            add:add,
            hashJwt:sessionStorage.getItem("SUFFRAGE_hash")
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat !== "done"){
                Toast.fire({
                    title: 'Une erreur est survenue.',
                    icon: 'error',
                })
            }else if (response.data.resultat === "Ce groupe n'existe plus."){
                this.setState({selectedGroup:{}, allGroups:response.data.allGroups})
            }else{
                let cloneSelectedGroup = this.state.selectedGroup
                cloneSelectedGroup = response.data.allGroups[response.data.allGroups.findIndex(elt => elt.idGroup === cloneSelectedGroup.idGroup)]
                this.setState({allClients:response.data.allUsers,allGroups:response.data.allGroups,selectedGroup:cloneSelectedGroup})
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.addNewGroupDialog}
                    onClose={()=>{this.setState({addNewGroupDialog:false})}}
                >
                    <DialogTitle>Créer un nouveau groupe</DialogTitle>
                    <DialogContent>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"flex-start",heigth:"70vh",maxHeight:"70vh"}}>
                            <form onSubmit={(e)=>{this.addNewGroup(e)}}>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsNomGroupe"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} label="Nom du groupe" variant="outlined" />
                                    <Tooltip arrow={true} title={"Entrez le nom que portera votre nouveau groupe"}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsDescriptionGroupe"} multiline={true} rowsMax={6} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} label="Description (optionel)" variant="outlined" />
                                    <Tooltip arrow={true} title={"Entrez une petite description du groupe"}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Créer</Button>
                            </form>
                        </div>
                    </DialogContent>
                </Dialog>
                <div className={"inGroupDrag"} style={{display:"flex",height:"calc(100vh - 90px)",marginTop:90,width:"100%", justifyContent:"center",alignItems:"center"}}>
                    <DragDropContext style={{overflow:"unset"}} onDragEnd={(result)=>{this.handleChangeDragAndDrop(result)}}>
                        <div style={{flex:0.5,height:"calc(100vh - 90px)", display:"flex", justifyContent:"center",alignItems:"center" }}>
                            <Droppable droppableId="clients">
                                {(provided) => (
                                    <div className={"clients"} ref={provided.innerRef} style={{overflowY:"auto",height:"94%",width:"90%",marginTop:10,backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                        <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Liste des votants</h2></div>
                                        <table style={{width:"100%",borderCollapse:"collapse", border:"1px solid #E5E5E5"}}>
                                            <thead style={{backgroundColor:"#FAFAFA"}}>
                                                <tr>
                                                    <th>Nom</th>
                                                    <th>Prénom</th>
                                                    <th>Adresse</th>
                                                    <th>Date de naissance</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{textAlign:"center"}}>
                                            {this.state.allClients.map((row, index) => (
                                                <Draggable key={row.empreinte} draggableId={row.empreinte} index={index}>
                                                    {(provided) => (
                                                        <tr
                                                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                        >
                                                            <td style={{borderWidth:"1px 0 1px 0"}} >{row.nom}</td>
                                                            <td style={{borderWidth:"1px 0 1px 0"}} >{row.prenom}</td>
                                                            <td style={{borderWidth:"1px 0 1px 0"}} >{row.adresse}</td>
                                                            <td style={{borderWidth:"1px 0 1px 0"}} >{row.DDN !== "errorDecrypt" ? row.DDN:"inconnu"}</td>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </Droppable>
                        </div>

                        <div style={{flex:0.5,height:"calc(100vh - 90px)",display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                            <div style={{flex:0.8,width:"100%",height:"calc(40vh - 90px)",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <div style={{overflowY:"auto",minHeight:"30vh",maxHeight:"30vh",width:"90%",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                    <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Liste des groupes</h2></div>
                                    <TableContainer style={{overflowY:"auto"}}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {columnsListeGroupes.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth,textAlign:"center", fontWeight:"bold"}}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={"AjouterUnGroupe"}>
                                                    <TableCell onClick={()=>{this.setState({addNewGroupDialog:true})}} colspan={columnsListeGroupes.length+1} style={{textAlign:"center"}} key={"ajoutGroupe"}>
                                                        <p style={{display:"flex",justifyContent:"center",alignItems:"center", margin:0}}>Ajouter un groupe <Add/></p>
                                                    </TableCell>
                                                </TableRow>
                                                {this.state.allGroups.map((row,indexGroup) => {
                                                    return (
                                                        <TableRow onClick={()=>{this.onSelectGroup(row)}} style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            {columnsListeGroupes.map((column) => {
                                                                return (
                                                                    <>
                                                                        <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                            {column.id === "action" ? (
                                                                                <IconButton
                                                                                    onClick={()=>{this.deleteGroup(row,indexGroup)}}
                                                                                    style={{padding:0}}
                                                                                >
                                                                                    <Delete/>
                                                                                </IconButton>
                                                                            ):(
                                                                                <>
                                                                                    {column.id === "nombreMembres" ? (
                                                                                        <>{row.content.length}</>
                                                                                    ):(
                                                                                        <>{row[column.id]}</>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </TableCell>
                                                                    </>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                            <div style={{flex:1.2,width:"100%",height:"calc(60vh - 90px)",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <Droppable droppableId="clientsDansGroupes">
                                    {(provided) => (
                                    <div  className={"clientsDansGroupes"} ref={provided.innerRef}  style={{overflowY:"auto",minHeight:"50vh",width:"90%",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                        <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Liste des votants {Object.keys(this.state.selectedGroup).length ===0 ? ("d'un groupe"):("de " + this.state.selectedGroup.nom)}</h2></div>
                                            <table style={{width:"100%",borderCollapse:"collapse", border:"1px solid #E5E5E5"}}>
                                                <thead style={{backgroundColor:"#FAFAFA"}}>
                                                <tr style={{padding:5}}>
                                                    <th>Nom</th>
                                                    <th>Prénom</th>
                                                    <th>Adresse</th>
                                                </tr>
                                                </thead>
                                                <tbody style={{textAlign:"center"}}>
                                                    {Object.keys(this.state.selectedGroup).length===0 ? (
                                                        <td colSpan={3} style={{marginTop:40}}> <h3>Selectionnez un groupe pour visionner ses votants</h3></td>
                                                    ):(
                                                        <>
                                                            {this.state.selectedGroup.content.length === 0 ? (
                                                                <td colSpan={3} style={{marginTop:40}}> <h3>Ce groupe est vide</h3></td>
                                                            ):(
                                                                <>
                                                                    {this.state.selectedGroup.content.map((row, index) => (
                                                                        <Draggable key={[row.empreinte+"inGroup"].toString()} draggableId={[row.empreinte+"inGroup"].toString()} index={index}>
                                                                            {(provided) => (
                                                                                <tr
                                                                                    ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                                >
                                                                                    <td style={{borderWidth:"1px 0 1px 0"}} >{row.nom}</td>
                                                                                    <td style={{borderWidth:"1px 0 1px 0"}} >{row.prenom}</td>
                                                                                    <td style={{borderWidth:"1px 0 1px 0"}} >{row.adresse}</td>
                                                                                </tr>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}

                                                                </>
                                                            )}
                                                            {provided.placeholder}
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                    </div>
                                    )}
                                </Droppable>
                            </div>
                        </div>
                    </DragDropContext>
                </div>
            </div>
        );
    }
}

export default withRouter(GererGroupesClient)