import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleSK.css"
import {isMobile} from "react-device-detect";
import {
    AccountCircle,
    AutoGraph,
    Check,
    Close,
    CloudUpload,
    CreateNewFolder,
    Delete,
    Edit,
    Folder,
    FolderZip,
    KeyboardReturn,
    Login
} from "@mui/icons-material";
import SharedFooterSK from "./components/SharedFooterSK";
import jwt_decode from "jwt-decode";
import Axios from "axios";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import PdfIcon from "../../assets/PDFIcon.png";
import ImageIcon from "@mui/icons-material/Image";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import DescriptionIcon from "@mui/icons-material/Description";

let jwtDecoded;
const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
const columnsListePreuveViaMail = [
    { id: 'identifiant', label: 'Identifiant', minWidth: 75 },
    { id: 'email', label: 'Email', minWidth: 150 },
    { id: 'Action', label: '', minWidth: 20 }]

class MesDossiersSK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            displayDialogModifFolder:null,
            dossierEnCours:true,
            folderName:"Dossier EDF",
            isGestionnaire:false,
            listFolder:[],
            connected:false,
            newUserMail:"",
            specificUserStats:null
        };
    }

    componentWillUnmount(){
        this._emitConnectedSK.remove()
        window.removeEventListener('resize', this.resizePage)
    }


    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    _emitConnectedSK
    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedSK = global.emitter.addListener("emitConnectedSK",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("SK_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedSK", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("SK_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SK_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedSK", {
                        jwt: this.state.jwt,
                    })
                    if(jwtDecoded.gestionnaire || jwtDecoded.admin){
                        this.setState({isGestionnaire:true})
                    }
                    this.setState({connected:true})
                    this.getAllMyFolders()
                }else{
                    global.emitter.emit("askConnectionSK",{disableOutsideClick:true})
                }
            })
        })
    }

    getAllMyFolders(){
        Axios.post(url+"getFoldersSK",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.resultat){
                this.setState({listFolder:response.data.createdFolders})
            }
        })
    }

    createNewMainFolder(){
        Swal.fire({
            title: 'Entrez le nom du dossier :',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonText:"Annuler",
            cancelButtonColor:"#d14529",
            confirmButtonText: 'Créer',
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result)=> {
            if(result.isConfirmed) {
                Axios.post(url+"createFolderSK", {folderName: result.value}, {
                    headers: {Authorization: "Bearer " + this.state.jwt}
                }).then(response => {
                    if (response.data.resultat) {
                        let objFolder = response.data.newFolder
                        Toast.fire({
                            icon: 'success',
                            title: 'Dossier créé'
                        })
                        let listFolder = this.state.listFolder
                        objFolder = {...objFolder, allowUpload:true, canUpload:true, gestionnaire:true,isEmpty:true, listSharedUsers:[{canUpload:true, email:jwtDecoded.email, creator:true,identifiant:jwtDecoded.pseudo}], name:result.value, type:"folder"}
                        listFolder.push(objFolder)
                        this.setState({listFolder})
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Erreur lors de la création du dossier'
                        })
                    }
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    addUserToFolder(e){
        e.preventDefault()
        if(this.state.newUserMail && this.state.newUserMail !== ""){
            Axios.post(url+"addUserToFolderSK", {idTokenFolder: this.state.displayDialogModifFolder.idToken, emailUser:this.state.newUserMail}, {
                headers: {Authorization: "Bearer " + this.state.jwt}
            }).then(response => {
                if (response.data.resultat === "done") {
                    Toast.fire({
                        icon: 'success',
                        title: 'Utilisateur ajouté.',
                        target:"#dialogModifDossier"
                    })
                    let listUserInFolder = this.state.displayDialogModifFolder.listSharedUsers
                    listUserInFolder.push({email:this.state.newUserMail, identifiant:null, idToken:response.data.idToken,canUpload:false,creator:false})
                    /*modify listFolder with according users*/
                    let listFolder = this.state.listFolder
                    listFolder.forEach((folder,index)=>{
                        if(folder.idToken === this.state.displayDialogModifFolder.idToken){
                            listFolder[index].listSharedUsers = listUserInFolder
                        }
                    })

                    this.setState({newUserMail:"",listFolder,displayDialogModifFolder:listFolder.find(folder=>folder.idToken === this.state.displayDialogModifFolder.idToken)})
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Erreur lors de l\'ajout de l\'utilisateur',
                        target:"#dialogModifDossier"
                    })
                }
            })
        }
    }

    removeRecepteurPreuveviaMail(idTokenUser, idTokenFolder){
        Swal.fire({
            title: 'Attention!',
            text: "Êtes-vous certain de vouloir retirer cet utilisateur du dossier ?",
            icon: 'info',
            confirmButtonText: "Oui",
            showCancelButton:true,
            cancelButtonText:"Annuler",
            cancelButtonColor:"red",
            target:"#dialogModifDossier"
        }).then((result)=>{
            if(result.isConfirmed){
                Axios.post(url+"deleteUserFromFolderSK", {idToken: idTokenUser, idTokenFolder}, {
                    headers: {Authorization: "Bearer " + this.state.jwt}
                }).then(response => {
                    if (response.data.resultat === "done") {
                        Toast.fire({
                            icon: 'success',
                            title: 'Utilisateur supprimé.',
                            target:"#dialogModifDossier"
                        })
                        let listUserInFolder = this.state.displayDialogModifFolder.listSharedUsers
                        listUserInFolder.splice(listUserInFolder.findIndex(user=>user.idToken === idTokenUser),1)

                        /*modify listFolder with according users*/
                        let listFolder = this.state.listFolder
                        listFolder.forEach((folder,index)=>{
                            if(folder.idToken === this.state.displayDialogModifFolder.idToken){
                                listFolder[index].listSharedUsers = listUserInFolder
                            }
                        })

                        this.setState({newUserMail:"",listFolder,displayDialogModifFolder:listFolder.find(folder=>folder.idToken === this.state.displayDialogModifFolder.idToken)})
                    } else {
                        Toast.fire({
                            icon: 'error',
                            title: 'Erreur lors de la suppression de l\'utilisateur',
                            target:"#dialogModifDossier"
                        })
                    }
                })
            }
        })
    }

    deleteFolder(folder){
        Axios.post(url+"deleteFolderSK", {idToken: folder.idToken, name:folder.name}, {
            headers: {Authorization: "Bearer " + this.state.jwt}
        }).then(response => {
            if (response.data.resultat === "done") {
                Toast.fire({
                    icon: 'success',
                    title: 'Dossier supprimé.',
                })
                /*delete folder from listFolder*/
                let listFolder = this.state.listFolder
                listFolder.splice(listFolder.findIndex(elt=>elt.idToken === folder.idToken),1)
                this.setState({listFolder, displayDialogModifFolder:null})
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Erreur lors de la suppression du dossier',
                    target:"#dialogModifDossier"
                })
            }
        })
    }

    editFolder(toChange){
        let folder = this.state.displayDialogModifFolder
        Axios.post(url+"editFolderSK", {idToken: folder.idToken, name:folder.name, newName:(toChange==="name" ? this.state.champsNomDossier:undefined),newAllowUpload:(toChange==="upload" ? folder.allowUpload:undefined) }, {
            headers: {Authorization: "Bearer " + this.state.jwt}
        }).then(response => {
            if (response.data.resultat === "done") {
                Toast.fire({
                    icon: 'success',
                    title: toChange==="name" ? 'Dossier renommé.':"Ajout de fichiers modifié.",
                    target: '#dialogModifDossier'
                })
                /*modifyFolder accordingly*/
                let listFolder = this.state.listFolder
                listFolder.forEach((folder,index)=>{
                    if(folder.idToken === this.state.displayDialogModifFolder.idToken){
                        if(toChange==="name"){
                            listFolder[index].name = this.state.champsNomDossier
                        } else if(toChange==="upload"){
                            listFolder[index].allowUpload = folder.allowUpload
                        }
                    }
                })
                this.setState({champsNomDossierOld:this.state.champsNomDossier,listFolder, displayDialogModifFolder:listFolder.find(folder=>folder.idToken === this.state.displayDialogModifFolder.idToken)})
                
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Erreur lors de la suppression du dossier',
                    target:"#dialogModifDossier"
                })
            }
        })
    }

    editUserPerms(userToEdit){
        let folder = this.state.displayDialogModifFolder
        Axios.post(url+"editUserRightsSK", {idToken: userToEdit.idToken, canUpload:userToEdit.canUpload}, {
            headers: {Authorization: "Bearer " + this.state.jwt}
        }).then(response => {
            // button.disabled = false
            if (response.data.resultat === "done") {
                Toast.fire({
                    icon: 'success',
                    title: 'Permissions modifiées.',
                    target:"#dialogModifDossier"
                })
                /*modify listFolder with according users*/
                let listFolder = this.state.listFolder
                listFolder.forEach((folder,index)=>{
                    if(folder.idToken === this.state.displayDialogModifFolder.idToken){
                        listFolder[index].listSharedUsers = folder.listSharedUsers.map(user=>{
                            if(user.idToken === userToEdit.idToken){
                                user.canUpload = userToEdit.canUpload
                            }
                            return user
                        })
                    }
                })
                this.setState({listFolder, displayDialogModifFolder:listFolder.find(folder=>folder.idToken === this.state.displayDialogModifFolder.idToken)})
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Erreur lors de la modification des permissions',
                    target:"#dialogModifDossier"
                })
            }
        })
    }

    getUserStatsInFolder(user){
        if(this.state.specificUserStats !== null){
            this.setState({specificUserStats:null})
        }else{
            Axios.post(url+"getUserDownloadsSK", {idTokenFolder: this.state.displayDialogModifFolder.idToken, userEmpreinte:user.empreinte}, {
                headers: {Authorization: "Bearer " + this.state.jwt}
            }).then(response => {
                if (response.data.resultat === "done") {
                    let totalFiles = response.data.filesDownloaded.length + response.data.filesUnseen.length
                    let allFilesDisplay = response.data.filesDownloaded.concat(response.data.filesUnseen)
                    this.setState({specificUserStats: {pseudo:user.identifiant,totalFiles, filesDownloaded:response.data.filesDownloaded, filesUnseen:response.data.filesUnseen, allFilesDisplay}})
                } else {
                    Toast.fire({
                        icon: 'error',
                        title: 'Erreur lors de la récupération des statistiques',
                        target:"#dialogModifDossier"
                    })
                }
            })
        }
    }

    getContentTypeByExtension(extension) {
        extension= extension.toLowerCase()
        const contentType = {
            aac:"audio/aac",
            abw:"application/x-abiword",
            arc:"application/octet-stream",
            avi:"video/x-msvideo",
            azx:"application/vnd.amazon.ebook",
            bin:"application/octet-stream",
            bmp:"image/bmp",
            bz:"application/x-bzip",
            bz2:"application/x-bzip2",
            csh:"application/x-csh",
            css:"text/css",
            csv:"text/csv",
            doc:"application/msword",
            docx:"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            eot:"application/vnd.ms-fontobject",
            epub:"application/epub+zip",
            gif:"image/gif",
            htm:"text/html",
            html:"text/html",
            ico:"image/x-icon",
            ics:"text/calendar",
            jar:"application/java-archive",
            jpg:"image/jpeg",
            jpeg:"image/jpeg",
            js:"application/javascript",
            json:"application/json",
            mid:"audio/midi",
            midi:"audio/midi",
            mpeg:"video/mpeg",
            mpkg:"application/vnd.apple.installer+xml",
            odp:"application/vnd.oasis.opendocument.presentation",
            ods:"application/vnd.oasis.opendocument.spreadsheet",
            odt:"application/vnd.oasis.opendocument.text",
            oga:"audio/ogg",
            ogv:"video/ogg",
            ogx:"application/ogg",
            otf:"font/otf",
            png:"image/png",
            pdf:"application/pdf",
            ppt:"application/vnd.ms-powerpoint",
            pptx:"application/vnd.openxmlformats-officedocument.presentationml.presentation",
            rar:"application/x-rar-compressed",
            rtf:"application/rtf",
            mp4:"video/mp4",
            sh:"application/x-sh",
            svg:"image/svg+xml",
            swf:"application/x-shockwave-flash",
            tar:"application/x-tar",
            tif:"image/tiff",
            tiff:"image/tiff",
            ts:"application/typescript",
            ttf:"font/ttf",
            txt:"text/plain",
            vsd:"application/vnd.visio",
            wav:"audio/x-wav",
            weba:"audio/webm",
            webm:"video/webm",
            webp:"image/webp",
            woff:"font/woff",
            woff2:"font/woff2",
            xhtml:"application/xhtml+xml",
            xls:"application/vnd.ms-excel",
            xlsx:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            xml:"application/xml",
            xul:"application/vnd.mozilla.xul+xml",
            zip:"application/zip",
            "3gp":"video/3gpp",
            "3gp2":"video/3gpp2",
            "7z":"application/x-7z-compressed",
        }
        return contentType[extension] || 'application/octet-stream';
    }
    getIconForFileType = (fileName) => {
        if(fileName.includes(".")){
            const extension = fileName.split('.')[fileName.split('.').length - 1];
            const contentType = this.getContentTypeByExtension(extension).split('/')[0];

            if(extension === "zip"){
                return <FolderZip style={{color:"#FFF"}} />;
            }else if(extension === "pdf"){
                return <img src={PdfIcon} style={{width:23,filter: "invert(100%)"}} alt="PDF Icon" />;
            }else{
                switch (contentType) {
                    case 'image':
                        return <ImageIcon style={{color:"#FFF"}} />;
                    case 'video':
                        return <VideoIcon style={{color:"#FFF"}} />;
                    default:
                        return <DescriptionIcon style={{color:"#FFF"}} />;
                }
            }
        }else{
            return <DescriptionIcon style={{color:"#FFF"}} />;
        }

    }

    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-SK flexCenter"}>
                    <Dialog
                        open={this.state.displayDialogModifFolder}
                        onClose={()=>{this.setState({displayDialogModifFolder: null})}}
                        fullScreen={!this.state.showBrowserView}
                        style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                        id={"dialogModifDossier"}
                    >
                        <div style={{position:"relative", width:"100%"}}>
                            <Close onClick={()=>{this.setState({displayDialogModifFolder: null,champsNomDossierOld:"", specificUserStats:null, displayStatsFolder:false})}} style={{position:"absolute",right:0,cursor:"pointer"}} />
                        </div>

                        <DialogTitle>
                            <div className={"flexCenter"}>
                                <h2 style={{margin:0}} className={"SKFont"}>Modifier mon dossier :</h2>
                                {this.state.displayDialogModifFolder && (
                                    <button onClick={()=>{this.deleteFolder(this.state.displayDialogModifFolder)}} className={"buttonSK"} style={{backgroundColor:"red",color:"white", margin:5, marginBottom:10, width:"fit-content", display:this.state.displayDialogModifFolder.isEmpty !== true ? "none":"block"}} type={"submit"}>Supprimer ce dossier</button>
                                )}
                            </div>
                        </DialogTitle>
                        {this.state.displayDialogModifFolder && (
                            <DialogContent style={{display:this.state.showBrowserView&&"flex",flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
                                <div className={"flexCenter"} style={{width:"100%"}}>
                                    <input autoComplete={"off"} name={"champsNomDossier"} required={true} className={"champsTexteSKInscription SKFont"} value={this.state.champsNomDossier} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #000000", width:"95%"}} type={"text"} placeholder={"Nom du dossier"}/>
                                    {this.state.champsNomDossier !== this.state.champsNomDossierOld && (
                                        <React.Fragment>
                                            <button onClick={()=>{this.editFolder("name")}} style={{width:"fit-content", padding:11.5}} className={"buttonSK"}><Check/></button>
                                            <button onClick={()=>{this.setState({champsNomDossier:this.state.champsNomDossierOld})}} style={{width:"fit-content", padding:11.5}} className={"buttonSK"}><Close/></button>
                                        </React.Fragment>
                                    )}

                                </div>
                                <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", marginTop:10, marginBottom:10}}>
                                    <button onClick={()=>{this.setState({displayStatsFolder:!this.state.displayStatsFolder, specificUserStats:null})}} className={"buttonSK flexCenter"} style={{color:"white",padding:7.5, width:"fit-content"}} type={"button"}>
                                        {this.state.displayStatsFolder ? (
                                            <React.Fragment>
                                                Permissions <AccountCircle style={{width:25, height:25, color:"#FFF", marginLeft:5}}/>
                                            </React.Fragment>
                                        ):(
                                            <React.Fragment>
                                                Statistiques <AutoGraph style={{width:25, height:25, color:"#FFF", marginLeft:5}}/>
                                            </React.Fragment>
                                        )}
                                    </button>
                                    {!this.state.displayStatsFolder && (
                                        <div className={"flexCenter"}>
                                            <h3 style={{margin:0}} className={"SKFont"}>Ajout de fichiers autorisé :</h3>
                                            <label style={{marginLeft:5}} className="switchKS">
                                                <input checked={this.state.displayDialogModifFolder.allowUpload === true} onChange={()=>{
                                                    let newAllowUpload = !this.state.displayDialogModifFolder.allowUpload
                                                    let listFolder = this.state.listFolder
                                                    listFolder.forEach((folder,index)=>{
                                                        if(folder.idToken === this.state.displayDialogModifFolder.idToken){
                                                            listFolder[index].allowUpload = newAllowUpload
                                                        }
                                                    })
                                                    this.setState({listFolder,displayDialogModifFolder:{...this.state.displayDialogModifFolder, allowUpload:newAllowUpload}},()=>{
                                                        this.editFolder("upload")
                                                    })
                                                }} type="checkbox"/>
                                                <span className="sliderKS round"/>
                                            </label>
                                        </div>
                                    )}
                                </div>
                                {this.state.displayStatsFolder ? (
                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                        <h2 className={"SKFont"} style={{marginBottom:5}}>Statistiques {this.state.specificUserStats ? "de "+this.state.specificUserStats.pseudo:"individuelles"} :</h2>
                                        <hr style={{width:"100%"}}/>

                                        {this.state.specificUserStats ? (
                                            <React.Fragment>
                                                <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                                    <p className={"SKFont"} style={{margin:5}}>Total des fichiers vus : {this.state.specificUserStats.filesDownloaded.length}/{this.state.specificUserStats.totalFiles}</p>
                                                    <button onClick={()=>{this.setState({specificUserStats:null})}} className={"buttonSK flexCenter"} style={{color:"white", width:"fit-content", padding:5}} type={"button"}>Retour <KeyboardReturn style={{width:25, height:25, color:"#FFF", marginLeft:5}}/></button>
                                                </div>
                                                <div style={{display:"flex",flexWrap:"wrap", justifyContent: "space-between", alignItems:"center", width:"100%", maxWidth:this.state.showBrowserView && 700, maxHeight:400, overflow:"auto"}}>
                                                    {this.state.specificUserStats.allFilesDisplay.map(fichier=>(
                                                        <React.Fragment>
                                                            {!!fichier.filename && (
                                                                <div className={"flexCenter"} style={{margin:5, padding:5, borderRadius:5, boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", backgroundImage:fichier.downloaded ? "linear-gradient(45deg, rgba(75,181,67,1) 0%, rgba(62, 233, 43, 1) 100%)":"linear-gradient(45deg, rgba(255,65,54,1) 0%, rgba(255,128,121,1) 100%)"}}>
                                                                    <div style={{marginRight:5}}>
                                                                        {this.getIconForFileType(fichier.filename)}
                                                                    </div>
                                                                    <p style={{margin:0, fontSize:14, color:"#FFF"}} className={"SKFont"}>{fichier.filename}</p>
                                                                </div>
                                                            )}
                                                        </React.Fragment>

                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        ):(
                                            <div style={{display:"flex",flexWrap:"wrap", justifyContent: "space-between", alignItems:"center", width:"100%", maxWidth:this.state.showBrowserView && 550, maxHeight:400, minWidth:400, overflow:"auto"}}>
                                                {this.state.displayDialogModifFolder.listSharedUsers.map((user) => (
                                                    <button onClick={()=>{this.getUserStatsInFolder(user)}} className={"buttonSK"} style={{margin:5, fontWeight:100,display:user.identifiant === null && "none"}}>{user.identifiant}</button>
                                                ))}
                                            </div>
                                        )}

                                    </div>
                                ):(
                                    <form onSubmit={(e)=>{this.addUserToFolder(e)}} style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center", width:"100%"}}>
                                        <div className={"flexCenter"} style={{justifyContent:"space-between", width:"100%", marginBottom:10}}>
                                            <input className={"champsTexteSKInscription SKFont"} name={"newUserMail"} style={{marginBottom: 0,width:"70%"}} value={this.state.newUserMail} onChange={(e)=>{this.handleChange(e)}} required={true} type={"email"} placeholder="Email de l'utilisateur" />
                                            <button className={"buttonSK"} disabled={this.state.newUserMail === ""} style={{backgroundColor:(this.state.recepteurProofName === "" || this.state.newUserMail === "" ? "#c7c7c7":"#52a8d9"),color:"white",padding:7.5, width:"25%"}} type={"submit"}>Ajouter cet utilisateur</button>
                                        </div>
                                        <TableContainer style={{maxHeight:300,overflowY:"auto"}}>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow style={{lineHeight:0}}>
                                                        {columnsListePreuveViaMail.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{ minWidth: column.minWidth, margin:0,lineHeight:0}}
                                                            >
                                                                <h3 className={"SKFont"} style={{margin:5, fontWeight:100}}>{column.label}</h3>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.displayDialogModifFolder.listSharedUsers.length === 0 && (
                                                        <p className={"SKFont"} style={{margin:5, fontWeight:100}}>Aucun utilisateur pour le moment</p>
                                                    )}
                                                    {this.state.displayDialogModifFolder.listSharedUsers.map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                {columnsListePreuveViaMail.map((column) => {
                                                                    return (
                                                                        <TableCell key={column.id}>
                                                                            {column.id === "Action" ? (
                                                                                <React.Fragment>
                                                                                    <Tooltip arrow={true} title={"Permission d'ajouter des fichiers dans ce dossier."}>
                                                                                        <Button style={{backgroundColor:(!row.creator && (row.canUpload ? "#4BB543":"#FF4136FF")), color:!row.creator && "#FFFFFF"}} disabled={row.creator} onClick={()=>{this.editUserPerms({...row, canUpload:!row.canUpload})}} name={row.identifiant}> <CloudUpload/> </Button>
                                                                                    </Tooltip>
                                                                                    <Tooltip arrow={true} title={"Retirer cet utilisateur."}>
                                                                                        <Button disabled={row.creator} onClick={()=>{this.removeRecepteurPreuveviaMail(row.idToken, this.state.displayDialogModifFolder.idToken)}} name={row.identifiant}> <Delete style={{color:"#000"}}/> </Button>
                                                                                    </Tooltip>
                                                                                </React.Fragment>

                                                                            ):(
                                                                                <React.Fragment>
                                                                                    {column.id === "identifiant" ? (
                                                                                        <p className={"SKFont"} style={{margin:5, fontWeight:100,color:row[column.id] === null&&"#ff7928"}}>
                                                                                            {`${row[column.id] === null ? "En attente" : row[column.id]}`}
                                                                                        </p>
                                                                                    ):(
                                                                                        <p className={"SKFont"} style={{margin:5, fontWeight:100,color:row[column.id] === null&&"#ff7928"}}>{row[column.id]}</p>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </form>

                                    )}
                            </DialogContent>
                        )}
                    </Dialog>
                    {this.state.connected && (
                        <div className={"flexCenter"} style={{height:"calc(100vh - 110px)", marginTop:70, width:"100%", flexDirection:"column", overflow:"hidden", justifyContent:"center"}}>
                            <div className={"flexCenter"} style={{width:"100%",  flexDirection:"column"}}>
                                <h1 className={"SKFont"} style={{color:"#000", fontSize:40}}>Mes dossiers :</h1>
                                {this.state.isGestionnaire && (
                                    <div className={"flexCenter"} style={{width:"75%", justifyContent:"flex-start"}}>
                                        <button onClick={()=>{this.createNewMainFolder()}} style={{width:"fit-content",margin:0, marginBottom:10, display:"flex", justifyContent:"center", alignItems:"center", padding:10}} className={"buttonSK"}>Créer un dossier <CreateNewFolder style={{color:"#FFF", height:25, width:25, marginLeft:5}}/></button>
                                    </div>
                                )}
                                <div className={"div-listeDossiers-SK"}>
                                    {this.state.listFolder.length === 0 && (
                                        <h2 style={{width:"100%", height:"100%", margin:0, marginLeft:10}} className={"SKFont flexCenter"}>Vous n'avez aucun dossier partagé pour le moment</h2>
                                    )}
                                    {this.state.listFolder.map(folder=>(
                                        <div className={"flexCenter"} style={{width:"100%", height:this.state.showBrowserView ? 100:140, flexDirection:"column"}}>
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", height:"100%", overflow:"auto"}}>
                                                <div style={{width:"fit-content", margin:0, maxHeight:80, overflow:"hidden", justifyContent:"flex-start", marginLeft:10, minWidth:150}} className={"flexCenter"}> <Folder style={{color:"#000", height:35, width:35, marginRight:5}}/><h2 className={"SKFont"} style={{textOverflow:"ellipsis"}}>{folder.name}</h2></div>
                                                <div className={"flexCenter"} style={{flexWrap:"wrap",width:"fit-content", justifyContent:"space-evenly", display:!this.state.showBrowserView && "none"}}>
                                                    <h3 style={{margin:0, marginRight:10}} className={"SKFont"}>Créé le {new Date(parseInt(folder.idToken.substring(0,13))).toLocaleString()}</h3>
                                                    <h3 style={{margin:0, marginRight:10}} className={"SKFont"}>Modifié le {new Date(parseInt(folder.lastEdit)).toLocaleString()}</h3>
                                                </div>
                                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                    <h3 style={{margin:0, fontSize:15, whiteSpace:"nowrap"}} className={"SKFont"}>{(folder.allowUpload && folder.canUpload) ? "Upload autorisé":"Upload interdit"}</h3>
                                                    <Tooltip title={(folder.allowUpload && folder.canUpload) ? "Le dossier est en cours et l'upload de document vous est autorisé":"Vous n'avez pas les permissions d'uploader un fichier dans ce dossier."}>
                                                        <div className={"flexCenter"} style={{borderRadius:"50%", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", width:38,height:38, backgroundImage:(folder.allowUpload && folder.canUpload) ? "linear-gradient(45deg, rgba(75,181,67,1) 0%, rgba(150,247,140,1) 100%)":"linear-gradient(45deg, rgba(255,65,54,1) 0%, rgba(255,128,121,1) 100%)"}}>
                                                            <CloudUpload style={{width:20, height:20, color:"#FFF"}} />
                                                        </div>
                                                    </Tooltip>

                                                </div>

                                                <div style={{width:this.state.showBrowserView ? "30%":"fit-content", flexWrap:this.state.showBrowserView ? "nowrap":"wrap"}} className={"flexCenter"}>
                                                    <button onClick={()=>{this.setState({displayDialogModifFolder:folder,champsNomDossier:folder.name,champsNomDossierOld:folder.name})}} style={{width:"fit-content", margin:5, padding:this.state.showBrowserView ? 10:5, display:!folder.gestionnaire ? "none":"flex", justifyContent:"center", alignItems:"center"}} className={"buttonSK"}>Modifier <Edit style={{color:"#FFF", height:25, width:23, marginLeft:5}}/></button>
                                                    <button onClick={()=>{this.props.navigate("/Sekuroom/EspaceDossier?td="+folder.idToken)}} style={{width:this.state.showBrowserView?105.5:95.5, margin:5, padding:this.state.showBrowserView ? 10:5, color:"#FFF", display:"flex", justifyContent:"center", alignItems:"center"}} className={"buttonSK"}>Entrer <Login style={{color:"#FFF", height:25, width:25, marginLeft:5}}/></button>
                                                </div>
                                            </div>
                                            <hr style={{width:"100%", borderColor:"#FFFFFF"}}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <SharedFooterSK/>
            </>
        );
    }
}

export default withRouter(MesDossiersSK)