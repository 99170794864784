import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';


class page403 extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex" ,backgroundColor:"#ffffff",height:"100vh",width:"100%"}}>
                    <div style={{height:"calc(100vh - 70px)",width:"100%", display:"flex",justifyContent:"center",alignItems:"center", justifyItems:"center", overflow:"auto", flexDirection:"column"}}>
                        <img style={{width:"100%", maxWidth:500}} src={require("../assets/No data.gif")} alt="illustration Session expirée"/>
                        <h1 style={{fontWeight:100, textAlign:"center", marginTop:5}}>Votre session a expiré, veuillez vous reconnecter !</h1>
                        <button onClick={()=>{this.props.navigate("/")}} className={"divButtonBuyResale"}>Accéder au site</button>
                    </div>
                </div>
            </div>


        );
    }
}

export default withRouter(page403)