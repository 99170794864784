import React, {Component} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import {withRouter} from '../../../shared/NavigationUtils';
import config from "../../../json/config.json";
import {isBrowser} from "react-device-detect";
import Swal from "sweetalert2";
import SharedFooterEkocoOR from "./components/SharedFooterEkocoOR";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Axios from "axios";
import {sha256} from "js-sha256";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let email, iban, bic, token, pseudo, hash;

let jwtDecoded

class DeleteAccountOR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            openLoad:false,
            url:"unknown",
            decompte:3,
            emailToSub:null
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950 || !isBrowser){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)

    }

    handleChange=(event) => {
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }

    handleDeleteAccount(e){
        e.preventDefault()

        Swal.fire({
            title: 'Attention !',
            text: "Cette action est irréversible, êtes-vous sûr de vouloir invalider votre compte ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonColor: '#ff0000',
            cancelButtonColor: '#ec5ba1',
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({openLoad:true})
                const empreinte = sha256(this.state.champsIdentifiant.concat(this.state.champsPassword))
                Axios.post(url + "deleteAccountOR", {empreinte}).then((response)=>{
                    this.setState({openLoad:false})
                    if(response.data.resultat === "done"){
                        Swal.fire({
                            title: 'Compte supprimé !',
                            text: "Votre compte a bien été supprimé.",
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#ec5ba1'
                        })
                        console.log("icii")
                        this.setState({
                            champsPassword:"",
                            champsIdentifiant:""
                        })
                    }else{
                        Swal.fire({
                            title: 'Erreur',
                            text: "Une erreur est survenue, veuillez réessayer ultérieurement.",
                            icon: 'error',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#ec5ba1'
                        })
                        this.setState({
                            champsPassword:"",
                            champsIdentifiant:""
                        })
                    }
                }).catch((err)=>{
                    console.log(err)
                    Swal.fire({
                        title: 'Erreur',
                        text: "Une erreur est survenue, veuillez réessayer ultérieurement.",
                        icon: 'error',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#ec5ba1'
                    })
                    this.setState({
                        openLoad:false,
                        champsPassword:"",
                        champsIdentifiant:""
                    })
                })
            }
        })
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:0, justifyContent:"flex-end"}}>
                    {/*<p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold"}}>{this.state.pseudo}</p>*/}
                    {/*<AccountCircleIcon style={{color:"#b0a9a8", marginRight:50}}/>*/}
                </div>
                {/*DEBUT RENDER STATIQUE*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)", height:"80vh"}}>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute",left:50, top:"25vh"}}>
                            <img style={{width:350}} src={require("../assetsEkoco/telephoneFemme_rose.png")} alt={"Reporting"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", zIndex:1,position:"absolute", left:0, top:0}}>
                            <img style={{width:"80", objectFit:"contain"}} src={require("../assetsEkoco/topleft_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                    <div style={{marginTop:50,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",marginBottom:20,width:"100%", overflow:"hidden"}}>
                        <div style={{backgroundColor:this.state.showBrowserView ? "#FFF":"transparent", width:this.state.showBrowserView ? 500:"95%", padding:15, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:30, paddingTop:30, borderRadius:10, zIndex:2}}>
                            {this.state.url !== "unknown" ? (
                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                    <p style={{color:"#ec5ba1",fontWeight:"bold", fontSize:30, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Redirection dans {this.state.decompte}</p>
                                    <div className={"flexCenter"} style={{flexDirection:"row", justifyContent:"space-evenly"}}>
                                        <p style={{color:"#ec5ba1",fontWeight:"bold", fontSize:30, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>{this.state.url}</p>
                                    </div>
                                </div>
                            ):(
                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                    <p className={"robotoFont"} style={{color:"#ec5ba1",fontWeight:1000, fontSize:30, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Invalidation du compte</p>
                                    <p className={"robotoFont"} style={{color:"#ec5ba1",fontWeight:400, fontSize:15, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>
                                        La désactivation de votre compte supprimera vos photos et certificats de nos serveurs. Vos actions enregistrées sur la blockchain, telles que vos parcours effectués et vos donations, demeureront inscrites sur le registre, mais elles seront anonymisées et ne seront plus prises en compte.
                                    </p>
                                    <form style={{width:"100%"}} autoComplete="off" onSubmit={(e)=>{this.handleDeleteAccount(e)}}>
                                        <div style={{position:"relative", width:"100%"}}>
                                            <input autoComplete={"off"} name={"champsIdentifiant"} required className={"champsTexteSK champsTexteEkocoRose"} value={this.state.champsIdentifiant} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #EC5BA1FF", color:"#EC5BA1FF"}} type={"text"} placeholder={"Identifiant"}/>
                                        </div>
                                        <div style={{position:"relative", width:"100%"}}>
                                            <input autoComplete={"new-password"} name={"champsPassword"} required className={"champsTexteGNFT champsTexteEkocoRose"} value={this.state.champsPassword} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #EC5BA1FF", color:"#EC5BA1FF", position:"relative"}} type={this.state.showPassword ? "text":"password"} placeholder={"Clef unique"}/>
                                            <div style={{position:"absolute", width:"fit-content", right:10, top:24}}>
                                                {this.state.showPassword ? (
                                                    <VisibilityIcon style={{cursor:"pointer", color:"#EC5BA1FF"}} onClick={()=>{this.setState({showPassword:false})}}/>
                                                ):(
                                                    <VisibilityOffIcon style={{cursor:"pointer", color:"#EC5BA1FF"}} onClick={()=>{this.setState({showPassword:true})}}/>
                                                )}
                                            </div>
                                        </div>
                                        <div className={"flexCenter"}>
                                            <button type={"submit"} style={{width:"fit-content", marginTop:15, backgroundImage:"none", backgroundColor:"#EC5BA1FF"}} className={"buttonSK robotoFont"}><h2 style={{margin:0, fontWeight:600, color:"#FFF"}}>Invalider mon compte</h2></button>
                                            {/*<button className={"buttonGNFT ChampFont"} style={{padding:10,width:"fit-content", color:"#000", margin:5, marginTop:10, border:"1px solid black", borderRadius:5}} type={"submit"}><h2 style={{margin:0, fontWeight:"normal"}}>Log In</h2></button>*/}
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex",justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute", right:50, top:"25vh"}}>
                            <img style={{width:350, objectFit:"contain"}} src={require("../assetsEkoco/homme_rose.png")} alt={"Statistiques"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end" , zIndex:1,position:"absolute", right:0, bottom:0}}>
                            <img style={{width:"80%", objectFit:"contain"}} src={require("../assetsEkoco/bottomright_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                </div>
                {/*FIN RENDER STATIQUE*/}
                {/*FOOTER*/}
                <SharedFooterEkocoOR/>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Chargement en cours ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default withRouter(DeleteAccountOR);