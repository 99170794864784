import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {isBrowser} from "react-device-detect";

import Axios from "axios";
import config from "../json/config.json";

import {Button, TextField, Tooltip} from "@mui/material";
import tooltipContenu from "../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded;

class OubliClefUnique extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phase:1,
            champsPseudo:"",
            champsEmailClient:"",
            resultatOubli:false,
            stateDrawer:true
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt", {},
                {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if (response.data.resultat !== true){
                    this.props.navigate('/Administration/ConnexionAdmin')
                }else{
                    if(response.data.jwt){
                        sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:jwtDecoded.pseudo
                    })
                    global.emitter.emit("onRouteChanged",{
                        pageName:'Oubli de la clef unique',
                        pseudo:jwtDecoded.pseudo,
                    })
                    if (jwtDecoded.admin){
                        this.setState({
                            connected:true
                        })
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Vous n'êtes pas autorisé à accéder à cette page",
                            icon: 'error',
                            target:".MuiDialog-root",
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/Administration/AccueilAdmin")
                        })
                    }
                }
            })
        })
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }
    }

    handleSubmitPhase1(event) {
        event.preventDefault()
        Axios.post(url+"generateLinkForgottenKey", {
            pseudo:this.state.champsPseudo,
            email:this.state.champsEmailClient,
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=> {
            if (response.data.jwt) {
                sessionStorage.setItem("SPURO_jwt", response.data.jwt)
                this.setState({
                    jwt: response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done") {
                this.setState({resultatOubli:true})
                Toast.fire({
                    title: "Un mail de récupération de clef unique a été envoyé au client " + this.state.champsPseudo + ".",
                    icon: 'success',
                })
            } else {
                Toast.fire({
                    title: "Une erreur est survenue.",
                    icon: 'error',
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto",minHeight:"285px", width:600,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase1(e)}}>
                            <h2>Entrez les informations demandées :</h2>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsPseudo"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez l'identifiant du client."} onChange={(e)=>{this.handleChange(e)}} label="Identifiant du client" variant="outlined"/>
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsEmailClient"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez l'email du client."} onChange={(e)=>{this.handleChange(e)}} label="Email du client" variant="outlined" />
                                <Tooltip arrow={true} title={"Entrez ici l'email du client ayant oublié sa clef unique."}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            {!this.state.resultatOubli ?
                                <Button type={"submit"} variant="contained">Confirmer</Button>
                                :
                                <p>Un mail vient d'être envoyé au client pour récupérer sa clef unique.</p>
                            }
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(OubliClefUnique)