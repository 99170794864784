import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import {
    ArrowRightAlt,
    Business,
    Close,
    Info,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Payments,
    Person,
    Receipt,
    ReceiptLong,
    Visibility,
    Warning
} from "@mui/icons-material";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    Slider,
    TextField,
    Tooltip
} from "@mui/material";


const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class MesActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true,
            listActions:[],
            listSaleProposals:[],
            listTransactions:[],
            clickedAction:null,
            dataClientToken:undefined
        };
    }

    setInitialStates(){
        return{
            valueSlider:null,
            typeVente:0,
            receiverValue:"",
            sellingPrice:"",
            displayListProposal:false,
            displayListTransactions:false,
            disableSendLink:false,
            preventOpenDiv:false,
            preventDoublePay:false,

        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }
    _emitConnectedBR
    componentDidMount() {
        let td = new URLSearchParams(window.location.search).get("td");
        if(td !== null) {
            this.setState({
                td
            })
        }
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedBR = global.emitter.addListener("emitConnectedSMSTO",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("SMSTO_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedStockMarketSTO", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: this.state.jwt,
                    })
                    this.getMyActions()
                    this.getAllMySaleProposals()
                }else{
                    global.emitter.emit("askConnexionSMSTO",{disableOutsideClick:true})
                }
            })
        })
    }

    getMyActions(){
        Axios.post(url+"getMyActions",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                this.setState({listActions:response.data.actions})
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let value = event.target.value
        /*remplacer les virgules par des points*/
        value = value.replace(/[^0-9.,]/g, '')
        value = value.replace(/,/g, '.')
        if((value.match(/\./g) || []).length > 1){
            /*Remplacer tous les points par "" sauf le premier*/
            value = value.replace(/.(?=[^.]*$)/g, '')
        }
        if(value.includes(".")){
            if(value.split(".")[1].length>2){
                /*On supprime les chiffres après le deuxième chiffre*/
                value = value.split(".")[0] + "." + value.split(".")[1].substring(0,2)
            }
        }
        /*On s'assure que la valeur est > 0*/
        if (parseFloat(value) < 0){
            value = 0
        }
        this.setState({
            [event.currentTarget.name] : value
        })
    }

    handleSliderChange(e){
        this.setState({valueSlider:e.target.value})
    }

    handleSliderInputChange(e,elt){
        if(Number(e.target.value) <= elt.client.boughtActions && Number(e.target.value) >= 0){
            this.setState({valueSlider:Number(e.target.value)})
        }
    }

    handleBlurInputSlider(e,elt){
        if (e.target.value < Number(elt.client.boughtActions) && e.target.value > 0) {
            this.setState({valueSlider:Number(e.target.value)})
        }
    };

    getAllMySaleProposals(){
        Axios.post(url+"getMyProposals",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                this.setState({listSaleProposals:response.data.proposals})
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    sendLinkSale(company,sendMail){
        this.setState({disableSendLink:true})
        Swal.fire({
            title: 'Attention!',
            text: "Êtes-vous certain de vouloir"+(this.state.typeVente === 2 ?" créer un lien de ":" envoyer une ")+"proposition de vente ?",
            icon: 'info',
            confirmButtonText: "Oui",
            showCancelButton:true,
            cancelButtonText:"Annuler",
            cancelButtonColor:"red"
        }).then((result)=> {
            if (result.isConfirmed) {
                if(this.state.valueSlider * this.state.sellingPrice < 1){
                    Swal.fire({
                        title: 'Erreur',
                        text: "Le montant minimum total pour une vente est de 1€",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }else{
                    Axios.post(url+"saleProposal",{
                        siren:company.siren,
                        numberActions:this.state.valueSlider,
                        actionValue:this.state.sellingPrice,
                        empreinte:this.state.typeVente === 0 ?this.state.receiverValue:null,
                        identifiant:this.state.typeVente === 1 ?this.state.receiverValue:null,
                        sendMail
                    },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                        if(response.data.jwt){
                            sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                            this.setState({
                                jwt:response.data.jwt
                            },()=>{
                                jwtDecoded = jwt_decode(this.state.jwt)
                            })
                        }
                        if (response.data.resultat === "done"){
                            if(this.state.typeVente !== 2 && sendMail ===true){
                                Toast.fire({
                                    icon: 'success',
                                    title: 'Proposition de vente envoyée !'
                                })
                            }else{
                                navigator.clipboard.writeText(response.data.lien)
                                Swal.fire({
                                    title: 'Lien de la vente copié !',
                                    icon: 'info',
                                    confirmButtonText: 'Ok'
                                })
                            }
                            let listSaleProposalsTemp = this.state.listSaleProposals.slice()
                            let idTokenTemp = response.data.lien.split("idTokenProposal=")[1]
                            listSaleProposalsTemp.push({
                                company: company.nomEntreprise,
                                idToken: idTokenTemp,
                                nominatif: this.state.receiverValue,
                                numberActions: this.state.valueSlider,
                                ref: company.siren,
                                sum: this.state.sellingPrice
                            })
                            this.setState({listSaleProposals:listSaleProposalsTemp})
                        }else{
                            Swal.fire({
                                title: 'Erreur',
                                text: response.data.resultat,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            }).then(()=>{
                                if(response.data.resultat === "Veuillez mettre à jour vos informations bancaires."){
                                    this.props.navigate("/StockMarketSTO/Profil")
                                }
                            })
                        }
                        this.setState({...this.setInitialStates()})
                    }).catch(()=>{
                        Swal.fire({
                            title: 'Erreur',
                            text: "Serveur injoignable.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    })
                }
            }else{
                this.setState({...this.setInitialStates()})
            }
        })
    }

    cancelProposal(idTokenProposal){
        Axios.post(url+"cancelProposal",{idTokenProposal},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let listSaleProposalsTemp = this.state.listSaleProposals.slice()
                listSaleProposalsTemp.splice(listSaleProposalsTemp.findIndex(elt => elt.idToken === idTokenProposal),1)
                this.setState({listSaleProposals:listSaleProposalsTemp})
            }else{
                this.setState({displayListProposal:false})
                Swal.fire({
                    title: 'Erreur',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    cancelInvestissement(invest){
        Swal.fire({
            title: 'Attention!',
            text: "Êtes-vous certain de vouloir annuler votre investissement ? Vous perdrez vos 10% investis.",
            icon: 'info',
            confirmButtonText: "Oui",
            showCancelButton:true,
            cancelButtonText:"Annuler",
            cancelButtonColor:"red"
        }).then((result)=> {
            if (result.isConfirmed) {
                Axios.post(url+"cancelInvestment",{
                    idToken:invest.client.idToken
                },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === "done"){
                        let listActionsTemp = this.state.listActions.slice()
                        listActionsTemp.splice(listActionsTemp.findIndex(elt => elt.client.idToken === invest.client.idToken),1)
                        this.setState({listActions:listActionsTemp})
                        Toast.fire({
                            icon: 'success',
                            title: "Promesse d'investissement annulé."
                        })
                    }else{
                        Swal.fire({
                            title: 'Erreur',
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                    this.setState({...this.setInitialStates()})
                })
            }else{
                this.setState({...this.setInitialStates()})
            }
        })
    }

    finaliseInvestment(action){
        if(this.state.preventDoublePay !== true){
            this.setState({preventDoublePay:true},()=>{
                Axios.post(url+"finaliseInvestment",{
                    idToken:action.idToken,
                    paid:(action.boughtActions-(action.paid/action.actionValue))*action.actionValue
                },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === "done"){
                        let listActionsTemp = this.state.listActions.slice()
                        let index = listActionsTemp.findIndex(elt => elt.client.idToken === action.idToken)
                        listActionsTemp[index].client.paid=action.boughtActions*action.actionValue
                        this.setState({listActions:listActionsTemp})
                        Toast.fire({
                            icon: 'success',
                            title: 'Actions réglées !'
                        })
                    }else{
                        Swal.fire({
                            title: 'Erreur',
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                    this.setState({preventDoublePay:false})
                })
            })
        }
    }

    getTransactionsHistory(){
        Axios.post(url+"getTransactionsHistory",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                this.setState({listTransactions:response.data.listTransactions,displayListTransactions:true})
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }


    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.displayListProposal}
                    onClose={()=>{this.setState({displayListProposal:false})}}
                    style={{padding:11, zIndex:10000}}
                >
                    <DialogTitle>Liste des propositions de vente :</DialogTitle>
                    <DialogContent style={{height:"100%", maxHeight:"65vh"}}>
                        {this.state.listSaleProposals.length === 0 && (
                            <div className={"flexCenter"} style={{width:"100%", height:100}}>
                                <p className={"flexCenter"} style={{margin:0, justifyContent:"flex-start", flex:1, fontStyle:"italic"}}>Vous n'avez créé aucune proposition de vente pour le moment</p>
                            </div>
                        )}
                        {this.state.listSaleProposals.map((elt,index)=>(
                            <>
                                <div className={"flexCenter"} style={{width:"100%", height:100}}>
                                    <h2 className={"flexCenter"} style={{margin:0, justifyContent:"flex-start", flex:1}}>
                                        <img style={{width:45, marginRight:10}} src={`${url}images/smsto_entreprise/${elt.ref}/logo.png`} alt="logo"/>
                                        {elt.company}
                                    </h2>
                                    <div style={{display:"flex", justifyContent:"center", flex:1, width:"fit-content", flexDirection:"column", margin:"0 20px 0 20px"}}>
                                        <button style={{padding:13}} className={"divButtonBuyResale"} onClick={()=>{window.open(baseUrl+"/StockMarketSTO/PropositionDeVente?idTokenProposal="+elt.idToken)}}><Visibility/></button>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"center", flex:1, width:"fit-content", flexDirection:"column", margin:"0 20px 0 20px"}}>
                                        {elt.nominatif ? (
                                            <p>Pour : <span style={{fontWeight:"bold"}}>{elt.nominatif.length > 15 ? (elt.nominatif.slice(0,4)+"..."+elt.nominatif.slice(-4)):elt.nominatif}</span></p>
                                        ):(
                                            <p>Non nominatif</p>
                                        )}
                                    </div>
                                    <div style={{display:"flex", justifyContent:"flex-end", flex:1, width:"fit-content", flexDirection:"column"}}>
                                        <h2 className={"flexCenter"} style={{margin:0, width:220, justifyContent:"flex-end"}}>
                                            <span style={{fontSize:34, marginLeft:10}}>{elt.numberActions}</span>
                                            <img style={{width:40, marginTop:4, marginLeft:10}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                        </h2>
                                        <h2 className={"flexCenter"} style={{margin:0, width:220, justifyContent:"flex-end"}}>
                                            <span style={{fontSize:34, marginLeft:10}}>{elt.sum}</span>
                                            <img style={{width:40, marginTop:4, marginLeft:8}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                        </h2>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"flex-end", flex:1, width:"fit-content", flexDirection:"column"}}>
                                        <button onClick={()=>{this.cancelProposal(elt.idToken)}} style={{marginLeft:20,color:"#FFF",backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)"}} className={"flexCenter divButtonBuyResale"}><Close/> Annuler</button>
                                    </div>
                                </div>
                                {index !== this.state.listSaleProposals.length-1 && (
                                    <div style={{width:"94%", marginLeft:"3%", marginRight:"3%", height:2, backgroundColor:"#ececec"}}/>
                                )}
                            </>
                        ))}
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.displayListTransactions}
                    onClose={()=>{this.setState({displayListTransactions:false})}}
                    style={{padding:11, zIndex:10000}}
                >
                    <DialogTitle>Historique des transactions :</DialogTitle>
                    <DialogContent style={{height:"100%", maxHeight:"65vh"}}>
                        {this.state.listTransactions.length === 0 && (
                            <div className={"flexCenter"} style={{width:"100%", height:100}}>
                                <p className={"flexCenter"} style={{margin:0, justifyContent:"flex-start", flex:1, fontStyle:"italic"}}>Aucun historique n'est disponible pour le moment.</p>
                            </div>
                        )}
                        {this.state.listTransactions.map((elt,index)=>(
                            <>
                                <div className={"flexCenter"} style={{width:"100%", height:"fit-content", flexDirection:"column"}}>
                                    <p style={{margin:0, width:"100%", textAlign:"left", fontStyle:"italic",fontWeight:400}}>{new Date(parseInt(elt.date)).toLocaleString()}</p>
                                    <div className={"flexCenter"} style={{width:"100%", height:100, justifyContent:"flex-start"}}>
                                        <h2 className={"flexCenter"} style={{margin:0, justifyContent:"flex-start", flex:1}}>
                                            <img style={{width:45, marginRight:10}} src={`${url}images/smsto_entreprise/${elt.siren}/logo.png`} alt="logo"/>
                                            {elt.company}
                                        </h2>
                                        <div style={{display:"flex", justifyContent:"center", flex:1, width:"fit-content", marginLeft:30}}>
                                            {elt.type === "vente" && (
                                                <div className={"flexCenter"} style={{width:'95%', justifyContent:"space-between"}}>
                                                    <h2 className={"flexCenter"} style={{margin:0, fontWeight:100, width:"100%", textAlign:"center"}}>
                                                        <Tooltip style={{zIndex:15000}} title={"Votre identifiant"}>
                                                            <Person style={{width:35, height:35,color:"#73BDECFF"}}/>
                                                        </Tooltip>
                                                        {jwtDecoded.pseudo}
                                                        <ArrowRightAlt style={{margin:"0 5px 0 5px", width:50, height:50}}/>
                                                        <Tooltip style={{zIndex:15000}} title={"Identifiant de l'acheteur"}>
                                                            <Person style={{width:35, height:35}}/>
                                                        </Tooltip>
                                                        {elt.who}
                                                    </h2>
                                                </div>
                                            )}
                                            {elt.type === "achat" && (
                                                <div className={"flexCenter"} style={{width:'95%', justifyContent:"space-between"}}>
                                                    <h2 className={"flexCenter"} style={{margin:0, fontWeight:100, width:"100%", textAlign:"center"}}>
                                                        <Tooltip style={{zIndex:15000}} title={elt.who === elt.company ? "Nom de l'entreprise":"Identifiant du vendeur"}>
                                                            {elt.who === elt.company ? (
                                                                <Business style={{width:35, height:35}}/>
                                                            ):(
                                                                <Person style={{width:35, height:35}}/>
                                                            )}
                                                        </Tooltip>
                                                        {elt.who}
                                                        <ArrowRightAlt style={{margin:"0 5px 0 5px", width:50, height:50}}/>
                                                        <Tooltip style={{zIndex:15000}} title={"Votre identifiant"}>
                                                            <Person style={{width:35, height:35,color:"#73BDECFF"}}/>
                                                        </Tooltip>
                                                        {jwtDecoded.pseudo}
                                                    </h2>
                                                </div>
                                            )}

                                        </div>
                                        <div style={{display:"flex", justifyContent:"flex-end", flex:1, width:"fit-content", flexDirection:"column"}}>
                                            <h2 className={"flexCenter"} style={{margin:0, width:200, justifyContent:"flex-end"}}>
                                                <span style={{fontSize:34, marginLeft:10}}>{elt.number}</span>
                                                <img style={{width:40, marginTop:4, marginLeft:10}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                            </h2>
                                            <h2 className={"flexCenter"} style={{margin:0, width:200, justifyContent:"flex-end"}}>
                                                <span style={{fontSize:34, marginLeft:10}}>{elt.number*elt.price}</span>
                                                <img style={{width:40, marginTop:4, marginLeft:8}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                            </h2>
                                        </div>
                                    </div>
                                    {index !== this.state.listTransactions.length-1 && (
                                        <div className={"flexCenter"} style={{width:"100%"}}>
                                            <div style={{width:"94%",margin:10, marginLeft:"3%", marginRight:"3%", height:2, backgroundColor:"#ececec"}}/>
                                        </div>
                                    )}
                                </div>

                            </>
                        ))}
                    </DialogContent>
                </Dialog>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between"}}>
                                <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                    <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Mes actions :</h1>
                                    <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:205, height:3, borderRadius:100, marginBottom:20}}></div>
                                </div>
                                <div className={"flexCenter"} style={{width:1200}}>
                                    {this.state.listActions.length !== 0 && (
                                        <button style={{margin:10}} onClick={()=>{this.getTransactionsHistory()}} className={"divButtonBuyResale flexCenter"}> <Receipt style={{marginRight:10, color:"#FFFFFF", transform:"scale(1.3)"}}/> Historique des transactions</button>
                                    )}
                                    {this.state.listActions.length !== 0 && (
                                        <button style={{margin:10}} onClick={()=>{this.setState({displayListProposal:true})}} className={"divButtonBuyResale flexCenter"}> <Payments style={{marginRight:10, color:"#FFFFFF", transform:"scale(1.3)"}}/> Propositions de vente</button>
                                    )}
                                </div>

                            </div>

                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                {this.state.listActions.length === 0 && (
                                    <div className={"flexCenter"} style={{width:"100%", height:100}}>
                                        <h3 className={"flexCenter"} style={{margin:0, justifyContent:"flex-start", flex:1, fontStyle:"italic"}}>Vous n'avez acheté aucune action pour le moment</h3>
                                    </div>
                                )}
                                {this.state.listActions.map(elt=>(
                                    <div onMouseLeave={()=>{elt.company.status !== "done" && (this.setState({preventOpenDiv:false}))}} onMouseEnter={()=>{elt.company.status !== "done" && (this.setState({preventOpenDiv:true}))}}  onClick={()=>{ //Ouvre la div si on clique n'importe où mais ne peut pas la refermer
                                        (this.state.clickedAction !== elt.client.idToken && !this.state.preventOpenDiv && Number(elt.client.paid)/elt.client.actionValue === Number(elt.client.boughtActions)) && (
                                            this.setState({clickedAction:elt.client.idToken,receiverValue:"",sellingPrice:"", valueSlider:""})
                                        )
                                    }} className={"divListeAction"} style={{width:"100%", flexDirection:"column", height:this.state.showBrowserView ? (this.state.clickedAction === elt.client.idToken && 300):(this.state.clickedAction === elt.client.idToken ? 565:260), cursor:(this.state.clickedAction !== elt.client.idToken && Number(elt.client.paid/elt.client.actionValue) === Number(elt.client.boughtActions)) && elt.company.status === "done" &&"pointer"}}>
                                        <div style={{width:"100%", flexDirection:!this.state.showBrowserView && "column" ,display:"flex", justifyContent:"space-between", alignItems:"center", minHeight:100}}>
                                            <h2 className={"flexCenter"} style={{margin:0, justifyContent:this.state.showBrowserView&&"flex-start", width:this.state.showBrowserView ? 200:"100%", maxHeight:45}}>
                                                <img style={{width:45, marginRight:10}} src={`${url}images/smsto_entreprise/${elt.company.siren}/logo.png`} alt="logo"/>
                                                {elt.company.nomEntreprise}
                                            </h2>
                                            <div style={{display:!this.state.showBrowserView ? "none":"flex", justifyContent:"flex-end", maxWidth:350, width:"fit-content", flexDirection:"column", opacity:elt.client.boughtActions - elt.client.paid/elt.client.actionValue === 0 ? 0:1}}>
                                                <Tooltip title={/*"Actions totales réservées. Le réglement de ces actions s'effectue à la fin de la levée de fonds concernée."*/""}>
                                                    <h4 className={"flexCenter"} style={{margin:0, color:"#a2a2a2", fontWeight:"100", marginBottom:3, textAlign:"center"}}>Actions totales réservées <Info style={{marginLeft:5}}/></h4>
                                                </Tooltip>
                                                <h2 className={"flexCenter"} style={{margin:0, width:"fit-content", justifyContent:"flex-end"}}>
                                                    <span style={{fontSize:34, marginLeft:10, color:"#ffd223"}}>{elt.client.boughtActions}</span>
                                                    <img style={{width:40, marginTop:4, marginLeft:10}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                </h2>
                                                <h2 className={"flexCenter"} style={{margin:0, width:"fit-content", justifyContent:"flex-end"}}>
                                                    <span style={{fontSize:34, marginLeft:10, color:"#ffd223"}}>{elt.client.boughtActions * elt.client.actionValue}</span>
                                                    <img style={{width:40, marginTop:4, marginLeft:8}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                </h2>
                                            </div>
                                            <div style={{display:"flex", justifyContent:"flex-end", maxWidth:350, width:"fit-content", flexDirection:"column"}}>
                                                <Tooltip title={"Les actions que vous possédez et leur valeur en Euro."}>
                                                    <h4 className={"flexCenter"} style={{margin:0, color:"#a2a2a2", fontWeight:"100", marginBottom:3, textAlign:"center"}}>Actions {elt.client.boughtActions - elt.client.paid/elt.client.actionValue === 0 ? "possédées":"payées"} <Info style={{marginLeft:5}}/></h4>
                                                </Tooltip>
                                                <h2 className={"flexCenter"} style={{margin:0, width:"fit-content", justifyContent:"flex-end"}}>
                                                    <span style={{fontSize:34, marginLeft:10}}>{elt.client.paid/elt.client.actionValue}</span>
                                                    <img style={{width:40, marginTop:4, marginLeft:10}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                </h2>
                                                <h2 className={"flexCenter"} style={{margin:0, width:"fit-content", justifyContent:"flex-end"}}>
                                                    <span style={{fontSize:34, marginLeft:10}}>{elt.client.paid}</span>
                                                    <img style={{width:40, marginTop:4, marginLeft:8}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                </h2>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                {Number(elt.client.paid/elt.client.actionValue) !== Number(elt.client.boughtActions) ? (
                                                    <section className={"flexCenter"} style={{flexDirection:"column", marginRight:15}}>
                                                        <h4 style={{margin:0, color:"#a2a2a2", fontWeight:"100", marginBottom:3}}>Annuler l'investissement</h4>
                                                        <IconButton onClick={()=>{this.cancelInvestissement(elt)}} onMouseLeave={()=>{this.setState({preventOpenDiv:false})}} onMouseEnter={()=>{this.setState({preventOpenDiv:true})}} style={{color:"#000", width:50,height:50, backgroundColor:"#ececec"}}>
                                                            <Close style={{width:30,height:30}}/>
                                                        </IconButton>
                                                    </section>
                                                ):(
                                                    <section className={"flexCenter"} style={{flexDirection:"column", marginRight:15}}>
                                                        <Tooltip title={elt.company.status !== "done" ? "Vous aurez accès au registre à la fin de la levée de fonds.":"Accédez au registre des actionnaires."}>
                                                            <h4 className={"flexCenter"} style={{margin:0, color:"#a2a2a2", fontWeight:"100", marginBottom:3}}>Registre des actionnaires <Info style={{marginLeft:5}}/></h4>
                                                        </Tooltip>
                                                        <IconButton disabled={elt.company.status !== "done"} onClick={()=>{this.props.navigate("/StockMarketSTO/RegistreActionnaires?td="+elt.client.ref)}} onMouseLeave={()=>{this.setState({preventOpenDiv:false})}} onMouseEnter={()=>{this.setState({preventOpenDiv:true})}}  style={{color:elt.company.status !== "done" ?"#A2A2A2FF":"#000", width:50,height:50, backgroundColor:"#ececec"}}>
                                                            <ReceiptLong style={{width:30,height:30}}/>
                                                        </IconButton>
                                                    </section>
                                                )}
                                                {Number(elt.client.paid/elt.client.actionValue) !== Number(elt.client.boughtActions) ? (
                                                    <Tooltip title={elt.company.status !== "done" ? "Le paiement de vos actions réservées sera disponible à la fin de la campagne de levée de fonds":"Accédez au paiement de vos actions réservées"}>
                                                        <section className={"flexCenter"} style={{flexDirection:"column", marginLeft:15}}>
                                                            <h4 className={"flexCenter"} style={{margin:0, color:"#a2a2a2", fontWeight:"100", marginBottom:3}}>Payer mes actions</h4>
                                                            <IconButton onClick={()=>{this.finaliseInvestment(elt.client)}} disabled={elt.company.status !== "done" || this.state.preventDoublePay} style={{color:elt.company.status !== "done" ?"#A2A2A2FF":"#000", width:50,height:50, backgroundColor:"#ececec"}}>
                                                                <Payments style={{width:30,height:30}}/>
                                                            </IconButton>
                                                        </section>
                                                    </Tooltip>
                                                ):(
                                                    <section className={"flexCenter"} style={{flexDirection:"column", marginLeft:15}}>
                                                        <Tooltip title={elt.company.status !=="done" ? "Vous pourrez mettre en vente vos actions à la fin de la levée de fonds.":"Mettez en vente vos actions."}>
                                                            <h4 className={"flexCenter"} style={{margin:0, color:"#a2a2a2", fontWeight:"100", marginBottom:3}}>Mise en vente <Info style={{marginLeft:5}}/></h4>
                                                        </Tooltip>
                                                        <IconButton disabled={elt.company.status !=="done"} style={{color:elt.company.status !=="done" ? "#A2A2A2FF":"#000", width:50,height:50, backgroundColor:"#ececec"}} onClick={()=>{this.setState({clickedAction:this.state.clickedAction === elt.client.idToken ? null:elt.client.idToken,receiverValue:"",sellingPrice:"", valueSlider:""})}}>
                                                            {this.state.clickedAction === elt.client.idToken ? (
                                                                <KeyboardArrowUp style={{width:30,height:30}}/>
                                                            ):(
                                                                <KeyboardArrowDown style={{width:30,height:30}}/>
                                                            )}
                                                        </IconButton>

                                                    </section>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{width:"100%", position:"relative", overflow:"hidden",height:"100%", maxHeight:this.state.showBrowserView ? 180:(this.state.clickedAction === elt.client.idToken ? 310:0),transition: "all .8s cubic-bezier(.25,.8,.25,1)",marginTop:20, flexDirection:!this.state.showBrowserView && "column"}}>
                                            <div style={{width:"94%", marginLeft:"3%", marginRight:"3%", height:2, backgroundColor:"#ececec"}}/>
                                            <div style={{width:"98%" , marginLeft:"1%", marginRight:"1%",display:"flex", justifyContent:"space-between", alignItems:"center", height:180, position:"absolute", flexDirection:!this.state.showBrowserView && "column"}}>
                                                <div className={"flexCenter"} style={{flexDirection:"column", width:this.state.showBrowserView ? "33%":"100%",marginBottom:!this.state.showBrowserView&&20}}>
                                                    <h4 style={{margin:0, color:"#000", fontWeight:"100", marginBottom:5, width:"100%"}}>Mode d'envoi de la vente</h4>
                                                    <div className={"flexCenter"} style={{width:"100%", border:"1px solid black", justifyContent:"space-between", borderRadius:10, cursor:"pointer"}}>
                                                        <p style={{margin:0, width:"33%", textAlign:"center", backgroundColor:this.state.typeVente === 0 && "#73BDECFF", color:this.state.typeVente === 0 && "white", borderRadius:10, padding:5}} onClick={()=>{this.setState({typeVente:0, receiverValue:""})}}>Empreinte</p>
                                                        <p style={{margin:0, width:"33%", textAlign:"center", backgroundColor:this.state.typeVente === 1 && "#73BDECFF", color:this.state.typeVente === 1 && "white", borderRadius:10, padding:5}} onClick={()=>{this.setState({typeVente:1, receiverValue:""})}}>Identifiant</p>
                                                        <p style={{margin:0, width:"33%", textAlign:"center", backgroundColor:this.state.typeVente === 2 && "#73BDECFF", color:this.state.typeVente === 2 && "white", borderRadius:10, padding:5}} onClick={()=>{this.setState({typeVente:2, receiverValue:""})}}>Lien</p>
                                                    </div>
                                                    {this.state.typeVente === 2 ? (
                                                        <div style={{width:"100%"}}>
                                                            <button onClick={()=>{this.sendLinkSale(elt.company,false)}} disabled={!this.state.valueSlider > 0 || (this.state.sellingPrice === null || this.state.sellingPrice === "") || this.state.disableSendLink} style={{width:"100%", marginTop:15, padding:19}} className={"divButtonBuyResale"}>Copier le lien de vente</button>
                                                            <p className={"flexCenter"} style={{margin:0, fontStyle:10, width:"fit-content", whiteSpace:"nowrap",textAlign:"left"}}> <Warning style={{marginRight:5}}/> Créé une proposition de vente non nominative</p>
                                                        </div>
                                                    ):(
                                                        <TextField
                                                            label={this.state.typeVente === 0 ? "Empreinte":this.state.typeVente === 1 && "Identifiant"}
                                                            variant={"outlined"}
                                                            name={"receiverValue"}
                                                            style={{marginTop:15, width:"100%"}}
                                                            value={this.state.receiverValue}
                                                            onChange={(e)=>{this.handleChange(e)}}
                                                            placeholder={this.state.typeVente === 0 ? "0b634c73bf8ebf9952a8ee544f364e5e57b166da21b6f4e196b0f359e552b794":"JohnDoe"}
                                                            InputLabelProps={{ shrink : true }}
                                                        />
                                                    )}
                                                </div>
                                                <div className={"flexCenter"} style={{width:"fit-content", flexDirection:!this.state.showBrowserView && "column"}}>
                                                    <div className={"flexCenter"} style={{width:'100%', flexDirection:"column"}}>
                                                        <div className={"flexCenter"} style={{width:300}}>
                                                            <Slider
                                                                style={{marginRight:10}}
                                                                value={typeof this.state.valueSlider === 'number' ? this.state.valueSlider : 0}
                                                                onChange={(e)=>{this.handleSliderChange(e)}}
                                                                min={0}
                                                                max={elt.client.boughtActions}
                                                                valueLabelDisplay="auto"
                                                            />
                                                            <TextField
                                                                label={"Actions"}
                                                                variant={"outlined"}
                                                                style={{marginLeft:10}}
                                                                value={typeof this.state.valueSlider === 'number' ? this.state.valueSlider : 0}
                                                                size="small"
                                                                onChange={(e)=>{this.handleSliderInputChange(e,elt)}}
                                                                onBlur={(e)=>{this.handleBlurInputSlider(e,elt)}}
                                                                onFocus={event => {
                                                                    event.target.select();
                                                                }}
                                                                inputProps={{
                                                                    step:10,
                                                                    min: 0,
                                                                    max: Number(elt.client.boughtActions),
                                                                    type: 'number',
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={"flexCenter"} style={{width:'100%', justifyContent:"space-around", marginTop:5}}>
                                                            <h2 className={"flexCenter"} style={{margin:0, fontSize:34}}>
                                                                {(this.state.valueSlider||0)<=0 ? 0:this.state.valueSlider}<img style={{width:31, marginTop:4, marginRight:10,marginLeft:3}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/> <span style={{fontSize:22, marginRight:10}}> : </span>
                                                                <div style={{width:130, height:36}}>
                                                                    <TextField
                                                                        label={"Prix unitaire"}
                                                                        variant={"outlined"}
                                                                        style={{marginLeft:5}}
                                                                        name={"sellingPrice"}
                                                                        value={this.state.sellingPrice}
                                                                        size="small"
                                                                        onChange={(e)=>{this.handleChangeOnlyNum(e)}}
                                                                        onFocus={event => {
                                                                            event.target.select();
                                                                        }}
                                                                        placeholder={"0"}
                                                                        InputLabelProps={{ shrink : true }}
                                                                        InputProps={{
                                                                            endAdornment:
                                                                                <InputAdornment position="end">
                                                                                    <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                                                </InputAdornment>
                                                                        }}
                                                                    />
                                                                </div>
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    {this.state.typeVente !== 2 && (
                                                        <div className={"flexCenter"} style={{marginTop:!this.state.showBrowserView &&10}}>
                                                            <button onClick={()=>{this.sendLinkSale(elt.company,true)}} disabled={!this.state.valueSlider > 0 || (this.state.sellingPrice === null || this.state.sellingPrice === "") || this.state.disableSendLink || this.state.receiverValue ===""} style={{marginLeft:15, fontSize:!this.state.showBrowserView ? 18:22, padding:!this.state.showBrowserView && 10}} className={"divButtonBuyResale flexCenter"}>
                                                                Envoyer la vente
                                                            </button>
                                                            <button onClick={()=>{this.sendLinkSale(elt.company,false)}} disabled={!this.state.valueSlider > 0 || (this.state.sellingPrice === null || this.state.sellingPrice === "" || this.state.disableSendLink || this.state.receiverValue ==="")} style={{marginLeft:15, fontSize:!this.state.showBrowserView ? 18:22, maxHeight:82, padding:!this.state.showBrowserView && 10}} className={"divButtonBuyResale flexCenter"}>
                                                                Copier le lien de vente
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MesActions)