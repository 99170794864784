import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import Axios from "axios";
import {Backdrop} from "@mui/material";
import jwt_decode from "jwt-decode";
import {AccountCircle, Business, ExpandMore, GetApp} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let columns = [
    { id: 'name', label: 'Name', minWidth: 10 },
    { id: 'image', label: 'Image', minWidth: 10 },
    { id: 'year', label: 'Year', minWidth: 10 },
    { id: 'volume', label: 'Volume', minWidth: 10 },
    { id: 'action', label: 'Action', minWidth: 10 },
];

const ToastGNFT = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFontTitleToast',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    },
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFont ChampFontTitle',
        content: 'ChampFont ChampFontContent',
        confirmButton: 'ChampFont ChampFontButton',
        cancelButton: 'ChampFont ChampFontButton',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    }
    // confirmButtonTextColor: "#3b3b3b",
})

let jwtDecoded;

class RecapTransacGNFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            addDialog:false,
            creationMode:1,
            brands:[],
            packs:[],
            randNumber:0,
            bottleInPack:[],
            oldBottleInpack:[],
            packPhase:1,
            displayEditImage:false,
            oldChampsNumberTickets:null,
            transacRecap:[]
        };
    }
    _emitConnectedGNFT;
    componentWillUnmount(){
        this._emitConnectedGNFT.remove()
        window.removeEventListener('resize', this.resizePage)
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedGNFT = global.emitter.addListener("emitConnectedGNFT",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("GNFT_jwt")}, ()=>{
                global.emitter.emit("onRouteChangedGNFT", {
                    jwt: this.state.jwt,
                })
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                },()=>{
                    this.connexion()
                })
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("GNFT_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    /*Si on est pas admin alors on redirige vers la page home*/
                    if(jwtDecoded.admin !== true){
                        this.props.navigate("/GrapeNFT/Home")
                    }else{
                        this.setState({connected:true})
                        global.emitter.emit("onRouteChangedGNFT", {
                            jwt: this.state.jwt,
                        })
                        this.getTransacRecap()
                    }
                }else{
                    global.emitter.emit("askConnectionGNFT",{disableOutsideClick:true})
                }
            })
        })
    }

    getTransacRecap(){
        /*Axios pour récupérer toutes les transactions faites par les clients*/
        Axios.post(url+"getGNFTTransactions",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.resultat){
                let allTransactions = response.data.allTransactions
                /*sort all transactions by timestamp from newest to oldest*/
                allTransactions.sort((a,b)=>{
                    return b.timestamp - a.timestamp
                })
                this.setState({
                    transacRecap:allTransactions
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    selectDeploy(transac){
        this.setState({
            ["deploy"+transac.idToken]: (this.state["deploy"+transac.idToken] ? !this.state["deploy"+transac.idToken] : true)
        })
    }

    downloadPDFRecap(){
        /*call Axios : generateCertificate avec idToken de la reservation et le nom de la bouteille*/
        this.setState({displayLoading:true})
        /*set timer of 10s and catch if timed out*/
        let timerCertif = setTimeout(()=>{
            this.setState({displayLoading:false})
            ToastGNFT.fire({
                title:"An error occured while downloading the PDF",
                icon:"error"
            })
        },10000)

        Axios.post(url+"generatePDFRecap",{
            transacRecap:this.state.transacRecap
        },{headers:{Authorization:"Bearer " + this.state.jwt},responseType:"arraybuffer"}).then(response=>{
            console.log(response.data)
            let buffer = Buffer.from(response.data, 'base64')
            let file = new Blob([buffer], {type: 'application/pdf'});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            let date = (new Date()).toLocaleString("fr-FR").replaceAll("_","-").replaceAll(" ","_")
            link.download = "Transaction_summary_"+date+".pdf";
            link.click();
            this.setState({displayLoading:false})
            clearTimeout(timerCertif)
            ToastGNFT.fire({
                title:"Recap generated",
                icon:"success"
            })
        }).catch(e=>{
            this.setState({displayLoading:false})
            clearTimeout(timerCertif)
            ToastGNFT.fire({
                title:"An error occured while downloading the PDF",
                icon:"error"
            })
        })
    }

    render() { //Affichage sur la page
        return (
            <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", flexDirection:"column" ,height:"100%",width:"100%"}}>
                <Backdrop
                    sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: 999999999 }}
                    open={this.state.displayLoading}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <div className={"loaderTextGNFT ChampFont"}>Loading</div>
                        <h2 className={"ChampFont"} style={{color:"#FFBB41FF"}}>Please wait a few seconds</h2>
                    </div>
                </Backdrop>
                <div className={"flexCenter"} style={{height:"calc(100vh - 70px)", marginTop:70, width:"100%", overflow:this.state.showBrowserView ? "hidden":"auto", justifyContent:"space-between"}}>
                    <div className={"flexCenter"} style={{flex:"1 1 10%", height:"100%", zIndex:99,background: "linear-gradient(125deg, rgba(42,44,36,1) 0%, rgba(15,18,0,1) 100%)", flexDirection:"column", justifyContent:"flex-start"}}>
                        <div className={"flexCenter"} style={{width: "100%", height:"100%", marginLeft:this.state.showBrowserView && 80, flexDirection:"column", alignItems:"flex-start", justifyContent:"space-between"}}>
                            <div className={"flexCenter"} style={{marginBottom:15, marginTop:15, width:"100%", justifyContent:"flex-start"}}>
                                <h1 className={"ChampFont"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:60, margin:0, whiteSpace:"nowrap"}}>
                                    Transaction summary
                                </h1>
                                <button onClick={()=>{this.downloadPDFRecap()}} style={{width:"fit-content", display:"flex", margin:0, marginLeft:10}} className={"flexCenter buttonGNFT"}>
                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:"bold", fontSize:30, margin:0,width:"fit-content"}}>
                                        PDF
                                    </h2>
                                    <GetApp style={{color:"#FFF", marginLeft:5, width:32, height:32}}/>
                                </button>
                            </div>

                            <div className={"flexCenter"} style={{width:"95%", justifyContent:"space-between", padding:0}}>
                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                    <h1 className={"ChampFont flexCenter"} style={{color:"#FFBB41FF", fontWeight:600, margin:0}}>Seller identity</h1>
                                </div>
                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                    <h1 className={"ChampFont flexCenter"} style={{color:"#FFBB41FF", fontWeight:600, margin:0}}>Bottle sold</h1>
                                </div>
                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                    <h1 className={"ChampFont flexCenter"} style={{color:"#FFBB41FF", fontWeight:600, margin:0}}>Price</h1>
                                </div>
                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                    <h1 className={"ChampFont flexCenter"} style={{color:"#FFBB41FF", fontWeight:600, margin:0, marginRight:5}}>Buyer identity</h1>
                                </div>
                            </div>
                            <hr style={{width:'95%', backgroundColor:"#FFF", margin:0, marginTop:10}}/>
                            <div className={"flexCenter"} style={{width:"95%", alignItems:"flex-start", justifyContent:"flex-start", height:"100%", overflowY:"auto", overflowX:"hidden", flexDirection:"column", padding:30, paddingLeft:0}}>
                                {this.state.transacRecap.length===0 && (
                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, marginLeft:10, marginTop:15}}>
                                        No transaction yet
                                    </h2>
                                )}
                                {this.state.transacRecap.map(transac => (
                                    <React.Fragment>
                                        <div className={"flexCenter"} style={{width:"100%",alignItems:"space-between", flexDirection:"column", transition:"all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                            <div style={{width:"100%",height:1, position:"relative"}}>
                                                <h2 onClick={()=>{this.selectDeploy(transac)}} className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", margin:0, cursor:"pointer", position:"absolute", fontSize:17}}>
                                                    <ExpandMore style={{color:"#FFBB41FF", width:30, height:30, cursor:"pointer", transition:"all .5s cubic-bezier(.25,.8,.25,1)", transform:!this.state["deploy"+transac.idToken] && "rotate(-90deg)"}} />
                                                    Display more informations
                                                </h2>
                                                <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:600, margin:0, right:0, position:"absolute", width:"fit-content", whiteSpace:"nowrap", fontSize:18, fontStyle:"italic"}}>{new Date(parseInt(transac.timestamp)).toLocaleString()}</h2>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", padding:15}}>
                                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:600, margin:0}}>{transac.seller==="brand" ? (<Business  style={{color:"#FFF", fontWeight:600, margin:0, width:35, height:35}}/>):(<AccountCircle  style={{color:"#FFF", fontWeight:600, margin:0, width:35, height:35}}/>)}</h2>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:600, margin:0, marginLeft:5}}>{transac.seller==="brand" ? (transac.infosBrand.nom):(transac.pseudoSeller)}</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                    <img className={"hoverImageRecap"} onClick={()=>{transac.idTokenBottle && (window.open(url+"GrapeNFT/"+transac.idTokenBottle+"_image.png"))}} style={{maxWidth:65,display:"flex", marginRight:5, borderRadius:10, cursor:"pointer"}} src={url+"GrapeNFT/"+transac.idTokenBottle+"_image.png"} alt="Champagne Bottle"
                                                         onError={({ currentTarget }) => {
                                                             currentTarget.onerror = null; // prevents looping
                                                             console.log("error",currentTarget.src )
                                                             currentTarget.src= require("../../assets/defaultUnknownBottle.png");
                                                         }}
                                                    />
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:600, margin:0}}>{transac.infosBottle.name}</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"fit-content", flexDirection:"column"}}>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:600, margin:0}}>{transac.price}€</h2>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#4BB543FF", fontWeight:600, margin:0}}>{(transac.price - (transac.price*(8/100)).toFixed(2) - (transac.seller==="brand" ? 0:(transac.price*(transac.infosBrand.commission/100)).toFixed(2))).toFixed(2)}€</h2>

                                                </div>
                                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:600, margin:0, marginRight:5}}>{transac.pseudoBuyer}</h2>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:600, margin:0}}><AccountCircle  style={{color:"#FFF", fontWeight:600, margin:0, width:35, height:35}}/></h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"flexCenter collapsableDivGNFT"} style={{width:"100%",transform:this.state["deploy"+transac.idToken] ? "scaleY(1)":"scaleY(0)", maxHeight:this.state["deploy"+transac.idToken]?"100%":"0%", opacity:this.state["deploy"+transac.idToken]?1:0}}>
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", padding:5}}>
                                                <div className={"flexCenter"} style={{flexDirection:"column", width:"25%", alignItems:"flex-start"}}>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:100, margin:0}}>IBAN :<span style={{fontStyle:"italic", fontSize:15, marginLeft:5, fontWeight:"bold"}}>{transac.seller === "brand" ?"unknown":(transac.ibanSeller ? transac.ibanSeller:"unknown")}</span></h2>
                                                </div>
                                                <div className={"flexCenter"} style={{ flexDirection:"column", width:"25%"}}>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:100, margin:0}}>Brand : <span style={{fontWeight:"bold", marginLeft:5}}>{transac.infosBrand ? transac.infosBrand.nom:"unknown"}</span></h2>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:100, margin:0}}>ID Bottle : <span style={{fontSize:15, fontStyle:"italic", marginLeft:5,fontWeight:"bold"}}>{transac.idTokenBottle}</span></h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"30%",flexDirection:"column", alignItems:"flex-end"}}>
                                                    {transac.infosBrand && (
                                                        <React.Fragment>
                                                            <h2 className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content", color:"#FFF", fontWeight:100}}>Manufacturer commission : <span style={{fontWeight:"bold", marginLeft:5, color:"#FF4136FF"}}>{(transac.price*(transac.infosBrand.commission/100)).toFixed(2)}€ ({transac.infosBrand.commission}%)</span></h2>
                                                            <h2 className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content", color:"#FFF", fontWeight:100}}>GrapeNFT total fees : <span style={{fontWeight:"bold", marginLeft:5, color:"#FF4136FF"}}>{(transac.price*(8/100)).toFixed(2)}€ (8%)</span></h2>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                                <div className={"flexCenter"} style={{width:"20%"}}>
                                                    {/*<h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:600, margin:0}}>{transac.price}€</h2>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{width:'100%', backgroundColor:"#FFF"}}/>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RecapTransacGNFT)