import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import Dropzone from "react-dropzone";
import jwt_decode from "jwt-decode";
import {
    Avatar,
    Backdrop,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {ContactSupport, Delete, Description, NavigateBefore, NavigateNext, ShoppingCart} from "@mui/icons-material";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import PayPalButtonBTOB from "../../Components/PaypalButtonBTOB";

const sizeLimitFile = 10737418 // 10MB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Couture/Mode","Production Écrite","Théâtre Spectacle","Danse","Musique","Film","Dessin/Graphisme","Photographie","Plan","Logiciel"
]


const {baseUrl, paypalPublicKey, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

let IntervalDiapo

class Concepts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true,
            td:null
        };
        this.createOrderPaypal = this.createOrderPaypal.bind(this);

    }

    setInitialStates(){
        return{
            displayUI:false,
            displayBackdrop:true,
            allFilesIllus:[],
            allFilesAttachments:[],
            phaseCreation:1,
            contractType:undefined,
            conceptName:"",
            conceptDesc:"",
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }
    _emitConnectedBtoBDesign
    init(){
        let td = new URLSearchParams(window.location.search).get("td");
        if(!!td){
            this.setState({td})
        }
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedBtoBDesign = global.emitter.addListener("emitConnectedBtoBDesign",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("BTOBDESIGN_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedBtoBDesign", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("BTOBDESIGN_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: this.state.jwt,
                    })
                    this.setState({connected:true})
                    this.getInfosClient()
                }else{
                    global.emitter.emit("askConnexionBtoBDesign",{disableOutsideClick:true})
                }
            })
        })
    }

    componentDidMount() {
        IntervalDiapo = setInterval(()=>{
            if (!!this.state.conceptDetail){
                let diplayIllus = this.state[this.state.conceptDetail.idToken + "_displayIllus"] || 1
                this.setState({[this.state.conceptDetail.idToken + "_displayIllus"]: diplayIllus === this.state.conceptDetail.numberImages ? (1) : (diplayIllus + 1)})
            }
        },3000)
        this.init()
    }

    getInfosClient(){
        Axios.post(url+"getInfosBtoBDesign",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                if (!response.data.allInfosCompleted && this.state.td === "new"){
                    Swal.fire({
                        title: 'Profil non complet',
                        text: "Votre profil n'est pas suffisamment complété pour créer un concept. Nous allons vous rediriger sur la page de modification de profil !",
                        icon: 'info',
                        target:"#backdropLoading",
                        confirmButtonText: 'Ok',
                        cancelButtonText:"Annuler",
                        showCancelButton:true,
                        allowEscapeKey:false,
                        allowOutsideClick:false
                    }).then((data)=>{
                        if(data.isConfirmed){
                            this.props.navigate("/BtoBDesign/Profil?td=missingInfos")
                        }else{
                            this.props.navigate(-1)
                        }
                    })
                }else if (this.state.td === "new"){
                    this.setState({displayUI:true,displayBackdrop:false})
                }else if (this.state.td !== "new"){
                    this.getInfosConcept()
                }
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    getInfosConcept(){
        this.setState({displayUI:true,displayBackdrop:true})
        Axios.post(url+"getOneConcept",{idToken:this.state.td},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            this.setState({displayBackdrop:false})
            console.log(response.data.concept)
            if(response.data.jwt){
                sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                let conceptDetail = response.data.concept
                this.setState({conceptDetail})
                if(conceptDetail.mine !== false){
                    this.setState({disableClickBuy:true})
                }
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    this.props.navigate(-1)
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    removeIllus(illus){
        let allFilesIllusTemp = this.state.allFilesIllus.slice()
        let index = allFilesIllusTemp.findIndex(elt => elt.name === illus.name)
        allFilesIllusTemp.splice(index,1)
        this.setState({allFilesIllus: allFilesIllusTemp})
    }

    removeAttach(attachment){
        let allFilesAttachmentsTemp = this.state.allFilesAttachments.slice()
        let index = allFilesAttachmentsTemp.findIndex(elt => elt.name === attachment.name)
        allFilesAttachmentsTemp.splice(index,1)
        this.setState({allFilesAttachments: allFilesAttachmentsTemp})
    }

    createConcept(){
        console.log(this.state.allFilesAttachments, this.state.allFilesAttachments.length)
        Swal.fire({
            title: 'Attention!',
            text: "Êtes-vous certain de vouloir créer ce concept ?",
            icon: 'info',
            confirmButtonText: "Oui",
            showCancelButton:true,
            cancelButtonText:"Annuler",
            cancelButtonColor:"red"
        }).then((result)=> {
            if (result.isConfirmed) {
                this.setState({displayBackdrop:true},()=>{
                    setTimeout(()=>{
                        Axios.post(url+"addConcept",{
                            title:this.state.conceptName,
                            desc:this.state.conceptDesc,
                            contrat:this.state.contractType ? "recurrent":"oneshot",
                            categorie:this.state.conceptCategorie,
                            price:this.state.conceptPrice,
                            images:this.state.allFilesIllus,
                            attachments:this.state.allFilesAttachments
                        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                            this.setState({displayBackdrop:false})
                            if(response.data.jwt){
                                sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                                this.setState({
                                    jwt:response.data.jwt
                                })
                            }
                            if (response.data.resultat === "done"){
                                Swal.fire({
                                    title: 'Concept en cours de création',
                                    text: "Votre concept est en cours de création, ce processus peut prendre quelques minutes.",
                                    icon: 'success',
                                    confirmButtonText: 'Ok'
                                }).then(()=>{
                                    this.props.navigate("/BtoBDesign/MesConcepts")
                                })
                            }else{
                                this.setState({...this.setInitialStates()})
                                this.init()
                                Swal.fire({
                                    title: 'Erreur!',
                                    text: response.data.resultat,
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                })
                            }
                        })
                    },500)
                })
            }
        })
    }

    diapoIllus(elt,todo) {
        clearInterval(IntervalDiapo)
        let diplayIllus = this.state[elt.idToken + "_displayIllus"] || 1
        if (todo === "previous") {
            this.setState({
                [elt.idToken + "_displayIllus"]: diplayIllus === 1 ? (elt.numberImages) : (diplayIllus - 1)
            })
        } else {
            this.setState({
                [elt.idToken + "_displayIllus"]: diplayIllus === elt.numberImages ? (1) : (diplayIllus + 1)
            })
        }
    }

    createOrderPaypal(){
        if(this.state.connected === false){
            Swal.fire({
                title: 'Connectez-vous !',
                text: "Veuillez vous connecter afin de pouvoir acheter une vidéo.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else{
            this.setState({displayBackdrop:true, disableClickBuy:true})
            return new Promise((resolve, reject) => {
                Axios.post(url+"preBuyConcept",{
                    concept:this.state.conceptDetail
                },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    this.setState({displayBackdrop:false})
                    if(response.data.jwt){
                        sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        })
                    }
                    this.setState({displayBackdrop:false})
                    if (response.data.resultat === "done"){
                        resolve(response.data.orderPaypalID)
                    } else{
                        this.setState({disableClickBuy:false})
                        reject(response.data.resultat)
                        Swal.fire({
                            title: "Erreur !",
                            text: response.data.resultat,
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        })
                    }
                })
            })
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.displayBackdrop}
                    id={"backdropLoading"}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>

                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,height:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            {this.state.displayUI && (
                                <div className={"flexCenter"} style={{width:"100%", height:"100%", display:"flex", alignItems:"flex-start", flexDirection:"column"}}>
                                    <div style={{width:"fit-content", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                        <h1 style={{marginBottom:10}} className={"textShadowBorder"}>{this.state.td === "new" ? "Créer un concept :":this.state.conceptDetail && this.state.conceptDetail.title}</h1>
                                        <div style={{background: "linear-gradient(49deg, rgb(54, 73, 130) 0%, rgb(142, 160, 215) 100%)", width:"100%", height:3, borderRadius:100, marginBottom:20}}></div>
                                    </div>
                                    {this.state.td === "new" ? (
                                        <div className={"flexCenter"} style={{width:"100%", height:"100%", alignItems:"flex-start"}}>
                                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                <h2 style={{width:"100%", textAlign:"left", color:"#364982FF", marginTop:(this.state.phaseCreation !== 4 || this.state.phaseCreation !== 5) && 100}}>Etape {this.state.phaseCreation}</h2>
                                                <div className={"flexCenter"} style={{marginBottom:10, width:450, justifyContent:"space-evenly"}}>
                                                    {[1,2,3,4,5,6,7].map((phase)=>(
                                                        <div onClick={()=>{
                                                            if(phase < this.state.phaseCreation){
                                                                this.setState({phaseCreation:phase})
                                                            }
                                                        }} className={`divEtapeBTOB${phase <= this.state.phaseCreation ? " divEtapeBTOBCompleted":""}` }/>
                                                    ))}
                                                </div>
                                                <div className={"flexCenter"} style={{padding:30, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0 6px 24px 0, rgba(0, 0, 0, 0.08) 0 0 0 1px"}}>
                                                    {this.state.phaseCreation === 1 && (
                                                        <>
                                                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                <h2 style={{fontWeight:100, marginTop:0}}>Entrez le nom de votre concept</h2>
                                                                <TextField style={{fontWeight:100, width:"95%"}} onChange={(e)=>{this.handleChange(e)}} name={"conceptName"} label={"Nom du concept"}/>
                                                            </div>
                                                            <button disabled={this.state.conceptName.length<3} onClick={()=>{this.setState({phaseCreation:2})}} style={{borderRadius:"50%", padding:5, marginLeft:30}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                                                        </>
                                                    )}
                                                    {this.state.phaseCreation === 2 && (
                                                        <>
                                                            {/*<button onClick={()=>{this.setState({phaseCreation:1})}} style={{borderRadius:"50%", padding:5, marginRight:30}} className={"divButtonBtoBDesign flexCenter"}><NavigateBefore style={{margin:0}} /></button>*/}
                                                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                <h2 style={{fontWeight:100, marginTop:0}}>Entrez une description de votre concept</h2>
                                                                <TextField multiline={true} rows={1} rowsMax={5} style={{fontWeight:100, width:"95%"}} onChange={(e)=>{this.handleChange(e)}} name={"conceptDesc"} label={"Description du concept"}/>
                                                            </div>
                                                            <button disabled={this.state.conceptDesc.length<1} onClick={()=>{this.setState({phaseCreation:3})}} style={{borderRadius:"50%", padding:5, marginLeft:30}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                                                        </>
                                                    )}
                                                    {this.state.phaseCreation === 3 && (
                                                        <>
                                                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                <h2 style={{fontWeight:100, marginTop:0}}>Choisissez le mode de vente</h2>
                                                                <div className={"flexCenter"}>
                                                                    <button className={"divButtonBtoBDesign"} style={{background:(!this.state.contractType || false)  && "gray", margin:5}} onClick={()=>{this.setState({contractType:true})}}>Droits d'utilisation</button>
                                                                    <button className={"divButtonBtoBDesign"} style={{background:(this.state.contractType ||this.state.contractType===undefined)&& "gray", margin:5}} onClick={()=>{this.setState({contractType:false})}}>Possession complète</button>
                                                                </div>
                                                                {this.state.contractType === undefined ? (
                                                                    <p style={{fontWeight:100, maxWidth:360, textAlign:"justify"}}>Cliquez pour choisir un mode de vente pour votre concept.</p>
                                                                ):(
                                                                    <>
                                                                        {this.state.contractType ? (
                                                                            <p style={{fontWeight:100, maxWidth:360, textAlign:"justify"}}>Le mode "Droits d'utilisation" vous permet de vendre votre concept à autant de clients vous souhaitez. Vos clients achèteront un droit d'usage mais ne possèdent pas votre concept.</p>
                                                                        ):(
                                                                            <p style={{fontWeight:100, maxWidth:360, textAlign:"justify"}}>Le mode "Possession complète" vous permet de vendre votre concept à un seul client. Ce client possèdera alors entièrement votre concept et vous perdez vos droits sur celui ci.</p>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                            <button disabled={this.state.contractType === undefined} onClick={()=>{this.setState({phaseCreation:4})}} style={{borderRadius:"50%", padding:5, marginLeft:30}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                                                        </>
                                                    )}
                                                    {this.state.phaseCreation === 4 && (
                                                        <>
                                                            <div className={"flexCenter"}>
                                                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                    <h2 style={{fontWeight:100, marginTop:0}}>Déposez des images d'illustration</h2>
                                                                    <p style={{fontWeight:100, maxWidth:300, textAlign:"justify", marginTop:0}}>Ces images apparaîtront sur la page de votre concept et sur le catalogue.</p>
                                                                    <Dropzone multiple={true} onDrop={(acceptedFiles) => {
                                                                        let temp = this.state.allFilesIllus.slice()
                                                                        acceptedFiles.forEach((file)=>{
                                                                            if(file.size >= sizeLimitFile){
                                                                                Toast.fire({
                                                                                    icon: 'error',
                                                                                    title: 'Le fichier est trop lourd (>10MB) !'
                                                                                })
                                                                            }else if(file.name.includes("##")){
                                                                                Toast.fire({
                                                                                    icon: 'error',
                                                                                    title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                                })
                                                                            }else if(acceptedFiles.length + temp.length > 5){
                                                                                Toast.fire({
                                                                                    icon: 'error',
                                                                                    title: 'Vous ne pouvez pas déposer plus de 5 illustrations.'
                                                                                })
                                                                            }else if(!file.type.includes("image")){
                                                                                Toast.fire({
                                                                                    icon: 'error',
                                                                                    title: 'Le fichier uploadé doit être une image !'
                                                                                })
                                                                            }else if (file.name.includes(".zip")){
                                                                                Toast.fire({
                                                                                    icon: 'error',
                                                                                    title: "L'enregistrement de zip n'est pas autorisé."
                                                                                })
                                                                            }else{
                                                                                const reader = new FileReader()
                                                                                reader.onload = () => {
                                                                                    const binaryStr = reader.result
                                                                                    temp.push({
                                                                                        name:file.name,
                                                                                        buffer:Buffer.from(binaryStr,"base64")
                                                                                    })
                                                                                    this.setState({
                                                                                        allFilesIllus:temp
                                                                                    })
                                                                                }
                                                                                reader.readAsArrayBuffer(file)
                                                                            }
                                                                        })
                                                                    }}>
                                                                        {({getRootProps, getInputProps}) => (
                                                                            <section style={{textAlign:"center"}}>
                                                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", justifyContent:"flex-end"}}>
                                                                                    <p style={{margin:0, position:"absolute", top:148, fontWeight:300, right:-18}}>{this.state.allFilesIllus.length}/5</p>
                                                                                </div>
                                                                                <div className={"flexCenter dropzoneBtoB"} {...getRootProps()}>
                                                                                    <input {...getInputProps()} />

                                                                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                                        <h3 style={{textAlign:"center",width:"100%", fontWeight:300}}>Déposez vos illustrations ici</h3>
                                                                                        <p style={{textAlign:"center",width:"100%",fontWeight:300, fontSize:14, margin:2}}>.png ou .jpg de format 1920x1080</p>
                                                                                    </div>
                                                                                </div>
                                                                            </section>
                                                                        )}
                                                                    </Dropzone>
                                                                    <div style={{userSelect: "none", flexWrap:"wrap",maxWidth:400}} className={"flexCenter"}>
                                                                        {this.state.allFilesIllus.map(elt=>(
                                                                            <>
                                                                                <div onClick={()=>{this.removeIllus(elt)}} className={"flexCenter previBtoBIllusConcept"} style={{margin:5, marginTop:15, flexDirection:"column"}}>
                                                                                    <div className={"flexCenter displayDeletePreviBtoBIllusConcept"} style={{position:"relative"}}>
                                                                                        <p style={{margin:0, position:"absolute", top:20}}><Delete style={{opacity:1, color:"red"}}/></p>
                                                                                    </div>
                                                                                    <div className={"flexCenter previBtoBIllusConceptOpacity"} style={{flexDirection:"column"}}>
                                                                                        <img alt={"Document"} style={{maxWidth: 100, maxHeight:75}} src={`data:${"image"+elt.name.split(".")[1]};base64,${Buffer.from(elt.buffer).toString("base64")}`} />
                                                                                        <p style={{margin:0, fontWeight:300}}>{elt.name.length > 15 ? (elt.name.slice(0,6)+"(...)"+elt.name.slice(-6)):(elt.name)}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <button disabled={this.state.allFilesIllus.length<1} onClick={()=>{this.setState({phaseCreation:5})}} style={{borderRadius:"50%", padding:5, marginLeft:30}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                                                            </div>
                                                        </>
                                                    )}
                                                    {this.state.phaseCreation === 5 && (
                                                        <>
                                                            <div className={"flexCenter"}>
                                                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                    <h2 style={{fontWeight:100, marginTop:0}}>Déposez des pièces jointes (optionnel)</h2>
                                                                    <p style={{fontWeight:100, maxWidth:300, textAlign:"justify", marginTop:0}}>Les clients ayant achetés votre concept auront accès à ces fichiers.</p>
                                                                    <Dropzone multiple={true} onDrop={(acceptedFiles) => {
                                                                        let temp = this.state.allFilesAttachments.slice()
                                                                        acceptedFiles.forEach((file)=>{
                                                                            if(file.size >= sizeLimitFile*5){
                                                                                Toast.fire({
                                                                                    icon: 'error',
                                                                                    title: 'Le fichier est trop lourd (>10MB) !'
                                                                                })
                                                                            }else if(file.name.includes("##")){
                                                                                Toast.fire({
                                                                                    icon: 'error',
                                                                                    title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                                })
                                                                            }else if(acceptedFiles.length + temp.length > 5){
                                                                                Toast.fire({
                                                                                    icon: 'error',
                                                                                    title: 'Vous ne pouvez pas déposer plus de 5 pièces jointes.'
                                                                                })
                                                                            }else{
                                                                                const reader = new FileReader()
                                                                                reader.onload = () => {
                                                                                    const binaryStr = reader.result
                                                                                    temp.push({
                                                                                        name:file.name,
                                                                                        buffer:Buffer.from(binaryStr,"base64")
                                                                                    })
                                                                                    this.setState({
                                                                                        allFilesAttachments:temp
                                                                                    })
                                                                                }
                                                                                reader.readAsArrayBuffer(file)
                                                                            }
                                                                        })
                                                                    }}>
                                                                        {({getRootProps, getInputProps}) => (
                                                                            <section style={{textAlign:"center"}}>
                                                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", justifyContent:"flex-end"}}>
                                                                                    <p style={{margin:0, position:"absolute", top:148, fontWeight:300, right:-18}}>{this.state.allFilesAttachments.length}/5</p>
                                                                                </div>
                                                                                <div className={"flexCenter dropzoneBtoB"} {...getRootProps()}>
                                                                                    <input {...getInputProps()} />

                                                                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                                        <h3 style={{textAlign:"center",width:"100%", fontWeight:300}}>Déposez vos pièces jointes ici</h3>
                                                                                        <p style={{textAlign:"center",width:"100%",fontWeight:300, fontSize:14, margin:2}}>Taille de fichier maximum : 50MB</p>
                                                                                    </div>
                                                                                </div>
                                                                            </section>
                                                                        )}
                                                                    </Dropzone>
                                                                    <div style={{userSelect: "none", flexWrap:"wrap",maxWidth:380}} className={"flexCenter"}>
                                                                        {this.state.allFilesAttachments.map(elt=>(
                                                                            <>
                                                                                <div onClick={()=>{this.removeAttach(elt)}} className={"flexCenter previBtoBIllusConcept"} style={{margin:5, marginTop:15, flexDirection:"column"}}>
                                                                                    <div className={"flexCenter displayDeletePreviBtoBIllusConcept"} style={{position:"relative"}}>
                                                                                        <p style={{margin:0, position:"absolute", top:20}}><Delete style={{opacity:1, color:"red"}}/></p>
                                                                                    </div>
                                                                                    <div className={"flexCenter previBtoBIllusConceptOpacity"} style={{flexDirection:"column"}}>
                                                                                        <Description style={{height:45, width:45}}/>
                                                                                        <p style={{margin:0, fontWeight:300}}>{elt.name.length > 15 ? (elt.name.slice(0,6)+"(...)"+elt.name.slice(-6)):(elt.name)}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </>

                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <button onClick={()=>{this.setState({phaseCreation:6})}} style={{borderRadius:"50%", padding:5, marginLeft:30}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                                                            </div>
                                                        </>
                                                    )}
                                                    {this.state.phaseCreation === 6 && (
                                                        <>
                                                            <div style={{display:"flex", alignItems:"center",justifyContent:"center", width:280, marginBottom:10, flexDirection:"column"}}>
                                                                <h2 style={{fontWeight:100, marginTop:0}}>Choisissez la catégorie de votre concept</h2>
                                                                <FormControl required={true} style={{width:"100%"}}>
                                                                    <InputLabel>Catégorie</InputLabel>
                                                                    <Select
                                                                        key="Catégorie"
                                                                        value={this.state.conceptCategorie}
                                                                        variant={"standard"}
                                                                        onChange={(event)=>{
                                                                            this.setState({
                                                                                conceptCategorie:event.target.value
                                                                            })
                                                                        }}
                                                                        style={{width:"100%", color:"black"}}
                                                                        label={"Catégorie"}

                                                                    >
                                                                        {categories.map(cat=>(
                                                                            <MenuItem value={cat}>{cat}</MenuItem>
                                                                        ))}

                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            <button disabled={!this.state.conceptCategorie} onClick={()=>{this.setState({phaseCreation:7})}} style={{borderRadius:"50%", padding:5, marginLeft:30}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                                                        </>
                                                    )}
                                                    {this.state.phaseCreation === 7 && (
                                                        <>
                                                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                <h2 style={{fontWeight:100, marginTop:0}}>Entrez le prix de votre concept</h2>
                                                                <TextField multiline={true} rows={1} rowsMax={5} style={{fontWeight:100, width:"95%"}} onChange={(e)=>{this.handleChangeOnlyNum(e)}} name={"conceptPrice"} label={"Prix d'achat du concept"}
                                                                           InputProps={{
                                                                               endAdornment:
                                                                                   <InputAdornment position="end">
                                                                                       €
                                                                                   </InputAdornment>
                                                                           }}
                                                                />
                                                                <button style={{marginTop:20}} disabled={!this.state.conceptPrice} className={"divButtonBtoBDesign"} onClick={()=>{this.createConcept()}}>Créer le concept</button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ):(
                                        <>
                                            {this.state.conceptDetail !== undefined && (
                                                <div className={"flexCenter"} style={{height:"100%",width:"100%", justifyContent:"flex-start", alignItems:"flex-start", flexDirection:"column", marginTop:20}}>
                                                    <div className={"flexCenter"} style={{width:"100%", height:"100%", alignItems:"flex-start"}}>
                                                        <div className={"flexCenter"} style={{width:"50%", flexDirection:"column", height:"100%", justifyContent:"flex-start", alignItems:"flex-start"}}>

                                                            <div className={"flexCenter"} style={{width:"100%",borderRadius:5,minWidth:400,maxWidth:700, height:"57%",maxHeight:400, borderBottom:"1px solid #d5d5d5", justifyContent:"center", overflow:"hidden", boxShadow:"rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px", backgroundColor:"#d7d7d7"}}>

                                                                {this.state.conceptDetail.mine !== false ? (
                                                                    <img style={{maxWidth:"100%", maxHeight:"100%"}} src={`${url}BtoBConcept?empreinte=${this.state.conceptDetail.owner}&idToken=${this.state.conceptDetail.idToken}&file=${this.state[this.state.conceptDetail.idToken+"_displayIllus"]||1}_ciphered&jwt=${this.state.jwt}`} alt={"illustration"}/>
                                                                ):(
                                                                    <img style={{maxWidth:"100%", maxHeight:"100%"}} src={`${url}BtoBConcept?empreinte=${this.state.conceptDetail.owner}&idToken=${this.state.conceptDetail.idToken}&file=${this.state[this.state.conceptDetail.idToken+"_displayIllus"]||1}_wm`} alt={"illustration"}/>
                                                                )}
                                                                {/*<img style={{width:"100%"}} src={`${url}BtoBConcept?empreinte=${this.state.conceptDetail.owner}&idToken=${this.state.conceptDetail.idToken}&file=${this.state[this.state.conceptDetail.idToken+"_displayIllus"]||1}_wm`} alt={"illustration"}/>*/}
                                                            </div>
                                                            <div className={"flexCenter"} style={{position:"relative", width:"100%",minWidth:400,maxWidth:700}}>
                                                                <div className={"flexCenter"} style={{position:"absolute", width:"100%",justifyContent:"space-between", bottom:180}}>
                                                                    <button onClick={()=>{this.diapoIllus(this.state.conceptDetail,"previous")}} style={{borderRadius:"50%", padding:2, marginLeft:35}} className={"divButtonBtoBDesign flexCenter"}><NavigateBefore style={{margin:0}} /></button>
                                                                    <button onClick={()=>{this.diapoIllus(this.state.conceptDetail,"next")}} style={{borderRadius:"50%", padding:2, marginRight:35}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                                                                </div>
                                                            </div>
                                                            <div style={{position:"relative", width:"100%",minWidth:400,maxWidth:700}}>
                                                                <div className={"flexCenter"} style={{position:"absolute", width:"100%", bottom:10}}>
                                                                    <div className={"flexCenter"} style={{justifyContent:"space-around", width:"fit-content"}}>
                                                                        {Array.from(Array(this.state.conceptDetail.numberImages).keys()).map((bubble)=>(
                                                                            <div onClick={()=>{clearInterval(IntervalDiapo);this.setState({[this.state.conceptDetail.idToken+"_displayIllus"]:bubble+1})}} style={{margin:"0 25px 0 25px",backgroundColor:((parseInt(this.state[this.state.conceptDetail.idToken+"_displayIllus"])||1) === bubble+1) && "#364982"}} className={`divBubbleBtoB`}/>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={"flexCenter"} style={{width:"100%", marginTop:15, flexDirection:"column", alignItems:"flex-start"}}>
                                                                <h4 onClick={()=>{this.props.navigate("/BtoBDesign/Profil?td="+this.state.conceptDetail.owner)}} className={"flexCenter"} style={{marginBottom:5, marginTop:5, width:"fit-content", cursor:"pointer"}}>
                                                                    <Avatar
                                                                        variant={"circular"}
                                                                        src={`${url}images/avatar/${this.state.conceptDetail.owner}.png`}
                                                                        alt={this.state.conceptDetail.pseudo && this.state.conceptDetail.pseudo.toUpperCase()}
                                                                        style={{width:40, height:40, fontSize:18, marginRight:5, marginBottom:10}}
                                                                    />
                                                                    {this.state.conceptDetail.pseudo}
                                                                </h4>
                                                                {/*<h2 style={{fontWeight:100, margin:5, marginLeft:0}}>Description</h2>*/}
                                                                <div style={{border:"1px solid", marginLeft:0, borderColor:"#364982",borderRadius:10, width :"fit-content"}}>
                                                                    <p style={{margin:0,fontWeight:100, padding:3}}>
                                                                        {this.state.conceptDetail.categorie || "inconnu"}
                                                                    </p>
                                                                </div>
                                                                <p style={{fontSize:15, fontWeight:400, margin:0, marginTop:5}}>{this.state.conceptDetail.desc}</p>
                                                            </div>
                                                        </div>

                                                        <div className={"flexCenter"} style={{width:"50%", flexDirection:"column"}}>
                                                            <div className={"flexCenter"} style={{width:"50%",justifyContent:"space-between",flexDirection:"column",borderRadius:5, height:"fit-content", boxShadow:"rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"}}>
                                                                {this.state.displayButtonPaypal ? (
                                                                    <React.Fragment>
                                                                        <h2 style={{margin:10, marginBottom:0, fontWeight:600}}>Payez avec PayPal ou par carte bancaire :</h2>
                                                                        <div className={"flexCenter"}>
                                                                            <p style={{fontSize:24, fontWeight:600, margin:0}}>{this.state.conceptDetail.price}</p>
                                                                            <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                                        </div>
                                                                        <p style={{fontSize:14, fontWeight:600, margin:0}}>{this.state.conceptDetail.contrat ==="recurrent" ? "Droits d'utilisation":"Possession complète"}</p>
                                                                        <PayPalScriptProvider options={{ "client-id": paypalPublicKey, currency: "EUR" }}>
                                                                            <PayPalButtonBTOB onCreateOrder={this.createOrderPaypal} onApproveOrder={()=>{this.setState({displayBackdrop:true})}}/>
                                                                        </PayPalScriptProvider>
                                                                    </React.Fragment>
                                                                ):(
                                                                    <React.Fragment>
                                                                        <h2>Acheter ce concept</h2>
                                                                        <div className={"flexCenter"} style={{margin:10, width:"100%", flexDirection:"column"}}>
                                                                            <div className={"flexCenter"}>
                                                                                <p style={{fontSize:24, fontWeight:600, margin:0}}>{this.state.conceptDetail.price}</p>
                                                                                <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                                            </div>
                                                                            <p style={{fontSize:14, fontWeight:600, margin:0}}>{this.state.conceptDetail.contrat ==="recurrent" ? "Droits d'utilisation":"Possession complète"}</p>
                                                                            {this.state.conceptDetail.contrat ==="recurrent" ? (
                                                                                <p style={{fontWeight:100, maxWidth:"85%", textAlign:"justify"}}>Le contrat "Droits d'utilisation" vous permet d'utiliser ce concept à des fins commerciales. Vous n'avez cependant pas l'exclusivité d'utilisation.</p>
                                                                            ):(
                                                                                <p style={{fontWeight:100, maxWidth:"85%", textAlign:"justify"}}>Le contrat "Possession complète" vous permet de posséder entièrement ce concept. Seul vous pourrez utiliser ce concept et il vous sera possible de le revendre ou de l'échanger.</p>
                                                                            )}

                                                                        </div>
                                                                        <div className={"flexCenter"} style={{width:"100%", marginBottom:5, justifyContent:"space-evenly"}}>
                                                                            <button disabled={this.state.disableClickBuy} onClick={()=>{this.setState({displayButtonPaypal:true})}} className={"divButtonBtoBDesign flexCenter"}>Acheter ce concept <ShoppingCart style={{marginLeft:5}}/> </button>
                                                                            <button className={"divButtonBtoBDesign flexCenter"}><ContactSupport/></button>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}

                                                            </div>
                                                            {this.state.conceptDetail.mine && (
                                                                <button disabled={this.state.conceptDetail.attachments === 0} onClick={()=>{window.open(`${url}BtoBConcept?empreinte=${this.state.conceptDetail.owner}&idToken=${this.state.conceptDetail.idToken}&file=attachments&jwt=${this.state.jwt}`)}} style={{marginTop:20}} className={"divButtonBtoBDesign"}>Télécharger les documents du concept</button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Concepts)