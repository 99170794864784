import React, {Component} from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip
} from '@mui/material';
import {isBrowser} from "react-device-detect";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import config from "../../../json/config.json";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import tooltipContenu from "../../../json/contenu.json";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let pseudo, hash, jwt;

let jwtDecoded

class Statistiques extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pointer:'',
            marginLeftContact:20,
            marginRightContact:10,
            statsContentWidth:"95%",
            statsContentMinWidth:"0%",
            statsContentMaxWidth:"100%",
            marginTopContent:0
        };
    }

    componentDidMount() {
        this.setState({
            jwt: sessionStorage.getItem("EKOCO_jwt"),
        }, () => {
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url + "verifyJwt", {}, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
                if (response.data.jwt) {
                    sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    }, () => {
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true) {
                    this.setState({
                        pseudo: jwtDecoded.pseudo
                    }, () => {
                        this.requeteAxios();
                    })
                } else {
                    this.props.navigate('/Ekoco/BasCarboneBlockchain', { replace: true })
                }
            })
        })
        if (isBrowser){
            this.setState({
                displayed:false,
                marginLeftContact:50,
                marginRightContact:100,
                statsContentWidth:"50%",
                statsContentMinWidth:400,
                statsContentMaxWidth:450,
                marginTopContent:50,
            })
        }
    }


    requeteAxios(){
        Axios.post(url+"BCBAccueil", {},{headers:{Authorization:"Bearer " + this.state.jwt}
        }).then(response => {
            if (response.data.resultat !== "Utilisateur introuvable."){
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
                    jwt=response.data.jwt
                }
                let token = response.data.token.replace(".",",")
                let temps = response.data.temps;
                let tempsH = parseInt(temps/60), tempsMin = parseInt(temps%60);
                this.setState({
                    token:token,
                    km:response.data.km,
                    tempsH:tempsH,
                    tempsMin:tempsMin,
                    co2:response.data.co2,
                    argent:response.data.argent
                })
                global.emitter.emit("onRouteChangedEkoco",{
                    page:'Accueil'
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    hideContact(){
        this.setState({
            displayed:false
        })
    }

    displayContact(){
        this.setState({
            displayed:true,
        })
    }

    handleChange=(event) => { //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        })
    }

    handleSubmitContact(event){
        event.preventDefault();
        const data= new FormData(event.target);
        let emailSender = data.get('champsEmail'), sujet = data.get('champsSujet'), message = data.get('champsMessage');
        this.setState({
            openLoad:true
        })
        Axios.post(url+"contact", {
            email : emailSender,
            sujet : sujet,
            message : message,
        }).then(response => {
            this.setState({
                openLoad:false
            })
            Swal.fire({
                title: 'Succès!',
                text: response.data,
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            this.handleCloseDialog()
        }).catch(() => {
            this.setState({
                openLoad:false
            })
            Swal.fire({
                title: 'Erreur!',
                text: "Erreur lors de l'envoie de l'email.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        });
    }

    handleCloseDialog(){
        this.setState({displayed:false})
    }

    sendFooterLink(e){
        this.props.navigate(
            {
                pathname: '/ekoco/BasCarboneBlockchain',
                link: e.target.id
            }
        )
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>
                <Dialog
                    open={this.state.displayed}
                    onClose={()=>{this.setState({displayed:false})}}
                >
                    <DialogTitle>Contactez nos administrateurs <span style={{color:"#6abfa2", fontWeight:"bold"}}>Ekoco</span> !</DialogTitle>
                    <form onSubmit={(e)=>{this.handleSubmitContact(e)}}>
                        <DialogContent>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsEmail"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} id="outlined-basic champsClef" required={true} type={"email"} label="Entrez votre email" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmailContact}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsSujet"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} id="outlined-basic champsPseudo" required={true} label="Entrez votre sujet" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipSujet}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsMessage"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} multiline={true} rows={6} rowsMax={10} id="outlined-basic champsPrenom" required={true} label="Entrez un message" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipMessage}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <Button style={{padding:"10px", width:"100%",backgroundColor:"#5ecaab",color:"white"}} variant={"outlined"} type={"submit"} >Envoyer</Button>

                        </DialogContent>
                    </form>
                </Dialog>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end"}}>
                    <p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold"}}>{this.state.pseudo}</p>
                    <AccountCircleIcon style={{color:"#b0a9a8"}}/>
                    <Button onClick={()=>{this.displayContact()}} style={{marginLeft:this.state.marginLeftContact, marginRight:this.state.marginRightContact, backgroundColor:"#5ecaab", color:"white", padding:"10px 30px", fontWeight:"bold", zIndex:2}}>Contact</Button>
                </div>
                {/*CONTENT*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)"}}>
                    {isBrowser === true ? (
                        <div style={{display:"flex",flex:3, justifyContent:"flex-end", alignItems:"flex-end", zIndex:2}}>
                            <img style={{width:261, height:450}} src={require("../assetsEkoco/STATISTIQUE1.png")} alt={"Statistiques"}/>
                        </div>
                    ):("")}

                    <div style={{marginTop:this.state.marginTopContent,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",flex:2}}>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <p style={{fontWeight:"bold", fontSize:39, marginBottom:0}}>Regardez</p>
                            <p style={{fontWeight:"bold", fontSize:39, marginTop:0}}>vos statistiques !</p>
                        </div>
                        <div style={{backgroundColor:"#e39e35", width:this.state.statsContentWidth, minWidth:this.state.statsContentMinWidth, maxWidth:this.state.statsContentMaxWidth, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:30, paddingTop:30, borderRadius:10}}>
                            <p style={{color:"white",fontWeight:"bold", fontSize:30, margin:0}}>Capital tokens</p>
                            <p style={{color:"white",fontWeight:"bold", fontSize:40, margin:0,marginBottom:5}}>{this.state.token}</p>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex", flex:1}}>
                                    <img style={{width:50, height:50}} src={require("../assetsEkoco/DUREE_ACTIVITE_APPLI MOBILE.png")} alt="Temps"/>
                                </div>
                                <div style={{display:"flex", flex:5,flexDirection:"column"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold"}}>Durée d'activité</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                        <p style={{color:"#5ecaab", fontWeight:"bold", fontSize:30, margin:0}}>{this.state.tempsH}h{this.state.tempsMin}mn</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex",flex:1, width:50}}>
                                    <img style={{width:42,height:90, marginTop:5, marginBottom:2, marginLeft:6}} src={require("../assetsEkoco/DISTANCE_PARCOURUE_APPLI MOBILE.png")} alt="DISTANCE"/>
                                </div>
                                <div style={{display:"flex",flex:5, flexDirection:"column"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold"}}>Distance parcourue</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                        <p style={{color:"#5ecaab", fontWeight:"bold", fontSize:30, margin:0}}>{this.state.km}km</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex", flex:1, justifyContent:"center", marginTop:6}}>
                                    <img style={{width:50, height:36, margin:2, marginLeft:5, marginTop:1}} src={require("../assetsEkoco/CO2_APPLI MOBILE.png")} alt="CO2"/>
                                </div>
                                <div style={{display:"flex", flex:5,flexDirection:"column"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold"}}>CO2 total</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                        <p style={{color:"#5ecaab", fontWeight:"bold", fontSize:30, margin:0}}>{this.state.co2}g</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex", flex:1, justifyContent:"center", marginTop:6}}>
                                    <EuroSymbolIcon style={{width:40, height:40, color:"#2e4a5a"}}/>
                                </div>
                                <div style={{display:"flex", flex:5,flexDirection:"column"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold"}}>Argent gagné</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                        <p style={{color:"#5ecaab", fontWeight:"bold", fontSize:30, margin:0}}>{this.state.argent}€</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isBrowser === true ? (
                        <div style={{display:"flex",flex:3}}>
                            <img style={{width:450, height:419}} src={require("../assetsEkoco/STATISTIQUE2.png")} alt={"Statistiques"}/>
                        </div>
                    ):("")}
                </div>

                {/*FOOTER*/}
                <div style={{width:"100%", height:"100%",paddingTop:10, backgroundColor:"#f5f6f6", display:"flex",flexWrap:"wrap", marginTop:50, justifyContent:"center", alignItems:"center", zIndex:4}}>
                    <div style={{width:"90%", height:"90%",display:"flex", flexWrap:"wrap"}}>
                        {isBrowser === true ? (
                            <div style={{display:"flex", flex:3, width:250, height:250, justifyContent:"center", alignItems:"center"}}>
                                <img style={{width:285,height:200, textAlign:"center"}} src={require("../assetsEkoco/Ekoco.png")} alt="LogoEkoco"/>
                            </div>
                        ):("")}
                        <div style={{display:"flex", flex:3, flexDirection:"column", justifyContent:"center"}}>
                            <h1 className={"arialExtraBold"} style={{margin:0,color:"#2c2e35"}}>L’application qui fait</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>DU BIEN</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>À LA PLANÈTE</h1>
                        </div>
                        <div style={{display:"flex",flex:4}}>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=cestQuoi")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO c’est quoi ?</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=commentCaMarche")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Comment ça marche ?</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=reporting")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Un reporting détaillé</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=pourEntreprise")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO pour les entreprises</p>
                            </div>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/PolitiqueDeConfidentialite")}}>Politique de confidentialité</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/ConditionsGeneralesDutilisation")}}>CGU</p>
                                <p className={"arialArrow"} onClick={()=>{this.displayContact()}} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Contacts</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Utilisation des cookies</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Le mail va être envoyé dans quelques instants ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default withRouter(Statistiques);