import React, {Component} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {withRouter} from '../shared/NavigationUtils';
import Axios from "axios";
import config from "../json/config.json";

import Swal from "sweetalert2";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import {AddBoxOutlined} from "@mui/icons-material";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const columns = [
    { id: 'name', label: 'URL des nodes', minWidth: 300 },
];

let jwtDecoded;

class ListeNodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueListNodes:[],
            connected:false,
            isSure:false,
            addNodeDialog:false,
            stateDrawer:true
        };
    }

    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt", {},
                {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if (response.data.resultat !== true){
                    this.props.navigate('/Administration/ConnexionAdmin')
                }else{
                    if(response.data.jwt){
                        sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:jwtDecoded.pseudo
                    })
                    global.emitter.emit("onRouteChanged",{
                        pageName:"Liste des nodes",
                        pseudo:jwtDecoded.pseudo,
                    })
                    if (jwtDecoded.admin){
                        this.setState({
                            connected:true
                        }, ()=>{
                            this.requeteAxiosGetNodes()
                        })
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Vous n'êtes pas autorisé à accéder à cette page",
                            icon: 'error',
                            target:".MuiDialog-root",
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/Administration/AccueilAdmin")
                        })
                    }
                }
            })
        })
    }


    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    requeteAxiosGetNodes(){
        Axios.post(url+"getNodesForFront").then(response => {
            this.setState({
                valueListNodes : response.data.list,
                isMasterNode:response.data.isMasterNode
            })
        }).catch(error => {
            console.log(error);
        });
    }

    sendNewNodesList(action, datas){
        if(this.state.connected && this.state.isMasterNode){
            Axios.post(url+"modifNodesList", {
                valueListNodes : this.state.valueListNodes,
                action : action,
                datas : datas,
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if(response.data.resultat !== 'La liste des nodes a bien été modifiée.'){
                    let newArray = []
                    this.state.valueListNodes.forEach(elt => {
                        if (elt.name !== datas){
                            newArray.push(elt)
                        }
                    })
                    if (action === "add"){newArray.push({name:datas})}
                    this.setState({valueListNodes:newArray})

                    Toast.fire({
                        title: response.data.resultat,
                        icon: 'error',
                    })
                }
                else {
                    this.requeteAxiosGetNodes()
                    Toast.fire({
                        title: "La liste des nodes a bien été modifiée!",
                        icon: 'success',
                    })
                }
            })
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Vous n'êtes pas autorisé à accéder à réaliser cette action",
                icon: 'error',
                target:".MuiDialog-root",
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.props.navigate("/Administration/AccueilAdmin")
            })
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.isSure}
                    onClose={()=>{this.setState({isSure:!(this.state.isSure)})}}
                >
                    <DialogTitle style={{fontWeight:"bold"}}>Attention !</DialogTitle>
                    <DialogContent>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <p style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Êtes vous sûr(e) de vouloir supprimer ce node de la liste ?</p>
                            <div style={{display:"flex", width:"100%", justifyContent:"center", marginTop:0, marginBottom:10}}>
                                <Button style={{backgroundColor:"white", margin:10}} variant={"contained"} name={'Oui'} onClick={(e)=>{this.sendNewNodesList("delete",this.state.selectedNode);this.setState({isSure:false})}}>Oui</Button>
                                <Button style={{backgroundColor:"white", margin:10}} variant={"contained"} name={'Non'} onClick={()=>{this.setState({isSure:false})}}>Non</Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.addNodeDialog}
                    onClose={()=>{this.setState({addNodeDialog:!(this.state.addNodeDialog)})}}
                >
                    <DialogTitle style={{fontWeight:"bold"}}>Ajout d'un node :</DialogTitle>
                    <DialogContent>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <p style={{display:"flex", alignItems:"center", justifyContent:"center"}}>Entrez le nom de domain du node (ex: ekoco.fr)</p>
                            <TextField name={"champsNewNode"} type={"text"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}}  id="outlined-basic champsPrenom" required={true} label="URL du node" variant="outlined" />

                            <div style={{display:"flex", width:"100%", justifyContent:"center", marginTop:0, marginBottom:10}}>
                                <Button style={{backgroundColor:"white", margin:10}} variant={"contained"} name={'Oui'} onClick={(e)=>{this.sendNewNodesList("add",this.state.champsNewNode);this.setState({addNodeDialog:false})}}>Ajouter</Button>
                                <Button style={{backgroundColor:"white", margin:10}} variant={"contained"} name={'Non'} onClick={()=>{this.setState({addNodeDialog:false})}}>Retour</Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                    <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                        <div style={{overflowY:"auto",minHeight:"300px",backgroundColor:"white",display:"flex",flexDirection:"column",alignContent:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                            <div style={{padding:20}}>
                                <div style={{ maxWidth: "100%", display:"flex", justifyContent:"center",alignItems:"center"}}>
                                    <TableContainer sx={{ maxHeight: 440 , overflow:"auto"}}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            <div style={{display:"flex",alignItems:"center"}}>
                                                                {column.label}
                                                                {this.state.isMasterNode && (
                                                                    <div style={{position:"relative"}}>
                                                                        <Tooltip arrow={true} title={"Ajouter un node"}>
                                                                            <AddBoxOutlined onClick={()=>{this.setState({addNodeDialog:true})}} style={{cursor:"pointer",transform:"scale(1.2)", marginLeft:10}}/>
                                                                        </Tooltip>
                                                                    </div>

                                                                )}
                                                            </div>
                                                        </TableCell>
                                                    ))}

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.valueListNodes.map(node => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={node.name}>
                                                            <TableCell key={node.name}>
                                                                <div style={{display:"flex",alignItems:"center"}}>
                                                                    {this.state.isMasterNode && (
                                                                        <Tooltip arrow={true} title={"Supprimer ce node"}>
                                                                            <DeleteIcon style={{marginRight:10, cursor:"pointer"}} onClick={()=>{this.setState({selectedNode:node.name,isSure:true})}}/>
                                                                        </Tooltip>
                                                                    )}
                                                                    {node.name}
                                                                </div>

                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                        {!this.state.valueListNodes.length>0 && (
                                            <div style={{width:"100%"}}>
                                                <Skeleton style={{margin:20,marginTop:40}}/>
                                                <Skeleton style={{margin:20}} />
                                                <Skeleton style={{margin:20}} />
                                            </div>
                                        )}
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ListeNodes)