import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';

import Axios from "axios";
import config from "../json/config.json";

import {Button, IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import {sha256} from 'js-sha256';
import tooltipContenu from "../json/contenu.json";
import {HelpOutline, Visibility, VisibilityOff} from "@mui/icons-material";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
let empreinte,jwtDecoded = sessionStorage.getItem("SPURO_jwt") ? jwt_decode(sessionStorage.getItem("SPURO_jwt")) : {}

class InvaliderMonCompte extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phase:1,
            champsVerifSuppression:"",
            stateDrawer:true
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        },()=>{
            Axios.post(url+"verifyJwt", {},
                {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat !== true){
                    this.props.navigate('/ConnexionAdmin')
                }else{
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:jwtDecoded.pseudo
                    })
                    global.emitter.emit("onRouteChanged",{
                        pageName:'Invalidation du compte',
                        pseudo:jwtDecoded.pseudo,
                    })
                }
            })
        })
    }
    handleSubmitPhase1(event){
        event.preventDefault()
        empreinte = sha256(this.state.champsPseudo.concat(this.state.champsClef))
        Axios.post(url+"connexionInvalidate", {
            empreinte : empreinte,
        },{headers:{Authorization:"Bearer " + "jwtBidon"}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
            }
            if (response.data.resultat === "Cet utilisateur n'existe pas."){
                Swal.fire({
                    title: 'Erreur!',
                    text: "Cet utilisateur n'existe pas.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }else{
                this.setState({
                    phase:2,
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Connecté au compte "'+this.state.champsPseudo+'"'
                })
            }
        })
    }
    handleSubmitPhase2(event){
        event.preventDefault()
        let validation = (this.state.champsVerifSuppression).toLocaleLowerCase()
        if (validation === "invalider"){
            Axios.post(url+"deleteAccount", {
                empreinte : empreinte,
            },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwtClient")}}).then((response)=>{
                if(response.data.resultat === "Cet utilisateur n'existe pas."){
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Cet utilisateur n'existe pas.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        window.location.reload()
                    })
                }else{
                    Swal.fire({
                        title: 'Succès!',
                        text: 'Compte "'+this.state.champsPseudo+'" correctement invalidé.',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        sessionStorage.removeItem('SPURO_jwtClient')
                        this.props.navigate("/ConnexionAdmin")
                    })
                }
            })
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "La validation n'a pas correctement été remplie",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto",minHeight:"285px", width:600,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        {this.state.phase === 1 ? (
                            <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase1(e)}} autoComplete="off">
                                <h1>Connectez vous à votre compte</h1>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsPseudo"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez votre identifiant."} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Identifiant" variant="outlined"/>
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutline style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField autoComplete="new-password" type={this.state.showPassword ? "text":"password"} name={"champsClef"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez votre Clef Unique."} onChange={(e)=>{this.handleChange(e)}} id="champsClef" label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }}
                                    />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutline style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button type={"submit"} variant="contained">Suivant</Button>
                            </form>
                        ):("")}
                        {this.state.phase === 2 ? (
                            <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase2(e)}} autoComplete="off">
                                <h3>Invalidation de compte :</h3>
                                <p>Entrez "INVALIDER" dans le champs de texte pour confirmer votre action.</p>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsVerifSuppression"} style={{width:200}} required={true} value={this.state.value} placeholder={"INVALIDER"} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Validation" variant="outlined"/>
                                </div>
                                <Button style={{margin:10}} type={"submit"} variant="contained">Invalider mon compte</Button>
                            </form>
                        ):("")}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(InvaliderMonCompte)