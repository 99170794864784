import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';

import Axios from "axios";
import config from "../json/config.json";

import Swal from "sweetalert2";
import LogoSpuro from "./components/LogoSpuro";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let link, clientOrAdmin, app;

class RecupCodeClefUnique extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pseudo:"",
            encryptedKey:"",
            clientOrAdmin:""
        };
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        link = queryParams.get("link")
        clientOrAdmin = queryParams.get("s") === "C" ? "Client" : "Admin"
        app = queryParams.get("app")
        setTimeout(()=>{(
            Axios.post(url+"verifyBufferLink"+clientOrAdmin, {
                link:link
            }).then(response=>{
                if(response.data.resultat === "no authorized"){
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Ce lien a déjà été utilisé.",
                        icon: 'error',
                        target:".MuiDialog-root",
                        confirmButtonText: 'Ok'
                    })
                }else{
                    this.setState({
                        pseudo:response.data.pseudo,
                        encryptedKey:response.data.encryptedKey
                    },()=>{
                        this.downloadPdf(response.data.pseudo,response.data.encryptedKey, app)
                    })
                }
            })
        )},300)
    }

    downloadPdf(pseudo,encryptedKey, app){
        Axios.post(url+"createPDFCodeRecup",{pseudo:pseudo,encryptedKey:encryptedKey, app:app},{responseType:"arraybuffer"}).then((response)=>{
            let file = new Blob([response.data], {type: 'application/pdf'});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = "code_"+(Math.random() + 1).toString(36).substring(3)+".pdf";

            link.click();
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    render() { //Affichage sur la page
        const { classes } = this.props;
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{height: 70, width:"100vw", backgroundColor:"#337ab7", textAlign:"center", boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)', zIndex:10}}>
                    <LogoSpuro/>
                    <h2 style={{color:"white"}}>Téléchargement du pdf de récupération</h2>
                </div>
                <div style={{height:"calc(100vh - 70px)",backgroundColor:"#72aacc",width:"100%", display:"flex",justifyContent:"center",alignItems:"center", justifyItems:"center", overflow:"auto"}}>
                    <div style={{overflowY:"auto",minHeight:"285px", width:600,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <h2 style={{marginBottom:20}}>Bonjour {this.state.pseudo} !</h2>
                        <p style={{padding:20, paddingBottom:5}}>Nous allons enregistrer un pdf sur votre ordinateur.</p>
                        <p style={{padding:20, paddingTop:5}}>Afin de garder votre code en sécurité, veuillez accepter le téléchargement du pdf contenant toutes les informations nécéssaires sur votre ordinateur. Veillez aussi à ne pas le laisser à la vue de tous.</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RecupCodeClefUnique)