import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {Dialog, DialogContent, DialogTitle, TextField, Tooltip} from '@mui/material';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import {Security} from "@mui/icons-material";
import jwt_decode from "jwt-decode";

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Design","Développement","Marketing","Informatique et logiciels","Développement personnel","Business","Photographie","Musique","Divers"
]
const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let socketIO; //Initialisation de l'instance du socket
let jwtDecoded;

class ModerationAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            videosToVerify:[],
        };
    }

    setInitialStates(){
        return{
            selectedVideo:{},
            banTime:""
        }
    }

    componentDidMount() {
        this.setState({
            jwt:sessionStorage.getItem("BUYRESALE_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    console.log(jwtDecoded)
                    if(jwtDecoded.admin !== true){
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Vous n'êtes pas autorisé à accéder à cette page.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            window.location.replace("/BuyResale/Accueil")
                        })
                    }else{
                        global.emitter.emit("onRouteChangedBuyResale", {
                            jwt: this.state.jwt,
                        })
                        this.getVideosToVerify()
                    }
                }else{
                    Swal.fire({
                        title: 'Veuillez vous reconnecter',
                        text: "Votre session a expiré, veuillez vous reconnecter.",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        sessionStorage.removeItem("BUYRESALE_jwt")
                        global.emitter.emit("onRouteChangedBuyResale", {
                            jwt: null,
                        })
                        window.location.replace("/BuyResale/Accueil")
                    })
                }
            })
        })
    }

    getVideosToVerify(){
        Axios.post(url+"getVideosToVerify",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            console.log(response.data)
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "done"){
                this.setState({videosToVerify:response.data.videos})
            }
        })
    }

    verifyVideo(action){
        let dataAxios = {
            action,
            fileName:this.state.selectedVideo.fileName,
            empreinteOwner:this.state.selectedVideo.empreinte,
            owner:this.state.selectedVideo.owner,
            banTime:this.state.banTime,
            hashFile:this.state.selectedVideo.hashFile,
            idToken:this.state.selectedVideo.idToken
        }
        Axios.post(url+"verifyVideo",dataAxios,{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "done"){
                Toast.fire({
                    icon: 'success',
                    title: 'Vidéo traité avec succès.'
                })
                let videosToVerifyTemp = this.state.videosToVerify.slice()
                videosToVerifyTemp.splice(videosToVerifyTemp.findIndex(elt => elt.hashFile === this.state.selectedVideo.hashFile), 1)
                this.setState({videosToVerify:videosToVerifyTemp,selectedVideo:{}})
            }else{
                this.setState({selectedVideo:{}})
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={Object.keys(this.state.selectedVideo).length > 0}
                    onClose={()=>{this.setState({selectedVideo:{}})}}
                    id={"dialogDetailVideo"}
                >
                    <DialogTitle>{this.state.selectedVideo.titre && (this.state.selectedVideo.titre.length>70 ? (this.state.selectedVideo.titre.slice(0,65)+"..."):(this.state.selectedVideo.titre))}</DialogTitle>
                    <DialogContent id={"dialogDetailVideo"} style={{maxWidth:"40vw"}}>
                        <video preload={"metadata"} controls>
                            <source
                                src={`${this.state.selectedVideo.srvDoc}videos${!this.state.selectedVideo.verified ? "Tampon":""}/${this.state.selectedVideo.owner}/${this.state.selectedVideo.fileName && this.state.selectedVideo.fileName.replaceAll("##","pourcentdiese")}?jwt=${this.state.jwt}`}
                                type={`video/${this.state.selectedVideo.fileName && (this.state.selectedVideo.fileName.split(".")[this.state.selectedVideo.fileName.split(".").length-1])}`}/>
                            Désolé, votre navigateur ne permet pas de lire des vidéos.
                            <a href={`${this.state.selectedVideo.srvDoc}videos${!this.state.selectedVideo.verified ? "Tampon":""}/${this.state.selectedVideo.owner}/${this.state.selectedVideo.fileName && this.state.selectedVideo.fileName.replaceAll("##","pourcentdiese")}?jwt=${this.state.jwt}`}
                               target={"_blank"}
                            >
                                un lien pour télécharger la vidéo
                            </a>
                        </video>
                        <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                            <div style={{border:"1px solid", borderColor:"red",borderRadius:10, width :"fit-content"}}>
                                <h3 style={{margin:3,color:"black"}}>{this.state.selectedVideo.categorie}</h3>
                            </div>
                            <div className={"flexCenter"} style={{width:"fit-content"}}>
                                <div style={{borderRadius:10, width :"fit-content"}}>
                                    <h4 style={{margin:3,color:"black", fontWeight:"normal"}}>Prix unitaire : <span style={{fontWeight:"bolder"}}>{this.state.selectedVideo.prixU}€</span></h4>
                                </div>
                                {this.state.selectedVideo.prixF !=="notAllowed" && (
                                    <div style={{borderRadius:10, width :"fit-content"}}>
                                        <h4 style={{margin:3,color:"black", fontWeight:"normal"}}>Prix fournisseur : <span style={{fontWeight:"bolder"}}>{this.state.selectedVideo.prixF}€</span></h4>
                                    </div>
                                )}
                            </div>

                        </div>
                        <h4 style={{fontWeight:"normal",margin:"25px 0 25px 0", width:"100%", maxHeight:130, overflowY:"auto"}}>{this.state.selectedVideo.desc}</h4>

                        <div className={"flexCenter"} style={{width:"100%"}}>
                            <button onClick={()=>{this.verifyVideo("accept")}} className={"divButtonBuyResale"} style={{backgroundImage: "linear-gradient(to right, #41ff00 0%, #34cb00 51%, #41ff00 100%)", margin:5, padding:18}}>Valider</button>
                            <button onClick={()=>{this.verifyVideo("refuse")}} className={"divButtonBuyResale"} style={{backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)", margin:5, padding:18}}>Refuser</button>
                            <TextField style={{marginLeft:10}} variant={"outlined"} value={this.state.banTime} name={"banTime"} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label={"Durée du ban"} placeholder={"Pas de ban par défaut"} InputLabelProps={{ shrink: true }}></TextField>
                            {/*<Button>Bannir</Button>*/}
                        </div>
                    </DialogContent>
                </Dialog>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Vidéos non vérifiées :</h1>
                                <div style={{background: "linear-gradient(49deg, rgba(114,170,204,1) 0%, rgba(250,182,3,1) 100%)", width:330, height:3, borderRadius:100, marginBottom:20}}></div>
                            </div>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                {this.state.videosToVerify.length !== 0 ? (
                                    <>
                                        {this.state.videosToVerify.map(vid=>(
                                            <div onClick={()=>{this.setState({selectedVideo:vid})}} className={"divMyVideo"} style={{flexDirection:"column", background: "rgba(0,0,0,.5)",display:"flex", alignItems:"center",justifyContent:"flex-start", overflow:"hidden", border:!vid.verified && "2px solid #ff8100"}}>
                                                <div style={{width:'100%', position:"relative"}}>
                                                    <div style={{height:31, textAlign:"center",position:"absolute", zIndex:999, width:"100%", background: "rgba(0,0,0,.6)"}}>
                                                        <h4 style={{margin:5, textAlign:"center", color:"white"}} >{vid.titre.length>32 ? (vid.titre.slice(0,28)+"..."):(vid.titre)}</h4>
                                                    </div>
                                                </div>
                                                <div style={{width:'100%', position:"relative"}}>
                                                    {!this.state.selectedVideo.verified && (
                                                        <div style={{position:"absolute", padding:2, zIndex:9999,borderRadius:10, width :"fit-content", marginBottom:10}}>
                                                            <Tooltip title={"Cette vidéo est en cours de vérification par notre équipe de modérateur, elle n'est pas visible sur le catalogue."}>
                                                                <Security style={{color:"#ff8100"}}/>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </div>
                                                <div style={{overflow:"hidden", width:"100%",height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <img style={{width:"100%", position:"absolute"}} src={`${vid.srvDoc}images/miniatures/${vid.idToken}.png`} alt={`${vid.fileName.split("##")[1]}`}/>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ):(
                                    <h2 style={{marginBottom:0}}>Il n'y a aucune vidéo à modérer pour le moment ...</h2>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModerationAdmin)