import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Axios from "axios";
import {Backdrop, CircularProgress, Tooltip} from "@mui/material";
import jwt_decode from "jwt-decode";
import {ArrowRightAlt, Business, ExpandMore, Person} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let columns = [
    { id: 'name', label: 'Name', minWidth: 10 },
    { id: 'image', label: 'Image', minWidth: 10 },
    { id: 'year', label: 'Year', minWidth: 10 },
    { id: 'volume', label: 'Volume', minWidth: 10 },
    { id: 'action', label: 'Action', minWidth: 10 },
];

let jwtDecoded;

class RecapTransacSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            addDialog:false,
            creationMode:1,
            brands:[],
            packs:[],
            randNumber:0,
            bottleInPack:[],
            oldBottleInpack:[],
            packPhase:1,
            displayEditImage:false,
            oldChampsNumberTickets:null,
            transacRecap:[]
        };
    }
    _emitConnectedSMSTO;
    componentWillUnmount(){
        this._emitConnectedSMSTO.remove()
        window.removeEventListener('resize', this.resizePage)
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedSMSTO = global.emitter.addListener("emitConnectedSMSTO",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("SMSTO_jwt")}, ()=>{
                global.emitter.emit("onRouteChangedStockMarketSTO", {
                    jwt: this.state.jwt,
                })
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                },()=>{
                    this.connexion()
                })
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    /*Si on est pas admin alors on redirige vers la page home*/
                    if(jwtDecoded.admin !== true){
                        this.props.navigate("/StockMarketSTO/Accueil")
                    }else{
                        this.setState({connected:true})
                        global.emitter.emit("onRouteChangedStockMarketSTO", {
                            jwt: this.state.jwt,
                        })
                        this.getTransacRecap()
                    }
                }else{
                    global.emitter.emit("askConnexionSMSTO",{disableOutsideClick:true})
                }
            })
        })
    }

    getTransacRecap(){
        /*Axios pour récupérer toutes les transactions faites par les clients*/
        Axios.post(url+"getSMSTOTransactions",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.resultat){
                let allTransactions = response.data.allTransactions
                /*sort all transactions by timestamp from newest to oldest*/
                allTransactions.sort((a,b)=>{
                    return b.date - a.date
                })
                this.setState({
                    transacRecap:allTransactions
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    selectDeploy(transac){
        this.setState({
            ["deploy"+transac.date]: (this.state["deploy"+transac.date] ? !this.state["deploy"+transac.date] : true)
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.displayLoading}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                <div className={"flexCenter"} style={{height:"calc(100vh - 70px)", marginTop:70, width:"100%", overflow:this.state.showBrowserView ? "hidden":"auto", justifyContent:"space-between"}}>
                    <div className={"flexCenter"} style={{flex:"1 1 10%", height:"100%", zIndex:99,background: "#FFF", flexDirection:"column", justifyContent:"flex-start", margin:30}}>
                        <div className={"flexCenter"} style={{width: "100%", height:"100%", marginLeft:0, flexDirection:"column", alignItems:"flex-start", justifyContent:"space-between"}}>
                            <h1 className={"flexCenter textShadowBorder"} style={{fontWeight:"bold", marginBottom:15, marginTop:50}}>
                                Résumé des transactions
                            </h1>
                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", padding:0}}>
                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                    <h2 className={"flexCenter"} style={{ fontWeight:600, margin:0}}>Entreprise</h2>
                                </div>
                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                    <h2 className={"flexCenter"} style={{ fontWeight:600, margin:0}}>Vendeur <ArrowRightAlt style={{margin:"0 5px 0 5px", width:50, height:50}}/> Acheteur</h2>
                                </div>
                                <div className={"flexCenter"} style={{width:"fit-content"}}>
                                    <h2 className={"flexCenter"} style={{fontWeight:600, margin:0, marginRight:5}}>Prix et tokens</h2>
                                </div>
                            </div>
                            <hr style={{width:'100%', backgroundColor:"#000", margin:0, marginTop:10}}/>
                            <div className={"flexCenter"} style={{width:"100%", alignItems:"flex-start", justifyContent:"flex-start", height:"100%", overflowY:"auto", overflowX:"hidden", flexDirection:"column", padding:30, paddingLeft:0}}>
                                {this.state.transacRecap.length===0 && (
                                    <h3 className={"flexCenter"} style={{maxWidth:"100%", marginLeft:10, marginTop:15}}>
                                        Aucune transaction pour le moment
                                    </h3>
                                )}
                                {this.state.transacRecap.map(transac => (
                                    <React.Fragment>
                                        <div className={"flexCenter"} style={{width:"100%",alignItems:"space-between", flexDirection:"column", transition:"all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                            <div style={{width:"100%",height:1, position:"relative"}}>
                                                <h2 onClick={()=>{this.selectDeploy(transac)}} className={"flexCenter"} style={{color:"#000", fontWeight:"bold", margin:0, cursor:"pointer", position:"absolute", fontSize:17}}>
                                                    <ExpandMore style={{color:"#000", width:30, height:30, cursor:"pointer", transition:"all .5s cubic-bezier(.25,.8,.25,1)", transform:!this.state["deploy"+transac.date] && "rotate(-90deg)"}} />
                                                    Afficher plus de détail
                                                </h2>
                                                <h2 className={"flexCenter"} style={{fontWeight:600, margin:0, right:0, position:"absolute", width:"fit-content", whiteSpace:"nowrap", fontSize:18, fontStyle:"italic"}}>{new Date(parseInt(transac.date)).toLocaleString()}</h2>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", padding:15}}>
                                                <div className={"flexCenter"} style={{width:"100%", height:100, justifyContent:"flex-start"}}>
                                                    <h2 className={"flexCenter"} style={{margin:0, justifyContent:"flex-start"}}>
                                                        <img style={{width:45, marginRight:10}} src={`${url}images/smsto_entreprise/${transac.siren}/logo.png`} alt="logo"/>
                                                        {transac.company}
                                                    </h2>
                                                    <div style={{display:"flex", justifyContent:"center", flex:1, width:"fit-content", marginLeft:30}}>
                                                        <div className={"flexCenter"} style={{width:'100%', justifyContent:"space-between"}}>
                                                            <h2 className={"flexCenter"} style={{margin:0, fontWeight:100, width:"100%", textAlign:"center"}}>
                                                                <Tooltip style={{zIndex:15000}} title={transac.who === transac.company ? "Nom de l'entreprise":"Identifiant du vendeur"}>
                                                                    {transac.seller === "company" ? (
                                                                        <Business style={{width:35, height:35}}/>
                                                                    ):(
                                                                        <Person style={{width:35, height:35}}/>
                                                                    )}
                                                                </Tooltip>
                                                                {transac.seller === "company" ? transac.company:transac.seller}
                                                                <ArrowRightAlt style={{margin:"0 5px 0 5px", width:50, height:50}}/>
                                                                <Tooltip style={{zIndex:15000}} title={"Identifiant de l'acheteur"}>
                                                                    <Person style={{width:35, height:35}}/>
                                                                </Tooltip>
                                                                {transac.buyer}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                    <div style={{display:"flex", justifyContent:"flex-end", width:"fit-content", flexDirection:"column"}}>
                                                        <h2 className={"flexCenter"} style={{margin:0, justifyContent:"flex-end"}}>
                                                            <span style={{fontSize:34, marginLeft:10}}>{transac.number}</span>
                                                            <img style={{width:40, marginTop:4, marginLeft:10}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                        </h2>
                                                        <h2 className={"flexCenter"} style={{margin:0, justifyContent:"flex-end"}}>
                                                            <span style={{fontSize:34, marginLeft:10}}>{transac.price}</span>
                                                            <img style={{width:40, marginTop:4, marginLeft:8}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"flexCenter collapsableDivGNFT"} style={{width:"100%",transform:this.state["deploy"+transac.date] ? "scaleY(1)":"scaleY(0)", maxHeight:this.state["deploy"+transac.date]?"100%":"0%", opacity:this.state["deploy"+transac.date]?1:0}}>
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", padding:5}}>
                                                <div className={"flexCenter"} style={{flexDirection:"column", width:"25%", alignItems:"flex-start"}}>
                                                    <h2 className={"flexCenter"} style={{fontWeight:100, margin:0}}>SIREN :<span style={{fontStyle:"italic", fontSize:15, marginLeft:5, fontWeight:"bold"}}>{transac.siren}</span></h2>
                                                </div>
                                                <div className={"flexCenter"} style={{ flexDirection:"column", width:"50%"}}>
                                                    <h2 className={"flexCenter"} style={{fontWeight:100, margin:0}}>IBAN vendeur :<span style={{fontStyle:"italic", fontSize:15, marginLeft:5, fontWeight:"bold"}}>{transac.iban}</span></h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"25%",flexDirection:"column", alignItems:"flex-end"}}>
                                                    <h2 className={"flexCenter"} style={{margin:0, width:"fit-content", fontWeight:100}}>Commissions : <span style={{fontWeight:"bold", marginLeft:5, color:"#FF4136FF"}}>{(transac.price*(8/100)).toFixed(2)}€ (8%)</span></h2>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{width:'100%', backgroundColor:"#FFF"}}/>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RecapTransacSTO)