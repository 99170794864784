import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import Download from '@mui/icons-material/Download';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import {Badge, Description, KeyboardReturn} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class RegistreActionnaires extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true,
            owner:false
        };
    }

    setInitialStates(){
        return{
            listInvestors : []
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }
    _emitConnectedBR
    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        let td = new URLSearchParams(window.location.search).get("td");
        if(td !== null){
            this.setState({
                td,
            })
        }else{
            Swal.fire({
                title: 'Erreur !',
                text: "IdToken de l'entreprise non valide.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            this.props.navigate("/StockMarketSTO/Accueil")
        }
        this._emitConnectedBR = global.emitter.addListener("emitConnectedSMSTO",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("SMSTO_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedStockMarketSTO", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                        this.setState({owner:jwtDecoded.admin})
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: this.state.jwt,
                    })
                    this.getRegistreActionnaire()
                }else{
                    global.emitter.emit("askConnexionSMSTO",{disableOutsideClick:true})
                }
            })
        })
    }

    getRegistreActionnaire(){
        Axios.post(url+"getActionnairesReg",{siren:this.state.td},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                this.setState({buffer:response.data.buffer.data,
                    listInvestors:response.data.investors,
                    nomEntreprise:response.data.nomEntreprise,
                    owner:this.state.owner === false && response.data.owner
                })
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    downloadFile(){
        let buffer = Buffer.from(this.state.buffer,"base64")
        let blob = new Blob([buffer], {type: "application/pdf"});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `registre_actionnaires_${this.state.nomEntreprise}.pdf`;
        link.click();
    }

    downloadAllJustificatifs(){
        // let allFilesToDl = []
        // this.state.listInvestors.forEach(i => {
        //     if (!!i.cni){
        //         allFilesToDl.push({fileName:i.cni, nom:i.nom, prenom:i.prenom})
        //     }
        //     if (!!i.jdd){
        //         allFilesToDl.push({fileName:i.jdd, nom:i.nom, prenom:i.prenom})
        //     }
        // })
        //
        // let zipFile = new AdmZip();
        // new Promise(async resolve => {
        //     resolve(await this.downloadEachFile(allFilesToDl.slice(), zipFile))
        // }).then(zip=>{
        //     let willSendthis = zip.toBuffer(); //On transforme le zip en Buffer Uint8Array pour le télécharger dans le front plus tard.
        //     let blob = new Blob([willSendthis], {type: "application/zip"});
        //     let link = document.createElement('a');
        //     link.href = window.URL.createObjectURL(blob);
        //     link.download = "Justificatifs_investisseurs_"+this.state.nomEntreprise+".zip";
        //     link.click();
        // })
        window.open(url+"downloadProofs?tokenJwt="+this.state.jwt+"&siren="+this.state.td)
    }
    async downloadEachFile(files, zipFile){
        return new Promise(async resolve => {
            if (files.length === 0){
                resolve(zipFile)
            }else{
                Axios.get(url+"smstoEvidences/"+files[0].fileName+"?jwt="+this.state.jwt+"&siren="+this.state.td, {
                    responseType: 'arraybuffer',
                }).then(response => {
                    const prenom = files[0].prenom.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                        nom = files[0].nom.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
                        fileName = files[0].fileName.includes("_cni.") ? `${nom}_${prenom}_CNI.${files[0].fileName.split("_cni.")[1]}` : `${nom}_${prenom}_JDD.${files[0].fileName.split("_jdd.")[1]}`
                    zipFile.addFile(fileName, Buffer.from(response.data, "utf8"),"Nom du fichier : "+fileName)
                    files.shift()
                    resolve(this.downloadEachFile(files, zipFile))
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <div style={{width:"fit-content",display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                    <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Registre des actionnaires de {this.state.nomEntreprise} :</h1>
                                    <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:"100%", height:3, borderRadius:100, marginBottom:10}}></div>
                                </div>
                                <div className={"flexCenter"}>
                                    <button style={{padding:8, fontSize:14, marginRight:10}} onClick={()=>{this.props.navigate(-1)}} className={"divButtonBuyResale flexCenter"}><KeyboardReturn style={{marginRight:10, color:"#FFFFFF"}}/> Retour</button>
                                    <button style={{padding:8, fontSize:14, marginRight:10}} onClick={()=>{this.downloadFile()}} className={"divButtonBuyResale flexCenter"}><Download style={{marginRight:10, color:"#FFFFFF"}}/> Télécharger le registre</button>
                                    {this.state.owner && (
                                        <button style={{padding:8, fontSize:14, marginRight:10}} onClick={()=>{this.downloadAllJustificatifs()}} className={"divButtonBuyResale flexCenter"}><Download style={{marginRight:10, color:"#FFFFFF"}}/> Télécharger les justificatifs</button>
                                    )}
                                </div>
                            </div>
                            <div style={{width:!this.state.showBrowserView ? "100%":"80%", display:"flex", alignItems:"center", justifyContent:"center", flexWrap:"wrap", marginTop:20}}>
                                {this.state.listInvestors.length === 0 ? (
                                    <p>Aucune investisseur disponible</p>
                                ):(
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">Nom</TableCell>
                                                    <TableCell align="center">Prénom</TableCell>
                                                    <TableCell align="center">Actions</TableCell>
                                                    <TableCell align="center">Adresse</TableCell>
                                                    {this.state.owner && (
                                                        <>
                                                            <TableCell align="center">Email</TableCell>
                                                            <TableCell align="center">Téléphone</TableCell>
                                                            <TableCell align="center">Justificatifs</TableCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.listInvestors.map(row=>(
                                                    <TableRow
                                                        key={row.ref}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center">{row.nom}</TableCell>
                                                        <TableCell align="center">{row.prenom}</TableCell>
                                                        <TableCell align="center">
                                                            <div className={"flexCenter"}>
                                                                {row.paid ? row.paid/row.actionValue: row.boughtActions}
                                                                {row.type === "irl" ? (" Actions"):(
                                                                    <Tooltip title={"STO : bons numériques"}>
                                                                        <img style={{width:25, marginLeft:5, marginRight:5}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                                    </Tooltip>
                                                                )}
                                                                {row.paid !== undefined && (row.boughtActions !== (row.paid / row.actionValue) && (" (non réglé)"))}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="center">{(row.postal && row.ville) ? `${row.adresse} ${row.postal}, ${row.ville}` : row.adresse}</TableCell>
                                                        {this.state.owner && (
                                                            <>
                                                                <TableCell align="center">{row.email}</TableCell>
                                                                <TableCell align="center">{row.tel}</TableCell>
                                                                <TableCell align="center">
                                                                    <button disabled={!row.cni} onClick={()=>{window.open(url+"smstoEvidences/"+row.cni+"?jwt="+this.state.jwt+"&siren="+this.state.td)}} style={{padding:6}} className={"divButtonBuyResale"}><Badge/></button>
                                                                    <button disabled={!row.jdd} onClick={()=>{window.open(url+"smstoEvidences/"+row.jdd+"?jwt="+this.state.jwt+"&siren="+this.state.td)}} style={{marginLeft:10, padding:6}} className={"divButtonBuyResale"}><Description/></button>
                                                                </TableCell>
                                                            </>

                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RegistreActionnaires)