import React, {Component} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import config from "../../../json/config.json";
import {isBrowser} from "react-device-detect";
import jwt_decode from "jwt-decode";
import SharedDrawerEkocoOR from "./components/SharedDrawerEkocoOR";
import SharedFooterEkocoOR from "./components/SharedFooterEkocoOR";
import Swal from "sweetalert2";

const {baseUrl, suffix, HelloAsso_client_id, HelloAsso_client_secret} = config;
const url = baseUrl + suffix
let email, iban, bic, token, pseudo, hash;

let jwtDecoded

class DonationOR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            openLoad:true,
            donationValue:null,
            statut:null,
            operatingSystem:"unknown",
            receiptUrl:""
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950 || !isBrowser){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)

        let type = new URLSearchParams(window.location.search).get("type")
        let code = new URLSearchParams(window.location.search).get("code")
        let checkoutIntentId = new URLSearchParams(window.location.search).get("checkoutIntentId")
        if(type === "return" || !type){
            this.setState({statut:code === "refused" ? "error":"success",checkoutIntentId})
        }else if (type === "error"){
            this.setState({statut:"error"})
        }else{
            this.setState({statut:"unknown"})
        }
        if(!checkoutIntentId){
            this.setState({statut:"unknown"})
        }


        let jwtURL = new URLSearchParams(window.location.search).get("jwt")
        if(jwtURL){
            sessionStorage.setItem("EKOCO_jwt", jwtURL)
        }
        let jwt = jwtURL ? jwtURL:sessionStorage.getItem("EKOCO_jwt")

        if(jwt){
            this.setState({
                jwt: jwt
            }, () => {
                jwtDecoded = jwt_decode(this.state.jwt)
                Axios.post(url + "verifyJwt", {}, {headers: {Authorization: "Bearer " + jwt}}).then(response => {
                    this.setState({openLoad:false})
                    if (response.data.jwt) {
                        sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                        this.setState({
                            jwt: response.data.jwt
                        }, () => {
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === true) {
                        this.setState({
                            pseudo: jwtDecoded.pseudo
                        })
                        this.setState({operatingSystem:this.getMobileOperatingSystem()})
                        if(this.state.statut === "success"){
                            this.validateDonation()
                        }
                    } else {
                        Swal.fire({
                            title: 'Erreur',
                            text: "Connexion impossible. Connectez vous et rafraichissez la page.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                })
            })
        }else{
            this.setState({openLoad:false})
            // Swal.fire({
            //     title: 'Erreur',
            //     text: "Connexion impossible, veuillez relancer ce site depuis l'application Ekoco ou connectez-vous directement sur le site.",
            //     icon: 'error',
            //     confirmButtonText: 'Ok',
            //     confirmButtonColor: '#ec5ba1'
            // })
            this.props.navigate("/Ekoco/OctobreRose/Connexion")
        }
    }

    validateDonation(){
        Axios.post(url + "finaliseDonationOR", {checkoutIntentId:this.state.checkoutIntentId}, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
            this.setState({openLoad:false})
            if (response.data.jwt) {
                sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                this.setState({
                    jwt: response.data.jwt
                }, () => {
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done") {
                this.setState({
                    donationValue: response.data.price,
                    receiptUrl: response.data.paymentReceiptUrl
                })
            } else {
                Swal.fire({
                    title: 'Erreur',
                    text: "Une erreur est survenue lors de la récupération de votre donation. Veuillez réessayer ultérieurement.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    displayContact(){
        this.setState({
            displayed:true,
        })
    }

    handleCloseDialog = () => {
        this.setState({
            displayed : false,
            displayed2 : false
        });
    };
    handleOpenDialog = () => {
        this.setState({
            displayed2 : true
        });
    };

    handleChange=(event) => { //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }

    handleValidation=()=>{ //Fonction servant a la vérification des données avant l'envoi du formulaire
        return true
    }

    getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "unknown";
        }

        if (/android/i.test(userAgent)) {
            return "android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iphone";
        }

        return "unknown";
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>
                <SharedDrawerEkocoOR/>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end", zIndex:2, backgroundColor:"#FFF"}}>
                    <p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold"}}>{this.state.pseudo}</p>
                    <AccountCircleIcon style={{color:"#b0a9a8", marginRight:50}}/>
                </div>
                {/*DEBUT RENDER STATIQUE*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)", height:"80vh"}}>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute",left:50, top:"25vh"}}>
                            <img style={{width:350}} src={require("../assetsEkoco/telephoneFemme_rose.png")} alt={"Reporting"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", zIndex:1,position:"absolute", left:0, top:0}}>
                            <img style={{width:"80", objectFit:"contain"}} src={require("../assetsEkoco/topleft_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                    <div style={{marginTop:50,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",marginBottom:20,width:"100%", overflow:"hidden"}}>
                        <div style={{backgroundColor:this.state.showBrowserView ? "#FFF":"transparent", width:this.state.showBrowserView ? 500:"95%", padding:15, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:30, paddingTop:30, borderRadius:10, zIndex:2}}>
                            {this.state.statut === null ? (
                                <>
                                    <CircularProgress style={{margin:"20px 0 20px 0", width:90, height:90, color:"#EC5BA1FF"}}/>
                                    <h3 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h3>
                                </>
                            ):(
                                <>
                                    <h2 style={{margin:0, marginBottom:20, fontWeight:100, fontSize:34}} className={"flexCenter"}>Paiement {this.state.statut === "success" ? (
                                        <>
                                            Validé
                                            <div style={{marginLeft:25}} className="sa">
                                                <div className="sa-success">
                                                    <div className="sa-success-tip"></div>
                                                    <div className="sa-success-long"></div>
                                                    <div className="sa-success-placeholder"></div>
                                                    <div className="sa-success-fix"></div>
                                                </div>
                                            </div>
                                        </>
                                    ):(
                                        <>
                                            {this.state.statut === "error" ? (
                                                <>
                                                    Échoué
                                                    <div style={{marginLeft:25}} className="sa">
                                                        <div className="sa-error">
                                                            <div className="sa-error-x">
                                                                <div className="sa-error-left"></div>
                                                                <div className="sa-error-right"></div>
                                                            </div>
                                                            <div className="sa-error-placeholder"></div>
                                                            <div className="sa-error-fix"></div>
                                                        </div>
                                                    </div>
                                                </>
                                            ):(
                                                <>
                                                    Inconnu
                                                </>
                                            )}
                                        </>
                                    )}</h2>
                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                        <div className={"flexCenter"} style={{width:'95%', justifyContent:"space-between"}}>
                                            <h2 style={{margin:0, fontWeight:100, textAlign:"center", width:"100%"}}>Ligue contre le cancer - Comité de l'Aube</h2>
                                        </div>

                                        <div style={{width:"95%", height:2, margin:30, marginBottom:20,borderBottom:"1px dashed #969696",}}/>

                                    </div>
                                    {this.state.donationValue !== null && (
                                        <div className={"flexCenter"} style={{justifyContent:"flex-end", width:"100%"}}>
                                            <h2 style={{fontSize:34, margin:0, fontWeight:300}}>{this.state.donationValue.toFixed(2)}€</h2>
                                        </div>
                                    )}

                                    <div className={"flexCenter"} style={{marginTop:20, width:"100%", flexDirection:"column"}}>
                                        {this.state.statut === "success" ? (
                                            <>
                                                <p onClick={()=>{window.open(this.state.receiptUrl)}} style={{fontWeight:600, textAlign:"justify", fontSize:18, marginBottom:0, cursor:"pointer", color:"#0534cc"}}>Téléchargez votre attestation de paiement ici.</p>
                                                <p style={{fontWeight:600, textAlign:"justify", fontSize:15, marginBottom:0}}>Vous pouvez maintenant accéder aux parcours sur l'application Ekoco !</p>
                                                {this.state.operatingSystem !== "unknown" && (
                                                    <a style={{backgroundColor:"#EC5BA1FF", color:"#FFF", borderRadius:10, padding:10, fontSize:25, textDecoration:"none", marginTop:10}} href="ekocoapp://Home">Retour sur Ekoco</a>
                                                )}
                                            </>
                                        ):(
                                            <>
                                                {this.state.statut === "error" ? (
                                                    <>
                                                        <p style={{fontWeight:100, textAlign:"justify", fontSize:15, marginBottom:0}}>Une erreur est survenue lors de la donation. Veuillez réessayer ultérieurement.</p>
                                                        <p style={{fontWeight:100, textAlign:"justify", fontSize:15, marginTop:0}}>Si le problème persiste, contactez nous au (+33)6 08 82 67 53 ou  <a href="https://spuro.eu/contact/" target={"_blank"}>par mail</a>.</p>
                                                    </>
                                                ):(
                                                    <>
                                                        <p style={{fontWeight:100, textAlign:"justify", fontSize:15, marginBottom:0}}>Aucune donation ne correspond à ces informations...</p>
                                                        <p style={{fontWeight:100, textAlign:"justify", fontSize:15, marginTop:0}}>Si le problème persiste, contactez nous au (+33)6 08 82 67 53 ou  <a href="https://spuro.eu/contact/" target={"_blank"}>par mail</a>.</p>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex",justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute", right:50, top:"25vh"}}>
                            <img style={{width:350, objectFit:"contain"}} src={require("../assetsEkoco/homme_rose.png")} alt={"Statistiques"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end" , zIndex:1,position:"absolute", right:0, bottom:0}}>
                            <img style={{width:"80%", objectFit:"contain"}} src={require("../assetsEkoco/bottomright_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                </div>
                {/*FIN RENDER STATIQUE*/}
                {/*FOOTER*/}
                <SharedFooterEkocoOR/>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Chargement en cours ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default  withRouter(DonationOR);