import React, {Component} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import config from "../../../json/config.json";
import {isBrowser} from "react-device-detect";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import SharedDrawerEkocoOR from "./components/SharedDrawerEkocoOR";
import SharedFooterEkocoOR from "./components/SharedFooterEkocoOR";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let email, iban, bic, token, pseudo, hash;

let jwtDecoded

const detailParcours = [
    {
        id:0,
        image:"https://ekoco.fr/back/ORassets/RuelleDesChats/1_RDC.jpg",
        name:"Les incontournables"
    },
    {
        id:1,
        image:"https://ekoco.fr/back/ORassets/LiliDameAuChapeau/1_LL.jpg",
        name:"Sculptures et statues"
    },
    {
        id:2,
        image:"https://ekoco.fr/back/ORassets/CathedraleStPierreStPaul/1_CSPSP.jpg",
        name:"Edifices religieux"
    },
    {
        id:3,
        image:"https://ekoco.fr/back/ORassets/CoeurDeTroyes/1_CDT.jpg",
        name:"Spots photos"
    },
    {
        id:4,
        image:"https://ekoco.fr/back/ORassets/PlaceDeLaLiberation/1_PDLL.jpg",
        name:"Le tour de Troyes"
    },
    {
        id:5,
        image:"https://ekoco.fr/back/ORassets/ParcoursTEST.png",
        name:"Parcours TEST"
    },
]

let listAllParcours = [
    {
        timestamp: 1693821441,
        parcoursId: 2,
    },
    {
        timestamp: 1693921441,
        parcoursId: 3,
    }
]

class MesParcoursOR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bicValue : null, ibanValue : null, emailValue : null,displayed:false,displayed2:false,
            marginLeftContact:20,
            marginRightContact:10,
            statsContentWidth:"95%",
            statsContentMinWidth:"0%",
            statsContentMaxWidth:"100%",
            marginTopContent:0,
            showBrowserView:true,
            allParcours:[]
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950 || !isBrowser){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)

        let jwtURL = new URLSearchParams(window.location.search).get("jwt")
        if(jwtURL){
            sessionStorage.setItem("EKOCO_jwt", jwtURL)
        }
        let jwt = jwtURL ? jwtURL:sessionStorage.getItem("EKOCO_jwt")


        if(jwt){
            this.setState({
                jwt: jwt
            }, () => {
                jwtDecoded = jwt_decode(this.state.jwt)
                Axios.post(url + "verifyJwt", {}, {headers: {Authorization: "Bearer " + jwt}}).then(response => {
                    this.setState({openLoad:false})
                    if (response.data.jwt) {
                        sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                        this.setState({
                            jwt: response.data.jwt
                        }, () => {
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === true) {
                        this.setState({
                            pseudo: jwtDecoded.pseudo
                        }, () => {
                            this.getAllParcours();
                        })
                    } else {
                        Swal.fire({
                            title: 'Erreur',
                            text: "Connexion impossible, veuillez relancer ce site depuis l'application Ekoco ou connectez-vous directement sur le site.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        }).then(() => {
                            sessionStorage.removeItem("EKOCO_jwt")
                            this.props.navigate("/Ekoco/OctobreRose/Connexion")
                        })
                    }
                })
            })
        }else{
            this.setState({openLoad:false})
            Swal.fire({
                title: 'Erreur',
                text: "Connexion impossible, veuillez relancer ce site depuis l'application Ekoco ou connectez-vous directement sur le site.",
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#ec5ba1'
            }).then(()=>{
                this.props.navigate("/Ekoco/OctobreRose/Connexion")
            })
        }


        if (this.state.showBrowserView){
            this.setState({
                marginLeftContact:50,
                marginRightContact:100,
                statsContentWidth:"50%",
                statsContentMinWidth:400,
                statsContentMaxWidth:450,
                marginTopContent:50,
            })
        }
    }

    displayContact(){
        this.setState({
            displayed:true,
        })
    }

    getAllParcours(){
        Axios.post(url+"getEndedParcoursOR",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.resultat === "done"){
                let allParcours = response.data.endedParcours
                this.setState({allParcours})
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: "Une erreur est survenue, veuillez réessayer plus tard.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#ec5ba1'
                })
            }
        }).catch((err)=>{
            console.log(err)
            Swal.fire({
                title: 'Erreur',
                text: "Une erreur est survenue, veuillez réessayer plus tard.",
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#ec5ba1'
            })
        })
    }

    handleCloseDialog = () => {
        this.setState({
            displayed : false,
            displayed2 : false
        });
    };
    handleOpenDialog = () => {
        this.setState({
            displayed2 : true
        });
    };

    handleChange=(event) => { //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }

    handleValidation=()=>{ //Fonction servant a la vérification des données avant l'envoi du formulaire
        return true
    }

    downloadFile(type, link){
        if(type === "certif"){
            window.open(link+"?tokenJwt="+this.state.jwt, "_blank")
        }else{
            this.props.navigate("/Ekoco/OctobreRose/VisionneuseFichier?album="+link)
        }
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>
                <SharedDrawerEkocoOR/>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end", zIndex:2}}>
                    <p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold"}}>{this.state.pseudo}</p>
                    <AccountCircleIcon style={{color:"#b0a9a8", marginRight:50}}/>
                </div>
                {/*DEBUT RENDER STATIQUE*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)", justifyContent:"flex-start", alignItems:"center", flexDirection:"column", minHeight:"80vh"}}>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute",left:50, top:"25vh"}}>
                            <img style={{width:350}} src={require("../assetsEkoco/telephoneFemme_rose.png")} alt={"Reporting"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", zIndex:1,position:"absolute", left:0, top:100}}>
                            <img style={{width:"80", objectFit:"contain"}} src={require("../assetsEkoco/topleft_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                    <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", flexDirection:"column",marginBottom:20,width:"100%",height:'100%', marginTop:50, overflow:"hidden"}}>
                        <div style={{backgroundColor:"#ec5ba1", width:this.state.showBrowserView ? 500:"95%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:10, paddingTop:20, borderRadius:10, zIndex:2, boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                            <p style={{color:"white",fontWeight:"bold", fontSize:this.state.showBrowserView ?30:"6vw", margin:0, marginBottom:20, textAlign:"center", whiteSpace:"pre-line"}}>Mes parcours</p>
                            <div className={"flexCenter"} style={{overflow:"auto", maxHeight: this.state.showBrowserView ? "50vh":"100%", justifyContent:"flex-start", flexDirection:"column"}}>
                                {this.state.allParcours.length === 0 && (
                                    <p style={{color:"white",fontWeight:"bold", fontSize:this.state.showBrowserView ?20:"4vw", margin:0, marginBottom:20, textAlign:"center", whiteSpace:"pre-line"}}>Vous n'avez pas encore fait de parcours.</p>
                                )}
                                {this.state.allParcours.map((elt,index)=>(
                                    <div style={{backgroundColor:"white",height:"fit-content", borderRadius:5,width:"90%", display:"flex", marginBottom:20, justifyContent:"space-between", alignItems:"center",boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                                        <div style={{display:"flex", height:"100%"}}>
                                            <img style={{height:this.state.showBrowserView ? 150:"26vw", objectFit:"contain", borderTopLeftRadius:5, borderBottomLeftRadius:5}} src={detailParcours[elt.parcoursId].image} alt="parcours"/>
                                        </div>
                                        <div style={{display:"flex",width:"100%", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                            <div className={"flexCenter"} style={{position:"relative", height:0, width:"100%", alignItems:"flex-start", justifyContent:"flex-start"}}>
                                                <p style={{color:"#B0A9A8FF", fontStyle:"italic", fontSize:this.state.showBrowserView ? 15:"2.5vw", position:"absolute", top:-10, left:5, width:"fit-content", whiteSpace:"nowrap"}}>Fait le {new Date(parseInt(elt.timestamp)).toLocaleString().replace(" "," à ")}</p>
                                            </div>
                                            <p style={{color:"#3A374C", fontWeight:"bold", fontSize:this.state.showBrowserView ? 26: "4.5vw", marginBottom:5}}>{detailParcours[elt.parcoursId].name}</p>
                                            <div style={{display:"flex",justifyContent:"space-evenly", alignItems:"center", width:"100%"}}>
                                                <img id={"hoverMoving"} onClick={()=>{this.downloadFile("certif", elt.urlCertificate)}} style={{width:"25%", objectFit:"contain"}} src={require("../assetsEkoco/certificat_download_icon.png")} alt="dl_certif"/>
                                                <img id={"hoverMoving"} onClick={()=>{this.downloadFile("album", index)}} style={{width:"25%", objectFit:"contain"}} src={require("../assetsEkoco/album_download_icon.png")} alt="dl_album"/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex",justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute", right:50, top:"25vh"}}>
                            <img style={{width:350, objectFit:"contain"}} src={require("../assetsEkoco/homme_rose.png")} alt={"Statistiques"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end" , zIndex:1,position:"absolute", right:0, bottom:0}}>
                            <img style={{width:"80%", objectFit:"contain"}} src={require("../assetsEkoco/bottomright_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                </div>
                {/*FIN RENDER STATIQUE*/}
                {/*FOOTER*/}
                <SharedFooterEkocoOR/>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Chargement en cours ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default  withRouter(MesParcoursOR);