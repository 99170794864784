import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import {isMobile} from "react-device-detect";
import jwt_decode from "jwt-decode";
import {Button, CircularProgress, IconButton} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Add, Check, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let socketIO //Initialisation de l'instance du socket

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories = [
    {name:"Technologie",color:"#006ae5"},
    {name:"Musique",color:"#b97e0f"}
]

let previsuCatalogue = [
    {
        name:"Comment fonctionne la blockchain Kagesecur",
        description:"L'entreprise Spuro et son PDG M. Benjamin Faraggi vous propose de découvrir le fonctionnement de la blockchain Kagesecur.",
        image:require("../../assets/vid_previsu_34298651.png"),
        category:0
    },
    {
        name:"Tout comprendre sur l'intelligence artificielle",
        description:"Notre formateur John Doe vous expliquera tout ce qu'il y a à savoir sur l'intelligence artificelle en 2022.",
        image:require("../../assets/vid_previsu_3215879.png"),
        category:0
    },
    {
        name:"Apprendre le tap harmonic en 15 minutes",
        description:"Si toi aussi tu veux apprendre facilement le tap harmonic, regarde cette vidéo. Tu auras aussi accès aux partitions des musiques que je joue pendant la vidéo",
        image:require("../../assets/vid_previsu_34236671.png"),
        category:1
    }
]

let jwtDecoded;
class AccueilBR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vidSelected:0,
            uploadMaVideo:false,
            valueProgress:0,
            uploadMaVideoDone:false,
            mousePosition:[0,0],
            showBrowserView:true,
            numberViews:0,
            pageWidth:document.body.offsetWidth,
            scrollPosition:0,
            alreadyClicked:false
        };
    }

    resizePage = () => {
        this.setState({pageWidth:document.body.offsetWidth})
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    trackMouse = (e) => {
        if(window.scrollY < 800){
            let mouseX
            if(e.screenX > 1920){
                mouseX = e.screenX-1920
            }else if (e.screenX < 0 ){
                mouseX = -e.screenX
            }else{
                mouseX = e.screenX
            }
            let mouseY = e.screenY
            this.setState({mousePosition:[mouseX,mouseY]})
        }
    }

    trackScroll = (e) => {
        if(window.scrollY > 1500 && !this.state.alreadyClicked){
            this.modifyProgress()
        }
    }

    goToPage(clickedPage){
        window.history.replaceState(null, null, window.location.pathname);
        window.location.pathname !== clickedPage ? this.props.navigate(clickedPage):window.location.reload()
        this.setState({displayMenuMobile:false})
    }

    componentWillUnmount(){
        this._willUnmount = true;
        if(this._interval){
            clearInterval(this._interval)
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        window.addEventListener("mousemove", this.trackMouse);
        window.addEventListener("scroll", this.trackScroll);

        this.setState({
            jwt:sessionStorage.getItem("BUYRESALE_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            if(jwtDecoded!=={}){
                Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === true){
                        jwtDecoded = jwt_decode(this.state.jwt)
                        global.emitter.emit("onRouteChangedBuyResale", {
                            jwt: this.state.jwt,
                        })
                    }else{
                        sessionStorage.removeItem("BUYRESALE_jwt")
                        global.emitter.emit("onRouteChangedBuyResale", {
                            jwt: null,
                        })
                    }
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    changeOrderCarousel(way){
        if(way ==="right") {
            let previsuCatalogueTemp = previsuCatalogue.slice()
            let firstData =previsuCatalogueTemp[0]
            previsuCatalogueTemp.splice(0,1)
            previsuCatalogueTemp.push(firstData)
            previsuCatalogue=previsuCatalogueTemp
            this.setState({vidSelected:this.state.vidSelected === previsuCatalogue.length-1 ? 0:this.state.vidSelected+1})
        }else{
            let previsuCatalogueTemp = previsuCatalogue.slice()
            let lastData = previsuCatalogueTemp.pop()
            previsuCatalogueTemp.unshift(lastData)
            previsuCatalogue=previsuCatalogueTemp
            this.setState({vidSelected:this.state.vidSelected === 0 ? 2:this.state.vidSelected-1})
        }
    }

    modifyProgress(){
        if(this.state.uploadMaVideoDone !== true && this.state.alreadyClicked === false){
            this.setState({uploadMaVideo:true,alreadyClicked:true})
            let timerInterval =30
            this.setState({valueProgress:this.state.valueProgress+1})
            const timer = setInterval(()=>{
                timerInterval = Math.floor(Math.random() * (80 - 40 + 1) + 40)
                if(this.state.valueProgress < 100){
                    this.setState({valueProgress:this.state.valueProgress+1})
                }else{
                    clearInterval(timer);
                    this.setState({uploadMaVideoDone:true})
                    this.incrementViews()
                }
            },timerInterval)
        }
    }

    incrementViews(){
        const timer = setInterval(()=>{
            if(this.state.numberViews < 500000){
                this.setState({numberViews:this.state.numberViews+Math.floor(Math.random() * (5 - 1 + 1) + 1)})
            }else{
                clearInterval(timer);
            }
        },50)
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,minHeight:"100vh",width:"100%", transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"flex-start",flexDirection:"column"}}>
                    {/*Première div LOGO*/}
                    <div id={"divPres"} className={"flexCenter"}>
                        <div style={{position:"relative", width:"100%"}}>
                            {this.state.showBrowserView && (
                                <>
                                    <img style={{textAlign:"center", position:"absolute", right:0, height:487, top:45,userSelect: "none"}} src={require("../../assets/backgroundBR1.png")} alt="backgroundBuyResale"/>
                                    <img style={{textAlign:"center", position:"absolute", height:600,userSelect: "none"}} src={require("../../assets/backgroundBR2.png")} alt="backgroundBuyResale"/>
                                </>
                            )}
                        </div>
                        <div className={"flexCenter divHoverPres"} style={{flex:8, flexDirection:"column", transform:this.state.showBrowserView && ("perspective(1000px) rotateY("+((this.state.mousePosition[0]-(document.body.offsetWidth/2))*40/document.body.offsetWidth)+"deg) rotateX("+((this.state.mousePosition[1]-(document.body.offsetHeight/5))*120/document.body.offsetHeight)+"deg) scale3d(1, 1, 1)")}}>
                            <img style={{textAlign:"center", width:this.state.showBrowserView ?450:"70%",userSelect: "none"}} src={require("../../assets/LOGO_BuyResale_blanc_orange.png")} alt="logoBuyResale"/>
                            <h1 style={{color:"#FFFFFF", marginBottom:0,marginTop:60, fontWeight:400, textAlign:"center", fontSize:!this.state.showBrowserView&&"5vw"}}>La place de marché des <span style={{color:"#f19028"}}>formations</span> vidéos</h1>
                            <h1 className={"flexCenter"} style={{color:"#FFFFFF", marginTop:5,marginBottom:0, fontWeight:400, textAlign:"center", fontSize:!this.state.showBrowserView&&"5vw", flexDirection:!this.state.showBrowserView&&"column", whiteSpace:"nowrap"}}>alimentée par la <span style={{color:"#f19028", marginLeft:8}}>blockchain</span> <img style={{width:250, marginLeft:10}} src={require("../../assets/logoGalaksioLine.png")} alt={"logo Galaksio"}/></h1>
                        </div>
                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                            <Button
                                onClick={()=>{document.getElementById("divPrevCatalogue").scrollIntoView()}}
                                style={{color:"#FFFFFF", borderColor:"#FFFFFF"}} variant={"outlined"}
                            >
                                En savoir plus
                            </Button>
                            <KeyboardArrowDown style={{color:"#FFFFFF"}}/>
                        </div>
                    </div>
                    {/*Div Présentation du catalogue*/}
                    <div id={"divPrevCatalogue"} className={"flexCenter"}>
                        <h1 style={{textAlign:"center", fontWeight:100, fontSize:48, marginTop:0}}>Revendez vos formations achetées comme des NFTs</h1>
                        <div className={"flexCenter"} style={{width:"100%", height:"100%"}}>
                            {this.state.pageWidth > 1450 && (
                                <div style={{flex:1}}>
                                    <div style={{width:"100%", height:1, position:"relative"}}>
                                        <h1 className={"textShadowBorder"} style={{margin:0, position:"absolute", color:"#FFFFFF", zIndex:2,top:-140,left:230}}>95€ ?</h1>
                                        <h1 className={"textShadowBorder"} style={{margin:0, position:"absolute", color:"#FFFFFF", zIndex:2,top:-140,left:445}}>100€ !</h1>
                                        <img style={{textAlign:"center",userSelect: "none", width:650, borderRadius:5, position:"absolute", top:-155, left:50}} src={require("../../assets/people-talking.png")} alt="logoBuyResale"/>
                                    </div>
                                </div>
                            )}
                            <div className={"flexCenter"} style={{flex:1, flexDirection:"column", width:"100%"}}>
                                <div className={"flexCenter"} style={{width:"80%", height:350, position:"relative",marginTop:this.state.showBrowserView && 60, marginLeft:60}}>
                                    <div id={"translateLeft"} className={"imageRevente"} style={{marginLeft:-50, marginTop:70,width:this.state.showBrowserView?550:400,height:this.state.showBrowserView?309:224}} >
                                        <img style={{width:"100%", height:"100%", borderRadius:5,userSelect: "none"}} src={require("../../assets/vid_previsu_34236671.png")} alt="logoBuyResale"/>
                                        <div style={{position:"relative"}}>
                                            <div className={"divBandeauPrixNFT"} style={{zIndex:5}}>
                                            </div>
                                            <h3 style={{margin:0,marginLeft:10, color:"#FFFFFF",opacity:1, zIndex:6,top:-29,position:"absolute"}}>Prix de vente du NFT : 100€</h3>
                                        </div>
                                    </div>
                                    <div className={"imageRevente"} style={{width:this.state.showBrowserView?550:400,height:this.state.showBrowserView?309:224}}>
                                        <img style={{width:"100%", height:"100%", borderRadius:5,userSelect: "none"}} src={require("../../assets/vid_previsu_3215879.png")} alt="logoBuyResale"/>
                                        <div style={{position:"relative"}}>
                                            <div className={"divBandeauPrixNFT"} style={{zIndex:5}}>
                                            </div>
                                            <h3 style={{margin:0,marginLeft:10, color:"#FFFFFF",opacity:1, zIndex:6,top:-29,position:"absolute"}}>Prix de vente du NFT : 85€</h3>
                                        </div>
                                    </div>
                                    <div id={"translateRight"} className={"imageRevente"} style={{marginLeft:50, marginTop:-70,width:this.state.showBrowserView?550:400,height:this.state.showBrowserView?309:224}} >
                                        <img style={{width:"100%", height:"100%", borderRadius:5,userSelect: "none"}} src={require("../../assets/blockchain-3277336_1280.png")} alt="logoBuyResale"/>
                                        <div style={{position:"relative"}}>
                                            <div className={"divBandeauPrixNFT"} style={{zIndex:5}}>
                                            </div>
                                            <h3 style={{margin:0,marginLeft:10, color:"#FFFFFF",opacity:1, zIndex:6,top:-29,position:"absolute"}}>Prix de vente du NFT : 45€</h3>
                                        </div>
                                    </div>
                                </div>
                                <div style={{zIndex:4}} >
                                    <h2 style={{fontWeight:"normal",marginBottom:0, textAlign:"center"}}>Vous pourrez revendre les formations que vous avez acheté.</h2>
                                    <h2 style={{fontWeight:"normal", marginTop:2, textAlign:"center"}}>Les revenus seront partagés entre vous et le créateur de la formation.</h2>
                                </div>
                            </div>
                        </div>
                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                            <Button
                                onClick={()=>{document.getElementById("divPrevRevente").scrollIntoView()}}
                                style={{color:"#000", borderColor:"#000"}} variant={"outlined"}
                            >
                                En savoir plus
                            </Button>
                            <KeyboardArrowDown style={{color:"#000"}}/>
                        </div>
                    </div>
                    {/*Div présentation vente vidéo*/}
                    <div id={"divPrevRevente"} style={{backgroundColor:"#f6f6f6"}} className={"flexCenter"}>
                        <h1 style={{textAlign:"center", fontWeight:100, fontSize:48, marginTop:0}} >Un catalogue de formation complet</h1>
                        <div style={{position:"relative",width:this.state.showBrowserView?705:"80vw"}}>
                            <div className={"flexCenter"} style={{position:"absolute",top:250,width:this.state.showBrowserView?695:"80vw",justifyContent:"space-between"}}>
                                <IconButton onClick={()=>{this.changeOrderCarousel("left")}} style={{color:"#000000"}}>
                                    <KeyboardArrowLeft className={"arrowCarousel"}/>
                                </IconButton>
                                <IconButton onClick={()=>{this.changeOrderCarousel("right")}} style={{color:"#000000"}}>
                                    <KeyboardArrowRight className={"arrowCarousel"}/>
                                </IconButton>
                            </div>
                        </div>
                        <div style={{flex:this.state.showBrowserView && 8,width:'100%',overflowX:"hidden", display:"flex", justifyContent:"center"}}>
                            {previsuCatalogue.map((vid,index)=>(
                                <div style={{opacity:index!==1 && 0.4, transform:index!==1 &&("scale(0.9)"), order:index,borderRadius:5}} className={"divPrevisu flexCenter"}>
                                    <div style={{position:"relative",width:"100%"}}>
                                        <div style={{border:"1px solid", borderColor:categories[vid.category].color, position:"absolute",borderRadius:10,top:-5,left:4}}>
                                            <p style={{fontSize:!this.state.showBrowserView &&"2vw",margin:3,color:categories[vid.category].color}}>{categories[vid.category].name}</p>
                                        </div>
                                    </div>
                                    <h3 style={{textAlign:"center"}}>{vid.name}</h3>
                                    <img style={{textAlign:"center", width:this.state.showBrowserView?600:"70vw",userSelect: "none"}} src={vid.image} alt="logoBuyResale"/>
                                    <h5 style={{margin:this.state.showBrowserView && 20,textAlign:"center"}}>{vid.description}</h5>
                                </div>
                            ))}
                        </div>
                        <div className={"flexCenter"}>
                            {previsuCatalogue.map((vid,index)=>(
                                <div style={{backgroundColor:this.state.vidSelected === index && "#72aacc"}} className={"circleCarousel"}/>
                            ))}
                        </div>

                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                            <Button
                                onClick={()=>{document.getElementById("divPrevMaVideo").scrollIntoView()}}
                                style={{color:"#000000", borderColor:"#000000"}} variant={"outlined"}
                            >
                                En savoir plus
                            </Button>
                            <KeyboardArrowDown style={{color:"#000000"}}/>
                        </div>
                    </div>
                    {/*Div présentation upload vidéo*/}
                    <div id={"divPrevMaVideo"} className={"flexCenter"}>
                        <div className={"flexCenter"} style={{flex:8, flexDirection:"column", zIndex:2}}>
                            <h1 style={{color:"#FFFFFF", textAlign:"center", fontWeight:100, fontSize:48, marginTop:0}}>Devenez formateur en uploadant une vidéo</h1>
                            <div style={{width: this.state.showBrowserView ? 700:"65vw", height: this.state.showBrowserView ? 394:"36.5vw"}} className={"divVideoToUpload"}>
                                <div style={{position:"relative",width:"100%", height:"1%"}}>
                                    <img style={{textAlign:"center", width:"100%", borderRadius:5, opacity:this.state.valueProgress/100,userSelect: "none"}} src={require("../../assets/previsuUploadBuyResale.jpg")} alt="logoBuyResale"/>
                                </div>
                                {this.state.uploadMaVideo && this.state.valueProgress===100 && this.state.showBrowserView && (
                                    <div style={{position:"relative",width:"100%", height:"1%"}}>
                                        <h3 className={"flexCenter"} style={{top:360, position:"absolute", margin:0, color:"#FFFFFF", left:10}}><VisibilityIcon/>{this.state.numberViews}</h3>
                                    </div>
                                )}

                                <div className={"flexCenter"} style={{width:"100%", height:"99%"}}>
                                    {!this.state.uploadMaVideo ? (
                                        <div id={"alwaysHover"} style={{zIndex:3}} onClick={()=>{this.modifyProgress()}} className={"circleAddVideo flexCenter"}>
                                            <Add style={{width:30,height:30}}/>
                                        </div>
                                    ):(
                                        <div style={{zIndex:3}} className={"circleAddVideo flexCenter"}>
                                            <div className={"flexCenter"}>
                                                <CircularProgress style={{width:80,height:80,transition:0,color:this.state.valueProgress === 100 ? "#43c53b":"#72AACCFF"}} value={this.state.valueProgress} variant="determinate"/>
                                                <div style={{position:"relative"}}>
                                                    {this.state.valueProgress === 100 ? (
                                                        <div style={{position:"relative"}}>
                                                            <div style={{position:"absolute", top:-35, left:-75}} className={"circleUploadDone flexCenter"}>
                                                                <Check style={{color:"#FFFFFF", transform:"scale(2)"}}/>
                                                            </div>
                                                        </div>

                                                    ):(
                                                        <p style={{margin:0, position:"absolute", top:-10, left:-52}}>{this.state.valueProgress}%</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <h2 style={{color:"#FFFFFF", fontWeight:"normal",marginBottom:0, textAlign:"center"}}>Vous aussi faites parti de la communauté BuyResale et mettez </h2>
                            <h2 style={{color:"#FFFFFF", fontWeight:"normal", marginTop:2, textAlign:"center"}}>en vente vos formations et vidéos sur notre catalogue de vente.</h2>
                        </div>
                        <div className={"flexCenter"} style={{flex:1, flexDirection:"column", zIndex:3}}>
                            <Button
                                onClick={()=>{this.goToPage("/BuyResale/Inscription")}}
                                style={{color:"#FFFFFF", borderColor:"#FFFFFF", marginBottom:20}} variant={"outlined"}
                            >
                                Je m'inscris
                            </Button>
                        </div>
                        <div style={{position:"relative", width:"100%"}}>
                            <img style={{textAlign:"center", width:600, height:300, position:"absolute", top:-300,userSelect: "none"}} src={require("../../assets/fouleDos.png")} alt="illustrationCommunauté"/>
                        </div>
                    </div>
                    <div id={"divFooter"} className={"flexCenter"} style={{width:"100%"}}>
                        {this.state.showBrowserView && (
                            <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                                <img style={{textAlign:"center", width: 250,marginRight:5,userSelect: "none"}} src={require("../../assets/LOGO_BUYRESALE.png")} alt="logoKageSecure"/>
                            </div>
                        )}
                        <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                            <div style={{width:this.state.showBrowserView ?"70%":"95%",display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"flex-start"}}>
                                <h3 style={{margin:10, marginLeft:0}}>Nous contacter :</h3>
                                <p style={{margin:0}}>Adresse : 9 Rue Gustave Eiffel 10430 Rosières-près-Troyes</p>
                                <p style={{margin:0}}>Email : spurocontact@spuro.eu</p>
                            </div>
                        </div>
                        <div className={"flexCenter"} style={{flex:1, height:"100%", flexDirection:"column", overflowX:"hidden"}}>
                            <h3 onClick={()=>{this.goToPage("/BuyResale/MentionsLegales")}} className={"textCGU"} style={{margin:5, fontWeight:"normal"}}>Mentions légales</h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AccueilBR)