import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {isBrowser} from "react-device-detect";

import Axios from "axios";
import config from "../json/config.json";
import {Button, IconButton, InputAdornment, TextField, Tooltip} from "@mui/material";
import {sha256} from 'js-sha256';
import tooltipContenu from "../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
let date = new Date(), empreinteInitiateur;

let jwtDecoded = sessionStorage.getItem("SPURO_jwt") ? jwt_decode(sessionStorage.getItem("SPURO_jwt")) : {}

class PropositionDePret extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phase:1,
            initiateurFongible:0,
            champsEcheance:"",
            stateDrawer:true
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt:sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = jwt_decode(this.state.jwt)
            global.emitter.emit("onRouteChanged",{
                pageName:'Proposition de contrats',
                pseudo:jwtDecoded.pseudo,
            })
        })
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }

        date = date.getTime() + (date.getTimezoneOffset()*60000);
        date = new Date(date).toLocaleString()
        date = date.split(" ")[0]
        date = date.split("/")
        let firstElt = date[0]
        date[0] = date.pop()
        date.push(firstElt)
        date = date.toString()
        date = date.replaceAll(",","")
        this.setState({todayDateAmericain:date})
    }
    handleSubmitPhase1(event){
        event.preventDefault()
        empreinteInitiateur = sha256(this.state.champsPseudo.concat(this.state.champsClef))
        Axios.post(url+"connexionInitiateur", {
            empreinte : empreinteInitiateur,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwtClient")}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
            }
            if (response.data.resultat === "Cet utilisateur n'existe pas."){
                Swal.fire({
                    title: 'Erreur!',
                    text: "Cet utilisateur n'existe pas.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }else{
                this.setState({
                    initiateurFongible : response.data.resultat,
                    phase:2,
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Connecté au compte "'+this.state.champsPseudo+'"'
                })
            }
        })
    }
    handleSubmitPhase2(event){

        event.preventDefault()
        let dateEcheance = ((this.state.champsEcheance).toString()).replaceAll("-","")

        if(parseFloat(this.state.champsValueLoaned) <= parseFloat(this.state.initiateurFongible) && parseFloat(this.state.champsValueLoaned) > 0) {
            if(parseFloat(this.state.champsTaux) >= 0 ||parseFloat(this.state.champsTaux)<=100){
                this.setState({
                    phase:3,
                    valuePayBack:parseFloat(parseFloat(this.state.champsValueLoaned*(this.state.champsTaux/100))+parseFloat(this.state.champsValueLoaned)).toFixed(3)
                },()=>{
                    this.setState({
                        valueFailurePayBack:parseFloat(parseFloat(this.state.valuePayBack*(this.state.champsTaux/100))+parseFloat(this.state.valuePayBack)).toFixed(3)
                    })
                })
                //A REMETTRE APRES LES TESTS AU NIVEAU DU PRET
                // if(parseFloat(dateEcheance)> parseFloat(this.state.todayDateAmericain)){
                //     this.setState({
                //         phase:3,
                //         valuePayBack:parseFloat(parseFloat(this.state.champsValueLoaned*(this.state.champsTaux/100))+parseFloat(this.state.champsValueLoaned)).toFixed(3)
                //     },()=>{
                //         this.setState({
                //             valueFailurePayBack:parseFloat(parseFloat(this.state.valuePayBack*(this.state.champsTaux/100))+parseFloat(this.state.valuePayBack)).toFixed(3)
                //         })
                //     })
                // }else{
                //     Toast.fire({
                //         icon: 'error',
                //         title: "Veuillez rentrer une échéance correspondant à au moins 24h d'attente."
                //     })
                // }
            }else{
                Toast.fire({
                    icon: 'error',
                    title: 'Veuillez rentrer un taux entre 0% et 100%'
                })
            }
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Vous ne possédez pas d'assez de token fongible pour faire préter des STC.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }
    }
    handleSubmitPhase3(event){
        event.preventDefault()
        Axios.post(url+"initiateLoan", {
            empreinteInitiateur : empreinteInitiateur,
            empreinteRecepteur : this.state.champsEmpreinteRecepteur,
            valueLoaned:this.state.champsValueLoaned,
            valuePayBack:this.state.valuePayBack,
            timeToPayBack:this.state.champsEcheance,
            failurePayBack:this.state.valueFailurePayBack,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwtClient")}}).then((response)=>{
            if(response.data.resultat === "La proposition de prêt a été effectuée."){
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
                }
                Swal.fire({
                    title: 'Succès!',
                    text: "La proposition de prêt a été effectuée.",
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    window.location.reload()
                })
            } else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }
    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    render() { //Affichage sur la page
        const { classes } = this.props;
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto",minHeight:"285px", width:600,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        {this.state.phase === 1 ? (
                            <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase1(e)}} autoComplete="off">
                                <h1>Connectez vous à votre compte</h1>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsPseudo"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez votre pseudonyme."} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Pseudo" variant="outlined"/>
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsClef"} className={"champsTexte"} type={this.state.showPassword ?"text" : "password"} required={true} value={this.state.value} placeholder={"Entrez votre Clef Unique."} onChange={(e)=>{this.handleChange(e)}} id="champsClef" label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }}
                                    />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button type={"submit"} variant="contained">Suivant</Button>
                            </form>
                        ):("")}
                        {this.state.phase === 2 ? (
                            <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase2(e)}} autoComplete="off">
                                <h3>Conditions du contrat :</h3>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <p style={{display:"flex",alignItems:"center"}}>Je prête &nbsp;
                                        <TextField name={"champsValueLoaned"} style={{width:90}} type={"number"} required={true} value={this.state.value} placeholder={"STC"} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="STC" variant="outlined"/>
                                        &nbsp;STC à la personne ayant pour empreinte
                                    </p>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsEmpreinteRecepteur"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Demandez son empreinte à la personne concernée."} onChange={(e)=>{this.handleChange(e)}} id="champsClef" label="Empreinte du récépteur" variant="outlined" />
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <p style={{display:"flex",alignItems:"center"}}>Le taux est de &nbsp;
                                        <TextField name={"champsTaux"} style={{width:90}} required={true} value={this.state.value} placeholder={"10"} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Taux" InputProps={{endAdornment:this.state.champsTaux ?(<InputAdornment position='end'>%</InputAdornment>):(<span/>) }} variant="outlined"/>
                                        &nbsp;et l'échéance est le &nbsp;
                                        <TextField name={"champsEcheance"} type={"date"} value={this.state.value} required={true} onChange={(e)=>{this.handleChange(e)}} variant="outlined" />
                                    </p>
                                </div>
                                <Button style={{margin:10}} type={"submit"} variant="contained">Suivant</Button>
                            </form>
                        ):("")}
                        {this.state.phase === 3 ? (
                            <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase3(e)}} autoComplete="off">
                                <h3>Récapitulatif :</h3>
                                <div style={{display:"flex", flexDirection:"column", alignItems:"center",justifyContent:"center"}}>
                                    <p style={{display:"flex",alignItems:"center"}}>Vous pretez {this.state.champsValueLoaned}STC à la personne avec l'empreinte</p>
                                    <p>{this.state.champsEmpreinteRecepteur}</p>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <p>Vous reçevrez {this.state.valuePayBack}STC avant le {this.state.champsEcheance}</p>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <p>En cas de non remboursement, vous serez remboursé de {this.state.valueFailurePayBack}STC</p>
                                </div>

                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <Button style={{margin:10}} type={"submit"} variant="contained">Confirmer</Button>
                                    <Button style={{margin:10}} variant="contained" onClick={()=>{window.location.reload()}}>Annuler</Button>
                                </div>

                            </form>
                        ):("")}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PropositionDePret)