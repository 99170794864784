import React, {Component} from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip
} from '@mui/material';
import {withRouter} from '../../../shared/NavigationUtils';
import Axios from "axios";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import config from "../../../json/config.json";
import Swal from "sweetalert2";
import tooltipContenu from "../../../json/contenu.json";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const useStyles = {
    header:{
        height: 70,
        width:"100vw",
        backgroundColor:"#337ab7",
        textAlign:"center",
        boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)',
        zIndex:10
    },
    button:{
        display:'flex',
        width: "100%",
        textTransform:'none',
        justifyContent:'start'
    },
    containerTableBrowser:{
        minWidth:500,
    },
    containerTableMobile:{
        minWidth:80,
        height:"100% !important",
        borderRadius:"0px !important",
        marginLeft:65
    },
    contenuTableCellBrowser:{
    },
    contenuTableCellMobile:{
        fontSize:"0.75rem !important",
        overflowX:"auto"
    }
};

let verif,nom,email,entreprise,emailEntreprise,urlChamps,iban,bic;
let saveKmCO2,saveCo2Token,saveValoToken;

class Parametres extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialogModif : false,
            openDialogFournisseur : false,
            openDialogAssociation : false,
            openLoad:false,
            kmCO2 : '',
            co2Token : '',
            valoToken : '',
            verification : '',
        };
    }
    componentDidMount() {
        this.setState({
            pseudo : sessionStorage.getItem('SPURO_pseudo'),
            hash : sessionStorage.getItem('SPURO_hash'),
            jwt : sessionStorage.getItem("SPURO_jwt")

        },()=>{
            global.emitter.emit("onRouteChanged",{
                pageName:'Paramètres Ekoco',
                pseudo:this.state.pseudo
            })
            Axios.post(url+"connexionAdmin", {
                hash : this.state.hash,
                hashJwt : this.state.hash
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                if (response.data.resultat === "Inconnu."){
                    this.props.navigate('/ConnexionAdmin')
                }
                global.emitter.emit("onConnexionAdmin",{
                    entrepriseName:response.data.entrepriseName,
                    pseudo:this.state.pseudo
                })
            })
            this.requeteAxiosGetTaux();
        })

    }

    handleChange(event){ //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        let value = event.target.value;
        if (event.target.name === 'verification'){
            this.setState({[event.target.name]:event.target.value})
        }else{
            if (value[(value.length)-1] === ','){ //remplacer les virgules par des points => format nombre
                value = value.slice(0,(value.length-1))+'.';
            }
            if (parseFloat(value) == value || value === "." || value.length === 0){
                this.setState({
                    [event.target.name]:value
                });
            }
        }
    }

    handleClickDialogModif(){
        this.setState({
            openDialogModif : true
        });
        saveKmCO2 = this.state.kmCO2
        saveCo2Token = this.state.co2Token
        saveValoToken = this.state.valoToken
    };
    handleClickDialogFournisseur(){
        this.setState({
            openDialogFournisseur : true
        });
    };
    handleClickDialogAssociation(){
        this.setState({
            openDialogAssociation : true
        });
    };
    handleCloseDialog(){
        this.setState({
            openDialogModif : false,
            openDialogFournisseur : false,
            openDialogAssociation : false,
            verification:'',
            valoToken:saveValoToken,
            co2Token:saveCo2Token,
            kmCO2:saveKmCO2
        });
    };

    requeteAxiosGetTaux(){
        Axios.post(url+"getTokenCo2Kms").then(response => {
            this.setState({
                kmCO2 : response.data.kmCo2,
                co2Token : response.data.co2Token,
            })
        }).catch(error => {
            console.log(error);
        });
    }

    handleValidation(){
        return true;
    }

    sendModifTaux(e){
        e.preventDefault();
        if(this.handleValidation()){
            Axios.post(url+"modifTaux", {
                hashAdmin: this.state.hash,
                kmCo2 : this.state.kmCO2,
                co2Token : this.state.co2Token,
                verification : this.state.verification,
                hashJwt:this.state.hash
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                if (response.data.resultat !== "Mauvais mot de passe !"){
                    this.setState({
                        verification:"",
                    })
                    saveKmCO2 = this.state.kmCO2
                    saveCo2Token = this.state.co2Token
                    Swal.fire({
                        title: 'Succès!',
                        text: response.data.resultat,
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                    this.handleCloseDialog();
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Le mot de passe de vérification est incorrect.",
                        target:".MuiDialog-root",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    handleSubmitFournisseur(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        const data= new FormData(event.target);
        entreprise = data.get('champsEntreprise'); emailEntreprise = data.get('champsEmail');verif = data.get('champsVerif');
        event.preventDefault();
        Axios.post(url+"BCBFournisseurInscription", {
            email : emailEntreprise,
            entreprise : entreprise,
            verif : verif,
            hashJwt:this.state.hash
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === 'Pseudo déjà utilisé' || response.data.resultat === 'Mauvais mot de passe'){
                    Swal.fire({
                        title: 'Erreur!',
                        text: response.data.resultat+".",
                        target:".MuiDialog-root",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
            }else{
                Swal.fire({
                    title: 'Succès!',
                    text: "Le fournisseur a bien été créé.",
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
                this.handleCloseDialog();
            }
        }).catch(error => {
            console.log(error);
        });
    }

    handleSubmitAssociation(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        const data= new FormData(event.target);
        nom = data.get('champsNom'); urlChamps = this.correctSyntaxe(data.get('champsWeb')); email = data.get('champsEmail'); iban = data.get('champsIban'); bic = data.get('champsBic');verif = data.get('champsVerif');
        event.preventDefault();
        if(()=>{this.handleValidation()}){
            Axios.post(url+"BCBCreationAssociation", {
                nom : nom,
                url:urlChamps,
                email : email,
                iban:iban,
                bic:bic,
                verif : verif,
                hashJwt:this.state.hash
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                if (response.data === 'Mauvais mot de passe'){
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Mot de passe incorrect.",
                        target:".MuiDialog-root",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
                else{
                    Swal.fire({
                        title: 'Succès!',
                        text: "L'association a bien été ajoutée",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                    this.handleCloseDialog();
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    correctSyntaxe(value){
        if (value.includes("http://")){
            value = value.replace("http://","")
        }else if (value.includes("https://")){
            value = value.replace("https://","")
        }
        if (value[value.length-1] === '/'){
            value = value.slice(0,-1)
        }
        if(!value.includes("www.")){
            value = "www."+value
        }

        return value
    }


    render(){ //Affichage sur la page
        return (

            <div style={{display:"flex" ,backgroundColor:"#72aacc",minHeight:"calc(100vh - 70px)",width:"100%"}}>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>En attente de validation...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
                <Dialog
                    open={this.state.openDialogModif}
                    onClose={()=>{this.handleCloseDialog()}}
                >
                    <DialogTitle>Modification des taux</DialogTitle>
                    <DialogContent>
                        <form style={{display:"flex", justifyContent:"center", flexDirection:"column"}} onSubmit={(e)=>{this.sendModifTaux(e)}} >
                            <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                                <p> <span style={{fontWeight: "bold"}}> Taux C02 : </span> 1 km =  </p>
                                <TextField style={{width:"80px", margin:"12px",marginRight:"0px"}} name={"kmCO2"} className={"champsTexte"} value={this.state.kmCO2 || ''} onChange={(e)=>{this.handleChange(e)}}  id="outlined-basic champsKms" required={true} label="CO2" variant="outlined"/>
                                <p>C02</p>
                            </div>
                            <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                                <p> <span style={{fontWeight: "bold"}}>Taux de Tokens :  </span> 1 CO2 =</p>
                                <TextField style={{width:"80px", margin:"12px",marginRight:"0px"}} name={"co2Token"} className={"champsTexte"} value={this.state.co2Token || ''} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsC02"  required={true} label="Token" variant="outlined"/>
                                <p>Token(s)</p>
                            </div>
                            <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                                <span style={{fontWeight: "bold"}}>Mot de passe administrateur :  </span>
                                <TextField style={{width:"150px", margin:"12px",marginRight:"0px"}} name={"verification"} className={"champsTexte"} value={this.state.verification || ''} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic" placeholder={"Vérification"} type={"password"} required={true} variant="outlined"/>
                            </div>
                            <Button style={{margin:"10px", padding:"10px"}} variant={"outlined"} type={"submit"}  color="primary" >Modifier</Button>
                        </form>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={this.state.openDialogFournisseur}
                    onClose={()=>{this.handleCloseDialog()}}
                >
                    <DialogTitle>{"Inscription Fournisseur"}</DialogTitle>
                    <DialogContent>
                        <form style={{display:"flex", flexDirection:"column", justifyContent:"center"}} onSubmit={(e)=>{this.handleSubmitFournisseur(e)}} >
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsEntreprise"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsEntreprise" required={true} label="Nom de l'entreprise" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipNomEntreprise}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsEmail"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsEmail" required={true} type={"email"} label="Email" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmailEntreprise}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsVerif"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic" required={true} type={"password"} label="Mot de passe administrateur" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipVerif}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <Button style={{margin:"10px", padding:"10px"}} variant={"outlined"} type={"submit"}  color="primary" >Inscrire</Button>
                        </form>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openDialogAssociation}
                    onClose={()=>{this.handleCloseDialog()}}
                >
                    <DialogTitle>{"Inscription Association"}</DialogTitle>
                    <DialogContent>
                        <form style={{display:"flex", flexDirection:"column", justifyContent:"center"}} onSubmit={(e)=>{this.handleSubmitAssociation(e)}} >

                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsNom"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} className={"champsTexte"} id="outlined-basic champsNom" required={true} label="Nom" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipNomAssoc}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsWeb"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsWeb" required={true} label="Url du site" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipURL}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsEmail"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsEmail" required={true} type={"email"} label="Email" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmailAssoc}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsIban"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsIban" required={true} label="IBAN" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIban}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsBic"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsBic" required={true} label="BIC" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipBic}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsVerif"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic" required={true} type={"password"} label="Mot de passe administrateur" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipVerif}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>

                            <Button style={{margin:"10px", padding:"10px"}} variant={"outlined"} type={"submit"}  color="primary" >Inscrire</Button>
                        </form>
                    </DialogContent>
                </Dialog>
                <div style={{display:"flex" ,backgroundColor:"#72aacc",height:"100vh",width:"100%"}}>
                    <div style={{minHeight:150,width:"100%", display:"flex",justifyContent:"center",alignItems:"center", justifyItems:"center", overflow:"auto"}}>
                        <div style={{overflowY:"auto",minHeight:"30px",backgroundColor:"white",display:"flex",flexDirection:"column",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                            <h1 style={{marginBottom:'10px'}}>Menu admin : {this.state.pseudo}</h1>
                            <Button onClick={()=>{this.handleClickDialogFournisseur()}} style={{margin:"5px"}} variant="contained">Créer un compte Fournisseur</Button>
                            <Button onClick={()=>{this.handleClickDialogAssociation()}} style={{margin:"5px"}} variant="contained">Créer un compte Association</Button>
                            <Button style={{margin:5, marginBottom:20}} onClick={()=>{this.handleClickDialogModif()}} color={"primary"} variant="contained">Voir/Modifier les Taux</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Parametres)
