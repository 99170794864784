import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {isBrowser} from "react-device-detect";

import Axios from "axios";
import config from "../json/config.json";

import {Button, CircularProgress, TextField, Tooltip} from "@mui/material";
import tooltipContenu from "../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Swal from "sweetalert2";
import Dropzone from "react-dropzone";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class RecuperationClefUnique extends Component {
    constructor(props) {
        super(props);
        this.state = {
            champsPseudo:"",
            champsCodeSecret:"",
            resultToDisplay:"",
            resultatOubli:false,
            modePDF:true,
            acceptedFiles:null,
            alreadyAsked:false
        };
    }
    componentDidMount() {
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }
    }

    handleSubmit() {
        if(!this.state.alreadyAsked){
            this.setState({alreadyAsked:true})
            let pseudoWritten = this.state.champsPseudo
            let codeSecret = this.state.champsCodeSecret
            if(pseudoWritten === "" || (this.state.modePDF && (this.state.binary === undefined) ) || !this.state.modePDF && codeSecret===""){
                this.setState({alreadyAsked:false})
                Swal.fire({
                    title: 'Erreur!',
                    text: "Veuillez entrer toutes les informations nécessaires pour récupérer votre clef unique.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }else{
                const queryParams = new URLSearchParams(window.location.search);
                Axios.post(url+"forgottenKey", {
                    pseudo:pseudoWritten,
                    clefChiffree:codeSecret,
                    isPdf:this.state.modePDF,
                    code:queryParams.get("code"),
                    pdfBuffer:this.state.binary||undefined,
                }).then(response=>{
                    this.setState({alreadyAsked:false})
                    if(response.data.resultat === "exist"){
                        this.setState({resultToDisplay:response.data.clefUnique})
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }

                })
            }
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex" ,backgroundColor:"#72aacc",height:"100vh",width:"100%"}}>
                    <div style={{height:"calc(100vh - 70px)",width:"100%", display:"flex",justifyContent:"center",alignItems:"center", justifyItems:"center", overflow:"auto"}}>
                        <div style={{overflowY:"auto",minHeight:"285px", width:600,backgroundColor:"#FFFFFF",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                            <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase1(e)}} autoComplete="off">
                                <h2>Récupération de votre clef unique :</h2>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsPseudo"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez votre identifiant."} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Identifiant" variant="outlined"/>
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", width:"100%"}}>
                                    <p onClick={()=>{this.setState({modePDF:!this.state.modePDF})}} className={"clickChangeModeRecupClef"} style={{backgroundColor:this.state.modePDF ? "#8a8a8a":"#FFFFFF",color:this.state.modePDF ? "#FFFFFF":"#8a8a8a",border:this.state.modePDF ? "solid 1px black":"solid 0px black"}}>PDF</p>
                                    <p onClick={()=>{this.setState({modePDF:!this.state.modePDF})}} className={"clickChangeModeRecupClef"} style={{backgroundColor:this.state.modePDF ? "#FFFFFF":"#8a8a8a", color:this.state.modePDF? "#8a8a8a":"#FFFFFF",border:this.state.modePDF ? "solid 0px black":"solid 1px black"}}>Texte</p>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center",width:"100%"}}>
                                    {this.state.modePDF ? (
                                        <Dropzone multiple={false} maxFiles={1} onDrop={(acceptedFiles) => {
                                            if (acceptedFiles[0].size < 500000 && acceptedFiles[0].type === "application/pdf"){
                                                this.setState({acceptedFile:acceptedFiles})
                                                const reader = new FileReader()
                                                reader.onload = () => {
                                                    const binaryStr = reader.result
                                                    this.setState({binary:Buffer.from(binaryStr,"base64")})
                                                }
                                                reader.readAsArrayBuffer(acceptedFiles[0])
                                            }else{
                                                Toast.fire({
                                                    title:"Le fichier déposé ne correspond pas au pdf de récupération de votre clef unique",
                                                    icon:"error"
                                                })
                                            }
                                        }}>
                                            {({getRootProps, getInputProps}) => (
                                                <section className={"dropzoneClefOubliee"} style={{width:"100%", marginBottom:25,backgroundColor:this.state.binary&&"rgb(90,255,0)"}}>
                                                    <div {...getRootProps()} style={{padding:20,borderRadius:5, border:"solid black 1px", cursor:"pointer", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                                        <input {...getInputProps()} />
                                                        {this.state.binary ? (
                                                            <p>Pdf correctement déposé.</p>
                                                        ):(
                                                            <p>Déposez le pdf contenant votre code de récupération ici.</p>
                                                        )}
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    ):(
                                        <>
                                            <TextField name={"champsCodeSecret"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez le code secret contenu dans votre pdf."} onChange={(e)=>{this.handleChange(e)}} id="champsClef" variant="outlined" />
                                            <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipOubliClef}>
                                                <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                                {this.state.resultToDisplay ? (
                                    <div style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
                                        <p >Votre clef unique : </p>
                                        <h4 style={{marginLeft:5}}>{this.state.resultToDisplay}</h4>
                                    </div>
                                ):(
                                    <Button disabled={this.state.alreadyAsked} style={{marginBottom:25}} onClick={()=>{this.handleSubmit()}} variant="contained">
                                        {(this.state.alreadyAsked === true && this.state.resultToDisplay ==="") ? (
                                            <CircularProgress/>
                                        ):(
                                            <>
                                                Récupérer ma clef
                                            </>
                                        )}
                                    </Button>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RecuperationClefUnique)