import React, {Component} from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip
} from '@mui/material';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MailIcon from '@mui/icons-material/Mail';
import tooltipContenu from "../../../json/contenu.json";
import config from "../../../json/config.json";
import {isBrowser} from "react-device-detect";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let email, iban, bic, token;

let jwtDecoded

class MonCompte extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bicValue : null, ibanValue : null, emailValue : null,displayed:false,displayed2:false,
            marginLeftContact:20,
            marginRightContact:10,
            statsContentWidth:"95%",
            statsContentMinWidth:"0%",
            statsContentMaxWidth:"100%",
            marginTopContent:0,
        };
    }

    componentDidMount() {
        this.setState({
            jwt: sessionStorage.getItem("EKOCO_jwt"),
        }, () => {
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url + "verifyJwt", {}, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
                if (response.data.jwt) {
                    sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    }, () => {
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true) {
                    this.setState({
                        pseudo: jwtDecoded.pseudo
                    }, () => {
                        this.requeteAxios();
                    })
                } else {
                    this.props.navigate('/Ekoco/BasCarboneBlockchain', { replace: true })
                }
            })
        })
        if (isBrowser){
            this.setState({
                marginLeftContact:50,
                marginRightContact:100,
                statsContentWidth:"50%",
                statsContentMinWidth:400,
                statsContentMaxWidth:450,
                marginTopContent:50,
            })
        }
    }

    displayContact(){
        this.setState({
            displayed:true,
        })
    }

    handleChangeModif=(event)=> { //handleChange permet de mettre a jour la valeur des inputs en temps réel
        const value = event.target.value;
        if (event.target.name === 'champsEmail'){
            this.setState({emailValue : value});
        }
        else if (event.target.name === 'champsIBAN'){
            this.setState({ibanValue : value});
        }
        else if (event.target.name === 'champsBIC'){
            this.setState({bicValue: value});
        }
    }
    handleCloseDialog = () => {
        this.setState({
            displayed : false,
            displayed2 : false
        });
    };
    handleOpenDialog = () => {
        this.setState({
            displayed2 : true
        });
    };

    handleChange=(event) => { //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }
    handleSubmitModif=(event)=> { //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        const data= new FormData(event.target);
        email = data.get('champsEmail'); iban = data.get('champsIBAN'); bic = data.get('champsBIC');
        event.preventDefault();
        if(this.handleValidation()){
            Axios.post(url+"BCBModifInfos", {
                email : email,
                iban : iban,
                bic : bic,
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                if(response.data.jwt){
                    sessionStorage.setItem("EKOCO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                if (response.data.resultat === 'Merci, votre compte a été mis à jour !'){
                    this.handleCloseDialog();
                    Swal.fire({
                        title: 'Succès!',
                        text: "Merci, votre compte a été mis à jour!",
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    })
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: response.data.resultat,
                        target:".MuiDialog-root",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    handleValidation=()=>{ //Fonction servant a la vérification des données avant l'envoi du formulaire
        return true
    }
    requeteAxios(){
        Axios.post(url+"BCBMonCompte", {},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
            if(response.data.jwt){
                sessionStorage.setItem("EKOCO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            let dataRecues = response.data;
            email = dataRecues.email; iban = dataRecues.iban; bic = dataRecues.bic; token = dataRecues.token;

            this.setState({
                emailValue: email,
                bicValue : bic,
                ibanValue : iban,
            })

            global.emitter.emit("onRouteChangedEkoco",{
                page:'MonCompte'
            });
        }).catch(error => {
            console.log(error);
        });
    }
    handleSubmitContact=(event) => {
        const data= new FormData(event.target);
        let emailSender = data.get('champsEmail'), sujet = data.get('champsSujet'), message = data.get('champsMessage');
        event.preventDefault();
        this.setState({
            openLoad:true
        })
        Axios.post(url+"contact", {
            email : emailSender,
            sujet : sujet,
            message : message,
        }).then(response => {
            this.setState({
                openLoad:false
            })
            Swal.fire({
                title: 'Succès!',
                text: response.data,
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            this.handleCloseDialog()
        }).catch(() => {
            this.setState({
                openLoad:false
            })
            Swal.fire({
                title: 'Erreur!',
                text: "Erreur lors de l'envoie de l'email.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        });
    }

    sendFooterLink(e){
        this.props.navigate(
            {
                pathname: '/ekoco/BasCarboneBlockchain',
                link: e.target.id
            }
        )
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>
                <Dialog
                    open={this.state.displayed}
                    onClose={()=>{this.setState({displayed:false})}}
                >
                    <DialogTitle>Contactez nos administrateurs <span style={{color:"#6abfa2", fontWeight:"bold"}}>Ekoco</span> !</DialogTitle>
                    <form onSubmit={(e)=>{this.handleSubmitContact(e)}} noValidate autoComplete="off">
                        <DialogContent>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsEmail"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} id="outlined-basic champsClef" required={true} type={"email"} label="Entrez votre email" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmailContact}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsSujet"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} id="outlined-basic champsPseudo" required={true} label="Entrez votre sujet" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipSujet}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsMessage"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} multiline={true} rows={6} rowsMax={10} id="outlined-basic champsPrenom" required={true} label="Entrez un message" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipMessage}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>                            </div>
                            <Button style={{padding:"10px", width:"100%",backgroundColor:"#5ecaab",color:"white"}} variant={"outlined"} type={"submit"} >Envoyer</Button>

                        </DialogContent>
                    </form>
                </Dialog>

                <Dialog
                    open={this.state.displayed2}
                    onClose={this.handleCloseDialog}
                >
                    <DialogTitle >Modifiez vos <span style={{color:"#6abfa2", fontWeight:"bold"}}>informations</span> personnelles !</DialogTitle>
                    <form onSubmit={(e)=>{this.handleSubmitModif(e)}} noValidate autoComplete="off">
                        <DialogContent style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                                <TextField style={{width:"100%"}} name={"champsEmail"} value={this.state.emailValue || ''} onChange={this.handleChangeModif} className={"champsTexte"} id="outlined-basic champsEmail" required={true} label="Email Ekoco" variant="outlined"/>
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>                            </div>
                            <div style={{display:"flex", width:"100%",justifyContent:"center", alignItems:"center"}}>
                                <div className={"inputHelpDiv"} style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                                    <TextField style={{display:"flex", flex:"6"}} name={"champsIBAN"} className={"champsTexte"} value={this.state.ibanValue === "Non enregistré" ? (''):(this.state.ibanValue)} onChange={this.handleChangeModif} required={true} id="outlined-basic champsIBAN" label="Code IBAN" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIban}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5, marginRight:5}} className={"helpIcon"} />
                                    </Tooltip>                                    <TextField style={{display:"flex", flex:"3"}} name={"champsBIC"} className={"champsTexte"} value={this.state.bicValue === "Non enregistré" ? (''):(this.state.bicValue)} onChange={this.handleChangeModif} required={true} id="outlined-basic champsBIC" label="BIC" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipBic}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>                                </div>
                            </div>
                            <Button style={{backgroundColor:"#5ecaab",padding:10, color:"white",width:"35%", fontWeight:"bold", margin:5}} type={"submit"}>Modifier</Button>
                        </DialogContent>
                    </form>
                </Dialog>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end"}}>
                    <p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold"}}>{this.state.pseudo}</p>
                    <AccountCircleIcon style={{color:"#b0a9a8"}}/>
                    <Button onClick={()=>{this.displayContact()}} style={{marginLeft:this.state.marginLeftContact, marginRight:this.state.marginRightContact, backgroundColor:"#5ecaab", color:"white", padding:"10px 30px", fontWeight:"bold", zIndex:2}}>Contact</Button>
                </div>
                {/*DEBUT RENDER STATIQUE*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)"}}>
                    {isBrowser === true ? (<div style={{display:"flex",flex:3, justifyContent:"flex-end", alignItems:"flex-end", zIndex:2}}>
                        <img style={{width:398, height:450}} src={require("../assetsEkoco/REPORTING.png")} alt={"Reporting"}/>
                    </div>):("")}
                    <div style={{marginTop:this.state.marginTopContent,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",marginBottom:20,flex:2}}>
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                            <p style={{fontWeight:"bold", fontSize:39,marginBottom:0}}>Paramétrez</p>
                            <p style={{fontWeight:"bold", fontSize:39, marginBottom:20,marginTop:0}}>votre compte !</p>
                        </div>
                        <div style={{backgroundColor:"#5ecaab", width:this.state.statsContentWidth, minWidth:this.state.statsContentMinWidth, maxWidth:this.state.statsContentMaxWidth, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:30, paddingTop:30, borderRadius:10}}>
                            <p style={{marginBottom:20,fontSize:20, color:"white"}}>Voici vos informations personnelles !</p>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", flexDirection:"column", marginBottom:10}}>
                                <div style={{display:"flex",flex:1, height:50,}}>
                                    <AccountCircleIcon style={{width:45,height:45,color:"#2e4a5a"}}/>
                                    <p style={{color:"#b0a9a8", fontWeight:"bold"}}>Pseudo :</p>
                                </div>
                                <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                    <p style={{color:"#e39e35", fontWeight:"bold", fontSize:"150%", margin:0}}>{this.state.pseudo}</p>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", flexDirection:"column", marginBottom:10}}>
                                <div style={{display:"flex",flex:1, height:50,}}>
                                    <MailIcon style={{width:45,height:45,color:"#2e4a5a"}}/>
                                    <p style={{color:"#b0a9a8", fontWeight:"bold"}}>Email :</p>
                                </div>
                                <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                    <p style={{color:"#e39e35", fontWeight:"bold", fontSize:"100%", margin:0}}>{this.state.emailValue}</p>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", flexDirection:"column", marginBottom:10}}>
                                <div style={{display:"flex",flex:1, height:50,}}>
                                    <AccountBalanceIcon style={{width:45,height:45,color:"#2e4a5a"}}/>
                                    <p style={{color:"#b0a9a8", fontWeight:"bold"}}>IBAN :</p>
                                </div>
                                <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                    <p style={{color:"#e39e35", fontWeight:"bold", fontSize:"100%", margin:0}}>{this.state.ibanValue}</p>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", flexDirection:"column", marginBottom:10}}>
                                <div style={{display:"flex",flex:1, height:50,}}>
                                    <AccountBalanceIcon style={{width:45,height:45,color:"#2e4a5a"}}/>
                                    <p style={{color:"#b0a9a8", fontWeight:"bold"}}>BIC :</p>
                                </div>
                                <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                    <p style={{color:"#e39e35", fontWeight:"bold", fontSize:"150%", margin:0}}>{this.state.bicValue}</p>
                                </div>
                            </div>
                            <Button style={{marginTop:"20px",backgroundColor:"#e39e35",fontWeight:"bold", color:"white"}} onClick={()=>{this.handleOpenDialog()}} variant={"contained"} >Modifier mes informations</Button>
                        </div>
                    </div>
                    {isBrowser === true ? (<div style={{display:"flex",flex:3}}>
                        <img style={{width:450, height:419}} src={require("../assetsEkoco/STATISTIQUE2.png")} alt={"Statistiques"}/>
                    </div>):("")}
                </div>
                {/*FIN RENDER STATIQUE*/}
                {/*FOOTER*/}
                <div style={{width:"100%", height:"100%",paddingTop:10, backgroundColor:"#f5f6f6", display:"flex",flexWrap:"wrap", marginTop:50, justifyContent:"center", alignItems:"center", zIndex:4}}>
                    <div style={{width:"90%", height:"90%",display:"flex", flexWrap:"wrap"}}>
                        {isBrowser === true ? (
                            <div style={{display:"flex", flex:3, width:250, height:250, justifyContent:"center", alignItems:"center"}}>
                                <img style={{width:285,height:200, textAlign:"center"}} src={require("../assetsEkoco/Ekoco.png")} alt="LogoEkoco"/>
                            </div>
                        ):("")}
                        <div style={{display:"flex", flex:3, flexDirection:"column", justifyContent:"center"}}>
                            <h1 className={"arialExtraBold"} style={{margin:0,color:"#2c2e35"}}>L’application qui fait</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>DU BIEN</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>À LA PLANÈTE</h1>
                        </div>
                        <div style={{display:"flex",flex:4}}>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=cestQuoi")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO c’est quoi ?</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=commentCaMarche")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Comment ça marche ?</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=reporting")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Un reporting détaillé</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=pourEntreprise")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO pour les entreprises</p>
                            </div>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/PolitiqueDeConfidentialite")}}>Politique de confidentialité</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/ConditionsGeneralesDutilisation")}}>CGU</p>
                                <p className={"arialArrow"} onClick={()=>{this.displayContact()}} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Contacts</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Utilisation des cookies</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Le mail va être envoyé dans quelques instants ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default  withRouter(MonCompte);