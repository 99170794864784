import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {IconButton, InputAdornment, TextField, Tooltip} from '@mui/material';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import {Close, FilterAlt, Person, Search} from "@mui/icons-material";
import jwt_decode from "jwt-decode";

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Design","Développement","Marketing","Informatique et logiciels","Développement personnel","Business","Photographie","Musique","Divers"
]
const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let socketIO; //Initialisation de l'instance du socket

class Catalogue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            archiVideosDisplay:[],
        };
    }

    setInitialStates(){
        return{
            rechercheValue:"",
            rechercheDisplay:null,
            showMenuCategories:false,
            jwtDecoded:null,
            showBrowserView:true,
            allCatSelected:[]
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    _emitConnectedBR
    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedBR = global.emitter.addListener("emitConnectedBR",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("BUYRESALE_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt)
                })
                global.emitter.emit("onRouteChangedBuyResale", {
                    jwt: this.state.jwt,
                })
            })
        })

        this.setState({
            jwt:sessionStorage.getItem("BUYRESALE_jwt"),
        },()=>{
            this.setState({
                jwtDecoded : this.state.jwt ? jwt_decode(this.state.jwt) : {}
            })
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        this.setState({
                            jwtDecoded : jwt_decode(this.state.jwt)
                        })
                    })
                }
                if (response.data.resultat === true){
                    this.setState({
                        jwtDecoded : jwt_decode(this.state.jwt)
                    })
                    global.emitter.emit("onRouteChangedBuyResale", {
                        jwt: this.state.jwt,
                    })
                }else{
                    sessionStorage.removeItem("BUYRESALE_jwt")
                    global.emitter.emit("onRouteChangedBuyResale", {
                        jwt: null,
                    })
                }
                this.getAllVideos()
            })
        })
    }

    getAllVideos(){
        Axios.post(url+"getAllVideos",{}).then(response=>{
            if (response.data.resultat === "done"){
                console.log(response.data)
                this.setState({
                    archiVideosDisplay:response.data.videos,
                    archiVideos:response.data.videos

                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Une erreur est survenue.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    clickRecherche(e){
        e.preventDefault()
        this.setState({rechercheDisplay:true})
        let toDisplay = [], recherche = this.state.rechercheValue.toLowerCase()
        this.state.archiVideos.forEach(video => {
            if (video.fileName.toLowerCase().includes(recherche) || video.owner.toLowerCase().includes(recherche)){
                toDisplay.push(video)
            }
        })
        this.setState({
            archiVideosDisplay:toDisplay
        })
    }

    catManager(cat){ //On gère les catégories ici
        let allCatSelected = this.state.allCatSelected
        if(allCatSelected.includes(cat)){
            allCatSelected.splice(allCatSelected.indexOf(cat),1)
        }else{
            allCatSelected.push(cat)
        }
        this.setState({
            [cat+"State"]:!this.state[cat+"State"],
            allCatSelected
        })
        let toDisplay = []
        if(allCatSelected.length>0){ // Si on selectionne une catégorie ou +
            this.state.archiVideos.forEach(video => {
                allCatSelected.forEach(catSelected=>{
                    if (video.categorie.toLowerCase().includes(catSelected.toLowerCase())){
                        toDisplay.push(video)
                    }
                })
            })
        }else{ //Si aucune catégorie n'est selectionné, on affiche tout
            this.state.archiVideos.forEach(video => {
                toDisplay.push(video)
            })
        }
        this.setState({
            archiVideosDisplay:toDisplay
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"93%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:this.state.showBrowserView && "center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                <div style={{width:"fit-content", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                    <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Catalogue :</h1>
                                    <div style={{background: "linear-gradient(49deg, rgba(114,170,204,1) 0%, rgba(250,182,3,1) 100%)", width:175, height:3, borderRadius:100, marginBottom:20}}></div>
                                </div>
                                <div className={"flexCenter"}>
                                    <form style={{marginRight:15}} onSubmit={(e)=>{this.clickRecherche(e)}}>
                                        <TextField style={{width:this.state.showBrowserView ? 300:"calc(100% - 52px)"}} variant={"outlined"} onChange={(event)=>{this.handleChange(event)}} value={this.state.rechercheValue} name={"rechercheValue"} placeholder={"Rechercher une vidéo..."} label={"Recherche"} InputLabelProps={{ shrink: true }}
                                                   InputProps={{
                                                       endAdornment:
                                                           <InputAdornment position="end">
                                                               <IconButton disabled={this.state.rechercheValue === ""} style={{opacity:this.state.rechercheValue === "" && 0}} onClick={(e)=>{this.setState({rechercheValue:""},()=>{this.clickRecherche(e)})}}>
                                                                   <Close/>
                                                               </IconButton>
                                                           </InputAdornment>
                                                   }}
                                        ></TextField>
                                        <button type={"submit"} className={"divButtonBuyResale"} style={{padding:14}}> <Search/> </button>
                                    </form>
                                    <div onMouseLeave={()=>{this.setState({showMenuCategories:false})}} className={"flexCenter"}>
                                        <button onClick={()=>{this.setState({showMenuCategories:!this.state.showMenuCategories})}} className={"divButtonBuyResale flexCenter"} style={{padding:16}}> Catégories <FilterAlt/> </button>
                                        <div style={{position:"relative",width:"100%", direction:"rtl"}}>
                                            <div className={"divMenuCat"} style={{marginTop:this.state.showBrowserView ? 28:-29,transform:this.state.showMenuCategories ? "scaleY(1)":"scaleY(0)", zIndex:99999,display:"flex", alignItems:"flex-start", justifyContent:"space-evenly", flexWrap:"wrap"}}>
                                                {categories.map(elt=>(
                                                    <>
                                                        <div style={{opacity:this.state[elt+"State"] ? 1:0.4}} className={"borderButtonBRCat"}>
                                                            <button onClick={()=>{this.catManager(elt)}} className={"divButtonBuyResaleCat"} style={{margin:2, flex:"1 1 30px"}} >{elt}</button>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                {this.state.archiVideosDisplay.length !== 0 ? (
                                    <>
                                        {this.state.archiVideosDisplay.map(vid=>(
                                            <div onAuxClick={(e)=>{if(e.button===1){window.open("/BuyResale/Token?id="+vid.idToken, "_blank")}}} onClick={()=>{this.props.navigate("/BuyResale/Token?id="+vid.idToken)}} className={"divMyVideo"} style={{flexDirection:"column", background: "rgba(0,0,0,.5)",display:"flex", alignItems:"center",justifyContent:"flex-start", overflow:"hidden", border:!vid.verified && "2px solid #ff8100"}}>
                                                <div style={{width:'100%', position:"relative"}}>
                                                    <div style={{height:31, textAlign:"center",position:"absolute", zIndex:999, width:"100%", background: "rgba(0,0,0,.6)"}}>
                                                        <h4 style={{margin:5, textAlign:"center", color:"white"}} >{vid.titre.length>32 ? (vid.titre.slice(0,28)+"..."):(vid.titre)}</h4>
                                                    </div>
                                                </div>
                                                <div style={{width:'100%', position:"relative"}}>
                                                    {this.state.jwtDecoded.empreinte === vid.empreinte && (
                                                        <div style={{position:"absolute", padding:2, zIndex:9999,borderRadius:10, width :"fit-content", marginBottom:10}}>
                                                            <Tooltip title={"Cette vidéo vous appartient."}>
                                                                <Person style={{color:"#00d2ff"}}/>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                    <div style={{position:"absolute", padding:2, zIndex:9999,borderRadius:10, width :"fit-content", marginBottom:10, top:175}}>
                                                        <h4 className={"textShadowBorder"} style={{color:"#FFFFFF", margin:0,fontWeight:"normal"}}>Par <span style={{fontWeight:"bold"}}>{vid.owner}</span></h4>
                                                    </div>
                                                    <div style={{position:"absolute",direction:"rtl", alignSelf:"flex-end", padding:2, zIndex:9999,borderRadius:10, width :"98%", marginBottom:10, top:175}}>
                                                        <h4 className={"textShadowBorder"} style={{color:"#FFFFFF", margin:0,fontWeight:"normal"}}>€ <span style={{fontWeight:"bold"}}>{vid.prixU}</span> </h4>
                                                    </div>
                                                </div>
                                                <div style={{overflow:"hidden", width:"100%",height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <img style={{width:"100%", position:"absolute"}} src={`${vid.srvDoc}images/miniatures/${vid.idToken}.png`} alt={`${vid.fileName.split("##")[1]}`}/>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ):(
                                    <h3>Aucune vidéo n'est disponible{this.state.allCatSelected.length>0 ? (this.state.allCatSelected.length===1 ? " avec cette catégorie" : " avec ces catégories"):""} pour le moment...</h3>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Catalogue)