import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {Button, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Axios from "axios";
import config from "../json/config.json";

import AdmZip from "adm-zip";
// import {DataGrid} from '@mui/x-data-grid';
import {FixedSizeList} from 'react-window';
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let recherche="", clear = "none";

const listRef = React.createRef();

let jwtDecoded;

class Registre extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateDrawer : true,
            displayAdministration : false,
            fullRegistre: [],
            fullRegistreRecherche:[],
            indexFullRegistreRecherche:null,
            registreChosen:"registre.txt",
            allRegistres:[],
            recherche:''
        };
    }

    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt", {},
                {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat !== true){
                    this.props.navigate('/ConnexionAdmin')
                }else{
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:jwtDecoded.pseudo
                    })
                    global.emitter.emit("onRouteChanged",{
                        pageName:'Registre',
                        pseudo:jwtDecoded.pseudo,
                    })
                    if (jwtDecoded.gestionnaire === true){
                        this.requeteAxios();
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Vous n'êtes pas autorisé à accéder à cette page",
                            icon: 'error',
                            target:".MuiDialog-root",
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/Administration/AccueilAdmin")
                        })
                    }
                }
            })
        })
    }

    requeteAxios(){
        Axios.post(url+"affichageRegistre",{
            regToRead:"registre.txt",
        },{headers:{Authorization:"Bearer " + this.state.jwt}}
        ).then(response => {
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            const fullRegistre = response.data.resultat
            this.setState({
                fullRegistre:fullRegistre,
                fullRegistreRecherche: fullRegistre,
            },()=>{
                this.getAllRegistres();
            })
        }).catch(error => {
            console.log(error);
        });
    }

    getAllRegistres(){
        Axios.post(url+"getAllRegistres",{
                hashJwt:this.state.hash
            },{headers:{Authorization:"Bearer " + this.state.jwt}}
        ).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            this.setState({
                allRegistres:response.data.resultat,
            })
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleClickRecherche(e){
        e.preventDefault();
        recherche = this.state.recherche
        let tempArray = []
        this.state.fullRegistre.map(row=>{
            if (row.includes(recherche)){
                let lineToPush = []
                let rowTemp = row.split(recherche)
                rowTemp.forEach((elt,index)=>{ //Système de surlignage
                    if(index === rowTemp.length-1){
                        lineToPush.push([elt])
                    }else{
                        lineToPush.push([elt,<span style={{backgroundColor:"yellow"}}>{recherche}</span>])
                    }
                })
                tempArray.push([<p style={{width:((row.length)*(9))}}>{lineToPush}</p>])
            }
        })
        clear = "block"
        this.setState({
            fullRegistreRecherche : tempArray
        })
    }

    clearFilter(){
        recherche="";
        clear = "none";
        this.setState({
            fullRegistreRecherche : this.state.fullRegistre,
            recherche : ""
        })
    }

    download(){
        let zipFile = new AdmZip();
        new Promise(async resolve => {
            resolve(await this.downloadEachReg(this.state.allRegistres.slice(), zipFile, this.state.jwt))
        }).then(zip=>{
            let willSendthis = zip.toBuffer(); //On transforme le zip en Buffer Uint8Array pour le télécharger dans le front plus tard.
            let blob = new Blob([willSendthis], {type: "application/zip"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            //Manipulation de la date pour l'afficher dans un bon format
            let todayDate = new Date(Date.now()).toLocaleString()
            todayDate = todayDate.split(" ")[0]
            todayDate = todayDate.split("/")
            let firstElt = todayDate[0]
            todayDate[0] = todayDate.pop()
            todayDate.push(firstElt)
            todayDate = todayDate.toString()
            todayDate = todayDate.replaceAll(",","-")
            //On télécharge le registre en .zip dans le front
            link.download = "SauvegardeRegistre-" + todayDate + ".zip";
            link.click();
        })
    }
    async downloadEachReg(allReg, zipFile, jwt){
        return new Promise(async resolve => {
            Axios.post(url+"affichageRegistre",{
                regToRead:allReg[0],
            },{headers:{Authorization:"Bearer " + jwt}}).then(response=> {
                zipFile.addFile(allReg[0].toString(), (this.registreToDlBackup(response.data.resultat)).toString(),"add file to zip") //On ajoute les .txt dans le zip
                allReg.shift()
                if (allReg.length>0){
                    resolve(this.downloadEachReg(allReg, zipFile, response.data.jwt ? response.data.jwt:jwt))
                }else{
                    if(response.data.jwt){
                        sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    resolve(zipFile)
                }
            })
        })
    }

    handleChangeSelect(e){
        this.setState({
            registreChosen:e.currentTarget.textContent
        },()=>{
            Axios.post(url+"affichageRegistre",{
                regToRead:this.state.registreChosen,
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                const fullRegistre = response.data.resultat;
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                this.setState({
                    fullRegistre:fullRegistre,
                    fullRegistreRecherche:fullRegistre,
                })
            })
        })
    }

    registreToDlBackup(regToConvert){
        let resultat = '';
        for (let i = 0; i<regToConvert.length;i++){
            resultat += regToConvert[i];
            resultat += '\n';
        }
        return resultat
    }

    render() { //Affichage sur la page
        const Row = ({ index, style }) => (
            <div style={style}>
                <p style={{width:((this.state.fullRegistreRecherche[index].length)*(9))}}>{this.state.fullRegistreRecherche[index]}</p>
            </div>
        );
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{width:"90%", display:"flex", marginTop:10}}>
                        <div style={{width:'100%'}}>
                            {/*Boutons : DL / Recherche / Icone loupe*/}
                            <div style={{display:"flex", justifyContent:"center", padding:20, flexWrap:"wrap"}}>
                                <FormControl>
                                    <InputLabel>Registre</InputLabel>
                                    <Select
                                        value={this.state.registreChosen}
                                        onChange={(e)=>{this.handleChangeSelect(e)}}
                                        MenuProps={{PaperProps: {sx: {maxHeight: 250}}}}
                                    >
                                        {(this.state.allRegistres).map((reg)=>{
                                            return(<MenuItem key={reg} value={reg}>{reg}</MenuItem>)
                                        })}

                                    </Select>
                                </FormControl>
                                <Button style={{color:"#000"}} onClick={()=>{this.download()}}>Sauvegarder les registres</Button>
                                <form style={{display:"flex",height:"56px",flexDirection:"row",justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleClickRecherche(e)}}>
                                    <TextField style={{borderRadius:5}} name={"recherche"} value={this.state.recherche} onChange={(e)=>{this.handleChange(e)}} label="Recherche" variant={"outlined"} />
                                    <Button style={{height:"56px"}} type={"submit"} variant={"outlined"}> <SearchIcon/> </Button>
                                </form>
                                <div style={{display:"flex", justifyContent:"center",alignItems:"center"}} >
                                    <p style={{marginLeft:"20px"}}>{recherche}</p>
                                    <ClearIcon style={{display:clear}} onClick={()=>{this.clearFilter()}}/>
                                </div>
                            </div>
                            {/*Boutons : fleche vers le haut*/}
                            <div style={{display:"flex", justifyContent:"center"}}>
                                <Button onClick={()=>{listRef.current.scrollToItem(0)}} style={{marginBottom:"10px", backgroundColor:"#FFF"}} variant={"contained"}>  <ArrowDropUpIcon style={{color:"#000"}}/> </Button>
                            </div>
                            {/*Affichage du registre*/}
                            <div style={{display:"flex",fontSize:"13.5px",overflowX:"scroll !important",width:"85vm", border:"solid black 1px", boxShadow:" 2px 2px 20px -5px rgba(0,0,0,0.5)"}}>
                                <FixedSizeList
                                    height={600}
                                    width={"100%"}
                                    itemSize={30}
                                    itemCount={this.state.fullRegistreRecherche.length}
                                    style={{color: "black", backgroundColor:"white"}}
                                    ref={listRef}
                                >
                                    {Row}
                                </FixedSizeList>
                            </div>
                            {/*Boutons : fleche vers le bas*/}
                            <div style={{display:"flex",justifyContent:"center"}}>
                                <Button  onClick={()=>{listRef.current.scrollToItem(this.state.fullRegistreRecherche.length)}} style={{marginTop:"10px", backgroundColor:"#FFF"}} variant={"contained"}>  <ArrowDropDownIcon style={{color:"#000"}}/> </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Registre)