import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import {CalendarMonth} from "@mui/icons-material";
import jwt_decode from "jwt-decode";
import {CircularProgress, LinearProgress, Tooltip} from "@mui/material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class ListeLDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            archiVideoPublished:[],
            archiVideoBought:[],
            showBrowserView:true,
            showMobileView2:false
        };
    }

    setInitialStates(){
        return{
            connected:false,
            listeLDF:[]
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 1400 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 1400 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
        if(document.body.offsetWidth <= 1030 && this.state.showMobileView2 === false){
            this.setState({showMobileView2:true})
        }else if(document.body.offsetWidth > 1030 && this.state.showMobileView2 === true){
            this.setState({showMobileView2:false})
        }
    }
    _emitConnectedBR
    componentDidMount() {
        if(document.body.offsetWidth <= 1400){
            this.setState({showBrowserView: false})
        }
        if(document.body.offsetWidth <= 1030){
            this.setState({showMobileView2: true})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedBR = global.emitter.addListener("emitConnectedSMSTO",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("SMSTO_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedStockMarketSTO", {
                    jwt: this.state.jwt,
                })
            })
        })
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    this.setState({connected:true})
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: this.state.jwt,
                    })
                }
                this.getAllFundRaising()
                // else{
                //     Swal.fire({
                //         title: 'Veuillez vous reconnecter',
                //         text: "Votre session a expiré, veuillez vous reconnecter.",
                //         icon: 'warning',
                //         confirmButtonText: 'Ok'
                //     }).then(()=>{
                //         sessionStorage.removeItem("SMSTO_jwt")
                //         global.emitter.emit("onRouteChangedStockMarketSTO", {
                //             jwt: null,
                //         })
                //         window.location.replace("/StockMarketSTO/Accueil")
                //     })
                // }
            })
        })
    }

    getAllFundRaising(){
        Axios.post(url+"getAllFundRaising",{empreinte:jwtDecoded.empreinte||null},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let listeLDFTemps = response.data.fundRaisings
                let mine = response.data.mine
                if(mine !== false){
                    mine.mine=true
                    listeLDFTemps.unshift(mine)
                }
                //Modifier chaque objets
                listeLDFTemps.forEach((entrepriseTemp)=>{

                    /*On calcule le nombre de jours entre le début et la fin de la levée de fonds*/
                    const diffInMsTotal   = new Date(entrepriseTemp.dateFin) - new Date(entrepriseTemp.dateDebut)
                    entrepriseTemp.joursTotaux = (diffInMsTotal / (1000 * 60 * 60 * 24))+1

                    /*On calcul le nombre de jours entre aujourd'hui et la fin de la levée de fonds*/
                    const diffInMs   = new Date(entrepriseTemp.dateFin) - new Date(Date.now())
                    entrepriseTemp.joursRestants = parseInt(diffInMs / (1000 * 60 * 60 * 24))+1

                    /*Calcul pourcentage du de l'avancement de la levée de fonds (date)*/
                    entrepriseTemp.percentDate = (entrepriseTemp.status === "notStarted" || entrepriseTemp.status === "unconfirmed") ? ( /*Si ça n'as pas encore démarré*/
                        0
                    ):(
                        entrepriseTemp.status === "done" ? ( /*Si la levée de fonds est terminée*/
                            100
                        ):(
                            parseInt((entrepriseTemp.joursTotaux - entrepriseTemp.joursRestants)*100/entrepriseTemp.joursTotaux) /*Si c'est en cours*/
                        )
                    )
                    /*Calcul pourcentage du de l'avancement de la levée de fonds (actions achetées)*/
                    entrepriseTemp.numberActions = parseInt(entrepriseTemp.numberActions)
                    entrepriseTemp.percentReserved = (entrepriseTemp.status === "notStarted" || entrepriseTemp.status === "unconfirmed") ? ( /*Si ça n'as pas encore démarré*/
                        0
                    ):(
                        parseFloat(entrepriseTemp.reservedActions*100/entrepriseTemp.numberActions) /*Si c'est en cours ou terminé*/
                    )
                    entrepriseTemp.percentReserved = Math.ceil(entrepriseTemp.percentReserved)

                    /*Format Date Début*/
                    entrepriseTemp.dateDebut = entrepriseTemp.dateDebut.split("-")
                    entrepriseTemp.dateDebut = entrepriseTemp.dateDebut.reverse()
                    entrepriseTemp.dateDebut = entrepriseTemp.dateDebut.join("/")

                    /*Format date Fin*/
                    entrepriseTemp.dateFin = entrepriseTemp.dateFin.split("-")
                    entrepriseTemp.dateFin = entrepriseTemp.dateFin.reverse()
                    entrepriseTemp.dateFin = entrepriseTemp.dateFin.join("/")
                })


                this.setState({listeLDF:listeLDFTemps})
            }else{
                Swal.fire({
                    title: 'Veuillez vous reconnecter',
                    text: "Votre session a expiré, veuillez vous reconnecter.",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column",alignItems:"flex-start"}}>
                            <div style={{width:"fit-content", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Liste des levées de fonds :</h1>
                                <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:"100%", height:3, borderRadius:100, marginBottom:20}}></div>
                            </div>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                {this.state.listeLDF.length === 0 && (
                                    <h3 style={{fontWeight:400}}>Les levées de fonds déployées sont terminées. Prochaine étape octobre 2023 !</h3>
                                )}
                                {this.state.listeLDF.map((elt,index)=>(
                                    <div onClick={()=>{this.state.connected ? this.props.navigate("/StockMarketSTO/Entreprise?td="+elt.siren):(global.emitter.emit("askConnexionSMSTO"))}} className={"divLDF flexCenter"} style={{justifyContent:this.state.showMobileView2 ? "center":"space-between",height:this.state.showMobileView2 && "fit-content",flexDirection:this.state.showMobileView2 && "column", border:(elt.mine && elt.status === "unconfirmed")&& "1px solid orange"}}>
                                        <div className={"flexCenter"} style={{width:!this.state.showMobileView2 && (!this.state.showBrowserView ?"50%":"29%"), height:"100%", flexDirection:"column", alignItems:"flex-start", justifyContent:"space-between"}}>
                                            <h2 className={"flexCenter"} style={{margin:0}}>
                                                <img style={{width:45, marginRight:10}} src={`${url}images/smsto_entreprise/${elt.siren}/logo.png`} alt="logo"/>
                                                {elt.nomEntreprise}
                                            </h2>
                                            <img style={{width:"100%", minWidth:260, maxWidth:430, marginLeft:0,borderRadius:5}} src={`${url}images/smsto_entreprise/${elt.siren}/illustration.png`} alt="illus"/>
                                        </div>
                                        <div className={"flexCenter"} style={{width:!this.state.showMobileView2 && (!this.state.showBrowserView ?"50%":"39%"),height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                            <div className={"flexCenter"} style={{marginTop:this.state.showMobileView2 && 10}}>
                                                <CalendarMonth style={{marginRight:3}}/>
                                                <h2 style={{margin:0, fontWeight:"normal"}}> Début le <span style={{fontWeight:"bold"}}> {elt.dateDebut}</span> - Fin le <span style={{fontWeight:"bold"}}> {elt.dateFin}</span> </h2>
                                            </div>
                                            <div style={{width:"100%", marginBottom:this.state.showMobileView2&&20, marginTop:this.state.showMobileView2&&20}}>
                                                <h3 style={{width:"100%", textAlign:"left", margin:0}}>Description :</h3>
                                                <h4 style={{width:"100%", textAlign:"left", margin:0, fontWeight:"normal", fontStyle:!elt.desc && "italic"}}>{elt.desc.length > 300 ? (this.state.showMobileView2 ? (elt.desc.slice(0,300)+" (...) "):elt.desc):(elt.desc) || "Aucune description n'est disponible pour cette levée de fonds"}</h4>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                                <LinearProgress style={{width:"90%", height:30, borderRadius:5}} variant="determinate" value={elt.percentDate} />
                                                <div className={"flexCenter"} style={{position:"relative", width:"90%"}}>
                                                    {(elt.percentDate === 100 && elt.status === "done") ? (
                                                        <div style={{width:`${elt.percentDate}%`, textAlign:"center"}}>
                                                            <p style={{position:"absolute",width:"inherit", margin:0, top:-25, color:"#FFF"}}>Levée de fonds terminée</p>
                                                        </div>
                                                    ):(
                                                        <>
                                                            {elt.percentDate > 0 && (
                                                                <div style={{width:`${elt.percentDate}%`, textAlign:"center"}}>
                                                                    <p style={{position:"absolute",width:"inherit", margin:0, top:-25, color:"#FFF"}}>{elt.joursTotaux-elt.joursRestants === -1 ? ("Levée de fonds terminée"):(elt.joursTotaux-elt.joursRestants + " jours")}</p>
                                                                </div>
                                                            )}
                                                            {100-elt.percentDate > 0 && (
                                                                <div style={{width:`${100-elt.percentDate}%`, textAlign:"center"}}>
                                                                    <p style={{position:"absolute",width:"inherit", margin:0, top:-25, color:"#FFF"}}>{elt.joursRestants > elt.joursTotaux ? ("Levée de fonds non démarrée"):(elt.joursRestants+" jours")}</p>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <div className={"flexCenter"} style={{width:"90%", justifyContent:"flex-start"}}>
                                                    <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                        <div style={{backgroundColor:"#1976d2", width:20, height:20, borderRadius:5, marginRight:5}}/>
                                                        <p>Temps écoulé</p>
                                                    </div>
                                                    <div className={"flexCenter"} style={{width:"fit-content", marginLeft:20}}>
                                                        <div style={{backgroundColor:"#a7caed", width:20, height:20, borderRadius:5, marginRight:5}}/>
                                                        <p>Temps restant</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"flexCenter"} style={{width:"29%",height:"100%",flexDirection:"column", justifyContent:"space-between", display:!this.state.showBrowserView && "none"}}>
                                            <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                                <div className={"flexCenter"} style={{width:"80%", justifyContent:"flex-start", flexDirection:"row-reverse"}}>
                                                    <div className={"flexCenter"} style={{width:"fit-content",marginLeft:20}}>
                                                        <p>Actions réservées</p>
                                                        <div style={{backgroundColor:"#1976d2", width:20, height:20, borderRadius:5, marginLeft:5}}/>
                                                    </div>
                                                    <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                        <p>Actions disponibles</p>
                                                        <div style={{backgroundColor:"#a7caed", width:20, height:20, borderRadius:5, marginLeft:5}}/>
                                                    </div>
                                                </div>
                                                <div className={"flexCenter"} style={{transform:"scale(1.2)",margin:35,width:95, height:95, borderRadius:"50%", backgroundColor:"transparent", border:"27px solid #a7caed", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                                                    <CircularProgress style={{width:150, height:150, position:"absolute"}} variant={"determinate"} value={elt.percentReserved} thickness={8}/>
                                                    {!(100-elt.percentReserved === 0) && (
                                                        /*100 - percent reserved -> actions dispo*/
                                                        <div style={{width:210,height:210,position:"absolute", display:"flex", justifyContent:"center", alignItems:"flex-start", transform:`rotate(${(100-(100-elt.percentReserved)/2)/100}turn)`}}>
                                                            <p style={{display:100-elt.percentReserved === 100 && "none",margin:0, transform:`rotate(${(((100-elt.percentReserved)/2)/100)}turn)`}}>{100-elt.percentReserved}%</p>
                                                        </div>
                                                    )}
                                                    {!(elt.percentReserved === 0) && (
                                                        /*percent reserved -> actions réservées*/
                                                        <div style={{width:210,height:210,position:"absolute", display:"flex", justifyContent:"center", alignItems:"flex-start", transform:`rotate(${(elt.percentReserved/2)/100}turn)`}}>
                                                            <p style={{display:elt.percentReserved === 100 && "none",margin:0, transform:`rotate(${100-((elt.percentReserved/2)/100)}turn)`}}>{elt.percentReserved}%</p>
                                                        </div>
                                                    )}
                                                    <p className={"flexCenter"}>{elt.numberActions}<img style={{width:20, marginLeft:4}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/></p>
                                                </div>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-around"}}>
                                                <div className={"flexCenter"}>
                                                    <Tooltip title={"Investissement minimum de "+(elt.minActions||"?")+(elt.minActions > 1 ? "STOs" : "STO")+" et maximum de "+(elt.maxActions||"?")+"STOs"}>
                                                        <h2 className={"flexCenter"} style={{margin:0}}>
                                                            <span style={{fontSize:34}}>{elt.minActions||"?"}</span>
                                                            <img style={{width:31, marginTop:4}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                            ~
                                                            <span style={{fontSize:34}}> {elt.maxActions||"?"}</span>
                                                            <img style={{width:31, marginTop:4}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                        </h2>
                                                    </Tooltip>
                                                </div>
                                                <div className={"flexCenter"}>
                                                    <Tooltip title={"1 STO vaut "+elt.actionValue+"€"}>
                                                        <h2 className={"flexCenter"} style={{margin:0, fontSize:34}}>
                                                            1<img style={{width:31, marginTop:4, marginRight:10}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>= {elt.actionValue}<img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                        </h2>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <button className={"divButtonBuyResale"}>En savoir plus</button>
                                            </div>
                                        </div>
                                        {this.state.showMobileView2 && (
                                            <div className={"flexCenter"} style={{width:"100%"}}>
                                                <button style={{width:"100%"}} className={"divButtonBuyResale"}>En savoir plus</button>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ListeLDF)