import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {IconButton, InputAdornment, TextField, Tooltip} from '@mui/material';
import {sha256} from "js-sha256";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import tooltipContenu from "../../json/contenu.json";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {KeyboardReturn} from "@mui/icons-material";


const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const useStyles = {
    header:{
        height: 70,
        width:"100vw",
        backgroundColor:"#337ab7",
        textAlign:"center",
        boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)',
        zIndex:10
    },
    containerTableBrowser:{
        minWidth:600
    },
    containerTableMobile:{
        minWidth:350,
    }
};

class InscriptionBR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identifiantValue : "",
            passwordValue : "",
            emailValue:"",
            verifValue:"",
            passwordBisValue:"",
            showBrowserView:true,
            imageToShow:Math.floor(Math.random() * 2) + 1
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    submitFirstStepCreation(e){
        e.preventDefault()
        Swal.fire({
            icon:"info",
            text:"Il est primordial de ne pas perdre votre clef unique.\nIl ne s'agit pas d'un mot de passe, mais d'une clef de preuve.\nNous ne pourrons pas vous aider à récupérer l'accès à votre compte dans le cas où vous l'auriez perdu.",
            input: 'checkbox',
            inputValue: 0,
            target:"#dialogInscription",
            inputPlaceholder:
                "J'ai bien compris l'importance de ma clef unique.",
            confirmButtonText:
                "Continuer",
            inputValidator: (result) => {
                return !result && 'Vous devez accepter les conditions !'
            }
        }).then(()=> {
            let hash = sha256((this.state.identifiantValue.trim()).concat(this.state.passwordValue.trim())) //Hashage avec protocole sha256
            if((this.state.passwordValue.trim()).match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!.@#$%^&*?-])[A-Za-z\d!.@#$%^&*?-]{12,}$/)) {
                if(this.state.emailValue.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)){
                    if((this.state.identifiantValue.trim()).match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{4,29}$/)){
                        if(this.state.passwordBisValue.trim() === this.state.passwordValue.trim()){
                            Axios.post(url+"inscriptionBuyResale ", {
                                empreinte : hash,
                                pseudo:this.state.identifiantValue.trim(),
                                email : this.state.emailValue,
                                clefToEncrypt:this.state.passwordValue.trim(),
                                // nom:this.state.nomValue,
                                // prenom:this.state.prenomValue,
                                // ddn:this.state.DDNValue,
                                // iban:this.state.ibanValue ||"",
                                // bic:this.state.bicValue ||"",
                                // tel:this.state.telValue,
                            }).then(response => {
                                if (response.data.resultat === 'done'){
                                    Swal.fire({
                                        title: 'Succès!',
                                        text: "Le compte "+this.state.identifiantValue+" a bien été créé!",
                                        icon: 'success',
                                        confirmButtonText: 'Ok'
                                    }).then(()=>{
                                        global.emitter.emit("emitInscris", {
                                            identifiant: this.state.identifiantValue,
                                            password:this.state.passwordValue
                                        })
                                        this.props.navigate("/BuyResale/Accueil")
                                    })
                                }else{
                                    Swal.fire({
                                        title: 'Erreur!',
                                        text: response.data.resultat,
                                        icon: 'error',
                                        confirmButtonText: 'Ok'
                                    })
                                }
                            }).catch(error => {
                                console.log(error);
                            });
                        }else{
                            Swal.fire({
                                title: 'Erreur!',
                                text: "Vos deux clés uniques ne correspondent pas.",
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Votre identifiant ne peut contenir que des lettres de a-z, des chiffres de 0-9, des tiret bas (_) et des points.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Votre email est dans un format incorrect.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Votre clef unique ne respecte pas les critères de sécurité.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    // validateCreation(code){
    //     let hash = sha256((this.state.identifiantValue.trim()).concat(this.state.passwordValue.trim())) //Hashage avec protocole sha256
    //     Axios.post(url+"inscriptionBuyResale ", {
    //         empreinte : hash,
    //         pseudo:this.state.identifiantValue.trim(),
    //         email : this.state.emailValue,
    //         clefToEncrypt:this.state.passwordValue.trim(),
    //         // nom:this.state.nomValue,
    //         // prenom:this.state.prenomValue,
    //         // ddn:this.state.DDNValue,
    //         // iban:this.state.ibanValue ||"",
    //         // bic:this.state.bicValue ||"",
    //         // tel:this.state.telValue,
    //         code
    //     }).then(response => {
    //         if (response.data.resultat === 'done'){
    //             Swal.fire({
    //                 title: 'Succès!',
    //                 text: "Le compte "+this.state.identifiantValue+" a bien été créé!",
    //                 icon: 'success',
    //                 confirmButtonText: 'Ok'
    //             }).then(()=>{
    //                 global.emitter.emit("emitInscris", {
    //                     identifiant: this.state.identifiantValue,
    //                     password:this.state.passwordValue
    //                 })
    //                 this.props.navigate("/BuyResale/Accueil")
    //             })
    //         }else{
    //             Swal.fire({
    //                 title: 'Erreur!',
    //                 text: response.data.resultat,
    //                 icon: 'error',
    //                 confirmButtonText: 'Ok'
    //             })
    //         }
    //     }).catch(error => {
    //         console.log(error);
    //     });
    // }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{minHeight:"100vh",width:"100%"}}>
                    <div className={"flexCenter"} style={{flex:"1 1 10%",flexDirection:"column", height:"100vh", zIndex:99, backgroundColor:"#fafafa"}}>
                        <div style={{overflowY:"auto",minHeight:"30vh", minWidth:this.state.showBrowserView && 425,width:this.state.showBrowserView ?"27vw":"95%",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                            <div style={{width:'100%', position:"relative"}}>
                                <Tooltip title={"Retour"}>
                                    <IconButton onClick={()=>{this.props.navigate(-1)}} style={{alignSelf:"left", position:"absolute", cursor:"pointer", margin:5, padding:5, color:"black"}}>
                                        <KeyboardReturn/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <h3 className={"flexCenter"} style={{textAlign:"center"}}>
                                Inscrivez-vous chez
                                <img style={{textAlign:"center", width:100,marginLeft:10}} src={require("../../assets/LOGO_BUYRESALE.png")} alt="logoKageSecure"/>
                            </h3>
                            <form autoComplete="off" onSubmit={(e)=>{this.submitFirstStepCreation(e)}} style={{display:"flex",flexDirection:"column",width:"85%", marginBottom:20}}>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField style={{width:"100%", marginBottom:10}} required={true} name={"identifiantValue"} onChange={(event)=>{this.handleChange(event)}} label="Identifiant" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <p style={{margin:0, padding:0,fontSize:13}}>Règles de sécurité - Votre identifiant : </p>
                                <p style={{margin:0, padding:0, fontSize:11}}>- ne doit <span style={{fontWeight:"bold"}}> pas contenir d'espace </span></p>
                                <p style={{margin:0, padding:0, fontSize:11}}>- peut contenir <span style={{fontWeight:"bold"}}> des "_" et des "." </span> sauf en fin d'identifiant </p>
                                <p style={{margin:0, padding:0, fontSize:11, marginBottom:8}}>- doit contenir <span style={{fontWeight:"bold"}}> au moins 5 caractères </span> </p>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField style={{width:"100%", marginBottom:10}} required={true} type={"email"} name={"emailValue"} onChange={(event)=>{this.handleChange(event)}} label="Email" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField autoComplete="new-password" style={{width:"100%", marginBottom:10}} required={true} type={this.state.showPassword ? "text":"password"} name={"passwordValue"} onChange={(event)=>{this.handleChange(event)}} label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }}
                                    />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <p style={{margin:0, padding:0,fontSize:13}}>Règles de sécurité - Votre clef unique doit contenir : </p>
                                <p style={{margin:0, padding:0, fontSize:11}}>- au moins une <span style={{fontWeight:"bold"}}> majuscule et minuscule</span></p>
                                <p style={{margin:0, padding:0, fontSize:11}}>- au moins un <span style={{fontWeight:"bold"}}> chiffre </span></p>
                                <p style={{margin:0, padding:0, fontSize:11}}>- au moins un <span style={{fontWeight:"bold"}}> symbole (!.@#$%^&*?-)</span></p>
                                <p style={{margin:0, padding:0, fontSize:11}}>- aucun <span style={{fontWeight:"bold"}}> espace </span></p>
                                <p style={{margin:0, padding:0, fontSize:11, marginBottom:8}}>- au moins 12 <span style={{fontWeight:"bold"}}> caractères </span></p>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField autoComplete="new-password" style={{width:"100%", marginBottom:10}} required={true} type={this.state.showPasswordVerif ? "text":"password"} name={"passwordBisValue"} onChange={(event)=>{this.handleChange(event)}} label="Confirmez votre Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPasswordVerif:!this.state.showPasswordVerif})}}
                                                           >
                                                               {this.state.showPasswordVerif ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }}
                                    />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <button className={"divButtonBuyResale flexCenter"} style={{width:"100%", marginTop:10}} type={"submit"}>Créer mon compte<ChevronRightIcon/></button>
                            </form>
                        </div>
                    </div>
                    {this.state.showBrowserView && (
                        <div className={"flexCenter"} style={{flex:1,flexDirection:"column", overflow:"hidden", height:"100vh",boxShadow:"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"}}>
                            <div className={"flexCenter"} style={{position:"absolute", zIndex:99, height:"100vh", flexDirection:"column"}}>
                                <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                                    <img style={{textAlign:"center", width: "80%",maxWidth:500,marginRight:5,userSelect: "none"}} src={require("../../assets/LOGO_BuyResale_blanc.png")} alt="Logo_SMSTO"/>
                                </div>
                                <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                                    <div style={{width:this.state.showBrowserView ?"70%":"95%",display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
                                        <h3 style={{margin:10, marginLeft:0,color:"#FFF",fontSize:25}}>Nous contacter :</h3>
                                        <p style={{margin:0,color:"#FFF",fontSize:20}}>Tel: (+33) 6 08 82 67 53</p>
                                        <p style={{margin:0,color:"#FFF", textAlign:"center",fontSize:20}}>Adresse : 9 rue Gustave Eiffel 10430 Rosière prés de Troyes</p>
                                        <a href={"https://buyresale.org/contact/"} target={"_blank"} style={{margin:0,color:"#FFF",fontSize:20}}>Nous contacter par mail</a>
                                    </div>
                                </div>
                                <div className={"flexCenter"} style={{flex:1, height:"100%", flexDirection:"column", overflowX:"hidden"}}>
                                    <h3 onClick={()=>{}} className={"textCGU"} style={{margin:5, fontWeight:"normal",color:"#FFF",fontSize:28}}>Politique de confidentialité</h3>
                                    <h3 onClick={()=>{}} className={"textCGU"} style={{margin:5, fontWeight:"normal",color:"#FFF",fontSize:28}}>Conditions générales d'utilisation</h3>
                                </div>
                            </div>
                            <img /*onClick={()=>{this.setState({imageToShow:this.state.imageToShow===4 ? 1:this.state.imageToShow+1})}}*/ style={{width:"125%", maxWidth:900, minWidth:600, filter:"brightness(0.4)"}} src={require("../../assets/portrait landscape"+this.state.imageToShow+".png")} alt="illustration paysage"/>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(InscriptionBR)