import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from '@mui/material';

import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {Add, Close, Delete, Euro, Security} from "@mui/icons-material";
import Dropzone from "react-dropzone";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";
import socketInstance from "../../Components/SocketClient";

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Design","Développement","Marketing","Informatique et logiciels","Développement personnel","Business","Photographie","Musique","Divers"
]
const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let socketIO; //Initialisation de l'instance du socket
let jwtDecoded;

class MesVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            archiVideoPublished:[],
            archiVideoBought:[],
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{
            displayUploadVideo:false,
            fournisseurTiers:false,
            valueCategorie:"",
            priceValue:"",
            valuePriceFournisseur:null,
            phaseUpload:1,
            typeChoiceMinia:1,
            miniaChosen:null,
            miniaturesToDisplay:[],
            progressUpload: "",
            acceptedFile:null,
            uploadingFile:false,
            miniaBuffer:null,
            idToken:"",
            selectedVideo:{},
            displayBackdrop:false
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("BUYRESALE_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    socketIO = socketInstance.connectionSocket(this.state.jwt)
                    global.emitter.emit("onRouteChangedBuyResale", {
                        jwt: this.state.jwt,
                    })
                    this.getUploadedVideos()
                    this.getBoughtVideos()
                    this.isMyProfileComplete()
                }else{
                    Swal.fire({
                        title: 'Veuillez vous reconnecter',
                        text: "Votre session a expiré, veuillez vous reconnecter.",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        sessionStorage.removeItem("BUYRESALE_jwt")
                        global.emitter.emit("onRouteChangedBuyResale", {
                            jwt: null,
                        })
                        window.location.replace("/BuyResale/Accueil")
                    })
                }
            })
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    isMyProfileComplete(){
        Axios.post(url+"getInfos",{},{
            headers:{
                Authorization:"Bearer " + this.state.jwt
            }
        }).then(response=>{
            if(response.data.resultat === "done"){
                this.setState({profileComplete:!!response.data.allInfosCompleted})
            }else{
                this.setState({profileComplete:false})
            }
        })
    }

    getUploadedVideos(){
        Axios.post(url + "getUploadedVideos", {}, {headers: {Authorization: "Bearer " + this.state.jwt}}).catch((err) => {
            console.log(err)
        }).then((response) => {
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat==="done"){
                this.setState({
                    archiVideoPublished:response.data.allFiles
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    getBoughtVideos(){
        Axios.post(url + "getBoughtVideos", {}, {headers: {Authorization: "Bearer " + this.state.jwt}}).catch((err) => {
            console.log(err)
        }).then((response) => {
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat==="done"){
                this.setState({
                    archiVideoBought:response.data.allFiles
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    uploadvideo(e){
        e.preventDefault()
        this.setState({uploadingFile:true,cancelUpload:false})
        let acceptedFile = this.state.acceptedFile
        this.setState({progressUpload:0, fileSent:"inProgress", cancel:false}, ()=>{
            socketIO.emit("askPermToUploadBuyResale", {empreinte:jwtDecoded.empreinte, path:"",fileName:acceptedFile.name,bearer:this.state.jwt}, (response) => {
                if(response.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.jwt)
                    this.setState({
                        jwt:response.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if(response.isAuthorized){
                    this.setState({idToken:response.idToken})
                    let dataFile = {
                        fileName: acceptedFile.name,
                        savedPath:response.codeUpload,
                        idToken:response.idToken
                    }
                    let stream = acceptedFile.stream()
                    let re = stream.getReader()
                    let SHA = CryptoJS.algo.SHA256.create()
                    let self = this
                    let numberOfPacketSent = 0, estimatedTime, packetTimesDuration =0, totalSizeAlreadySent = 0
                    new ReadableStream({
                        start(controller) {
                            function push() {
                                const packetStartTime = Date.now()
                                re.read().then( ({done, value}) => {
                                    numberOfPacketSent++
                                    if (done) {
                                        socketIO.emit("sendChunkBuyResale##"+response.codeUpload, {status:"ended",bearer:self.state.jwt}, () => {
                                            if(response.jwt){
                                                sessionStorage.setItem("BUYRESALE_jwt",response.jwt)
                                                self.setState({
                                                    jwt:response.jwt
                                                },()=>{
                                                    jwtDecoded = jwt_decode(self.state.jwt)
                                                })
                                            }
                                            const finalHash = SHA.finalize().toString()

                                            dataFile.hash = finalHash
                                            dataFile.categorie = self.state.valueCategorie
                                            dataFile.desc = self.state.descValue
                                            dataFile.prix = self.state.priceValue
                                            dataFile.title = self.state.titleValue
                                            dataFile.fournisseurTiers = self.state.fournisseurTiers
                                            dataFile.valuePriceFournisseur = self.state.valuePriceFournisseur
                                            uploadBuyResale()
                                            function uploadBuyResale() {
                                                Axios.post(url + "uploadBuyResale", dataFile, {headers: {Authorization: "Bearer " + self.state.jwt}}).catch((err) => {
                                                    console.log(err)
                                                }).then((response) => {
                                                    if(response.data.jwt){
                                                        sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                                                        this.setState({
                                                            jwt:response.data.jwt
                                                        },()=>{
                                                            jwtDecoded = jwt_decode(this.state.jwt)
                                                        })
                                                    }
                                                    if(response.data.resultat === "done"){
                                                        self.setState({
                                                            displayBackdrop:false,
                                                            displayUploadVideo:true,
                                                            uploadingFile:false,
                                                            progressUpload:100,
                                                            fileSent: response.data.resultat,
                                                            miniaturesToDisplay:response.data.miniatures ||[],
                                                            phaseUpload:2,
                                                        })
                                                        controller.close();
                                                    }
                                                        // else if (response.data.resultat==="Iban et bic manquants."){
                                                        //     self.setState({displayUploadVideo:false})
                                                        //     Swal.fire({
                                                        //         title: 'Des informations sont manquantes',
                                                        //         text: "Pour uploader une vidéo, vous devez renseigner votre IBAN et BIC afin de reçevoir vos paiements.",
                                                        //         icon: 'warning',
                                                        //         confirmButtonText: 'Ok'
                                                        //     }).then(()=>{
                                                        //         Swal.fire({
                                                        //             title: 'Renseignez vos informations',
                                                        //             html:
                                                        //                 '<p style="margin-bottom: 0; display: flex;align-items: flex-start;font-weight: bold" >IBAN</p>' +
                                                        //                 '<input id="swal-input1" class="swal2-input">' +
                                                        //                 '<p style="margin: 0; display: flex;align-items: flex-start;font-weight: bold" >BIC</p>' +
                                                        //                 '<input id="swal-input2" class="swal2-input">',
                                                        //             focusConfirm: false,
                                                        //             confirmButtonText:"Valider",
                                                        //             allowOutsideClick:false,
                                                        //             allowEscapeKey:false,
                                                        //             preConfirm: () => {
                                                        //                 if(document.getElementById('swal-input2').value && document.getElementById('swal-input1').value){
                                                        //                     self.setState({displayBackdrop:true})
                                                        //                     Axios.post(url+"editInfos",{
                                                        //                         iban:document.getElementById('swal-input1').value,
                                                        //                         bic:document.getElementById('swal-input2').value,
                                                        //                     },{headers:{Authorization:"Bearer " + self.state.jwt}}).then(response=>{
                                                        //                         console.log(response.data)
                                                        //                         if(response.data.resultat === "done"){
                                                        //                             Toast.fire({
                                                        //                                 icon: 'success',
                                                        //                                 title: 'IBAN et BIC modifiés.'
                                                        //                             })
                                                        //                             setTimeout(()=>{uploadBuyResale()},1000)
                                                        //                         }else{
                                                        //                             Toast.fire({
                                                        //                                 icon: 'warning',
                                                        //                                 title: 'Une erreur est survenue, ce fichier ne vous appartient pas.'
                                                        //                             })
                                                        //                         }
                                                        //                     })
                                                        //                 }else{
                                                        //                     Swal.fire({
                                                        //                         text: "Veuillez remplir les informations demandées correctement.",
                                                        //                         target:"#dialogUpload",
                                                        //                         icon: 'warning',
                                                        //                         confirmButtonText: "Ok"
                                                        //                     }).then(()=>{
                                                        //                         uploadBuyResale()
                                                        //                     })
                                                        //                 }
                                                        //             }
                                                        //         })
                                                        //     })
                                                    // }
                                                    else{
                                                        self.setState({...self.setInitialStates()})
                                                        Swal.fire({
                                                            title: 'Erreur!',
                                                            text: response.data.resultat,
                                                            icon: 'error',
                                                            confirmButtonText: 'Ok'
                                                        })
                                                        controller.close();
                                                    }
                                                })
                                            }
                                        })
                                    }else{
                                        socketIO.emit("sendChunkBuyResale##"+response.codeUpload,  {buffer:value, status:"upload",bearer:self.state.jwt}, (responseSC) => {
                                            if(response.jwt){
                                                sessionStorage.setItem("BUYRESALE_jwt",response.jwt)
                                                this.setState({
                                                    jwt:response.jwt
                                                },()=>{
                                                    jwtDecoded = jwt_decode(self.state.jwt)
                                                })
                                            }
                                            if(responseSC.isAuthorized){
                                                totalSizeAlreadySent = totalSizeAlreadySent+value.length
                                                let wordBuffer = CryptoJS.lib.WordArray.create(value);
                                                SHA.update(wordBuffer);
                                                const packetTime = (Date.now()-packetStartTime)/1000
                                                packetTimesDuration = (value.length/packetTime) + packetTimesDuration
                                                const debitMoyen = packetTimesDuration/(numberOfPacketSent+1)
                                                estimatedTime = parseInt((acceptedFile.size-totalSizeAlreadySent)/debitMoyen)
                                                const progressUploadEnCours = parseInt(totalSizeAlreadySent * 100 / acceptedFile.size)
                                                if(estimatedTime !== self.state.uploadEstimatedTime){
                                                    self.setState({
                                                        uploadEstimatedTime: estimatedTime
                                                    })
                                                }
                                                if(progressUploadEnCours !== self.state.progressUpload){
                                                    self.setState({
                                                        progressUpload:progressUploadEnCours
                                                    })
                                                }
                                                setTimeout(()=>{
                                                    if (!self.state.cancel && !self.state.cancelUpload){
                                                        push();
                                                    }else{
                                                        socketIO.emit("sendChunkBuyResale##"+response.codeUpload, {status:"cancel",bearer:self.state.jwt}, () => {
                                                            if(response.jwt){
                                                                sessionStorage.setItem("BUYRESALE_jwt",response.jwt)
                                                                self.setState({
                                                                    jwt:response.jwt
                                                                },()=>{
                                                                    jwtDecoded = jwt_decode(self.state.jwt)
                                                                })
                                                            }
                                                            Toast.fire({
                                                                icon: 'info',
                                                                target:"#dialogUpload",
                                                                title: 'Upload annulé !'
                                                            })
                                                        })
                                                    }
                                                },value.length/1000000)
                                            }else{
                                                Swal.fire({
                                                    title: 'Erreur!',
                                                    text: "Vous n'êtes pas autorisé à envoyer un fichier.",
                                                    target:"#dialogUpload",
                                                    icon: 'error',
                                                    confirmButtonText: 'Ok'
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                            push();
                        }
                    });
                }
            })
        })
    }

    selectMinia(e){
        e.preventDefault()
        Axios.post(url + "confirmMiniature", {
            idToken:this.state.idToken,
            choice:this.state.typeChoiceMinia,
            bufferMiniature:this.state.miniaBuffer !== null ? Buffer.from(this.state.miniaBuffer,"base64"):null,
            miniatureChosen:this.state.miniaChosen
        }, {headers: {Authorization: "Bearer " + this.state.jwt}}).catch((err) => {
            console.log(err)
        }).then((response) => {
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat==="done"){
                this.setState({...this.setInitialStates()})
                Toast.fire({
                    icon: 'success',
                    title: 'Vidéo ajoutée !'
                })
                this.getUploadedVideos()
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    target:"#dialogUpload",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.displayBackdrop}
                    id={"backdropLoading"}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                <Dialog
                    open={this.state.displayUploadVideo}
                    onClose={()=>{this.setState({displayUploadVideo:false})}}
                    id={"dialogUpload"}
                    fullScreen={!this.state.showBrowserView}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                >
                    <DialogTitle style={{marginTop:!this.state.showBrowserView && 90}}> <span style={{display:"flex", alignItems:"center", justifyContent:"flex-start"}}><Close style={{cursor:"pointer"}} onClick={()=>{this.setState({...this.setInitialStates()})}}/> Je publie ma formation : </span></DialogTitle>
                    {this.state.phaseUpload === 1 ? ( /*première phase de l'upload de vidéo*/
                        <form style={{width:"100%", overflowY:"auto"}} autoComplete="off" onSubmit={(e)=>{this.uploadvideo(e)}}>
                            <DialogContent className={"flexCenter"} style={{flexDirection:"column", width:this.state.showBrowserView ? "50vw":"85%"}}>
                                <div className={"flexCenter"} style={{width:"99%", flexDirection:!this.state.showBrowserView && "column"}}>
                                    <div style={{flex:1}}>
                                        <Dropzone disabled={this.state.acceptedFile!==null} multiple={false} onDrop={(acceptedFiles) => {
                                            let acceptedFilesArray = []
                                            acceptedFiles.forEach((file)=>{
                                                if(file.size >= sizeLimitFile){
                                                    Toast.fire({
                                                        icon: 'error',
                                                        target:"#dialogUpload",
                                                        title: 'La vidéo est trop lourde (>10GB) !'
                                                    })
                                                }else if(file.name.includes("##")){
                                                    Toast.fire({
                                                        icon: 'error',
                                                        target:"#dialogUpload",
                                                        title: 'Le nom de la vidéo ne doit pas comporter "##" !'
                                                    })
                                                }else if(!file.type.includes("video")){
                                                    Toast.fire({
                                                        icon: 'error',
                                                        target:"#dialogUpload",
                                                        title: 'Le fichier uploadé doit être une vidéo !'
                                                    })
                                                }else if (file.name.includes(".zip")){
                                                    Toast.fire({
                                                        icon: 'error',
                                                        target:"#dialogUpload",
                                                        title: "L'enregistrement de zip n'est pas autorisé."
                                                    })
                                                }else{
                                                    acceptedFilesArray.push(file)
                                                    Toast.fire({
                                                        icon: 'success',
                                                        target:"#dialogUpload",
                                                        title: "Fichier(s) correctement déposé(s), prêt à l'enregistrement."
                                                    })
                                                    this.setState({acceptedFile:acceptedFilesArray[0]})
                                                }
                                            })
                                        }}>
                                            {({getRootProps, getInputProps}) => (
                                                <section style={{textAlign:"center"}}>
                                                    <div id={this.state.acceptedFile===null &&"hoverMoving"} {...getRootProps()} style={{padding:this.state.showBrowserView ?"145px 80px 145px 80px":"120px 40px 120px 40px", marginRight:this.state.showBrowserView && 20, marginBottom:!this.state.showBrowserView&&20,borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold",cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                        <input {...getInputProps()} />
                                                        <>
                                                            {this.state.acceptedFile ? (
                                                                <h3 style={{textAlign:"center", margin:0}} className={"flexCenter"}>{this.state.acceptedFile.name.length>32 ? (this.state.acceptedFile.name.slice(0,28)+"..."):(this.state.acceptedFile.name)} <IconButton style={{marginLeft:5, color:"#000000"}} onClick={()=>{this.setState({acceptedFile:null})}}><Delete/></IconButton></h3>
                                                            ):(
                                                                <h3 style={{textAlign:"center", margin:0}}>Déposez votre vidéo ici.</h3>
                                                            )}

                                                            <p>{this.state.progressUpload && (
                                                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                                    <div style={{position:"relative"}}>
                                                                        <CircularProgress style={{height:55, width:55}}/>
                                                                        <p style={{position:"absolute", margin:0, width:40,textAlign:"center", top:16, right:8}}>{this.state.progressUpload || 10 + "%"}</p>
                                                                    </div>
                                                                    <p style={{fontSize:12,fontStyle:"italic",marginTop:5}}>Temps restant ≈ {this.state.uploadEstimatedTime>60 ? (parseInt(this.state.uploadEstimatedTime/60)+"m"):this.state.uploadEstimatedTime+"s"}</p>
                                                                </div>

                                                            )}</p>
                                                        </>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <div style={{flex:1}}>
                                        <TextField style={{width:"100%", marginBottom:10}} required={true} name={"titleValue"} onChange={(event)=>{this.handleChange(event)}} label="Titre" variant="outlined" />
                                        <TextField style={{width:"100%", marginBottom:10}} required={true} multiline={true} rowsMax={10} rows={5} name={"descValue"} onChange={(event)=>{this.handleChange(event)}} label="Description" variant="outlined" />
                                        <div style={{display:"flex", alignItems:"center",justifyContent:"center", width:"100%", marginBottom:10}}>
                                            <FormControl required={true} variant={"outlined"} style={{width:"100%"}}>
                                                <InputLabel>Catégorie</InputLabel>
                                                <Select
                                                    key="Catégorie"
                                                    value={this.state.valueCategorie}
                                                    onChange={(event)=>{
                                                        this.setState({
                                                            valueCategorie:event.target.value
                                                        })
                                                    }}
                                                    style={{width:"100%", color:"black"}}
                                                    label={"Catégorie"}

                                                >
                                                    {categories.map(cat=>(
                                                        <MenuItem value={cat}>{cat}</MenuItem>
                                                    ))}

                                                </Select>
                                            </FormControl>
                                            <Tooltip arrow={true} title={"Votre formation sera répertorié dans le catalogue associé à cette catégorie."}>
                                                <HelpOutlineIcon style={{marginLeft:5}} className={"helpIcon"} />
                                            </Tooltip>
                                        </div>
                                        <div style={{width:"100%", display:"flex", justifyContent:"space-around", alignItems:"center"}}>
                                            <TextField style={{width:"30%", marginRight:10}} required={true} value={this.state.priceValue} name={"priceValue"} onChange={(event)=>{this.handleChangeOnlyNum(event)}} label="Prix" variant="outlined"
                                                       InputProps={{
                                                           endAdornment:
                                                               <InputAdornment position="end">
                                                                   <p style={{color:"#000000"}}>€</p>
                                                               </InputAdornment>
                                                       }}
                                            />
                                            {this.state.fournisseurTiers ? (
                                                <div style={{width:"70%", borderRadius:5, marginLeft:10}}>
                                                    <TextField style={{width:"100%"}} required={true} value={this.state.valuePriceFournisseur} name={"valuePriceFournisseur"} onChange={(event)=>{this.handleChangeOnlyNum(event)}} placeholder={"Entrez le prix de vente"} label="Fournisseurs tiers autorisés" variant="outlined"
                                                               InputLabelProps={{ shrink: true }}
                                                               InputProps={{
                                                                   endAdornment:
                                                                       <InputAdornment position="end">
                                                                           <p style={{color:"#000000"}}>€</p>
                                                                           <Tooltip style={{cursor:"pointer"}} onClick={()=>{this.setState({fournisseurTiers:false})}}  title={"Annuler la vente aux fournisseurs tiers"}>
                                                                               <IconButton>
                                                                                   <Close/>
                                                                               </IconButton>
                                                                           </Tooltip>
                                                                       </InputAdornment>
                                                               }}
                                                    />
                                                </div>
                                            ):(
                                                <Tooltip title={"Autorise ou non la vente de votre formation à des fournisseurs qui pourront la revendre plusieurs fois. Un pourcentage de la vente vous reviendra."}>
                                                    <button onClick={()=>{this.setState({fournisseurTiers:true})}} className={"divButtonBuyResale"} style={{textTransform:"none",padding:10, width:"100%",backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)"}}><h4 style={{margin:9, color:"#FFFFFF"}}>Fournisseurs tiers non autorisés</h4></button>
                                                </Tooltip>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <button disabled={this.state.acceptedFile === null || this.state.uploadingFile === true} style={{width:"100%", marginTop:20}} type={"submit"} className={"divButtonBuyResale"} >J'upload ma vidéo</button>
                            </DialogContent>
                        </form>
                    ):( /*seconde phase de l'upload de vidéo (choix de la miniature)*/
                        <form style={{width:"100%", overflowY:!this.state.showBrowserView && "auto"}} autoComplete="off" onSubmit={(e)=>{this.selectMinia(e)}}>
                            <DialogContent className={"flexCenter"} style={{flexDirection:"column", width:this.state.showBrowserView ? "50vw":"85%", overflow:"visible"}}>
                                <div className={"flexCenter"} style={{width:"99%", flexDirection:"column"}}>
                                    <div className={"flexCenter"} style={{width:'100%', marginBottom:20, cursor:"pointer", flexDirection:!this.state.showBrowserView && "column"}}>
                                        <div onClick={()=>{this.setState({typeChoiceMinia:1})}} className={"stepperChoiceMinia"} style={{color:this.state.typeChoiceMinia === 1 && "#FFFFFF", backgroundColor:this.state.typeChoiceMinia === 1 && "#72aacc"}}>
                                            J'upload ma miniature
                                        </div>
                                        <div onClick={()=>{this.setState({typeChoiceMinia:2})}} className={"stepperChoiceMinia"} style={{color:this.state.typeChoiceMinia === 2 && "#FFFFFF", backgroundColor:this.state.typeChoiceMinia === 2 && "#72aacc"}}>
                                            Je choisis une miniature dans ma vidéo
                                        </div>

                                    </div>
                                    <div className={"flexCenter"} style={{width:'100%', height:"100%",flexDirection:!this.state.showBrowserView && "column"}}>
                                        {this.state.typeChoiceMinia === 1 ? (
                                            <>
                                                <div style={{flex:1}}>
                                                    <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                                        acceptedFiles.forEach((file)=>{
                                                            if(file.size >= sizeLimitFile){
                                                                Toast.fire({
                                                                    icon: 'error',
                                                                    target:"#dialogUpload",
                                                                    title: 'Le fichier est trop lourd (>10GB) !'
                                                                })
                                                            }else if(file.name.includes("##")){
                                                                Toast.fire({
                                                                    icon: 'error',
                                                                    target:"#dialogUpload",
                                                                    title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                })
                                                            }else if(!file.type.includes("image")){
                                                                Toast.fire({
                                                                    icon: 'error',
                                                                    target:"#dialogUpload",
                                                                    title: 'Le fichier uploadé doit être une image !'
                                                                })
                                                            }else if (file.name.includes(".zip")){
                                                                Toast.fire({
                                                                    icon: 'error',
                                                                    target:"#dialogUpload",
                                                                    title: "L'enregistrement de zip n'est pas autorisé."
                                                                })
                                                            }else{
                                                                const reader = new FileReader()
                                                                reader.onload = () => {
                                                                    const binaryStr = reader.result
                                                                    this.setState({miniaBuffer:binaryStr})
                                                                }
                                                                reader.readAsArrayBuffer(file)
                                                                Toast.fire({
                                                                    icon: 'success',
                                                                    target:"#dialogUpload",
                                                                    title: "Fichier(s) correctement déposé(s), prêt à l'enregistrement."
                                                                })
                                                            }
                                                        })
                                                    }}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <section style={{textAlign:"center"}}>
                                                                <div id={this.state.acceptedFile===null &&"hoverMoving"} {...getRootProps()} style={{padding:this.state.showBrowserView ? "145px 80px 145px 80px" : "50px 80px 50px 80px", margin:"0 20px 0 20px",borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold",cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                                    <input {...getInputProps()} />
                                                                    <>
                                                                        <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFile && "#bbbbbb"}}>{this.state.miniaBuffer ? "Miniature déposée.":"Déposez votre miniature ici."}</h3>
                                                                    </>
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                                <div style={{flex:1}}>
                                                    {this.state.miniaBuffer ? (
                                                        <img style={{width:"100%",marginTop:!this.state.showBrowserView && 20,borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={`data:${"image"+"Miniature"};base64,${Buffer.from(this.state.miniaBuffer).toString("base64")}`} alt="miniature"/>
                                                    ):(
                                                        <h3 style={{textAlign:"center"}}>Aucune miniature déposée</h3>
                                                    )}
                                                </div>
                                            </>
                                        ):(
                                            <>
                                                {this.state.miniaturesToDisplay.length>0 && (
                                                    <div className={"flexCenter"} style={{flex:1, flexDirection:!this.state.showBrowserView && "column"}}>
                                                        {this.state.miniaturesToDisplay.map((minia,index)=>(
                                                            <div className={"divContainImageMinia"} onClick={()=>{this.setState({miniaChosen:index+1})}} style={{flex:1, position:"relative",textAlign:"center"}}>
                                                                <img className={"imageMinia"} style={{width:!this.state.showBrowserView &&"60%",border:this.state.miniaChosen === index+1 && "2px solid #f9b500", opacity:this.state.miniaChosen !== index+1 && 0.4}} src={`${url+minia}`} alt={`miniature ${index+1}`}/>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <button disabled={(this.state.miniaChosen === null && this.state.typeChoiceMinia === 2) || (this.state.typeChoiceMinia === 1 && this.state.miniaBuffer ===null)} style={{width:"100%", marginTop:this.state.typeChoiceMinia === 2 ? 50:20}} className={"divButtonBuyResale"} type={"submit"}>Valider la miniature</button>
                            </DialogContent>
                        </form>
                    )}
                </Dialog>
                <Dialog
                    open={Object.keys(this.state.selectedVideo).length > 0}
                    onClose={()=>{ setTimeout(()=>{this.setState({selectedVideo:{}})},1000)}}
                    id={"dialogDetailVideo"}
                >
                    <DialogTitle style={{paddingBottom:3}}>{this.state.selectedVideo.titre && (this.state.selectedVideo.titre.length>70 ? (this.state.selectedVideo.titre.slice(0,65)+"..."):(this.state.selectedVideo.titre))}</DialogTitle>
                    <DialogContent id={"dialogDetailVideo"} style={{maxWidth:"40vw"}}>
                        {!this.state.selectedVideo.verified && (
                            <div style={{border:"1px solid #ff8100",borderRadius:10, width :"fit-content", marginBottom:10}}>
                                <p style={{margin:3, color:"#ff8100"}}>Vidéo en cours de vérification</p>
                            </div>
                        )}
                        <video preload={"metadata"} controls>
                            <source
                                src={`${this.state.selectedVideo.srvDoc}videos${!this.state.selectedVideo.verified ? "Tampon":""}/${this.state.selectedVideo.owner}/${this.state.selectedVideo.fileName && this.state.selectedVideo.fileName.replaceAll("##","pourcentdiese")}?jwt=${this.state.jwt}`}
                                type={`video/${this.state.selectedVideo.fileName && (this.state.selectedVideo.fileName.split(".")[this.state.selectedVideo.fileName.split(".").length-1])}`}/>
                            Désolé, votre navigateur ne permet pas de lire des vidéos.
                            <a href={`${this.state.selectedVideo.srvDoc}videos${!this.state.selectedVideo.verified ? "Tampon":""}/${this.state.selectedVideo.owner}/${this.state.selectedVideo.fileName && this.state.selectedVideo.fileName.replaceAll("##","pourcentdiese")}?jwt=${this.state.jwt}`}
                               target={"_blank"}
                            >
                                un lien pour télécharger la vidéo
                            </a>
                        </video>
                        <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                            <div style={{border:"1px solid", borderColor:"red",borderRadius:10, width :"fit-content"}}>
                                <h3 style={{margin:3,color:"black"}}>{this.state.selectedVideo.categorie}</h3>
                            </div>
                            <div className={"flexCenter"} style={{width:"fit-content"}}>
                                <div style={{borderRadius:10, width :"fit-content"}}>
                                    <h4 style={{margin:3,color:"black", fontWeight:"normal"}}>Prix unitaire : <span style={{fontWeight:"bolder"}}>{this.state.selectedVideo.prixU}€</span></h4>
                                </div>
                                {this.state.selectedVideo.prixF !=="notAllowed" && (
                                    <div style={{borderRadius:10, width :"fit-content"}}>
                                        <h4 style={{margin:3,color:"black", fontWeight:"normal"}}>Prix fournisseur : <span style={{fontWeight:"bolder"}}>{this.state.selectedVideo.prixF}€</span></h4>
                                    </div>
                                )}
                            </div>

                        </div>
                        <h4 style={{fontWeight:"normal",margin:"25px 0 25px 0", width:"100%"}}>{this.state.selectedVideo.desc}</h4>
                    </DialogContent>
                </Dialog>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Vidéos publiées :</h1>
                                <div style={{background: "linear-gradient(49deg, rgba(114,170,204,1) 0%, rgba(250,182,3,1) 100%)", width:260, height:3, borderRadius:100, marginBottom:20}}></div>
                            </div>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                {this.state.archiVideoPublished.length !== 0 && (
                                    <>
                                        {this.state.archiVideoPublished.map(vid=>(
                                            <div onAuxClick={(e)=>{if(e.button===1){window.open("/BuyResale/Token?id="+vid.idToken, "_blank")}}} onClick={()=>{this.props.navigate("/BuyResale/Token?id="+vid.idToken)}} className={"divMyVideo"} style={{flexDirection:"column", background: "rgba(0,0,0,.5)",display:"flex", alignItems:"center",justifyContent:"flex-start", overflow:"hidden", border:!vid.verified && "2px solid #ff8100"}}>
                                                <div style={{width:'100%', position:"relative"}}>
                                                    <div style={{height:31, textAlign:"center",position:"absolute", zIndex:999, width:"100%", background: "rgba(0,0,0,.6)"}}>
                                                        <h4 style={{margin:5, textAlign:"center", color:"white"}} >{vid.titre.length>32 ? (vid.titre.slice(0,28)+"..."):(vid.titre)}</h4>
                                                    </div>
                                                </div>
                                                <div style={{width:'100%', position:"relative"}}>
                                                    {vid.verified === false && (
                                                        <div style={{position:"absolute", padding:2, zIndex:9999,borderRadius:10, width :"fit-content", marginBottom:10}}>
                                                            <Tooltip title={"Cette vidéo est en cours de vérification par notre équipe de modérateur, elle n'est pas visible sur le catalogue."}>
                                                                <Security style={{color:"#ff8100"}}/>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </div>
                                                <div style={{width:'100%', position:"relative"}}>
                                                    {vid.toSell === false && (
                                                        <div style={{position:"absolute", padding:2, zIndex:9999,borderRadius:10, width :"fit-content", marginBottom:10}}>
                                                            <Tooltip title={"Cette vidéo n'est pas en vente et n'est pas affichée sur le catalogue."}>
                                                                <Euro style={{color:"red"}}/>
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </div>
                                                <div style={{overflow:"hidden", width:"100%",height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <img style={{width:"100%", position:"absolute"}} src={`${vid.srvDoc}images/miniatures/${vid.idToken}.png`} alt={`${vid.fileName.split("##")[1]}`}/>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                                <div onClick={()=>{
                                    if(!this.state.profileComplete){
                                        Swal.fire({
                                            title: 'Profil non complet',
                                            text: "Votre profil n'est pas suffisamment complété pour publier une vidéo. Nous allons vous rediriger sur la page de modification de profil !",
                                            icon: 'info',
                                            confirmButtonText: 'Ok',
                                            cancelButtonText:"Annuler",
                                            showCancelButton:true,
                                            allowEscapeKey:false,
                                            allowOutsideClick:false
                                        }).then((data)=>{
                                            if(data.isConfirmed){
                                                this.props.navigate("/BuyResale/Profil?emp=missingInfos")
                                            }
                                        })
                                    }else{
                                        this.setState({displayUploadVideo:true})
                                    }
                                }} className={"divMyVideo flexCenter"}>
                                    <h4 style={{margin:0}}>Publier une vidéo</h4>
                                    <div style={{zIndex:3}} className={"circleAdd flexCenter"}>
                                        <Add style={{width:30,height:30}}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Vidéos possédées :</h1>
                                <div style={{background: "linear-gradient(49deg, rgba(114,170,204,1) 0%, rgba(250,182,3,1) 100%)", width:286, height:3, borderRadius:100}}></div>
                            </div>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                {this.state.archiVideoBought.length === 0 ? (
                                    <div style={{marginTop:20}}>
                                        <h2 style={{marginBottom:0}}>Vous ne possedez encore aucune vidéo ...</h2>
                                        <h2 style={{marginTop:10}}>Allez faire un tour sur le <Button onClick={()=>{this.props.navigate("/BuyResale/Catalogue")}} variant={"outlined"} style={{textTransform:"none", borderColor:"#000", color:"#000"}}><h2 style={{margin:0}}>catalogue des formations</h2></Button></h2>
                                    </div>
                                ):(
                                    <>
                                        {this.state.archiVideoBought.length !== 0 && (
                                            <>
                                                {this.state.archiVideoBought.map(vid=>(
                                                    <div onAuxClick={(e)=>{if(e.button===1){window.open("/BuyResale/Token?id="+vid.idTokenRef, "_blank")}}} onClick={()=>{this.props.navigate("/BuyResale/Token?id="+vid.idTokenRef)}} className={"divMyVideo"} style={{flexDirection:"column", background: "rgba(0,0,0,.5)",display:"flex", alignItems:"center",justifyContent:"flex-start", overflow:"hidden", border:!vid.verified && "2px solid #ff8100"}}>
                                                        <div style={{width:'100%', position:"relative"}}>
                                                            <div style={{height:31, textAlign:"center",position:"absolute", zIndex:999, width:"100%", background: "rgba(0,0,0,.6)"}}>
                                                                <h4 style={{margin:5, textAlign:"center", color:"white"}} >{vid.titre.length>32 ? (vid.titre.slice(0,28)+"..."):(vid.titre)}</h4>
                                                            </div>
                                                        </div>
                                                        <div style={{width:'100%', position:"relative"}}>
                                                            {vid.verified === false && (
                                                                <div style={{position:"absolute", padding:2, zIndex:9999,borderRadius:10, width :"fit-content", marginBottom:10}}>
                                                                    <Tooltip title={"Cette vidéo est en cours de vérification par notre équipe de modérateur, elle n'est pas visible sur le catalogue."}>
                                                                        <Security style={{color:"#ff8100"}}/>
                                                                    </Tooltip>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div style={{overflow:"hidden", width:"100%",height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                            <img style={{width:"100%", position:"absolute"}} src={`${vid.srvDoc}images/miniatures/${vid.idTokenRef}.png`} alt={`${vid.fileName.split("##")[1]}`}/>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MesVideos)