import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import Swal from "sweetalert2";
import {CalendarMonth} from "@mui/icons-material";
import jwt_decode from "jwt-decode";
import {Backdrop, CircularProgress, Tooltip} from "@mui/material";
import config from "../../json/config.json";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class ModerationSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true,
            showBackdrop:false
        };
    }

    setInitialStates(){
        return{
            connected:false,
            listeEntreprise:[]
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }
    _emitConnectedBR
    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedBR = global.emitter.addListener("emitConnectedSMSTO",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("SMSTO_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedStockMarketSTO", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    if(jwtDecoded.admin){
                        this.setState({connected:true})
                        jwtDecoded = jwt_decode(this.state.jwt)
                        global.emitter.emit("onRouteChangedStockMarketSTO", {
                            jwt: this.state.jwt,
                        })
                        this.getLDFModeration()
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Vous n'êtes pas autorisé à accéder à cette page.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                        this.props.navigate("/StockmarketSTO/Accueil")
                    }
                }else{
                    global.emitter.emit("askConnexionSMSTO",{disableOutsideClick:true})
                }
            })
        })
    }

    getLDFModeration(){
        Axios.post(url+"getCompanyToVerify",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }if(response.data.resultat === "done"){
                let listeEntrepriseTemp = response.data.companies
                listeEntrepriseTemp.forEach((entrepriseTemp)=>{

                    /*Format Date Début*/
                    entrepriseTemp.dateDebut = entrepriseTemp.dateDebut.split("-")
                    entrepriseTemp.dateDebut = entrepriseTemp.dateDebut.reverse()
                    entrepriseTemp.dateDebut = entrepriseTemp.dateDebut.join("/")

                    /*Format date Fin*/
                    entrepriseTemp.dateFin = entrepriseTemp.dateFin.split("-")
                    entrepriseTemp.dateFin = entrepriseTemp.dateFin.reverse()
                    entrepriseTemp.dateFin = entrepriseTemp.dateFin.join("/")
                })
                this.setState({
                    listeEntreprise:listeEntrepriseTemp
                })
            }else{
                Swal.fire({
                    title: 'Erreur !',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    verifyLDF(todo,entreprise,index){
        this.setState({showBackdrop:true})
        Axios.post(url+"verifyCompany",{siren:entreprise.siren, accept:todo==="accept"},{headers:{Authorization:"Bearer " + this.state.jwt}
        }).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            this.setState({showBackdrop:false})
            if(response.data.resultat === "done"){
                let listeEntrepriseTemp = this.state.listeEntreprise
                listeEntrepriseTemp.splice(index,1)
                this.setState({
                    listeEntreprise:listeEntrepriseTemp
                })
            }else{
                Swal.fire({
                    title: 'Erreur !',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }).catch((err)=>{
            this.setState({showBackdrop:false})
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.showBackdrop}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Levées de fonds à modérer :</h1>
                                <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:425, height:3, borderRadius:100, marginBottom:20}}></div>
                            </div>
                            {this.state.listeEntreprise.length>0 ? (
                                <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                    {this.state.listeEntreprise.map((elt,index)=>(
                                        <div className={"divLDF flexCenter"} style={{justifyContent:"space-between", transform:"scale(1.01)", cursor:"default"}}>
                                            <div className={"flexCenter"} style={{width:"29%", height:"100%", flexDirection:"column", alignItems:"flex-start", justifyContent:"space-between"}}>
                                                <h2 className={"flexCenter"} style={{margin:0}}>
                                                    <img style={{width:45, marginRight:10}} src={`${url}images/smsto_entreprise/${elt.siren}/logo.png`} alt="logoSpuro"/>
                                                    {elt.nomEntreprise}
                                                </h2>
                                                <img style={{width:"100%", minWidth:260, maxWidth:430, marginLeft:0,borderRadius:5}} src={`${url}images/smsto_entreprise/${elt.siren}/illustration.png`} alt="illusSpuro"/>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"39%",height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                                <div className={"flexCenter"}>
                                                    <CalendarMonth style={{marginRight:3}}/>
                                                    <h2 style={{margin:0, fontWeight:"normal"}}> Début le <span style={{fontWeight:"bold"}}>{elt.dateDebut}</span> - Fin le <span style={{fontWeight:"bold"}}> {elt.dateFin}</span> </h2>
                                                </div>
                                                <div style={{width:"100%"}}>
                                                    <h3 style={{width:"100%", textAlign:"left", margin:0}}>Description :</h3>
                                                    <h4 style={{width:"100%", textAlign:"left", margin:0, fontWeight:"normal"}}>{elt.desc}</h4>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-around"}}>
                                                    <div className={"flexCenter"}>
                                                        <Tooltip title={"Investissement minimum de "+elt.minActions+"€ et maximum de "+elt.maxActions+"€"}>
                                                            <h2 className={"flexCenter"} style={{margin:0}}>
                                                                <span style={{fontSize:34}}>{elt.minActions}</span>
                                                                <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                                ~
                                                                <span style={{fontSize:34}}> {elt.maxActions}</span>
                                                                <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                            </h2>
                                                        </Tooltip>
                                                    </div>
                                                    <div className={"flexCenter"}>
                                                        <Tooltip title={"1 STO vaut "+elt.valueAction+"€"}>
                                                            <h2 className={"flexCenter"} style={{margin:0, fontSize:34}}>
                                                                1<img style={{width:31, marginTop:4, marginRight:10}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>= {elt.actionValue}<img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                            </h2>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"29%",height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                                <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                                    <p>Créateur : {elt.pseudo} - {elt.nom} {elt.prenom} </p>
                                                    <p>Email : {elt.email}</p>
                                                    <p>Téléphone : {elt.tel}</p>
                                                    <p>nombre d'action : {elt.numberActions}</p>
                                                    <p>siren : {elt.siren}</p>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%"}}>
                                                    <button onClick={()=>{this.verifyLDF("accept",elt,index)}} className={"divButtonBuyResale"} style={{backgroundImage: "linear-gradient(to right, #41ff00 0%, #34cb00 51%, #41ff00 100%)", margin:5, padding:18}}>Valider</button>
                                                    <button onClick={()=>{this.verifyLDF("refuse",elt,index)}} className={"divButtonBuyResale"} style={{backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)", margin:5, padding:18}}>Refuser</button>
                                                    <button onClick={()=>{window.open(`/StockMarketSTO/EchangeFichier?td=${elt.siren}&jwt=${this.state.jwt}`, "_blank")}} className={"divButtonBuyResale"} style={{margin:5, padding:18}}>Fichiers annexes</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ):(
                                <p>Aucune levée de fonds à modérer</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ModerationSTO)