import React, {Component} from 'react';
import {sha256} from 'js-sha256';
import {
    Button,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Axios from "axios";
import {withRouter} from '../shared/NavigationUtils';
import config from "../json/config.json";

import Swal from "sweetalert2";
import tooltipContenu from "../json/contenu.json";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const columns = [
    { id: 'ValeurToken', label: 'Valeur du token', minWidth: 75 },
    { id: 'TypeToken', label: 'Type', minWidth: 75 },
    { id: 'IDToken', label: 'Identification', minWidth: 100 },
    { id: 'Statut', label: 'Statut', minWidth: 100 },
];

let jwtDecoded = sessionStorage.getItem("SPURO_jwt") ? jwt_decode(sessionStorage.getItem("SPURO_jwt")) : {}

class TransfertToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phase:1,
            typeToken:"",
            checkedDonation:false,
            empreinteInitiateur:"",
            pseudoAffichage:"",
            stateDrawer:true
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        let jwtDecoded = sessionStorage.getItem("SPURO_jwt") ? jwt_decode(sessionStorage.getItem("SPURO_jwt")) : {}
        global.emitter.emit("onRouteChanged",{
            pageName:'Mes tokens',
            pseudo:jwtDecoded.pseudo,
        })
    }

    handleValidation(clef,pseudo){ //Fonction servant a la vérification des données avant l'envoi du formulaire
        if(clef !== "" && pseudo !== ""){
            return true
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Les données saisies sont incorrectes.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return false
        }
    }
    handleChange(event){ //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }
    handleSubmitPhase1(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        const data= new FormData(event.target);
        let pseudo = data.get('champsPseudo'), clef = data.get('champsClef'),hash;
        event.preventDefault();
        if(()=>{this.handleValidation(clef, pseudo)}){
            this.setState({
                pseudoAffichage : pseudo
            })
            hash = sha256(pseudo.concat(clef)) //Hashage avec protocole sha256
            Axios.post(url+"getAllMyTokens", {
                empreinte:hash,
            }).then(response => {
                if (response.data.resultat !== "Ce client n'existe pas."){
                    this.setState({
                        jwtClient: response.data.jwt
                    })
                    this.setState({
                        phase:2,
                        allToken:response.data.resultat,
                    })
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Erreur ce compte n'existe pas.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto", padding:30,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <h1>Mes tokens {this.state.phase === 2 ? (<>{this.state.pseudoAffichage}</>):("")}</h1>
                        {this.state.phase === 1 ? (
                            <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase1(e)}} noValidate={true} autoComplete="off">
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsPseudo"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez votre ientifiant."} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Identifiant" variant="outlined"/>
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsClef"} type={this.state.showPassword ? "text":"password"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez votre Clef Unique."} onChange={(e)=>{this.handleChange(e)}} id="champsClef" label="Clef unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }}
                                    />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button type={"submit"} variant="contained">Suivant</Button>
                            </form>
                        ):("")}
                        {this.state.phase === 2 ? (
                            <Paper style={{margin:10, maxWidth:"115vh", maxHeight:400, overflowY:"scroll"}}>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                    >
                                                        {column.label === "Valeur du token" ? (
                                                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                {column.label}
                                                            </div>
                                                        ):(column.label)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(this.state.allToken).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                        {columns.map((column) => {
                                                            return (
                                                                <TableCell key={column.id}>
                                                                    <>
                                                                        {column.id === "ValeurToken" ? (
                                                                            <>
                                                                                {row.type === "fichier" ? ((row.value).slice(0,4) + "..." + (row.value).slice(-4)):(
                                                                                    <>
                                                                                        {row.type === "fongible" ? (row.value.toFixed(3).replace(".", ',')):(row.value)}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {column.id === "TypeToken" ? (
                                                                                    row.type === "fichier" ? "NFT" : row.type
                                                                                ):(
                                                                                    <>
                                                                                    {column.id === "Statut" ? (
                                                                                            <>
                                                                                                {row.status}
                                                                                            </>
                                                                                        ):(
                                                                                            <>
                                                                                                {row.type === "fichier" ? (
                                                                                                    <>
                                                                                                        {row.fileName}
                                                                                                    </>
                                                                                                ):(
                                                                                                    <>
                                                                                                        {row.idToken}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        ):("")}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(TransfertToken)