import React, {Component} from 'react';
import {withRouter} from '../../../../shared/NavigationUtils';
import {isMobile} from "react-device-detect";

class SharedFooterEkocoOR extends Component {

    constructor(props) {
        super(props);
        this.state={
            showBrowserView:true
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
    }

    render() {
        return(
            <div style={{width:"100%", height:"100%",paddingTop:10, backgroundColor:"#f5f6f6", display:"flex",flexWrap:"wrap", marginTop:0, justifyContent:"center", alignItems:"center", zIndex:4}}>

                <div style={{width:"90%", height:"90%",display:"flex", flexWrap:"wrap"}}>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex", flex:3, width:200, justifyContent:"center", alignItems:"center"}}>
                            <img style={{width:200, textAlign:"center"}} src={require("../../assetsEkoco/logo_fulltext_Rose.png")} alt="LogoEkoco"/>
                        </div>
                    ):("")}

                    <div style={{display:"flex", flex:3, flexDirection:"column", justifyContent:"center"}}>
                        <h1 className={"arialExtraBold"} style={{margin:0,color:"#2c2e35", fontSize:!this.state.showBrowserView ? "4vw":30}}>Soutenez la lutte contre</h1>
                        <h1 className={"arialExtraBold"} style={{margin:0, color:"#ec5ba1", fontSize:!this.state.showBrowserView ? "4vw":30}}>le cancer du sein</h1>
                    </div>
                    <div style={{display:"flex",flex:2}}>
                        <div style={{display:"flex", flex:1, flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                            <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:!this.state.showBrowserView ? "3vw":18,cursor:"pointer"}} onClick={()=>{window.open("https://spuro.eu/mentions-legales/")}}>Mentions légales</p>
                            <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:!this.state.showBrowserView ? "3vw":18,cursor:"pointer"}} onClick={()=>{window.open("https://www.ligue-cancer.net/")}}>Ligue contre le cancer</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SharedFooterEkocoOR)