import React, {Component} from 'react';
import {withRouter} from '../../../shared/NavigationUtils';
import {isMobile} from "react-device-detect";
import config from "../../../json/config.json";

import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import {AccountCircle, Close, Home, Liquor, Menu, Store} from "@mui/icons-material";
import {Dialog, DialogContent} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Axios from "axios";
import {sha256} from "js-sha256";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const ToastGNFT = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFontTitleToast',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    },
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFont ChampFontTitle',
        content: 'ChampFont ChampFontContent',
        confirmButton: 'ChampFont ChampFontButton',
        cancelButton: 'ChampFont ChampFontButton',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    }
    // confirmButtonTextColor: "#3b3b3b",
})

const allAcceptedPath = [
    "/grapenft/home", "/grapenft/catalog","/grapenft/signup",
    "/grapenft/admin/addbottles","/grapenft/myaccount","/grapenft/paymentstatus",
    "/grapenft/legalnotices","/grapenft/contact", "/grapenft/mybottles",
    "/grapenft/salesproposal","/grapenft/admin/recaptransac"
]

class SharedHeaderGNFT extends Component {

    constructor(props) {
        super(props);
        this.state={
            openMenuConnexion:false,
            connected:false,
            displayConnexion:false,
            openDialogueModifEmail:false,
            openDialogueAjoutClient:false,
            champsIdentifiant:"",
            champsClef:"",
            champsNewEmail:"",
            displayed:false,
            openDialogueInvalidationCompte:false,
            displayMenuMobile:false,
            showBrowserView:true,
            freeClient:false,
            relance:false,
            readyToClick:true,
            sekurEmailClient:false,
            jwtDecoded:{}
        };
    }

    _onRouteChangedGNFT; _emitInscrisGNFT;
    _askConnectionGNFT;
    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentDidMount() {
        this.setState({
            jwtDecoded : sessionStorage.getItem("GNFT_jwt") ? jwt_decode(sessionStorage.getItem("GNFT_jwt")) : {},
            connected: !!sessionStorage.getItem("GNFT_jwt")
        })
        const queryParams = new URLSearchParams(window.location.search);
        let oc = queryParams.get("oc") //OpenConnexion = true ? ouvre dialog de connexion
        if(oc){
            this.setState({
                displayConnexion:true
            })
        }
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this._onRouteChangedGNFT = global.emitter.addListener("_onRouteChangedGNFT",(data)=>{
            this.setState({
                jwt:data.jwt,
                jwtDecoded : (data.jwt ? jwt_decode(data.jwt):""),
                connected: !!data.jwt,
                displayConnexion:false,
                disableOutsideClick:false,
                displayMenuMobile:false
            })
        })

        this._askConnectionGNFT = global.emitter.addListener("askConnectionGNFT",(data)=>{
            this.setState({
                displayConnexion:true,
                displayMenuMobile:false,
                disableOutsideClick:data.disableOutsideClick
            })
        })

        this._emitInscrisGNFT = global.emitter.addListener("emitInscrisGNFT",(data)=>{
            this.setState({
                champsIdentifiant:data.identifiant,
                champsPassword:data.password
            },()=>{
                setTimeout(()=>{this.handleConnexion("fromInsc")},500)
            })
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    componentWillUnmount(){
        if(this._onRouteChangedGNFT){this._onRouteChangedGNFT.remove()}
        window.removeEventListener('resize', this.resizePage)
    }

    disconnect(){
        this.setState({displayMenuMobile:false, readyToClick:true, connected:false, jwt:"", jwtDecoded:""})
        sessionStorage.removeItem("GNFT_jwt")
        ToastGNFT.fire({
            icon: 'success',
            title: 'Disconnected.'
        })
        this.props.navigate("/GrapeNFT/Home")
    }

    handleConnexion(e){
        this.setState({showPassword:false, displayMenuMobile:false})
        if(e !== "fromInsc"){
            e.preventDefault()
        }
        let empreinte = sha256((this.state.champsIdentifiant.trim()).concat(this.state.champsPassword.trim()));
        Axios.post(url+"connexionGNFT",{
            empreinte:empreinte,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("GNFT_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt,
                    jwtDecoded:jwt_decode(response.data.jwt)
                })
            }
            this.setState({displayConnexion:false},()=>{
                if (response.data.resultat === "Connected"){
                    this.setState({
                        connected:true
                    })
                    ToastGNFT.fire({
                        icon: 'success',
                        title: 'Welcome back '+this.state.champsIdentifiant
                    })
                    global.emitter.emit("emitConnectedGNFT")
                }else{
                    /*check if dialog-catalog-GNFT opacity is 1*/
                    let target = ""
                    if(document.getElementById("dialog-catalog-GNFT")){
                        if(document.getElementById("dialog-catalog-GNFT").style.opacity === "1"){
                            target = "#dialog-catalog-GNFT"
                        }
                    }
                    console.log(target)
                    SwalDefault.fire({
                        title: 'User not found.',
                        text: "This combination of username and key does not exist.",
                        icon: 'error',
                        target,
                        confirmButtonText: 'Ok'
                    }).then(()=>{this.setState({displayConnexion:true,readyToClick:true})})
                }
            })
        }).catch(()=>{
            let target = ""
            if(document.getElementById("dialog-catalog-GNFT")){
                if(document.getElementById("dialog-catalog-GNFT").style.opacity === "1"){
                    target = "#dialog-catalog-GNFT"
                }
            }
            this.setState({displayConnexion:false},()=> {
                SwalDefault.fire({
                    title: 'Erreur!',
                    text: "Serveur injoignable.",
                    icon: 'error',
                    target,
                    confirmButtonText: 'Ok'
                })
            })
        })
    }

    goToPage(clickedPage){
        this.setState({displayMenuMobile:false, displayConnexion:false},()=>{
            window.location.pathname !== clickedPage ? this.props.navigate(clickedPage):window.location.reload()
        })
    }

    render() {
        
        return(
            <>
                <div className={"navbar-frontpage-GNFT"}>
                    <Dialog
                        open={this.state.displayConnexion}
                        onClose={()=>{this.setState({displayConnexion:false,readyToClick:true})}}
                        disableBackdropClick={this.state.disableOutsideClick}
                        disableEscapeKeyDown={this.state.disableOutsideClick}
                        style={{zIndex:99999}}
                    >
                        <DialogContent className={"flexCenter"} style={{background: "linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)", flexDirection:"column"}}>
                            <div className={"flexCenter"} style={{position:"relative", width:"100%", alignItems:"center"}}>
                                <div style={{position:"absolute", right:-5}}>
                                    <Close onClick={()=>{
                                        this.setState({readyToClick:true,displayConnexion: false})
                                        if(this.state.disableOutsideClick) {
                                            this.setState({disableOutsideClick:false})
                                            this.goToPage("/GrapeNFT/Home")
                                        }
                                    }} style={{width:35,height:35, cursor:"pointer"}}/>
                                </div>
                            </div>
                            <h1 className={"ChampFont flexCenter"} style={{margin:"0 40px 10px 40px", fontWeight:"bold"}}>Connect to GrapeNFT!</h1>
                            <h2 className={"ChampFont flexCenter"} style={{fontWeight:"bold", marginBottom:10}}>I don't have an account yet :</h2>
                            <div className={"flexCenter"}>
                                <button onClick={()=>{
                                    let td = new URLSearchParams(window.location.search).get("td")
                                    // console.log("/GrapeNFT/SignUp"+(td && ("?td="+td)))
                                    if(td) {
                                        this.goToPage("/GrapeNFT/SignUp"+(td && ("?td="+td)))
                                    }else{
                                        this.goToPage("/GrapeNFT/SignUp")
                                    }
                                }} style={{width:"fit-content", marginTop:10}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Sign up</h2></button>
                            </div>
                            <form style={{width:"100%"}} autoComplete="off" onSubmit={(e)=>{this.handleConnexion(e)}}>
                                <h2 className={"ChampFont flexCenter"} style={{fontWeight:"bold",marginBottom:10}}>I have an account :</h2>
                                <div style={{position:"relative", width:"100%"}}>
                                    <input autoComplete={"off"} name={"champsIdentifiant"} required className={"champsTexteGNFT ChampFont"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF"}} type={"text"} placeholder={"Username"}/>
                                </div>
                                <div style={{position:"relative", width:"100%"}}>
                                    <input autoComplete={"new-password"} name={"champsPassword"} required className={"champsTexteGNFT ChampFont"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", position:"relative"}} type={this.state.showPassword ? "text":"password"} placeholder={"Private Key"}/>
                                    <div style={{position:"absolute", width:"fit-content", right:10, top:24}}>
                                        {this.state.showPassword ? (
                                            <VisibilityIcon style={{cursor:"pointer"}} onClick={()=>{this.setState({showPassword:false})}}/>
                                        ):(
                                            <VisibilityOffIcon style={{cursor:"pointer"}} onClick={()=>{this.setState({showPassword:true})}}/>
                                        )}
                                    </div>
                                </div>
                                <div className={"flexCenter"}>
                                    <button type={"submit"} style={{width:"fit-content", marginTop:15}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Log In</h2></button>
                                    {/*<button className={"buttonGNFT ChampFont"} style={{padding:10,width:"fit-content", color:"#000", margin:5, marginTop:10, border:"1px solid black", borderRadius:5}} type={"submit"}><h2 style={{margin:0, fontWeight:"normal"}}>Log In</h2></button>*/}
                                </div>
                            </form>
                        </DialogContent>
                    </Dialog>
                    {this.state.showBrowserView ? (
                        <>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <img onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/Home', "_blank")}}} onClick={()=>{this.props.navigate("/GrapeNFT/Home")}} style={{width:30, height:30, marginLeft:20, cursor:"pointer"}} src={require("../../../assets/grapelogowinenft.png")} alt="grapelogowinenft"/>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",width:"35%"}}>
                                <h2 onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/Home', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/Home")}} className={"textButtonGNFT flexCenter"} style={{color:"#FFF"}}>
                                    <Home style={{marginRight:10}}/>
                                    Home
                                </h2>
                                <h2 onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/Catalog', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/Catalog")}} className={"textButtonGNFT flexCenter"} style={{color:"#FFF"}}>
                                    <Store style={{marginRight:10}}/>
                                    Catalog
                                </h2>
                                <h2 onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/MyBottles', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/MyBottles")}} className={"textButtonGNFT flexCenter"} style={{color:"#FFF"}}>
                                    <Liquor style={{marginRight:10}}/>
                                    My Bottles
                                </h2>
                                {/*<h2 className={"textButtonGNFT"} style={{color:"#FFF"}}>About Us</h2>*/}
                            </div>
                            <div onMouseEnter={()=>{this.setState({openMenuConnexion:true})}} style={{display:"flex", justifyContent:"center", alignItems:"center", position:"relative"}}>
                                <h2 className={"ChampFont"} style={{color:"#FFF", marginRight:10}}>{this.state.jwtDecoded.pseudo}</h2>
                                <AccountCircle style={{width:40, height:"100%", color:"#FFF", marginRight:20, cursor:"pointer"}}/>
                                <div onMouseLeave={()=>{this.setState({openMenuConnexion:false})}} style={{transition:"all 0.26s ease", maxHeight:this.state.openMenuConnexion? 258:0, transformOrigin:"top", position:"absolute", backgroundColor:"transparent", paddingTop:55, top:0, right:20, width:250, borderRadius:10, overflow:"hidden"}}>
                                    <div className={"flexCenter"} style={{flexDirection:"column", backgroundColor:"#FFF", borderRadius:5}}>
                                        {this.state.connected === false ? (
                                            <>
                                                <div onClick={()=>{this.setState({displayConnexion:true, displayMenuMobile:false,openMenuConnexion:false})}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Log in</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/SignUp', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/SignUp")}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Sign up</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/LegalNotices', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/LegalNotices")}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Legal Notices</h2>
                                                </div>

                                            </>
                                        ):(
                                            <>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/MyAccount', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/MyAccount")}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>My Account</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/LegalNotices', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/LegalNotices")}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Legal Notices</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onClick={()=>{this.disconnect()}} className={"hoverMenuGNFT flexCenter"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold", color:"#f44336",marginRight:15}}>Log off</h2>
                                                    <ExitToAppIcon style={{color:'#f44336'}}/>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </>
                    ):(
                        <>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <img onClick={()=>{this.props.navigate("/GrapeNFT/Home")}} style={{width:30,zIndex:9999, height:30, marginLeft:20, cursor:"pointer"}} src={require("../../../assets/grapelogowinenft.png")} alt="grapelogowinenft"/>
                            </div>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center", position:"relative"}}>
                                {this.state.displayMenuMobile ? (
                                    <Close onClick={()=>{this.setState({displayMenuMobile:false})}} style={{width:40,zIndex:9999, height:"100%", color:"#FFF", marginRight:20, cursor:"pointer"}}/>
                                ):(
                                    <Menu onClick={()=>{this.setState({displayMenuMobile:true})}} style={{width:40, height:"100%", color:"#FFF", marginRight:20, cursor:"pointer"}}/>
                                )}
                                <div style={{display:this.state.displayMenuMobile ? "block":"none", position:"absolute", backgroundColor:"transparent", paddingTop:55, top:0, right:0, width:"100vw", height:"100%"}}>
                                    <div className={"flexCenter"} style={{flexDirection:"column", backgroundColor:"#FFF", borderRadius:5}}>
                                        <div onClick={()=>{this.goToPage("/GrapeNFT/Home")}} className={"hoverMenuGNFT flexCenter"} style={{width:"100%", cursor:"pointer"}}>
                                            <Home style={{marginRight:10, width:30, height:30}}/>
                                            <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Home</h2>
                                        </div>
                                        <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                            <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                        </div>
                                        <div onClick={()=>{this.goToPage("/GrapeNFT/Catalog")}} className={"hoverMenuGNFT flexCenter"} style={{width:"100%", cursor:"pointer"}}>
                                            <Store style={{marginRight:10, width:30, height:30}}/>
                                            <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Catalog</h2>
                                        </div>
                                        <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                            <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                        </div>
                                        <div onClick={()=>{this.goToPage("/GrapeNFT/MyBottles")}} className={"hoverMenuGNFT flexCenter"} style={{width:"100%", cursor:"pointer"}}>
                                            <Liquor style={{marginRight:10, width:30, height:30}}/>
                                            <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>My Bottles</h2>
                                        </div>
                                        <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                            <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                        </div>
                                        {this.state.connected === false ? (
                                            <>
                                                <div onClick={()=>{this.setState({displayConnexion:true, displayMenuMobile:false,openMenuConnexion:false})}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Log in</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/SignUp', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/SignUp")}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Sign up</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/LegalNotices', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/LegalNotices")}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Legal Notices</h2>
                                                </div>
                                            </>
                                        ):(
                                            <>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/MyAccount', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/MyAccount")}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>My Account</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/GrapeNFT/LegalNotices', "_blank")}}} onClick={()=>{this.goToPage("/GrapeNFT/LegalNotices")}} className={"hoverMenuGNFT"} style={{width:"100%", cursor:"pointer"}}>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold"}}>Legal Notices</h2>
                                                </div>
                                                <div className={"flexCenter"} style={{width:"100%", position:"relative", height:0}}>
                                                    <hr style={{width:"80%", margin:0, color:"#3b3b3b", position:"absolute", height:0}} />
                                                </div>
                                                <div onClick={()=>{this.disconnect()}} className={"hoverMenuGNFT flexCenter"} style={{width:"100%", cursor:"pointer"}}>
                                                    <ExitToAppIcon style={{marginRight:10, width:30, height:30, color:'#f44336'}}/>
                                                    <h2 className={"ChampFont"} style={{textAlign:"center", fontWeight:"bold", color:'#f44336'}}>Log off</h2>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </>
                    )}


                </div>
            </>
        )
    }
}

export default withRouter(SharedHeaderGNFT)