// Importing combination
import React, {Component} from 'react';
import {NavigateBefore, NavigateNext, Payments, Person, Warning} from "@mui/icons-material";
import config from "../../json/config.json";
import {Tooltip} from "@mui/material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix


class TileConceptComponent extends Component {
    constructor(props)
    {
        super(props);
        this.state = { concept : null };
        /*Si on retire cette ligne ça plante.*/
    }

    diapoIllus(elt,todo){
        let diplayIllus = this.state[elt.idToken+"_displayIllus"] || 1
        if(todo === "previous"){
            this.setState({
                [elt.idToken+"_displayIllus"]:diplayIllus === 1 ? (elt.numberImages):(diplayIllus-1)
            })
        }else{
            this.setState({
                [elt.idToken+"_displayIllus"]:diplayIllus === elt.numberImages ? (1):(diplayIllus+1)
            })
        }
    }

    render()
    {
        return (
            <div className={"flexCenter mainDivConcept"} style={{flexDirection:"column", border:this.props.concept.moderated === false && "2px solid rgb(255 141 0)" }}>
                {this.props.dai !== false && (
                    <div style={{width:"100%", position:"relative"}}>
                        <div className={"flexCenter"} style={{position:"absolute", width:"100%", justifyContent:"flex-end", marginTop:5}}>
                            <div style={{width:"fit-content", backgroundColor:"#364982", padding:5, borderRadius:5, marginRight:5, border:"1px solid #FFF"}}>
                                <p className={"flexCenter"} style={{margin:0, fontWeight:100, color:"#FFF"}}>{this.props.dai === "mine" ? (<>Concept créé <Person style={{marginLeft:5}}/></>):(<>Concept acheté <Payments style={{marginLeft:5}}/></>) }</p>
                            </div>
                        </div>
                    </div>
                )}
                {this.props.concept.moderated === false && (
                    <div style={{width:"100%", position:"relative"}}>
                        <div className={"flexCenter"} style={{position:"absolute", width:"100%", justifyContent:"flex-start", marginTop:5}}>
                            <Tooltip title={"Ce concept n'a pas encore été validé par un modérateur"}>
                                <Warning style={{color:"rgb(255 141 0)", margin:8}}/>
                            </Tooltip>
                        </div>
                    </div>
                )}
                <div className={"flexCenter"} style={{flex:3,width:"100%", borderBottom:"1px solid #d5d5d5", flexDirection:"column", justifyContent:"flex-start", overflow:"hidden",backgroundColor:"#D7D7D7FF"}}>
                    <div style={{position:"relative", width:"100%"}}>
                        <div className={"flexCenter"} style={{position:"absolute", width:"100%", justifyContent:"space-between", top:88}}>
                            <button onClick={()=>{this.diapoIllus(this.props.concept,"previous")}} style={{borderRadius:"50%", padding:2, marginLeft:5}} className={"divButtonBtoBDesign flexCenter"}><NavigateBefore style={{margin:0}} /></button>
                            <button onClick={()=>{this.diapoIllus(this.props.concept,"next")}} style={{borderRadius:"50%", padding:2, marginRight:5}} className={"divButtonBtoBDesign flexCenter"}><NavigateNext style={{margin:0}} /></button>
                        </div>
                    </div>
                    <div style={{position:"relative", width:"100%"}}>
                        <div className={"flexCenter"} style={{position:"absolute", width:"100%", top:165}}>
                            <div className={"flexCenter"} style={{justifyContent:"space-around", width:"fit-content"}}>
                                {Array.from(Array(this.props.concept.numberImages).keys()).map((bubble)=>(
                                    <div onClick={()=>{this.setState({[this.props.concept.idToken+"_displayIllus"]:bubble+1})}} style={{margin:"0 10px 0 10px",backgroundColor:((parseInt(this.state[this.props.concept.idToken+"_displayIllus"])||1) === bubble+1) && "#364982"}} className={`divBubbleBtoB`}/>
                                ))}
                            </div>
                        </div>
                    </div>
                    <img style={{maxWidth:"100%"}} src={`${url}BtoBConcept?empreinte=${this.props.concept.owner}&idToken=${this.props.concept.ref}&file=${this.state[this.props.concept.idToken+"_displayIllus"]||1}_wm`} alt={"illustration"}/>
                </div>
                <div style={{flex:2,width:"100%", borderBottom:"1px solid #d5d5d5",display:"flex", alignItems:"flex-start", flexDirection:"column", justifyContent:"space-evenly"}}>
                    <h3 style={{margin:5,fontWeight:400}}>{this.props.concept.title}</h3>
                    <div style={{border:"1px solid", marginLeft:5, borderColor:"#364982",borderRadius:10, width :"fit-content"}}>
                        <p style={{margin:0,fontWeight:100, padding:3}}>
                            {this.props.concept.categorie || "inconnu"}
                        </p>
                    </div>
                    <p style={{fontSize:13, fontWeight:100, margin:0, marginLeft:5}}>{this.props.concept.desc && (this.props.concept.desc.length > 150 ? this.props.concept.desc.slice(0,150) + " (...)":this.props.concept.desc)}</p>
                </div>
                <div className={"flexCenter"} style={{flex:1,width:"100%",justifyContent:"space-around"}}>
                    <button onClick={()=>{this.props.navigate("/BtoBDesign/Concept?td="+this.props.concept.ref)}} className={"divButtonBtoBDesign"}>En savoir plus</button>
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <div className={"flexCenter"}>
                            <p style={{fontSize:24, fontWeight:600, margin:0}}>{this.props.concept.price}</p>
                            <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                        </div>
                        <p style={{fontSize:14, fontWeight:600, margin:0}}>{this.props.concept.contrat ==="recurrent" ? "Droits d'utilisation":"Possession complète"}</p>
                    </div>
                </div>
            </div>
        )
    }
}

// Exporting the component
export default TileConceptComponent;