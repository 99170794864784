import React, {Component} from "react";
import {Button, TextField} from "@mui/material";
import Axios from "axios";
import config from "../../../json/config.json";
import Swal from "sweetalert2";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let Km, Temps;

export default class EkocoDonnees extends Component {

    constructor(props) {
        super(props);
        this.state={
        };
    }
    componentDidMount() {
        this.setState({
            hash : sessionStorage.getItem('EKOCO_hash'),
            jwt : sessionStorage.getItem('SPURO_jwtClient')
        })

        if (this.state.hash === null){this.props.navigate('/Ekoco/BasCarboneBlockchain', { replace: true })}
    }
    handleValidation(Km,Temps){ //Fonction servant a la vérification des données avant l'envoi du formulaire
        if(Km!=="" && Temps!==""){
            return true
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Les données saisies sont incorrectes.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return false
        }
    }
    handleChange(event){ //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }
    handleSubmit(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        const data= new FormData(event.target);
        Km = data.get('champsKm'); Temps = data.get('champsTemps');
        event.preventDefault();
        if(()=>{this.handleValidation(Km,Temps)}){
            Axios.post(url+"verificationEkoco", {
                km : Km,
                temps : Temps,
                hash : this.state.hash,
                speedLimit : '25',
                hashJwt:this.state.hash
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                Swal.fire({
                    title: 'Succès!',
                    text: response.data.resultat,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            }).catch(error => {
                console.log(error);
            });
        }
    }
    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex", justifyContent:"center", alignItems:"center",width:"100vw", height:"100vh"}}>
                <div id={"InputKmTemps"}>
                    <h1>Données</h1>
                    <p>Saisissez le km/temps (provisoire)</p>
                    <form onSubmit={(e)=>{this.handleSubmit(e)}} autoComplete="off">
                        <div className={"inputHelpDiv"}>
                            <TextField name={"champsKm"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsKm" label="Km" variant="outlined" />
                        </div>
                        <div className={"inputHelpDiv"}>
                            <TextField name={"champsTemps"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsTemps" label="Temps (en minute)" variant="outlined" />
                        </div>
                        <Button type={"submit"} variant="contained">Valider mes données</Button>
                    </form>
                </div>
            </div>
        );
    }
}