import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import {ArrowRightAlt, Description, Image as ImageIcon, InsertDriveFile, Person} from "@mui/icons-material";
import jwt_decode from "jwt-decode";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Download from "@mui/icons-material/Download";
import AdmZip from "adm-zip";
import {Backdrop, CircularProgress, Tooltip} from "@mui/material";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import PayPalButton from "../../Components/PaypalButton";

const {baseUrl, paypalPublicKey, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class PropositionDeVenteSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
        this.createOrderPaypal = this.createOrderPaypal.bind(this);
    }

    setInitialStates(){
        return{
            connected:false,
            entreprise:{},
            allFiles:[],
            disableAcceptProposal:false,
            showBackdrop:false
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }
    _emitConnectedBR
    componentDidMount() {
        let td = new URLSearchParams(window.location.search).get("idTokenProposal");
        if(td !== null){
            this.setState({
                td,
            })
        }else{
            Swal.fire({
                title: 'Erreur !',
                text: "IdToken de la proposition de vente non valide.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            this.props.navigate("/StockMarketSTO/Accueil")
        }
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedBR = global.emitter.addListener("emitConnectedSMSTO",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("SMSTO_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedStockMarketSTO", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }
    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    this.setState({connected:true})
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: this.state.jwt,
                    })
                    this.getProposalDetail()
                }else{
                    global.emitter.emit("askConnexionSMSTO",{disableOutsideClick:true})
                }
            })
        })
    }

    getProposalDetail(){
        Axios.post(url+"getSaleProposal",{idTokenProposal:this.state.td},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let allFilesTemp = response.data.allFiles
                let allFilesObj = []
                allFilesTemp.forEach(elt=>{
                    const ext = elt.split(".")[elt.split(".").length-1]
                    let type = "fichier"
                    const listImages = ["png","jpg","jpeg","gif"]
                    if (listImages.includes(ext)){
                        type = "image"
                    }else if(ext === "pdf"){
                        type = "pdf"
                    }else{
                        type = "fichier"
                    }
                    allFilesObj.push({type:type,fileName:elt})
                })
                if(response.data.proposal.empreinte === jwtDecoded.empreinte) {
                    this.setState({disableAcceptProposal:true})
                }
                this.setState({entreprise:response.data.company,allFiles:allFilesObj, proposal:response.data.proposal})
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.props.navigate("/StockMarketSTO/Accueil")
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    dlAllFiles(){
        let zipFile = new AdmZip();
        new Promise(async resolve => {
            resolve(await this.downloadEachFile(this.state.allFiles.slice(), zipFile))
        }).then(zip=>{
            let willSendthis = zip.toBuffer(); //On transforme le zip en Buffer Uint8Array pour le télécharger dans le front plus tard.
            let blob = new Blob([willSendthis], {type: "application/zip"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.state.entreprise.nomEntreprise+".zip";
            link.click();
        })
    }
    async downloadEachFile(files, zipFile){
        return new Promise(async resolve => {
            if (files.length === 0){
                resolve(zipFile)
            }else{
                Axios.get(url+"smsto/"+this.state.td+"/"+files[0].fileName+"?jwt="+this.state.jwt+"&siren="+this.state.td, {
                    responseType: 'arraybuffer',
                }).then(response => {
                    zipFile.addFile(files[0].fileName, Buffer.from(response.data, "utf8"),"Nom du fichier : "+files[0].fileName) //On ajoute les .txt dans le zip
                    files.shift()
                    resolve(this.downloadEachFile(files, zipFile))
                })
            }
        })
    }

    downloadFile(file){
        Axios.get(url+"smsto/"+this.state.td+"/"+file+"?jwt="+this.state.jwt+"&siren="+this.state.td, {
            responseType: 'arraybuffer',
        }).then(response => {
            let blob = new Blob([Buffer.from(response.data, "utf8")], {type: "application/zip"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = file;
            link.click();
        })
    }

    createOrderPaypal(){
        this.setState({disableAcceptProposal:true})
        if(this.state.proposal.empreinte === jwtDecoded.empreinte){
            this.setState({disableAcceptProposal:true})
            Swal.fire({
                title: 'Erreur',
                text: "Vous ne pouvez pas acheter des actions via votre proposition de vente.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }else{
            this.setState({showBackdrop:true})
            return new Promise((resolve, reject) => {
                Axios.post(url+"acceptProposal",{idTokenProposal:this.state.td},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    this.setState({showBackdrop:false})
                    if (response.data.resultat === "done"){
                        resolve(response.data.orderPaypalID)
                    }else if (response.data.resultat === "Cette proposition de vente n'est plus d'actualité."){
                        this.setState({disableAcceptProposal:false})
                        reject(response.data.resultat)
                        Swal.fire({
                            title: "Proposition de vente expirée",
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/StockMarketSTO/Accueil")
                        })
                    }else if (response.data.resultat === "Une erreur est survenue."){
                        this.setState({disableAcceptProposal:false})
                        reject(response.data.resultat)
                        Swal.fire({
                            title: "Erreur",
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/StockMarketSTO/Accueil")
                        })
                    } else{
                        this.setState({disableAcceptProposal:false})
                        reject(response.data.resultat)
                        Swal.fire({
                            title: "Manque d'informations",
                            text: response.data.resultat,
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            if(response.data.resultat === "Veuillez fournir une preuve d'identité et un justificatif de domicile avant d'acheter des actions."){
                                this.props.navigate("/StockMarketSTO/Profil?td=missingInfos&return="+this.state.td)
                            }
                        })
                    }
                })
            })
        }

    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.showBackdrop}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Proposition de vente :</h1>
                                <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:345, height:3, borderRadius:100, marginBottom:20}}></div>
                            </div>
                            {Object.keys(this.state.entreprise).length>0 && (
                                <>
                                    <div style={{width:"100%", minHeight:320, marginTop:20,marginBottom:30}}>
                                        <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"space-around", flexWrap:"wrap"}}>
                                            {this.state.displayPaypalPayment ? (
                                                <React.Fragment>
                                                    <div className={"flexCenter"} style={{width:!this.state.showBrowserView?"100%":"35%",height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                                        <div className={"divReceipt flexCenter"} style={{width:!this.state.showBrowserView&&"95%", padding:!this.state.showBrowserView&&"2%", marginTop:0}}>
                                                            <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                                                <h2 style={{margin:0, fontWeight:600, marginBottom:20}}>Récapitulatif d'achat :</h2>
                                                                <div className={"flexCenter"} style={{width:'95%', justifyContent:"space-between", marginBottom:20}}>
                                                                    <h2 className={"flexCenter"} style={{margin:0, fontWeight:100, width:"100%", textAlign:"center"}}>
                                                                        <Tooltip title={"Identifiant du vendeur"}>
                                                                            <Person style={{width:35, height:35}}/>
                                                                        </Tooltip>
                                                                        {this.state.proposal.pseudo}
                                                                        <ArrowRightAlt style={{margin:"0 5px 0 5px", width:50, height:50}}/>
                                                                        <Tooltip title={"Votre identifiant"}>
                                                                            <Person style={{width:35, height:35,color:"#73BDECFF"}}/>
                                                                        </Tooltip>
                                                                        {jwtDecoded.pseudo}</h2>
                                                                </div>
                                                                <div className={"flexCenter"} style={{width:'95%', justifyContent:"space-between"}}>
                                                                    <h2 className={"flexCenter"} style={{margin:0, fontWeight:100}}>x{this.state.proposal.numberActions} <img style={{width:31, marginLeft:4}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/> </h2>
                                                                    <h2 style={{margin:0, fontWeight:100}}>Actions <span style={{fontWeight:600}}>{this.state.entreprise.nomEntreprise}</span></h2>
                                                                </div>
                                                                <div style={{width:"95%", height:2, margin:30, marginBottom:20,borderBottom:"1px dashed #969696",}}/>
                                                            </div>
                                                            <div className={"flexCenter"} style={{justifyContent:"flex-end", width:"100%"}}>
                                                                <h2 className={"flexCenter"} style={{fontSize:34, margin:0}}>Total : {this.state.proposal.sum}<img style={{width:35, marginLeft:4,marginTop:4}} src={require("../../assets/euroIcon.png")} alt="EURO_ICON"/></h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"flexCenter"} style={{width:!this.state.showBrowserView?"100%":"35%",height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                                        <h2 style={{margin:10, marginBottom:30, fontWeight:100}}>Payez avec PayPal ou par carte bancaire</h2>
                                                        <PayPalScriptProvider options={{ "client-id": paypalPublicKey, currency: "EUR" }}>
                                                            <PayPalButton onCreateOrder={this.createOrderPaypal} onApproveOrder={()=>{this.setState({showBackdrop:true})}} />
                                                        </PayPalScriptProvider>
                                                    </div>
                                                </React.Fragment>
                                            ):(
                                                <React.Fragment>
                                                    <div className={"flexCenter"} style={{width:"29%", height:"100%", flexDirection:"column", alignItems:"flex-start", justifyContent:"space-between"}}>
                                                        <h2 className={"flexCenter"} style={{margin:0, marginBottom:5}}>
                                                            <img style={{width:45, marginRight:10}} src={`${url}images/smsto_entreprise/${this.state.entreprise.siren}/logo.png`} alt="logo"/>
                                                            {this.state.entreprise.nomEntreprise}
                                                        </h2>
                                                        <img style={{width:"100%", minWidth:260, maxWidth:500, marginLeft:0,borderRadius:5}} src={`${url}images/smsto_entreprise/${this.state.entreprise.siren}/illustration.png`} alt="illusSpuro"/>
                                                    </div>
                                                    <div className={"flexCenter"} style={{width:"39%",height:"100%",flexDirection:"column", justifyContent:"center"}}>
                                                        <div style={{width:"100%"}}>
                                                            <h3 style={{width:"100%", textAlign:"left", margin:0}}>Description :</h3>
                                                            <h4 style={{width:"100%", textAlign:"left", margin:0, fontWeight:"normal", fontStyle:this.state.entreprise.desc === "" && "italic"}}>{this.state.entreprise.desc || "Aucune description n'est disponible pour cette levée de fonds"}</h4>
                                                        </div>
                                                    </div>
                                                    <div className={"flexCenter"} style={{width:"fit-content", display:"flex",alignItems:"flex-start", padding:10, flexWrap:"wrap", boxShadow:"rgb(0 0 0 / 24%) 0px 3px 8px", borderRadius:10, flexDirection:"column"}}>
                                                        <h2 style={{margin:5}}>Proposition de vente :</h2>
                                                        <h4 style={{margin:5, fontWeight:100}}>Proposé par <span style={{fontWeight:"bold"}}>{this.state.proposal.pseudo}</span></h4>
                                                        <div className={"flexCenter"}>
                                                            <p style={{margin:5, fontWeight:100}}>Actions totales :</p>
                                                            <p style={{margin:0, fontWeight:"bold"}}>{this.state.entreprise.numberActions}</p>
                                                            <img style={{width:20, marginLeft:5}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                        </div>
                                                        <div className={"flexCenter"} style={{width:"100%"}}>
                                                            <h2 className={"flexCenter"} style={{margin:0, justifyContent:"flex-end"}}>
                                                                <span style={{fontSize:34, marginLeft:10}}>{this.state.proposal.numberActions}</span>
                                                                <img style={{width:40, marginTop:4, marginLeft:10}} src={require("../../assets/STOcoin.png")} alt="STO_Coin"/>
                                                            </h2>
                                                            <h2 style={{marginLeft:10}}>pour</h2>
                                                            <h2 className={"flexCenter"} style={{margin:0, justifyContent:"flex-end"}}>
                                                                <span style={{fontSize:34, marginLeft:10}}>{this.state.proposal.sum}</span>
                                                                <img style={{width:40, marginTop:4, marginLeft:8}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                            </h2>
                                                        </div>
                                                        <div className={"flexCenter"} style={{width:"100%"}}>
                                                            <button disabled={this.state.disableAcceptProposal} onClick={()=>{this.setState({displayPaypalPayment:true})}} className={"divButtonBuyResale"} style={{marginTop:10,backgroundImage:!this.state.disableAcceptProposal && "linear-gradient(to right, #41ff00 0%, #34cb00 51%, #41ff00 100%)"}}>Passer au paiement</button>
                                                        </div>
                                                        {this.state.showLink && (
                                                            <a href={this.state.showLink} target={"_blank"} style={{fontSize:11, marginTop:5}}>Si vous n'avez pas été redirigé sur le lien de paiement, cliquez ici.</a>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{width:"100%", height:"fit-content",marginTop: 30, display:"flex", justifyContent:"flex-start",alignItems:"flex-start", flexDirection:"column"}}>
                                        <div style={{width:'100%', backgroundColor:"#dcdcdc",height:2, marginTop:50, marginBottom:20, borderRadius:10}}/>
                                        <div className={"flexCenter"}>
                                            <h2 className={"flexCenter"}> <Description style={{marginRight:5}}/> Fichiers téléchargeables</h2>
                                            <button disabled={this.state.allFiles.length === 0} onClick={()=>{this.dlAllFiles()}} style={{padding:10, marginLeft:20}} className={"divButtonBuyResale"}>Tout télécharger</button>
                                        </div>
                                        <div style={{overflow:"auto",width:"fit-content",height:"fit-content",maxHeight:400, display:"flex", justifyContent:"flex-start",alignItems:"flex-start",flexWrap:"wrap"}}>
                                            {this.state.allFiles.length === 0 && (
                                                <p>Aucun fichiers déposés.</p>
                                            )}
                                            {this.state.allFiles.map(elt=>(
                                                <div onClick={()=>{this.downloadFile(elt.fileName)}} className={"divUploadFileEchangeCompact flexCenter"} style={{flexDirection:"column", justifyContent:"space-between"}}>
                                                    <p style={{width:"95%",margin:"5%", textAlign:"center",wordWrap: "break-word", overflow:"hidden", maxHeight:63}}>{elt.fileName}</p>
                                                    {elt.type === "image" && (
                                                        <ImageIcon style={{width:"25%", height:"25%"}}/>
                                                    )}
                                                    {elt.type === "pdf" && (
                                                        <PictureAsPdf style={{width:"25%", height:"25%"}}/>
                                                    )}
                                                    {elt.type === "fichier" && (
                                                        <InsertDriveFile style={{width:"25%", height:"25%"}}/>
                                                    )}
                                                    <div className={"flexCenter"} style={{height:"30%", width:"100%"}}>
                                                        <div className={"flexCenter darkenHover"} style={{width:"100%", height:"100%"}}>
                                                            <Download/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PropositionDeVenteSTO)