import React, {Component} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import {withRouter} from '../../../shared/NavigationUtils';
import config from "../../../json/config.json";
import {isBrowser} from "react-device-detect";
import Axios from "axios";
import Swal from "sweetalert2";
import SharedFooterEkocoOR from "./components/SharedFooterEkocoOR";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let email, iban, bic, token, pseudo, hash;

let jwtDecoded

class RedirectionQRCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            openLoad:false,
            url:"unknown",
            decompte:3,
            emailToSub:null
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950 || !isBrowser){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        let url = this.getMobileOperatingSystem()
        console.log("url", url)
        this.setState({url},()=>{
            this.decompteTimer()
        })

    }

    decompteTimer(){
        setInterval((
            ()=>{
                if(this.state.decompte > 1){
                    this.setState({decompte:this.state.decompte - 1})
                }else{
                    if(this.state.url !== "unknown"){
                        window.location.href = this.state.url
                    }
                }
            }
        ),1000)
    }

    handleChange=(event) => {
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }

    getMobileOperatingSystem() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "unknown";
        }

        if (/android/i.test(userAgent)) {
            return "https://play.google.com/store/apps/details?id=com.ekoco&hl=fr&gl=US";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "unknown";
            // return "https://apps.apple.com/fr/app/ekoco/id6463861690";
        }

        return "unknown";
    }

    subEmailToAlert(){
        console.log(this.state.emailToSub)
        Axios.post(url + "saveClientEmailOR", {email:this.state.emailToSub}).then((response)=>{
            if(response.data.resultat === "done"){
                Swal.fire({
                    title: 'Email enregistré !',
                    text: "Vous serez prévenu dès que l'application sera disponible.",
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#ec5ba1'
                })
                this.setState({emailToSub:null})
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: "Une erreur est survenue, veuillez réessayer ultérieurement.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#ec5ba1'
                })
            }
        }).catch((err)=>{
            console.log(err)
            Swal.fire({
                title: 'Erreur',
                text: "Une erreur est survenue, veuillez réessayer ultérieurement.",
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#ec5ba1'
            })
        })
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:0, justifyContent:"flex-end"}}>
                    {/*<p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold"}}>{this.state.pseudo}</p>*/}
                    {/*<AccountCircleIcon style={{color:"#b0a9a8", marginRight:50}}/>*/}
                </div>
                {/*DEBUT RENDER STATIQUE*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)", height:"80vh"}}>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute",left:50, top:"25vh"}}>
                            <img style={{width:350}} src={require("../assetsEkoco/telephoneFemme_rose.png")} alt={"Reporting"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", zIndex:1,position:"absolute", left:0, top:0}}>
                            <img style={{width:"80", objectFit:"contain"}} src={require("../assetsEkoco/topleft_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                    <div style={{marginTop:50,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",marginBottom:20,width:"100%", overflow:"hidden"}}>
                        <div style={{backgroundColor:this.state.showBrowserView ? "#FFF":"transparent", width:this.state.showBrowserView ? 500:"95%", padding:15, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:30, paddingTop:30, borderRadius:10, zIndex:2}}>
                            {this.state.url !== "unknown" ? (
                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                        <p style={{color:"#ec5ba1",fontWeight:"bold", fontSize:30, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Redirection dans {this.state.decompte}</p>
                                        <div className={"flexCenter"} style={{flexDirection:"row", justifyContent:"space-evenly"}}>
                                            <p style={{color:"#ec5ba1",fontWeight:"bold", fontSize:30, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Nous allons vous dirigez sur la page d'application d'Ekoco</p>
                                        </div>
                                    </div>
                            ):(
                                <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                    <p className={"robotoFont"} style={{color:"#ec5ba1",fontWeight:1000, fontSize:30, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Ekoco sera disponible</p>
                                    <p className={"robotoFont"} style={{color:"#ec5ba1",fontWeight:1000, fontSize:30, margin:0, marginBottom:30, textAlign:"center", whiteSpace:"pre-line"}}>à partir du 1er Octobre</p>
                                    <p className={"robotoFont"} style={{color:"#ec5ba1",fontWeight:400, fontSize:20, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Pour être prévenu de l'ouverture de l'application, vous pouvez renseigner votre email ici :</p>
                                    <input autoComplete={"off"} name={"emailToSub"} required className={"champsTexteSK champsTexteEkocoRose robotoFont"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #EC5BA1FF", color:"#EC5BA1FF"}} type={"email"} placeholder={"Entrez votre email"}/>
                                    <button onClick={()=>{this.subEmailToAlert()}} style={{width:"fit-content", marginTop:15, backgroundImage:"none", backgroundColor:"#EC5BA1FF"}} className={"buttonSK robotoFont"}><h2 style={{margin:0, fontWeight:600, color:"#FFF"}}>Me prévenir</h2></button>
                                </div>
                            )}
                        </div>
                    </div>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex",justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute", right:50, top:"25vh"}}>
                            <img style={{width:350, objectFit:"contain"}} src={require("../assetsEkoco/homme_rose.png")} alt={"Statistiques"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end" , zIndex:1,position:"absolute", right:0, bottom:0}}>
                            <img style={{width:"80%", objectFit:"contain"}} src={require("../assetsEkoco/bottomright_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                </div>
                {/*FIN RENDER STATIQUE*/}
                {/*FOOTER*/}
                <SharedFooterEkocoOR/>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Chargement en cours ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default  withRouter(RedirectionQRCode);