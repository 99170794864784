import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import {isMobile} from "react-device-detect";
import {Info} from "@mui/icons-material";
import SharedFooterGNFT from "./components/SharedFooterGNFT";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let allBubbles = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40]


const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class AccueilGNFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            eaaot:false
        };
    }

    componentWillUnmount(){

    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.bubbleGenerator()
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.bubbleGenerator()
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    bubbleGenerator(){
        if(this.state.showBrowserView){
            allBubbles.forEach((bubble,index)=>{
                let bubbleToChange = document.getElementsByClassName("bubbles")[index]
                if(bubbleToChange){
                    let randomOffset = Math.floor(Math.random() * 35)+55
                    let randomWidth = Math.floor(Math.random() * 50)+50
                    let randomDeg = Math.floor(Math.random() * 360)
                    let startDeg = Math.floor(Math.random() * 360)
                    let offsetTime = Math.floor(Math.random() * 20)+15
                    let offsetDelay = Math.floor(Math.random() * 10)
                    bubbleToChange.style.setProperty("--offsetX", randomOffset+"vw")
                    bubbleToChange.style.setProperty("--widthBubble", randomWidth+"px")
                    bubbleToChange.style.setProperty("--offsetDeg", randomDeg+"deg")
                    bubbleToChange.style.setProperty("--startDeg", startDeg+"deg")
                    bubbleToChange.style.setProperty("--offsetTime", offsetTime+"s")
                    bubbleToChange.style.setProperty("--offsetDelay", offsetDelay+"s")
                }
            })
        }else{
            allBubbles.forEach((bubble,index)=>{
                let bubbleToChange = document.getElementsByClassName("bubbles")[index]
                if(bubbleToChange){
                    let randomOffset = Math.floor(Math.random() * 70)+5
                    let randomWidth = Math.floor(Math.random() * 50)+50
                    let randomDeg = Math.floor(Math.random() * 360)
                    let startDeg = Math.floor(Math.random() * 360)
                    let offsetTime = Math.floor(Math.random() * 10)+15
                    let offsetDelay = Math.floor(Math.random() * 10)
                    bubbleToChange.style.setProperty("--offsetX", randomOffset+"vw")
                    bubbleToChange.style.setProperty("--widthBubble", randomWidth+"px")
                    bubbleToChange.style.setProperty("--offsetDeg", randomDeg+"deg")
                    bubbleToChange.style.setProperty("--startDeg", startDeg+"deg")
                    bubbleToChange.style.setProperty("--offsetTime", offsetTime+"s")
                    bubbleToChange.style.setProperty("--offsetDelay", offsetDelay+"s")
                }
            })
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this.bubbleGenerator()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.ealgnft === prevState.ealgnft) {
            this.bubbleGenerator()
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    bottleClicked(){
        // let newBubbleIndex = allBubbles.length+1
        // allBubbles.push(newBubbleIndex)
        // let bubbleToChange = document.getElementsByClassName("bubbles")[newBubbleIndex]
        // if(bubbleToChange){
        //     let randomOffset = Math.floor(Math.random() * 35)+55
        //     let randomWidth = Math.floor(Math.random() * 50)+50
        //     let randomDeg = Math.floor(Math.random() * 360)
        //     let startDeg = Math.floor(Math.random() * 360)
        //     let offsetTime = Math.floor(Math.random() * 20)+15
        //     let offsetDelay = Math.floor(Math.random() * 10)
        //     bubbleToChange.style.setProperty("--offsetX", randomOffset+"vw")
        //     bubbleToChange.style.setProperty("--widthBubble", randomWidth+"px")
        //     bubbleToChange.style.setProperty("--offsetDeg", randomDeg+"deg")
        //     bubbleToChange.style.setProperty("--startDeg", startDeg+"deg")
        //     bubbleToChange.style.setProperty("--offsetTime", offsetTime+"s")
        //     bubbleToChange.style.setProperty("--offsetDelay", offsetDelay+"s")
        // }
    }

    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", flexDirection:"column" ,height:"100%",width:"100%"}}>
                    <div className={"flexCenter"} style={{height:"calc(100vh - 70px)", marginTop:70, width:"100%", flexDirection:"column", overflow:"hidden", justifyContent:"space-between"}}>
                        {this.state.showBrowserView ? (
                            <>
                                <div/> {/*DIV POUR ALIGNER AU CENTRE LE TEXTE*/}
                                <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", width:"100%"}}>
                                    <div className={"flexCenter"} style={{width:"50%", marginLeft:80, flexDirection:"column", alignItems:"flex-start"}}>
                                        <h1 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:60, marginBottom:5}}>
                                            <img className={this.state.ealgnft ? "ealgnft":undefined} onDoubleClick={()=>{
                                                if(!this.state.ealgnft){
                                                    this.setState({ealgnft:true, eaaot:true},()=>{setTimeout(()=>{this.setState({ealgnft:false})},2000)})
                                                }
                                            }} style={{width:45, height:45,marginRight:10}} src={require("../../assets/grapelogowinenft.png")} alt="grapelogowinenft"/>
                                            Grape - NFT
                                        </h1>
                                        <h1 className={"ChampFont"} style={{color:"#ffbb41", fontSize:40}}>The first wine bottle tokenization platform</h1>
                                        <h2 className={"ChampFont"} style={{color:"#FFF",maxWidth:"80%", fontWeight:100}}>
                                            This platform is revolutionizing the wine industry by tokenizing wine bottles and offering a secure and transparent way to buy, sell, and trade rare and exclusive wines. It utilizes blockchain technology to verify the authenticity and ownership of each bottle, providing a new level of trust and accessibility to the wine market.
                                        </h2>
                                        <h2 onClick={()=>{window.open("https://bulles-innovation-solidaire.org/", "blank_")}} className={"ChampFont flexCenter"} style={{color:"#FFBB41FF",maxWidth:"80%", fontWeight:100, cursor:"pointer", margin:0}}> <Info style={{color:"#FFBB41FF", marginRight:5}} />Read more about this project</h2>
                                        <div className={"flexCenter"} style={{width:"80%", justifyContent:"flex-start", marginTop:20}}>
                                            <button
                                                onClick={()=>{
                                                    // let element = document.getElementsByClassName("suite-frontpage-GNFT")[0]
                                                    // element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                                    this.props.navigate("/GrapeNFT/Catalog")
                                                }} style={{width:"fit-content"}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Visit our catalog</h2>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                                <div style={{position:"relative", width:"100%"}}>
                                    {allBubbles.map((bubble, index)=> (
                                        <div style={{display:!this.state.showBrowserView && "none"}} className={`bubbles bubble_variant_`+index}/>
                                    ))}
                                    <div className={"flexCenter"} style={{position:"absolute", right:"3%", bottom:20, width:"50%", zIndex:100, minWidth:750, maxWidth:890}}>
                                        <img onClick={()=>{
                                            if(this.state.eaaot) {
                                                let bottleClickable = document.getElementsByClassName("champagneBottleClickable")[0]
                                                /*scale up the bottle on click*/
                                                bottleClickable.style.setProperty("--scaleProperty", "0.95")
                                                setTimeout(()=>{
                                                    bottleClickable.style.setProperty("--scaleProperty", "1")
                                                }, 500)
                                                this.bottleClicked()
                                            }
                                        }} className={this.state.eaaot ? "champagneBottleClickable":undefined} style={{width:"24%", zIndex:21, cursor:this.state.eaaot && "cursor"}} src={require("../../assets/ChampagneBottle.webp")} alt="ChampagneBottle"/>
                                        <div className={"bottleShadow"}/>
                                    </div>
                                    <div className={"tableShape2"}/>
                                    {/*<div className={"tableShape"}/>*/}
                                </div>
                            </>
                        ):(
                            <>
                                <div className={"flexCenter"} style={{display:"flex", width:"100%", flexDirection:"column"}}>
                                    <div className={"flexCenter"} style={{width:"95%", flexDirection:"column", alignItems:"flex-start"}}>
                                        <h1 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:"12vw", marginBottom:5}}>
                                            <img className={this.state.ealgnft && "ealgnft"} onDoubleClick={()=>{
                                                this.setState({ealgnft:true},()=>{setTimeout(()=>{this.setState({ealgnft:false})},2000)})
                                            }} style={{width:"12vw", height:"12vw",marginRight:10}} src={require("../../assets/grapelogowinenft.png")} alt="grapelogowinenft"/>
                                            Grape - NFT
                                        </h1>
                                        <h1 className={"ChampFont"} style={{color:"#ffbb41", fontSize:"8vw"}}>The first wine bottle tokenization platform</h1>
                                        <h2 className={"ChampFont"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, fontSize:"5vw", margin:0}}>
                                            This platform is revolutionizing the wine industry by tokenizing wine bottles and offering a secure and transparent way to buy, sell, and trade rare and exclusive wines. It utilizes blockchain technology to verify the authenticity and ownership of each bottle, providing a new level of trust and accessibility to the wine market.
                                        </h2>
                                        <h2 onClick={()=>{window.open("https://bulles-innovation-solidaire.org/", "blank_")}} className={"ChampFont flexCenter"} style={{color:"#FFBB41FF",maxWidth:"80%", fontWeight:100, cursor:"pointer", margin:0}}> <Info style={{color:"#FFBB41FF", marginRight:5}} />Read more about this project</h2>
                                        <div className={"flexCenter"} style={{width:"80%", justifyContent:"flex-start", marginTop:20}}>
                                            <button
                                                onClick={()=>{
                                                    // let element = document.getElementsByClassName("suite-frontpage-GNFT")[0]
                                                    // element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                                                    this.props.navigate("/GrapeNFT/Catalog")
                                                }} style={{width:"fit-content"}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Visit our catalog</h2></button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{position:"relative", width:"100%"}}>
                                    {allBubbles.map((bubble, index)=> (
                                        <div style={{display:this.state.showBrowserView && "none"}} className={`bubbles bubble_variant_`+index}/>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div className={"suite-frontpage-GNFT flexCenter"} style={{width:"100%", height:40, marginTop:-40, alignItems:"flex-start", display:!this.state.showBrowserView && "none"}}>
                        {/*<p className={"ChampFont"} style={{fontSize:60, fontWeight:"bold", color:"#1B1B1BFF", marginTop:100}}>Read more</p>*/}
                    </div>

                </div>
                <SharedFooterGNFT/>
            </>
        );
    }
}

export default withRouter(AccueilGNFT)