import React, {Component} from 'react';
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Tooltip
} from '@mui/material';
import {withRouter} from '../../../shared/NavigationUtils';
import Axios from "axios";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {sha256} from "js-sha256";
import config from "../../../json/config.json";
import tooltipContenu from "../../../json/contenu.json";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {isBrowser} from "react-device-detect";
import Swal from "sweetalert2";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
let pseudo,clef,hash,lienInscription = "";

class PolitiqueDeConfidentialite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialog : false,
            pseudo : null,
            clef: null,
            marginLeftContact:20,
            marginRightContact:10,
            displayed:false,
            displayed2:false,
            openLoad:false,
            goToTopDisplay:"none",
            goToTopOpacity:0,
            ChampsEmailPro:"",
            ChampsMessagePro:"",
            flexDirectionCCM:"column",
            marginBottomAvantFooter:20,
            displayQRCode:false,
            politiqueConfidentialite:false
        };
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
        document.removeEventListener('scroll', this.trackScrolling)
    }

    componentDidMount() {
        if (this.props.location.link !== undefined){
            document.getElementById(this.props.location.link).scrollIntoView()
        }
        document.addEventListener('scroll', this.trackScrolling);
        window.addEventListener('resize', this.resizePage)
        if (isBrowser){
            this.setState({
                marginLeftContact:50,
                marginRightContact:100,
                flexDirectionCCM:"row",
                marginBottomAvantFooter:100
            })
        }
    }
    trackScrolling = () => {
        if (window.pageYOffset>=200){
            this.setState({
                goToTopDisplay:"flex",
            })
        }else{
            this.setState({
                goToTopDisplay:"none",
            })
        }
    };

    resizePage = () => {
        this.setState({windowWidth:document.body.offsetWidth})
        if (document.body.offsetWidth < 770){
            this.setState({
                flexDirectionCCM:"column"
            })
        }else{
            this.setState({
                flexDirectionCCM:"row"
            })
        }
    }

    hideContact(){
        this.setState({
            displayed2:false
        })
    }
    displayContact(){
        this.setState({
            displayed2:true
        })
    }
    handleChange = (event) =>{ //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }
    handleClickDialog = () =>{
        lienInscription="Pas de compte ? Inscrivez-vous !"
        this.setState({
            displayed : true
        });
    };
    handleCloseDialog = () =>{
        this.setState({
            displayed : false,
            displayed2: false
        });
    };
    handleValidation(){
        return true;
    };

    handleSubmitContact=(event) => { //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        event.preventDefault();
        const data= new FormData(event.target);
        let emailSender = data.get('champsEmail'), sujet = data.get('champsSujet'), message = data.get('champsMessage');
        event.preventDefault();
        if(this.handleValidation()){
            this.setState({
                openLoad:true
            })
            Axios.post(url+"contact", {
                email : emailSender,
                sujet : sujet,
                message : message,
            }).then(() => {
                this.setState({
                    openLoad:false
                })
                Toast.fire({
                    title:"Email envoyé avec succès.",
                    icon:"success"
                })
                this.handleCloseDialog()
            }).catch(() => {
                this.setState({
                    openLoad:false
                })
                Swal.fire({
                    title: 'Erreur!',
                    text: "Erreur lors de l'envoie de l'email.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            });
        }
    }

    sendFooterLink(e){
        this.props.navigate(
            {
                pathname: '/ekoco/BasCarboneBlockchain',
                link: e.target.id
            }
        )
    }

    handleSubmit(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        let data= new FormData(event.target);
        pseudo = data.get('champsPseudo'); clef = data.get('champsClef');
        event.preventDefault();
        if(()=>{this.handleValidation(clef, pseudo)}){
            hash = sha256(pseudo.concat(clef)) //Hashage avec protocole sha256
            Axios.post(url+"BCBConnexionClient", {
                hash : hash,
                hashJwt:this.state.hash
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                if (response.data.resultat === 'Utilisateur introuvable'){
                    Swal.fire({
                        title: 'Erreur!',
                        text: "L'utilisateur est introuvable.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                    this.setState({
                        displayed:false
                    })
                }else{
                    Toast.fire({
                        title:"Connexion réussie!",
                        icon:"success"
                    })
                    if(response.data.jwt){
                        sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
                    }
                    sessionStorage.setItem('EKOCO_pseudo', pseudo);
                    sessionStorage.setItem('EKOCO_hash', hash);
                    this.props.navigate('/Ekoco/Statistiques')
                }
            }).catch(error => {console.log(error);});
        }
    }
    render(){ //Affichage sur la page
        return (
            <div id={"topOfThePage"} style={{display:"flex",height:'100%', width:'100%', flexDirection:"column",transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.displayed2}
                    onClose={()=>{this.hideContact()}}
                >
                    <DialogTitle>Contactez nos administrateurs <span style={{color:"#6abfa2", fontWeight:"bold"}}>Ekoco</span> !</DialogTitle>
                    <form onSubmit={(e)=>{this.handleSubmitContact(e)}}>
                        <DialogContent>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsEmail"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} required={true} type={"email"} label="Entrez votre email" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmailContact}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsSujet"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} id="outlined-basic champsPseudo" required={true} label="Entrez votre sujet" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipSujet}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsMessage"} className={"champsTexte"} value={this.state.value} onChange={this.handleChange} multiline={true} rows={6} rowsMax={10} id="outlined-basic champsPrenom" required={true} label="Entrez un message" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipMessage}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <Button style={{backgroundColor:"#5ecaab",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5}} variant={"outlined"} type={"submit"} >Envoyer</Button>
                        </DialogContent>
                    </form>
                </Dialog>
                <Dialog
                    open={this.state.displayQRCode}
                    onClose={()=>{this.setState({displayQRCode:false})}}

                >
                    <DialogContent style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                        <CloseIcon style={{position:"absolute", right:15, top:15, cursor:"pointer"}} onClick={()=>{this.setState({displayQRCode:false})}} />
                        <h1>Scannez ou cliquez moi !</h1>
                        <a href="https://play.google.com/store/apps/details?id=com.ekoco" target={"_blank"} style={{textAlign:"center", paddingBottom:50}}>
                            <img style={{width:"100%"}} src={require("../assetsEkoco/QRCODEPlayStore.png")} alt="QR_Code"/>
                        </a>
                    </DialogContent>
                </Dialog>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Le mail va être envoyé dans quelques instants ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
                <Dialog
                    open={this.state.displayed}
                    onClose={()=>{this.handleCloseDialog()}}
                >
                    <DialogTitle style={{color:"#2c2e35"}}>Connectez-vous à <span style={{color:"#6abfa2", fontWeight:"bold"}}>Ekoco</span> !</DialogTitle>
                    <form onSubmit={(e)=>{this.handleSubmit(e)}}>
                        <DialogContent>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField  name={"champsPseudo"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" required={true} label="Identifiant" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsClef" required={true} type={"password"} label="Clef Unique" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                    <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <p>Pas de compte ? <a style={{color:'blue', textDecoration:"underline blue", cursor:"pointer"}} onClick={()=>{this.setState({displayQRCode:true})}}>Téléchargez l'application Ekoco !</a></p>
                            <Button style={{backgroundColor:"#5ecaab",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5}} type={"submit"}>Se connecter</Button>
                        </DialogContent>
                    </form>
                </Dialog>
                {isBrowser === true ? (
                    <div onClick={()=>{document.getElementById("topOfThePage").scrollIntoView()}} style={{zIndex:5,display:this.state.goToTopDisplay,opacity:0.4, width:"8vh",height:"8vh",backgroundColor:"grey", position:"fixed",marginRight:30,marginTop:840, borderRadius:15, alignSelf:"flex-end",justifyContent:"center",alignItems:"center", cursor:"pointer"}}>
                        <KeyboardArrowUpIcon style={{fontSize:45}}/>
                    </div>
                ):("")}

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end"}}>
                    <Button style={{padding:0, borderRadius:5}} onClick={()=>{this.handleClickDialog()}} > <div style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}> <img style={{width:30, margin:0}} src={require('../assetsEkoco/BOUTON_CONNEXION_APPLI MOBILE.png')} alt="Bouton connexion"/><p style={{color:'#b0a9a8',textTransform:"none", margin:0}} >Se connecter</p> </div>  </Button>
                    <Button onClick={()=>{this.displayContact()}} style={{marginLeft:this.state.marginLeftContact, marginRight:this.state.marginRightContact, backgroundColor:"#5ecaab", color:"white", padding:"10px 30px", fontWeight:"bold", zIndex:2}}>Contact</Button>
                </div>
                {isBrowser === true ? (
                    <>
                        {this.state.windowWidth<650 ? (""):(<img style={{width:"14vw", margin:"20px 75vw 0px 10vw", position:"absolute", cursor:"pointer"}} src={require('../assetsEkoco/Ekoco.png')} alt="Logo Ekoco" onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain")}}/>)}
                    </>
                ):(
                    <>
                        <img style={{width:"20vw", margin:"20px 75vw 0px 10vw", position:"absolute"}} src={require('../assetsEkoco/Ekoco.png')} alt="Logo Ekoco"/>
                    </>
                )}
                {/*DEBUT CONTENT*/}

                <div id={"centrePageAccueil"} style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                    <h2>Politique de confidentialité</h2>
                    <div style={{margin:400,marginTop:180,marginBottom:100, display:"flex", flexDirection:"column"}}>
                        <p><strong>Application EKOCO MOBILE</strong></p>
                        <p><strong>1 Mars 2021</strong></p>
                        <p><strong>INTRODUCTION</strong></p>
                        <p>La protection de vos donn&eacute;es personnelles est importante pour nous. Nous nous engageons &agrave; ne collecter que les donn&eacute;es dont nous avons besoin pour vous assurer un service optimal, &agrave; en assurer la confidentialit&eacute; et la s&eacute;curit&eacute;, y compris lorsque nous faisons appel &agrave; des prestataires et &agrave; faciliter l&rsquo;exercice de vos droits sur vos donn&eacute;es.</p>
                        <p>Nous nous engageons &agrave; traiter vos donn&eacute;es dans le plus strict respect de la Loi Informatique et Libert&eacute;s du 6 janvier 1978 (<em>ci-apr&egrave;s &laquo; loi IEL &raquo;</em>) modifi&eacute;e et du R&egrave;glement (UE) g&eacute;n&eacute;ral sur la protection des donn&eacute;es du 27 avril 2016 et du RGPD</p>
                        <p>Nous ne traitons pas de cat&eacute;gories particuli&egrave;res de donn&eacute;es (donn&eacute;es sensibles) telles que les donn&eacute;es &eacute;num&eacute;r&eacute;es &agrave; l&rsquo;article 9 du RGPD qui r&eacute;v&egrave;lent l&rsquo;origine raciale ou ethnique, les opinions politiques, les convictions religieuses ou philosophiques ou l&rsquo;appartenance syndicale, des donn&eacute;es g&eacute;n&eacute;tiques, des donn&eacute;es biom&eacute;triques, des donn&eacute;es concernant la sant&eacute; ou des donn&eacute;es concernant la vie sexuelle ou l&rsquo;orientation sexuelle des personnes.</p>
                        <p>La pr&eacute;sente politique de confidentialit&eacute; d&eacute;crit les donn&eacute;es personnelles que nous recueillons, comment elles sont utilis&eacute;es et vos droits &agrave; cet &eacute;gard. Elle s&rsquo;applique &agrave; tout utilisateur qui acc&egrave;de &agrave; l&rsquo;application et qui utilise les services propos&eacute;s sur l&rsquo;application.</p>
                        <p>Nous nous r&eacute;servons le droit de modifier la pr&eacute;sente politique de confidentialit&eacute; &agrave; tout moment. La version la plus actuelle de la pr&eacute;sente politique r&eacute;git notre utilisation de vos informations et sera toujours disponible sur l&rsquo;application ou sur demande aupr&egrave;s de EKOCO.</p>
                        <p>Si nous devions apporter une modification substantielle &agrave; cette politique de confidentialit&eacute;, nous nous engageons &agrave; vous la notifier via votre adresse email.</p>
                        <p>&nbsp;<strong>DONNEES QUE NOUS COLLECTIONS</strong></p>
                        <p>Dans le cadre de l&rsquo;utilisation de nos services et de votre navigation sur notre application, EKOCO collecte plusieurs cat&eacute;gories de donn&eacute;es dont vous trouverez le d&eacute;tail ci-dessous. Ces donn&eacute;es proviennent :</p>
                        <ul>
                            <li>Des informations que vous nous communiquez lorsque vous vous inscrivez sur notre application, ou lorsque vous &eacute;changez avec EKOCO. Ces informations personnelles sont&nbsp;:</li>
                            <li>Des donn&eacute;es permettant votre identification&nbsp;(empreinte / hash, de votre speudo et votre cl&eacute; de preuve)</li>
                            <li>Le token d&rsquo;identification g&eacute;n&eacute;r&eacute; par le serveur EKOCO. Il s&rsquo;agit d&rsquo;une liste de caract&egrave;res uniques utilis&eacute;e lors des communications avec le serveur pour identifier l&rsquo;utilisateur.</li>
                        </ul>
                        <p>&nbsp;Des donn&eacute;es de localisation</p>
                        <p>Nous vous demandons l&rsquo;autorisation pour collecter sur notre application les donn&eacute;es pr&eacute;cises de localisation de votre mobile via le syst&egrave;me d&rsquo;autorisation que le syst&egrave;me d&rsquo;exploitation de votre mobile utilise. Si vous autorisez la collecte de ces donn&eacute;es, vous pouvez revenir ult&eacute;rieurement sur votre choix en changeant les param&egrave;tres de localisation de votre t&eacute;l&eacute;phone portable. Cependant, cela limitera votre capacit&eacute; &agrave; utiliser certaines fonctionnalit&eacute;s de nos services.</p>
                        <p>Nous utilisons des cookies pour am&eacute;liorer l&rsquo;acc&egrave;s &agrave; notre application et l&rsquo;exp&eacute;rience de l&rsquo;utilisateur. Pour en savoir plus sur la politique d&rsquo;utilisation de cookies.</p>
                        <p>&nbsp;<strong>FINALITES </strong></p>
                        <p>EKOCO proc&egrave;de au traitement de vos donn&eacute;es &agrave; caract&egrave;re personnel pour des finalit&eacute;s d&eacute;termin&eacute;es, explicites et l&eacute;gitimes.</p>
                        <p>En particulier, ces donn&eacute;es sont destin&eacute;es &agrave;&nbsp;:</p>
                        <ul>
                            <li>La cr&eacute;ation et la gestion de votre compte;</li>
                            <li>L&rsquo;utilisation de nos services&nbsp;;</li>
                            <li>Vous adressez des communications pertinentes et adapt&eacute;es &agrave; vos besoins directement en lien avec les services du site&nbsp;;</li>
                            <li>S&eacute;curiser le site ainsi que les donn&eacute;es et &eacute;changes intervenant sur le site;</li>
                            <li>Faire &eacute;voluer nos services pour vous offrir de nouvelles fonctionnalit&eacute;s et nous adapter &agrave; vos besoins&nbsp;;</li>
                            <li>Communiquer avec vous&nbsp;;</li>
                        </ul>
                        <ul>
                            <li>R&eacute;pondre &agrave; une injonction des autorit&eacute;s l&eacute;gales et notamment pour lutter contre la fraude et plus g&eacute;n&eacute;ralement contre toute activit&eacute; p&eacute;nalement r&eacute;pr&eacute;hensible.</li>
                        </ul>
                        <p>&nbsp;<strong>BASE JURIDIQUE DES TRAITEMENTS</strong></p>
                        <p>EKOCO traite la plupart de vos donn&eacute;es personnelles dans le cadre du contrat que vous avez conclu lors de votre inscription sur l&rsquo;application via l&rsquo;acceptation de nos conditions g&eacute;n&eacute;rales d&rsquo;utilisation. Toutefois, nous sommes susceptibles de traiter certaines donn&eacute;es vous concernant sur la base de votre consentement, en raison d&rsquo;obligations l&eacute;gales ou pour r&eacute;pondre &agrave; notre int&eacute;r&ecirc;t l&eacute;gitime &agrave; les traiter.</p>
                        <p>&nbsp;<strong>DESTINATAIRES OU CATEGORIES DE DESTINATAIRES</strong></p>
                        <p>Nous conservons ou divulguons vos informations si nous estimons que cela est raisonnablement n&eacute;cessaire pour satisfaire &agrave; toute obligation l&eacute;gale ou r&eacute;glementaire, toute proc&eacute;dure juridique ou demande administrative, pour prot&eacute;ger la s&eacute;curit&eacute; d&rsquo;une personne, pour traiter tout probl&egrave;me de nature frauduleuse, s&eacute;curitaire ou technique, ou pour prot&eacute;ger les droits ou les biens de nos utilisateurs.</p>
                        <p>EKOCO ne revend pas vos donn&eacute;es et ne transmettra jamais vos donn&eacute;es personnelles &agrave; aucun tiers susceptible de les utiliser &agrave; ses propres fins et notamment &agrave; des fins commerciales et/ou de publicit&eacute; directe, sans votre consentement expr&egrave;s. Par cons&eacute;quent, EKOCO ne divulgue pas d&rsquo;informations personnelles en dehors des situations d&eacute;crites dans la pr&eacute;sente politique de confidentialit&eacute;.</p>
                        <p><strong>TRANSFERT DES DONNEES HORS UE</strong></p>
                        <p>Tous nos serveurs sur lesquels vos donn&eacute;es sont conserv&eacute;es et ceux des prestataires utilis&eacute;s pour &eacute;changer et stocker ces donn&eacute;es sont localis&eacute;es en Europe.</p>
                        <p>&nbsp;<strong>DUREE DE CONSERVATION DES DONNEES</strong></p>
                        <p>EKOCO conserve vos informations tant que votre compte reste actif, &agrave; moins que vous ne demandiez leur suppression ou celle de votre compte. Dans certains cas, nous pouvons conserver des informations vous concernant en raison de la loi ou &agrave; d&rsquo;autres fins, m&ecirc;me si vous supprimez votre compte.</p>
                        <p>Par ailleurs, les donn&eacute;es permettant d&rsquo;&eacute;tablir la preuve d&rsquo;un droit ou d&rsquo;un contrat, ou conserv&eacute;es au titre du respect d&rsquo;une obligation l&eacute;gale, peuvent faire l&rsquo;objet d&rsquo;une politique d&rsquo;archivage interm&eacute;diaire pour une p&eacute;riode correspondant aux dur&eacute;es de prescription l&eacute;gale (et notamment le d&eacute;lai de droit commun de 5 ans).</p>
                        <p>&nbsp;<strong>SECURITE DES DONNEES</strong></p>
                        <p>EKOCO met en &oelig;uvre les mesures techniques et organisationnelles appropri&eacute;es afin de garantir la s&eacute;curit&eacute;, la confidentialit&eacute;, l&rsquo;int&eacute;grit&eacute; et la disponibilit&eacute; des services et prot&eacute;ger les donn&eacute;es contre la destruction, la perte, l&rsquo;alt&eacute;ration, la divulgation non autoris&eacute;e de donn&eacute;es &agrave; caract&egrave;re personnel transmises, conserv&eacute;es ou trait&eacute;es d&rsquo;une autre mani&egrave;re, ou l&rsquo;acc&egrave;s non autoris&eacute; &agrave; de telles donn&eacute;es. La technologie utilis&eacute;e est la Blockchain.</p>
                        <p>&nbsp;<strong>&nbsp;</strong></p>
                        <p><strong>DROIT D&rsquo;INTRODUIRE UN RECOURS AUPRES D&rsquo;UNE AUTORITE DE CONTROLE</strong></p>
                        <p>L&rsquo;autorit&eacute; de contr&ocirc;le comp&eacute;tente pour conna&icirc;tre de toute demande nous concernant, est la Commission Nationale de l&rsquo;Informatique et des Libert&eacute;s (CNIL).Si souhaitez saisir la CNIL de toute demande, vous trouverez ci-dessous les coordonn&eacute;es:</p>
                        <p>&nbsp;CNIL (COMMISSION NATIONALE DE L&rsquo;INFORMATIQUE ET DES LIBERT&Eacute;S)</p>
                        <p>3 Place de Fontenoy &ndash; TSA 80715 &ndash; 75334 PARIS CEDEX 07</p>
                        <p>T&eacute;l. : 01 53 73 22 22</p>
                        <p>(du lundi au jeudi de 9h &agrave; 18h30 / le vendredi de 9h &agrave; 18h)</p>
                        <p>Fax : 01 53 73 22 00</p>
                        <p>&nbsp;<strong>RESPONSABLE DE TRAITEMENT DES DONNEES</strong></p>
                        <p>EKOCO&nbsp;&nbsp;&nbsp;&nbsp; /SAS SPURO<br /> Si&egrave;ge social : 45 rue Pierre SEMARD<br /> Repr&eacute;sent&eacute;e par Benjamin FARAGGI, CEO</p>
                        <p>&nbsp;</p>
                    </div>
                </div>
                {/*Footer*/}
                <div style={{width:"100%", height:"100%",paddingTop:10, backgroundColor:"#f5f6f6", display:"flex",flexWrap:"wrap", marginTop:50, justifyContent:"center", alignItems:"center", zIndex:4}}>
                    <div style={{width:"90%", height:"90%",display:"flex", flexWrap:"wrap"}}>
                        {isBrowser === true ? (
                            <div style={{display:"flex", flex:3, width:250, height:250, justifyContent:"center", alignItems:"center"}}>
                                <img style={{width:285,height:200, textAlign:"center", cursor:"pointer"}} src={require("../assetsEkoco/Ekoco.png")} alt="LogoEkoco" onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain")}}/>
                            </div>
                        ):("")}
                        <div style={{display:"flex", flex:3, flexDirection:"column", justifyContent:"center"}}>
                            <h1 className={"arialExtraBold"} style={{margin:0,color:"#2c2e35"}}>L’application qui fait</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>DU BIEN</h1>
                            <h1 className={"arialExtraBold"} style={{margin:0, color:"#5ecaab"}}>À LA PLANÈTE</h1>
                        </div>
                        <div style={{display:"flex",flex:4}}>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=cestQuoi")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO c’est quoi ?</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=commentCaMarche")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Comment ça marche ?</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=reporting")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>Un reporting détaillé</p>
                                <p className={"arialArrow"} onClick={()=>{this.props.navigate("/Ekoco/BasCarboneBlockchain?scroll=pourEntreprise")}} style={{color:"#b0a9a8", fontSize:18, cursor:"pointer"}}>EKOCO pour les entreprises</p>
                            </div>
                            <div style={{display:"flex", flex:2, flexDirection:"column"}}>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/PolitiqueDeConfidentialite")}}>Politique de confidentialité</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}} onClick={()=>{this.props.navigate("/Ekoco/ConditionsGeneralesDutilisation")}}>CGU</p>
                                <p className={"arialArrow"} onClick={()=>{this.displayContact()}} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Contacts</p>
                                <p className={"arialArrow"} style={{color:"#b0a9a8", fontSize:18,cursor:"pointer"}}>Utilisation des cookies</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default withRouter(PolitiqueDeConfidentialite);