import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {sha256} from "js-sha256";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import tooltipContenu from "../../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {KeyboardReturn} from "@mui/icons-material";
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip
} from "@mui/material";

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

class InscriptionSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identifiantValue : "",
            passwordValue : "",
            emailValue:"",
            verifValue:"",
            passwordBisValue:"",
            showBrowserView:true,
            imageToShow:Math.floor(Math.random() * 2) + 1,
            firstDisplay:false,
            type:null,
            phaseCreation:1,
            showBackdrop:false,
            forceDisable:false
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }
    _emitConnectedBR
    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedBR = global.emitter.addListener("emitConnectedSMSTO",()=>{ //On prévient que le client s'est connecté
            if(this.state.type === "ldf"){
                this.props.navigate("/StockMarketSTO/Entreprise?td=new")
            }else{
                this.props.navigate("/StockMarketSTO/Profil")
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value.trim()
        })
    }

    clickNext(e){
        e.preventDefault()
        this.setState({phaseCreation:2})
    }

    submitFirstStepCreation(e, force){
        this.setState({forceDisable:true})
        e.preventDefault()
        new Promise(async resolve => {
            if (!force){
                Swal.fire({
                    icon:"info",
                    html:"<p style={{fontSize:12}}>Nous ne pourrons pas regénérer une clef unique en cas de perte, pensez à la stocker en lieu sûr. <br/> En cochant cette case, vous acceptez nos <a href='/StockMarketSTO/CGUSTO' target={'_blank'}>Conditions générales d'utilisation</a></p>\n",
                    input: 'checkbox',
                    inputValue: 0,
                    target:"#dialogInscription",
                    allowEscapeKey:false,
                    allowOutsideClick:false,
                    inputPlaceholder:
                        "J'accepte les conditions générales d'utilisations.",
                    confirmButtonText:
                        "Continuer",
                    inputValidator: (result) => {
                        return !result && 'Vous devez accepter les conditions !'
                    }
                }).then(()=> {
                    resolve()
                })
            }else{
                resolve()
            }
        }).then(()=>{
            let hash = sha256((this.state.identifiantValue.trim()).concat(this.state.passwordValue.trim())) //Hashage avec protocole sha256
            if((this.state.passwordValue.trim()).match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!.@#$%^&*?-])[A-Za-z\d!.@#$%^&*?-]{12,}$/)) {
                if(this.state.emailValue.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)){
                    if((this.state.identifiantValue.trim()).match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{4,29}$/)){
                        if(this.state.passwordBisValue.trim() === this.state.passwordValue.trim()){
                            Axios.post(url+"generateCodeToVerifyEmail", {
                                empreinte : hash,
                                pseudo:this.state.identifiantValue.trim(),
                                email : this.state.emailValue,
                                clefToEncrypt:this.state.passwordValue.trim(),
                                nom:this.state.nomValue,
                                prenom:this.state.prenomValue,
                                ddn:this.state.dobValue,
                                adresse:this.state.adresseValue,
                                ville:this.state.villeValue,
                                postal:this.state.postalValue,
                                tel:this.state.telValue,
                                app:"smsto",
                                force
                            }).then(response => {
                                if(response.data.resultat==="done"){
                                    this.setState({displayInscription:false})
                                    this.validateCreation("smsto")
                                    // Swal.fire({
                                    //     title: 'Entrez le code reçu par mail',
                                    //     html:"<p style='font-weight: 100'>La réception du mail peut prendre <span style='font-weight: bold'>quelques minutes</span>, pensez à vérifier vos <span style='font-weight: bold'>spams</span>. Ce code n'est valable <span style='font-weight: bold'>qu'une heure</span>.</p>",
                                    //     input: 'text',
                                    //     inputAttributes: {
                                    //         autocapitalize: 'off'
                                    //     },
                                    //     allowOutsideClick:false,
                                    //     allowEscapeKey:false,
                                    //     showCancelButton: true,
                                    //     cancelButtonText:"Annuler",
                                    //     cancelButtonColor:"#d14529",
                                    //     confirmButtonText: 'Suivant',
                                    //     showDenyButton:true,
                                    //     denyButtonText:"Renvoyer le code",
                                    //     denyButtonColor:"#757575",
                                    //     showLoaderOnConfirm: true,
                                    //     preConfirm: (code) => {
                                    //         if(code !== "" && code.length === 6){
                                    //             this.setState({displayInscription:false})
                                    //             this.validateCreation(code)
                                    //         }else{
                                    //             Toast.fire({
                                    //                 icon: 'error',
                                    //                 title: 'Veuillez saisir un code correct.',
                                    //                 target:"#dialogInscription"
                                    //             })
                                    //             this.submitFirstStepCreation(e, true)
                                    //         }
                                    //     },
                                    // }).then((result)=> {
                                    //     if(result.isDenied) {
                                    //         this.submitFirstStepCreation(e, true)
                                    //     }
                                    //     if(result.isDismissed) {
                                    //         this.setState({forceDisable:false})
                                    //     }
                                    // })
                                }else{
                                    this.setState({forceDisable:false})
                                    Swal.fire({
                                        title: 'Erreur!',
                                        text: response.data.resultat,
                                        icon: 'error',
                                        confirmButtonText: 'Ok'
                                    })
                                }
                            }).catch(error => {
                                console.log(error);
                            });
                        }else{
                            this.setState({forceDisable:false})
                            Swal.fire({
                                title: 'Erreur!',
                                text: "Vos deux clés uniques ne correspondent pas.",
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }
                    }else{
                        this.setState({forceDisable:false})
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Votre identifiant ne peut contenir que des lettres de a-z, des chiffres de 0-9, des tiret bas (_) et des points.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                }else{
                    this.setState({forceDisable:false})
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Votre email est dans un format incorrect.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }else{
                this.setState({forceDisable:false})
                Swal.fire({
                    title: 'Erreur!',
                    text: "Votre clef unique ne respecte pas les critères de sécurité.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChangePhone(event){
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if(onlyNums.length < 10){
            this.setState({
                [event.currentTarget.name] : onlyNums
            })
        } else if (onlyNums.length === 10){
            const number = onlyNums.replace(
                /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                '$1 $2 $3 $4 $5'
            );
            this.setState({
                [event.currentTarget.name] : number
            })
        }
    }

    validateCreation(code){
        this.setState({showBackdrop:true})
        let hash = sha256((this.state.identifiantValue.trim()).concat(this.state.passwordValue.trim())) //Hashage avec protocole sha256
        Axios.post(url + "inscriptionSMSTO", {
            empreinte: hash,
            pseudo: this.state.identifiantValue.trim(),
            email: this.state.emailValue,
            clefToEncrypt: this.state.passwordValue.trim(),
            nom: this.state.nomValue,
            prenom: this.state.prenomValue,
            ddn: this.state.dobValue,
            adresse: this.state.adresseValue,
            ville: this.state.villeValue,
            postal: this.state.postalValue,
            tel: this.state.telValue,
        }).then(response => {
            if (response.data.resultat === 'done') {
                this.setState({showBackdrop: false})
                Swal.fire({
                    title: 'Succès!',
                    text: "Le compte " + this.state.identifiantValue + " a bien été créé!",
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then(() => {
                    global.emitter.emit("emitInscrisSMSTO", {
                        identifiant: this.state.identifiantValue,
                        password: this.state.passwordValue
                    })
                })
            } else {
                this.setState({showBackdrop: false, forceDisable: false})
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }).catch(error => {
            console.log(error);
        });
    }

    displayError(e){
        e.preventDefault()
        let toDisplay = ""
        switch (e.target.name){
            case "identifiantValue":
                toDisplay = "Identifiant"
            break;
            case "emailValue":
                toDisplay = "Email"
            break;
            case "passwordValue":
                toDisplay = "Clef unique"
            break;
            case "passwordBisValue":
                toDisplay = "Vérification de clef unique"
            break;
        }
        Toast.fire({
            icon: 'error',
            title: `Le champ suivant n'est pas correct : ${toDisplay}`,
            target:"#dialogInscription"
        })
    }

    getMaxDateProfil(){
        let date = new Date()
        date.setDate(date.getDate())
        date = date.toISOString().split("T")[0]
        return date
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.firstDisplay}
                    onClose={()=>{this.setState({firstDisplay:false})}}
                    style={{padding:11}}
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                >
                    <DialogTitle>
                        <div style={{width:'100%', position:"relative"}}>
                            <div style={{alignSelf:"flex-end", position:"absolute",display:"flex", justifyContent:"flex-end", width:"100%"}}>
                                <Tooltip title={"Retour"}>
                                    <IconButton onClick={()=>{this.props.navigate(-1)}} style={{cursor:"pointer", margin:5, padding:5, color:"black"}}>
                                        <KeyboardReturn/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <h2 style={{margin:0, fontWeight:100}}>Je veux :</h2>
                    </DialogTitle>
                    <DialogContent className={"flexCenter"} style={{height:"100%", maxHeight:350, flexDirection:"column"}}>
                        <div className={"flexCenter"}>
                            <div onClick={()=>{this.setState({firstDisplay:false, type:"ldf"})}} className={"divChoixInscription flexCenter"}>
                                <h2 style={{fontWeight:100}}>Lever des fonds</h2>
                            </div>
                            <div onClick={()=>{this.setState({firstDisplay:false})}} className={"divChoixInscription flexCenter"}>
                                <h2 style={{fontWeight:100}}>Acheter des actions</h2>
                            </div>
                        </div>
                        <p style={{width:500, fontWeight:100, fontSize:14, textAlign:"justify"}}>Les deux choix vous permettent de créer un compte et d'effectuer des levées de fonds et des investissements. L'option "lever des fonds" vous emmène directement sur l'enregistrement de votre entreprise et de sa campagne de levée de fonds. L'option "acheter des actions" vous permet simplement de créer un compte.</p>
                    </DialogContent>
                </Dialog>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.showBackdrop}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                <div className={"flexCenter"} style={{minHeight:"100vh",width:"100%"}}>
                    <div className={"flexCenter"} style={{flex:"1 1 10%",flexDirection:"column", height:"100vh", zIndex:99, backgroundColor:"#fafafa"}}>
                        <div style={{overflowY:"auto",minHeight:"30vh", minWidth:this.state.showBrowserView && 425,width:this.state.showBrowserView ?"27vw":"95%",backgroundColor:"white",flexDirection:"column",display:"flex",alignItems:"center",borderRadius:8,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                            {!this.state.firstDisplay ? (
                                <>
                                    <div style={{width:'100%', position:"relative"}}>
                                        <Tooltip title={"Retour"}>
                                            <IconButton onClick={()=>{this.props.navigate(-1)}} style={{alignSelf:"left", position:"absolute", cursor:"pointer", margin:5, padding:5, color:"black"}}>
                                                <KeyboardReturn/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <h3 className={"flexCenter"} style={{textAlign:"center", marginTop:30}}>
                                        Création d'un compte personnel
                                        <img style={{textAlign:"center", width:100,marginLeft:10}} src={require("../../assets/Logo_SMSTO.png")} alt="logoKageSecure"/>
                                    </h3>
                                    {this.state.phaseCreation === 1 ? (
                                        <form onInvalid={(e)=>{this.displayError(e)}} autoComplete="off" onSubmit={(e)=>{(this.state.type === "ldf" ? this.clickNext(e) : this.submitFirstStepCreation(e))}} style={{display:"flex",flexDirection:"column",width:"85%", marginBottom:20}}>
                                            <div className={"flexCenter"}>
                                                <TextField style={{width:"100%", marginBottom:10}} required={true} name={"identifiantValue"} onChange={(event)=>{this.handleChange(event)}} label="Identifiant" variant="outlined" />
                                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                                </Tooltip>
                                            </div>
                                            <p style={{margin:0, padding:0,fontSize:13}}>Règles de sécurité - Votre identifiant : </p>
                                            <p style={{margin:0, padding:0, fontSize:11}}>- doit contenir <span style={{fontWeight:"bold"}}> au moins 5 caractères </span> </p>
                                            <p style={{margin:0, padding:0, fontSize:11}}>- ne doit <span style={{fontWeight:"bold"}}> pas contenir d'espace </span></p>
                                            <p style={{margin:0, padding:0, fontSize:11, marginBottom:8}}>- peut contenir <span style={{fontWeight:"bold"}}> des "_" et des "." </span> sauf en fin d'identifiant </p>
                                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                                <TextField style={{width:"100%", marginBottom:10}} required={true} type={"email"} name={"emailValue"} onChange={(event)=>{this.handleChange(event)}} label="Email" variant="outlined" />
                                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>
                                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                                </Tooltip>
                                            </div>
                                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                                <TextField autoComplete="new-password" style={{width:"100%", marginBottom:10}} required={true} type={this.state.showPassword ? "text":"password"} name={"passwordValue"} onChange={(event)=>{this.handleChange(event)}} label="Clef Unique" variant="outlined"
                                                           InputProps={{
                                                               endAdornment:
                                                                   <InputAdornment position="end">
                                                                       <IconButton
                                                                           aria-label="toggle password visibility"
                                                                           onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                                       >
                                                                           {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                       </IconButton>
                                                                   </InputAdornment>
                                                           }}
                                                />
                                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                                </Tooltip>
                                            </div>
                                            <p style={{margin:0, padding:0,fontSize:13}}>Règles de sécurité - Votre clef unique doit contenir : </p>
                                            <p style={{margin:0, padding:0, fontSize:11}}>- au moins 12 <span style={{fontWeight:"bold"}}> caractères </span></p>
                                            <p style={{margin:0, padding:0, fontSize:11}}>- au moins une <span style={{fontWeight:"bold"}}> majuscule et minuscule</span></p>
                                            <p style={{margin:0, padding:0, fontSize:11}}>- au moins un <span style={{fontWeight:"bold"}}> chiffre </span></p>
                                            <p style={{margin:0, padding:0, fontSize:11}}>- au moins un <span style={{fontWeight:"bold"}}> symbole (!.@#$%^&*?-)</span></p>
                                            <p style={{margin:0, padding:0, fontSize:11, marginBottom:8}}>- aucun <span style={{fontWeight:"bold"}}> espace </span></p>
                                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                                <TextField autoComplete="new-password" style={{width:"100%", marginBottom:10}} required={true} type={this.state.showPasswordVerif ? "text":"password"} name={"passwordBisValue"} onChange={(event)=>{this.handleChange(event)}} label="Confirmez votre Clef Unique" variant="outlined"
                                                           InputProps={{
                                                               endAdornment:
                                                                   <InputAdornment position="end">
                                                                       <IconButton
                                                                           aria-label="toggle password visibility"
                                                                           onClick={()=>{this.setState({showPasswordVerif:!this.state.showPasswordVerif})}}
                                                                       >
                                                                           {this.state.showPasswordVerif ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                       </IconButton>
                                                                   </InputAdornment>
                                                           }}
                                                />
                                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                                </Tooltip>
                                            </div>

                                            <button disabled={this.state.forceDisable} className={"divButtonBuyResale flexCenter"} style={{width:"100%", marginTop:10}} type={"submit"}>{this.state.type === "ldf" ? "Suivant":"Créer mon compte"}<ChevronRightIcon/></button>
                                        </form>
                                    ):(
                                        <form autoComplete="off" onSubmit={(e)=>{this.submitFirstStepCreation(e)}} style={{display:"flex",flexDirection:"column",width:"85%", marginBottom:20}}>
                                            {this.state.type === "ldf" && (
                                                <>
                                                    <div className={"flexCenter"}>
                                                        <div className={"flexCenter"} style={{width:"100%",justifyContent:"space-between"}}>
                                                            <TextField style={{width:"39%", marginBottom:10}} required={true} name={"prenomValue"} onChange={(event)=>{this.handleChange(event)}} label="Prénom" variant="outlined" />
                                                            <TextField style={{width:"59%", marginBottom:10}} required={true} name={"nomValue"} onChange={(event)=>{this.handleChange(event)}} label="Nom" variant="outlined" />
                                                        </div>
                                                        <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                                            <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                                        </Tooltip>
                                                    </div>
                                                    <TextField type={"date"} required={true} style={{width:"100%", marginBottom:15}} name={"dobValue"} value={this.state.dobValue} onChange={(e)=>{this.handleChange(e)}} label="Date de naissance" variant="outlined" InputLabelProps={{
                                                        shrink: true
                                                    }}inputProps={{ max:`${this.getMaxDateProfil()}`}}></TextField>
                                                    <TextField style={{width:"100%", marginBottom:15}} name={"adresseValue"} defaultValue={this.state.adresseValue} value={this.state.adresseValue} onChange={(e)=>{this.handleChange(e)}} label="Adresse postale" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                    <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                        <TextField style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"postalValue"} defaultValue={this.state.postalValue} value={this.state.postalValue} onChange={(e)=>{this.handleChange(e)}} label="Code postal" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                        <TextField style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"villeValue"} defaultValue={this.state.villeValue} value={this.state.villeValue} onChange={(e)=>{this.handleChange(e)}} label="Ville" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                    </div>
                                                </>
                                            )}
                                            {this.state.type ==="ldf" && (
                                                <div className={"flexCenter"}>
                                                    <TextField style={{width:"100%", marginBottom:10}} value={this.state.telValue} required={true} name={"telValue"} onChange={(e)=>{this.handleChangePhone(e)}} label="Téléphone" variant="outlined" />
                                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>
                                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                                    </Tooltip>
                                                </div>
                                            )}
                                            <button disabled={this.state.forceDisable} className={"divButtonBuyResale flexCenter"} style={{width:"100%", marginTop:10}} type={"submit"}>Créer mon compte<ChevronRightIcon/></button>
                                            <p style={{fontSize:12}}>En cliquant sur ce bouton, vous acceptez nos<a href="#">CGU</a></p>
                                        </form>
                                    )}
                                </>
                            ):(
                                <h2 className={"flexCenter"} style={{fontWeight:100, height:"100%"}}>
                                    Inscription
                                    <img style={{textAlign:"center", width:150,marginLeft:15}} src={require("../../assets/Logo_SMSTO.png")} alt="LogoSMSTO"/>
                                </h2>
                            )}
                        </div>
                    </div>
                    {this.state.showBrowserView && (
                        <div className={"flexCenter"} style={{flex:1,flexDirection:"column", overflow:"hidden", height:"100vh",boxShadow:"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"}}>
                            <div className={"flexCenter"} style={{position:"absolute", zIndex:99, height:"100vh", flexDirection:"column"}}>
                                <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                                    <img style={{textAlign:"center", width: "80%",maxWidth:500,marginRight:5,userSelect: "none"}} src={require("../../assets/LOGO_STO_blanc.png")} alt="Logo_SMSTO"/>
                                </div>
                                <div className={"flexCenter"} style={{flex:1, height:"100%"}}>
                                    <div style={{width:this.state.showBrowserView ?"70%":"95%",display:"flex", justifyContent:"center", flexDirection:"column", alignItems:"center"}}>
                                        <h3 style={{margin:10, marginLeft:0,color:"#FFF",fontSize:25}}>Nous contacter :</h3>
                                        <p style={{margin:0,color:"#FFF",fontSize:20}}>Tel: (+33) 6 08 82 67 53</p>
                                        <p style={{margin:0,color:"#FFF", textAlign:"center",fontSize:20}}>Adresse : 9 rue Gustave Eiffel 10430 Rosière prés de Troyes</p>
                                        <a href={"https://smsto.site/contact/"} target={"_blank"} style={{margin:0,color:"#FFF",fontSize:20}}>Nous contacter par mail</a>
                                    </div>
                                </div>
                                <div className={"flexCenter"} style={{flex:1, height:"100%", flexDirection:"column", overflowX:"hidden"}}>
                                    <h3 onClick={()=>{this.props.navigate("/StockMarketSTO/PDCSTO")}} className={"textCGU"} style={{margin:5, fontWeight:"normal",color:"#FFF",fontSize:28}}>Politique de confidentialité</h3>
                                    <h3 onClick={()=>{this.props.navigate("/StockMarketSTO/CGUSTO")}} className={"textCGU"} style={{margin:5, fontWeight:"normal",color:"#FFF",fontSize:28}}>Conditions générales d'utilisation</h3>
                                </div>
                            </div>
                            <img /*onClick={()=>{this.setState({imageToShow:this.state.imageToShow===4 ? 1:this.state.imageToShow+1})}}*/ style={{width:"125%", maxWidth:900, minWidth:600, filter:"brightness(0.4)"}} src={require("../../assets/portrait landscape"+this.state.imageToShow+".png")} alt="illustration paysage"/>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(InscriptionSTO)