import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import Axios from "axios";
// import {loadStripe} from '@stripe/stripe-js';
import {Backdrop} from "@mui/material";
import {isMobile} from "react-device-detect";
import SharedFooterGNFT from "./components/SharedFooterGNFT";

// const stripe = loadStripe(stripePublicKey);

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFont ChampFontTitle',
        content: 'ChampFont ChampFontContent',
        confirmButton: 'ChampFont ChampFontButton',
        cancelButton: 'ChampFont ChampFontButton',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    }
})
class ContactGNFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    _emitConnectedGNFT;
    componentWillUnmount(){
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    contact(e){
        e.preventDefault()
        this.setState({displayLoading:true})
        /*Contact axios "contactGNFT" with data : const {contenuMail, emailEmetteur, nomEmetteur, sujet} = req.body*/
        Axios.post(url + "contactGNFT", {
            contenuMail: this.state.champsContent,
            emailEmetteur: this.state.champsEmail,
            nomEmetteur: this.state.champsName,
            sujet: this.state.champsSubject,
        }).then((response) => {
            this.setState({displayLoading:false})
            if(response.data.resultat === "done"){
                SwalDefault.fire({
                    icon: 'success',
                    title: 'Your message has been sent',
                    text: 'We will answer you as soon as possible',
                    confirmButtonText: 'Ok',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.navigate("/GrapeNFT/Catalog")
                    }
                })
            }else{
                SwalDefault.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong, please try again later',
                    confirmButtonText: 'Ok',
                })
            }
        }).catch((err) => {
            console.log(err)
            SwalDefault.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong, please try again later',
                confirmButtonText: 'Ok',
            })
        })

    }

    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", overflowX:"hidden", flexDirection:"column" ,height:"100%",width:"100%"}}>
                    <Backdrop
                        sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: 999999999 }}
                        open={this.state.displayLoading}
                    >
                        <div className={"flexCenter"} style={{flexDirection:"column"}}>
                            <div className={"loaderTextGNFT ChampFont"}>Loading</div>
                            <h2 className={"ChampFont"} style={{color:"#FFBB41FF"}}>Please wait a few seconds while we send your message</h2>
                        </div>
                    </Backdrop>
                    <div className={"flexCenter"} style={{height:"calc(100vh - 70px)", marginTop:70, width:"100%", overflow:"hidden", justifyContent:"space-between"}}>
                        <div className={"flexCenter"} style={{flex:"1 1 10%", overflow:!this.state.showBrowserView && "auto", height:"100%", alignItems:!this.state.showBrowserView&&"flex-start", zIndex:99,background: "linear-gradient(125deg, rgba(42,44,36,1) 0%, rgba(15,18,0,1) 100%)"}}>
                            <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", width:this.state.showBrowserView ? "100%":"95%"}}>
                                <form onSubmit={(e)=>{this.contact(e)}} className={"flexCenter"} style={{width:this.state.showBrowserView ? "50%":"95%", marginLeft:this.state.showBrowserView && 80, flexDirection:"column", alignItems:"flex-start"}}>
                                    <h1 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:60, marginBottom:15}}>
                                        Leave us a message
                                    </h1>
                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:!this.state.showBrowserView && "column", justifyContent:"space-between"}}>
                                        <div style={{position:"relative", width:!this.state.showBrowserView?"100%":"46%",marginBottom:15}}>
                                            <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#21231d"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", margin:"0 5px 0 5px"}}>Email</h3>
                                            </div>
                                            <input autoComplete={"off"} name={"champsEmail"} required={true} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsEmail} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%"}} type={"email"} placeholder={"Email"}/>
                                        </div>
                                        <div style={{position:"relative", width:!this.state.showBrowserView?"100%":"46%", marginBottom:15}}>
                                            <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#21231d"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", margin:"0 5px 0 5px"}}>Name</h3>
                                            </div>
                                            <input autoComplete={"off"} name={"champsName"} required={true} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsName} onChange={(e)=>{this.handleChange(e)}} style={{width:"100%",border:"1px solid #FFBB41FF"}} type={"text"} placeholder={"Name"}/>
                                        </div>

                                    </div>
                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:!this.state.showBrowserView && "column", justifyContent:"space-between"}}>
                                        <div style={{position:"relative", width:"100%", marginBottom:15}}>
                                            <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#21231d"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", margin:"0 5px 0 5px"}}>Subject</h3>
                                            </div>
                                            <input autoComplete={"off"} name={"champsSubject"}  required={true} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsSubject} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%"}} placeholder={"Subject"}/>
                                        </div>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:!this.state.showBrowserView && "column", justifyContent:"space-between"}}>
                                        <div style={{position:"relative", width:"100%", marginBottom:15}}>
                                            <div style={{position:"absolute", top:-11, left:20, backgroundColor:"#21231d"}}>
                                                <h3 className={"ChampFont"} style={{color:"#FFF", margin:"0 5px 0 5px"}}>Content</h3>
                                            </div>
                                            <textarea autoComplete={"off"} rows={5} name={"champsContent"} required={true} className={"champsTexteGNFTInscription ChampFont"} value={this.state.champsContent} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #FFBB41FF", width:"100%"}} placeholder={"Content"}/>
                                        </div>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"80%", justifyContent:this.state.showBrowserView ? "flex-start":"center", marginTop:20}}>
                                        <button disabled={this.state.displayLoading || !this.state.champsName || !this.state.champsEmail || !this.state.champsSubject|| !this.state.champsContent} style={{width:"fit-content"}} type={"submit"} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Send message</h2></button>
                                    </div>
                                </form>
                                <div className={"flexCenter"} style={{position:"relative", width:"50%", alignItems:"flex-end", display:!this.state.showBrowserView && "none"}}>
                                    <img style={{width:"78%", zIndex:21, marginTop:250, minWidth:550}} src={require("../../assets/CB-1.webp")} alt="Champagne Bottle"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SharedFooterGNFT/>
            </>
        );
    }
}

export default withRouter(ContactGNFT)