import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import Axios from "axios";
import Swal from "sweetalert2";
import config from "../../json/config.json";
import jwt_decode from "jwt-decode";
import {CircularProgress, Tooltip} from "@mui/material";
import {ArrowRightAlt, Person} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let jwtDecoded = {};

class PaiementStatut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{
            // statut:"success",
            // price:1,
            // boughtActions:1000,
            // nomEntreprise:"Guy Hoquet",
            // nomVendeur:"LucasRDPSTR1"
            statut:"",
            price:null,
            boughtActions:null,
            nomEntreprise:"",
            nomVendeur:""
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        let token = new URLSearchParams(window.location.search).get("token"),
            statut = new URLSearchParams(window.location.search).get("statut")
        if(token !== null) {
            this.setState({
                token,statut
            })
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Aucun paiement n'a été trouvé, redirection vers vos actions.",
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.props.navigate("/StockMarketSTO/MesActions")
            })
        }
        if (document.body.offsetWidth <= 950) {
            this.setState({showBrowserView: false})
        }

        this.init()

    }
    _emitConnectedBR
    init(){
        this._emitConnectedBR = global.emitter.addListener("emitConnectedSMSTO",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("SMSTO_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedStockMarketSTO", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }
    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: this.state.jwt,
                    })
                    this.setState({connected:true})
                    if(this.state.token){
                        this.finaliseInvestment()
                    }
                }else{
                    global.emitter.emit("askConnexionSMSTO",{disableOutsideClick:true})
                }
            })
        })
    }

    finaliseInvestment(){
        Axios.post(url+"finaliseInvestment",{resource:{id:this.state.token}},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                this.setState({boughtActions:response.data.boughtActions,price:parseFloat(response.data.price),nomEntreprise:response.data.nomEntreprise,nomVendeur:response.data.pseudo})
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    this.props.navigate("/StockMarketSTO/MesActions")
                })
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div className={"divReceipt flexCenter"} style={{width:!this.state.showBrowserView&&"95%", padding:!this.state.showBrowserView&&"2%"}}>
                        {this.state.boughtActions === null ? (
                            <>
                                <CircularProgress style={{margin:"20px 0 20px 0", width:90, height:90}}/>
                                <h3 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h3>
                            </>
                        ):(
                            <>
                                <h2 style={{margin:0, marginBottom:20, fontWeight:100, fontSize:34}} className={"flexCenter"}>Paiement {this.state.statut === "success" ? (
                                    <>
                                        Validé
                                        <div style={{marginLeft:25}} className="sa">
                                            <div className="sa-success">
                                                <div className="sa-success-tip"></div>
                                                <div className="sa-success-long"></div>
                                                <div className="sa-success-placeholder"></div>
                                                <div className="sa-success-fix"></div>
                                            </div>
                                        </div>
                                    </>
                                ):(
                                    <>
                                        Échoué
                                        <div style={{marginLeft:25}} className="sa">
                                            <div className="sa-error">
                                                <div className="sa-error-x">
                                                    <div className="sa-error-left"></div>
                                                    <div className="sa-error-right"></div>
                                                </div>
                                                <div className="sa-error-placeholder"></div>
                                                <div className="sa-error-fix"></div>
                                            </div>
                                        </div>
                                    </>
                                )}</h2>
                                <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                    {this.state.nomVendeur && (
                                        <div className={"flexCenter"} style={{width:'95%', justifyContent:"space-between", marginBottom:20}}>
                                            <h2 className={"flexCenter"} style={{margin:0, fontWeight:100, width:"100%", textAlign:"center"}}>
                                                <Tooltip title={"Identifiant du vendeur"}>
                                                    <Person style={{width:35, height:35}}/>
                                                </Tooltip>
                                                {this.state.nomVendeur}
                                                <ArrowRightAlt style={{margin:"0 5px 0 5px", width:50, height:50}}/>
                                                <Tooltip title={"Votre identifiant"}>
                                                    <Person style={{width:35, height:35,color:"#73BDECFF"}}/>
                                                </Tooltip>
                                                {jwtDecoded.pseudo}</h2>
                                        </div>
                                    )}


                                    <div className={"flexCenter"} style={{width:'95%', justifyContent:"space-between"}}>
                                        <h2 style={{margin:0, fontWeight:100}}>x{this.state.boughtActions}</h2>
                                        <h2 style={{margin:0, fontWeight:100}}>Actions {this.state.nomEntreprise}</h2>
                                    </div>

                                    <div style={{width:"95%", height:2, margin:30, marginBottom:20,borderBottom:"1px dashed #969696",}}/>

                                </div>
                                {this.state.price && (
                                    <div className={"flexCenter"} style={{justifyContent:"flex-end", width:"100%"}}>
                                        <h2 style={{fontSize:34, margin:0}}>Total : {this.state.price.toFixed(2)}€</h2>
                                    </div>
                                )}
                                <div className={"flexCenter"} style={{marginTop:20, width:"100%", flexDirection:"column"}}>
                                    {this.state.statut === "success" ? (
                                        <button style={{width:"100%"}} className={"divButtonBuyResale"} onClick={()=>{this.props.navigate("/StockMarketSTO/MesActions")}}>Voir mes actions</button>
                                    ):(
                                        <>
                                            <p style={{fontWeight:100, textAlign:"justify", fontSize:15, marginBottom:0}}>Une erreur est survenue lors du paiement, vous n'avez pas été débité. Veuillez réessayer ultérieurement.</p>
                                            <p style={{fontWeight:100, textAlign:"justify", fontSize:15, marginTop:0}}>Si le problème persiste, contactez nous au (+33)6 08 82 67 53 ou  <a href="https://smsto.site/contact/" target={"_blank"}>par mail</a>.</p>
                                            <button style={{width:"100%"}} className={"divButtonBuyResale"} onClick={()=>{this.props.navigate("/StockMarketSTO/ListeLDF")}}>Voir les levées de fonds</button>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PaiementStatut)