import React, {Component} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {EventEmitter} from 'fbemitter';
import packageData from '../package.json';


//Administration
import AccueilAdmin from "./Administration/AccueilAdmin";
import CreationAdmin from "./Administration/CreationAdmin";
import ConnexionAdmin from "./Administration/ConnexionAdmin";
import Parametres from "./Administration/Parametres";
import Email from "./Administration/Email";
import ListeNodes from "./Administration/ListeNodes";
import OubliClefUnique from "./Administration/OubliClefUnique"
import RecupCodeClefUnique from "./Administration/RecupCodeClefUnique"
import CreationGestionnaire from "./Administration/CreationGestionnaire";
import ListeGestionnaires from "./Administration/ListeGestionnaires";
import RecuperationClefUnique from "./Administration/RecuperationClefUnique";
//Sécurité
import Registre from "./Administration/Registre";
import IntegriteDesRegistres from "./Administration/IntegriteDesRegistres";
import IntegriteDuCodeSource from "./Administration/IntegriteDuCodeSource";
//SmartContracts
import PropositionDePret from "./Administration/PropositionDePret";
import ListeDesPrets from "./Administration/ListeDesPrets";
//Fonctionnalites
import InvaliderMonCompte from "./Administration/InvaliderMonCompte"
//Tokens
import AchatToken from "./Administration/AchatToken";
import TransfertToken from "./Administration/TransfertToken";
import ConnaitreMonEmpreinte from "./Administration/ConnaitreMonEmpreinte";
import TransfertsEnAttente from "./Administration/TransfertsEnAttente";
import MesTokens from "./Administration/MesTokens";


//Application
//Ekoco
import BasCarboneBlockchain from "./Applications/ekoco/EkocoClassic/BasCarboneBlockchain";
import EkocoInscription from "./Applications/ekoco/EkocoClassic/EkocoInscription";
import EkocoDonnees from "./Applications/ekoco/EkocoClassic/EkocoDonnees";
import Statistiques from "./Applications/ekoco/EkocoClassic/Statistiques";
import GestionTokens from "./Applications/ekoco/EkocoClassic/GestionTokens";
import MonCompte from "./Applications/ekoco/EkocoClassic/MonCompte";
import ParametresEkoco from "./Applications/ekoco/EkocoClassic/Parametres";
import SyntheseEkoco from "./Applications/ekoco/EkocoClassic/Synthese";
import PolitiqueDeConfidentialite from "./Applications/ekoco/EkocoClassic/PolitiqueDeConfidentialite";
import ConditionsGeneralesDutilisation from "./Applications/ekoco/EkocoClassic/ConditionsGeneralesDutilisation"
//OctobreRose
import HomeOR from "./Applications/ekoco/OctobreRose/HomeOR"
import MesParcoursOR from "./Applications/ekoco/OctobreRose/MesParcoursOR"
import VisionneuseFichierOR from "./Applications/ekoco/OctobreRose/VisionneuseFichierOR"
import ConnexionOR from "./Applications/ekoco/OctobreRose/ConnexionOR"
import RedirectionQRCode from "./Applications/ekoco/OctobreRose/RedirectionQRCode"
import DonationOR from "./Applications/ekoco/OctobreRose/DonationOR"
import DeleteAccountOR from "./Applications/ekoco/OctobreRose/DeleteAccountOR";
import StatutPaiementOR from "./Applications/ekoco/OctobreRose/StatutPaiementOR";

//KageSecur
import AccueilKageSecur from "./Applications/KageSecur/AccueilKS";
import UploadFile from "./Applications/KageSecur/UploadFile";
import VerificationKageSecur from "./Applications/KageSecur/VerificationKS";
import TracabiliteKageSecur from "./Applications/KageSecur/TracabiliteKS";
import SignatureCollaborative from "./Applications/KageSecur/SignatureCollaborative";
import VoirMesDocuments from "./Applications/KageSecur/VoirMesDocuments";
import InscriptionClientKageSecur from "./Applications/KageSecur/InscriptionClientKS";
import InscriptionClientSekurEmail from "./Applications/KageSecur/InscriptionClientSekurEmail";
import InscriptionApprobateurKageSecur from "./Applications/KageSecur/InscriptionApprobateurKS";
import ListeDesClients from "./Applications/KageSecur/ListeDesClients";
import GestionDesApprobateurs from "./Applications/KageSecur/GestionDesApprobateurs";
import PreuveViaMail from "./Applications/KageSecur/PreuveViaMail";
import ListeZoneTemporaire from "./Applications/KageSecur/ListeZoneTemporaire";
import SecurEmail from "./Applications/KageSecur/SecurEmail";
import ConnexionExterne from "./Applications/KageSecur/ConnexionExterne";
import SekurEmailMessage from "./Applications/KageSecur/SekurEmailMessage";

//Suffrage
import AccueilSuffrage from "./Applications/suffrage/AccueilSuffrage";
import InscriptionClientSuffrage from "./Applications/suffrage/InscriptionClientSuffrage";
import GererInscriptionsClient from "./Applications/suffrage/GererInscriptionsClient";
import GererGroupesClient from "./Applications/suffrage/GererGroupesClient";
import GererVotes from "./Applications/suffrage/GererVotes";
import ListeVotesEnCoursClient from "./Applications/suffrage/ListeVotesEnCoursClient";
import ListeVotesRepondusClient from "./Applications/suffrage/ListeVotesRepondusClient";
import MesGroupesClient from "./Applications/suffrage/MesGroupesClient";

//BuyResale
import AccueilBR from "./Applications/buyResale/AccueilBR";
import InscriptionBR from "./Applications/buyResale/InscriptionBR";
import MesVideos from "./Applications/buyResale/MesVideos";
import Catalogue from "./Applications/buyResale/Catalogue";
import ModerationAdmin from "./Applications/buyResale/ModerationAdmin";
import Token from "./Applications/buyResale/Token";
import Profil from "./Applications/buyResale/Profil";
import PaiementStatutBR from "./Applications/buyResale/PaiementStatutBR";
import MentionsLegalesBR from "./Applications/buyResale/MentionsLegalesBR";

//StockMarketSTO
import AccueilSTO from "./Applications/stockMarketSTO/AccueilSTO";
import InscriptionSTO from "./Applications/stockMarketSTO/InscriptionSTO";
import MesActions from "./Applications/stockMarketSTO/MesActions";
import ListeLDF from "./Applications/stockMarketSTO/ListeLDF";
import ProfilSTO from "./Applications/stockMarketSTO/ProfilSTO";
import EntrepriseSTO from "./Applications/stockMarketSTO/EntrepriseSTO";
import ModerationSTO from "./Applications/stockMarketSTO/ModerationSTO";
import EchangeFichierSTO from "./Applications/stockMarketSTO/EchangeFichierSTO";
import PropositionDeVenteSTO from "./Applications/stockMarketSTO/PropositionDeVenteSTO";
import RegistreActionnaires from "./Applications/stockMarketSTO/RegistreActionnaires";
import CGUSTO from "./Applications/stockMarketSTO/CGUSTO";
import PDCSTO from "./Applications/stockMarketSTO/PDCSTO";
import FAQSTO from "./Applications/stockMarketSTO/FAQSTO";
import PaiementStatut from "./Applications/stockMarketSTO/PaiementStatut";
import RecapTransacSTO from "./Applications/stockMarketSTO/RecapTransacSTO";

//BtoBDesign
import AccueilBtoB from "./Applications/BtoBDesign/AccueilBtoB";
import InscriptionBTOB from "./Applications/BtoBDesign/InscriptionBTOB";
import ProfilBTOB from "./Applications/BtoBDesign/ProfilBTOB";
import CatalogueBTOB from "./Applications/BtoBDesign/CatalogueBTOB";
import MesConcepts from "./Applications/BtoBDesign/MesConcepts";
import Concept from "./Applications/BtoBDesign/Concept";
import ModerationAdminBTOB from "./Applications/BtoBDesign/ModerationAdminBTOB";
import PaiementStatutBTOB from "./Applications/BtoBDesign/PaiementStatutBTOB";
import MentionsLegalesBTOB from "./Applications/BtoBDesign/MentionsLegalesBTOB";

//Grape-NFT
import AccueilGNFT from "./Applications/Grape-NFT/AccueilGNFT";
import InscriptionGNFT from "./Applications/Grape-NFT/InscriptionGNFT";
import MyAccountGNFT from "./Applications/Grape-NFT/MyAccountGNFT";
import CatalogueGNFT from "./Applications/Grape-NFT/CatalogueGNFT";
import MyBottlesGNFT from "./Applications/Grape-NFT/MyBottlesGNFT";
import AddBottlesGNFT from "./Applications/Grape-NFT/AddBottlesGNFT";
import RecapTransacGNFT from "./Applications/Grape-NFT/RecapTransacGNFT";
import PaymentStatusGNFT from "./Applications/Grape-NFT/PaymentStatusGNFT";
import SalesProposalGNFT from "./Applications/Grape-NFT/SalesProposalGNFT";
import ContactGNFT from "./Applications/Grape-NFT/ContactGNFT";
import LegalNotices from "./Applications/Grape-NFT/LegalNotices";

//Grape-NFT OLD
import AccueilGNFTold from "./Applications/Grape-NFT-old/AccueilGNFT";
import InscriptionGNFTold from "./Applications/Grape-NFT-old/InscriptionGNFT";
import MyAccountGNFTold from "./Applications/Grape-NFT-old/MyAccountGNFT";
import CatalogueGNFTold from "./Applications/Grape-NFT-old/CatalogueGNFT";
import AddBottlesGNFTold from "./Applications/Grape-NFT-old/AddBottlesGNFT";
import PaymentStatusGNFTold from "./Applications/Grape-NFT-old/PaymentStatusGNFT";
import LegalNoticesold from "./Applications/Grape-NFT-old/LegalNotices";
import SharedHeaderGNFTold from "./Applications/Grape-NFT-old/components/SharedHeaderGNFT-old";

//Sekuroom
import AccueilSK from "./Applications/Sekuroom/AccueilSK";
import MesDossiersSK from "./Applications/Sekuroom/MesDossiersSK";
import InscriptionSK from "./Applications/Sekuroom/InscriptionSK";
import EspaceDossierSK from "./Applications/Sekuroom/EspaceDossierSK";
import InvitationDossierSK from "./Applications/Sekuroom/InvitationDossierSK";
import MentionsLegalesSK from "./Applications/Sekuroom/MentionsLegalesSK";

//Divers
import SharedDrawerAdmin from "./Administration/components/SharedDrawerAdmin";
import SharedDrawerEkoco from "./Applications/ekoco/EkocoClassic/components/SharedDrawerEkoco";
import SharedHeaderKageSecur from "./Applications/KageSecur/components/SharedHeaderKS";
import SharedHeaderSuffrage from "./Applications/suffrage/components/SharedHeaderSuffrage";
import SharedHeaderBuyResale from "./Applications/buyResale/components/SharedHeaderBR";
import SharedHeaderSTO from "./Applications/stockMarketSTO/components/SharedHeaderSTO";
import SharedHeaderBTOB from "./Applications/BtoBDesign/components/SharedHeaderBTOB";
import SharedHeaderGNFT from "./Applications/Grape-NFT/components/SharedHeaderGNFT";
import SharedHeaderSK from "./Applications/Sekuroom/components/SharedHeaderSK";

import Page404 from "./errorStatus/page404";
import Page403 from "./errorStatus/page403";
import Page503 from "./errorStatus/page503";
import axios from "axios";

import config from '../src/json/config.json';
import {withRouter} from "./shared/NavigationUtils";

const { date_version } = packageData;
const {baseUrl, suffix} = config;
const url = baseUrl + suffix;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: '',
    };
    global.emitter = new EventEmitter();
  }

  componentDidMount() {
    let cssblue = "color:#517591"
    let csslightblue = "color:#6589a3"
    let csswhite = "color:white"

    // console.log(
    //     "\n" +
    //     "\n" +
    //     "                                                  %c//\n" +
    //     "                                             *//%c((((((%c/*.\n" + //
    //     "                                        .,//%c((((((((((((((%c//,\n" + //
    //     "                                    .//%c(((((((((((((((((((((((%c//,.\n" + //
    //     "                                ,////%c(((((((((((((((((((((((((((((%c//\n" + //
    //     "                            //%c((((((((%c////%c(((((((((((((((((((%c///%c@#%c(/\n" + //
    //     "                       ,*/%c((((((((((((((((%c////%c(((((((((((%c////%c&@@@@%c(/\n" + //
    //     "                   ,//%c((((((((((((((((((((((((%c/////%c(%c////%c#&@@@@@@@@%c(/\n" +//
    //     "                 /////%c((((((((((((((((((((((((((%c////%c#@@@@@@@@@@@@@%c(/\n" + //
    //     "                 /////////%c((((((((((((((((((%c///(%c@@%c//%c&@@@@@@@@@@@@@%c(/\n" + //
    //     "                 /////////////%c((((((((((%c///(%c@@@@@@%c//%c&@@@@@@@@@@@@@%c(/\n" + //
    //     "                 //////////////////////%c%@@@@@@@@@@%c//%c&@@@@@@@@@@@@@%c(/\n" + //
    //     "                 //////////////////%c#@@@@@@@@@@@@@@%c//%c&@@@@@@@@@@%c(//,.\n" + //
    //     "                 //////////////////%c#@@@@@@@@@@@@@@%c//%c&@@@@@@%c//*,\n" + //
    //     "                 //////////////////%c#@@@@@@@@@@@@@@%c//%c&&#%c//*.\n" + //
    //     "                 //////////////////%c#@@@@@@@@@@@@@@%c///*\n" + //
    //     "             ,*/%c(((%c////////////////%c#@@@@@@@@@#%c/////\n" +
    //     "         ,//%c(((((((((((%c////////////%c#@@@@&%c(////%c#&@@%c/\n" +
    //     "    ,*/%c((((((((((((((((((((%c////////%c#@%c(////%c%@@@@@@@%c/\n" +
    //     ".//%c(((((((((((((((((((((((((((((%c/////(%c&@@@@@@@@@@@%c/\n" +
    //     "///////%c((((((((((((((((((((((%c//////%c#@@@@@@@@@@@@@@%c/\n" +
    //     "///////////%c((((((((((((((%c////%c%@@&%c//%c#@@@@@@@@@@@@@@%c/\n" +
    //     "///////////////%c((((((%c///(%c&@@@@@@&%c//%c#@@@@@@@@@@@@@@%c/\n" +
    //     "////////////////////%c#@@@@@@@@@@@&%c//%c#@@@@@@@@@@@@#%c//\n" +
    //     "//////////////////(%c@@@@@@@@@@@@@&%c//%c#@@@@@@@&#%c//*.\n" +
    //     "//////////////////(%c@@@@@@@@@@@@@&%c//%c#@@@&%c///*\n" +
    //     "//////////////////(%c@@@@@@@@@@@@@&%c//////\n" +
    //     "//////////////////(%c@@@@@@@@@@@&%c(//*.\n" +
    //     "    ,/////////////(%c@@@@@@@@%c(//,\n" +
    //     "        ,*////////(%c@@&#%c//*.\n" +
    //     "             *///////*\n" +
    //     "\n" +
    //     "\n%cV."+date_version
    //     ,cssblue,csslightblue,cssblue,csslightblue,cssblue,csslightblue,cssblue,csslightblue,cssblue
    //     ,csslightblue,cssblue,csslightblue,cssblue,csswhite,cssblue,csslightblue,cssblue,csslightblue
    //     ,cssblue,csswhite,cssblue,csslightblue,cssblue,csslightblue,cssblue,csswhite,cssblue,csslightblue
    //     ,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite,cssblue,csswhite,cssblue,csslightblue,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csslightblue
    //     ,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite,cssblue,csswhite,cssblue,csslightblue,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite
    //     ,cssblue,csslightblue,cssblue,csswhite,cssblue,csswhite,cssblue,csslightblue,cssblue,csswhite,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue
    //     ,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,csswhite,cssblue,"color:initial"
    // )

    // let css1 = "color:#547790"
    // let css2 = "color:#F3B02AFF"
    //
    // console.log(
    //     "%c███████╗██████╗ ██╗   ██╗██████╗  ██████╗                                     \n" +
    //     "██╔════╝██╔══██╗██║   ██║██╔══██╗██╔═══██╗                                    \n" +
    //     "███████╗██████╔╝██║   ██║██████╔╝██║   ██║                                    \n" +
    //     "╚════██║██╔═══╝ ██║   ██║██╔══██╗██║   ██║                                    \n" +
    //     "███████║██║     ╚██████╔╝██║  ██║╚██████╔╝                                    \n" +
    //     "╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝ ╚═════╝                                     \n" +
    //     "                                                                              \n" +
    //     "%c██████╗ ██╗      ██████╗  ██████╗██╗  ██╗ ██████╗██╗  ██╗ █████╗ ██╗███╗   ██╗\n" +
    //     "██╔══██╗██║     ██╔═══██╗██╔════╝██║ ██╔╝██╔════╝██║  ██║██╔══██╗██║████╗  ██║\n" +
    //     "██████╔╝██║     ██║   ██║██║     █████╔╝ ██║     ███████║███████║██║██╔██╗ ██║\n" +
    //     "██╔══██╗██║     ██║   ██║██║     ██╔═██╗ ██║     ██╔══██║██╔══██║██║██║╚██╗██║\n" +
    //     "██████╔╝███████╗╚██████╔╝╚██████╗██║  ██╗╚██████╗██║  ██║██║  ██║██║██║ ╚████║\n" +
    //     "╚═════╝ ╚══════╝ ╚═════╝  ╚═════╝╚═╝  ╚═╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝\n" +
    //     "                                                                              \n" +
    //     "██████╗ ██╗      █████╗ ████████╗███████╗ ██████╗ ██████╗ ███╗   ███╗         \n" +
    //     "██╔══██╗██║     ██╔══██╗╚══██╔══╝██╔════╝██╔═══██╗██╔══██╗████╗ ████║         \n" +
    //     "██████╔╝██║     ███████║   ██║   █████╗  ██║   ██║██████╔╝██╔████╔██║         \n" +
    //     "██╔═══╝ ██║     ██╔══██║   ██║   ██╔══╝  ██║   ██║██╔══██╗██║╚██╔╝██║         \n" +
    //     "██║     ███████╗██║  ██║   ██║   ██║     ╚██████╔╝██║  ██║██║ ╚═╝ ██║         \n" +
    //     "╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚═╝      ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝         \n"  +
    //     "                                                                               ",css1,css2)



    axios.interceptors.request.use(function (config) {
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

// Add a response interceptor
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      console.log(error)
      if(error.response){
        if(error.response.status === 404){
          // alert("ERREUR 404 ! Veuillez rafraîchir la page")
          window.location = "/page404"
        }else if(error.response.status === 503){
          window.location = "/page503"
        }else if(error.response.status === 403){
          window.location = "/page403"
          // alert("ERREUR 403 ! Veuillez rafraîchir la page")
        }
      }
      return Promise.reject(error);
    });
    let currentPath = this.props.location.pathname.toLowerCase()
    this.setState({currentPath})
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    let currentPath = this.props.location.pathname.toLowerCase()
    if(this.state.currentPath !== currentPath){
      this.setState({currentPath})
    }
  }

  componentDidCatch(error, errorInfo) {
    axios.post(url+"frontErrors", {error:error.stack, pathname:window.location.pathname})
  }

  render() {
    return (
        <>
          {this.state.currentPath.includes("/administration") && (
              <SharedDrawerAdmin/>
          )}
          {(this.state.currentPath.includes("/ekoco") && !this.state.currentPath.includes("/octobrerose"))&& (
              <SharedDrawerEkoco/>
          )}
          {this.state.currentPath.includes("/kagesecur") && (
              <SharedHeaderKageSecur/>
          )}
          {this.state.currentPath.includes("/suffrage") && (
              <SharedHeaderSuffrage/>
          )}
          {this.state.currentPath.includes("/buyresale") && (
              <SharedHeaderBuyResale/>
          )}
          {this.state.currentPath.includes("/stockmarketsto") && (
              <SharedHeaderSTO/>
          )}
          {this.state.currentPath.includes("/btobdesign") && (
              <SharedHeaderBTOB/>
          )}
          {this.state.currentPath.includes("/grapenft") && (
              <SharedHeaderGNFT/>
          )}
          {this.state.currentPath.includes("/grape-nft") && (
              <SharedHeaderGNFTold/>
          )}
          {this.state.currentPath.includes("/sekuroom") && (
              <SharedHeaderSK/>
          )}

          <Routes>
            {/*Administration*/}
            <Route path="/Administration/Parametres" element={<Parametres />} />
            <Route path="/Administration/ListeDesNodes" element={<ListeNodes />} />
            <Route path="/Administration/Email" element={<Email />} />
            <Route path="/Administration/OubliClefUnique" element={<OubliClefUnique />} />
            <Route path="/Administration/CreationGestionnaire" element={<CreationGestionnaire />} />
            <Route path="/Administration/ListeGestionnaires" element={<ListeGestionnaires />} />
            <Route path="/Administration/AccueilAdmin" element={<AccueilAdmin />} />
            <Route path="/Administration/ConnexionAdmin" element={<ConnexionAdmin />} />
            <Route path="/Administration/CreationAdmin" element={<CreationAdmin />} />
            <Route path="/Administration/RecupCodeClefUnique" element={<RecupCodeClefUnique />} />
            <Route path="/Administration/RecuperationClefUnique" element={<RecuperationClefUnique />} />
            {/*Sécurité*/}
            <Route path="/Administration/Registre" element={<Registre/>}/>
            <Route path="/Administration/IntegriteDesRegistres" element={<IntegriteDesRegistres/>}/>
            <Route path="/Administration/IntegriteDuCodeSource" element={<IntegriteDuCodeSource/>}/>
            {/*SmartContracts*/}
            {/*sc_loan*/}
            <Route path="/Administration/sc_loan/ListeDesPrets" element={<ListeDesPrets/>}/>
            <Route path="/Administration/sc_loan/PropositionDePret" element={<PropositionDePret/>}/>
            {/*Fonctionnalites*/}
            <Route path="/Administration/InvaliderMonCompte" element={<InvaliderMonCompte/>}/>

            {/*Gestion des tokens*/}
            <Route path="/Administration/AchatToken" element={<AchatToken/>}/>
            <Route path="/Administration/TransfertToken" element={<TransfertToken/>}/>
            <Route path="/Administration/ConnaitreMonEmpreinte" element={<ConnaitreMonEmpreinte/>}/>
            <Route path="/Administration/TransfertsEnAttente" element={<TransfertsEnAttente/>}/>
            <Route path="/Administration/MesTokens" element={<MesTokens/>}/>

            {/*Applications*/}
            {/*Suffrage*/}
            <Route path="/Suffrage/Accueil" element={<AccueilSuffrage/>}/>
            <Route path="/Suffrage/InscriptionVotant" element={<InscriptionClientSuffrage/>}/>
            <Route path="/Suffrage/GererInscriptions" element={<GererInscriptionsClient/>}/>
            <Route path="/Suffrage/GererGroupes" element={<GererGroupesClient/>}/>
            <Route path="/Suffrage/GererVotes" element={<GererVotes/>}/>
            <Route path="/Suffrage/ListeVotesEnCours" element={<ListeVotesEnCoursClient/>}/>
            <Route path="/Suffrage/ListeVotesRepondus" element={<ListeVotesRepondusClient/>}/>
            <Route path="/Suffrage/MesGroupes" element={<MesGroupesClient/>}/>
            {/*SMSTO*/}
            <Route path="/StockMarketSTO/Accueil" element={<AccueilSTO/>}/>
            <Route path="/StockMarketSTO/Inscription" element={<InscriptionSTO/>}/>
            <Route path="/StockMarketSTO/MesActions" element={<MesActions/>}/>
            <Route path="/StockMarketSTO/ListeLDF" element={<ListeLDF/>}/>
            <Route path="/StockMarketSTO/Profil" element={<ProfilSTO/>}/>
            <Route path="/StockMarketSTO/Entreprise" element={<EntrepriseSTO/>}/>
            <Route path="/StockMarketSTO/Moderation" element={<ModerationSTO/>}/>
            <Route path="/StockMarketSTO/EchangeFichier" element={<EchangeFichierSTO/>}/>
            <Route path="/StockMarketSTO/PropositionDeVente" element={<PropositionDeVenteSTO/>}/>
            <Route path="/StockMarketSTO/RegistreActionnaires" element={<RegistreActionnaires/>}/>
            <Route path="/StockMarketSTO/CGUSTO" element={<CGUSTO/>}/>
            <Route path="/StockMarketSTO/PDCSTO" element={<PDCSTO/>}/>
            <Route path="/StockMarketSTO/FAQ" element={<FAQSTO/>}/>
            <Route path="/StockMarketSTO/PaiementStatut" element={<PaiementStatut/>}/>
            <Route path="/StockMarketSTO/RecapTransac" element={<RecapTransacSTO/>}/>

            {/*BtoBDesign*/}
            <Route path="/BtoBDesign/Accueil" element={<AccueilBtoB/>}/>
            <Route path="/BtoBDesign/Inscription" element={<InscriptionBTOB/>}/>
            <Route path="/BtoBDesign/Profil" element={<ProfilBTOB/>}/>
            <Route path="/BtoBDesign/Catalogue" element={<CatalogueBTOB/>}/>
            <Route path="/BtoBDesign/MesConcepts" element={<MesConcepts/>}/>
            <Route path="/BtoBDesign/Concept" element={<Concept/>}/>
            <Route path="/BtoBDesign/ModerationAdmin" element={<ModerationAdminBTOB/>}/>
            <Route path="/BtoBDesign/PaiementStatut" element={<PaiementStatutBTOB/>}/>
            <Route path="/BtoBDesign/MentionsLegales" element={<MentionsLegalesBTOB/>}/>
            {/*BuyResale*/}
            <Route path="/BuyResale/Accueil" element={<AccueilBR/>}/>
            <Route path="/BuyResale/Inscription" element={<InscriptionBR/>}/>
            <Route path="/BuyResale/MesVideos" element={<MesVideos/>}/>
            <Route path="/BuyResale/Catalogue" element={<Catalogue/>}/>
            <Route path="/BuyResale/ModerationAdmin" element={<ModerationAdmin/>}/>
            <Route path="/BuyResale/Token" element={<Token/>}/>
            <Route path="/BuyResale/Profil" element={<Profil/>}/>
            <Route path="/BuyResale/PaiementStatut" element={<PaiementStatutBR/>}/>
            <Route path="/BuyResale/MentionsLegales" element={<MentionsLegalesBR/>}/>
            {/*Ekoco*/}
            <Route path="/Ekoco/BasCarboneBlockchain" element={<BasCarboneBlockchain/>}/>
            <Route path="/Ekoco/Inscription" element={<EkocoInscription/>}/>
            <Route path="/Ekoco/Donnees" element={<EkocoDonnees/>}/>
            <Route path="/Ekoco/Statistiques" element={<Statistiques/>}/>
            <Route path="/Ekoco/GestionTokens" element={<GestionTokens/>}/>
            <Route path="/Ekoco/MonCompte" element={<MonCompte/>}/>
            <Route path="/Ekoco/Parametres" element={<ParametresEkoco/>}/>
            <Route path="/Ekoco/Synthese" element={<SyntheseEkoco/>}/>
            <Route path="/Ekoco/PolitiqueDeConfidentialite" element={<PolitiqueDeConfidentialite/>}/>
            <Route path="/Ekoco/ConditionsGeneralesDutilisation" element={<ConditionsGeneralesDutilisation/>}/>
            {/*OctobreRose*/}
            <Route path="/Ekoco/OctobreRose/Home" element={<HomeOR/>}/>
            <Route path="/Ekoco/OctobreRose/MesParcours" element={<MesParcoursOR/>}/>
            <Route path="/Ekoco/OctobreRose/VisionneuseFichier" element={<VisionneuseFichierOR/>}/>
            <Route path="/Ekoco/OctobreRose/Connexion" element={<ConnexionOR/>}/>
            <Route path="/Ekoco/OctobreRose/RedirectionQRCode" element={<RedirectionQRCode/>}/>
            <Route path="/Ekoco/OctobreRose/Donation" element={<DonationOR/>}/>
            <Route path="/Ekoco/OctobreRose/DeleteAccount" element={<DeleteAccountOR/>}/>
            <Route path="/Ekoco/OctobreRose/StatutPaiement" element={<StatutPaiementOR/>}/>

            {/*KageSecur*/}
            <Route path="/KageSecur/Accueil" element={<AccueilKageSecur/>}/>
            <Route path="/KageSecur/SansValidation" element={<UploadFile signature={false}/>}/>
            <Route path="/KageSecur/AvecValidation" element={<UploadFile signature={true}/>}/>
            <Route path="/KageSecur/Verification" element={<VerificationKageSecur/>}/>
            <Route path="/KageSecur/Tracabilite" element={<TracabiliteKageSecur/>}/>
            <Route path="/KageSecur/SignatureCollaborative" element={<SignatureCollaborative/>}/>
            <Route path="/KageSecur/TableauDeBord" element={<VoirMesDocuments/>}/>
            <Route path="/KageSecur/ListeDesClients" element={<ListeDesClients/>}/>
            <Route path="/KageSecur/InscriptionClientKageSecur" element={<InscriptionClientKageSecur/>}/>
            <Route path="/KageSecur/InscriptionClientSekurEmail" element={<InscriptionClientSekurEmail/>}/>
            <Route path="/KageSecur/InscriptionApprobateurKageSecur" element={<InscriptionApprobateurKageSecur/>}/>
            <Route path="/KageSecur/GestionDesApprobateurs" element={<GestionDesApprobateurs/>}/>
            <Route path="/KageSecur/PreuveViaMail" element={<PreuveViaMail/>}/>
            <Route path="/KageSecur/ListeZoneTemporaire" element={<ListeZoneTemporaire/>}/>
            <Route path="/KageSecur/SekurEmail" element={<SecurEmail/>}/>
            <Route path="/KageSecur/ConnexionExterne" element={<ConnexionExterne/>}/>
            <Route path="/KageSecur/SekurEmailMessage" element={<SekurEmailMessage/>}/>
            {/*Grape-NFT*/}
            <Route path="/GrapeNFT/Home" element={<AccueilGNFT/>}/>
            <Route path="/GrapeNFT/SignUp" element={<InscriptionGNFT/>}/>
            <Route path="/GrapeNFT/Catalog" element={<CatalogueGNFT/>}/>
            <Route path="/GrapeNFT/MyBottles" element={<MyBottlesGNFT/>}/>
            <Route path="/GrapeNFT/Admin/AddBottles" element={<AddBottlesGNFT/>}/>
            <Route path="/GrapeNFT/Admin/RecapTransac" element={<RecapTransacGNFT/>}/>
            <Route path="/GrapeNFT/MyAccount" element={<MyAccountGNFT/>}/>
            <Route path="/GrapeNFT/PaymentStatus" element={<PaymentStatusGNFT/>}/>
            <Route path="/GrapeNFT/SalesProposal" element={<SalesProposalGNFT/>}/>
            <Route path="/GrapeNFT/Contact" element={<ContactGNFT/>}/>
            <Route path="/GrapeNFT/LegalNotices" element={<LegalNotices/>}/>
            {/*Grape-NFT OLD*/}
            <Route path="/Grape-NFT/Home" element={<AccueilGNFTold/>}/>
            <Route path="/Grape-NFT/SignUp" element={<InscriptionGNFTold/>}/>
            <Route path="/Grape-NFT/Catalog" element={<CatalogueGNFTold/>}/>
            <Route path="/Grape-NFT/Admin/AddBottles" element={<AddBottlesGNFTold/>}/>
            <Route path="/Grape-NFT/MyAccount" element={<MyAccountGNFTold/>}/>
            <Route path="/Grape-NFT/PaymentStatus" element={<PaymentStatusGNFTold/>}/>
            <Route path="/Grape-NFT/LegalNotices" element={<LegalNoticesold/>}/>
            {/*Grape-NFT*/}
            <Route path="/Sekuroom/Accueil" element={<AccueilSK/>}/>
            <Route path="/Sekuroom/MesDossiers" element={<MesDossiersSK/>}/>
            <Route path="/Sekuroom/Inscription" element={<InscriptionSK/>}/>
            <Route path="/Sekuroom/EspaceDossier" element={<EspaceDossierSK/>}/>
            <Route path="/Sekuroom/InvitationDossier" element={<InvitationDossierSK/>}/>
            <Route path="/Sekuroom/MentionsLegales" element={<MentionsLegalesSK/>}/>

            {/*SmartContracts*/}
            {/*Divers*/}
            <Route path="/page404" element={<Page404/>}/>
            <Route path="/page403" element={<Page403/>}/>
            <Route path="/page503" element={<Page503/>}/>

            {/* Redirect route */}
            <Route path="/Ekoco/OctobreRose" element={<Navigate replace to="/Ekoco/OctobreRose/Home" />} />
            <Route path="/Ekoco" element={<Navigate replace to="/Ekoco/BasCarboneBlockchain" />} />
            <Route path="/Fonctionnalites/GestionDePreuve" element={<Navigate replace to="/Fonctionnalites/GestionDePreuve" />} />
            <Route path="/Fonctionnalites/GestionDesTokens" element={<Navigate replace to="/Fonctionnalites/GestionDesTokens/AchatToken" />} />
            <Route path="/Administration" element={<Navigate replace to="/Administration/AccueilAdmin" />} />
            <Route path="/KageSecur" element={<Navigate replace to="/KageSecur/Accueil" />} />
            <Route path="/Suffrage" element={<Navigate replace to="/Suffrage/Accueil" />} />
            <Route path="/BuyResale" element={<Navigate replace to="/BuyResale/Accueil" />} />
            <Route path="/StockMarketSTO" element={<Navigate replace to="/StockMarketSTO/Accueil" />} />
            <Route path="/BtoBDesign" element={<Navigate replace to="/BtoBDesign/Accueil" />} />
            <Route path="/Grape-NFT" element={<Navigate replace to="/Grape-NFT/Home" />} />
            <Route path="/GrapeNFT" element={<Navigate replace to="/GrapeNFT/Home" />} />
            <Route path="/Sekuroom" element={<Navigate replace to="/Sekuroom/Accueil" />} />

            {/* Default redirect */}
            <Route path="*" element={<Navigate to="/Administration/AccueilAdmin"/>}/>
          </Routes>
        </>
    );
  }
}
export default withRouter(App)
