import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import {isMobile} from "react-device-detect";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let socketIO //Initialisation de l'instance du socket

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

function animateLetterOut(cw, i) {
    setTimeout(function() {
        try{  cw[i].className = 'letter out'}
        catch(e){}
    }, i*80);
}

function animateLetterIn(nw, i) {
    setTimeout(function() {
        try{nw[i].className = 'letter in'}
        catch(e){}
    }, 340+(i*80));
}

// let acceptedFiles

class AccueilKageSecur extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phaseCSS:0,
            acceptedFile:[],
            displayHiddenTests:false,
            showBrowserView:true,
        };
    }

    words;
    wordArray;
    currentWord;

    _interval = null;

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }
    componentWillUnmount(){
        window.removeEventListener('resize', this.resizePage)
        if(this._interval){
            clearInterval(this._interval)
        }
    }

    splitLetters(word) {
        // console.log("splitLetters", word)
        let content = word.innerText.split('\n').join('');
        word.innerText = '';
        let letters = [];
        for (let i = 0; i < content.length; i++) {
            let letter = document.createElement('span');
            letter.key = "letter-" + i;
            letter.className = 'letter';
            letter.innerText = content.charAt(i);
            word.appendChild(letter);
            letters.push(letter);
        }
        this.wordArray.push(letters);
    }

    changeWord() {
        let cw = this.wordArray[this.currentWord];
        let nw = this.currentWord === this.words.length-1 ? this.wordArray[0] : this.wordArray[this.currentWord+1];
        for (let i = 0; i < cw.length; i++) {
            animateLetterOut(cw, i);
        }

        for (let i = 0; i < nw.length; i++) {
            nw[i].className = 'letter behind';
            nw[0].parentElement.style.opacity = 1;
            animateLetterIn(nw, i);
        }

        this.currentWord = (this.currentWord === this.wordArray.length-1) ? 0 : this.currentWord+1;
    }
    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        },()=>{
            Axios.post(url+"verifyJwt ",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                if (response.data.resultat === true){
                    //On décode le jwt pour connaitre les infos utiles
                    let jwtDecoded = jwt_decode(this.state.jwt)
                    // socketIO = socketInstance.connectionSocket(this.state.jwt)
                    global.emitter.emit("onRouteChangedKageSecur",{
                        user:jwtDecoded.gestionnaire ? "gestionnaire":"client",
                        pseudo:jwtDecoded.pseudo
                    })
                    // this.setState({isGestionnaire:jwtDecoded.gestionnaire})
                    this.setState({isGestionnaire:jwtDecoded.gestionnaire})
                }
            })

        })
        this.words = document.getElementsByClassName('word');
        /*get value of span*/

        this.wordArray = [];
        this.currentWord = 0;

        this.words[this.currentWord].style.opacity = 1;
        for (var i = 0; i < this.words.length; i++) {
            this.splitLetters(this.words[i]);
        }

        this._interval = setInterval(()=>{
            this.changeWord()
        }, 6000);
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    // sendAPIDirectAct(){
    //     console.log(this.state.binary)
    //     Axios.post(url+"writeProofDirectAct",{
    //         empreinte:this.state.hash,
    //         hashJwt:this.state.hash,
    //         allDatas:[{
    //             hash: this.state.hashFile,
    //             fileName: this.state.fileName,
    //             bufferFile: this.state.binary
    //         }],
    //     },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
    //         // if(response.data.jwt){
    //         //     sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
    //         //     this.setState({
    //         //         jwt:response.data.jwt
    //         //     })
    //         // }
    //         console.log(response.data)
    //     })
    // }

    sendToBlockchain(){
        Axios.post(url + "getCustomerServer", {},
            {headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
            let acceptedFiles = this.state.acceptedFile.slice()
            acceptedFiles.forEach((file) => {
                this.setState({["progressUpload"+file.name]:0, ["fileSent"+file.name]:"inProgress", ["cancel"+file.name]:false}, ()=>{
                    socketIO.emit("askPermToUploadMasterReport", {fileName:file.name,bearer:this.state.jwt}, (response) => {
                        // if(response.jwt){
                        //     sessionStorage.setItem("KAGESECUR_jwt",response.jwt)
                        //     this.setState({
                        //         jwt:response.jwt
                        //     },()=>{
                        //         jwtDecoded = jwt_decode(this.state.jwt)
                        //     })
                        // }
                        if(response.isAuthorized){
                            let dataFile = {
                                codeUpload:response.codeUpload,
                                empreinte:this.state.hash,
                                fileName: file.name,
                                node:baseUrl.split("/")[2],
                                savedPath:response.codeUpload,
                                idToken:response.idToken
                            }
                            let stream = file.stream()
                            let re = stream.getReader()
                            let SHA = CryptoJS.algo.SHA256.create()
                            let self = this
                            let numberOfPacketSent = 0, estimatedTime, packetTimesDuration =0, totalSizeAlreadySent = 0
                            new ReadableStream({
                                start(controller) {
                                    function push() {
                                        const packetStartTime = Date.now()
                                        re.read().then( ({done, value}) => {
                                            numberOfPacketSent++
                                            if (done) {
                                                socketIO.emit("sendChunkMasterReport##"+response.codeUpload, {status:"ended", bearer:self.state.jwt}, () => {
                                                    // if(response.jwt){
                                                    //     sessionStorage.setItem("KAGESECUR_jwt",response.jwt)
                                                    //     this.setState({
                                                    //         jwt:response.jwt
                                                    //     },()=>{
                                                    //         jwtDecoded = jwt_decode(this.state.jwt)
                                                    //     })
                                                    // }
                                                    dataFile.hash = SHA.finalize().toString()
                                                    let acceptedFileClone = self.state.acceptedFile.slice();
                                                    let data = {
                                                        allDatas: [dataFile],
                                                        signatairesList: [],
                                                        checkedExterne:self.state.checkedExterne,
                                                        checkedVisible:self.state.checkedVisible,
                                                        empreinte:self.state.hash,
                                                        hashJwt:self.state.hash,
                                                    }
                                                    self.setState({
                                                        acceptedFile:acceptedFileClone,
                                                        ["dropzoneProgress"+file.name]:100,
                                                        ["dropZoneEstimatedTime"+file.name]:0,
                                                        processusDone:true
                                                    })
                                                    controller.close();
                                                })
                                            }else{
                                                socketIO.emit("sendChunkMasterReport##"+response.codeUpload, {buffer:value, status:"upload", bearer:self.state.jwt}, (responseSC) => {
                                                    // if(response.jwt){
                                                    //     sessionStorage.setItem("KAGESECUR_jwt",response.jwt)
                                                    //     this.setState({
                                                    //         jwt:response.jwt
                                                    //     },()=>{
                                                    //         jwtDecoded = jwt_decode(this.state.jwt)
                                                    //     })
                                                    // }
                                                    if(responseSC.isAuthorized){
                                                        totalSizeAlreadySent = totalSizeAlreadySent+value.length
                                                        let wordBuffer = CryptoJS.lib.WordArray.create(value);
                                                        SHA.update(wordBuffer);
                                                        const packetTime = (Date.now()-packetStartTime)/1000
                                                        packetTimesDuration = (value.length/packetTime) + packetTimesDuration
                                                        const debitMoyen = packetTimesDuration/(numberOfPacketSent+1)
                                                        estimatedTime = parseInt((file.size-totalSizeAlreadySent)/debitMoyen)
                                                        const progressUploadEnCours = parseInt(totalSizeAlreadySent * 100 / file.size)
                                                        if(estimatedTime !== self.state["uploadEstimatedTime"+file.name]){
                                                            self.setState({
                                                                ["uploadEstimatedTime"+file.name]: estimatedTime
                                                            })
                                                        }
                                                        if(progressUploadEnCours !== self.state["progressUpload"+file.name]){
                                                            self.setState({
                                                                ["progressUpload"+file.name]:progressUploadEnCours
                                                            })
                                                        }
                                                        setTimeout(()=>{
                                                            if (!self.state["cancel"+file.name] && !self.state.cancelUpload){
                                                                push();
                                                            }else{
                                                                socketIO.emit("sendChunk##"+response.codeUpload, {status:"cancel",bearer:self.state.jwt}, () => {
                                                                    // if(response.jwt){
                                                                    //     sessionStorage.setItem("KAGESECUR_jwt",response.jwt)
                                                                    //     this.setState({
                                                                    //         jwt:response.jwt
                                                                    //     },()=>{
                                                                    //         jwtDecoded = jwt_decode(this.state.jwt)
                                                                    //     })
                                                                    // }
                                                                    Toast.fire({
                                                                        icon: 'info',
                                                                        title: 'Upload annulé !'
                                                                    })
                                                                })
                                                            }
                                                        },value.length/1000000)
                                                    }else{
                                                        Swal.fire({
                                                            title: 'Erreur!',
                                                            text: "Vous n'êtes pas autorisé à envoyer un fichier.",
                                                            icon: 'error',
                                                            confirmButtonText: 'Ok'
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                    }
                                    push();
                                }
                            });
                        }
                    })
                })
            })
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100vw", overflow:"hidden", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div style={{display:"flex",flex:1, justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                        <div style={{display:"flex",userSelect: "none", justifyContent:"center", alignItems:"center"}}>
                            <img style={{textAlign:"center", width:this.state.showBrowserView ?150:"12vw",marginRight:this.state.showBrowserView ?20:"1vw", marginTop:this.state.showBrowserView?110:"3vh"}} src={require("../../assets/PICTO_KAGESECUR_RVB.png")} alt="logoKageSecure"/>
                            <div style={{display:"flex",flexDirection:"column"}}>
                                <p style={{fontSize:this.state.showBrowserView ? 82:"7vw",margin:0,marginTop:this.state.showBrowserView ?60:"2vw", fontWeight:"1000",fontFamily:"arial",lineHeight:this.state.showBrowserView ? "75px":"5vw"}}><span style={{color:"#64a7db"}}>KAGE</span><span onDoubleClick={()=>{this.setState({displayHiddenTests:!this.state.displayHiddenTests})}} style={{color:"#F19028"}}>DOC</span></p>
                                {/*<p style={{display:"block",fontSize:50,margin:0,marginLeft:4, fontWeight:"bold",fontFamily:"arialLight"}}>La chaîne de la confiance</p>*/}
                                <p style={{display:"block",whiteSpace:"pre-wrap",fontSize:this.state.showBrowserView ? 50:"4.5vw",margin:0,marginLeft:4, fontWeight:"bold",fontFamily:"arialLight"}} className={"animated-title-wrapper"}>
                                    <span className="word">La preuve et la sécurité</span>
                                    <span className="word">Un espace à valeur probatoire</span>
                                    <span className="word">Utilise la blockchain Kagesecur</span>
                                </p>
                            </div>
                        </div>

                    </div>
                    <div style={{display:"flex",flex:0, justifyContent:"center", alignItems:"center", flexDirection:"column"}}>

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AccueilKageSecur)