import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {Document, Page, pdfjs} from 'react-pdf';
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const columns = [
    { id: 'prenom', label: 'Prénom', minWidth: "10%" },
    { id: 'nom', label: 'Nom', minWidth: "10%" },
    { id: 'adresse', label: 'Adresse', minWidth: "40%" },
    { id: 'DDN', label: 'Date de naissance', minWidth: "40%" },
];

class GererInscriptionClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allPC:[],
            openDialogDetail:false,
            clientDetail:{},
            bufferCNI:null,
            bufferJDD:null,
            scaleJDD:1,
            scaleCNI:1,
            scaleVI:1,
            VIname:"",
            CNIname:"",
            JDDname:"",
            pdfPage:1,
            showHUDPDF:false
        };
    }

    componentDidMount() {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`; //On dépend d'un truc en ligne ..
        this.setState({
            hash: sessionStorage.getItem("SUFFRAGE_hash"),
            pseudo: sessionStorage.getItem("SUFFRAGE_pseudo"),
            jwt: sessionStorage.getItem("SUFFRAGE_jwt")
        },  () => {
            this.checkConnexion()
        })
    }

    checkConnexion(){
        Axios.post(url+"connexionSuffrage",{
            empreinte:this.state.hash,
            hashJwt:this.state.hash
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "Connecté"){
                global.emitter.emit("onRouteChangedSuffrage",{
                    user:response.data.gestionnaire ? "gestionnaire":"client",
                    pseudo:sessionStorage.getItem("SUFFRAGE_pseudo")
                })
                this.getAllPC()
            }else{
                Toast.fire({
                    title: 'Utilisateur introuvable.',
                    icon: 'error',
                })
                window.location.replace("/Suffrage/Accueil")
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    getAllPC(){
        Axios.post(url + "getInscriptionSuffrage", {
            empreinte: sessionStorage.getItem("SUFFRAGE_hash"),
            hashJwt: sessionStorage.getItem("SUFFRAGE_hash")
        }, {headers: {authorization: "Bearer " + this.state.jwt}}).then(response => {
            if (response.data.resultat === 'done') {
                this.setState({allPC: response.data.allPC})
            } else {
                Toast.fire({
                    title: "Vous n'avez aucune inscription à valider.",
                    icon: 'info',
                })
            }
        }).catch(error => {
            console.log(error)
        });
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    getFilesIncription(empreinteC){
        Axios.post(url+"getFilesIncription", {
            empreinteG:this.state.hash,
            empreinteC:empreinteC,
            hashJwt:this.state.hash
        }, {headers:{authorization:"Bearer "+this.state.jwt}}).then(response => {
            if (response.data.resultat === "done"){
                this.setState({
                    bufferCNI:response.data.bufferCNI||"",
                    bufferJDD:response.data.bufferJDD||"",
                    bufferVI:response.data.bufferVI||"",
                    CNIname:response.data.CNIname||"",
                    JDDname:response.data.JDDname||"",
                    VIname:response.data.VIname||"",
                })
            }else{
                Toast.fire({
                    title: response.data.resultat,
                    icon: 'warning',
                })
            }
        })
    }

    acceptOrNotInscription(empreinteC, accept){
        Axios.post(url+"inscriptionProcessSuffrage", {
            empreinteAdmin:this.state.hash,
            empreinte:empreinteC,
            accept:accept,
            hashJwt:this.state.hash
        }, {headers:{authorization:"Bearer "+this.state.jwt}}).then(response => {
            if (response.data.resultat === "done"){
                let allPCTemp = this.state.allPC.slice()
                allPCTemp.forEach((elt,index) => {
                    if (elt.empreinte === empreinteC){
                        allPCTemp.splice(index,1)
                    }
                })
                this.setState({allPC:allPCTemp, openDialogDetail:false,bufferCNI:null,bufferJDD:null})
                Toast.fire({
                    title: accept ? "Inscription validée." : "Inscription refusée.",
                    icon: 'success',
                })
            }else{
                if(response.data.resultat === "Cette inscription a déjà été traitée"){
                    let allPCTemp = this.state.allPC.slice()
                    allPCTemp.splice(allPCTemp.findIndex(elt=> elt.empreinte === empreinteC),1)
                    this.setState({allPC:allPCTemp})
                }
                this.setState({openDialogDetail:false,bufferCNI:null,bufferJDD:null})
                Toast.fire({
                    title: response.data.resultat,
                    icon: 'warning',
                })
            }
        })
    }

    handleChangePagePdf(action){
        let pageActuelle = this.state.pdfPage
        if(action === "next"){
            if(this.state.pdfPage !== this.state.pdfNumberOfPages){
                this.setState({pdfPage:pageActuelle+1})
            }
        }else{
            if(this.state.pdfPage !== 1){
                this.setState({pdfPage:pageActuelle-1})
            }
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.openDialogDetail}
                    onClose={()=>{this.setState({openDialogDetail:false,bufferCNI:null,bufferJDD:null})}}
                >
                    <DialogTitle>Détail votant</DialogTitle>
                    <DialogContent style={{width:"50vw",maxWidth:"500px"}}>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                            <div style={{width:"50%"}} className={"divBorderDialogSuffrage"}>
                                <div style={{position:"relative"}}>
                                    <p className={"legendeDivBorderDialogSuffrage"}>Prénom</p>
                                </div>
                                <p>{this.state.clientDetail.prenom}</p>
                            </div>
                            <div style={{width:"50%"}} className={"divBorderDialogSuffrage"}>
                                <div style={{position:"relative"}}>
                                    <p className={"legendeDivBorderDialogSuffrage"}>Nom</p>
                                </div>
                                <p>{this.state.clientDetail.nom}</p>
                            </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                            <div style={{width:"100%"}} className={"divBorderDialogSuffrage"}>
                                <div style={{position:"relative"}}>
                                    <p className={"legendeDivBorderDialogSuffrage"}>Adresse</p>
                                </div>
                                <p>{this.state.clientDetail.adresse}</p>
                            </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                            <div style={{width:"100%"}} className={"divBorderDialogSuffrage"}>
                                <div style={{position:"relative"}}>
                                    <p className={"legendeDivBorderDialogSuffrage"}>Numéro de carte d'identité</p>
                                </div>
                                <p>{this.state.clientDetail.CNI}</p>
                            </div>
                        </div>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}>
                            <div style={{width:"33%"}} className={"divBorderDialogSuffrage"}>
                                <div style={{position:"relative"}}>
                                    <p className={"legendeDivBorderDialogSuffrage"}>Carte d'identité</p>
                                    {this.state.bufferCNI ?(
                                        <>
                                            {this.state.CNIname.split(".")[this.state.CNIname.split(".").length-1] === "pdf" ? (
                                                <img onClick={()=>{this.setState({openShowImage:true,imageToShow:"CNI"})}} style={{textAlign:"center", height:90,maxWidth:125, marginLeft:20,marginTop:20, cursor:"pointer", zIndex:99999}} src={require("../../assets/logoPDF.png")} alt="logoSuffrage"/>
                                            ):(
                                                <img onClick={()=>{this.setState({openShowImage:true,imageToShow:"CNI"})}} alt={"CNI"} style={{textAlign:"center", height:90,maxWidth:125,marginTop:20, cursor:"pointer"}}  src={`data:${"image"+this.state.CNIname.split(".")[1]};base64,${Buffer.from(this.state.bufferCNI.data).toString("base64")}`} />
                                            )}
                                        </>
                                    ):(
                                        <>
                                            <CircularProgress  style={{margin:10}}/>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div style={{width:"33%"}} className={"divBorderDialogSuffrage"}>
                                <div style={{position:"relative"}}>
                                    <p className={"legendeDivBorderDialogSuffrage"}>Carte d'identité et visage</p>
                                    {this.state.bufferVI ?(
                                        <>
                                            {this.state.VIname.split(".")[this.state.VIname.split(".").length-1] === "pdf" ? (
                                                <img onClick={()=>{this.setState({openShowImage:true,imageToShow:"VI"})}} style={{textAlign:"center", height:90,maxWidth:125, marginLeft:20,marginTop:20, cursor:"pointer", zIndex:99999}} src={require("../../assets/logoPDF.png")} alt="logoSuffrage"/>
                                            ):(
                                                <img onClick={()=>{this.setState({openShowImage:true,imageToShow:"VI"})}} alt={"VI"} style={{textAlign:"center", height:90,maxWidth:125,marginTop:20, cursor:"pointer"}}  src={`data:${"image"+this.state.VIname.split(".")[1]};base64,${Buffer.from(this.state.bufferVI.data).toString("base64")}`} />
                                            )}
                                        </>
                                    ):(
                                        <>
                                            <CircularProgress  style={{margin:10}}/>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div style={{width:"33%"}} className={"divBorderDialogSuffrage"}>
                                <div style={{position:"relative"}}>
                                    <p className={"legendeDivBorderDialogSuffrage"}>Justificatif</p>
                                    {this.state.bufferJDD ?(
                                        <>
                                            {this.state.JDDname.split(".")[this.state.JDDname.split(".").length-1] === "pdf" ? (
                                                <img onClick={()=>{this.setState({openShowImage:true,imageToShow:"JDD"})}} style={{textAlign:"center", height:90, maxWidth:125, marginLeft:20,marginTop:20, cursor:"pointer", zIndex:99999}} src={require("../../assets/logoPDF.png")} alt="logoSuffrage"/>
                                            ):(
                                                <img onClick={()=>{this.setState({openShowImage:true,imageToShow:"JDD"})}} alt={"JDD"} style={{textAlign:"center", height:90,maxWidth:125,marginTop:20, cursor:"pointer"}}  src={`data:${"image"+this.state.JDDname.split(".")[1]};base64,${Buffer.from(this.state.bufferJDD.data).toString("base64")}`} />
                                            )}
                                        </>
                                    ):(
                                        <>
                                            <CircularProgress  style={{margin:10}}/>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <Button onClick={()=>{this.acceptOrNotInscription(this.state.clientDetail.empreinte,true)}} style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Valider l'inscription</Button>
                            <Button onClick={()=>{this.acceptOrNotInscription(this.state.clientDetail.empreinte,false)}} style={{backgroundColor:"#ff1818",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Refuser l'inscription</Button>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openShowImage}
                    onClose={()=>{this.setState({openShowImage:false})}}
                >
                    <DialogContent>
                        <div style={{display:"flex",justifyContent:"center",alignItems:"flex-start",heigth:"70vh",maxHeight:"70vh"}}>
                            {this.state.imageToShow ==="CNI" ? (
                                <>
                                    {this.state.CNIname.includes(".pdf") ?
                                        <>
                                            {this.state.bufferCNI && (
                                                <Document
                                                    file={`data:"application/pdf";base64,${Buffer.from(this.state.bufferCNI.data).toString("base64")}`}
                                                    onLoadSuccess={(pdf)=>{this.setState({showHUDPDF:true,pdfNumberOfPages:pdf.numPages})}}
                                                    onMouseEnter={()=>{this.setState({showHUDPDF:true})}}
                                                    onMouseLeave={()=>{this.setState({showHUDPDF:false})}}
                                                >
                                                    <h4 style={{position:"absolute",zIndex:9999,visibility:this.state.showHUDPDF ? "visible":"hidden"}}>{this.state.CNIname}</h4>
                                                    <div style={{backgroundColor:"white",position:"absolute",display:"flex",alignItems:"center",justifyContent:"center",zIndex:9999,bottom:50,left:"50%",width:180,marginLeft:-90,visibility:this.state.showHUDPDF ? "visible":"hidden",boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                                        <Button disabled={this.state.pdfPage === 1} onClick={()=>{this.handleChangePagePdf("previous")}}>
                                                            <ChevronLeft/>
                                                        </Button>
                                                        <p>{this.state.pdfPage}/{this.state.pdfNumberOfPages}</p>
                                                        <Button disabled={this.state.pdfPage === this.state.pdfNumberOfPages} onClick={()=>{this.handleChangePagePdf("next")}}>
                                                            <ChevronRight/>
                                                        </Button>
                                                    </div>
                                                    <Page pageNumber={this.state.pdfPage} />
                                                </Document>
                                            )}
                                        </>
                                    :
                                        this.state.bufferCNI && <img alt={"CNI"} style={{textAlign:"center", height:600,zIndex:"9999"}}  src={`data:${"image"+this.state.CNIname.split(".")[1]};base64,${Buffer.from(this.state.bufferCNI.data).toString("base64")}`} />
                                    }
                                </>
                            ):(
                                <>
                                    {this.state.imageToShow === "VI" ? (
                                        <>
                                            {this.state.VIname.includes(".pdf") ?
                                                <>
                                                    {this.state.bufferVI && (
                                                        <Document
                                                            file={`data:"application/pdf";base64,${Buffer.from(this.state.bufferVI.data).toString("base64")}`}
                                                            onLoadSuccess={(pdf)=>{this.setState({showHUDPDF:true,pdfNumberOfPages:pdf.numPages})}}
                                                            onMouseEnter={()=>{this.setState({showHUDPDF:true})}}
                                                            onMouseLeave={()=>{this.setState({showHUDPDF:false})}}
                                                        >
                                                            <h4 style={{position:"absolute",zIndex:9999,visibility:this.state.showHUDPDF ? "visible":"hidden"}}>{this.state.VIname}</h4>
                                                            <div style={{backgroundColor:"white",position:"absolute",display:"flex",alignItems:"center",justifyContent:"center",zIndex:9999,bottom:50,left:"50%",width:180,marginLeft:-90,visibility:this.state.showHUDPDF ? "visible":"hidden",boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                                                <Button disabled={this.state.pdfPage === 1} onClick={()=>{this.handleChangePagePdf("previous")}}>
                                                                    <ChevronLeft/>
                                                                </Button>
                                                                <p>{this.state.pdfPage}/{this.state.pdfNumberOfPages}</p>
                                                                <Button disabled={this.state.pdfPage === this.state.pdfNumberOfPages} onClick={()=>{this.handleChangePagePdf("next")}}>
                                                                    <ChevronRight/>
                                                                </Button>
                                                            </div>
                                                            <Page pageNumber={this.state.pdfPage} />
                                                        </Document>
                                                    )}
                                                </>
                                                :
                                                this.state.bufferVI && <img alt={"JDD"} style={{textAlign:"center", height:600,zIndex:"9999"}}  src={`data:${"image"+this.state.VIname.split(".")[1]};base64,${Buffer.from(this.state.bufferVI.data).toString("base64")}`} />
                                            }
                                        </>
                                    ):(
                                        <>
                                            {this.state.JDDname.includes(".pdf") ?
                                                <>
                                                    {this.state.bufferJDD && (
                                                        <Document
                                                            file={`data:"application/pdf";base64,${Buffer.from(this.state.bufferJDD.data).toString("base64")}`}
                                                            onLoadSuccess={(pdf)=>{this.setState({showHUDPDF:true,pdfNumberOfPages:pdf.numPages})}}
                                                            onMouseEnter={()=>{this.setState({showHUDPDF:true})}}
                                                            onMouseLeave={()=>{this.setState({showHUDPDF:false})}}
                                                        >
                                                            <h4 style={{position:"absolute",zIndex:9999,visibility:this.state.showHUDPDF ? "visible":"hidden"}}>{this.state.JDDname}</h4>
                                                            <div style={{backgroundColor:"white",position:"absolute",display:"flex",alignItems:"center",justifyContent:"center",zIndex:9999,bottom:50,left:"50%",width:180,marginLeft:-90,visibility:this.state.showHUDPDF ? "visible":"hidden",boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                                                                <Button disabled={this.state.pdfPage === 1} onClick={()=>{this.handleChangePagePdf("previous")}}>
                                                                    <ChevronLeft/>
                                                                </Button>
                                                                <p>{this.state.pdfPage}/{this.state.pdfNumberOfPages}</p>
                                                                <Button disabled={this.state.pdfPage === this.state.pdfNumberOfPages} onClick={()=>{this.handleChangePagePdf("next")}}>
                                                                    <ChevronRight/>
                                                                </Button>
                                                            </div>
                                                            <Page pageNumber={this.state.pdfPage} />
                                                        </Document>
                                                    )}
                                                </>
                                                :
                                                this.state.bufferJDD && <img alt={"JDD"} style={{textAlign:"center", height:600,zIndex:"9999"}}  src={`data:${"image"+this.state.JDDname.split(".")[1]};base64,${Buffer.from(this.state.bufferJDD.data).toString("base64")}`} />
                                            }
                                        </>
                                    )}

                                </>
                            )}
                        </div>
                    </DialogContent>
                </Dialog>

                <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                    <div style={{overflowY:"auto",maxHeight:"60vh",height:"40vh",width:"70vw",minWidth:"800px",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                        <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Liste des inscriptions</h2></div>
                        <TableContainer style={{overflowY:"auto"}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth,textAlign:"center", fontWeight:"bold"}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {this.state.allPC.length === 0 ? (
                                    <TableCell colSpan={4} style={{textAlign:"center"}}>
                                        <h3 style={{width:"100%",textAlign:"center"}}>Aucune inscription votant à confirmer pour le moment...</h3>
                                    </TableCell>
                                ):(
                                    <TableBody>
                                        {this.state.allPC.map((row) => {
                                            return (
                                                <TableRow onClick={()=>{this.setState({clientDetail:row,openDialogDetail:true});this.getFilesIncription(row.empreinte)}} style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {
                                                        return (
                                                            <>
                                                                <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                    {row[column.id] !=="errorDecrypt"? row[column.id]:"inconnu"}
                                                                </TableCell>
                                                            </>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                )}

                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(GererInscriptionClient)