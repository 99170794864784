import React, {Component} from 'react';
import {sha256} from 'js-sha256';
import {withRouter} from '../shared/NavigationUtils';
import {
    Backdrop,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import Axios from "axios";
import config from "../json/config.json";

import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let columns = [
    { id: 'name', label: 'Url', minWidth: 10 },
    { id: 'hash', label: 'Hash', minWidth: 250 },
];

let jwtDecoded;

class IntegriteDesRegistres extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateDrawer : true,
            displayAdministration : false,
            arrayIntegrite : [],
            resultatIntegrite : 'En attente ...',
            openLoad : false,

        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        }, () => {
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt", {},
                {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat !== true){
                    this.props.navigate('/ConnexionAdmin')
                }else{
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:jwtDecoded.pseudo
                    })
                    global.emitter.emit("onRouteChanged",{
                        pageName:'Intégrité des registres',
                        pseudo:jwtDecoded.pseudo,
                    })
                    this.integriteReg();
                }
            })
        })

    }

    integriteReg(){
        this.setState({openLoad:true})
        Axios.post(url+"integriteReg",{},
            {headers:{Authorization:"Bearer " + this.state.jwt}
            }).then((response)=> {
                let res = response.data.resultat, arrayIntegrite = [];
                for (let i = 0; i<res.length-1;i++){
                    arrayIntegrite.push([res[i].name, res[i].hash]);
                }
                let resultatIntegrite = res[res.length-1].resultat
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                this.setState({
                    arrayIntegrite:arrayIntegrite,
                    resultatIntegrite:resultatIntegrite,
                }, ()=>{
                    setTimeout(()=>{this.setState({openLoad:false})},700)
                })
        }).catch(()=>{this.setState({openLoad:false})})
    }

    render() { //Affichage sur la page
        const { classes } = this.props;
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div className={"flexCenter"} style={{maxWidth:'90%',minWidth:"50%", flexDirection:"column", overflow:"auto"}}>
                        <TableContainer style={{backgroundColor:"white",overflow:"auto", maxHeight:450}}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth,maxWidth:column.maxWidth, fontWeight:"bold" }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(this.state.arrayIntegrite).map(node=>{
                                        return(
                                            <TableRow key={node[0]}>
                                                {columns.map((column)=>{
                                                    return(
                                                        <TableCell key={column.id}>
                                                            {column.id === 'name' ?
                                                                node[0]
                                                            :
                                                                node[1] === "Serveur injoignable." ? <p>Serveur injoignable.</p> : sha256(JSON.stringify(node[1]))
                                                            }
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div style={{display:"flex"}}>
                            <p>Statut des registres :</p>
                            {this.state.resultatIntegrite === 'Registres synchronisés' ? (<p style={{fontWeight:'bold'}}>{this.state.resultatIntegrite}</p>):(<p style={{color:'red',fontWeight:'bold'}}>{this.state.resultatIntegrite}</p>)}
                        </div>
                        <Button variant={"contained"} style={{backgroundColor:"white", color:"#000"}} onClick={()=>{this.integriteReg()}}>Vérification manuelle de l’intégrité des registres</Button>
                    </div>

                </div>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Mise à jour des résultats...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default withRouter(IntegriteDesRegistres)