import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import {KeyboardReturn} from "@mui/icons-material";
import jwt_decode from "jwt-decode";
import {Avatar, Backdrop, CircularProgress, InputAdornment, TextField} from "@mui/material";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import PayPalButtonBR from "../../Components/PaypalButtonBR";

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Design","Développement","Marketing","Informatique et logiciels","Développement personnel","Business","Photographie","Musique","Divers"
]
const {baseUrl, paypalPublicKey, suffix} = config;
const url = baseUrl + suffix

let socketIO; //Initialisation de l'instance du socket
let jwtDecoded;

class Token extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
        this.createOrderPaypal = this.createOrderPaypal.bind(this);
    }

    setInitialStates(){
        return{
            tokenDetail:{},
            suggestedVids:[],
            sellingPrice:"",
            sellingPriceFournisseur:"",
            modifySellingPrice:null,
            modifySellingPriceFournisseur:null,
            empreinteUrl:null,
            disableBuyButton:false,
            displayBackdrop:false,
            displayPaypalButton:false
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    _emitConnectedBR
    componentDidMount() {
        this._emitConnectedBR = global.emitter.addListener("emitConnectedBR",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("BUYRESALE_jwt")}, ()=>{
                jwtDecoded = jwt_decode(this.state.jwt)
                global.emitter.emit("onRouteChangedBuyResale", {
                    jwt: this.state.jwt,
                })
                this.getTokenDetailConnected()
            })
        })
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("BUYRESALE_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedBuyResale", {
                        jwt: this.state.jwt,
                    })
                    this.getTokenDetailConnected()
                }else{
                    sessionStorage.removeItem("BUYRESALE_jwt")
                    global.emitter.emit("onRouteChangedBuyResale", {
                        jwt: null,
                    })
                    this.getTokenDetailNotConnected()
                }
            })
        })
    }

    isMyProfileComplete(){
        Axios.post(url+"getInfos",{},{
            headers:{
                Authorization:"Bearer " + this.state.jwt
            }
        }).then(response=>{
            console.log(response.data)
            if(response.data.resultat === "done"){
                this.setState({profileComplete:!!response.data.allInfosCompleted})
            }else{
                this.setState({profileComplete:false})
            }

        })
    }

    getTokenDetailConnected(){
        console.log("here")
        let idToken = new URLSearchParams(window.location.search).get("id");
        let emp = new URLSearchParams(window.location.search).get("emp");
        this.setState({empreinteUrl:emp})
        if (!!idToken){
            Axios.post(url+"getTokenByIdConnected",{idToken,empreintePossesseur:emp},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                console.log(response.data)
                if (response.data.resultat === "done"){
                    this.setState({tokenDetail:response.data.token, connected:true, suggestedVids:response.data.suggestions})
                    this.isMyProfileComplete()
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Une erreur est survenue.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        this.props.navigate("/BuyResale/Accueil")
                    })
                }
            })
        }else{
            this.props.navigate("/BuyResale/Accueil")
        }
    }

    getTokenDetailNotConnected(){
        let idToken = new URLSearchParams(window.location.search).get("id");
        let emp = new URLSearchParams(window.location.search).get("emp");
        console.log(emp)
        if (!!idToken){
            Axios.post(url+"getTokenById",{idToken,empreintePossesseur:emp}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                console.log("rs", response.data)
                if (response.data.resultat === "done"){
                    this.setState({tokenDetail:response.data.token, connected:false, suggestedVids:response.data.suggestions})
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Une erreur est survenue.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        this.props.navigate("/BuyResale/Accueil")
                    })
                }
            })
        }else{
            this.props.navigate("/BuyResale/Accueil")
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    createOrderPaypal(){
        if(this.state.connected === false){
            Swal.fire({
                title: 'Connectez-vous !',
                text: "Veuillez vous connecter afin de pouvoir acheter une vidéo.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else{
            this.setState({disableBuyButton:true,displayBackdrop:true})
            console.log(this.state.empreinteUrl)
            let dataAxios = {
                idToken : this.state.tokenDetail.idToken,
                empreintePossesseur : this.state.empreinteUrl ? this.state.empreinteUrl : this.state.tokenDetail.empreinte,
                achatF:(this.state.displayPaypalButton==="F")
            }
            return new Promise((resolve, reject) => {
                Axios.post(url+"buyVideo",dataAxios,{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    console.log("rs", response.data)
                    this.setState({displayBackdrop:false})
                    if (response.data.resultat === "done"){
                        resolve(response.data.orderPaypalID)
                    } else{
                        this.setState({disableBuyButton:false})
                        reject(response.data.resultat)
                        Swal.fire({
                            title: "Erreur !",
                            text: response.data.resultat,
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        })
                    }
                })
            })
        }
    }

    sellVideo(){
        if(!this.state.profileComplete){
            Swal.fire({
                title: 'Profil non complet',
                text: "Votre profil n'est pas suffisamment complété pour publier une vidéo. Nous allons vous rediriger sur la page de modification de profil !",
                icon: 'info',
                confirmButtonText: 'Ok',
                cancelButtonText:"Annuler",
                showCancelButton:true,
                allowEscapeKey:false,
                allowOutsideClick:false
            }).then((data)=>{
                if(data.isConfirmed){
                    this.props.navigate("/BuyResale/Profil?emp=missingInfos")
                }
            })
        }else{
            this.setState({displayBackdrop:true})
            Axios.post(url+"remiseEnVente", {idToken:this.state.tokenDetail.idTokenAchat, prix:this.state.sellingPrice,prixF:this.state.sellingPriceFournisseur || null},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === "done"){
                    let tokenDetailTemp = this.state.tokenDetail
                    tokenDetailTemp.visionner = true
                    tokenDetailTemp.vente = {
                        idToken:response.data.newIdVente,
                        prixU:this.state.sellingPrice,
                        ownVente:true,
                        prixF:this.state.sellingPriceFournisseur || "notAllowed",
                    }
                    this.setState({tokenDetail:tokenDetailTemp})
                }else if (response.data.resultat === "Iban et bic manquants."){
                    Swal.fire({
                        title: 'Des informations sont manquantes',
                        text: "Pour uploader une vidéo, vous devez renseigner votre IBAN et BIC afin de reçevoir vos paiements.",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        Swal.fire({
                            title: 'Renseignez vos informations',
                            html:
                                '<p style="margin-bottom: 0; display: flex;align-items: flex-start;font-weight: bold" >IBAN</p>' +
                                '<input id="swal-input1" class="swal2-input">' +
                                '<p style="margin: 0; display: flex;align-items: flex-start;font-weight: bold" >BIC</p>' +
                                '<input id="swal-input2" class="swal2-input">',
                            focusConfirm: false,
                            confirmButtonText:"Valider",
                            showCancelButton: true,
                            cancelButtonText:"Annuler",
                            preConfirm: () => {
                                if(document.getElementById('swal-input2').value && document.getElementById('swal-input1').value){
                                    Axios.post(url+"editInfos",{
                                        iban:document.getElementById('swal-input1').value,
                                        bic:document.getElementById('swal-input2').value,
                                    },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                                        console.log(response.data)
                                        if(response.data.resultat === "done"){
                                            Toast.fire({
                                                icon: 'success',
                                                title: 'IBAN et BIC modifiés.'
                                            })
                                            setTimeout(()=>{this.sellVideo()},500)
                                        }else{
                                            Toast.fire({
                                                icon: 'warning',
                                                title: 'Une erreur est survenue, ce fichier ne vous appartient pas.'
                                            })
                                        }
                                    })
                                }else{
                                    Swal.fire({
                                        text: "Veuillez remplir les informations demandées correctement.",
                                        target:"#dialogUpload",
                                        icon: 'warning',
                                        confirmButtonText: "Ok"
                                    }).then(()=>{
                                        this.sellVideo()
                                    })
                                }
                            }
                        })
                    })
                } else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: response.data.resultat,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
                this.setState({displayBackdrop:false})
            })
        }
    }

    cancelSale(){
        console.log(this.state.tokenDetail.vente.idToken)
        Axios.post(url+"cancelVente", {idToken:this.state.tokenDetail.vente.idToken},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let tokenDetailTemp = this.state.tokenDetail
                tokenDetailTemp.vente = false
                this.setState({
                    tokenDetail:tokenDetailTemp,
                    sellingPrice:"",
                    modifySellingPrice:null,
                    sellingPriceFournisseur:"",
                    modifySellingPriceFournisseur:null
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Une erreur est survenue.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    modifySellingPriceFunc(){
        Axios.post(url+"editVente", {idToken:this.state.tokenDetail.vente.idToken, prixU:this.state.modifySellingPrice},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let tokenDetailTemp = this.state.tokenDetail
                tokenDetailTemp.vente.prixU = this.state.modifySellingPrice
                this.setState({tokenDetail:tokenDetailTemp,modifySellingPrice:null})
                Toast.fire({
                    icon: 'success',
                    title: 'Prix de la vente modifié !'
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Une erreur est survenue.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    generateSellLink(){
        let urlLink = baseUrl+(suffix===":42000/" ? ":3000":"")
        navigator.clipboard.writeText(urlLink+"/BuyResale/Token?id="+this.state.tokenDetail.idToken+"&emp="+jwtDecoded.empreinte)
        Toast.fire({
            icon: 'info',
            title: 'Lien de vente copié !'
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.displayBackdrop}
                    id={"backdropLoading"}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100, flexDirection:"column"}}>
                        {!this.state.empreinteUrl && this.state.connected === true &&  (
                            <div style={{position:"relative", width:"100%", textAlign:"left"}}>
                                <div style={{position:"absolute", zIndex:999, top:-40, height:20}}>
                                    <button onClick={()=>{this.props.navigate(-1)}} style={{padding:5, fontSize:14}} className={"divButtonBuyResale flexCenter"}> <KeyboardReturn style={{marginRight:5, transform:"scale(0.8)"}}/> Retour</button>
                                </div>
                            </div>
                        )}
                        <div style={{flex:1, width:"100%", display:"flex",alignItems:"flex-start", justifyContent:"space-between", flexDirection:this.state.showBrowserView ? "row":"column"}}>
                            <div>
                                {(this.state.connected === true && this.state.tokenDetail.visionner === true) ? (
                                    <video style={{borderRadius:10, width:this.state.showBrowserView ?"50vw":"100%"}} preload={"metadata"} controls>
                                        <source
                                            src={`${this.state.tokenDetail.srvDoc}videos${!this.state.tokenDetail.verified ? "Tampon":""}/${this.state.tokenDetail.owner}/${this.state.tokenDetail.fileName && this.state.tokenDetail.fileName.replaceAll("##","pourcentdiese")}?jwt=${this.state.jwt}`}
                                            type={`video/${this.state.tokenDetail.fileName && (this.state.tokenDetail.fileName.split(".")[this.state.tokenDetail.fileName.split(".").length-1])}`}/>
                                        Désolé, votre navigateur ne permet pas de lire des vidéos.
                                    </video>
                                ) : (
                                    <>
                                        <div style={{width:"fit-content", height:"fit-content"}}>
                                            <img style={{borderRadius:10, width:this.state.showBrowserView ?"50vw":"100%", filter:"brightness(70%)"}} src={`${this.state.tokenDetail.srvDoc}images/miniatures/${this.state.tokenDetail.idToken}.png`} alt={`${this.state.tokenDetail.fileName && this.state.tokenDetail.fileName.split("##")[1]}`}/>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div style={{display:"flex", flexDirection:"column",width:"100%", marginLeft:this.state.showBrowserView && 20}}>
                                {!this.state.tokenDetail.verified && (
                                    <div style={{border:"1px solid #ff8100",borderRadius:10, width :"fit-content", marginBottom:10}}>
                                        <p style={{margin:3, color:"#ff8100"}}>Vidéo en cours de vérification</p>
                                    </div>
                                )}
                                <h2 style={{margin:0,marginBottom:5, textAlign:"left"}} className={"textShadowBorder"}>{this.state.tokenDetail.titre}</h2>
                                <h4 onClick={()=>{this.props.navigate("/BuyResale/Profil?emp="+this.state.tokenDetail.empreinte)}} className={"flexCenter"} style={{marginBottom:5, marginTop:5, width:"fit-content", cursor:"pointer"}}>
                                    <Avatar
                                        variant={"circular"}
                                        src={`${url}images/avatar/${this.state.tokenDetail.empreinte}.png`}
                                        alt={this.state.tokenDetail.owner && this.state.tokenDetail.owner.toUpperCase()}
                                        style={{width:40, height:40, fontSize:18, marginRight:5, marginBottom:10}}
                                    />
                                    {this.state.tokenDetail.owner}
                                </h4>
                                <div style={{border:"1px solid", borderColor:"#73bdec",borderRadius:10, width :"fit-content"}}>
                                    <h4 style={{margin:3,color:"black"}}>{this.state.tokenDetail.categorie}</h4>
                                </div>
                                <h4 style={{marginBottom:5}}>Description :</h4>
                                <h4 style={{marginTop:0,marginBottom:10, fontWeight:"normal", maxHeight:290, overflowY:"auto"}}>{this.state.tokenDetail.desc}</h4>
                                <div className={"buttonBuyResale"}>

                                </div>
                                {this.state.connected ? (
                                    <>
                                        {this.state.tokenDetail.visionner ? (
                                            <div style={{display:"flex", alignItems:"center", justifyContent:"space-evenly"}}>
                                                {this.state.tokenDetail.vente && this.state.tokenDetail.vente.ownVente === true ? (
                                                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                        <div className={"flexCenter"} style={{width:"100%", marginTop:10}}>
                                                            <button disabled={!this.state.tokenDetail.verified} style={{width:"100%"}} onClick={()=>{this.generateSellLink()}} className={"divButtonBuyResale"} >Copier le lien vers la vente</button>
                                                        </div>
                                                        <div className={"flexCenter"} style={{width:"100%", marginTop:5, flexWrap:"wrap"}}>
                                                            <TextField style={{height:56, width:150, margin:5, marginLeft:0}} required={true} name={"modifySellingPrice"} defaultValue={this.state.tokenDetail.vente.prixU} value={this.state.modifySellingPrice === null ? this.state.tokenDetail.vente.prixU:this.state.modifySellingPrice} onChange={(event)=>{this.handleChangeOnlyNum(event)}} placeholder={this.state.tokenDetail.vente.prixU} label="Prix de vente" variant="outlined"
                                                                       InputProps={{
                                                                           endAdornment:
                                                                               <InputAdornment position="end">
                                                                                   <p style={{color:"#000000"}}>€</p>
                                                                               </InputAdornment>
                                                                       }}
                                                            />
                                                            {(this.state.tokenDetail.empreinte === jwtDecoded.empreinte && (this.state.tokenDetail && this.state.tokenDetail.vente.prixF !== "notAllowed")) ? (
                                                                <>
                                                                    <TextField style={{height:56, width:150, margin:5}} required={true} name={"modifySellingPriceFournisseur"} defaultValue={this.state.tokenDetail.vente.prixF} value={this.state.modifySellingPriceFournisseur === null ? this.state.tokenDetail.vente.prixF:this.state.modifySellingPriceFournisseur} onChange={(event)=>{this.handleChangeOnlyNum(event)}} placeholder={this.state.tokenDetail.vente.prixF} label="Prix fournisseur" variant="outlined"
                                                                               InputProps={{
                                                                                   endAdornment:
                                                                                       <InputAdornment position="end">
                                                                                           <p style={{color:"#000000"}}>€</p>
                                                                                       </InputAdornment>
                                                                               }}
                                                                    />
                                                                    <button disabled={!this.state.modifySellingPrice && !this.state.modifySellingPriceFournisseur} onClick={()=>{this.modifySellingPriceFunc()}} className={"divButtonBuyResale"} style={{margin:5, marginRight:0, padding:18}}>Modifier le prix</button>
                                                                </>
                                                            ):(
                                                                <>
                                                                    <button disabled={!this.state.modifySellingPrice} onClick={()=>{this.modifySellingPriceFunc()}} className={"divButtonBuyResale"} style={{margin:5, padding:18}}>Modifier le prix</button>
                                                                    <button style={{margin:5, marginRight:0, padding:18,backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)"}} onClick={()=>{this.cancelSale()}} className={"divButtonBuyResale"}>Annuler la vente</button>
                                                                </>
                                                            )}

                                                        </div>
                                                        {(this.state.tokenDetail.empreinte === jwtDecoded.empreinte && (this.state.tokenDetail && this.state.tokenDetail.vente.prixF !== "notAllowed")) && (
                                                            <div className={"flexCenter"} style={{width:"100%", marginTop:10}}>
                                                                <button style={{width:"100%",backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)"}} onClick={()=>{this.cancelSale()}} className={"divButtonBuyResale"}>Annuler la vente</button>
                                                            </div>
                                                        )}
                                                    </div>
                                                ):(
                                                    // Si la vidéo n'est pas encore en vente :
                                                    <div className={"flexCenter"} style={{width:"100%", marginTop:10, flexWrap:"wrap"}}>
                                                        <TextField style={{height:56, marginRight:5}} required={true} name={"sellingPrice"} value={this.state.sellingPrice ||""} onChange={(event)=>{this.handleChangeOnlyNum(event)}} placeholder={this.state.tokenDetail.vente.prixU} label="Prix à l'unité" variant="outlined"
                                                                   InputProps={{
                                                                       endAdornment:
                                                                           <InputAdornment position="end">
                                                                               <p style={{color:"#000000"}}>€</p>
                                                                           </InputAdornment>
                                                                   }}
                                                        />
                                                        {(this.state.tokenDetail.empreinte === jwtDecoded.empreinte && (this.state.tokenDetail && this.state.tokenDetail.vente.prixF !== "notAllowed")) && (
                                                            <TextField style={{height:56, margin:5}} required={true} name={"sellingPriceFournisseur"} value={this.state.sellingPriceFournisseur ||""} onChange={(event)=>{this.handleChangeOnlyNum(event)}} placeholder={this.state.tokenDetail.vente.prixF} label="Prix fournisseur" variant="outlined"
                                                                       InputProps={{
                                                                           endAdornment:
                                                                               <InputAdornment position="end">
                                                                                   <p style={{color:"#000000"}}>€</p>
                                                                               </InputAdornment>
                                                                       }}
                                                            />
                                                        )}
                                                        <button disabled={this.state.sellingPrice === "" || this.state.displayBackdrop} onClick={()=>{this.sellVideo()}} className={"divButtonBuyResale"} style={{padding:18}}>Mettre en vente</button>
                                                    </div>
                                                )}
                                            </div>
                                        ):(
                                            <>
                                                {this.state.tokenDetail.vente && (
                                                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-evenly"}}>
                                                        {this.state.displayPaypalButton ? (
                                                            <div className={"flexCenter"} style={{width:!this.state.showBrowserView?"100%":"80%",height:"100%",flexDirection:"column", justifyContent:"space-between"}}>
                                                                <h2 style={{margin:10, marginBottom:0, fontWeight:600}}>Payez avec PayPal ou par carte bancaire :</h2>
                                                                <p style={{margin:10, marginBottom:30, fontWeight:600}}>Prix d'achat : {this.state.displayPaypalButton === "U" ? this.state.tokenDetail.vente.prixU:this.state.tokenDetail.vente.prixF}€</p>
                                                                <PayPalScriptProvider options={{ "client-id": paypalPublicKey, currency: "EUR" }}>
                                                                    <PayPalButtonBR onCreateOrder={this.createOrderPaypal} onApproveOrder={()=>{this.setState({showBackdrop:true})}}/>
                                                                </PayPalScriptProvider>
                                                            </div>
                                                        ):(
                                                            <React.Fragment>
                                                                <button className={"divButtonBuyResale"} disabled={this.state.tokenDetail.empreinte === jwtDecoded.empreinte || this.state.disableBuyButton} onClick={()=>{this.setState({displayPaypalButton:"U"})}}>Acheter la formation - {this.state.tokenDetail.vente.prixU}€</button>
                                                                {this.state.tokenDetail.vente.prixF !=="notAllowed" && (
                                                                    <button className={"divButtonBuyResale"} disabled={this.state.tokenDetail.empreinte === jwtDecoded.empreinte || this.state.disableBuyButton} onClick={()=>{this.setState({displayPaypalButton:"F"})}}>Devenir fournisseur - {this.state.tokenDetail.vente.prixF}€</button>
                                                                )}
                                                            </React.Fragment>)}

                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </>
                                ):(
                                    <div style={{display:"flex", alignItems:"center", justifyContent:"space-evenly", flexWrap:"wrap"}}>
                                        <button className={"divButtonBuyResale"} onClick={()=>{global.emitter.emit("askConnexionBR") /*Display interface connexion du header BuyResale*/}}>Acheter la formation - {this.state.tokenDetail.vente && this.state.tokenDetail.vente.prixU}€</button>
                                        {this.state.tokenDetail.vente && this.state.tokenDetail.vente.prixF !=="notAllowed" && (
                                            <button className={"divButtonBuyResale"} disabled={this.state.tokenDetail.empreinte === jwtDecoded.empreinte} onClick={()=>{global.emitter.emit("askConnexionBR")}}>Devenir fournisseur - {this.state.tokenDetail.vente.prixF}€</button>
                                        )}
                                    </div>
                                )}

                            </div>
                        </div>
                        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                            <h3 style={{marginBottom:0}}>Ces vidéos pourraient aussi vous intéresser :</h3>
                            <div style={{width:"100%", display:"flex", justifyContent:"flex-start", alignItems:"center", maxWidth:"90vw", overflowX:"auto"}}>
                                {this.state.suggestedVids.length>0 ? (
                                    <>
                                        {this.state.suggestedVids.map(vid=>(
                                            <div onAuxClick={(e)=>{if(e.button===1){window.open("/BuyResale/Token?id="+vid.idToken, "_blank")}}} onClick={()=>{window.open("/BuyResale/Token?id="+vid.idToken, "_self")}} className={"divMyVideo"} style={{flexDirection:"column", background: "rgba(0,0,0,.5)",display:"flex", alignItems:"center",justifyContent:"flex-start", overflow:"hidden", border:!vid.verified && "2px solid #ff8100"}}>
                                                <div style={{width:'100%', position:"relative"}}>
                                                    <div style={{height:31, textAlign:"center",position:"absolute", zIndex:999, width:"100%", background: "rgba(0,0,0,.6)"}}>
                                                        <h4 style={{margin:5, textAlign:"center", color:"white"}} >{vid.titre.length>32 ? (vid.titre.slice(0,28)+"..."):(vid.titre)}</h4>
                                                    </div>
                                                </div>
                                                <div style={{overflow:"hidden", width:"100%",height:200, display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <img style={{width:"100%", position:"absolute"}} src={`${vid.srvDoc}images/miniatures/${vid.idToken}.png`} alt={`${vid.fileName.split("##")[1]}`}/>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ):(
                                    <h4 style={{fontWeight:"normal"}}>Aucune vidéo similaire disponible pour le moment...</h4>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Token)