import React, {Component} from 'react';
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField
} from '@mui/material';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';

import config from "../../../json/config.json";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {isMobile} from "react-device-detect";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const columnsC = [
    { id: 'pseudo', label: 'PSEUDO', minWidth: 170 },
    { id: 'km', label: 'KM', minWidth: 50 },
    { id: 'temps', label: 'TEMPS', minWidth: 70 },
    { id: 'co2', label: 'CO2', minWidth: 70 },
    { id: 'token', label: 'TOKEN', minWidth: 50 },
    { id: 'argent', label: 'ARGENT', minWidth: 50 },
    { id: 'email', label: 'EMAIL', minWidth: 170 },
    { id: 'hash', label: 'HASH', minWidth: 170 }
];
const columnsF = [
    { id: 'nom', label: 'NOM', minWidth: 170 },
    { id: 'email', label: 'EMAIL', minWidth: 100 },
];
const columnsA = [
    { id: 'nom', label: 'NOM', minWidth: 170 },
    { id: 'email', label: 'EMAIL', minWidth: 170 },
    { id: 'siteweb', label: 'SITE WEB', minWidth: 170 },
    { id: 'argentDu', label: 'ARGENT DÛ', minWidth: 50 },
];

function createDataC(pseudo, km, temps, co2, token, argent , email, hash) {
    return { pseudo, km, temps, co2, token, argent, email, hash};
}
function createDataF(nom, email) {
    return { nom, email};
}
function createDataA(nom, email, siteweb, argentDu) {
    return { nom, email, siteweb, argentDu};
}

let initialRowsC = [],recherche="", clear = "none";

class Synthese extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageC:0, pageF:0, pageA:0,
            rowsPerPageC:10, rowsPerPageF:10, rowsPerPageA:10,
            initialRowsC:[], rowsC:[], rowsF:[], rowsA:[],
            recherche:'',
            totalCO2:0,
        }
    }
    componentDidMount() {
        this.setState({
            pseudo:sessionStorage.getItem('SPURO_pseudo'),
            jwt: sessionStorage.getItem("SPURO_jwt"),
            hash : sessionStorage.getItem("SPURO_hash")
        },()=>{
            global.emitter.emit("onRouteChanged",{
                pageName:'Synthèse Ekoco',
                pseudo:this.state.pseudo
            })
            Axios.post(url+"connexionAdmin", {
                hash : this.state.hash,
                hashJwt : this.state.hash
            },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                if (response.data.resultat === "Inconnu."){
                    this.props.navigate('/ConnexionAdmin')
                }else{
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:this.state.pseudo
                    })
                    this.requeteAxios()
                }
            })

        })
    }
    requeteAxios(){
        Axios.post(url+"synthese", {
            hashJwt:this.state.hash
        }, {headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            let infos = response.data.resultat;
            let infoClients = infos[0];
            let infoAssociations = infos[1];
            let infoFournisseurs = infos[2];

            infoClients.sort(this.sortByName)
            infoFournisseurs.sort(this.sortByName)
            infoAssociations.sort(this.sortByName)

            let rowsC = [], rowsF = [], rowsA = [];

            infoClients.map((row)=>{
                rowsC.push(createDataC(row[0],row[1],row[2],row[3],row[4],row[5],row[6], row[7]))
            })
            infoFournisseurs.map((row)=>{
                rowsF.push(createDataF(row[0],row[1],row[2]))
            })
            infoAssociations.map((row)=>{
                rowsA.push(createDataA(row[0],row[1],row[2],row[3],row[4]))
            })
            initialRowsC = rowsC;

            this.setState({initialRowsC : rowsC, rowsC : rowsC, rowsF : rowsF, rowsA : rowsA, totalCO2 : infos[3].toFixed(3)})
        }).catch(error => {
            console.log(error);
        });
    }
    sortByName(a,b){
        if (a[0] === b[0]) {
            return 0;
        }
        else {
            return (a[0].toLowerCase() < b[0].toLowerCase()) ? -1 : 1;
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangePageC(event){
        if (event.currentTarget.title === "Next page"){
            this.setState({
                pageC:this.state.pageC+1
            })
        }else{
            this.setState({
                pageC:this.state.pageC-1
            })
        }
    };
    handleChangeRowsPerPageC(event){
        this.setState({
            pageC:0,
            rowsPerPageC:event.target.value
        })
    };

    handleChangePageF(event){
        if (event.currentTarget.title === "Next page"){
            this.setState({
                pageC:this.state.pageF+1
            })
        }else{
            this.setState({
                pageC:this.state.pageF-1
            })
        }
    };
    handleChangeRowsPerPageF(event){
        this.setState({
            pageF:0,
            rowsPerPageF:event.target.value
        })
    };

    handleChangePageA(event){
        if (event.currentTarget.title === "Next page"){
            this.setState({
                pageA:this.state.pageA+1
            })
        }else{
            this.setState({
                pageA:this.state.pageA-1
            })
        }
    };
    handleChangeRowsPerPageA(event){
        this.setState({
            pageA:0,
            rowsPerPageA:event.target.value
        })
    };

    handleClickRecherche(e){
        e.preventDefault();
        recherche = this.state.recherche.toLowerCase();
        let tempArray = []
        this.state.initialRowsC.map((row)=>{
            if (row.pseudo.toLowerCase().includes(recherche)){
                tempArray.push(row)
            }
        })
        clear = "block"
        this.setState({
            rowsC : tempArray
        })
    }
    clearFilter(){
        recherche="";
        clear = "none";
        this.setState({
            rowsC : initialRowsC,
            recherche : ""
        })
    }

    render(){ //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:70,display:"flex" ,backgroundColor:"#72aacc",height:"calc(100vh - 70px)",width:"100%", overflow: ( isMobile && !this.state.openDrawer) ? ('scroll'):('hidden')}}>
                    <div style={{width:"70%", display:"flex", flexDirection:"column", minWidth:'70%', margin:"auto", marginTop:10}}>
                        <div style={{width:'90%'}}>
                            <h2 style={{color:"white"}} >LISTE FOURNISSEURS</h2>
                            <Paper>
                                <TableContainer style={{maxHeight:220, overflowY:"scroll"}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {columnsF.map((columnF) => (
                                                    <TableCell
                                                        key={columnF.id}
                                                        align={columnF.align}
                                                        style={{ minWidth: columnF.minWidth }}
                                                    >
                                                        {columnF.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.rowsF.slice(this.state.pageF * this.state.rowsPerPageF, this.state.pageF * this.state.rowsPerPageF + this.state.rowsPerPageF).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                        {columnsF.map((columnF) => {
                                                            const value = row[columnF.id];
                                                            return (
                                                                <TableCell key={columnF.id} align={columnF.align}>
                                                                    {columnF.format && typeof value === 'number' ? columnF.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={this.state.rowsF.length}
                                    rowsPerPage={this.state.rowsPerPageF}
                                    page={this.state.pageF}
                                    onChangePage={(event)=>{this.handleChangePageF(event)}}
                                    onChangeRowsPerPage={(event)=>{this.handleChangeRowsPerPageF(event)}}
                                />
                            </Paper>
                        </div>

                        <div style={{width:'90%', marginTop:"5vh"}}>
                            <h2 style={{color:"white"}}>LISTE ASSOCIATIONS</h2>
                            <Paper>
                                <TableContainer style={{maxHeight:220, overflowY:"scroll"}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {columnsA.map((columnA) => (
                                                    <TableCell
                                                        key={columnA.id}
                                                        align={columnA.align}
                                                        style={{ minWidth: columnA.minWidth }}
                                                    >
                                                        {columnA.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.rowsA.slice(this.state.pageA * this.state.rowsPerPageA, this.state.pageA * this.state.rowsPerPageA + this.state.rowsPerPageA).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                        {columnsA.map((columnA) => {
                                                            const value = row[columnA.id];
                                                            return (
                                                                <TableCell key={columnA.id} align={columnA.align}>
                                                                    {columnA.format && typeof value === 'number' ? columnA.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={this.state.rowsA.length}
                                    rowsPerPage={this.state.rowsPerPageA}
                                    page={this.state.pageA}
                                    onChangePage={(event)=>{this.handleChangePageA(event)}}
                                    onChangeRowsPerPage={(event)=>{this.handleChangeRowsPerPageA(event)}}
                                />
                            </Paper>
                        </div>

                        <div style={{width:'90%', marginTop:"5vh"}}>
                            <div style={{display:"flex", flexWrap:"wrap"}}>
                                <h2 style={{color:"white"}}>LISTE CLIENTS</h2>
                                <form style={{display:"flex",height:"56px",flexDirection:"row",justifyContent:"center",alignItems:"center", margin:7, marginLeft:20}} onSubmit={(e)=>{this.handleClickRecherche(e)}}>
                                    <TextField style={{borderRadius:5}} name={"recherche"} value={this.state.recherche} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsClef" label="Recherche" variant={"outlined"} />
                                    <Button style={{height:"56px"}} type={"submit"} variant={"outlined"}> <SearchIcon/> </Button>
                                </form>
                                <div style={{display:"flex", justifyContent:"center",alignItems:"center"}} >
                                    <p style={{marginLeft:"20px"}}>{recherche}</p>
                                    <ClearIcon style={{display:clear}} onClick={()=>{this.clearFilter()}}/>
                                </div>
                            </div>

                            <Paper>
                                <TableContainer style={{maxHeight:220, overflowY:"scroll"}}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {columnsC.map((columnC) => (
                                                    <TableCell
                                                        key={columnC.id}
                                                        align={columnC.align}
                                                        style={{ minWidth: columnC.minWidth }}
                                                    >
                                                        {columnC.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.rowsC.slice(this.state.pageC * this.state.rowsPerPageC, this.state.pageC * this.state.rowsPerPageC + this.state.rowsPerPageC).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                        {columnsC.map((columnC) => {
                                                            let value = row[columnC.id];
                                                            if(!isNaN(parseFloat(value)) && (columnC.id === "km"|| columnC.id ==="temps" || columnC.id ==="token" || columnC.id === "co2" || columnC.id ==="argent")){
                                                                value = parseFloat(value).toFixed(3)
                                                            }
                                                            return (
                                                                <TableCell key={columnC.id} align={columnC.align}>
                                                                    {columnC.format && typeof value === 'number' ? columnC.format(value) : value}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={this.state.rowsC.length}
                                    rowsPerPage={this.state.rowsPerPageC}
                                    page={this.state.pageC}
                                    onChangePage={(event)=>{this.handleChangePageC(event)}}
                                    onChangeRowsPerPage={(event)=>{this.handleChangeRowsPerPageC(event)}}
                                />
                            </Paper>
                            <p style={{color:"white", fontWeight:"bold"}} >Total de CO2 gagné : {this.state.totalCO2}kg</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Synthese);