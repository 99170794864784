import {io} from "socket.io-client";
import config from "../json/config.json";

const {baseUrl, suffix} = config


let socketIO, socketIOMaster
const socketInstance = {
    connectionSocket: function(toVerify){
        socketIO = io(baseUrl+(suffix !== "/back/" ? suffix:"" ), {query:"toVerify="+toVerify});
        socketIO.on("connect", () => {
            sessionStorage.setItem("socket_id_session",socketIO.id)
        });
        socketIO.on("connectResult", (args) => {
            if(args.status === "Forbidden"){
                socketIO.disconnect()
                console.log("Forbidden : Socket déconnecté.")
            }
        });
        return socketIO
    }
}

export default socketInstance;

