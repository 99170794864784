import React, {Component} from 'react';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import {withRouter} from '../../shared/NavigationUtils';
import {Check, CheckCircle, Close, ContentCopy, Refresh, Search, ShoppingCart} from "@mui/icons-material";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import {Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import {isMobile} from "react-device-detect";
import SharedFooterGNFT from "./components/SharedFooterGNFT";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import PayPalButton from "../../Components/PaypalButtonEnglish";

const {baseUrl, paypalPublicKey, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded = {}

let columns = [
    { id: 'name', label: 'Name', minWidth: 10 },
    { id: 'image', label: 'Image', minWidth: 10 },
    { id: 'year', label: 'Year', minWidth: 10 },
    { id: 'volume', label: 'Volume', minWidth: 10 },
    { id: 'buy', label: 'Buy', minWidth: 10 },
];

let intervalRefresh = undefined;

const ToastGNFT = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFontTitleToast',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    },
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const swalCustomClasses = {
    title: 'ChampFont ChampFontTitle',
    content: 'ChampFont ChampFontContent',
    confirmButton: 'ChampFont ChampFontButton',
    cancelButton: 'ChampFont ChampFontButton',
    container: "ToastGNFT-container",
    icon: "SwalGNFT-icon",
}

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    marginTop:"20px",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: swalCustomClasses,
    target:"#dialog-catalog-GNFT"
    // confirmButtonTextColor: "#3b3b3b",
})

class CatalogueGNFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packs:[],
            brands:[],
            packSelected:null,
            toSearch:"",
            soldOutPacksBool:false,
            categoryVisit:true,
            bottleBidding:null,
            valueBidding:null,
            displayTransactions:null,
            refreshingData:false,
            showBrowserView:true,
            displayHelperSwipe:true,
            paypalPaymentBottle:null
        };
        this.createOrderPaypal = this.createOrderPaypal.bind(this);

    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.resizePage)
        this._emitConnectedGNFT.remove()
        clearInterval(intervalRefresh)
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedGNFT = global.emitter.addListener("emitConnectedGNFT",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("GNFT_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedGNFT", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("GNFT_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedGNFT", {
                        jwt: this.state.jwt,
                    })
                    this.setState({connected:true})
                }
                this.getAllBrands()
                this.getAllPacks()
                this.startLoopRefreshData()
            })
        })
    }

    startLoopRefreshData(){
        if(intervalRefresh !== undefined){
            clearInterval(intervalRefresh)
        }
        let td = new URLSearchParams(window.location.search).get("td");

        intervalRefresh = setInterval(()=>{
            if(td !== null){
                this.props.navigate("/GrapeNFT/Catalog")
            }
            ToastGNFT.fire({
                icon: 'info',
                title: 'Refreshing catalog ...'
            })
            this.getAllBrands()
            this.getAllPacks()
        },60000)
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    getAllBrands(){
        Axios.post(url+"getBrands",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.resultat === "done") {
                this.setState({
                    brands:response.data.brands
                })
            }
        })
    }

    getAllPacks(){
        Axios.post(url+"getPacks",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.resultat === "done") {
                let packsTemp = response.data.packs
                packsTemp.forEach(pack => {
                    pack.variation = (Math.floor(Math.random() * 5)+1)
                    pack.type = "bottle"
                })
                this.setState({
                    packs:packsTemp,
                    disableInstantBuy:false
                },()=>{
                    let td = new URLSearchParams(window.location.search).get("td");
                    if(td !== null) {
                        /*Get the object where idToken equals td in this.state.packs*/
                        let item = this.state.packs.find(item => item.idToken === td)
                        if(item !== undefined && item.allSold === false){
                            this.openOnePack(item)
                            if(!this.state.connected){
                                global.emitter.emit("askConnectionGNFT",{disableOutsideClick:false})
                            }
                        }else{
                            SwalDefault.fire({
                                title:"Too late !",
                                text:"This bottle has been sold.",
                                icon:"error",
                                customClass:{
                                    ...swalCustomClasses,
                                    popup:!this.state.showBrowserView && "swalGNFT-mobile"
                                },
                                target:""
                            })
                            this.props.navigate("/GrapeNFT/Catalog")
                        }
                    }
                })
            }
        })
    }

    searchCatalog(event){
        event.preventDefault()
        let toSearch = event.target[0].value === this.state.toSearch ? "" : event.target[0].value
        if(event.target[0].value === this.state.toSearch){
            let inputResearch = document.getElementsByClassName("inputResearch")
            inputResearch[0].value = ""
        }
        this.setState({toSearch})
    }

    openOnePack(pack,refresh){
        /*Appeler l'axios getOnePack avec data : id du pack*/
        this.setState({previousScrollPos : window.scrollY})
        Axios.post(url+"getOnePack", {idTokenPack:pack.idToken},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            let prepaymentPresent = false
            let allBottles = response.data.bottles
            allBottles.forEach(bottle => {
                if(bottle.prepayment && bottle.prepayment === jwtDecoded.empreinte){
                    prepaymentPresent = true
                }
            })
            if(response.data.pack.allSold && !prepaymentPresent){
                /*fire error Swal*/
                SwalDefault.fire({
                    title:"Too late !",
                    text:"This bottle has been sold.",
                    icon:"error",
                    customClass:{
                        ...swalCustomClasses,
                        popup:!this.state.showBrowserView && "swalGNFT-mobile"
                    },
                    target:""
                })
                this.getAllPacks()
                this.getAllBrands()
                return;
            }
            let bottleInPack = []
            if(response.data.resultat === "done") {
                bottleInPack = JSON.parse(JSON.stringify(response.data.bottles))
            }
            pack.bottleInPack = bottleInPack
            this.setState({packSelected:pack},()=>{
                window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                if (refresh){
                    ToastGNFT.fire({
                        icon: 'success',
                        title: "Bottle refreshed !"
                    })
                    setTimeout(()=>{
                        this.setState({refreshingData:false})
                    },1000)
                }
            })
        })
    }

    createOrderPaypal(){
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        this.setState({disableInstantBuy:true})
        if(!this.state.connected){
            global.emitter.emit("askConnectionGNFT",{disableOutsideClick:false})
            this.setState({disableInstantBuy:false})
            return
        }
        this.setState({displayLoading:true})
        return new Promise((resolve, reject) => {
            Axios.post(url+"reserveBottle",{idTokenBottle:this.state.paypalPaymentBottle.idToken, idTokenPack:this.state.packSelected.idToken, price:this.state.paypalPaymentBottle.price},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                this.setState({displayLoading:false})
                if(response.data.resultat === "done") {
                    resolve(response.data.orderPaypalID)
                }else{
                    SwalDefault.fire({
                        title:"Error",
                        text:response.data.resultat,
                        icon:"error",
                        customClass:{
                            ...swalCustomClasses,
                            popup:!this.state.showBrowserView && "swalGNFT-mobile"
                        }
                    }).then(()=>{
                        this.setState({disableInstantBuy:false,paypalPaymentBottle:null, packSelected:null})
                    })
                    reject(response.data.resultat)
                }
            })
        })
    }

    //Vérifie si on doit display ou non le pack en fonction de la recherche client
    checkSearch(item){
        if (this.state.toSearch && this.state.toSearch !== ""){
            let toSearchLow = this.state.toSearch.toLowerCase()
            // Si le texte recherché est présent dans une des conditions : on return true
            const inName = (item.name.toLowerCase()).includes(toSearchLow)
            const isBottle = item.type !== "visit"
            const inBrandName = isBottle ? ((this.state.brands[this.state.brands.findIndex(elt => elt.idToken === item.brand)].nom).toLowerCase()).includes(toSearchLow):false
            return (inName || inBrandName)
        }else{
            return true
        }
    }

    closePack(){
        this.setState({packSelected:null})
        let td = new URLSearchParams(window.location.search).get("td")
        if(td){
            this.props.navigate("/GrapeNFT/Catalog")
        }
        window.scrollTo({top: this.state.previousScrollPos, left: 0, behavior: 'instant'});
    }

    copyPackToClipboard(){
        let url = window.location.origin+"/GrapeNFT/Catalog?td="+this.state.packSelected.idToken
        navigator.clipboard.writeText(url)
        SwalDefault.fire({
            title:"Link copied !",
            text:"You can now share this bottle.",
            icon:"success",
            customClass:{
                ...swalCustomClasses,
                popup:!this.state.showBrowserView && "swalGNFT-mobile"
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", flexDirection:"column" ,height:"100%",width:"100%"}}>
                    <div onClick={()=>{this.closePack()}} style={{display:!this.state.packSelected && "none", alignItems:"flex-start"}} className={"backdrop-dialog-catalog-GNFT"}/>
                    <Backdrop
                        sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: 99999, alignItems:"flex-start"}}
                        open={this.state.displayLoading}
                    >
                        <div className={"flexCenter"} style={{flexDirection:"column", marginTop:"45vh"}}>
                            <div className={"loaderTextGNFT ChampFont"}>Loading</div>
                            <h2 className={"ChampFont"} style={{color:"#FFBB41FF"}}>Please wait a few seconds</h2>
                        </div>
                    </Backdrop>
                    <div id={"dialog-catalog-GNFT"} style={{width:!this.state.showBrowserView && "100%", overflow:"auto", position:!this.state.showBrowserView && "inherit", height:!this.state.showBrowserView && "100%", marginTop:!this.state.showBrowserView && 70, left:!this.state.showBrowserView && 0,flexDirection:"column",opacity:this.state.packSelected ? 1:0, transform:this.state.packSelected ? "scale(1)":"scale(0)", boxShadow:"rgb(50 50 93 / 25%) 1px 20px 60px -12px inset, rgb(0 0 0 / 30%) 0px 0px 50px 8px inset"}} className={"flexCenter dialog-catalog-GNFT"}>
                        {this.state.packSelected && (
                            <>
                                <div style={{width:"100%", height:1, position:"relative"}}>
                                    <Close onClick={()=>{this.closePack()}} style={{position:"absolute", color:"#3b3b3b", right:0, width:45, height:45, cursor:"pointer"}}/>
                                </div>
                                <div style={{width:"100%", height:1, position:"relative"}}>
                                    <div className={"flexCenter"} style={{width:"fit-content", position:"absolute", justifyContent:"flex-end", top:5, right:60}}>
                                        <button onClick={()=>{this.copyPackToClipboard()}} className={"buttonGNFT flexCenter"} style={{width:"fit-content", padding:5, marginRight:10}}>
                                            <h3 className={"ChampFont flexCenter"} style={{color:"#3b3b3b",margin:0, fontWeight:"bold"}}>Copy link <ContentCopy style={{width:25, height:25, marginLeft:10, color:"#3b3b3b"}}/></h3>
                                        </button>
                                        <button disabled={this.state.refreshingData} onClick={()=>{
                                            this.setState({refreshingData: true});
                                            this.openOnePack(this.state.packSelected,true);
                                        }} style={{padding:5, width:"fit-content", display:"flex", marginLeft:10}} className={"ChampFont buttonGNFT flexCenter"}>
                                            <span style={{color:"#3b3b3b", fontSize:20, fontWeight:"bold"}}>Refresh</span>
                                            <Refresh className={this.state.refreshingData && "animLoadingCircleGNFT"} style={{color:"#3b3b3b"}} />
                                        </button>
                                    </div>

                                </div>

                                <div className={"flexCenter"} style={{width:"100%", height:"100%", flexDirection:"column"}}>
                                    <div className={"flexCenter"} style={{width:"100%", height:this.state.paypalPaymentBottle ?"35%":"50%", flexDirection:!this.state.showBrowserView && "column", justifyContent:!this.state.showBrowserView && "flex-start"}}>
                                        <div className={"flexCenter"} style={{width:this.state.showBrowserView && "30%", height:"100%", marginTop:!this.state.showBrowserView && 30, padding:20, alignItems:"flex-start", flexDirection:"column"}}>
                                            {this.state.packSelected.type === "bottle" && (
                                                <div style={{position:"relative",height:1, width:'100%'}}>
                                                    <div className={"flexCenter"} style={{position:"absolute", width:100, height:100, top:0, left:0}}>
                                                        <img style={{maxWidth:"100%", maxHeight:"100%", filter:"brightness(1)", zIndex:9, borderRadius:5}} src={url+"GrapeNFT/"+this.state.packSelected.brand+"_image.png"} alt="brand"/>
                                                    </div>
                                                </div>
                                            )}
                                            <img style={{maxHeight:"100%", maxWidth:"100%", borderRadius:10}} src={url+"GrapeNFT/"+this.state.packSelected.idToken+"_image.png"} alt="Champagne Bottle"/>
                                        </div>
                                        <div className={"flexCenter"} style={{width:this.state.showBrowserView && "70%", height:"100%", flexDirection:"column", justifyContent:"space-around"}}>
                                            <h1 className={"ChampFont"} style={{color:"#3b3b3b", fontSize:40}}>{this.state.packSelected.name}</h1>
                                            <h2 className={"ChampFont"} style={{color:"#3b3b3b",maxWidth:"80%", fontWeight:100, maxHeight:this.state.showBrowserView && 224, overflowY:"auto"}}>
                                                {this.state.packSelected.desc}
                                            </h2>
                                        </div>
                                    </div>
                                    <div style={{width:"95%", height:2, margin:0, marginTop:10, borderBottom:"1px dashed #969696",}}/>
                                    <div onScrollCapture={()=>{this.setState({displayHelperSwipe:false})}} onClick={()=>{this.setState({displayHelperSwipe:false})}} className={"flexCenter"} style={{width:"100%", height:this.state.paypalPaymentBottle ? "63%":"48%", flexDirection:"column"}}>
                                        {this.state.paypalPaymentBottle ? (
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:this.state.showBrowserView&&"space-around", flexDirection:!this.state.showBrowserView&&"column"}}>
                                                <div className={"flexCenter"} style={{flexDirection:"column", width:"45%"}}>
                                                    <h1 className={"ChampFont"} style={{margin:10, marginBottom:30, fontWeight:100}}>You will pay for this bottle :</h1>
                                                    <div className={"flexCenter"} style={{width:"100%"}}>
                                                        <h2 className={"ChampFont"} style={{margin:10, fontWeight:100}}>Name : <span style={{fontWeight:"600"}}>{this.state.paypalPaymentBottle.name}</span></h2>
                                                        <img className={"hoverImageRecap"} onClick={()=>{this.state.paypalPaymentBottle.image && (window.open(url+"GrapeNFT/"+this.state.paypalPaymentBottle.idToken+"_image.png"))}} style={{maxWidth:65,display:"flex", marginRight:5, borderRadius:10, cursor:"pointer"}} src={url+"GrapeNFT/"+this.state.paypalPaymentBottle.idToken+"_image.png"} alt="Champagne Bottle"
                                                             onError={({ currentTarget }) => {
                                                                 currentTarget.onerror = null; // prevents looping
                                                                 console.log("error",currentTarget.src )
                                                                 currentTarget.src= require("../../assets/defaultUnknownBottle.png");
                                                             }}
                                                        />
                                                    </div>
                                                    <h2 className={"ChampFont"} style={{margin:10, fontWeight:100}}>Price : <span style={{fontWeight:"600"}}>{this.state.paypalPaymentBottle.price}</span>€</h2>

                                                </div>
                                                <div className={"flexCenter"} style={{width:!this.state.showBrowserView?"100%":"45%",height:"100%",flexDirection:"column", justifyContent:"space-between", marginBottom:10}}>
                                                    <PayPalScriptProvider options={{ "client-id": paypalPublicKey, currency: "EUR", locale:"en_US" }}>
                                                        <PayPalButton onCreateOrder={this.createOrderPaypal} onApproveOrder={()=>{this.setState({displayLoading:true})}} />
                                                    </PayPalScriptProvider>
                                                </div>
                                            </div>

                                        ):(
                                            <React.Fragment>
                                                <TableContainer style={{backgroundColor:"transparent",overflow:"auto", maxHeight:this.state.showBrowserView && 295, width:"100%", position:"relative"}}>
                                                    <div className={"flexCenter"} onClick={()=>{this.setState({displayHelperSwipe:false})}} style={{pointerEvents:"none", display:this.state.displayHelperSwipe ? (this.state.showBrowserView && "none"):"none", width:"100%", bottom:0, left:0, height:"100%", backgroundColor:"#000", opacity:0.6, position:"absolute"}}>
                                                        <h2 className={"ChampFont"} style={{color:"#FFF",pointerEvents:"none", marginLeft:20, zIndex:9999}}>
                                                            You can swipe left or right to see more
                                                        </h2>
                                                        <img className={"animSwipeGNFT"} style={{width:65, height:65,pointerEvents:"none", zIndex:9999}} src={require("../../assets/SwipeIcon.png")} alt="SwipeIcon"/>
                                                    </div>
                                                    <Table style={{marginBottom:!this.state.showBrowserView && 50}}>
                                                        <TableHead style={{backgroundColor:"transparent"}}>
                                                            <TableRow style={{backgroundColor:"transparent"}}>
                                                                {columns.map((column) => (
                                                                    <TableCell
                                                                        className={"flexCenter"}
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        style={{ minWidth: column.minWidth,maxWidth:column.maxWidth, fontWeight:"bold", backgroundColor:"transparent", display:(this.state.packSelected.type === "visit" && (column.id === "year" || column.id === "volume" || column.id === "image" || column.id === "instantBuy")) && "none" }}
                                                                    >
                                                                        <h2 className={"ChampFont"} style={{margin:5, fontWeight:"bold", textAlign:"center"}}>{column.label}</h2>
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {(this.state.packSelected.bottleInPack.sort((a, b) => {
                                                                    if(a.name && b.name){
                                                                        return a.name.localeCompare(b.name)
                                                                    }else {
                                                                        return 0
                                                                    }
                                                            }
                                                            )).map((bottle, index)=>{
                                                                return(
                                                                    <TableRow key={"bottleRow_"+index}>
                                                                        {(bottle.sold || (bottle.prepayment !== false && bottle.prepayment !== jwtDecoded.empreinte))? (
                                                                            <>
                                                                                <TableCell colSpan={1} className={"flexCenter"} key={"bottleSoldName_"+index}>
                                                                                    <h2 className={"ChampFont"} style={{margin:5, textAlign:"center", whiteSpace:"nowrap"}}>{bottle.name}</h2>
                                                                                </TableCell>
                                                                                <TableCell colSpan={1} className={"flexCenter"} key={"bottleSoldImg_"+index}>
                                                                                    <div className={"flexCenter"} style={{width:"100%"}}>
                                                                                        <img className={"hoverImageRecap"} onClick={()=>{bottle.image && (window.open(url+"GrapeNFT/"+bottle.idToken+"_image.png"))}} style={{maxWidth:65,display:"flex", marginRight:5, borderRadius:10, cursor:"pointer"}} src={url+"GrapeNFT/"+bottle.idToken+"_image.png"} alt="Champagne Bottle"
                                                                                             onError={({ currentTarget }) => {
                                                                                                 currentTarget.onerror = null; // prevents looping
                                                                                                 console.log("error",currentTarget.src )
                                                                                                 currentTarget.src= require("../../assets/defaultUnknownBottle.png");
                                                                                             }}
                                                                                        />
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell colSpan={1} className={"flexCenter"} key={"bottleSoldYear_"+index}>
                                                                                    <h2 className={"ChampFont"} style={{margin:5, textAlign:"center"}}>{bottle.year}</h2>
                                                                                </TableCell>
                                                                                <TableCell colSpan={1} className={"flexCenter"} key={"bottleSoldVolume_"+index}>
                                                                                    <h2 className={"ChampFont"} style={{margin:5, textAlign:"center"}}>{bottle.volume}L</h2>
                                                                                </TableCell>
                                                                                <TableCell colSpan={5} className={"flexCenter"} key={"bottleSoldBool_"+index}>
                                                                                    <h2 className={"ChampFont flexCenter"} style={{margin:5,fontWeight:"600", textAlign:"center"}}>{(jwtDecoded.empreinte && (bottle.owner === jwtDecoded.empreinte)) ? "You own this bottle":"This bottle has been sold"} {(jwtDecoded.empreinte && (bottle.owner === jwtDecoded.empreinte)) && <CheckCircle style={{width:25, height:25,marginLeft:5, color:"#4BB543FF"}}/>}</h2>
                                                                                </TableCell>
                                                                            </>
                                                                        ):(
                                                                            <>
                                                                                {columns.map((column)=>{
                                                                                    return(
                                                                                        <TableCell style={{display:(this.state.packSelected.type === "visit" && (column.id === "year" || column.id === "volume" || column.id === "image" || column.id === "instantBuy")) && "none" }} className={"flexCenter"} key={column.id}>
                                                                                            {column.id === 'name' &&
                                                                                                (<h2 className={"ChampFont"} style={{margin:5, textAlign:"center", whiteSpace:"nowrap"}}>{bottle.name}</h2>)
                                                                                            }
                                                                                            {(column.id === 'image' && this.state.packSelected.type === "bottle") &&
                                                                                                (
                                                                                                    <div className={"flexCenter"} style={{width:"100%"}}>
                                                                                                        <img className={"hoverImageRecap"} onClick={()=>{bottle.image && (window.open(url+"GrapeNFT/"+bottle.idToken+"_image.png"))}} style={{maxWidth:65,display:"flex", marginRight:5, borderRadius:10, cursor:"pointer"}} src={url+"GrapeNFT/"+bottle.idToken+"_image.png"} alt="Champagne Bottle"
                                                                                                             onError={({ currentTarget }) => {
                                                                                                                 currentTarget.onerror = null; // prevents looping
                                                                                                                 console.log("error",currentTarget.src )
                                                                                                                 currentTarget.src= require("../../assets/defaultUnknownBottle.png");
                                                                                                             }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                            {(column.id === 'year' && this.state.packSelected.type === "bottle") &&
                                                                                                (
                                                                                                    <h2 className={"ChampFont"} style={{margin:5, textAlign:"center"}}>{bottle.year}</h2>
                                                                                                )
                                                                                            }
                                                                                            {(column.id === 'volume' && this.state.packSelected.type === "bottle") &&
                                                                                                (
                                                                                                    <h2 className={"ChampFont"} style={{margin:5, textAlign:"center"}}>{bottle.volume}L</h2>
                                                                                                )
                                                                                            }
                                                                                            {(column.id === 'buy' && this.state.packSelected.type === "bottle") &&
                                                                                                (
                                                                                                    <div className={"flexCenter"} style={{width:"100%"}}>
                                                                                                        {jwtDecoded.empreinte && (bottle.owner === jwtDecoded.empreinte) ? (
                                                                                                            <h2 className={"ChampFont flexCenter"} style={{margin:5,fontWeight:"600", textAlign:"center"}}>You own this bottle<CheckCircle style={{width:25, height:25,marginLeft:5, color:"#4BB543FF"}}/></h2>
                                                                                                        ):(
                                                                                                            <div style={{padding:5, width:"100%", maxWidth:250, transition:"0s", display:"flex", marginLeft:5, justifyContent:"center"}}>
                                                                                                                <h2 className={"ChampFont flexCenter"} style={{margin:5, textAlign:"center"}}>{bottle.price ? bottle.price:"-"}€</h2>
                                                                                                                <Tooltip title={"Buy this bottle"}>
                                                                                                                    <button disabled={this.state.disableInstantBuy || !bottle.price} onClick={()=>{!this.state.disableInstantBuy && (this.setState({paypalPaymentBottle:bottle}))}} className={"buttonGNFT flexCenter"} style={{marginLeft:5,padding:5, width:"100%", maxWidth:50, transition:"0s", display:"flex"}}>
                                                                                                                        <ShoppingCart style={{width:35, height:35}}/>
                                                                                                                    </button>
                                                                                                                </Tooltip>
                                                                                                            </div>
                                                                                                        )}



                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </TableCell>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        )}

                                                                    </TableRow>
                                                                )
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className={"flexCenter"} style={{width:"100%", height:this.state.showBrowserView ? 80: "fit-content", marginTop:this.state.showBrowserView ? 100:20, justifyContent:this.state.showBrowserView && "space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                        <div className={"flexCenter"} style={{width:this.state.showBrowserView ? "40%":"100%", flexWrap:!this.state.showBrowserView && "wrap"}}>
                            <h2 className={"ChampFont"} style={{color:"#FFF", margin:0, whiteSpace:"nowrap", display:!this.state.showBrowserView && "none"}}>Filter : </h2>
                            <div onClick={()=>{this.setState({soldOutPacksBool:!this.state.soldOutPacksBool})}} className={"selectOptionDivGNFT"} style={{backgroundColor:this.state.soldOutPacksBool && "#FFF", width:!this.state.showBrowserView&&"100%", maxWidth:280}}>
                                <h2 className={"ChampFont flexCenter"} style={{color:this.state.soldOutPacksBool ? "#3b3b3b":"#FFF", margin:0, whiteSpace:"nowrap", userSelect:"none"}}>
                                    Display sold out packs
                                    {this.state.soldOutPacksBool ? (
                                        <Check style={{marginLeft:5}}/>
                                    ):(
                                        <Close style={{marginLeft:5}}/>
                                    )}
                                </h2>
                            </div>
                        </div>
                        <form style={{width:!this.state.showBrowserView&&"100%", maxWidth:!this.state.showBrowserView&&280, marginTop:!this.state.showBrowserView&&10, marginRight:this.state.showBrowserView && 50}} onSubmit={(e)=>{this.searchCatalog(e)}}>
                            <input className={"inputResearch ChampFont"} style={{width:!this.state.showBrowserView&&"100%", paddingLeft:!this.state.showBrowserView&&0, paddingRight:!this.state.showBrowserView&&0}} type={"text"} placeholder={"Search"}></input>
                            <div style={{position:"relative", width:"100%",display:"flex", justifyContent:"flex-end"}}>
                                <button type={"submit"} style={{position:"absolute", color:"#FFF", top:-40, right:5, backgroundColor:"transparent",fontSize:25, border:"none", cursor:"pointer"}}>
                                    {this.state.toSearch !== "" ? (
                                        <Close style={{width:35, height:35}}/>
                                    ):(
                                        <Search style={{width:35, height:35}}/>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className={"flexCenter"} style={{width:"100%", height:"100%"}}>
                        <div className={"flexCenter div-catalogue-GNFT"} style={{height:"100%", minHeight:"100vh", justifyContent:!this.state.showBrowserView && "center"}}>
                            <div className={"flexCenter"} style={{width:'100%', height:this.state.showBrowserView ? 280:"fit-content", flexDirection:"column", alignItems:"flex-start", justifyContent:"flex-start",marginBottom:!this.state.showBrowserView && 20}}>
                                <h1 className={"ChampFont"} style={{color:"#ffbb41", fontSize:40, marginBottom:5, marginTop:!this.state.showBrowserView&&0}}>Our Catalog</h1>
                                <h2 className={"ChampFont"} style={{color:"#FFF",maxWidth:"95%", fontWeight:100, display:!this.state.showBrowserView && "none"}}>
                                    At GrapeNFT, we offer an exclusive collection of NFTs of <span style={{fontWeight:"bold"}}>champagne bottles </span>. These NFTs are linked to an underlying asset of actual bottles. This means that each NFT represents a real bottle, creating a <span style={{fontWeight:"bold"}}>physical-digital linkage</span>. Through this connection, it's possible to invest in and trade these bottles safely and securely, thanks to the blockchain. This makes investing in these NFTs a secure method of owning a piece of the wine industry. Each NFT is <span style={{fontWeight:"bold"}}>certified for authenticity</span>. Invest in a unique and valuable asset today.
                                </h2>
                            </div>
                            {/*{this.state.packs.length === 0 && (*/}
                            {/*    <p>test</p>*/}
                            {/*)}*/}
                            {/*this.state.packs.map sort by name and by allSold*/}
                            {this.state.packs.sort((a, b) => {
                                if (a.allSold !== b.allSold) {
                                    return a.allSold - b.allSold;
                                } else {
                                    return a.name.localeCompare(b.name);
                                }
                            }).map((item, index) => (
                                <div onClick={()=>{if(!item.allSold){this.openOnePack(item)}}} style={{cursor:item.allSold&&"not-allowed",flexDirection:"column", display:(((!this.state.soldOutPacksBool && item.allSold) || (!this.state.categoryVisit && item.type === "visit") || (this.checkSearch(item) === false)) && "none")}} className={`flexCenter square-catalogue-GNFT ${"variation"+(true ? "3":item.variation)+"-square"}`}>
                                    <div style={{width:"100%", position:"relative"}}>
                                        <h3 className={"ChampFont"} style={{margin:20, color:item.type === "bottle" ? "#000":"#FFF", position:"absolute", textShadow:item.type === "visit" && "2px 2px #000000", zIndex:99, textAlign:"left", width:item.type === "bottle" && "60%", maxHeight:44, overflowY:"auto", fontWeight:700}}>{item.name}</h3>
                                    </div>
                                    {item.allSold && (
                                        <div style={{position:"relative", width:'100%'}}>
                                            <div className={"flexCenter"} style={{position:"absolute", width:270,height:270}}>
                                                <div style={{minWidth:400, backgroundColor:"rgba(255,0,0,0.6)", transform:"rotate(-45deg)", zIndex:10, textAlign:"center" }}>
                                                    <h1 className={"ChampFont"} style={{color:"#FFF", fontSize:25, margin:5, fontWeight:1000}}>Sold out</h1>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {item.type === "bottle" && (
                                        <div style={{position:"relative", width:'100%'}}>
                                            {/*<img onLoad={()=>{this.setState({["imgLoadedBrand_"+index]:true})}} style={{width:"30%", filter:"brightness(1)", position:"absolute", right:5, top:5, maxHeight:50, display:this.state["imgLoadedBrand_"+index] ? "flex":"none"}} src={url+"GrapeNFT/"+item.brand+"_image.png"} alt="brand"/>*/}
                                            <img style={{width:"30%", filter:item.allSold && "grayscale(0.3)",zIndex:9, position:"absolute", borderRadius:5, right:5, top:5,display:this.state["imgLoadedBrand_"+index] ? "none":"flex"}} src={url+"GrapeNFT/"+item.brand+"_imagelowres.png"} alt="brand"/>
                                        </div>
                                    )}
                                    <div className={"flexCenter"} style={{height:"100%", position:"relative", width:"100%"}}>
                                        {item.type === "bottle" && (
                                            <>
                                                {/*<img onLoad={()=>{this.setState({["imgLoaded_"+index]:true})}} style={{height:"100%", position:"absolute", transform:"scale(1.2)", marginTop:55, display:this.state["imgLoaded_"+index] ? "flex":"none"}} src={url+"GrapeNFT/"+item.idToken+"_image.png"} alt="Champagne Bottle"/>*/}
                                                <img style={{maxHeight:"100%", maxWidth:"100%",filter:item.allSold && "grayscale(0.3)", position:"absolute", transform:"scale(1)",display:this.state["imgLoaded_"+index] ? "none":"flex"}} src={url+"GrapeNFT/"+item.idToken+"_imagelowres.png"} alt="Champagne Bottle"/>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <SharedFooterGNFT/>
            </>

        );
    }
}

export default withRouter(CatalogueGNFT)