import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {Backdrop, Button, CircularProgress} from '@mui/material';
import {isMobile} from "react-device-detect";

import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import {
    AccessTimeFilled,
    AccountCircle,
    CheckCircle,
    CheckCircleOutline,
    Description,
    Downloading,
    ForwardToInbox,
    GetApp,
    Info
} from "@mui/icons-material";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

// let socketIO //Initialisation de l'instance du socket

class PreuveViaMail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            donnee:"",
            openLoad:true,
            validateurs:[],
            hashFile:"",
            showBrowserView:true,
            downloadFinished:true,
            progressDownloadFile:0,
            showSaveFilePickerCanceled:false,
            isAnalyzing:false,
            showDetail:false,
            secondsDownload:3,
            displayDownloadTwice:false,
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        const queryParams = new URLSearchParams(window.location.search);
        if(!!queryParams.get("idToken") && !!queryParams.get("p")){
            this.setState({
                openLoad:false,
                idToken:queryParams.get("idToken"),
                labelR:queryParams.get("p")
            },()=>{
                Axios.post(url+"preuveViaMail",{
                    idToken:queryParams.get("idToken"),
                    labelR:queryParams.get("p")
                }).then(response=>{
                    this.setState({resultat:response.data.resultat})
                    if (response.data.resultat === "Ce document est enregistré dans notre blockchain."){
                        this.setState({
                            donnee:response.data.resultat,
                            hashFile:response.data.hashFile,
                            ownerEmpreinte:response.data.ownerEmpreinte,
                            ownerPseudo:response.data.ownerPseudo,
                            fileName:response.data.fileName,
                            uploadDate:response.data.uploadDate,
                            fileSize:response.data.fileSize
                        })
                        if(response.data.fileSize !== 0){
                            this.downloadFile()
                        }else{
                            this.setState({showDetail:true})
                        }
                    }else{
                        Swal.fire({
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                    global.emitter.emit("onRouteChangedKageSecur",{
                        user:"none",
                        pseudo:""
                    })
                }).catch(err=>{
                    console.log(err)
                })
            })
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    transfert(){
        Swal.fire({
            title: 'Envoyer la preuve du fichier par mail',
            html:
                '<p style="margin-bottom: 0px; display: flex;align-items: flex-start;font-weight: bold" >Nom du récepteur</p>' +
                '<input id="swal-input1" class="swal2-input">' +
                '<p style="margin: 0px; display: flex;align-items: flex-start;font-weight: bold" >Email du récepteur</p>' +
                '<input id="swal-input2" class="swal2-input">' +
                '<p style="margin: 0px; display: flex;align-items: flex-start;font-weight: bold" >Email pour accusé de réception <span style="font-weight: normal; margin-left: 3px"> (optionnel)</span></p>' +
                '<input id="swal-input3" class="swal2-input">',
            focusConfirm: false,
            confirmButtonText:"Envoyer",
            showCancelButton: true,
            cancelButtonText:"Annuler",
            preConfirm: () => {
                if(document.getElementById('swal-input2').value && document.getElementById('swal-input1').value && (document.getElementById('swal-input2').value).match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)){
                    Axios.post(url+"retransfertPreuveViaMail",{
                        idToken:this.state.idToken,
                        mail:document.getElementById('swal-input2').value,
                        labelEmetteur:this.state.labelR,
                        labelRecepteur:document.getElementById('swal-input1').value,
                        emailEmetteur:document.getElementById('swal-input3').value ? document.getElementById('swal-input3').value:undefined,
                        fileName:this.state.fileName
                    }).then(response=>{
                        if(response.data.resultat === "Impossible de trouver le document."){
                            Toast.fire({
                                icon: 'warning',
                                title: 'Une erreur est survenue, ce fichier ne vous appartient pas.'
                            })
                        }else{
                            Toast.fire({
                                icon: 'success',
                                title: 'Email correctement envoyé.'
                            })
                        }
                    })
                }else{
                    Swal.fire({
                        text: "Veuillez remplir les informations demandées correctement.",
                        icon: 'warning',
                        confirmButtonText: "Ok"
                    }).then(()=>{
                        this.transfert()
                    })
                }
            }
        })
    }

    dlCertif(){
        Axios.post(url+"getVltCerficate",{
            hashFile:this.state.hashFile,
            fileName:this.state.fileName,
        },{responseType:"arraybuffer"}).then(response => {
            let file = new Blob([response.data], {type: 'application/pdf'});
            this.setState({fileSaved:file})
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = "attestation_KAGESECUR";
            link.click();
        }).catch((err)=>{
            console.log("Erreur")
        })
    }

    downloadFile(){
        if(this.state.secondsDownload !== 0){
            setTimeout(()=>{
                this.setState({secondsDownload:this.state.secondsDownload-1})
                this.downloadFile()
            },1000)
        }else{
            window.open(`${url}downloadVlt?idToken=${this.state.idToken}&labelR=${this.state.labelR}&fileName=${this.state.fileName.split("##")[0]}`)
            this.setState({displayDownloadTwice:true})
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Recherche en cours, veuillez patienter quelques instants ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
                <div style={{textAlign:"center",marginTop:90,height:"calc(100%-90px)",width:"99%",display:"flex",flexDirection:"column", alignItems:"center"}}>
                    {this.state.showBrowserView && (
                        <>
                            <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110}} src={require("../../assets/document.png")} alt="imageDocument"/>
                            <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20}} src={require("../../assets/auction.png")} alt="imageAuction"/>
                        </>
                    )}
                    <div style={{marginTop:90,display:"flex", width:"100%",height:"70%", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                        {this.state.resultat !=="Ce document n'est pas enregistré dans la blockchain." ? (
                            <>
                                {this.state.showDetail === false ? (
                                    <>
                                        <h1> <span style={{color:"#52a8d9"}}>Télécharger</span> votre <span style={{color:"#f18f29"}}>document</span></h1>

                                        <div className={"divPVM"} style={{width:!this.state.showBrowserView&&"90%", height:"fit-content"}}>
                                            <div className={"flexCenter"} style={{position:"relative", width:"100%", flexDirection:"column", height:"100%"}}>
                                                {this.state.secondsDownload === 0 ? (
                                                    <CheckCircleOutline style={{width:130,height:130}}/>
                                                ):(
                                                    <Downloading style={{width:130,height:130}}/>
                                                )}

                                                <p style={{fontWeight:100, fontStyle:"italic"}}>{this.state.fileName}</p>
                                                {this.state.secondsDownload !== 0 && (
                                                    <p style={{marginTop:10, fontWeight:"bold"}}>Votre téléchargement va démarrer dans {this.state.secondsDownload}s</p>
                                                )}
                                                {this.state.displayDownloadTwice && (
                                                    <p onClick={()=>{window.open(`${url}downloadVlt?idToken=${this.state.idToken}&labelR=${this.state.labelR}&fileName=${this.state.fileName}`)}} style={{fontWeight:400, cursor:"pointer", textDecoration:"underline", margin:0, fontSize:18, color:"#0000d5"}}>Cliquez ici si votre téléchargement n'a pas démarré</p>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{flexDirection:!this.state.showBrowserView&&"column"}} className={"flexCenter"}>
                                            <Button onClick={()=>{this.setState({showDetail:true})}} style={{backgroundColor:"#369dd7",padding:9, color:"white",width:"fit-content", fontWeight:"bold", margin:5, textTransform:"none"}}>Afficher les détails</Button>
                                            <Button onClick={()=>{this.transfert()}} style={{backgroundColor:"#369dd7",padding:9, color:"white",width:"fit-content", fontWeight:"bold", margin:5, textTransform:"none"}}>Transférer ce fichier <ForwardToInbox style={{marginLeft:5}}/></Button>
                                        </div>
                                        <Button onClick={()=>{this.dlCertif()}} style={{backgroundColor:"#369dd7",padding:9, color:"white",width:"fit-content", fontWeight:"bold", margin:5, textTransform:"none"}}>Télécharger le certificat d'authenticité <GetApp style={{marginLeft:5}}/></Button>

                                    </>
                                ):(
                                    <>
                                        <h1> <span style={{color:"#52a8d9"}}>Informations</span> sur votre <span style={{color:"#f18f29"}}>document</span></h1>
                                        <div style={{width:!this.state.showBrowserView&&"100%"}} className={"flexCenter"}>
                                            <div style={{flexDirection:"column",width:!this.state.showBrowserView&&"100%"}} className={"flexCenter"}>
                                                <div className={"divPVM"} style={{width:!this.state.showBrowserView&&"90%"}}>
                                                    <div style={{position:"relative", width:"100%", display:"flex",alignItems:"flex-start", flex:1}}>
                                                        <Description style={{position:"absolute"}}/>
                                                    </div>
                                                    <div className={"flexCenter"} style={{position:"relative", width:"100%",justifyContent:"space-evenly", flexDirection:"column", height:"100%"}}>
                                                        <h2 style={{marginBottom:5}}>Document </h2>
                                                        <p>Nom : <span style={{fontWeight:"bold"}}>{this.state.fileName}</span> </p>
                                                        <p style={{cursor:"pointer"}} onClick={() => {
                                                            navigator.clipboard.writeText(this.state.hashFile)
                                                            Toast.fire({
                                                                icon: 'info',
                                                                title: 'Hash copié dans le presse-papier !'
                                                            })
                                                        }}>Hash : <span style={{fontWeight:"bold"}}>{this.state.hashFile.slice(0,4)}...{this.state.hashFile.slice(-4)}</span> </p>
                                                    </div>

                                                </div>
                                                <div className={"divPVM"} style={{width:!this.state.showBrowserView&&"90%"}}>
                                                    <div style={{position:"relative", width:"100%", display:"flex", flex:1,alignItems:"flex-start"}}>
                                                        <AccountCircle style={{position:"absolute"}}/>
                                                    </div>
                                                    <div className={"flexCenter"} style={{position:"relative", width:"100%",justifyContent:"space-evenly", flexDirection:"column", height:"100%"}}>
                                                        <h2 style={{marginBottom:20}}>Possesseur </h2>
                                                        {this.state.ownerPseudo && (
                                                            <p>Identifiant : <span style={{fontWeight:"bold"}}>{this.state.ownerPseudo}</span> </p>
                                                        )}
                                                        {this.state.ownerEmpreinte && (
                                                            <p style={{cursor:"pointer"}} onClick={() => {
                                                                navigator.clipboard.writeText(this.state.ownerEmpreinte)
                                                                Toast.fire({
                                                                    icon: 'info',
                                                                    title: 'Empreinte copiée dans le presse-papier !'
                                                                })
                                                            }}>Empreinte : <span style={{fontWeight:"bold"}}>{this.state.ownerEmpreinte.slice(0,4)}...{this.state.ownerEmpreinte.slice(-4)}</span> </p>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{flexDirection:"column",width:!this.state.showBrowserView&&"100%"}} className={"flexCenter"}>
                                                <div className={"divPVM"} style={{width:!this.state.showBrowserView&&"90%"}}>
                                                    <div style={{position:"relative", width:"100%",flex:1, display:"flex",alignItems:"flex-start"}}>
                                                        <AccessTimeFilled style={{position:"absolute"}}/>
                                                    </div>
                                                    <div className={"flexCenter"} style={{position:"relative", width:"100%",justifyContent:"space-evenly", flexDirection:"column", height:"100%"}}>
                                                        <h2 style={{marginBottom:15}}>Enregistrement </h2>
                                                        {this.state.uploadDate && (
                                                            <>
                                                                <p >Date : <span style={{fontWeight:"bold"}}>{this.state.uploadDate.split("à")[0]}</span>  </p>
                                                                <p>Horaire : <span style={{fontWeight:"bold"}}>{this.state.uploadDate.split("à ")[1]} UTC</span>  </p>
                                                            </>
                                                        )}
                                                    </div>

                                                </div>
                                                <div className={"divPVM"} style={{width:!this.state.showBrowserView&&"90%"}}>
                                                    <div style={{position:"relative", width:"100%",flex:1, display:"flex",alignItems:"flex-start"}}>
                                                        <Info style={{position:"absolute"}}/>
                                                    </div>
                                                    <div className={"flexCenter"} style={{position:"relative", width:"100%", flexDirection:"column", height:"100%"}}>
                                                        <h2 style={{marginBottom:5}}>Statut </h2>
                                                        {this.state.donnee === "Ce document est enregistré dans notre blockchain." && (
                                                            <>
                                                                <CheckCircle  style={{color:"#08de00", width:85,height:85}}/>
                                                                <p style={{marginTop:10, fontWeight:"bold"}}>{this.state.donnee}</p>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.fileSize === 0 && (
                                            <p>Ce document n'est pas archivé et n'est donc <span style={{fontWeight:"bold"}}>pas téléchargeable</span> </p>
                                        )}
                                        <div style={{flexDirection:!this.state.showBrowserView&&"column"}} className={"flexCenter"}>
                                            {this.state.fileSize !== 0 && (
                                                <Button onClick={()=>{this.setState({showDetail:false})}} style={{backgroundColor:"#369dd7",padding:9, color:"white",width:"fit-content", fontWeight:"bold", margin:5, textTransform:"none"}}>Cacher les détails</Button>
                                            )}
                                            <Button onClick={()=>{this.transfert()}} style={{backgroundColor:"#369dd7",padding:9, color:"white",width:"fit-content", fontWeight:"bold", margin:5, textTransform:"none"}}>Transférer ce{this.state.fileSize === 0 ? "tte preuve":" fichier"} <ForwardToInbox style={{marginLeft:5}}/></Button>
                                        </div>
                                        <Button onClick={()=>{this.dlCertif()}} style={{backgroundColor:"#369dd7",padding:9, color:"white",width:"fit-content", fontWeight:"bold", margin:5, textTransform:"none"}}>Télécharger le certificat d'authenticité <GetApp style={{marginLeft:5}}/></Button>
                                    </>
                                )}
                            </>
                        ):(
                            <>
                                <h1>Une erreur est survenue !</h1>
                                <h2>Aucun fichier correspondant à ces informations n'a été trouvé dans la blockchain.</h2>
                                <p style={{fontStyle:"italic"}}>Vérifiez de ne pas avoir modifié l'url involontairement, si le problème persiste, essayez de redemander une preuve.</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PreuveViaMail)