import React, {Component} from 'react';
import {sha256} from 'js-sha256';
import {Button, TextField} from '@mui/material';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import config from "../../../json/config.json";
import Swal from "sweetalert2";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
//Initialisation variables à envoyer dans la requête Axios (vers l'smartContracts inscription)
let pseudo, email, clef, hash;

class EkocoInscription extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    handleValidation(email, clef,pseudo){ //Fonction servant a la vérification des données avant l'envoi du formulaire
        if(clef !== "" && pseudo !== ""){
            return true
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Les données saisies sont incorrectes.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return false
        }
    }
    handleChange(event){ //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }
    handleSubmit(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        const data= new FormData(event.target);
        pseudo = data.get('champsPseudo'); email = data.get('champsEmail'); clef = data.get('champsClef');
        event.preventDefault();
        Swal.fire({
            icon:"info",
            text:"Il est primordial de ne pas perdre votre clef unique.\nIl ne s'agit pas d'un mot de passe, mais d'une clef de preuve.\nNous ne pourrons pas vous aider à récupérer l'accès à votre compte dans le cas où vous l'auriez perdu.",
            input: 'checkbox',
            inputValue: 0,
            inputPlaceholder:
                "J'ai bien compris l'importance de ma clef unique.",
            confirmButtonText:
                "Continuer",
            inputValidator: (result) => {
                return !result && 'Vous devez accepter les conditions !'
            }
        }).then(()=> {
            if (() => {
                this.handleValidation(email, clef, pseudo)
            }) {
                hash = sha256(pseudo.concat(clef)) //Hashage avec protocole sha256
                Axios.post(url+"ekocoInscription", {
                    hash: hash,
                    pseudo: pseudo,
                    email: email,
                    clefToEncrypt:clef
                }).then(response => {
                    if (response.data.resultat === 'Compte créé !') {
                        Swal.fire({
                            title: 'Succès!',
                            text: "Votre compte à bien été créé!",
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                        this.renderRedirect();
                    } else {
                        Swal.fire({
                            title: 'Erreur!',
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        })
    }
    renderRedirect(){
        sessionStorage.setItem('EKOCO_pseudo', pseudo);
        sessionStorage.setItem('EKOCO_hash', hash);
        this.props.navigate("/Ekoco/BasCarboneBlockchain")
    }
    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex", justifyContent:"center", alignItems:"center",width:"100vw", height:"100vh"}}>
                <div id={"connectionEkoco"}>
                    <h1>Inscription Ekoco</h1>
                    <form onSubmit={(e)=>{this.handleSubmit(e)}} noValidate autoComplete="off">
                        <div className={"inputHelpDiv"}>
                            <TextField name={"champsPseudo"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Pseudo" variant="outlined"/>
                        </div>
                        <div className={"inputHelpDiv"}>
                            <TextField name={"champsEmail"} className={"champsTexte"} value={this.state.value} type={"email"} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsEmail" label="Email" variant="outlined" />
                        </div>
                        <div className={"inputHelpDiv"}>
                            <TextField name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="champsClef" label="Clef Unique" variant="outlined" />
                        </div>
                        <Button type={"submit"} variant="contained">S'inscrire </Button>
                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(EkocoInscription);