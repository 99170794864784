import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const columnsListeGroupes = [
    { id: 'groupName', label: 'Nom du groupe', minWidth: "35%" },
    { id: 'groupDescription', label: 'Description', minWidth: "50%" },
    { id: 'nombreClients', label: 'Nombre de membres', minWidth: "10%" },
    { id: 'votesActifs', label: 'Votes Actifs', minWidth: "5%" },
];

class MesGroupesClient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            listAllGroups:[],
        };
    }

    setInitialStates(){
        return{
            answerVoteDialog:false,
            selectedVote:{questions:[],reponses:[]},
            reponseArray:[]
        }
    }

    componentDidMount() {
        this.setState({
            hash: sessionStorage.getItem("SUFFRAGE_hash"),
            pseudo: sessionStorage.getItem("SUFFRAGE_pseudo"),
            jwt: sessionStorage.getItem("SUFFRAGE_jwt")
        },  () => {
            this.checkConnexion()
        })
    }

    checkConnexion(){
        Axios.post(url+"connexionSuffrage",{
            empreinte:this.state.hash,
            hashJwt:this.state.hash
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "Connecté"){
                global.emitter.emit("onRouteChangedSuffrage",{
                    user:response.data.gestionnaire ? "gestionnaire":"client",
                    pseudo:sessionStorage.getItem("SUFFRAGE_pseudo"),
                    nom:response.data.nom,
                    prenom:response.data.prenom
                })
                this.getAllMyGroups()
            }else{
                Toast.fire({
                    title: 'Utilisateur introuvable.',
                    icon: 'error',
                })
                window.location.replace("/Suffrage/Accueil")
            }
        }).catch(()=>{
            Swal.fire({
                title: 'Erreur!',
                text: "Serveur injoignable.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    getAllMyGroups(){
        Axios.post(url + "getClientGroups", {
            empreinte: sessionStorage.getItem("SUFFRAGE_hash"),
            hashJwt: sessionStorage.getItem("SUFFRAGE_hash")
        }, {headers: {authorization: "Bearer " + this.state.jwt}}).then(response => {
            if (response.data.resultat === 'done') {
                this.setState({listAllGroups: response.data.clientGroups})
            } else {
                Toast.fire({
                    title: "Vous n'êtes pas autorisé à accéder à ces données.",
                    icon: 'error',
                })
            }
        }).catch(error => {
            console.log(error)
        });
    }


    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%",overflow:"auto", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <div style={{overflowY:"auto",maxHeight:"60vh",height:"40vh",width:"70vw",minWidth:"800px",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"flex-start",alignItems:"center",borderRadius:8,boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}}>
                        <div style={{width:"100%",justifyContent:"center",display:"flex",alingItems:"center",color:"#FFFFFF", backgroundColor:"#6600ff"}}><h2 style={{margin:5}}>Liste des groupes auquel vous appartenez</h2></div>
                        <TableContainer style={{overflowY:"auto"}}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {columnsListeGroupes.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth,textAlign:"center", fontWeight:"bold"}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.listAllGroups.map((row) => {
                                        return (
                                            <TableRow style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columnsListeGroupes.map((column) => {
                                                    return (
                                                        <>
                                                            <TableCell style={{textAlign:"center"}} key={column.id}>
                                                                {row[column.id]}
                                                            </TableCell>
                                                        </>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(MesGroupesClient)