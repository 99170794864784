import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {Button, IconButton, InputAdornment, TextField, Tooltip} from '@mui/material';
import {isBrowser} from "react-device-detect";
import {sha256} from "js-sha256";

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import tooltipContenu from "../../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const useStyles = {
    header:{
        height: 70,
        width:"100vw",
        backgroundColor:"#337ab7",
        textAlign:"center",
        boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)',
        zIndex:10
    },
    containerTableBrowser:{
        minWidth:600
    },
    containerTableMobile:{
        minWidth:350,
    }
};

class InscriptionApprobateurKS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identifiantValue : "",
            passwordValue : "",
            emailValue:"",
            verifValue:"",
            passwordBisValue:"",
            refCode:null,
            refCodeAuth:false
        };
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        if(queryParams.get("ref")){
            this.setState({refCode:queryParams.get("ref")})
        }
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    submitCreation(e){
        e.preventDefault()
        Swal.fire({
            icon:"info",
            text:"Il est primordial de ne pas perdre votre clef unique.\nIl ne s'agit pas d'un mot de passe, mais d'une clef de preuve.\nNous ne pourrons pas vous aider à récupérer l'accès à votre compte dans le cas où vous l'auriez perdu.",
            input: 'checkbox',
            inputValue: 0,
            inputPlaceholder:
                "J'ai bien compris l'importance de ma clef unique.",
            confirmButtonText:
                "Continuer",
            inputValidator: (result) => {
                return !result && 'Vous devez accepter les conditions !'
            }
        }).then(()=> {
            let hash = sha256((this.state.identifiantValue.trim()).concat(this.state.passwordValue.trim())) //Hashage avec protocole sha256
            if(this.state.passwordValue.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!.@#$%^&*?-])[A-Za-z\d!.@#$%^&*?-]{12,}$/)) {
                if((this.state.identifiantValue.trim()).match(/^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{4,29}$/)){
                    if(this.state.passwordBisValue.trim() === this.state.passwordValue.trim()){
                        Axios.post(url+"addValidateur", {
                            empreinte : hash,
                            Identifiant:this.state.identifiantValue.trim(),
                            Email : this.state.emailValue,
                            clefToEncrypt:this.state.passwordValue.trim(),
                            refCode:this.state.refCode,
                        }).then(response => {
                            if (response.data.resultat === 'Compte créé !'){
                                Swal.fire({
                                    title: 'Succès!',
                                    text: "Le compte "+this.state.identifiantValue+" a bien été créé!",
                                    icon: 'success',
                                    confirmButtonText: 'Ok'
                                }).then(()=>{
                                    sessionStorage.removeItem("KAGESECUR_hash")
                                    sessionStorage.removeItem("KAGESECUR_pseudo")
                                    sessionStorage.removeItem("KAGESECUR_jwt")
                                    this.props.navigate("/KageSecur/Accueil")
                                })
                            }else{
                                Swal.fire({
                                    title: 'Erreur!',
                                    text: response.data.resultat,
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                })
                            }
                        }).catch(error => {
                            console.log(error);
                        });
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Vos deux clés uniques ne correspondent pas.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Votre identifiant ne peut contenir que des lettres de a-z, des chiffres de 0-9, des tiret bas (_) et des points.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: "Votre clef unique ne respecte pas les critères de sécurité.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex",minHeight:"100vh",width:"100%", justifyContent:"center",alignItems:"center"}}>
                    <div style={{overflowY:"auto",minHeight:"30vh",backgroundColor:"white",flexDirection:"column",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <h4>Entrez vos informations personnelles</h4>
                        <form autoComplete="off" onSubmit={(e)=>{this.submitCreation(e)}} style={{display:"flex",flexDirection:"column",width:"85%", marginBottom:20}}>

                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField style={{width:"100%", marginBottom:10}} required={true} name={"identifiantValue"} onChange={(event)=>{this.handleChange(event)}} label="Identifiant" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <p style={{margin:0, padding:0,fontSize:13}}>Règles de sécurité - Votre identifiant : </p>
                            <p style={{margin:0, padding:0, fontSize:11}}>- ne doit <span style={{fontWeight:"bold"}}> pas contenir d'espace </span></p>
                            <p style={{margin:0, padding:0, fontSize:11}}>- peut contenir <span style={{fontWeight:"bold"}}> des "_" et des "." </span> sauf en fin d'identifiant </p>
                            <p style={{margin:0, padding:0, fontSize:11, marginBottom:8}}>- doit contenir <span style={{fontWeight:"bold"}}> au moins 5 caractères </span> </p>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField style={{width:"100%", marginBottom:10}} required={true} type={"email"} name={"emailValue"} onChange={(event)=>{this.handleChange(event)}} label="Email" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField autoComplete="new-password" style={{width:"100%", marginBottom:10}} required={true} type={this.state.showPassword ? "text":"password"} name={"passwordValue"} onChange={(event)=>{this.handleChange(event)}} label="Clef Unique" variant="outlined"
                                           InputProps={{
                                               endAdornment:
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           aria-label="toggle password visibility"
                                                           onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                       >
                                                           {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                       </IconButton>
                                                   </InputAdornment>
                                           }}
                                />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <p style={{margin:0, padding:0,fontSize:13}}>Règles de sécurité - Votre clef unique doit contenir : </p>
                            <p style={{margin:0, padding:0, fontSize:11}}>- au moins une <span style={{fontWeight:"bold"}}> majuscule et minuscule</span></p>
                            <p style={{margin:0, padding:0, fontSize:11}}>- au moins un <span style={{fontWeight:"bold"}}> chiffre </span></p>
                            <p style={{margin:0, padding:0, fontSize:11}}>- au moins un <span style={{fontWeight:"bold"}}> symbole (!.@#$%^&*?-)</span></p>
                            <p style={{margin:0, padding:0, fontSize:11}}>- aucun <span style={{fontWeight:"bold"}}> espace </span></p>
                            <p style={{margin:0, padding:0, fontSize:11, marginBottom:8}}>- au moins 12 <span style={{fontWeight:"bold"}}> caractères </span></p>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField autoComplete="new-password" style={{width:"100%", marginBottom:10}} required={true} type={this.state.showPasswordVerif ? "text":"password"} name={"passwordBisValue"} onChange={(event)=>{this.handleChange(event)}} label="Confirmez votre Clef Unique" variant="outlined"
                                           InputProps={{
                                               endAdornment:
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           aria-label="toggle password visibility"
                                                           onClick={()=>{this.setState({showPasswordVerif:!this.state.showPasswordVerif})}}
                                                       >
                                                           {this.state.showPasswordVerif ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                       </IconButton>
                                                   </InputAdornment>
                                           }}
                                />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <Button type={"submit"} style={{marginTop:10}} variant="outlined" >Créer mon compte<ChevronRightIcon/></Button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(InscriptionApprobateurKS)