import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {isBrowser} from "react-device-detect";

import Axios from "axios";
import config from "../json/config.json";
import {
    Button,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import tooltipContenu from "../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {sha256} from "js-sha256";
import Swal from "sweetalert2";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const columnsLAI = [
    { id: 'action', label: "Action", minWidth: 0 },
    { id: 'empreinteRecepteur', label: "Récepteur", minWidth: 0 },
    { id: 'valueLoaned', label: 'Token prêtés', minWidth: 0 },
    { id: 'valuePayBack', label: 'Token dûs', minWidth: 0 },
    { id: 'failurePayBack', label: 'Compensation', minWidth: 0 },
    { id: 'timeToPayBack', label: 'Date limite', minWidth: 0 },
];
const columnsLAR = [
    { id: 'action', label: "Action", minWidth: 0 },
    { id: 'empreinteInitiateur', label: "Initiateur", minWidth: 0 },
    { id: 'valueLoaned', label: 'Token prêtés', minWidth: 0 },
    { id: 'valuePayBack', label: 'Token dûs', minWidth: 0 },
    { id: 'failurePayBack', label: 'Compensation', minWidth: 0 },
    { id: 'timeToPayBack', label: 'Date limite', minWidth: 0 },
];const columnsLR = [
    { id: 'action', label: "Action", minWidth: 0 },
    { id: 'empreinteInitiateur', label: "Initiateur", minWidth: 0 },
    { id: 'valueLoaned', label: 'Token prêtés', minWidth: 0 },
    { id: 'valuePayBack', label: 'Token dûs', minWidth: 0 },
    { id: 'failurePayBack', label: 'Compensation', minWidth: 0 },
    { id: 'timeToPayBack', label: 'Date limite', minWidth: 0 },
];const columnsLI = [
    { id: 'action', label: "Action", minWidth: 0 },
    { id: 'empreinteRecepteur', label: "Récepteur", minWidth: 0 },
    { id: 'valueLoaned', label: 'Token prêtés', minWidth: 0 },
    { id: 'valuePayBack', label: 'Token dûs', minWidth: 0 },
    { id: 'failurePayBack', label: 'Compensation', minWidth: 0 },
    { id: 'timeToPayBack', label: 'Date limite', minWidth: 0 },
];

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
let empreinteInitiateur;

let jwtDecoded = sessionStorage.getItem("SPURO_jwt") ? jwt_decode(sessionStorage.getItem("SPURO_jwt")) : {}

class ListeDesPrets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phase:1,
            stateDrawer:true,
            jsonLAI: {},
            jsonLAR: {},
            jsonLI: {},
            jsonLR: {},
        };
    }

    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt : sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = jwt_decode(this.state.jwt)
            global.emitter.emit("onRouteChanged",{
                pageName:'Liste des contrats',
                pseudo:jwtDecoded.pseudo,
            })
        })
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }
    }

    handleSubmitPhase1(event){
        event.preventDefault()
        empreinteInitiateur = sha256(this.state.champsPseudo.concat(this.state.champsClef))
        Axios.post(url+"connexionLoan", {
            empreinte : empreinteInitiateur,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwtClient")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
            }
            if (response.data.resultat === "Cet utilisateur n'existe pas."){
                Swal.fire({
                    title: 'Erreur!',
                    text: "Cet utilisateur n'existe pas.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }else{
                this.setState({
                    jsonLAI : response.data.listLAI,
                    jsonLAR : response.data.listLAR,
                    jsonLI : response.data.listLI,
                    jsonLR : response.data.listLR,
                    phase:2,
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Connecté au compte "'+this.state.champsPseudo+'"'
                })

            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    answerLoan(answers){
        Axios.post(url+"validateLoan", {
            empreinteRecepteur : empreinteInitiateur,
            accept:answers[0],
            idLoan:answers[1],
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwtClient")}}).then((response)=> {
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
            }
            if (response.data.resultat === "La personne qui vous a proposée un prêt n'existe plus."){
                Swal.fire({
                    title: 'Erreur!',
                    text: "La personne qui vous a proposée ce contrat n'existe plus.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                let LAR = this.state.jsonLAR.slice()
                LAR.forEach(elt => {
                    if (elt.idLoan === answers[1]){
                        LAR.splice(LAR.indexOf(elt),1)
                    }
                })
                this.setState({jsonLAR:LAR})
            }else{
                Toast.fire({
                    icon: 'success',
                    title: response.data.resultat
                })

                let LAR = this.state.jsonLAR.slice()
                LAR.forEach(elt => {
                    if (elt.idLoan === answers[1]){
                        LAR.splice(LAR.indexOf(elt),1)
                    }
                })
                this.setState({jsonLAR:LAR})
            }
        })
    }

    cancelLoanBeforeAnswer(idLoan){
        Axios.post(url+"cancelLoanBeforeAnswer", {
            empreinteInitiateur : empreinteInitiateur,
            idLoan:idLoan,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwtClient")}}).then((response)=> {
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
            }
            Toast.fire({
                icon: 'success',
                title: response.data.resultat
            })

            let LAI = this.state.jsonLAI.slice()
            LAI.forEach(elt =>{
                if (elt.idLoan === idLoan){
                    LAI.splice(LAI.indexOf(elt),1)
                }
            })
            this.setState({jsonLAI:LAI})
        })
    }

    paybackLoan(idLoan){
        Axios.post(url+"payback", {
            empreinteRecepteur : empreinteInitiateur,
            idLoan:idLoan,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SPURO_jwtClient")}}).then((response)=> {
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
            }
            Toast.fire({
                icon: 'success',
                title: response.data.resultat
            })

            let LR = this.state.jsonLR.slice()
            LR.forEach(elt => {
                if (elt.idLoan === idLoan){
                    LR.splice(LR.indexOf(elt),1)
                }
            })
            this.setState({jsonLR : LR})
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    {this.state.phase === 1 ? (
                        <div className={"flexCenter"} style={{overflowY:"auto", backgroundColor:"white",flexDirection:"column",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                            <form className={"flexCenter"} style={{flexDirection:"column",padding:25}} onSubmit={(e)=>{this.handleSubmitPhase1(e)}} autoComplete="off">
                                <h1>Connectez vous à votre compte</h1>
                                <div className={"flexCenter"}>
                                    <TextField name={"champsPseudo"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez votre pseudonyme."} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Pseudo" variant="outlined"/>
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div className={"flexCenter"}>
                                    <TextField name={"champsClef"} className={"champsTexte"} type={this.state.showPassword ?"text" : "password"} required={true} value={this.state.value} placeholder={"Entrez votre Clef Unique."} onChange={(e)=>{this.handleChange(e)}} id="champsClef" label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }}/>
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button type={"submit"} variant="contained">Suivant</Button>
                            </form>
                        </div>
                    ):("")}
                    {this.state.phase === 2 ? (
                        <div className={"flexCenter"} style={{flexDirection:"column"}} >
                            <div className={"flexCenter"}  style={{width:"93.5%",backgroundColor:"white",flexDirection:"column",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                                <div>
                                    <h3 className={"flexCenter"}> <AccountCircleRoundedIcon style={{transform:"scale(2)",marginRight:25}}/> Connecté en tant que : {this.state.champsPseudo}</h3>
                                </div>

                            </div>
                            <div className={"flexCenter"} style={{flexWrap:"wrap",width:"95%"}}>
                                <div className={"flexCenter"} style={{overflowY:"auto",minWidth:600,margin:10, flex:1,backgroundColor:"white",flexDirection:"column",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                                    <h3>Liste de vos contrats envoyés en attente d'acceptation</h3>
                                    <Paper style={{maxHeight:400, overflow:"auto"}}>
                                        <TableContainer>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        {columnsLAI.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(this.state.jsonLAI).map((row) => {
                                                        return (
                                                            <TableRow style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                {columnsLAI.map((column) => {
                                                                    return (
                                                                        <TableCell key={column.id}>
                                                                            <>
                                                                                {column.id === "empreinteRecepteur" ? (
                                                                                    <>
                                                                                        {(row.empreinteRecepteur).slice(0,4) + "..." + (row.empreinteRecepteur).slice(-4)}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {column.id === "valueLoaned" ? (
                                                                                            <>
                                                                                                {row.valueLoaned}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {column.id === "valuePayBack" ? (
                                                                                                    <>
                                                                                                        {row.valuePayBack}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {column.id === "action" ? (
                                                                                                            <>
                                                                                                                <Button onClick={()=>{this.cancelLoanBeforeAnswer(row.idLoan)}}>
                                                                                                                    <CloseIcon/>
                                                                                                                </Button>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {column.id === "timeToPayBack" ? (
                                                                                                                    <>
                                                                                                                        {new Date(row.timeToPayBack).toLocaleString()}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {column.id === "failurePayBack" ? (
                                                                                                                            <>
                                                                                                                                {row.failurePayBack}
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            ""

                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </div>
                                <div className={"flexCenter"} style={{overflowY:"auto",minWidth:600,margin:10, flex:1,backgroundColor:"white",flexDirection:"column",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                                    <h3>Liste des contrats reçus en attente d'acceptation</h3>
                                    <Paper style={{maxHeight:400, overflow:"auto"}}>
                                        <TableContainer>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        {columnsLAR.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(this.state.jsonLAR).map((row) => {
                                                        return (
                                                            <TableRow style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                {columnsLAR.map((column) => {
                                                                    return (
                                                                        <TableCell key={column.id}>
                                                                            <>
                                                                                {column.id === "empreinteInitiateur" ? (
                                                                                    <>
                                                                                        {(row.empreinteInitiateur).slice(0,4) + "..." + (row.empreinteInitiateur).slice(-4)}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {column.id === "valueLoaned" ? (
                                                                                            <>
                                                                                                {row.valueLoaned}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {column.id === "valuePayBack" ? (
                                                                                                    <>
                                                                                                        {row.valuePayBack}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {column.id === "action" ? (
                                                                                                            <>
                                                                                                                <Button onClick={()=>{this.answerLoan([true,row.idLoan])}}>
                                                                                                                    <CheckIcon/>
                                                                                                                </Button>
                                                                                                                <Button onClick={()=>{this.answerLoan([false,row.idLoan])}}>
                                                                                                                    <CloseIcon/>
                                                                                                                </Button>
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {column.id === "timeToPayBack" ? (
                                                                                                                    <>
                                                                                                                        {new Date(row.timeToPayBack).toLocaleString()}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {column.id === "failurePayBack" ? (
                                                                                                                            <>
                                                                                                                                {row.failurePayBack}
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            ""

                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </div>
                                <div className={"flexCenter"} style={{overflowY:"auto",minWidth:600,margin:10, flex:1,backgroundColor:"white",flexDirection:"column",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                                    <h3>Liste des contrats envoyés</h3>
                                    <Paper style={{maxHeight:400, overflow:"auto"}}>
                                        <TableContainer>
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        {columnsLI.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(this.state.jsonLI).map((row) => {
                                                        return (
                                                            <TableRow style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                {columnsLI.map((column) => {
                                                                    return (
                                                                        <TableCell key={column.id}>
                                                                            <>
                                                                                <TableCell key={column.id}>
                                                                                    <>
                                                                                        {column.id === "empreinteRecepteur" ? (
                                                                                            <>
                                                                                                {(row.empreinteRecepteur).slice(0,4) + "..." + (row.empreinteRecepteur).slice(-4)}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {column.id === "valueLoaned" ? (
                                                                                                    <>
                                                                                                        {row.valueLoaned}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {column.id === "valuePayBack" ? (
                                                                                                            <>
                                                                                                                {row.valuePayBack}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {column.id === "action" ? (
                                                                                                                    <>
                                                                                                                        {/*<Button>*/}
                                                                                                                        {/*    <MonetizationOnIcon/>*/}
                                                                                                                        {/*</Button>*/}
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {column.id === "timeToPayBack" ? (
                                                                                                                            <>
                                                                                                                                {new Date(row.timeToPayBack).toLocaleString()}
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                {column.id === "failurePayBack" ? (
                                                                                                                                    <>
                                                                                                                                        {row.failurePayBack}
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    ""

                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                </TableCell>
                                                                            </>
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </div>
                                <div className={"flexCenter"} style={{overflowY:"auto",minWidth:600,margin:10, flex:1,backgroundColor:"white",flexDirection:"column",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                                    <h3>Liste des contrats reçus</h3>
                                    <Paper style={{maxHeight:400, overflow:"auto"}}>
                                        <TableContainer >
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        {columnsLR.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                            >
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(this.state.jsonLR).map((row) => {
                                                        return (
                                                            <TableRow style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                {columnsLR.map((column) => {
                                                                    return (
                                                                        <TableCell key={column.id}>
                                                                            <>
                                                                                <TableCell key={column.id}>
                                                                                    <>
                                                                                        {column.id === "empreinteInitiateur" ? (
                                                                                            <>
                                                                                                {(row.empreinteInitiateur).slice(0,4) + "..." + (row.empreinteInitiateur).slice(-4)}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {column.id === "valueLoaned" ? (
                                                                                                    <>
                                                                                                        {row.valueLoaned}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {column.id === "valuePayBack" ? (
                                                                                                            <>
                                                                                                                {row.valuePayBack}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {column.id === "action" ? (
                                                                                                                    <>
                                                                                                                        <Button onClick={()=>{this.paybackLoan(row.idLoan)}}>
                                                                                                                            <MonetizationOnIcon/>
                                                                                                                        </Button>
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        {column.id === "timeToPayBack" ? (
                                                                                                                            <>
                                                                                                                                {new Date(row.timeToPayBack).toLocaleString()}
                                                                                                                            </>
                                                                                                                        ) : (
                                                                                                                            <>
                                                                                                                                {column.id === "failurePayBack" ? (
                                                                                                                                    <>
                                                                                                                                        {row.failurePayBack}
                                                                                                                                    </>
                                                                                                                                ) : (
                                                                                                                                    ""

                                                                                                                                )}
                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                </TableCell>
                                                                            </>
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    ):("")}
                </div>
            </div>
        );
    }
}

export default withRouter(ListeDesPrets)