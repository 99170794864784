import React, {Component} from 'react';
import {sha256} from 'js-sha256';
import {Button, IconButton, InputAdornment, TextField, Tooltip} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Axios from "axios";
import {withRouter} from '../shared/NavigationUtils';
import config from "../json/config.json";

import Swal from "sweetalert2";
import tooltipContenu from "../json/contenu.json";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
class AchatToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateDrawer:true
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        let jwtDecoded = sessionStorage.getItem("SPURO_jwt") ? jwt_decode(sessionStorage.getItem("SPURO_jwt")) : {}
        global.emitter.emit("onRouteChanged",{
            pageName:'Acheter des STCs',
            pseudo:jwtDecoded.pseudo
        })
    }

    handleValidation(clef,pseudo){ //Fonction servant a la vérification des données avant l'envoi du formulaire
        if(clef !== "" && pseudo !== ""){
            return true
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Les données saisies sont incorrectes.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return false
        }
    }
    handleChange(event){ //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        let value = event.target.value;
        value = value.replace(",", '.');
        if (event.target.name=== "champsEuros"){
            value = value.replace(/[^0-9.]/g, '');
        }
        this.setState({
            [event.target.name]:value
        });
    }
    handleSubmitAchat(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        const data= new FormData(event.target);
        let euros = data.get('champsEuros'),pseudo = data.get('champsPseudo'), clef = data.get('champsClef'),hash;
        event.preventDefault();
        hash = sha256(pseudo.concat(clef)) //Hashage avec protocole sha256
        if(()=>{this.handleValidation(clef, pseudo)}){
            Axios.post(url+"connexionGDT", {
                hash:hash,
            },{headers:{Authorization:"Bearer " + "pasbesoindejwtpourGDTpourlinstant"}}).then((response)=>{
                if(response.data.resultat === "Connecté!"){
                    Axios.post(url+"achatToken", {
                        hash:hash,
                        euros:euros,
                    }).then(response => {
                        if (response.data.resultat === "achatSTC"){
                            Swal.fire({
                                title: 'Succès!',
                                text: "Merci d'avoir acheté des STCs!",
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            })
                        }else{
                            Swal.fire({
                                title: 'Erreur!',
                                text: "Une erreur est survenue, veuillez réessayer plus tard.",
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: response.data.resultat,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            })
        }
    }
    render(){ //Affichage sur la page
        const { classes } = this.props;
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto", padding:30,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <h1>Acheter des STCs</h1>
                        <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitAchat(e)}} autoComplete="off">
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField required={"true"} name={"champsPseudo"} className={"champsTexte"} value={this.state.champsPseudo} placeholder={"Entrez votre pseudonyme."} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Identifiant" variant="outlined"/>
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField required={"true"} type={this.state.showPassword ? "text":"password"} name={"champsClef"} className={"champsTexte"} value={this.state.champsClef} placeholder={"Entrez votre Clef Unique."} onChange={(e)=>{this.handleChange(e)}} id="champsClef" label="Clef Unique" variant="outlined"
                                           InputProps={{
                                               endAdornment:
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           aria-label="toggle password visibility"
                                                           onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                       >
                                                           {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                       </IconButton>
                                                   </InputAdornment>
                                           }}
                                />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField required={"true"} name={"champsEuros"} className={"champsTexte"} value={this.state.champsEuros} placeholder={"Entrez le nombre d'euros que vous voulez transférer."} onChange={(e)=>{this.handleChange(e)}} id="champsEuros" label="Euros" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEuros}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <Button type={"submit"} variant="contained">Transférer</Button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AchatToken)