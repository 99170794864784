import React, {Component} from 'react';
import {
    Avatar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip
} from '@mui/material';
import {withRouter} from '../../../shared/NavigationUtils';
import Axios from "axios";
import config from "../../../json/config.json";

import tooltipContenu from "../../../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {sha256} from "js-sha256";
import Swal from "sweetalert2";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VisibilityIcon from "@mui/icons-material/Visibility";
import {ExitToApp, KeyboardReturn, Menu, Security, Store, VideoLibrary} from "@mui/icons-material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const allAcceptedPath = [
   "/buyresale/accueil",
   "/buyresale/mesvideos",
   "/buyresale/catalogue",
   "/buyresale/moderationadmin",
   "/buyresale/token",
   "/buyresale/profil",
   "/buyresale/paiementstatut",
   "/buyresale/mentionslegales",
]

class SharedHeaderBR extends Component {

    constructor(props) {
        super(props);
        this.state={
            jwtDecoded:{},
            readyToClick:true,
            identifiantValue : "",
            passwordValue : "",
            emailValue:"",
            verifValue:"",
            passwordBisValue:"",
            displayConnexion:false,
            showBrowserView:true,
            connected:false,
            displayMenuMobile:false,
            showMenuUser:false,
            refreshRandom:"",
            disableOutsideClick:false,
            currentPath:""
        };
    }


    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    _askConnexionBR; _modifyAvatar; _onRouteChangedBuyResale; _emitInscris;
    componentDidMount() {
        this.setState({
            jwtDecoded : sessionStorage.getItem("BUYRESALE_jwt") ? jwt_decode(sessionStorage.getItem("BUYRESALE_jwt")) : {},
            connected: !!sessionStorage.getItem("BUYRESALE_jwt")
        })
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._onRouteChangedBuyResale = global.emitter.addListener("onRouteChangedBuyResale",(data)=>{
            this.setState({
                jwt:data.jwt,
                jwtDecoded : (data.jwt ? jwt_decode(data.jwt):""),
                connected: !!data.jwt
            })
        })

        this._emitInscris = global.emitter.addListener("emitInscris",(data)=>{
            this.setState({
                champsIdentifiant:data.identifiant,
                champsClef:data.password
            },()=>{
                setTimeout(()=>{this.handleConnexion("fromInsc")},500)
            })
        })
        this._askConnexionBR = global.emitter.addListener("askConnexionBR",(data)=>{
            this.setState({
                displayConnexion:true,
                disableOutsideClick:data?(data.disableOutsideClick||false):false /*Si on envoie pas de donnée -> false, sinon c'est true ou false*/
            })
        })
        this._modifyAvatar = global.emitter.addListener("modifyAvatar",()=>{
            this.setState({
                refreshRandom:"?refresh="+new Date().getTime()
            })
        })
        let currentPath = this.props.location.pathname.toLowerCase()
        this.setState({currentPath})
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        let currentPath = this.props.location.pathname.toLowerCase()
        if(this.state.currentPath !== currentPath){
            this.setState({currentPath})
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    handleChangePhone(event){
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if(onlyNums.length < 10){
            this.setState({
                [event.currentTarget.name] : onlyNums
            })
        } else if (onlyNums.length === 10){
            const number = onlyNums.replace(
                /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                '$1 $2 $3 $4 $5'
            );
            this.setState({
                [event.currentTarget.name] : number
            })
        }
    }

    componentWillUnmount(){
        if(this._onRouteChangedKageSecur){this._onRouteChangedKageSecur.remove()}
        window.removeEventListener('resize', this.resizePage)
    }

    handleConnexion(e){
        if(e !== "fromInsc"){
            e.preventDefault()
        }
        this.setState({readyToClick:false})
        let empreinte = sha256((this.state.champsIdentifiant.trim()).concat(this.state.champsClef.trim()));
        Axios.post(url+"connexionBuyResale",{
            empreinte:empreinte,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("BUYRESALE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BUYRESALE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt,
                    jwtDecoded : jwt_decode(response.data.jwt)
                })
            }
            this.setState({displayConnexion:false,readyToClick:true},()=>{
                if (response.data.resultat === "Connecté"){
                    Toast.fire({
                        icon: 'success',
                        title: 'Connecté au compte "'+this.state.champsIdentifiant+'"'
                    })
                    this.setState({connected:true,disableOutsideClick:false})
                    global.emitter.emit("emitConnectedBR")

                }else{
                    Swal.fire({
                        title: 'Utilisateur introuvable.',
                        text: "Cette combinaison d'identifiant et de clef unique n'existe pas.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{this.setState({displayConnexion:true,readyToClick:true})})
                }
            })
        }).catch(()=>{
            this.setState({displayConnexion:false,readyToClick:true,disableOutsideClick:false},()=> {
                Swal.fire({
                    title: 'Erreur!',
                    text: "Serveur injoignable.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
        })
    }

    verifMesVideos(clickedPage){
        if(clickedPage === "/BuyResale/MesVideos"){
            if (this.state.connected){
                this.goToPage(clickedPage)
            }else{
                this.setState({displayMenuMobile:false,displayConnexion:true})
            }
        }
    }

    goToPage(clickedPage){
        window.history.replaceState(null, null, window.location.pathname);
        window.location.pathname !== clickedPage ? this.props.navigate(clickedPage):window.location.reload()
        this.setState({displayMenuMobile:false})
    }

    deco(){
        this.setState({jwt:null, jwtDecoded:{},showMenuUser:false,displayMenuMobile:false, connected:false})
        sessionStorage.removeItem("BUYRESALE_jwt")
        this.props.navigate("/BuyResale/Accueil")
    }

    render() {
        if(this.state.currentPath.includes("inscription")){
            return null
        }
        return(
            <>
                {/*Affichage du menu pour téléphone*/}
                <div style={{display:this.state.displayMenuMobile ? "flex":"none",justifyContent:"flex-start", alignItems:"center", width:"100%", height:"100%", position:"fixed", backgroundColor:"#FFFFFF", zIndex:999999, flexDirection:"column"}}>
                    <div style={{padding:"10px 0 10px 0", width:"100%"}} onClick={()=>{this.setState({displayMenuMobile:false})}} className={"menuBR flexCenter"}><KeyboardReturn style={{marginRight:5}}/> Retour</div>
                    <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                    <div style={{padding:"10px 0 10px 0", width:"100%"}} onClick={()=>{this.verifMesVideos("/BuyResale/MesVideos")}} className={"menuBR flexCenter"}><VideoLibrary style={{marginRight:5}}/> Mes vidéos</div>
                    <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                    <div style={{padding:"10px 0 10px 0", width:"100%"}} onClick={()=>{this.goToPage("/BuyResale/Catalogue")}} className={"menuBR flexCenter"}><Store style={{marginRight:5}}/> Catalogue</div>
                    <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                    {this.state.jwtDecoded && this.state.jwtDecoded.admin && (
                        <>
                            <div style={{padding:"10px 0 10px 0", width:"100%"}} onClick={()=>{this.goToPage("/BuyResale/ModerationAdmin")}} className={"menuBR flexCenter"}><Security style={{marginRight:5}}/> Moderation</div>
                            <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                        </>
                    )}
                    {this.state.connected ? (
                        <>
                            <div style={{padding:"10px 0 10px 0", width:"100%"}} onClick={()=>{this.goToPage("/BuyResale/Profil")}} className={"menuBR flexCenter"}><AccountCircleIcon style={{marginRight:5}}/> Mon profil</div>
                            <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                            <div style={{padding:"10px 0 10px 0", width:"100%",color:"red"}} onClick={()=>{this.deco()}} className={"menuBR flexCenter"}><ExitToApp style={{marginRight:5}}/> Déconnexion</div>
                            <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                        </>
                    ):(
                        <>
                            <div style={{padding:"10px 0 10px 0", width:"100%"}} onClick={()=>{this.state.readyToClick && (this.setState({displayConnexion:true, displayMenuMobile:false}))}} className={"menuBR flexCenter"}>Je me connecte</div>
                            <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                            <div style={{padding:"10px 0 10px 0", width:"100%"}} onClick={()=>{this.state.readyToClick && (this.goToPage("/BuyResale/Inscription"))}} className={"menuBR flexCenter"}>Je m'inscris</div>
                            <div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />
                        </>
                    )}




                </div>
                <div style={{position:"fixed", display:"flex", height:90, backgroundColor:"white", width:"100%", alignItems:"center", boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", zIndex:9999}}>
                    <Dialog
                        open={this.state.displayConnexion}
                        onClose={()=>{this.setState({displayConnexion:false,readyToClick:true})}}
                        disableBackdropClick={this.state.disableOutsideClick}
                        disableEscapeKeyDown={this.state.disableOutsideClick}
                    >
                        <DialogTitle>Connectez-vous à BuyResale !</DialogTitle>
                        <form autoComplete="off" onSubmit={(e)=>{this.handleConnexion(e)}}>
                            <DialogContent>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsIdentifiant"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsIdentifiant" required={true} label="Identifiant" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField autoComplete="off" name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsClef" required={true} type={this.state.showPassword ? "text":"password"} label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }} />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <a style={{color:"blue", textDecoration:"underline",cursor:"pointer"}} onClick={()=>{
                                    Swal.fire({
                                        text: "Munissez-vous de votre code de récupération de clef unique et contactez votre administrateur.",
                                        icon: 'info',
                                        target:".MuiDialog-root",
                                        confirmButtonText: 'Ok'
                                    })
                                }}>Clef unique oubliée ?</a>
                                <button className={"divButtonBuyResale"} style={{width:"100%",marginTop:10}} type={"submit"}>Se connecter</button>
                            </DialogContent>
                        </form>
                    </Dialog>
                    <div onAuxClick={(e)=>{if(e.button===1){window.open('/BuyResale/Accueil', "_blank")}}} onClick={()=>{this.goToPage("/BuyResale/Accueil")}} style={{display:"flex",cursor:"pointer", justifyContent:"center", alignItems:"center", marginLeft:20}}>
                        <img style={{textAlign:"center", width:120,marginRight:5}} src={require("../../../assets/LOGO_BUYRESALE.png")} alt="logoKageSecure"/>
                    </div>
                    <div style={{display:"flex",width:"100%", justifyContent:!this.state.showBrowserView && "flex-end"}}>
                        {this.state.showBrowserView ? ( /*Si affichage desktop*/
                            <>
                                <div style={{display:"flex",width:"90%", justifyContent:"space-evenly", alignItems:"center", marginLeft:0, marginRight:20}}>
                                    <div onAuxClick={(e)=>{if(e.button===1 && this.state.connected){window.open('/BuyResale/MesVideos', "_blank")}}} onClick={()=>{this.verifMesVideos("/BuyResale/MesVideos")}} className={"menuBR flexCenter"}><VideoLibrary style={{marginRight:5}}/> Mes vidéos</div>
                                    <div onAuxClick={(e)=>{if(e.button===1){window.open('/BuyResale/Catalogue', "_blank")}}} onClick={()=>{this.goToPage("/BuyResale/Catalogue")}} className={"menuBR flexCenter"}><Store style={{marginRight:5}}/> Catalogue</div>
                                    {Object.keys(this.state.jwtDecoded).length>0 && (
                                        <>
                                            {this.state.jwtDecoded && this.state.jwtDecoded.admin && (
                                                <div onAuxClick={(e)=>{if(e.button===1){window.open('/BuyResale/ModerationAdmin', "_blank")}}} onClick={()=>{this.goToPage("/BuyResale/ModerationAdmin")}} className={"menuBR flexCenter"}><Security style={{marginRight:5}}/> Moderation</div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {!Object.keys(this.state.jwtDecoded).length>0 && (
                                    <div className={"flexCenter"} style={{width:"fit-content"}}>
                                        <button onClick={()=>{this.state.readyToClick && (this.setState({displayConnexion:true}))}} className={"divButtonBuyResale flexCenter"} style={{marginRight:10, whiteSpace:"nowrap"}}>Je me connecte</button>
                                        <button onClick={()=>{this.state.readyToClick && (this.goToPage("/BuyResale/Inscription"))}} className={"divButtonBuyResale flexCenter"} style={{marginRight:10, whiteSpace:"nowrap"}}>Je m'inscris</button>
                                    </div>
                                )}
                                {Object.keys(this.state.jwtDecoded).length>0 && (
                                    <>
                                        <div onMouseLeave={()=>{this.setState({showMenuUser:false})}} onMouseEnter={()=>{this.setState({showMenuUser:true})}} className={"flexCenter cardAccount"}>
                                            <div style={{width:"fit-content", height:"fit-content", borderRadius:"50%", boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding:10}}>
                                                <Avatar
                                                    variant={"circular"}
                                                    src={`${url}images/avatar/${this.state.jwtDecoded.empreinte}.png${this.state.refreshRandom}`}
                                                    alt={this.state.jwtDecoded && this.state.jwtDecoded.pseudo.toUpperCase()}
                                                    style={{transform:"scale(1.3)"}}
                                                />
                                            </div>
                                            <div style={{position:"relative",width:"100%", direction:"rtl"}}>
                                                <div className={"divMenuUser"} style={{transform:this.state.showMenuUser ? "scaleY(1)":"scaleY(0)", zIndex:99999}}>
                                                    <div onAuxClick={(e)=>{if(e.button===1){window.open('/BuyResale/Profil', "_blank")}}} onClick={()=>{this.goToPage("/BuyResale/Profil")}} className={"fontDivMenuUser"}>
                                                        Mon profil
                                                    </div>
                                                    <hr style={{width:"100%", color:"#b6b6b6"}}/>
                                                    <div onClick={()=>{this.deco()}} style={{color:"red"}} className={"fontDivMenuUser"}>
                                                        Déconnexion
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        ):( /*Si affichage mobile*/
                            <>
                                <IconButton onClick={()=>{this.setState({displayMenuMobile:true})}}>
                                    <Menu style={{color:"#000000", transform:"scale(1.3)"}} />
                                </IconButton>
                            </>
                        )}
                    </div>
                </div>

            </>
        )
    }
}

export default withRouter(SharedHeaderBR)