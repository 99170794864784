import React, {Component} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import config from "../../../json/config.json";
import {isBrowser} from "react-device-detect";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {sha256} from "js-sha256";
import SharedFooterEkocoOR from "./components/SharedFooterEkocoOR";


const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded


const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class ConnexionOR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            openLoad:false
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950 || !isBrowser){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
    }

    handleConnexion(e){
        this.setState({showPassword:false})
        e.preventDefault()
        let empreinte = sha256((this.state.champsIdentifiant.trim()).concat(this.state.champsPassword.trim()));
        Axios.post(url+"BCBConnexionClient",{
            empreinte:empreinte,
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("EKOCO_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("EKOCO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt,
                    jwtDecoded:jwt_decode(response.data.jwt)
                })
            }
            if (response.data.resultat === "Utilisateur introuvable."){
                Swal.fire({
                    title: 'Utilisateur introuvable.',
                    text: "Cette combinaison identifiant clef unique n'existe pas.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#ec5ba1'
                })
            }else{
                this.setState({
                    connected:true
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Bienvenue '+this.state.champsIdentifiant
                })
                this.props.navigate("/Ekoco/OctobreRose/Home")
            }
        }).catch(()=>{
            this.setState({displayConnexion:false},()=> {
                Swal.fire({
                    title: 'Erreur!',
                    text: "Serveur injoignable.",
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#ec5ba1'
                })
            })
        })
    }

    handleChange=(event) => { //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }



    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>
                {/*DEBUT RENDER STATIQUE*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)"}}>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute",left:50, top:"25vh"}}>
                            <img style={{width:350}} src={require("../assetsEkoco/telephoneFemme_rose.png")} alt={"Reporting"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", zIndex:1,position:"absolute", left:0, top:0}}>
                            <img style={{width:"80", objectFit:"contain"}} src={require("../assetsEkoco/topleft_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                    <div style={{marginTop:50,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",marginBottom:20,width:"100%", height:"80vh"}}>
                        <div style={{backgroundColor:this.state.showBrowserView ? "#FFF":"transparent", width:this.state.showBrowserView ? 500:"95%", padding:15, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:30, paddingTop:30, borderRadius:10, zIndex:2}}>
                            <p style={{color:"#EC5BA1FF",fontWeight:"bold", fontSize:30, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Connexion Ekoco - Octobre Rose</p>
                            <form style={{width:"100%"}} autoComplete="off" onSubmit={(e)=>{this.handleConnexion(e)}}>
                                <div style={{position:"relative", width:"100%"}}>
                                    <input autoComplete={"off"} name={"champsIdentifiant"} required className={"champsTexteSK champsTexteEkocoRose"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #EC5BA1FF", color:"#EC5BA1FF"}} type={"text"} placeholder={"Identifiant"}/>
                                </div>
                                <div style={{position:"relative", width:"100%"}}>
                                    <input autoComplete={"new-password"} name={"champsPassword"} required className={"champsTexteGNFT champsTexteEkocoRose"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #EC5BA1FF", color:"#EC5BA1FF", position:"relative"}} type={this.state.showPassword ? "text":"password"} placeholder={"Clef unique"}/>
                                    <div style={{position:"absolute", width:"fit-content", right:10, top:24}}>
                                        {this.state.showPassword ? (
                                            <VisibilityIcon style={{cursor:"pointer", color:"#EC5BA1FF"}} onClick={()=>{this.setState({showPassword:false})}}/>
                                        ):(
                                            <VisibilityOffIcon style={{cursor:"pointer", color:"#EC5BA1FF"}} onClick={()=>{this.setState({showPassword:true})}}/>
                                        )}
                                    </div>
                                </div>
                                <div className={"flexCenter"}>
                                    <button type={"submit"} style={{width:"fit-content", marginTop:15, backgroundImage:"none", backgroundColor:"#EC5BA1FF"}} className={"buttonSK"}><h2 style={{margin:0, fontWeight:600, color:"#FFF"}}>Se connecter</h2></button>
                                    {/*<button className={"buttonGNFT ChampFont"} style={{padding:10,width:"fit-content", color:"#000", margin:5, marginTop:10, border:"1px solid black", borderRadius:5}} type={"submit"}><h2 style={{margin:0, fontWeight:"normal"}}>Log In</h2></button>*/}
                                </div>
                            </form>
                        </div>
                    </div>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex",justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute", right:50, top:"25vh"}}>
                            <img style={{width:350, objectFit:"contain"}} src={require("../assetsEkoco/homme_rose.png")} alt={"Statistiques"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end" , zIndex:1,position:"absolute", right:0, bottom:0}}>
                            <img style={{width:"80%", objectFit:"contain"}} src={require("../assetsEkoco/bottomright_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                </div>
                {/*FIN RENDER STATIQUE*/}
                {/*FOOTER*/}
                <SharedFooterEkocoOR/>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Chargement en cours ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default withRouter(ConnexionOR);