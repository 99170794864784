import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {
    Button,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';
import {isMobile} from "react-device-detect";
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import {Cancel, Close, Delete, ShoppingCart} from "@mui/icons-material";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const columns = [
    { id: 'Gestion', label: 'Gestion', minWidth: 30 },
    { id: 'Invalider', label: 'Invalider', minWidth: 30 },
    { id: 'pseudo', label: 'Identifiant', minWidth: 75 },
    { id: 'email', label: 'Email', minWidth: 150 },
    { id: 'abonnement', label: 'Abonnement', minWidth: 150 },
    { id: 'empreinte', label: 'Empreinte', minWidth: 300 },

];

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded;

class ListeDesClients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allClients : [],
            showBrowserView:true,
            displaySkeleton:true
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (!response.data.resultat || !jwtDecoded.gestionnaire ){
                    Swal.fire({
                        text: "Vous n'êtes pas autorisé à accéder à cette page. Veuillez vous rendre sur votre node.",
                        icon: 'info',
                        confirmButtonText: "Ok",
                    }).then(()=> {
                        this.props.navigate('/KageSecur/Accueil')
                    })
                }else{
                    global.emitter.emit("onRouteChangedKageSecur",{
                        user:"gestionnaire",
                        pseudo:jwtDecoded.pseudo
                    })
                    this.getAllClients()
                }
            })
        })
    }

    getAllClients(){
        Axios.post(url+"getAllClients",{getInvalidation:true},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            let allClientsTemp = [], clientsOthersNodes = [];
            (response.data.resultat).forEach(client=>{
                if (client.thisSrv){
                    client.label = client.pseudo
                    allClientsTemp.push(client)
                }else{
                    client.label = client.pseudo
                    clientsOthersNodes.push(client)
                }
            })
            let indexMyAccount = response.data.resultat.findIndex(elt => elt.empreinte === jwtDecoded.empreinte)
            this.setState({
                allClients:allClientsTemp,
                allClientsOthersNodes:clientsOthersNodes,
                displaySkeleton:false,
                isAdmin:response.data.resultat[indexMyAccount].status === "admin"
            })
        }).catch((err)=>{
            console.log(err)
        })
    }
    GestionAbonnementClient(client){
        Swal.fire({
            title: 'Attention!',
            text: client.abonnement === "Sans abonnement"? "Voulez-vous vraiment abonner ce client ? Cette action est définitive":"Voulez-vous vraiment annuler l'abonnement de ce client ?",
            icon: 'info',
            confirmButtonText: 'Oui',
            showCancelButton:true,
            cancelButtonText:"Non",
            cancelButtonColor:"red"
        }).then((result)=>{
            if(result.isConfirmed){
                Axios.post(url+"GestionAbonnementClient", {
                    empreinte:client.empreinte
                },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                    if(response.data.jwt){
                        sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === "done"){
                        Toast.fire({
                            title: "L'abonnement a correctement été modifié pour le client "+client.pseudo+".",
                            icon: 'success',
                        })
                        let newAllClient = this.state.allClients.slice(),
                            index = newAllClient.findIndex(elt => elt.empreinte === client.empreinte)
                        if (index !== -1){
                            console.log("old", newAllClient[index].abonnement)
                            console.log("new", !client.abonnement)
                            newAllClient[index].abonnement = client.abonnement === "Sans abonnement" ? "Abonné" : "Sans abonnement"
                        }
                        this.setState({allClients:newAllClient})
                    }else{
                        Toast.fire({
                            title: response.data.resultat,
                            icon: 'error',
                        })
                    }
                })
            }
        })
    }

    InvalidationClient(client){
        Swal.fire({
            title: 'Attention!',
            text: !!client.invalidation ? "Voulez-vous vraiment annuler l'invalidation de ce client ?":"Voulez-vous vraiment invalider ce client ? Vous aurez jusqu'à 90 jours avant la supression permanente de cet utilisateur.",
            icon: 'info',
            confirmButtonText: 'Oui',
            showCancelButton:true,
            cancelButtonText:"Non",
            cancelButtonColor:"red"
        }).then((result)=>{
            if(result.isConfirmed){
                if(!!client.invalidation){
                    Axios.post(url+"cancelInvalidation", {
                        empreinte:client.empreinte,
                    },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                        if(response.data.jwt){
                            sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                            this.setState({
                                jwt:response.data.jwt
                            },()=>{
                                jwtDecoded = jwt_decode(this.state.jwt)
                            })
                        }
                        if (response.data.resultat === "done"){
                            Toast.fire({
                                title: "Vous avez annulé l'invalidation.",
                                icon: 'success',
                            })
                            let allClientsTemp = this.state.allClients.slice()
                            delete allClientsTemp[allClientsTemp.findIndex(elt => elt.empreinte === client.empreinte)].invalidation
                            this.setState({allClients:allClientsTemp})
                        }else{
                            Toast.fire({
                                title: response.data.resultat,
                                icon: 'error',
                            })
                        }
                    })
                }else{
                    Axios.post(url+"adminDeleteUser", {
                        empreinte:client.empreinte,
                    },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                        if(response.data.jwt){
                            sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                            this.setState({
                                jwt:response.data.jwt
                            },()=>{
                                jwtDecoded = jwt_decode(this.state.jwt)
                            })
                        }
                        if (response.data.resultat === "done"){
                            Toast.fire({
                                title: "Ce compte sera invalidé dans 90 jours.",
                                icon: 'success',
                            })
                            let allClientsTemp = this.state.allClients.slice()
                            allClientsTemp[allClientsTemp.findIndex(elt => elt.empreinte === client.empreinte)].invalidation = true
                            this.setState({allClients:allClientsTemp})
                        }else{
                            Toast.fire({
                                title: response.data.resultat,
                                icon: 'error',
                            })
                        }
                    })
                }
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                {this.state.showBrowserView ? (
                    <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", overflowY:"scroll"}}>
                        <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110}} src={require("../../assets/clientOrange.png")} alt="imageDocument"/>
                        <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20}} src={require("../../assets/listBlue.png")} alt="imageAuction"/><h1 style={{ marginBottom:60}}>Gérer <span style={{color:"#52a8d9"}}>l'abonnement</span> de <span style={{color:"#f18f29"}}>vos clients</span></h1>
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column", width:"70%", padding:"3vw", paddingTop:"1vw", paddingBottom:"1vw",marginBottom:100 ,minWidth:500,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                            <Paper style={{margin:10, width:"95%", maxHeight:500, overflowY:"scroll"}}>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(this.state.allClients).map((row) => {
                                                return (
                                                    <>
                                                        {row.status === "client" && (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                {columns.map((column) => {
                                                                    return (
                                                                        <>
                                                                            {column.id === "Gestion" ?
                                                                                <TableCell key={column.id}>
                                                                                    <Button disabled={!!row.invalidation} onClick={()=>{this.GestionAbonnementClient(row)}}>
                                                                                        {row.abonnement === "Sans abonnement" ? (
                                                                                            <Tooltip arrow={true} title={"Démarrer l'abonnement de ce client"}>
                                                                                                <ShoppingCart style={{color:"#000"}}/>
                                                                                            </Tooltip>
                                                                                        ):(
                                                                                            <Tooltip arrow={true} title={"Annuler l'abonnement du client"}>
                                                                                                <Close style={{color:"#000"}}/>
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </Button>
                                                                                </TableCell>
                                                                                :
                                                                                <>
                                                                                    {column.id === "Invalider" ?
                                                                                        <TableCell key={column.id}>
                                                                                            <Button disabled={!this.state.isAdmin} onClick={()=>{this.InvalidationClient(row)}}>
                                                                                                {!!row.invalidation ? (
                                                                                                    <Tooltip arrow={true} title={"Annuler l'invalidation de ce client"}>
                                                                                                        <Cancel style={{color:"#000"}}/>
                                                                                                    </Tooltip>
                                                                                                ):(
                                                                                                    <Tooltip arrow={true} title={"Invalider le client"}>
                                                                                                        <Delete style={{color:"#000"}}/>
                                                                                                    </Tooltip>
                                                                                                )}
                                                                                            </Button>
                                                                                        </TableCell>
                                                                                        :
                                                                                        <TableCell key={column.id}>
                                                                                            {row[column.id]}
                                                                                        </TableCell>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    {this.state.displaySkeleton && (
                                        <div style={{width:"100%"}}>
                                            <Skeleton style={{margin:20,marginTop:40}}/>
                                            <Skeleton style={{margin:20}} />
                                            <Skeleton style={{margin:20}} />
                                        </div>
                                    )}
                                </TableContainer>
                            </Paper>
                        </div>
                    </div>
                ):(
                    <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", overflowY:"scroll"}}>
                        <h2 style={{ marginBottom:60, textAlign:"center"}}>Gérer <span style={{color:"#52a8d9"}}>l'abonnement</span> de <span style={{color:"#f18f29"}}>vos clients</span></h2>
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column", width:"95%",marginBottom:100,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                            <Paper style={{margin:10, width:"95%", maxHeight:500, overflowY:"scroll"}}>
                                <TableContainer>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(this.state.allClients).map((row) => {
                                                return (
                                                    <>
                                                        {row.status === "client" && (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                {columns.map((column) => {
                                                                    return (
                                                                        <>
                                                                            {column.id === "Gestion" ?
                                                                                <TableCell key={column.id}>
                                                                                    <Button onClick={()=>{this.GestionAbonnementClient(row)}}>
                                                                                        {row.abonnement === "Sans abonnement" ? (
                                                                                            <Tooltip arrow={true} title={"Démarrer l'abonnement de ce client"}>
                                                                                                <ShoppingCart/>
                                                                                            </Tooltip>
                                                                                        ):(
                                                                                            <Tooltip arrow={true} title={"Annuler l'abonnement de ce client"}>
                                                                                                <Close/>
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </Button>
                                                                                </TableCell>
                                                                                :
                                                                                <>
                                                                                    {column.id === "Invalider" ?
                                                                                        <TableCell key={column.id}>
                                                                                            <Button disabled={!this.state.isAdmin} onClick={()=>{this.InvalidationClient(row)}}>
                                                                                                {!!row.invalidation ? (
                                                                                                    <Tooltip arrow={true} title={"Annuler l'invalidation de ce client"}>
                                                                                                        <Cancel/>
                                                                                                    </Tooltip>
                                                                                                ):(
                                                                                                    <Tooltip arrow={true} title={"Invalider le client"}>
                                                                                                        <Delete/>
                                                                                                    </Tooltip>
                                                                                                )}
                                                                                            </Button>
                                                                                        </TableCell>
                                                                                        :
                                                                                        <TableCell key={column.id}>
                                                                                            {row[column.id]}
                                                                                        </TableCell>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        )}
                                                    </>

                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    {this.state.displaySkeleton && (
                                        <div style={{width:"100%"}}>
                                            <Skeleton style={{margin:20,marginTop:40}}/>
                                            <Skeleton style={{margin:20}} />
                                            <Skeleton style={{margin:20}} />
                                        </div>
                                    )}
                                </TableContainer>
                            </Paper>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(ListeDesClients)