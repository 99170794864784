import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {KeyboardReturn} from "@mui/icons-material";


class PDCSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{

        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if (document.body.offsetWidth <= 950) {
            this.setState({showBrowserView: false})
        }
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <div style={{width:"fit-content",display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                    <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Politique de confidentialité :</h1>
                                    <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:"100%", height:3, borderRadius:100, marginBottom:10}}></div>
                                </div>
                                <div className={"flexCenter"}>
                                    <button style={{padding:8, fontSize:14, marginRight:10}} onClick={()=>{this.props.navigate(-1)}} className={"divButtonBuyResale flexCenter"}><KeyboardReturn style={{marginRight:10, color:"#FFFFFF"}}/> Retour</button>
                                </div>
                            </div>
                            <div style={{width:"80%", display:"flex", alignItems:"center", justifyContent:"center", flexWrap:"wrap"}}>
                                <p>Application EKOCO MOBILE

                                    1 Mars 2021

                                    INTRODUCTION

                                    La protection de vos données personnelles est importante pour nous. Nous nous engageons à ne collecter que les données dont nous avons besoin pour vous assurer un service optimal, à en assurer la confidentialité et la sécurité, y compris lorsque nous faisons appel à des prestataires et à faciliter l’exercice de vos droits sur vos données.

                                    Nous nous engageons à traiter vos données dans le plus strict respect de la Loi Informatique et Libertés du 6 janvier 1978 (ci-après « loi IEL ») modifiée et du Règlement (UE) général sur la protection des données du 27 avril 2016 et du RGPD

                                    Nous ne traitons pas de catégories particulières de données (données sensibles) telles que les données énumérées à l’article 9 du RGPD qui révèlent l’origine raciale ou ethnique, les opinions politiques, les convictions religieuses ou philosophiques ou l’appartenance syndicale, des données génétiques, des données biométriques, des données concernant la santé ou des données concernant la vie sexuelle ou l’orientation sexuelle des personnes.

                                    La présente politique de confidentialité décrit les données personnelles que nous recueillons, comment elles sont utilisées et vos droits à cet égard. Elle s’applique à tout utilisateur qui accède à l’application et qui utilise les services proposés sur l’application.

                                    Nous nous réservons le droit de modifier la présente politique de confidentialité à tout moment. La version la plus actuelle de la présente politique régit notre utilisation de vos informations et sera toujours disponible sur l’application ou sur demande auprès de EKOCO.

                                    Si nous devions apporter une modification substantielle à cette politique de confidentialité, nous nous engageons à vous la notifier via votre adresse email.

                                    DONNEES QUE NOUS COLLECTIONS

                                    Dans le cadre de l’utilisation de nos services et de votre navigation sur notre application, EKOCO collecte plusieurs catégories de données dont vous trouverez le détail ci-dessous. Ces données proviennent :

                                    Des informations que vous nous communiquez lorsque vous vous inscrivez sur notre application, ou lorsque vous échangez avec EKOCO. Ces informations personnelles sont :
                                    Des données permettant votre identification (empreinte / hash, de votre speudo et votre clé de preuve)
                                    Le token d’identification généré par le serveur EKOCO. Il s’agit d’une liste de caractères uniques utilisée lors des communications avec le serveur pour identifier l’utilisateur.
                                    Des données de localisation

                                    Nous vous demandons l’autorisation pour collecter sur notre application les données précises de localisation de votre mobile via le système d’autorisation que le système d’exploitation de votre mobile utilise. Si vous autorisez la collecte de ces données, vous pouvez revenir ultérieurement sur votre choix en changeant les paramètres de localisation de votre téléphone portable. Cependant, cela limitera votre capacité à utiliser certaines fonctionnalités de nos services.

                                    Nous utilisons des cookies pour améliorer l’accès à notre application et l’expérience de l’utilisateur. Pour en savoir plus sur la politique d’utilisation de cookies.

                                    FINALITES

                                    EKOCO procède au traitement de vos données à caractère personnel pour des finalités déterminées, explicites et légitimes.

                                    En particulier, ces données sont destinées à :

                                    La création et la gestion de votre compte;
                                    L’utilisation de nos services ;
                                    Vous adressez des communications pertinentes et adaptées à vos besoins directement en lien avec les services du site ;
                                    Sécuriser le site ainsi que les données et échanges intervenant sur le site;
                                    Faire évoluer nos services pour vous offrir de nouvelles fonctionnalités et nous adapter à vos besoins ;
                                    Communiquer avec vous ;
                                    Répondre à une injonction des autorités légales et notamment pour lutter contre la fraude et plus généralement contre toute activité pénalement répréhensible.
                                    BASE JURIDIQUE DES TRAITEMENTS

                                    EKOCO traite la plupart de vos données personnelles dans le cadre du contrat que vous avez conclu lors de votre inscription sur l’application via l’acceptation de nos conditions générales d’utilisation. Toutefois, nous sommes susceptibles de traiter certaines données vous concernant sur la base de votre consentement, en raison d’obligations légales ou pour répondre à notre intérêt légitime à les traiter.

                                    DESTINATAIRES OU CATEGORIES DE DESTINATAIRES

                                    Nous conservons ou divulguons vos informations si nous estimons que cela est raisonnablement nécessaire pour satisfaire à toute obligation légale ou réglementaire, toute procédure juridique ou demande administrative, pour protéger la sécurité d’une personne, pour traiter tout problème de nature frauduleuse, sécuritaire ou technique, ou pour protéger les droits ou les biens de nos utilisateurs.

                                    EKOCO ne revend pas vos données et ne transmettra jamais vos données personnelles à aucun tiers susceptible de les utiliser à ses propres fins et notamment à des fins commerciales et/ou de publicité directe, sans votre consentement exprès. Par conséquent, EKOCO ne divulgue pas d’informations personnelles en dehors des situations décrites dans la présente politique de confidentialité.

                                    TRANSFERT DES DONNEES HORS UE

                                    Tous nos serveurs sur lesquels vos données sont conservées et ceux des prestataires utilisés pour échanger et stocker ces données sont localisées en Europe.

                                    DUREE DE CONSERVATION DES DONNEES

                                    EKOCO conserve vos informations tant que votre compte reste actif, à moins que vous ne demandiez leur suppression ou celle de votre compte. Dans certains cas, nous pouvons conserver des informations vous concernant en raison de la loi ou à d’autres fins, même si vous supprimez votre compte.

                                    Par ailleurs, les données permettant d’établir la preuve d’un droit ou d’un contrat, ou conservées au titre du respect d’une obligation légale, peuvent faire l’objet d’une politique d’archivage intermédiaire pour une période correspondant aux durées de prescription légale (et notamment le délai de droit commun de 5 ans).

                                    SECURITE DES DONNEES

                                    EKOCO met en œuvre les mesures techniques et organisationnelles appropriées afin de garantir la sécurité, la confidentialité, l’intégrité et la disponibilité des services et protéger les données contre la destruction, la perte, l’altération, la divulgation non autorisée de données à caractère personnel transmises, conservées ou traitées d’une autre manière, ou l’accès non autorisé à de telles données. La technologie utilisée est la Blockchain.



                                    DROIT D’INTRODUIRE UN RECOURS AUPRES D’UNE AUTORITE DE CONTROLE

                                    L’autorité de contrôle compétente pour connaître de toute demande nous concernant, est la Commission Nationale de l’Informatique et des Libertés (CNIL).Si souhaitez saisir la CNIL de toute demande, vous trouverez ci-dessous les coordonnées:

                                    CNIL (COMMISSION NATIONALE DE L’INFORMATIQUE ET DES LIBERTÉS)

                                    3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07

                                    Tél. : 01 53 73 22 22

                                    (du lundi au jeudi de 9h à 18h30 / le vendredi de 9h à 18h)

                                    Fax : 01 53 73 22 00

                                    RESPONSABLE DE TRAITEMENT DES DONNEES

                                    EKOCO     /SAS SPURO
                                    Siège social : 45 rue Pierre SEMARD
                                    Représentée par Benjamin FARAGGI, CEO</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PDCSTO)