import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import {Close, ContentPaste, Edit, Info, Warning} from "@mui/icons-material";
import Dropzone from "react-dropzone";
import jwt_decode from "jwt-decode";
import {Avatar, Backdrop, Checkbox, CircularProgress, TextField, Tooltip} from "@mui/material";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const marital= [
    "Marié(e)","Célibataire","Divorcé(e)","Autre"
]
const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let jwtDecoded;

class ProfilSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            returnUrl:null,
            ...this.setInitialStates(),
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    setInitialStates(){
        return{
            avatarBuffer:null,
            crop: {
                unit: 'px',
                x: 0,
                y: 0,
                width: 250,
                height: 250
            },
            croppedBuffer:null,
            empreinteAvatar:null,
            modifyAvatar:false,
            hoverImageEdit:false,
            refreshRandom:"",
            showBrowserView:true,
            modeModif:false,
            videosPreview:[],
            videosPreviewVente:[],
            empToGet:"",
            statusMaritalValue:"none",
            identityVerified:"unknown",
            showBackdrop:false,
            displayCNIOCR:false,
            numberCNIOCR:null,
            numberCNIOCRValue:null,
            typeID:null,
            tsv:{},
            checkConfirm:false,
            jwtDecodedAdmin:false,
            displayAdditionnalInfos:false
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        const td = new URLSearchParams(window.location.search).get("td")
        if(td !== null){
            this.setState({td})
        }
        const returnUrl = new URLSearchParams(window.location.search).get("return")
        if(returnUrl !== null){
            this.setState({returnUrl})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("SMSTO_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    this.setState({
                        empreinteAvatar:jwtDecoded.empreinte,
                        jwtDecodedAdmin:jwtDecoded.admin
                    })
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: this.state.jwt,
                    })
                    this.getInfos()
                }else{
                    sessionStorage.removeItem("SMSTO_jwt")
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: null,
                    })
                    window.location.replace("/StockMarketSTO/Accueil")
                }
            })
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    getInfos(){
        Axios.post(url+"getInfosSMSTO",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                this.setState({
                    defaultEmailValue:response.data.email,
                    emailValue:response.data.email,
                    defaultIbanValue: response.data.iban ? response.data.iban.replace(
                        /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})/,
                        '$1 $2 $3 $4 $5 $6 $7'
                    ):response.data.iban,
                    ibanValue:response.data.iban ? response.data.iban.replace(
                        /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})/,
                        '$1 $2 $3 $4 $5 $6 $7'
                    ):response.data.iban,
                    defaultBicValue: response.data.bic,
                    bicValue:response.data.bic,
                    defaultTelValue: response.data.tel,
                    telValue:response.data.tel,
                    statusMaritalValue:response.data.statusMarital,
                    defaultStatusMaritalValue:response.data.statusMarital,
                    adresseValue:response.data.adresse,
                    defaultAdresseValue:response.data.adresse,
                    nomValue:response.data.nom,
                    defaultNomValue:response.data.nom,
                    prenomValue:response.data.prenom,
                    defaultPrenomValue:response.data.prenom,
                    pseudoValue:response.data.pseudo,
                    defaultVilleValue:response.data.ville,
                    villeValue:response.data.ville,
                    defaultPostalValue:response.data.postal,
                    postalValue:response.data.postal,
                    defaultDobValue:response.data.ddn,
                    dobValue:response.data.ddn,
                    identityVerified:response.data.identityVerified,
                    cniDepot:response.data.cni,
                    jddDepot:response.data.jdd,
                    displayAdditionnalInfos:response.data.displayAdditionnalInfos
                },()=>{
                    this.setState({checkConfirm:!(!this.state.defaultPrenomValue && !this.state.defaultNomValue)})
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    handleChange(event) {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    handleChangePhone(event){
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if(onlyNums.length < 10){
            this.setState({
                [event.currentTarget.name] : onlyNums
            })
        } else if (onlyNums.length === 10){
            const number = onlyNums.replace(
                /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                '$1 $2 $3 $4 $5'
            );
            this.setState({
                [event.currentTarget.name] : number
            })
        }
    }

    handleChangeIBAN(event){
        const dataIban = event.target.value.replaceAll(" ","")
        if(dataIban.length < 27){
            this.setState({
                [event.currentTarget.name] : dataIban
            })
        } else if (dataIban.length === 27){
            const number = dataIban.replace(
                /(.{4})(.{4})(.{4})(.{4})(.{4})(.{4})(.{3})/,
                '$1 $2 $3 $4 $5 $6 $7'
            );
            this.setState({
                [event.currentTarget.name] : number
            })
        }
    }

    handleChangeCheckbox(e){
        this.setState({checkConfirm:!this.state.checkConfirm})
    }

    cropComplete = (finalCrop) =>{
        this.getCroppedImg(this.state.avatarBuffer)
    }

    getCroppedImg(imageParam) {
        let image = new Image()
        image.src = imageParam
        //On cap la height a 250 mais pas la width -> on cherche a savoir la width de l'image sur notre page
        let imageMinia = document.getElementById('imageMinia');
        let realWidth = imageMinia.clientWidth
        let realHeight = imageMinia.clientHeight

        const canvas = document.createElement("canvas");
        const scaleX = image.width / realWidth;
        const scaleY = image.height / realHeight;
        canvas.width = 250;
        canvas.height =  250;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            this.state.crop.x * scaleX,
            this.state.crop.y * scaleY,
            this.state.crop.width * scaleX,
            this.state.crop.height * scaleY,
            0,
            0,
            250,
            250
        )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImage = new File([u8arr], filename, {type:mime});
        let self = this
        const reader = new FileReader()
        reader.onload = () => {
            self.setState({croppedBuffer:reader.result})
        }
        reader.readAsArrayBuffer(croppedImage)
    }

    validateAvatar(){
        this.setState({clickedAvatarButton:true})
        Axios.post(url+"uploadAvatar",{buffer:Buffer.from(this.state.croppedBuffer,"base64")},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            this.setState({clickedAvatarButton:false})
            if(response.data.resultat === "done"){
                this.setState({modifyAvatar:false,refreshRandom:"?refresh="+new Date().getTime(), avatarBuffer:null,croppedBuffer:null})
                global.emitter.emit("modifyAvatarSTO")
                Toast.fire({
                    title:"Avatar modifié",
                    icon:"success"
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    async modifyInfos(e){
        e.preventDefault()
        const iban = this.state.ibanValue ? this.state.ibanValue.replaceAll(" ",""):this.state.ibanValue
        const dateSplit = this.state.dobValue.split("-")
        if(!!iban && iban.length !== 27){
            Swal.fire({
                title: 'Erreur!',
                text: "L'IBAN renseigné n'est pas valide.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }else if(!!this.state.telValue && this.state.telValue.length !== 14){
            Swal.fire({
                title: 'Erreur!',
                text: "Le téléphone renseigné n'est pas valide. (10 chiffres attendus)",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        }else if(parseInt(dateSplit[0]) < 1900 || parseInt(dateSplit[0]) > new Date().getFullYear()-18){
            if(parseInt(dateSplit[0]) < 1900){
                Swal.fire({
                    title: 'Erreur!',
                    text: "La date de naissance renseignée n'est pas valide. (Année inférieure à 1900)",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }else if(parseInt(dateSplit[0]) > new Date().getFullYear()-18){
                Swal.fire({
                    title: 'Erreur!',
                    text: "La date de naissance renseignée n'est pas valide. (Vous devez avoir plus de 18 ans)",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }else{
            this.setState({showBackdrop:true})
            let obj = {
                first_name:this.state.prenomValue.trim(),
                last_name:this.state.nomValue.trim(),
                phone:this.state.telValue && (("+33"+this.state.telValue.slice(1,this.state.telValue.length).replaceAll(" ",""))),
                email:this.state.emailValue.trim(),
                address: {
                    city:this.state.villeValue.trim(),
                    country:"FR",
                    line1:this.state.adresseValue.trim(),
                    postal_code:this.state.postalValue.trim(),
                },
            }
            if(!!this.state.dobValue){
                obj.dob = {
                    day:this.state.dobValue && this.state.dobValue.split("-")[2],
                    month:this.state.dobValue && this.state.dobValue.split("-")[1],
                    year:this.state.dobValue && this.state.dobValue.split("-")[0]
                }
            }

            try{

                Axios.post(url+"editInfosSMSTO",{
                    email:this.state.emailValue.trim(),
                    iban,
                    bic:this.state.bicValue,
                    tel:this.state.telValue,
                    adresse:this.state.adresseValue.trim(),
                    nom:this.state.nomValue.trim(),
                    prenom:this.state.prenomValue.trim(),
                    postal:this.state.postalValue.trim(),
                    ddn:this.state.dobValue,
                    ville:this.state.villeValue.trim(),
                    bufferCNI:this.state.bufferCNI,
                    bufferJDD:this.state.bufferJDD,
                    nameJDD:this.state.nameJDD,
                    nameCNI:this.state.nameCNI,
                },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if(response.data.resultat === "done" || response.data.resultat === "Votre iban n'est pas valide."){
                        if(response.data.resultat !== "Votre iban n'est pas valide."){
                            Swal.fire({
                                title: 'Erreur!',
                                text: response.data.resultat,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }
                        this.setState({
                            defaultEmailvalue:this.state.emailValue,
                            defaultIbanValue:this.state.ibanValue,
                            defaultBicValue:this.state.bicValue,
                            defaultTelValue:this.state.telValue,
                            defaultAdresseValue:this.state.adresseValue,
                            defaultNomValue:this.state.nomValue,
                            defaultPrenomValue:this.state.prenomValue,
                            defaultVilleValue:this.state.villeValue,
                            defaultDobValue:this.state.dobValue,
                            defaultPostalValue:this.state.postalValue,
                            defaultStatusMaritalValue:this.state.statusMaritalValue,
                            bufferCNI:null,
                            bufferJDD:null
                        })
                        let depotCNI, depotJDD
                        if(this.state.cni){
                            this.setState({depotCNI:true})
                            depotCNI = true
                        }
                        if(this.state.jdd){
                            this.setState({depotJDD:true})
                            depotJDD = true
                        }
                        if(depotCNI && depotJDD){
                            this.setState({identityVerified:"verified"})
                        }
                        Toast.fire({
                            title:"Informations modifiées",
                            icon:"success"
                        })
                        if(this.state.returnUrl){
                            this.props.navigate("/StockMarketSTO/PropositionDeVente?idTokenProposal="+this.state.returnUrl)
                        }
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }
                    this.setState({showBackdrop:false})
                })
            }catch (e) {
                Swal.fire({
                    title: 'Attention !',
                    text: "Vos informations ne peuvent pas être confirmées.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.setState({showBackdrop:false})
            }
        }
    }

    validButton(){
        let email = this.state.defaultEmailValue === this.state.emailValue,
            iban = this.state.defaultIbanValue === this.state.ibanValue,
            bic = this.state.defaultBicValue === this.state.bicValue,
            tel = this.state.defaultTelValue === this.state.telValue,
            adr = this.state.defaultAdresseValue === this.state.adresseValue,
            statMar = this.state.defaultStatusMaritalValue === this.state.statusMaritalValue,
            nom = this.state.defaultNomValue === this.state.nomValue,
            prenom = this.state.defaultPrenomValue === this.state.prenomValue,
            ville = this.state.defaultVilleValue === this.state.villeValue,
            postal = this.state.defaultPostalValue === this.state.postalValue,
            dob = this.state.defaultDobValue === this.state.dobValue,
            cni = !this.state.bufferCNI,
            jdd = !this.state.bufferJDD
        if(email && iban && bic && tel && adr && statMar && nom && prenom && ville && postal && dob && cni && jdd){
            return true
        }else{
            // On enable le bouton SAUF si la case n'est pas cochée
            return this.state.checkConfirm === false;
        }
    }

    allDone(){
        let email = this.state.emailValue,
            iban = this.state.ibanValue && this.state.displayAdditionnalInfos,
            bic = this.state.bicValue && this.state.displayAdditionnalInfos,
            tel = this.state.telValue,
            adr = this.state.adresseValue,
            // statMar = this.state.statusMaritalValue,
            nom = this.state.nomValue,
            prenom = this.state.prenomValue,
            ville = this.state.villeValue,
            postal = this.state.postalValue,
            dob = this.state.dobValue,
            cC = this.state.checkConfirm
        return !!(email && tel && adr && nom && prenom && ville && postal && dob && cC && iban && bic);
    }

    getMaxDateProfil(){
        let date = new Date()
        date.setDate(date.getDate())
        date = date.toISOString().split("T")[0]
        return date
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.showBackdrop}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                {/*<Dialog*/}
                {/*    //DIALOG POUR L'ANALYSE D'UNE PIECE D'IDENTITE*/}
                {/*    open={this.state.displayCNIOCR}*/}
                {/*    onClose={()=>{this.setState({displayCNIOCR:false, bufferCNI:null, numberCNIOCR:null,numberCNIOCRValue:null,nameCNI:null, cni:null})}}*/}
                {/*    style={{padding:11}}*/}
                {/*    fullScreen={!this.state.showBrowserView}*/}
                {/*    disableEscapeKeyDown={this.state.numberCNIOCR === null}*/}
                {/*    disableBackdropClick={this.state.numberCNIOCR === null}*/}
                {/*>*/}
                {/*    <DialogTitle>*/}
                {/*        <div style={{width:'100%', position:"relative"}}>*/}
                {/*            {this.state.numberCNIOCR && (*/}
                {/*                <div style={{alignSelf:"flex-end", position:"absolute",display:"flex", justifyContent:"flex-start", width:"100%", left:-15}}>*/}
                {/*                    <Tooltip title={"Retour"}>*/}
                {/*                        <IconButton onClick={()=>{this.setState({displayCNIOCR:false, bufferCNI:null, numberCNIOCR:null,numberCNIOCRValue:null,nameCNI:null, cni:null})}} style={{cursor:"pointer", margin:5, padding:5, color:"black"}}>*/}
                {/*                            <Close/>*/}
                {/*                        </IconButton>*/}
                {/*                    </Tooltip>*/}
                {/*                </div>*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*        <h3 style={{margin:0, fontWeight:100, marginLeft:this.state.numberCNIOCR && 28}}>Analyse de votre pièce d'identité</h3>*/}
                {/*    </DialogTitle>*/}
                {/*    <DialogContent className={"flexCenter"} style={{height:"100%", flexDirection:"column"}}>*/}
                {/*        {this.state.numberCNIOCR ? (*/}
                {/*            <div className={"flexCenter"} style={{flexDirection:"column"}}>*/}
                {/*                <div style={{position:"relative", width:"100%"}}>*/}
                {/*                    {this.state.tsv.display && (*/}
                {/*                        <div style={{position:"absolute", backgroundColor:"rgba(255, 0, 0, 0.35)", padding:"5px 10px 5px 10px", border:"1px solid red", width:this.state.tsv.width,height:this.state.tsv.height, top:this.state.tsv.top,left:this.state.tsv.left}}/>*/}
                {/*                    )}*/}
                {/*                </div>*/}
                {/*                {!!this.state.bufferCNI && (<img alt={"Carte d'identité"} style={{maxWidth: 350,marginBottom:10}} src={`data:${"image."+this.state.nameCNI.split(".")[1]};base64,${Buffer.from(this.state.bufferCNI).toString("base64")}`} />)}*/}
                {/*                {this.state.numberCNIOCR=== "inconnu" ? (*/}
                {/*                    <div className={"flexCenter"} style={{flexDirection:"column"}}>*/}
                {/*                        <h4 style={{marginBottom:0}}>Nous n'avons pas pû correctement détecter votre numéro </h4>*/}
                {/*                        <h4 style={{marginTop:0}}>de {this.state.typeID === "passeport" ? "passeport":"carte d'identité"}, veuillez le renseigner :</h4>*/}
                {/*                        {this.state.typeID === "oldCNI" && (*/}
                {/*                            <p style={{fontWeight:100, marginTop:0, fontStyle:"italic", fontSize:13}}>(numéro à 12 chiffres situé en haut du recto de la carte)</p>*/}
                {/*                        )}*/}
                {/*                        {this.state.typeID === "newCNI" && (*/}
                {/*                            <p style={{fontWeight:100, marginTop:0, fontStyle:"italic", fontSize:13}}>(code à 9 caractères en bas du recto de la carte)</p>*/}
                {/*                        )}*/}
                {/*                        {this.state.typeID === "passeport" && (*/}
                {/*                            <p style={{fontWeight:100, marginTop:0, fontStyle:"italic", fontSize:13}}>(code à 9 caractères en haut à droite du passeport)</p>*/}
                {/*                        )}*/}
                {/*                        {this.state.typeID === "unknown" && (*/}
                {/*                            <p style={{fontWeight:100, marginTop:0, fontStyle:"italic", fontSize:13}}>(Numéro à 12 chiffres ou code à 9 caractères selon votre document)</p>*/}
                {/*                        )}*/}
                {/*                        <TextField style={{width:"100%", marginBottom:15}} value={this.state.numberCNIOCRValue} name={"numberCNIOCRValue"} onChange={(e)=>{this.handleChangeOnlyNum(e)}} label={"Numéro du document"}  variant="outlined" InputLabelProps={{ shrink: true }}/>*/}
                {/*                    </div>*/}
                {/*                ):(*/}
                {/*                    <div className={"flexCenter"} style={{flexDirection:"column"}}>*/}
                {/*                        <h4 style={{marginBottom:0, marginTop:5}}>Numéro de {this.state.typeID === "passeport" ? "passeport":"carte"} détecté : {this.state.numberCNIOCR}</h4>*/}
                {/*                        <p style={{fontWeight:400, marginTop:5, fontSize:14, maxWidth:350}}>Veuillez vérifier que le numéro détecté soit égal aux*/}
                {/*                            {this.state.typeID === "oldCNI" && (*/}
                {/*                                " 12 chiffres situé en haut du recto de la carte"*/}
                {/*                            )}*/}
                {/*                            {this.state.typeID === "newCNI" && (*/}
                {/*                                " 9 caractères en bas du recto de la carte"*/}
                {/*                            )}*/}
                {/*                            {this.state.typeID === "passeport" && (*/}
                {/*                                " 9 caractères en haut à droite du passeport"*/}
                {/*                            )}*/}
                {/*                        </p>*/}
                {/*                    </div>*/}

                {/*                )}*/}
                {/*                <div className={"flexCenter"}>*/}
                {/*                    <button disabled={this.state.numberCNIOCR === "inconnu" && (!this.state.numberCNIOCRValue || (this.state.numberCNIOCRValue && this.state.numberCNIOCRValue.length !== 12))} onClick={()=>{this.setState({displayCNIOCR:false}) ; (!!this.state.numberCNIOCRValue && this.state.numberCNIOCRValue !== this.state.numberCNIOCR) &&  this.setState({numberCNIOCR:this.state.numberCNIOCRValue})}} className={"divButtonBuyResale"} style={{backgroundImage:!(this.state.numberCNIOCR === "inconnu" && (!this.state.numberCNIOCRValue || (this.state.numberCNIOCRValue && this.state.numberCNIOCRValue.length !== 12)))&& "linear-gradient(to right, #41ff00 0%, #34cb00 51%, #41ff00 100%)", margin:5, padding:18}}>Valider</button>*/}
                {/*                    {this.state.numberCNIOCR!== "inconnu" && (*/}
                {/*                        <button disabled={this.state.numberCNIOCR=== "inconnu"} onClick={()=>{this.setState({numberCNIOCR:"inconnu"})}} className={"divButtonBuyResale"} style={{margin:5, padding:18}}>Modifier</button>*/}
                {/*                    )}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        ):(*/}
                {/*            <div className={"flexCenter"} style={{flexDirection:"column"}}>*/}
                {/*                <h3 style={{fontWeight:100}} className={"loadingText"}>Lecture de votre document en cours</h3>*/}
                {/*                <CircularProgress style={{marginBottom:10}}/>*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*    </DialogContent>*/}
                {/*</Dialog>*/}
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100, marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Mon profil :</h1>
                                <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:185, height:3, borderRadius:100, marginBottom:20}}></div>
                            </div>
                            <div style={{width:"100%", display:"flex",marginTop:this.state.showBrowserView&&20, alignItems:this.state.showBrowserView ? "flex-start":"center", justifyContent:"center", flexDirection:this.state.showBrowserView ? "row":"column"}}>
                                <div className={"flexCenter"} style={{flex:1, flexDirection:"column"}}>
                                    <h3>Mon Avatar :</h3>
                                    {this.state.modifyAvatar ? (
                                        <>
                                            <div className={"flexCenter"}>
                                                <div style={{flex:1}}>
                                                    {this.state.avatarBuffer === null && (
                                                        <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                                            acceptedFiles.forEach((file)=>{
                                                                if(file.size >= sizeLimitFile){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        target:"#dialogUpload",
                                                                        title: 'Le fichier est trop lourd (>10GB) !'
                                                                    })
                                                                }else if(file.name.includes("##")){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        target:"#dialogUpload",
                                                                        title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                    })
                                                                }else if(!file.type.includes("image")){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        target:"#dialogUpload",
                                                                        title: 'Le fichier uploadé doit être une image !'
                                                                    })
                                                                }else if (file.name.includes(".zip")){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        target:"#dialogUpload",
                                                                        title: "L'enregistrement de zip n'est pas autorisé."
                                                                    })
                                                                }else{
                                                                    let self = this;
                                                                    const reader = new FileReader()
                                                                    reader.onload = () => {
                                                                        let img = new Image;
                                                                        img.onload = function() {
                                                                            self.setState({avatarBuffer:reader.result},()=>{
                                                                                let imageMinia = document.getElementById('imageMinia');
                                                                                let minDim = imageMinia.clientHeight > imageMinia.clientWidth ? imageMinia.clientWidth:imageMinia.clientHeight
                                                                                self.setState({
                                                                                    crop: {
                                                                                        unit: 'px',
                                                                                        x: 0,
                                                                                        y: 0,
                                                                                        width: minDim,
                                                                                        height: minDim
                                                                                    }
                                                                                },()=>{
                                                                                    self.getCroppedImg(self.state.avatarBuffer)
                                                                                })
                                                                            })
                                                                        };
                                                                        img.src = reader.result;
                                                                    }
                                                                    reader.readAsDataURL(file)
                                                                }
                                                            })
                                                        }}>
                                                            {({getRootProps, getInputProps}) => (
                                                                <section style={{textAlign:"center"}}>
                                                                    <div className={"flexCenter"} id={this.state.acceptedFile===null &&"hoverMoving"} {...getRootProps()} style={{width:250, height:250, margin:"0 20px 0 20px",borderRadius:10,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold",cursor:"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent"}}>
                                                                        <input {...getInputProps()} />
                                                                        <>
                                                                            <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFile && "#bbbbbb"}}>{this.state.avatarBuffer ? "Avatar déposée.":"Déposez votre avatar ici."}</h3>
                                                                        </>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    )}
                                                </div>
                                                <div style={{textAlign:"center"}}>
                                                    {this.state.avatarBuffer && (
                                                        // <img style={{width:"60%",maxHeight:270, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={`data:${"image"+"Miniature"};base64,${Buffer.from(this.state.avatarBuffer).toString("base64")}`} alt="miniature"/>
                                                        <ReactCrop
                                                            crop={this.state.crop}
                                                            onChange={(pixelCrop)=>{this.onCropChange(pixelCrop)}}
                                                            circularCrop={true}
                                                            aspect={1}
                                                            keepSelection={true}
                                                            onComplete={(pixelCrop)=>{this.cropComplete(pixelCrop)}}
                                                        >
                                                            <img id={"imageMinia"} style={{width:"100%",maxHeight:250, borderRadius:5,boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"}} src={this.state.avatarBuffer} alt="avatar"/>
                                                        </ReactCrop>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={"flexCenter"} style={{marginTop:20}}>
                                                <button disabled={!this.state.croppedBuffer || this.state.clickedAvatarButton} onClick={()=>{this.validateAvatar()}} className={"divButtonBuyResale"}>Valider l'avatar</button>
                                                <button className={"divButtonBuyResale"} onClick={()=>{this.setState({modifyAvatar:false,avatarBuffer:null,croppedBuffer:null, crop: {unit: 'px',x: 0, y: 0, width: 300, height: 300}})}} style={{marginLeft:5, backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)", padding:10}}><Close style={{color:"#FFFFFF"}}/></button>
                                            </div>
                                        </>
                                    ):(
                                        <>
                                            {this.state.empreinteAvatar && (
                                                <>
                                                    {this.state.hoverImageEdit && (
                                                        <div style={{position:"relative", width:"fit-content"}}>
                                                            <Edit style={{position:"absolute", color:"white",pointerEvents:"none", zIndex:1, marginLeft:0, marginRight:0, left:-12, right:0, top:140, transform:"scale(1.5)", textAlign:"center"}}/>
                                                        </div>
                                                    )}
                                                    <div style={{width:"fit-content", height:"fit-content", borderRadius:"50%", boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px", padding:10}}>
                                                        <Avatar
                                                            variant={"circular"}
                                                            onClick={()=>{this.setState({modifyAvatar:true,hoverImageEdit:false})}}
                                                            onMouseEnter={()=>{this.setState({hoverImageEdit:true})}}
                                                            onMouseLeave={()=>{this.setState({hoverImageEdit:false})}}
                                                            className={"imageProfilPreview"}
                                                            src={`${url}images/avatar/${this.state.empreinteAvatar}.png${this.state.refreshRandom}`}
                                                            alt={jwtDecoded && jwtDecoded.pseudo.toUpperCase()}
                                                            style={{width:250, height:250, fontSize:90}}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {/*<h3 style={{marginBottom:10}}>Vérification d'identité :</h3>*/}
                                    {(!this.state.defaultPrenomValue && !this.state.defaultNomValue) ? (
                                        <>
                                            <p style={{margin:0, fontWeight:100, padding:20, paddingBottom:0, textAlign:"justify", marginBottom:10, maxWidth:500, fontSize:16}}>
                                                Après avoir investi et dans le cadre de l’assemblée générale qui confirmera votre participation au capital d'une société,
                                                nous vous demanderons une photocopie de votre carte d'identité ou passeport, et un justificatif de domicile.
                                            </p>
                                            <div className={"flexCenter"}>
                                                <p style={{margin:0, fontWeight:500, textAlign:"justify", fontSize:16}}>
                                                    J'ai bien compris et j'accepte
                                                </p>
                                                <Checkbox
                                                    checked={this.state.checkConfirm}
                                                    onChange={(e)=>{this.handleChangeCheckbox(e)}}
                                                />
                                            </div>
                                        </>
                                    ):(
                                        <>
                                            <h3>Mon empreinte :</h3>
                                            <p style={{cursor:"pointer"}} onClick={()=>{
                                                navigator.clipboard.writeText(jwtDecoded.empreinte)
                                                Swal.fire({
                                                    title: 'Empreinte copiée !',
                                                    icon: 'info',
                                                    confirmButtonText: 'Ok'
                                                })
                                            }} className={"flexCenter"}>{jwtDecoded.empreinte.slice(0,5)} ... {jwtDecoded.empreinte.slice(60,64)} <button style={{marginLeft:10, padding:5}} className={"divButtonBuyResale"}><ContentPaste/> </button> </p>
                                        </>

                                    )}


                                    {(this.state.displayAdditionnalInfos||this.state.td === "missingInfos") && (
                                        <>
                                            {this.state.identityVerified === "pending" || this.state.identityVerified === "verified" ? (
                                                <h4 style={{fontWeight:100}}>Votre compte est <span style={{fontWeight:500,color:this.state.identityVerified === "pending" ? "orange":"#34cb00"}}>{this.state.identityVerified === "pending" ? "en cours de vérification.":"vérifié"}</span>.</h4>
                                            ):(
                                                <>
                                                    <h4 style={{fontWeight:100, margin:0, marginTop:10}}>Vous n'avez <span style={{fontWeight:500,color:"#ff0000"}}>pas encore déposé</span> un ou plusieurs justificatif(s) :</h4>
                                                    <div className={"flexCenter"}>
                                                        <Warning style={{color:"red", marginRight:5}}/>
                                                        <p style={{color:"red",fontWeight:400}}>Documents obligatoires :</p>
                                                    </div>
                                                    {!this.state.cniDepot && (
                                                        <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                                            acceptedFiles.forEach((file)=>{
                                                                if(file.size >= 10737418){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        title: 'Le fichier est trop lourd (>10MB) !'
                                                                    })
                                                                }else if(file.name.includes("##")){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                    })
                                                                }else if(!file.type.includes("image")){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        title: 'Le fichier uploadé doit être une image !'
                                                                    })
                                                                }else if (file.name.includes(".zip")){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        title: "L'enregistrement de zip n'est pas autorisé."
                                                                    })
                                                                }else{
                                                                    this.setState({cni:file})
                                                                    let self = this;
                                                                    const reader = new FileReader()
                                                                    reader.onload = () => {
                                                                        let img = new Image;
                                                                        img.onload = function() {
                                                                            //ON SAUVEGARDE L'IMAGE COMPLETE DANS UN STATE :
                                                                            self.setState({bufferCNIToCrop:reader.result},()=>{
                                                                                let arr = self.state.bufferCNIToCrop.split(','),
                                                                                    mime = arr[0].match(/:(.*?);/)[1],
                                                                                    bstr = atob(arr[1]),
                                                                                    n = bstr.length,
                                                                                    u8arr = new Uint8Array(n);
                                                                                while(n--){
                                                                                    u8arr[n] = bstr.charCodeAt(n);
                                                                                }
                                                                                let fullImage = new File([u8arr], "cni.jpg", {type:mime});
                                                                                const reader = new FileReader()
                                                                                reader.onload = () => {
                                                                                    self.setState({bufferCNI:Buffer.from(reader.result,"base64"),nameCNI:file.name})
                                                                                    self.setState({numberCNIOCR:"noAnalyse"})
                                                                                }
                                                                                reader.readAsArrayBuffer(fullImage)
                                                                            })

                                                                            //ON CROP ET RESIZE L'IMAGE :
                                                                            // getCroppedImgCNI(reader.result)
                                                                            // function getCroppedImgCNI(imageParam) {
                                                                            //     let image = new Image()
                                                                            //     image.src = imageParam
                                                                            //
                                                                            //
                                                                            //     const canvas = document.createElement("canvas");
                                                                            //     canvas.width = image.width;
                                                                            //     canvas.height =  image.height/2;
                                                                            //     const ctx = canvas.getContext("2d");
                                                                            //     ctx.filter = "contrast(200) brightness(4) grayscale(100)"
                                                                            //     ctx.drawImage(
                                                                            //         image,
                                                                            //         0,
                                                                            //         image.height/2,
                                                                            //         image.width,
                                                                            //         image.height/2,
                                                                            //         0,
                                                                            //         0,
                                                                            //         image.width,
                                                                            //         image.height/2
                                                                            //     )
                                                                            //     self.setState({ratioImage:image.width/350,imageHeight:image.height})
                                                                            //
                                                                            //     //On compresse l'image : bcp si > 100kb sinon juste de moitié
                                                                            //     let qualityReducer = file.size < 100000 ? 0.5:0.1
                                                                            //     const reader = new FileReader()
                                                                            //     canvas.toBlob(blob => {
                                                                            //         reader.readAsDataURL(blob)
                                                                            //         reader.onloadend = () => {
                                                                            //             dataURLtoFileCNI(reader.result, 'cropped.jpg')
                                                                            //         }
                                                                            //     },"image/jpeg",qualityReducer)
                                                                            // }
                                                                            //
                                                                            // function dataURLtoFileCNI(dataurl, filename) {
                                                                            //     let arr = dataurl.split(','),
                                                                            //         mime = arr[0].match(/:(.*?);/)[1],
                                                                            //         bstr = atob(arr[1]),
                                                                            //         n = bstr.length,
                                                                            //         u8arr = new Uint8Array(n);
                                                                            //
                                                                            //     while(n--){
                                                                            //         u8arr[n] = bstr.charCodeAt(n);
                                                                            //     }
                                                                            //     let croppedImage = new File([u8arr], filename, {type:mime});
                                                                            //     const reader = new FileReader()
                                                                            //     reader.onload = () => {
                                                                            //         self.setState({croppedBufferCNI:Buffer.from(reader.result,"base64")},()=>{
                                                                            //             self.setState({nameCNI:file.name,displayCNIOCR:true},()=> {
                                                                            //                 Axios.post(url+"readCNI", {
                                                                            //                     bufferCNI:self.state.croppedBufferCNI
                                                                            //                 }).then(response => {
                                                                            //                     if(response.data.resultat === "found"){
                                                                            //                         //TSV = tableau avec des coordonnées en fonction des symboles détectés
                                                                            //                         let tsv = response.data.tsv
                                                                            //                         //On retire les symboles inutiles
                                                                            //                         tsv = tsv.split("\t")
                                                                            //                         let indexUtile = tsv.findIndex(elt => elt.includes(response.data.numberCNI))
                                                                            //                         tsv = tsv.slice(indexUtile-5,indexUtile+1)
                                                                            //                         let ligneUtile = tsv[tsv.length-1].replaceAll("\n","")
                                                                            //                         //On compte les caractères inutiles a droite et a gauche dans la ligne
                                                                            //                         let nomberCarRight = ligneUtile.split(response.data.numberCNI)[1].length
                                                                            //                         let nomberCarLeft = ligneUtile.split(response.data.numberCNI)[0].length
                                                                            //                         //On calcule la width, height, top, left en prenant en compte le ratio de l'image affiché sur internet
                                                                            //                         let widthTotal = parseFloat(tsv[2])/self.state.ratioImage
                                                                            //                         let widthOneCar = widthTotal/(ligneUtile.length)
                                                                            //                         let offsetLeft = parseFloat(tsv[0])/self.state.ratioImage + nomberCarLeft*(widthOneCar+1)
                                                                            //                         if(response.data.typeID === "newCNI"){
                                                                            //                             offsetLeft+=10
                                                                            //                         }
                                                                            //                         let widthToDisplay = widthTotal-offsetLeft-nomberCarRight*widthOneCar
                                                                            //                         let tsvObject = {
                                                                            //                             left:offsetLeft,
                                                                            //                             top:(parseFloat(tsv[1])/self.state.ratioImage-5)+self.state.imageHeight/2/self.state.ratioImage,
                                                                            //                             width:widthToDisplay,
                                                                            //                             height:parseFloat(tsv[3])/self.state.ratioImage,
                                                                            //                             display:true
                                                                            //                         }
                                                                            //                         let numberCNICorrige = response.data.numberCNI
                                                                            //                         if(response.data.typeID==="passeport"){
                                                                            //                             numberCNICorrige = numberCNICorrige.replaceAll("I","1")
                                                                            //                             numberCNICorrige = numberCNICorrige.replaceAll("S","5")
                                                                            //                             numberCNICorrige = numberCNICorrige.replaceAll("O","0")
                                                                            //                         }
                                                                            //                         self.setState({
                                                                            //                             numberCNIOCR : numberCNICorrige,
                                                                            //                             typeID:response.data.typeID,
                                                                            //                             tsv:tsvObject
                                                                            //                         })
                                                                            //                     }else{
                                                                            //                         self.setState({
                                                                            //                             numberCNIOCR :"inconnu",
                                                                            //                             typeID:"unknown",
                                                                            //                             tsv: {display:false}
                                                                            //                         })
                                                                            //                     }
                                                                            //
                                                                            //                 }).catch(error => {
                                                                            //                     console.log(error)
                                                                            //                 });
                                                                            //             })
                                                                            //         })
                                                                            //     }
                                                                            //     reader.readAsArrayBuffer(croppedImage)
                                                                            // }
                                                                        };
                                                                        img.src = reader.result;
                                                                    }
                                                                    reader.readAsDataURL(file)
                                                                }
                                                            })
                                                        }}>
                                                            {({getRootProps, getInputProps}) => (
                                                                <section style={{textAlign:"center"}}>
                                                                    <div className={"flexCenter"} id={this.state.acceptedFile===null &&"hoverMoving"} {...getRootProps()} style={{flexDirection:"column"}}>
                                                                        <input {...getInputProps()} />
                                                                        <p style={{margin:2,pointerEvents: "none", fontWeight:400, fontStyle:"italic"}}>Image .png ou .jpg</p>
                                                                        <div className={"flexCenter"}>
                                                                            <button className={"divButtonDropZone"} >{this.state.cni ? ("Pièce d'identité déposée ✔"):("Déposez une pièce d'identité")}</button>

                                                                            <Tooltip title={"Une pièce d'identité est requise pour devenir actionnaire d'une entreprise. (carte d'identité, passeport, permis...). Si vous possédez une nouvelle carte d'identité (>2021) veuillez déposer le verso."}>
                                                                                <Info style={{color:"#a2a2a2", marginLeft:5}}/>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>
                                                                    {/*{this.state.cni && (*/}
                                                                    {/*    <TextField style={{marginTop:10}} name={"numberCNIOCR"} onChange={(e)=>{this.handleChange(e)}} variant={"outlined"} label={"Numéro de pièce d'identité"}></TextField>*/}
                                                                    {/*)}*/}
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    )}
                                                    {!this.state.jddDepot && (
                                                        <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                                            acceptedFiles.forEach((file)=>{
                                                                if(file.size >= 10737418){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        title: 'Le fichier est trop lourd (>10MB) !'
                                                                    })
                                                                }else if(file.name.includes("##")){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        title: 'Le nom du fichier ne doit pas comporter "##" !'
                                                                    })
                                                                }else if(!file.type.includes("application/pdf")){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        title: 'Le fichier doit être un pdf !'
                                                                    })
                                                                }else if (file.name.includes(".zip")){
                                                                    Toast.fire({
                                                                        icon: 'error',
                                                                        title: "L'enregistrement de zip n'est pas autorisé."
                                                                    })
                                                                }else{
                                                                    this.setState({jdd:file})
                                                                    let self = this;
                                                                    const reader = new FileReader()
                                                                    reader.onload = () => {
                                                                        self.setState({nameJDD:file.name,bufferJDD:Buffer.from(reader.result,"base64")})
                                                                    }
                                                                    reader.readAsArrayBuffer(file)
                                                                }
                                                            })
                                                        }}>
                                                            {({getRootProps, getInputProps}) => (
                                                                <section style={{textAlign:"center"}}>
                                                                    <div className={"flexCenter"} id={this.state.acceptedFile===null &&"hoverMoving"} {...getRootProps()} style={{marginTop:30, flexDirection:"column"}}>
                                                                        <input {...getInputProps()} />
                                                                        <p style={{margin:2,pointerEvents: "none", fontWeight:400, fontStyle:"italic"}}>Fichier en format pdf </p>
                                                                        <div className={"flexCenter"}>
                                                                            <button className={"divButtonDropZone"} >{this.state.jdd ? ("Justificatif déposée ✔"):("Déposez un justificatif de domicile")}</button>
                                                                            <Tooltip title={"Un justificatif de domicile est nécessaire pour pouvoir vous faire des virements et prouver votre identité. (quittance de loyer, facture d'eau/électricité ...)"}>
                                                                                <Info style={{color:"#a2a2a2", marginLeft:5}}/>
                                                                            </Tooltip>
                                                                        </div>

                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                <form onSubmit={(e)=>{this.modifyInfos(e)}} className={"flexCenter"} style={{flex:5, flexDirection:"column", width:"100%", maxWidth:750}}>
                                    <h3>Mes informations :</h3>
                                    <TextField required={true} disabled={true} style={{width:"100%", marginBottom:15, color:"#000", backgroundColor:"#f3f3f3",borderRadius:3}} name={"pseudoValue"} defaultValue={this.state.pseudoValue} value={this.state.pseudoValue} label="Identifiant" variant="outlined" InputLabelProps={{ shrink: true }} inputProps={{ style: { color: "#000" } }}/>
                                    <div style={{width:"100%", display:"flex", alignItems:"center",marginBottom:(!!this.state.defaultPrenomValue && !!this.state.defaultNomValue) && 15, justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                        <TextField required={true} disabled={!!this.state.defaultPrenomValue} style={{width:this.state.showBrowserView ? "34%":"100%", color:!!this.state.defaultPrenomValue&&"#000",marginBottom:!this.state.showBrowserView && 15, backgroundColor:!!this.state.defaultPrenomValue &&"#f3f3f3",borderRadius:3}} name={"prenomValue"} defaultValue={this.state.prenomValue} value={this.state.prenomValue} onChange={(e)=>{this.handleChange(e)}} label="Prénom" variant="outlined" InputLabelProps={{ shrink: true }} inputProps={{ style: { color: "#000" } }}/>
                                        <TextField required={true} disabled={!!this.state.defaultNomValue} style={{width:this.state.showBrowserView ? "64%":"100%", color:!!this.state.defaultNomValue && "#000", backgroundColor:!!this.state.defaultNomValue && "#f3f3f3",borderRadius:3}} name={"nomValue"} defaultValue={this.state.nomValue} value={this.state.nomValue} onChange={(e)=>{this.handleChange(e)}} label="Nom" variant="outlined" InputLabelProps={{ shrink: true }} inputProps={{ style: { color:"#000" } }}/>
                                    </div>
                                    {(!this.state.defaultPrenomValue || !this.state.defaultNomValue) && (
                                        <p className={"flexCenter"} style={{marginBottom: 0,color:"red", marginTop:0, fontSize:12}}> <Warning/> Le nom et prénom ne peuvent plus être modifiés ultérieurement</p>
                                    )}
                                    <TextField required={true} style={{width:"100%", marginBottom:15}} type={"email"} name={"emailValue"} defaultValue={this.state.emailValue} value={this.state.emailValue} onChange={(e)=>{this.handleChange(e)}} label="Email" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                    <TextField type={"date"} required={true} style={{width:"100%", marginBottom:15}} name={"dobValue"} value={this.state.dobValue} onChange={(e)=>{this.handleChange(e)}} label="Date de naissance" variant="outlined" InputLabelProps={{
                                        shrink: true
                                    }}inputProps={{ max:`${this.getMaxDateProfil()}`}}></TextField>
                                    <TextField required={true} style={{width:"100%", marginBottom:15}} name={"telValue"} defaultValue={this.state.telValue} value={this.state.telValue} onChange={(e)=>{this.handleChangePhone(e)}} label="Téléphone" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                    <TextField required={true} style={{width:"100%", marginBottom:15}} name={"adresseValue"} defaultValue={this.state.adresseValue} value={this.state.adresseValue} onChange={(e)=>{this.handleChange(e)}} label="Adresse postale" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                    <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                        <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"postalValue"} defaultValue={this.state.postalValue} value={this.state.postalValue} onChange={(e)=>{this.handleChange(e)}} label="Code postal" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                        <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"villeValue"} defaultValue={this.state.villeValue} value={this.state.villeValue} onChange={(e)=>{this.handleChange(e)}} label="Ville" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                    </div>
                                    {/*<FormControl required={true} variant={"outlined"} style={{width:"100%"}}>*/}
                                    {/*    <InputLabel>Statut Marital</InputLabel>*/}
                                    {/*    <Select*/}
                                    {/*        key="statusMarital"*/}
                                    {/*        value={this.state.statusMaritalValue}*/}
                                    {/*        defaultValue={this.state.statusMaritalValue !== "none" ? this.state.statusMaritalValue:""}*/}
                                    {/*        onChange={(event)=>{*/}
                                    {/*            this.setState({*/}
                                    {/*                statusMaritalValue:event.target.value*/}
                                    {/*            })*/}
                                    {/*        }}*/}
                                    {/*        style={{width:"100%", color:"black"}}*/}
                                    {/*        label={"Statut Marital"}*/}
                                    {/*    >*/}
                                    {/*        {marital.map(mar=>(*/}
                                    {/*            <MenuItem value={mar}>{mar}</MenuItem>*/}
                                    {/*        ))}*/}

                                    {/*    </Select>*/}
                                    {/*</FormControl>*/}
                                    {/*IBAN ET BIC (retiré pour le lancement)*/}
                                    {(this.state.displayAdditionnalInfos||this.state.td === "missingInfos") && (
                                        <>
                                            <h3 style={{marginBottom:0}}>Utile pour la revente d'actions :</h3>
                                            <p style={{fontWeight:100, marginTop:0, fontSize:14, fontStyle:"italic"}}>(Vous pouvez <span style={{fontWeight:500}}>investir</span> sans avoir renseigné ce champ)</p>
                                            <div style={{width:"100%", marginTop:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                <TextField style={{width:this.state.showBrowserView ? "69%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"ibanValue"} defaultValue={this.state.ibanValue} value={this.state.ibanValue} onChange={(e)=>{this.handleChangeIBAN(e)}} label="IBAN personnel" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                                <TextField style={{width:this.state.showBrowserView ? "29%":"100%"}} name={"bicValue"} defaultValue={this.state.bicValue} value={this.state.bicValue} onChange={(e)=>{this.handleChange(e)}} label="BIC personnel" variant="outlined" InputLabelProps={{ shrink: true }}/>
                                            </div>
                                        </>
                                    )}

                                    {this.state.jwtDecodedAdmin ? (
                                        <>
                                            <h2>Pas de modification de compte en tant qu'admin</h2>
                                        </>
                                    ):(
                                        <>
                                            {(this.validButton() && this.allDone()) ? (
                                                <button className={"divButtonBuyResale"} style={{marginTop:20}} onClick={()=>{this.props.navigate("/StockMarketSTO/ListeLDF")}} >Profil complété - Investissez dans une levée de fonds</button>
                                            ):(
                                                <button disabled={this.validButton()} className={"divButtonBuyResale"} style={{marginTop:20}} type={"submit"} >Valider mes informations</button>
                                            )}
                                        </>
                                    )}

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ProfilSTO)