import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import Axios from "axios";
import {Backdrop} from "@mui/material";
import jwt_decode from "jwt-decode";

import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import PayPalButton from "../../Components/PaypalButtonEnglish";

const {baseUrl, paypalPublicKey, suffix} = config;
const url = baseUrl + suffix

const swalCustomClasses = {
    title: 'ChampFont ChampFontTitle',
    content: 'ChampFont ChampFontContent',
    confirmButton: 'ChampFont ChampFontButton',
    cancelButton: 'ChampFont ChampFontButton',
    container: "ToastGNFT-container",
    icon: "SwalGNFT-icon",
}

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    marginTop:"20px",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: swalCustomClasses,
    target:"#dialog-catalog-GNFT"
    // confirmButtonTextColor: "#3b3b3b",
})

const ToastGNFT = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFontTitleToast',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    },
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded = {}

class SalesProposal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            bottleArray:[],
            bottleSelected:null,
            displayLoading:false,
            proposal:null,
            paypalPayment:false,
            hidePage:true
        };
        this.createOrderPaypal = this.createOrderPaypal.bind(this);

    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    _emitConnectedGNFT;
    componentWillUnmount(){
        window.removeEventListener('resize', this.resizePage)
        this._emitConnectedGNFT.remove()
    }

    componentDidMount() {
        let idTokenProposal = new URLSearchParams(window.location.search).get("idTokenProposal"),
            idTokenBottle = new URLSearchParams(window.location.search).get("idTokenBottle")
        if(idTokenProposal !== null) {
            this.setState({
                idTokenProposal,idTokenBottle
            })
        }else{
            SwalDefault.fire({
                title: 'Error!',
                text: "This proposal does not exist.",
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.props.navigate("/GrapeNFT/Catalog")
            })
        }
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedGNFT = global.emitter.addListener("emitConnectedGNFT",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("GNFT_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedGNFT", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("GNFT_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedGNFT", {
                        jwt: this.state.jwt,
                    })
                    this.setState({displayLoading:false,connected:true},()=>{
                        this.getSalesProposal()
                    })
                }else{
                    global.emitter.emit("askConnectionGNFT",{disableOutsideClick:true})
                }
            })
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    getSalesProposal(){
        Axios.post(url+"getSalesProposal",{idTokenBottle:this.state.idTokenBottle},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            this.setState({displayLoading:false})
            if (response.data.resultat === "done"){
                this.setState({proposal:response.data.informations})
            }else{
                SwalDefault.fire({
                    title: 'Error!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    this.props.navigate("/GrapeNFT/Catalog")
                })
            }
        })
    }

    createOrderPaypal(){
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        this.setState({disableButtons:true, displayLoading:true})
        return new Promise((resolve, reject) => {
            Axios.post(url+"reserveBottle",{idTokenBottle:this.state.proposal.idTokenBottle, idTokenPack:this.state.proposal.idTokenPack,price:this.state.proposal.price},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                this.setState({displayLoading:false})
                if(response.data.resultat === "done") {
                    resolve(response.data.orderPaypalID)
                }else{
                    this.setState({disableButtons:false})
                    SwalDefault.fire({
                        title:"Error",
                        text:response.data.resultat,
                        icon:"error",
                        customClass:{
                            ...swalCustomClasses,
                            popup:!this.state.showBrowserView && "swalGNFT-mobile"
                        }
                    })
                    reject(response.data.resultat)
                }
            })
        })
    }

    denyProposal(){
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        this.setState({disableButtons:true})
        SwalDefault.fire({
            title: 'Are you sure?',
            text: "You will deny this proposal.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b3b3b',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, deny !',
            customClass:{
                ...swalCustomClasses,
                popup:!this.state.showBrowserView && "swalGNFT-mobile"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({displayLoading:true})
                Axios.post(url+"denySalesProposal",{idTokenBottle:this.state.idTokenBottle},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    this.setState({displayLoading:false})
                    if(response.data.resultat === "done") {
                        ToastGNFT.fire({
                            icon: 'success',
                            title: "Sales proposal denied."
                        })
                        this.props.navigate("/GrapeNFT/Catalog")
                    }else{
                        this.setState({disableButtons:false})
                        SwalDefault.fire({
                            title:"Error",
                            text:response.data.resultat,
                            icon:"error",
                            customClass:{
                                ...swalCustomClasses,
                                popup:!this.state.showBrowserView && "swalGNFT-mobile"
                            }
                        })
                    }
                })
            }else{
                this.setState({disableButtons:false})
            }
        })
    }

    render() { //Affichage sur la page
        return (
            <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", overflowX:"hidden", flexDirection:"column" ,height:"100%",width:"100%"}}>
                <Backdrop
                    sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: 999999999 }}
                    open={this.state.displayLoading}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <div className={"loaderTextGNFT ChampFont"}>Loading</div>
                        <h2 className={"ChampFont"} style={{color:"#FFBB41FF"}}>Please wait a few seconds while we load the proposal</h2>
                    </div>
                </Backdrop>
                <div className={"flexCenter"} style={{height:"calc(100vh - 70px)", marginTop:70, width:"100%", overflow:"hidden", justifyContent:"space-between"}}>
                    {this.state.proposal !== null && (
                        <div className={"flexCenter"} style={{flex:"1 1 10%", overflow:!this.state.showBrowserView && "auto", height:"100%", alignItems:!this.state.showBrowserView&&"flex-start", zIndex:99,background: "linear-gradient(125deg, rgba(42,44,36,1) 0%, rgba(15,18,0,1) 100%)"}}>
                            <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", width:this.state.showBrowserView ? "100%":"95%", flexDirection:!this.state.showBrowserView && "column"}}>
                                <div className={"flexCenter"} style={{width:this.state.showBrowserView ? "60%":"95%", marginLeft:this.state.showBrowserView && "2%", flexDirection:"column", alignItems:"flex-start"}}>
                                    <h1 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:60, marginBottom:15}}>
                                        Sales proposal
                                    </h1>
                                    {this.state.paypalPayment ? (
                                        <div className={"flexCenter"} style={{flexDirection:"column", width:"100%"}}>
                                            <div className={"flexCenter"} style={{flexDirection:"column",width:"100%"}}>
                                                <h1 className={"ChampFont flexCenter"} style={{color:"#FFF", whiteSpace:"nowrap",maxWidth:"100%", fontWeight:100, width:"100%", justifyContent:"center"}}>
                                                    {this.state.proposal.brand.nom} - {this.state.proposal.bottle.name}
                                                </h1>
                                                <div className={"flexCenter"} style={{maxWidth:"100%", width:"100%", flexDirection:!this.state.showBrowserView && "column"}}>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:100, width:"100%", margin:5}}>
                                                        Price : <span style={{fontWeight:700, marginLeft:10}}>{this.state.proposal.price}€</span>
                                                    </h2>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:100, width:"100%", margin:5}}>
                                                        Year : <span style={{fontWeight:700, marginLeft:10}}>{this.state.proposal.bottle.year}</span>
                                                    </h2>
                                                    <h2 className={"ChampFont flexCenter"} style={{color:"#FFF", fontWeight:100, width:"100%", margin:5}}>
                                                        Volume : <span style={{fontWeight:700, marginLeft:10}}>{this.state.proposal.bottle.volume}L</span>
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"100%",height:"100%",flexDirection:"column", justifyContent:"space-between", marginTop:10}}>
                                                <div className={"flexCenter"} style={{maxWidth:750, width:"75%"}}>
                                                    <PayPalScriptProvider options={{ "client-id": paypalPublicKey, currency: "EUR", locale:"en_US" }}>
                                                        <PayPalButton onCreateOrder={this.createOrderPaypal} onApproveOrder={()=>{this.setState({displayLoading:true})}} />
                                                    </PayPalScriptProvider>
                                                </div>
                                            </div>
                                        </div>

                                    ):(
                                        <React.Fragment>
                                            <h2 className={"ChampFont"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100}}>
                                                <span style={{fontWeight:"bold"}}>{this.state.proposal.pseudoEmetteur}</span> has sent you the following sales proposal :
                                            </h2>
                                            <h1 className={"ChampFont flexCenter"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, width:"100%", justifyContent:"center"}}>
                                                {this.state.proposal.brand.nom} - {this.state.proposal.bottle.name} for <span style={{fontSize:35, fontWeight:700, marginLeft:10}}>{this.state.proposal.price}€</span>
                                            </h1>
                                            <h2 className={"ChampFont flexCenter"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, width:"100%", justifyContent:"space-around"}}>
                                                <span>Year : {this.state.proposal.bottle.year}</span>
                                                <span>Volume : {this.state.proposal.bottle.volume}L</span>
                                            </h2>
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-around", marginTop:20}}>
                                                <button disabled={this.state.disableButtons} onClick={()=>{this.setState({paypalPayment:true})}} style={{width:"fit-content", backgroundColor:"#4BB543FF", color:"#FFF"}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Accept and buy this bottle</h2></button>
                                                <button disabled={this.state.disableButtons} onClick={()=>{this.denyProposal()}} style={{width:"fit-content", backgroundColor:"#FF4136FF", color:"#FFF"}} className={"buttonGNFT"}><h2 className={"ChampFont"} style={{margin:0, fontWeight:600}}>Reject the offer</h2></button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                                <div className={"flexCenter"} style={{position:"relative", width:this.state.showBrowserView ? "40%":"90%", alignItems:"flex-end", margin:!this.state.showBrowserView && 30}}>
                                    <img style={{width:this.state.showBrowserView ? "75%":"90%" , zIndex:21, minWidth:this.state.showBrowserView && 430, borderRadius:5, maxWidth:500}} src={url+"GrapeNFT/"+this.state.proposal.idTokenBottle+"_image.png"} alt="Champagne Bottle"
                                         onError={({ currentTarget }) => {
                                             currentTarget.onerror = null; // prevents looping
                                             console.log("error",currentTarget.src )
                                             currentTarget.src= require("../../assets/defaultUnknownBottle.png");
                                         }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

        );
    }
}

export default withRouter(SalesProposal)