import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

class AccueilSuffrage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                    <h2 style={{fontWeight:"normal", textAlign:"center"}}>Démo Suffrage</h2>
                </div>
            </div>
        );
    }
}

export default withRouter(AccueilSuffrage)