import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import {Backdrop, CircularProgress} from "@mui/material";
import TileConceptComponent from "./TileConceptComponent";

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Design","Développement","Marketing","Informatique et logiciels","Développement personnel","Business","Photographie","Musique","Divers"
]
const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class CatalogueBTOB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{
            listAllConcepts:[]
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("BTOBDESIGN_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedBtoBDesign", {
                        jwt: this.state.jwt,
                    })
                }
                this.getListeConcepts()
            })
        })
    }

    getListeConcepts(){
        Axios.post(url+"getListeConcepts",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            this.setState({displayBackdrop:false})
            if(response.data.jwt){
                sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                this.setState({listAllConcepts:response.data.concepts,jwtDecoded})
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.displayBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"93%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column", alignItems:"flex-start"}}>
                            <div style={{width:"fit-content", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Catalogue :</h1>
                                <div style={{background: "linear-gradient(49deg, rgb(54, 73, 130) 0%, rgb(142, 160, 215) 100%)", width:"100%", height:3, borderRadius:100, marginBottom:20}}></div>
                            </div>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", marginBottom:10}}>
                                <button onClick={()=>{
                                    Swal.fire({
                                        title: "Qu'est ce qu'un concept ?",
                                        text: "Pour BtoBDesign, un concept est un NFT composé de données comme un nom, une description (...) et d'illustrations. Un concept peut représenter une idée, un design, un plan etc...",
                                        icon: 'info',
                                        confirmButtonText: 'Ok'
                                    })
                                }} className={"divButtonBtoBDesign"}>Qu'est ce qu'un concept ?</button>
                            </div>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                {this.state.listAllConcepts.length > 0 ? (
                                    <>
                                        {this.state.listAllConcepts.map(elt=>(
                                            <TileConceptComponent navigate={this.props.navigate} concept={elt} dai={false}></TileConceptComponent>
                                        ))}
                                    </>
                                ):(
                                    <p>Le catalogue est pour l'instant vide, revenez plus tard !</p>
                                )}
                            </div>
                        </div>

                        {/*<div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>*/}
                        {/*    <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>*/}
                        {/*        <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Vidéos possédées :</h1>*/}
                        {/*        <div style={{background: "linear-gradient(49deg, rgba(114,170,204,1) 0%, rgba(250,182,3,1) 100%)", width:286, height:3, borderRadius:100}}></div>*/}
                        {/*    </div>*/}
                        {/*    <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>*/}
                        {/*        <div style={{marginTop:20}}>*/}
                        {/*            <h2 style={{marginBottom:0}}>Vous ne possedez encore aucune vidéo ...</h2>*/}
                        {/*            <h2 style={{marginTop:10}}>Allez faire un tour sur le <Button onClick={()=>{this.props.navigate("/BtoBDesign/Catalogue")}} variant={"outlined"} style={{textTransform:"none"}}><h2 style={{margin:0}}>catalogue</h2></Button></h2>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(CatalogueBTOB)