import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import config from "../../json/config.json";
import Swal from "sweetalert2";
import "./styleGNFT.css"
import Axios from "axios";
import {Backdrop, InputAdornment, TextField, Tooltip} from "@mui/material";
import jwt_decode from "jwt-decode";
import {
    Close,
    ContentCopy,
    Delete,
    DocumentScanner,
    Image,
    Info,
    Mail,
    Sell,
    Store,
    Warning,
} from "@mui/icons-material";
import SharedFooterGNFT from "./components/SharedFooterGNFT";

// const stripe = loadStripe(stripePublicKey);

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const ToastGNFT = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFontTitleToast',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    },
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const swalCustomClasses = {
    title: 'ChampFont ChampFontTitle',
    content: 'ChampFont ChampFontContent',
    confirmButton: 'ChampFont ChampFontButton',
    cancelButton: 'ChampFont ChampFontButton',
    container: "ToastGNFT-container",
    icon: "SwalGNFT-icon",
}

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFont ChampFontTitle',
        content: 'ChampFont ChampFontContent',
        confirmButton: 'ChampFont ChampFontButton',
        cancelButton: 'ChampFont ChampFontButton',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    }
    // confirmButtonTextColor: "#3b3b3b",
})

const columns =[
    { id: 'name', label: 'Name', minWidth: 10 },
    { id: 'image', label: 'Image', minWidth: 10, maxWidth: 65 },
    { id: 'certi', label: 'Certificate', minWidth: 100, maxWidth: 65 },
    { id: 'bid', label: 'Donation', minWidth: 10 },
    { id: 'status', label: 'Status', minWidth: 10 },
]

let jwtDecoded;

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class MyBottles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            reservations:[],
            collapsePersonal:true,
            collapsePayment:true,
            disableEdit:true,
            allValueModified:[],
            missingDataPersonal:false,
            missingDataPayment:false,
            focusBottle:{},
            dialogSellBottle:{},
            champsPrix:"",
            typeVente:0,
            sellingPrice:null,
            brands:[]
        };
    }
    _emitConnectedGNFT;
    componentWillUnmount(){
        this._emitConnectedGNFT.remove()
        window.removeEventListener('resize', this.resizePage)
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentDidMount() {
        let td = new URLSearchParams(window.location.search).get("td");
        if(td !== null) {
            this.setState({
                td
            })
        }
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this._emitConnectedGNFT = global.emitter.addListener("emitConnectedGNFT",()=>{ //On prévient que le client s'est connecté
            this.setState({jwt:sessionStorage.getItem("GNFT_jwt")}, ()=>{
                this.setState({
                    jwtDecoded : jwt_decode(this.state.jwt),
                    connected:true
                })
                global.emitter.emit("onRouteChangedGNFT", {
                    jwt: this.state.jwt,
                })
                this.connexion()
            })
        })
        this.connexion()
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("GNFT_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedGNFT", {
                        jwt: this.state.jwt,
                    })
                    this.getMyReservations()
                    this.getAllBrands()
                }else{
                    global.emitter.emit("askConnectionGNFT",{disableOutsideClick:true})
                }
            })
        })
    }

    getMyReservations(){
        /*Appel Axios getMyReservations & stock response.data.reservations dans un state*/
        Axios.post(url+"getMyReservations",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("GNFT_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat === "done"){
                this.setState({
                    reservations:response.data.reservations
                })
            }else{
                SwalDefault.fire(
                    'Error',
                    response.data.resultat,
                )
            }
        })
    }


    getAllBrands(){
        Axios.post(url+"getBrands",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.resultat === "done") {
                this.setState({
                    brands:response.data.brands
                })
            }
        })
    }

    handleChange(event){
        let targetName = event.currentTarget.name
        this.setState({
            [targetName] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let targetName = event.currentTarget.name
        const onlyNums = event.target.value.replace(/[^0-9]/g, '');
        this.setState({
            [targetName] : onlyNums
        })
    }
    generateCertificate(reservation){
        /*call Axios : generateCertificate avec idToken de la reservation et le nom de la bouteille*/
        this.setState({displayLoading:true})
        /*set timer of 10s and catch if timed out*/
        let timerCertif = setTimeout(()=>{
            this.setState({displayLoading:false})
            ToastGNFT.fire({
                title:"An error occured while generating the certificate",
                icon:"error"
            })
        },10000)

        Axios.post(url+"generateCertificateGNFT",{
            idToken:reservation.idTokenBottle
        },{headers:{Authorization:"Bearer " + this.state.jwt},responseType:"arraybuffer"}).then(response=>{
            console.log(response.data)
            let file = new Blob([response.data], {type: 'application/pdf'});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = "certificate_"+reservation.bottle.name+".pdf";
            link.click();
            this.setState({displayLoading:false})
            clearTimeout(timerCertif)
            ToastGNFT.fire({
                title:"Certificate generated",
                icon:"success"
            })
        }).catch(e=>{
            this.setState({displayLoading:false})
            clearTimeout(timerCertif)
            ToastGNFT.fire({
                title:"An error occured while generating the certificate",
                icon:"error"
            })
        })
    }

    sellBottle(typeSale, canal){
        if(this.state.typeVente===1 && this.state.receiverValue.length < 64){
            SwalDefault.fire({
                title:"Incorrect hash",
                text:"Please enter a valid hash (must be 64 characters long)",
                icon:"error",
                customClass:{
                    ...swalCustomClasses,
                    popup:!this.state.showBrowserView && "swalGNFT-mobile"
                },
                target:"#dialog-catalog-GNFT"
            })
            return
        }
        if(this.state.receiverValue === jwtDecoded.pseudo || this.state.receiverValue === jwtDecoded.empreinte){
            SwalDefault.fire({
                title:"Incorrect receiver",
                text:"You can't sell a bottle to yourself",
                icon:"error",
                customClass:{
                    ...swalCustomClasses,
                    popup:!this.state.showBrowserView && "swalGNFT-mobile"
                },
                target:"#dialog-catalog-GNFT"
            })
            return
        }
        /*call Axios : sellBottle avec idToken de la reservation et le nom de la bouteille*/
        this.setState({displayLoading:true})
        /*set timer of 10s and catch if timed out*/
        let timerCertif = setTimeout(()=>{
            this.setState({displayLoading:false})
            ToastGNFT.fire({
                title:"An error occured while selling the bottle",
                icon:"error"
            })
        },10000)

        Axios.post(url+"sellBottle",{
            idTokenBottle:this.state.dialogSellBottle.idTokenBottle,
            nominative:typeSale === "nominative",
            empreinteBuyer:this.state.typeVente === 1 ? this.state.receiverValue:undefined,
            pseudoBuyer:this.state.typeVente === 2 ? this.state.receiverValue:undefined,
            price:this.state.sellingPrice,
            sendMail:canal === "mail",
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            clearTimeout(timerCertif)
            if(response.data.resultat === "done"){
                /*Success swal*/
                if(canal === "mail"){
                    ToastGNFT.fire({
                        title:"Bottle available for sale",
                        icon:"success"
                    })
                }else if (canal === "catalog"){
                    ToastGNFT.fire({
                        title:"Bottle available for sale",
                        icon:"success"
                    })
                }else if (canal === "self"){
                    /*copy sales link to clipboard*/
                    navigator.clipboard.writeText(response.data.link)
                    ToastGNFT.fire({
                        title:"Sales link copied to clipboard !",
                        icon:"success"
                    })
                }else{
                    ToastGNFT.fire({
                        title:"Bottle available for sale",
                        icon:"success"
                    })
                }
                let newReservations = this.state.reservations
                newReservations.forEach(reservation=>{
                    if(reservation.idTokenBottle === this.state.dialogSellBottle.idTokenBottle){
                        reservation.onSale = {
                            nominative:typeSale === "nominative",
                            receiver:typeSale === "nominative" ? this.state.receiverValue:undefined,
                            price:this.state.sellingPrice,
                            idTokenProposal:response.data.link ? response.data.link.split("idTokenProposal=")[1]:undefined
                        }
                    }
                })
                this.setState({
                    reservations:newReservations,
                    displayLoading:false,
                    dialogSell:false,
                    typeVente:0,
                    receiverValue:"",
                    sellingPrice:""
                })
            }else{
                /*Error swal*/
                this.setState({displayLoading:false})
                SwalDefault.fire({
                    title:"Error",
                    text:response.data.resultat,
                    icon:"error",
                    customClass:{
                        ...swalCustomClasses,
                        popup:!this.state.showBrowserView && "swalGNFT-mobile"
                    },
                    target:"#dialog-catalog-GNFT"
                }).then(()=>{
                    this.setState({disableInstantBuy:false})
                    if(response.data.resultat === "Please, fill your personal informations before selling a bottle."){
                        this.props.navigate("/GrapeNFT/MyAccount")
                    }
                })
            }
        }).catch(e=>{
            this.setState({displayLoading:false})
            clearTimeout(timerCertif)
            console.log("error",e)
            ToastGNFT.fire({
                title:"An error occured while selling the bottle",
                icon:"error"
            })
        })
    }

    cancelSale(){
        /*call Axios : cancelSale avec idToken de la reservation et le nom de la bouteille*/
        this.setState({previousScrollPos : window.scrollY})
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
        this.setState({displayLoading:true})
        /*set timer of 10s and catch if timed out*/
        let timerCertif = setTimeout(()=>{
            this.setState({displayLoading:false})
            ToastGNFT.fire({
                title:"An error occured while cancelling the sale",
                icon:"error"
            })
        },10000)

        Axios.post(url+"cancelSale",{
            idTokenBottle:this.state.dialogSellBottle.idTokenBottle,
            nominative:this.state.dialogSellBottle.onSale.nominative,
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            clearTimeout(timerCertif)
            this.setState({displayLoading:false})
            if(response.data.resultat !== "done"){
                /*Error swal*/
                SwalDefault.fire({
                    title:"Error",
                    text:response.data.resultat,
                    icon:"error",
                    customClass:{
                        ...swalCustomClasses,
                        popup:!this.state.showBrowserView && "swalGNFT-mobile"
                    },
                    target:"#dialog-catalog-GNFT"
                })
            }else{
                ToastGNFT.fire({
                    title:"Sale cancelled",
                    icon:"success"
                })
                let newReservations = this.state.reservations
                newReservations.forEach(reservation=>{
                    if(reservation.idToken === this.state.dialogSellBottle.idToken){
                        reservation.onSale = false
                    }
                })
                this.setState({
                    reservations:newReservations,
                })
            }
        }).catch(e=>{
            this.setState({displayLoading:false})
            clearTimeout(timerCertif)
            ToastGNFT.fire({
                title:"An error occured while cancelling the sale",
                icon:"error"
            })
        })
    }

    closeDialog(){
        this.setState({dialogSellBottle:{}})
        window.scrollTo({top: this.state.previousScrollPos, left: 0, behavior: 'instant'});
    }

    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-GNFT"} style={{display:"flex",justifyContent:"center", alignItems:"center", flexDirection:"column" ,height:"100%",width:"100%"}}>
                    <div onClick={()=>{this.closeDialog()}} style={{display:Object.keys(this.state.dialogSellBottle).length === 0 && "none", alignItems:"flex-start"}} className={"backdrop-dialog-catalog-GNFT"}/>
                    <Backdrop
                        sx={{ color: '#fff', backgroundColor:"rgba(0,0,0,0.8)", zIndex: 999999999 }}
                        open={this.state.displayLoading}
                    >
                        <div className={"flexCenter"} style={{flexDirection:"column"}}>
                            <div className={"loaderTextGNFT ChampFont"}>Loading</div>
                            <h2 className={"ChampFont"} style={{color:"#FFBB41FF"}}>Please wait a few seconds</h2>
                        </div>
                    </Backdrop>
                    <div id={"dialog-catalog-GNFT"} style={{width:!this.state.showBrowserView && "100%", overflow:"auto", position:!this.state.showBrowserView && "inherit", height:!this.state.showBrowserView && "100%", marginTop:!this.state.showBrowserView && 70, left:!this.state.showBrowserView && 0,flexDirection:"column",opacity:Object.keys(this.state.dialogSellBottle).length !== 0 ? 1:0, transform:Object.keys(this.state.dialogSellBottle).length !== 0 ? "scale(1)":"scale(0)", boxShadow:"rgb(50 50 93 / 25%) 1px 20px 60px -12px inset, rgb(0 0 0 / 30%) 0px 0px 50px 8px inset"}} className={"flexCenter dialog-catalog-GNFT"}>
                        {Object.keys(this.state.dialogSellBottle).length !== 0 && (
                            <>
                                <div style={{width:"100%", height:1, position:"relative"}}>
                                    <Close onClick={()=>{this.closeDialog()}} style={{position:"absolute", color:"#3b3b3b", right:0, width:45, height:45, cursor:"pointer"}}/>
                                </div>
                                <div className={"flexCenter"} style={{width:"100%", height:"50%", flexDirection:!this.state.showBrowserView && "column", justifyContent:!this.state.showBrowserView && "flex-start"}}>
                                    <div className={"flexCenter"} style={{width:this.state.showBrowserView && "30%", height:"100%", marginTop:!this.state.showBrowserView && 30, padding:20, alignItems:"flex-start", flexDirection:"column"}}>
                                        <div style={{position:"relative",height:1, width:'100%'}}>
                                            <div className={"flexCenter"} style={{position:"absolute", width:100, height:100, top:0, left:0}}>
                                                <img style={{maxWidth:"100%", maxHeight:"100%", filter:"brightness(1)", zIndex:9, borderRadius:5}} src={url+"GrapeNFT/"+this.state.dialogSellBottle.pack.brand+"_image.png"} alt="brand"/>
                                            </div>
                                        </div>
                                        <img style={{maxHeight:"100%", maxWidth:"100%", borderRadius:10}} src={url+"GrapeNFT/"+this.state.dialogSellBottle.idTokenBottle+"_image.png"}
                                             onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                 console.log("error",currentTarget.src )
                                                currentTarget.src= require("../../assets/defaultUnknownBottle.png");
                                             }}
                                             alt="Champagne Bottle"
                                        />
                                    </div>
                                    <div className={"flexCenter"} style={{width:this.state.showBrowserView && "70%", height:"100%", flexDirection:"column", justifyContent:"space-around"}}>
                                        <h1 className={"ChampFont"} style={{color:"#3b3b3b", fontSize:40}}>{this.state.dialogSellBottle.bottle.name}</h1>
                                        <div style={{width:"100%" , display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column",marginBottom:15}}>
                                            <div className={"flexCenter"} style={{justifyContent:"space-around", width:"100%", flexDirection:!this.state.showBrowserView && "column", marginBottom:this.state.showBrowserView && 15}}>
                                                <h2 style={{margin:0}} className={"flexCenter ChampFont"}>Year : <span style={{color:"#3b3b3b", fontWeight:"bold", marginLeft:5}}>{this.state.dialogSellBottle.bottle.year}</span></h2>
                                                <h2 style={{margin:0}} className={"flexCenter ChampFont"}>Volume : <span style={{color:"#3b3b3b", fontWeight:"bold", marginLeft:5}}>{this.state.dialogSellBottle.bottle.volume}L</span></h2>
                                            </div>
                                            <div className={"flexCenter"} style={{justifyContent:"space-around", width:"100%", flexDirection:!this.state.showBrowserView && "column",marginBottom:15}}>
                                                <h2 style={{margin:0}} className={"flexCenter ChampFont"}>Pack : <span style={{color:"#3b3b3b", fontWeight:"bold", marginLeft:5}}>{this.state.dialogSellBottle.pack.name}</span></h2>
                                                <h2 style={{margin:0}} className={"flexCenter ChampFont"}>Buying price : <span style={{color:"#3b3b3b", fontWeight:"bold", marginLeft:5}}>{this.state.dialogSellBottle.price}€</span></h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:"95%", height:2,margin:0, marginTop:10,borderBottom:"1px dashed #969696",}}/>

                                <div className={"flexCenter"} style={{width:"100%", height:"100%", flexDirection:"column"}}>
                                    <div style={{width:"100%" , display:"flex", justifyContent:"space-between", alignItems:"center", flexDirection:"column",marginBottom:15}}>
                                        <div className={"flexCenter"} style={{flexDirection:"column", width:this.state.showBrowserView ? "80%":"95%",marginBottom:!this.state.showBrowserView&&20}}>
                                            {this.state.dialogSellBottle.onSale ? (
                                                <React.Fragment>
                                                    <h2 className={"flexCenter ChampFont"} style={{margin:0, color:"#000", fontWeight:"100", fontSize:28, marginBottom:5, width:"100%", justifyContent:"left"}}>
                                                        Cancel sales proposal :</h2>
                                                    <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", alignItems:"center", flexDirection:"column"}}>
                                                        <div className={"flexCenter"} style={{justifyContent:"space-around", width:"100%", flexDirection:!this.state.showBrowserView && "column"}}>
                                                            <h2 className={"flexCenter ChampFont"}><span style={{fontWeight:"bold", marginLeft:5}}>{this.state.dialogSellBottle.onSale.nominative ? `Sales proposal to ${this.state.dialogSellBottle.onSale.receiver.length === 64 ? (this.state.dialogSellBottle.onSale.receiver.substring(0,4)+"..."+this.state.dialogSellBottle.onSale.receiver.slice(-4)):(this.state.dialogSellBottle.onSale.receiver)}`:"Bottle available on the catalog"}</span></h2>
                                                            <h2 className={"flexCenter ChampFont"}>Price : <span style={{fontWeight:"bold", marginLeft:5}}>{this.state.dialogSellBottle.onSale.price}€</span></h2>
                                                        </div>
                                                        <div className={"flexCenter"} style={{width:"100%", marginTop:15, justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                            <button onClick={()=>{
                                                                if(this.state.dialogSellBottle.onSale.nominative === true) {
                                                                    navigator.clipboard.writeText(baseUrl+`/GrapeNFT/SalesProposal?idTokenBottle=${this.state.dialogSellBottle.idTokenBottle}&idTokenProposal=${this.state.dialogSellBottle.onSale.idTokenProposal}`)
                                                                }else{
                                                                    navigator.clipboard.writeText(baseUrl+`/GrapeNFT/Catalog?td=${this.state.dialogSellBottle.idTokenPack}`)
                                                                }
                                                                ToastGNFT.fire({
                                                                    icon: 'success',
                                                                    title: 'Link copied to clipboard'
                                                                })
                                                            }} style={{width:this.state.showBrowserView ? "28%":"100%", marginTop:15,fontSize:!this.state.showBrowserView ? 18:22, padding:!this.state.showBrowserView && 10}} className={"buttonGNFT flexCenter"}>
                                                                <h3 style={{margin:0}} className={"flexCenter ChampFont"}>Copy sales link <ContentCopy style={{color:"#000", width:30, height:30, marginLeft:10}}/></h3>
                                                            </button>
                                                            <button onClick={()=>{this.cancelSale()}} style={{width:this.state.showBrowserView ? "68%":"100%", marginTop:15,fontSize:!this.state.showBrowserView ? 18:22, padding:!this.state.showBrowserView && 10, backgroundColor:"#FF4136FF"}} className={"buttonGNFT flexCenter"}>
                                                                <h3 style={{margin:0,color:"#FFF"}} className={"flexCenter ChampFont"}>Cancel this sale <Delete style={{color:"#FFF", width:30, height:30, marginLeft:10}}/></h3>
                                                            </button>
                                                        </div>

                                                    </div>
                                                </React.Fragment>
                                            ):(
                                                <React.Fragment>
                                                    <h2 className={"flexCenter"} style={{margin:0, color:"#000", fontWeight:"100", marginBottom:5, width:"100%", justifyContent:"left"}}>
                                                        Sales method : {["Catalog", "Hash", "Username","Link"][this.state.typeVente]}
                                                        <Tooltip title={[
                                                            "This method allows you to showcase your product on a catalog within GrapeNFT. Once the product is listed with its price, any user of GrapeNFT can view it. The product is sold to the first user who pays the listed price. It's an open method that potentially reaches all users, suitable for broad visibility and encouraging spontaneous purchases.",
                                                            "With this method, you need to enter the unique hash of the intended user along with a price. Upon submission, GrapeNFT sends a personalized sales proposal to that particular user. It's a secure and precise method, ideal when you want to target a specific customer and give them a tailored offer. Please note that only a user associated with GrapeNFT will receive and can accept this proposal.",
                                                            "With this method, you need to enter the username of the intended user along with a price. Upon submission, GrapeNFT sends a personalized sales proposal to that particular user. It's a secure and precise method, ideal when you want to target a specific customer and give them a tailored offer. Please note that only a user associated with GrapeNFT will receive and can accept this proposal.",
                                                            "With the 'Link' method, you set a price for your product and generate a unique link which you can send to multiple potential buyers. The first user who clicks on the link and completes the payment will secure the purchase. This method is non-nominative, meaning the offer isn't intended for a specific person. It's a fast, competitive method perfect for flash sales or promotional offers."
                                                        ][this.state.typeVente]}>
                                                            <Info style={{cursor:"pointer", marginLeft:5}}/>
                                                        </Tooltip>
                                                    </h2>
                                                    <div style={{display:"flex", height:33, flexDirection:"column", width:"100%"}}>
                                                        <div className={"flexCenter"} style={{width:"100%", flexGrow:1, border:"1px solid black", justifyContent:"space-between", borderRadius:10, cursor:"pointer", zIndex:10}}>
                                                            <p style={{margin:0, width:"33.3333%", textAlign:"center", backgroundColor:"transparent", color:this.state.typeVente === 0 && "white", borderRadius:10, padding:5}} onClick={()=>{this.setState({typeVente:0, receiverValue:""})}}>Catalog</p>
                                                            <p style={{margin:0, width:"33.3333%", textAlign:"center", backgroundColor:"transparent", color:this.state.typeVente === 1 && "white", borderRadius:10, padding:5}} onClick={()=>{this.setState({typeVente:1, receiverValue:""})}}>Hash</p>
                                                            <p style={{margin:0, width:"33.3333%", textAlign:"center", backgroundColor:"transparent", color:this.state.typeVente === 2 && "white", borderRadius:10, padding:5}} onClick={()=>{this.setState({typeVente:2, receiverValue:""})}}>Username</p>
                                                            {/*<p style={{margin:0, width:"25%", textAlign:"center", backgroundColor:"transparent", color:this.state.typeVente === 2 && "white", borderRadius:10, padding:5}} onClick={()=>{this.setState({typeVente:2, receiverValue:""})}}>Link</p>*/}
                                                        </div>
                                                        <div style={{width:'100%', position:"relative"}}>
                                                            <div style={{transition:"all .5s cubic-bezier(.25,.8,.25,1)", width:"33.3333%", height:33, position:"absolute", backgroundColor:"#FFBB41FF", borderRadius:10, bottom:0, transform:`translate(${this.state.typeVente*100}%)`}}>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {this.state.typeVente === 3 ? (
                                                        <div style={{width:"100%"}}>
                                                            <TextField
                                                                label={"Sell price"}
                                                                variant={"outlined"}
                                                                style={{marginTop:15, width:"100%"}}
                                                                name={"sellingPrice"}
                                                                value={this.state.sellingPrice}
                                                                onChange={(e)=>{this.handleChangeOnlyNum(e)}}
                                                                onFocus={event => {
                                                                    event.target.select();
                                                                }}
                                                                placeholder={"0"}
                                                                InputLabelProps={{ shrink : true }}
                                                                InputProps={{
                                                                    endAdornment:
                                                                        <InputAdornment position="end">
                                                                            <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                                        </InputAdornment>
                                                                }}
                                                            />
                                                            <button onClick={()=>{this.sellBottle("non-nominative", "self")}} disabled={ (this.state.sellingPrice === null || this.state.sellingPrice === "") || this.state.disableSendLink} style={{width:"100%", marginTop:15,fontSize:!this.state.showBrowserView ? 18:22, padding:!this.state.showBrowserView && 10}} className={"buttonGNFT"}>Create and copy sales link</button>
                                                            <div style={{width:"100%", position:"relative"}}>
                                                                <p className={"flexCenter"} style={{margin:0, position:"absolute", fontStyle:10, width:"fit-content", whiteSpace:"nowrap",textAlign:"left"}}> <Warning style={{marginRight:5}}/> Creates a non-nominative sales proposal</p>
                                                            </div>
                                                        </div>
                                                    ):(
                                                        <React.Fragment>
                                                            {this.state.typeVente === 0 ? (
                                                                <div style={{width:"100%"}}>
                                                                    <TextField
                                                                        label={"Sell price"}
                                                                        variant={"outlined"}
                                                                        style={{marginTop:15, width:"100%"}}
                                                                        name={"sellingPrice"}
                                                                        value={this.state.sellingPrice}
                                                                        onChange={(e)=>{this.handleChangeOnlyNum(e)}}
                                                                        onFocus={event => {
                                                                            event.target.select();
                                                                        }}
                                                                        placeholder={"0"}
                                                                        InputLabelProps={{ shrink : true }}
                                                                        InputProps={{
                                                                            endAdornment:
                                                                                <InputAdornment position="end">
                                                                                    <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                                                </InputAdornment>
                                                                        }}
                                                                    />
                                                                    <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", flexWrap:"wrap", marginTop:10}}>
                                                                        <p className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content"}}>Selling price : <span style={{fontWeight:"bold", marginLeft:3}}>{this.state.sellingPrice||0}€</span></p>
                                                                        <p className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content"}}>Manufacturer commission : <span style={{fontWeight:"bold", marginLeft:3, color:"#FF4136FF"}}>{(this.state.sellingPrice*(this.state.bottleBrand.commission/100)).toFixed(2)}€ ({this.state.bottleBrand.commission}%)</span></p>
                                                                        <p className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content"}}>GrapeNFT fees : <span style={{fontWeight:"bold", marginLeft:3, color:"#FF4136FF"}}>{(this.state.sellingPrice*(8/100)).toFixed(2)}€ (8%)</span></p>
                                                                        <p className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content"}}>Your estimated net gain : <span style={{fontWeight:"bold", marginLeft:3, color:"#4BB543FF"}}>{(this.state.sellingPrice-(this.state.sellingPrice*(8/100)+this.state.sellingPrice*(this.state.bottleBrand.commission/100))).toFixed(2)}€</span></p>
                                                                    </div>
                                                                    <button onClick={()=>{this.sellBottle("non-nominative", "self")}} disabled={(this.state.sellingPrice === null || this.state.sellingPrice === "")} style={{width:"100%", marginTop:15, fontSize:!this.state.showBrowserView ? 18:22, padding:!this.state.showBrowserView && 10}} className={"buttonGNFT"}>
                                                                        <h3 style={{margin:0}} className={"flexCenter ChampFont"}>Sell on the catalog <Store style={{marginLeft:10, color:"#000", width:30, height:30}}/></h3>
                                                                    </button>
                                                                    <div style={{width:"100%", position:"relative"}}>
                                                                        <p className={"flexCenter ChampFont"} style={{margin:0, position:"absolute", fontStyle:10, width:"fit-content", whiteSpace:"nowrap",textAlign:"left"}}> <Warning style={{marginRight:5}}/> Creates a non-nominative sales proposal</p>
                                                                    </div>
                                                                </div>
                                                            ):(
                                                                <div style={{width:"100%"}}>
                                                                    <div className={"flexCenter"} style={{width:'100%', justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                                                                        <TextField
                                                                            label={this.state.typeVente === 1 ? "Hash buyer":this.state.typeVente === 2 && "Username buyer"}
                                                                            variant={"outlined"}
                                                                            name={"receiverValue"}
                                                                            style={{marginTop:15, width:this.state.showBrowserView ? "79%" : "100%"}}
                                                                            value={this.state.receiverValue}
                                                                            onChange={(e)=>{this.handleChange(e)}}
                                                                            placeholder={this.state.typeVente === 1 ? "0b634c73bf8ebf9952a8ee544f364e5e57b166da21b6f4e196b0f359e552b794":"JohnDoe"}
                                                                            InputLabelProps={{ shrink : true }}
                                                                        />
                                                                        <TextField
                                                                            label={"Sell price"}
                                                                            variant={"outlined"}
                                                                            style={{marginTop:15, width:this.state.showBrowserView ? "19%": "100%"}}
                                                                            name={"sellingPrice"}
                                                                            value={this.state.sellingPrice}
                                                                            onChange={(e)=>{this.handleChangeOnlyNum(e)}}
                                                                            onFocus={event => {
                                                                                event.target.select();
                                                                            }}
                                                                            placeholder={"0"}
                                                                            InputLabelProps={{ shrink : true }}
                                                                            InputProps={{
                                                                                endAdornment:
                                                                                    <InputAdornment position="end">
                                                                                        <img style={{width:29, marginTop:4}} src={require("../../assets/euroIcon.png")} alt="Euro"/>
                                                                                    </InputAdornment>
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className={"flexCenter"} style={{width:"100%", justifyContent:"space-between", flexWrap:"wrap", marginTop:10}}>
                                                                        <p className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content"}}>Selling price : <span style={{fontWeight:"bold", marginLeft:3}}>{this.state.sellingPrice||0}€</span></p>
                                                                        <p className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content"}}>Manufacturer commission : <span style={{fontWeight:"bold", marginLeft:3, color:"#FF4136FF"}}>{(this.state.sellingPrice*(this.state.bottleBrand.commission/100)).toFixed(2)}€ ({this.state.bottleBrand.commission}%)</span></p>
                                                                        <p className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content"}}>GrapeNFT fees : <span style={{fontWeight:"bold", marginLeft:3, color:"#FF4136FF"}}>{(this.state.sellingPrice*(8/100)).toFixed(2)}€ (8%)</span></p>
                                                                        <p className={"flexCenter ChampFont"} style={{margin:0, width:"fit-content"}}>Your estimated net gain : <span style={{fontWeight:"bold", marginLeft:3, color:"#4BB543FF"}}>{(this.state.sellingPrice-(this.state.sellingPrice*(8/100)+this.state.sellingPrice*(this.state.bottleBrand.commission/100))).toFixed(2)}€</span></p>
                                                                    </div>
                                                                    <div className={"flexCenter"} style={{marginTop:15, width:"100%", justifyContent:"space-between"}}>
                                                                        <button onClick={()=>{this.sellBottle("nominative", "mail")}} disabled={ (this.state.sellingPrice === null || this.state.sellingPrice === "") || this.state.receiverValue ===""} style={{width:"48%",fontSize:!this.state.showBrowserView ? 18:22, padding:!this.state.showBrowserView && 10}} className={"buttonGNFT flexCenter"}>
                                                                            <h3 style={{margin:0}} className={"flexCenter ChampFont"}>Send sales proposal by mail <Mail style={{marginLeft:10, color:"#000", width:30, height:30}}/></h3>
                                                                        </button>
                                                                        <button onClick={()=>{this.sellBottle("nominative", "self")}} disabled={ (this.state.sellingPrice === null || this.state.sellingPrice === "") || this.state.receiverValue ===""} style={{width:"48%",fontSize:!this.state.showBrowserView ? 18:22, padding:!this.state.showBrowserView && 10}} className={"buttonGNFT flexCenter"}>
                                                                            <h3 style={{margin:0}} className={"flexCenter ChampFont"}>Create and copy sales link <ContentCopy style={{marginLeft:10, color:"#000", width:30, height:30}}/></h3>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                    {/*<input autoComplete={"off"} name={"champsPrix"} className={"champsTexteGNFT ChampFont"} value={this.state.champsPrix} onChange={(e)=>{this.handleChangeOnlyNum(e)}} style={{border:"1px solid black", width:"100%"}} placeholder={"Price"}/>*/}
                                </div>
                            </>
                        )}
                    </div>
                    <div style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", width:"100%", height:"100%", minHeight:"100vh", flexDirection:"column"}}>
                        <h1 className={"ChampFont flexCenter"} style={{color:"#ffbb41", fontWeight:"bold", fontSize:60, marginBottom:15, marginTop:this.state.showBrowserView ? 100:15, marginLeft:this.state.showBrowserView && 60}}>
                            My bottles
                        </h1>
                        <div className={"flexCenter div-catalogue-GNFT"} style={{height:"100%", justifyContent:!this.state.showBrowserView && "center"}}>
                            {this.state.reservations.map((item, index) => (
                                <React.Fragment>
                                    {item.bottle && (
                                        <div onMouseEnter={()=>{this.setState({focusBottle:item})}} onMouseLeave={()=>{this.setState({focusBottle: {}})}} style={{flexDirection:"column", filter:"none"}} className={`flexCenter square-catalogue-GNFT ${"variation"+(true ? "3":item.variation)+"-square"}`}>
                                            <div style={{width:"100%", position:"relative"}}>
                                                <h3 className={"ChampFont"} style={{margin:20, color:item.bottle ? "#000":"#FFF", position:"absolute", textShadow:!item.bottle && "2px 2px #000000", zIndex:99, textAlign:"left", width:item.bottle && "60%", maxHeight:44, overflowY:"auto", fontWeight:700}}>{item.bottle.name}</h3>
                                            </div>
                                            {item.bottle && (
                                                <div style={{position:"relative", width:'100%'}}>
                                                    {/*<img onLoad={()=>{this.setState({["imgLoadedBrand_"+index]:true})}} style={{width:"30%", filter:"brightness(1)", position:"absolute", right:5, top:5, maxHeight:50, display:this.state["imgLoadedBrand_"+index] ? "flex":"none"}} src={url+"GrapeNFT/"+item.brand+"_image.png"} alt="brand"/>*/}
                                                    <img style={{width:"30%",zIndex:9, position:"absolute", borderRadius:5, right:5, top:5,display:"flex"}} src={url+"GrapeNFT/"+item.pack.brand+"_imagelowres.png"} alt="brand"/>
                                                </div>
                                            )}
                                            <div className={"flexCenter"} style={{height:"100%", position:"relative", width:"100%"}}>
                                                {item.bottle && (
                                                    <>
                                                        {/*<img onLoad={()=>{this.setState({["imgLoaded_"+index]:true})}} style={{height:"100%", position:"absolute", transform:"scale(1.2)", marginTop:55, display:this.state["imgLoaded_"+index] ? "flex":"none"}} src={url+"GrapeNFT/"+item.idToken+"_image.png"} alt="Champagne Bottle"/>*/}
                                                        <img style={{maxHeight:"100%", maxWidth:"100%",filter:item.allSold && "grayscale(1)", position:"absolute", transform:"scale(1)",display:"flex"}} src={url+"GrapeNFT/"+item.idTokenBottle+"_image.png"} alt="Champagne Bottle"
                                                             onError={({ currentTarget }) => {
                                                                 currentTarget.onerror = null; // prevents looping
                                                                 console.log("error",currentTarget.src )
                                                                 currentTarget.src= require("../../assets/defaultUnknownBottle.png");
                                                             }}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                            <div style={{display:"flex", position:"relative" ,width:"100%"}}>
                                                <div className={"flexCenter"} style={{position:"absolute", width:"100%", height:50, bottom:10, justifyContent:"space-around", transform:this.state.focusBottle.idToken === item.idToken ? "translate(0px)":"translate(-270px)", transition:"all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                                    <h2 style={{maxWidth:65, margin:"auto"}}>
                                                        <Tooltip title={"Display this bottle"}>
                                                            <button disabled={!item.bottle.image} onClick={()=>{item.bottle.image && (window.open(url+"GrapeNFT/"+item.idTokenBottle+"_image.png"))}} className={"buttonGNFT flexCenter"} style={{padding:10.5, width:"100%", display:"flex"}}>
                                                                <Image style={{cursor:(item.bottle && item.bottle.image) && "pointer"}}/>
                                                            </button>
                                                        </Tooltip>
                                                    </h2>
                                                    <h2 style={{maxWidth:95, margin:"auto"}}>
                                                        <Tooltip title={"Show bottle details"}>
                                                            <button onClick={()=>{
                                                                let bottleBrand = this.state.brands.find((brand)=>{return brand.idToken === item.pack.brand})
                                                                this.setState({dialogSellBottle:item, previousScrollPos : window.scrollY, bottleBrand})
                                                                window.scrollTo({top: 0, left: 0, behavior: 'instant'});
                                                            }} className={"buttonGNFT flexCenter"} style={{padding:10.5, width:"100%", display:"flex"}}>
                                                                <h2 style={{margin:0, fontSize:20, fontWeight:"bold"}} className={"ChampFont"}>Sell </h2> <Sell style={{cursor:(item.bottle && item.bottle.image) && "pointer", marginLeft:5}}/>
                                                            </button>
                                                        </Tooltip>
                                                    </h2>
                                                    <h2 style={{maxWidth:65, margin:"auto"}}>
                                                        <Tooltip title={"Get a certificate"}>
                                                            <button onClick={()=>{this.generateCertificate(item)}} className={"buttonGNFT flexCenter"} style={{padding:10.5, width:"100%", display:"flex"}}>
                                                                <DocumentScanner style={{cursor:(item.bottle && item.bottle.image) && "pointer"}}/>
                                                            </button>
                                                        </Tooltip>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                            {this.state.reservations.length === 0 && (
                                <h2 className={"ChampFont flexCenter"} style={{color:"#FFF",maxWidth:"100%", fontWeight:100, marginLeft:10, marginTop:15}}>
                                    You have no bottle yet ...
                                </h2>
                            )}
                        </div>
                    </div>
                </div>
                <SharedFooterGNFT/>
            </>
        )
    }
}

export default withRouter(MyBottles)