import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import {
    Button,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';
import {isBrowser} from "react-device-detect";
import Axios from "axios";
import config from "../json/config.json";

import Swal from "sweetalert2";
import {Cancel, Delete} from "@mui/icons-material";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const columns = [
    { id: 'Invalider', label: 'Invalider', minWidth: 30 },
    { id: 'identifiant', label: 'Identifiant', minWidth: 75 },
    { id: 'email', label: 'Email', minWidth: 150 },
    { id: 'status', label: 'Statut', minWidth: 100 },
    { id: 'thisSrv', label: 'Appartient au serveur', minWidth: 30 }
];

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded;

class ListeGestionnaires extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateDrawer : true,
            allGestionnaires : [],
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt", {},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if (response.data.result === "Administrateur introuvable."){
                    this.props.navigate('/Administration/ConnexionAdmin')
                }else{
                    if(response.data.jwt){
                        sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:jwtDecoded.pseudo
                    })
                    global.emitter.emit("onRouteChanged",{
                        pageName:'Liste des gestionnaires et administrateurs',
                        pseudo:jwtDecoded.pseudo,
                    })
                    if (jwtDecoded.admin){
                        this.setState({
                            connected:true
                        }, ()=>{
                            this.getListeGestionnaires()
                        })
                    }else{
                        Swal.fire({
                            title: 'Erreur!',
                            text: "Vous n'êtes pas autorisé à accéder à cette page",
                            icon: 'error',
                            target:".MuiDialog-root",
                            confirmButtonText: 'Ok'
                        }).then(()=>{
                            this.props.navigate("/Administration/AccueilAdmin")
                        })
                    }
                }
            })
        })
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }
    }

    getListeGestionnaires(){
        Axios.post(url+"getAllAdmins", {getInvalidation:true},
            {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            this.setState({
                allGestionnaires:response.data.resultat.sort(this.sortBy("identifiant")),
            })
        }).catch((err)=>{
            console.log(err)
        })
    }
    InvalidationClient(client){
        Swal.fire({
            title: 'Attention!',
            text: !!client.invalidation ? "Voulez-vous vraiment annuler l'invalidation de ce gestionnaire ?":"Voulez-vous vraiment invalider ce gestionnaire ? Vous aurez jusqu'à 90 jours avant la supression permanente de cet utilisateur.",
            icon: 'info',
            confirmButtonText: 'Oui',
            showCancelButton:true,
            cancelButtonText:"Non",
            cancelButtonColor:"red"
        }).then((result)=>{
            if(result.isConfirmed){
                if(!!client.invalidation){
                    Axios.post(url+"cancelInvalidation", {
                        empreinte:client.empreinte,
                    },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                        if(response.data.jwt){
                            sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                            this.setState({
                                jwt:response.data.jwt
                            },()=>{
                                jwtDecoded = jwt_decode(this.state.jwt)
                            })
                        }
                        if (response.data.resultat === "done"){
                            Toast.fire({
                                title: "Vous avez annulé l'invalidation.",
                                icon: 'success',
                            })
                            let allClientsTemp = this.state.allGestionnaires.slice()
                            delete allClientsTemp[allClientsTemp.findIndex(elt => elt.empreinte === client.empreinte)].invalidation
                            this.setState({allGestionnaires:allClientsTemp})
                        }else{
                            Toast.fire({
                                title: response.data.resultat,
                                icon: 'error',
                            })
                        }
                    })
                }else{
                    Axios.post(url+"adminDeleteUser", {
                        empreinte:client.empreinte,
                    },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response => {
                        if(response.data.jwt){
                            sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                            this.setState({
                                jwt:response.data.jwt
                            },()=>{
                                jwtDecoded = jwt_decode(this.state.jwt)
                            })
                        }
                        if (response.data.resultat === "done"){
                            Toast.fire({
                                title: "Ce compte sera invalidé dans 90 jours.",
                                icon: 'success',
                            })
                            let allClientsTemp = this.state.allGestionnaires.slice()
                            allClientsTemp[allClientsTemp.findIndex(elt => elt.empreinte === client.empreinte)].invalidation = true
                            this.setState({allGestionnaires:allClientsTemp})
                        }else{
                            Toast.fire({
                                title: response.data.resultat,
                                icon: 'error',
                            })
                        }
                    })
                }
            }
        })
    }

    sortBy(prop) {
        return function(a, b) {
            if (a[prop] > b[prop]) {
                return 1;
            } else if (a[prop]< b[prop]) {
                return -1;
            }
            return 0;
        }
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto",minHeight:"285px",width:"80%",backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <h3>Liste des gestionnaires et administrateurs</h3>
                        <Paper style={{margin:10, width:"95%", maxHeight:400, overflowY:"scroll"}}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(this.state.allGestionnaires).map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column,index) => {
                                                        return (
                                                            <>
                                                                {column.id === "Invalider" ?
                                                                    <Button disabled={row["status"] === "Administrateur"} onClick={()=>{this.InvalidationClient(row)}}>
                                                                        {!!row.invalidation ? (
                                                                            <Tooltip arrow={true} title={"Annuler l'invalidation de ce gestionnaire"}>
                                                                                <Cancel/>
                                                                            </Tooltip>
                                                                        ):(
                                                                            <Tooltip arrow={true} title={"Invalider le gestionnaire"}>
                                                                                <Delete/>
                                                                            </Tooltip>
                                                                        )}
                                                                    </Button>
                                                                    :
                                                                    <>
                                                                        {column.id === "thisSrv" ?
                                                                            <TableCell>{row[column.id].includes("http") ? <a href={row[column.id]}>{row[column.id]}</a>:row[column.id] }</TableCell>
                                                                            :
                                                                            <TableCell  key={column.id}>
                                                                                {row[column.id]}
                                                                            </TableCell>
                                                                        }
                                                                    </>

                                                                }
                                                            </>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                {!this.state.allGestionnaires.length>0 && (
                                    <div style={{width:"100%"}}>
                                        <Skeleton style={{margin:20,marginTop:30}}/>
                                        <Skeleton style={{margin:20}} />
                                        <Skeleton style={{margin:20}} />
                                    </div>
                                )}
                            </TableContainer>
                        </Paper>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ListeGestionnaires)