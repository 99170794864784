import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import Swal from "sweetalert2";
import {isMobile} from "react-device-detect";
import {
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip
} from "@mui/material";
import tooltipContenu from "../../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Axios from "axios";
import config from "../../json/config.json";
import {sha256} from "js-sha256";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let queryParams = null;

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class ConnexionExterne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            showPassword:false,
            tokenLifetime:2592000000,
            displayLifetime:false
        };
    }
    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        queryParams = new URLSearchParams(window.location.search);
        console.log(window.location.search)
        this.setState({
            app:queryParams.get("app")||null,
            redirectRes:queryParams.get("redirect"),
            endpointRes:queryParams.get("endpoint"),
            displayLifetime:queryParams.get("app") !== "DirectAct"
        })
        window.addEventListener('resize', this.resizePage)
    }

    generateJwt(e){
        e.preventDefault()
        let hash = sha256(this.state.champsIdentifiant+this.state.champsClef)
        Axios.post(url+"createJwt", {
            empreinte:hash,
            lifetime:this.state.tokenLifetime
        }).then((response)=>{
            if (response.data.resultat !== false){
                if(this.state.endpointRes){ // Envoi du jwt à un serveur (requete GET et POST)
                    Axios.get(`${this.state.endpointRes}${this.state.endpointRes.includes("?") ? "&" : "?"}jwt=${response.data.resultat}&urlToAsk=${response.data.urlToAsk}`)
                    //Check si l'endpoint contient des variables à transmettre
                    const url = new URL(this.state.endpointRes)
                    const queryEndpointParams = new URLSearchParams(url.search);
                    let urlData = {}
                    queryEndpointParams.forEach((value, key) => {
                        urlData[key] = value
                    })
                    Axios.post(this.state.endpointRes, {
                        jwt:response.data.resultat,
                        urlToAsk:response.data.urlToAsk,
                        ...urlData
                    },{headers:{authorization:"Bearer "+response.data.resultat}})
                }
                if(this.state.redirectRes){ //Redirection vers une page web
                    window.location.replace(this.state.redirectRes+"?token="+response.data.resultat+"&urlToAsk="+response.data.urlToAsk)
                }

            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text:"Utilisateur introuvable.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    componentWillUnmount(){
        this._willUnmount = true;
        if(this._interval){
            clearInterval(this._interval)
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center"}}>
                    {queryParams!==null && (
                        <form onSubmit={(e)=>{this.generateJwt(e)}} style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", padding:10, border:"1px solid lightgrey", borderRadius:10}}>
                            <p style={{fontWeight:"bold", fontSize:18}}>Autoriser {queryParams.get("app")} à se connecter sur KageDoc</p>
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center", marginBottom:10}}>
                                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <img style={{textAlign:"center", width:32,marginRight:5}} src={require("../../assets/PICTO_KAGESECUR_RVB.png")} alt="logoKageSecure"/>
                                    <div onDoubleClick={()=>{this.setState({displayLifetime:true})}} style={{display:"flex",flexDirection:"column"}}>
                                        <p style={{fontSize:21,margin:0, fontWeight:"1000",fontFamily:"arial",lineHeight:"25px"}}><span style={{color:"#64a7db"}}>KAGE</span><span style={{color:"#F19028"}}>DOC</span></p>
                                        <p style={{display:"block",fontSize:12,margin:0,marginLeft:0, fontWeight:"bold",fontFamily:"arialLight"}}>La preuve et la sécurité</p>
                                    </div>
                                </div>
                                {/*<p style={{padding:5}}>&</p>*/}
                                {/*<img style={{textAlign:"center", width:60,marginRight:5}} src={""} alt="logoMasterReport"/>*/}
                            </div>

                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField name={"champsIdentifiant"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsIdentifiant" required={true} label="Identifiant" variant="outlined" />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                    <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                <TextField autoComplete="new-password" name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsClef" required={true} type={this.state.showPassword ? "text":"password"} label="Clef Unique" variant="outlined"
                                           InputProps={{
                                               endAdornment:
                                                   <InputAdornment position="end">
                                                       <IconButton
                                                           aria-label="toggle password visibility"
                                                           onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                       >
                                                           {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                       </IconButton>
                                                   </InputAdornment>
                                           }} />
                                <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                    <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <div style={{display:!this.state.displayLifetime ? "none":"flex", alignItems:"center",justifyContent:"center", width:"100%" }}>
                                <FormControl required={true} variant={"outlined"} style={{width:"100%"}}>
                                    <InputLabel>Durée de l'autorisation</InputLabel>
                                    <Select
                                        key="Durée de l'autorisation"
                                        value={this.state.tokenLifetime}
                                        onChange={(event)=>{
                                            this.setState({
                                                tokenLifetime:event.target.value
                                            })
                                        }}
                                        style={{width:"100%", color:"black"}}
                                        label={"Durée de l'autorisation"}

                                    >
                                        <MenuItem value={3600000}>1 Heure</MenuItem>
                                        <MenuItem value={86400000}>24 Heures</MenuItem>
                                        <MenuItem value={604800000}>7 Jours</MenuItem>
                                        <MenuItem value={2592000000}>30 Jours</MenuItem>
                                        <MenuItem value={31540000000}>12 Mois</MenuItem>
                                    </Select>
                                </FormControl>
                                <Tooltip arrow={true} title={"Indique au bout de combien de temps l'autorisation expire."}>
                                    <HelpOutlineIcon style={{marginLeft:5}} className={"helpIcon"} />
                                </Tooltip>
                            </div>
                            <Button style={{backgroundColor:"#369dd7",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Se connecter</Button>
                        </form>
                    )}

                </div>
            </div>
        );
    }
}

export default withRouter(ConnexionExterne)