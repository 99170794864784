import React, {Component} from 'react';
import {isBrowser} from "react-device-detect";

import Axios from "axios";
import config from "../json/config.json";

import {sha256} from "js-sha256";
import jwt_decode from "jwt-decode";
import {withRouter} from '../shared/NavigationUtils';

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
let jwtDecoded;

class AccueilAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stateDrawer:true
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        this.setState({
            jwt: sessionStorage.getItem("SPURO_jwt")
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt", {},
                {headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                if (response.data.resultat !== true){
                    this.props.navigate('/Administration/ConnexionAdmin');
                }else{
                    global.emitter.emit("onConnexionAdmin",{
                        entrepriseName:response.data.entrepriseName,
                        pseudo:jwtDecoded.pseudo
                    })
                    global.emitter.emit("onRouteChanged",{
                        pageName:'Accueil',
                        pseudo:jwtDecoded.pseudo,
                    })
                    if(response.data.jwt){
                        sessionStorage.setItem("SPURO_jwt",response.data.jwt)
                        this.setState({
                            jwt: response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    this.setState({isAdmin:jwtDecoded.admin})
                    console.log(jwtDecoded.admin)
                }
            })
        })
        if (isBrowser){
            this.setState({
                openDrawer : true,
            })
        }
    }

    hashFile(file){
        let hash = sha256(file)
        this.setState({hashFile:hash})
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    test(){
        Axios.post(url+"testIntegriteStockageSecure").then((response)=>{
            console.log(response.data)
        })
    }

    testPaiement(){
        Axios.post(url+"testPaiementEasyTransac").then((response)=>{
            console.log(response.data)
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>

                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto",minHeight:"285px", width:600,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <h3>Bienvenue dans le menu d'administration !</h3>
                        <p>Vous pouvez accéder aux paramètres grâce au menu à gauche de l'écran.</p>
                        {/*<button style={{display:this.state.isAdmin ? "block":"none"}} onClick={()=>{this.test()}}>*/}
                        {/*    Test IntegriteFiles*/}
                        {/*</button>*/}
                        {/*<button style={{display:this.state.isAdmin ? "block":"none"}} onClick={()=>{this.testPaiement()}}>*/}
                        {/*    Test Paiement EasyTransac*/}
                        {/*</button>*/}
                    </div>
                </div>
            </div>


        );
    }
}

export default withRouter(AccueilAdmin)