import React, {Component} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {withRouter} from '../../../shared/NavigationUtils';
import MenuIcon from "@mui/icons-material/Menu";
import {isMobile} from "react-device-detect";
import Axios from "axios";
import config from "../../../json/config.json";

import tooltipContenu from "../../../json/contenu.json";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {sha256} from "js-sha256";
import Swal from "sweetalert2";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const allAcceptedPath = [
    "/suffrage/accueil","/suffrage/gererinscriptions","/suffrage/gerergroupes","/suffrage/gerervotes","/suffrage/listevotesencours","/suffrage/listevotesrepondus","/suffrage/mesgroupes"
]

class SharedHeaderSuffrage extends Component {

    constructor(props) {
        super(props);
        this.state={
            openMenuEnregistrement:"none",
            openMenuAnalyse:"none",
            openMenuGestion:"none",
            openMenuAccount:"none",
            openMenuCollabo:"none",
            user:"none",
            displayConnexion:false,
            openDialogueModifEmail:false,
            openDialogueAjoutClient:false,
            champsIdentifiant:"",
            champsClef:"",
            champsNewEmail:"",
            displayed:false,
            openDialogueInvalidationCompte:false,
            displayMenuMobile:false,
            showBrowserView:true,
        };
    }

    _onRouteChangedSuffrage;
    _askConnectionSuffrage;
    resizePage = () => {
        if(document.body.offsetWidth <= 1150 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 1150 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 1150){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this._onRouteChangedSuffrage = global.emitter.addListener("onRouteChangedSuffrage",(data)=>{
            this.setState({
                user:data.user,
                pseudo:data.pseudo,
                nom:data.nom,
                prenom:data.prenom
            })
        })

        this._askConnectionSuffrage = global.emitter.addListener("askConnectionSuffrage",(data)=>{
            this.setState({
                displayConnexion:true
            })
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    componentWillUnmount(){
        if(this._onRouteChangedSuffrage){this._onRouteChangedSuffrage.remove()}
        window.removeEventListener('resize', this.resizePage)
    }

    decoFunction(){
        this.setState({user:"none",displayMenuMobile:false})
        sessionStorage.removeItem("SUFFRAGE_jwt")
        sessionStorage.removeItem("SUFFRAGE_pseudo")
        sessionStorage.removeItem("SUFFRAGE_hash")
        Toast.fire({
            icon: 'success',
            title: 'Déconnexion.'
        })
        this.props.navigate("/Suffrage/Accueil")
    }

    handleConnexion(e){
        e.preventDefault()
        let empreinte = sha256((this.state.champsIdentifiant.trim()).concat(this.state.champsClef.trim()));
        Axios.post(url+"connexionSuffrage",{
            empreinte:empreinte,
            hashJwt:empreinte
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            this.setState({displayConnexion:false},()=>{
                if (response.data.resultat === "Connecté"){
                    sessionStorage.setItem("SUFFRAGE_pseudo",this.state.champsIdentifiant.trim())
                    sessionStorage.setItem("SUFFRAGE_hash",empreinte)
                    this.setState({
                        user:response.data.gestionnaire ? "gestionnaire" : "client",
                        openMenuAccount:"none",
                        pseudo:this.state.champsIdentifiant,
                        nom:response.data.nom,
                        prenom:response.data.prenom
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Connexion réussie'
                    })
                }else if (response.data.resultat === "Vous n'appartenez pas à ce site."){
                    Swal.fire({
                        title: "Utilisateur non autorisé.",
                        text: "Cet utilisateur n'a pas l'autorisation d'accéder à ce node.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{this.setState({displayConnexion:true})})
                }
                else if (response.data.resultat === "Votre compte n'a pas encore été validé"){
                    Swal.fire({
                        title: 'En cours de validation.',
                        text: "Votre compte n'a pas encore été validé, veuillez patienter.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{this.setState({displayConnexion:true})})
                }
                else{
                    Swal.fire({
                        title: 'Utilisateur introuvable.',
                        text: "Cette combinaison d'identifiant et de clef unique n'existe pas.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{this.setState({displayConnexion:true})})
                }
            })
        }).catch(()=>{
            this.setState({displayConnexion:false},()=> {
                Swal.fire({
                    title: 'Erreur!',
                    text: "Serveur injoignable.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
        })
    }

    changeEmail(e){
        e.preventDefault()
        Axios.post(url+"modifEmail", {
            hash : sha256(this.state.champsIdentifiant.trim().concat(this.state.champsClef.trim())),
            email : this.state.champsNewEmail,
            hashJwt : sha256(this.state.champsIdentifiant.trim().concat(this.state.champsClef.trim()))
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "Email modifié."){
                Toast.fire({
                    icon:"success",
                    title:"Votre email a correctement été modifié."
                })
                this.setState({
                    champsIdentifiant:"",
                    champsClef:"",
                    champsNewEmail:"",
                    openDialogueModifEmail:false
                })
            }else{
                Toast.fire({
                    icon:"error",
                    title:"Impossible de modifier votre email.",
                    target:".MuiDialog-root",
                })
            }
        }).catch(()=>{
            Toast.fire({
                icon:"error",
                title:"Echec de l'envoi de l'email. Veuillez réessayer plus tard."
            })
        })
    }

    invalidateAccount(e){
        e.preventDefault()
        this.setState({openDialogueInvalidationCompte:false})
        Swal.fire({
            title: 'Attention!',
            text: "Voulez-vous vraiment invalider DÉFINITIVEMENT votre compte de la blockchain ?",
            icon: 'info',
            confirmButtonText: "Oui j'invalide",
            confirmButtonColor:"red",
            showCancelButton:true,
            cancelButtonText:"Annuler",
            cancelButtonColor:"#6600ff"
        }).then((result)=>{
            if(result.isConfirmed){
                Axios.post(url+"deleteAccount", {
                    empreinte : sha256(this.state.champsIdentifiant.concat(this.state.champsClef)),
                    hashJwt: sessionStorage.getItem("SUFFRAGE_hash")
                },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SUFFRAGE_jwt")}}).then((response)=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SUFFRAGE_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        })
                    }
                    if(response.data.resultat !== "Ce compte a bien été invalidé."){
                        Swal.fire({
                            title: 'Erreur!',
                            text: response.data.resultat,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    }else{
                        Swal.fire({
                            title: 'Succès!',
                            text: 'Vous disposez de 30 jours afin de récupérer vos document.',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                    }
                }).catch(()=>{
                    Toast.fire({
                        icon:"error",
                        title:"Le serveur ne réponds pas."
                    })
                })
            }
        })
    }

    goToPage(clickedPage){
        window.location.pathname !== clickedPage ? this.props.navigate(clickedPage):window.location.reload()
        this.setState({displayMenuMobile:false})
    }

    render() {
        
        return(
            <>
                <div style={{backgroundColor:"#FFFFFF", width:"99.5%",height:"99.5vh", position:"absolute", display:this.state.displayMenuMobile ? "flex":"none" , zIndex:9999}}>
                    <div style={{marginTop:100, width:"100%",zIndex:99999}}>
                        {/*<Accordion>*/}
                        {/*    <AccordionSummary*/}
                        {/*        expandIcon={<ExpandMoreIcon />}*/}
                        {/*    >*/}
                        {/*        <div style={{ display:"flex", justifyContent:"start", width:'100%',transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>*/}
                        {/*            <DescriptionIcon/>*/}
                        {/*            <Typography style={{marginLeft:15,fontWeight:"bold"}}>Enregistrement d'un document</Typography>*/}
                        {/*        </div>*/}

                        {/*    </AccordionSummary>*/}
                        {/*    <AccordionDetails>*/}
                        {/*        <div style = {{display:"flex", flexDirection:"column", alignItems:"start"}}>*/}
                        {/*            <p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/KageSecur/SansValidation')}}>Sans approbation</p>*/}
                        {/*            <p className={'buttonDrawerAdmin'} onClick={()=>{this.goToPage('/KageSecur/AvecValidation')}}>Avec approbation et signature</p>*/}
                        {/*        </div>*/}
                        {/*    </AccordionDetails>*/}
                        {/*</Accordion>*/}

                        {this.state.user === "gestionnaire" ? (
                            <>
                                <p>en dev</p>
                                {/*<p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/MiseEnDemeure")}}> <GavelIcon style={{marginLeft:8, marginRight:14}}/> Liste des votes</p>*/}
                                {/*<div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />*/}
                                {/*<p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/MiseEnDemeure")}}> <GavelIcon style={{marginLeft:8, marginRight:14}}/> Gérer les inscriptions clients</p>*/}
                                {/*<div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />*/}
                                {/*<p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/MiseEnDemeure")}}> <GavelIcon style={{marginLeft:8, marginRight:14}}/> Gérer les groupes</p>*/}
                            </>
                        ):(
                            <>
                                <p>en dev</p>
                                {/*<p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/MiseEnDemeure")}}> <GavelIcon style={{marginLeft:8, marginRight:14}}/> Liste des votes en cours</p>*/}
                                {/*<div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />*/}
                                {/*<p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/MiseEnDemeure")}}> <GavelIcon style={{marginLeft:8, marginRight:14}}/> Liste des votes répondus</p>*/}
                                {/*<div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />*/}
                                {/*<p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/MiseEnDemeure")}}> <GavelIcon style={{marginLeft:8, marginRight:14}}/> Visualiser mes groupes</p>*/}
                                {/*<div style={{backgroundColor:"#e0e0e0", margin:0, height:1, width:"100%"}} />*/}
                                {/*<p style={{cursor:"pointer",fontFamily: "Arial",margin:0, backgroundColor:"#FFFFFF", fontSize:"1rem", fontWeight:"bold", paddingTop:10, paddingBottom:10, display:"flex", alignItems:"center"}} onClick={()=>{this.goToPage("/KageSecur/MiseEnDemeure")}}> <GavelIcon style={{marginLeft:8, marginRight:14}}/> Faire une procuration</p>*/}
                            </>
                        )
                        }
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <div style={{ display:"flex", justifyContent:"start", width:'100%',transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                    <AccountCircleIcon/>
                                    <Typography style={{marginLeft:15,fontWeight:"bold"}}>Mon compte</Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
                                    <p className={'buttonDrawerAdmin'} onClick={()=>{this.setState({openDialogueModifEmail:true,displayMenuMobile:false})}} style={{padding:10}}>Modifier mon email</p>
                                    <p className={'buttonDrawerAdmin'} onClick={()=>{this.setState({openDialogueInvalidationCompte:true,displayMenuMobile:false})}} style={{padding:10}}>Invalider mon compte</p>
                                    <p className={'buttonDrawerAdmin'} onClick={()=>{this.decoFunction()}} style={{padding:10, color:"#ff3629"}}>Déconnexion</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
                <div style={{position:"absolute", display:"flex", height:90, backgroundColor:"white", width:"100%", alignItems:"center", boxShadow:"rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", zIndex:9999}}>
                    <Dialog
                        open={this.state.displayConnexion}
                        onClose={()=>{this.setState({displayConnexion:false})}}
                    >
                        <DialogTitle>Connectez-vous à Suffrage !</DialogTitle>
                        <form autoComplete="off" onSubmit={(e)=>{this.handleConnexion(e)}}>
                            <DialogContent>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsIdentifiant"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsIdentifiant" required={true} label="Identifiant" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField autoComplete="new-password" name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsClef" required={true} type={this.state.showPassword ? "text":"password"} label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }} />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <a style={{color:"blue", textDecoration:"underline",cursor:"pointer"}} onClick={()=>{
                                    Swal.fire({
                                        text: "Munissez-vous de votre code de récupération de clef unique et contactez votre administrateur.",
                                        icon: 'info',
                                        target:".MuiDialog-root",
                                        confirmButtonText: 'Ok'
                                    })
                                }}>Clef unique oubliée ?</a>
                                <Button style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Se connecter</Button>
                            </DialogContent>
                        </form>
                    </Dialog>
                    <Dialog
                        open={this.state.openDialogueModifEmail}
                        onClose={()=>{this.setState({openDialogueModifEmail:false})}}
                    >
                        <DialogTitle>Modifier votre email</DialogTitle>
                        <form autoComplete="off" onSubmit={(e)=>{this.changeEmail(e)}}>
                            <DialogContent>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField  name={"champsIdentifiant"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} label="Identifiant" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField autoComplete="new-password" name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"password"} label="Clef Unique" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsNewEmail"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"email"} label="Nouvel email" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>
                                        <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Modifier</Button>
                            </DialogContent>
                        </form>
                    </Dialog>
                    <Dialog
                        open={this.state.openDialogueInvalidationCompte}
                        onClose={()=>{this.setState({openDialogueInvalidationCompte:false})}}
                    >
                        <DialogTitle>Invalider mon compte</DialogTitle>
                        <form autoComplete="off" onSubmit={(e)=>{this.invalidateAccount(e)}}>
                            <h4 style={{marginLeft:28}}>ATTENTION cette action est <span style={{fontWeight:"bold"}}>permanente</span></h4>
                            <DialogContent>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField  name={"champsIdentifiant"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} label="Identifiant" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField autoComplete="new-password" name={"champsClef"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"password"} label="Clef Unique" variant="outlined" />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>INVALIDER MON COMPTE</Button>
                            </DialogContent>
                        </form>
                    </Dialog>
                    <Dialog
                        open={this.state.openDialogueAjoutClient}
                        onClose={()=>{this.setState({openDialogueAjoutClient:false})}}
                    >
                        <DialogTitle>Ajouter un votant</DialogTitle>
                        <form onSubmit={(e)=>{this.addClient(e)}}>
                            <DialogContent>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsNewEmail"} className={"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} required={true} type={"email"} label="Email du votant" variant="outlined" />
                                    {/*<Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmail}>*/}
                                    {/*    <HelpOutlineIcon style={{marginTop:"-13px",marginLeft:5}} className={"helpIcon"} />*/}
                                    {/*</Tooltip>*/}
                                </div>
                                <Button style={{backgroundColor:"#6600ff",padding:10, color:"white",width:"100%", fontWeight:"bold", margin:5, marginTop:10}} type={"submit"}>Ajouter</Button>
                            </DialogContent>
                        </form>
                    </Dialog>
                    {this.state.showBrowserView ? (
                        <>
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center", flexDirection:"column"}}>
                                <img onClick={()=>{this.goToPage("/Suffrage/Accueil")}} style={{textAlign:"center", height:65, marginLeft:20, cursor:"pointer"}} src={require("../../../assets/logoSuffrage.png")} alt="logoSuffrage"/>
                                <p style={{margin:0,marginLeft:20,fontWeight:"bold"}}>Suffrage</p>
                            </div>
                            <div style={{display:"flex",width:"100%", justifyContent:"space-evenly", alignItems:"center", marginLeft:60}}>
                                {this.state.user === "none" ? (
                                    <div style={{right:20 ,position:"absolute"}}>
                                        <Button variant={"contained"} style={{backgroundColor:"#6600ff",cursor:"pointer", marginBottom:10, marginTop:10, marginRight:10, textTransform:"none"}} onClick={()=>{this.setState({displayConnexion:true})}}>
                                            <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#FFFFFF"}}>CONNEXION</p>
                                        </Button>
                                        <Button variant={"contained"} style={{backgroundColor:"transparent",border:"solid 2px #6600ff" ,cursor:"pointer", marginBottom:10, marginTop:10,marginLeft:10, textTransform:"none"}} onClick={()=>{this.goToPage("/Suffrage/InscriptionVotant")}}>
                                            <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#6600ff"}}>INSCRIPTION</p>
                                        </Button>
                                    </div>
                                ):(
                                    <>
                                        {this.state.user === "gestionnaire" ?
                                            <>
                                                <p style={{cursor:"pointer"}} onClick={()=>{this.goToPage("/Suffrage/GererVotes")}}>Liste des votes</p>
                                                <p style={{cursor:"pointer"}} onClick={()=>{this.goToPage("/Suffrage/GererInscriptions")}}>Gérer les inscriptions des votants</p>
                                                <p style={{cursor:"pointer"}} onClick={()=>{this.goToPage("/Suffrage/GererGroupes")}}>Gérer les groupes</p>
                                                {/*<div id={"menuGestion"} onMouseEnter={()=>{this.setState({openMenuGestion:"block"})}} onMouseLeave={()=>{this.setState({openMenuGestion:"none"})}} style={{display:"flex", flexDirection:"column", cursor:"pointer"}}>*/}
                                                {/*    <p>Gestion</p>*/}
                                                {/*    <div style={{position:"relative"}}>*/}
                                                {/*        <div style={{display:this.state.openMenuGestion, position:"absolute", backgroundColor:"white", right:-20, width:250, borderRadius:10,border:"1px solid black"}}>*/}
                                                {/*            <div onClick={()=>{this.setState({openDialogueAjoutClient:true})}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"10px 10px 0px 0px"}}>*/}
                                                {/*                <p>Ajouter un client</p>*/}
                                                {/*            </div>*/}
                                                {/*            <div onClick={()=>{this.goToPage("/KageSecur/ListeDesClients")}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 0px 0px"}}>*/}
                                                {/*                <p>Liste des clients</p>*/}
                                                {/*            </div>*/}
                                                {/*            <div onClick={()=>{this.goToPage("/KageSecur/GestionDesApprobateurs")}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 10px 10px"}}>*/}
                                                {/*                <p>Gérer les approbateurs</p>*/}
                                                {/*            </div>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </>
                                            :
                                            <>
                                                <p style={{cursor:"pointer"}} onClick={()=>{this.goToPage("/Suffrage/ListeVotesEnCours")}}>Liste des votes en cours</p>
                                                <p style={{cursor:"pointer"}} onClick={()=>{this.goToPage("/Suffrage/ListeVotesRepondus")}}>Liste des votes répondus</p>
                                                <p style={{cursor:"pointer"}} onClick={()=>{this.goToPage("/Suffrage/MesGroupes")}}>Visualiser mes groupes</p>
                                                {/*<p style={{cursor:"pointer"}} onClick={()=>{this.goToPage("/KageSecur/MiseEnDemeure")}}>Faire une procuration</p>*/}
                                                <p style={{cursor:"pointer"}}>Faire une procuration</p>
                                            </>
                                        }
                                        <div id={"menuAccount"} onMouseEnter={()=>{this.setState({openMenuAccount:"block"})}} onMouseLeave={()=>{this.setState({openMenuAccount:"none"})}} style={{display:"flex", flexDirection:"column", cursor:"pointer"}}>
                                            <div style={{textAlign:"center"}}>
                                                <AccountCircleIcon style={{cursor:"pointer", transform:"scale(1.5)",color:"#6600ff"}}/>
                                                <p style={{margin:0, textAlign:"center"}}>{this.state.user === "gestionnaire" ? this.state.pseudo:this.state.prenom+" "+this.state.nom}</p>
                                            </div>
                                            <div style={{position:"relative"}}>
                                                <div style={{display:this.state.openMenuAccount, position:"absolute", backgroundColor:"white",right:-20, width:250, borderRadius:10,border:"1px solid black"}}>
                                                    <div onClick={()=>{this.setState({openDialogueModifEmail:true})}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"10px 10px 0px 0px"}}>
                                                        <p>Modifier mon email</p>
                                                    </div>
                                                    <div onClick={()=>{this.setState({openDialogueInvalidationCompte:true})}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 0px 0px"}}>
                                                        <p>Invalider mon compte</p>
                                                    </div>
                                                    <div onClick={()=>{this.decoFunction()}} className={"hoverMenuKageSecur"} style={{padding:9,borderRadius:"0px 0px 10px 10px"}}>
                                                        <p style={{color:"#ff3629"}}>Déconnexion</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    ):(
                        <>
                            <img onClick={()=>{this.goToPage("/Suffrage/Accueil")}} style={{textAlign:"center", height:90, marginLeft:20, cursor:"pointer", zIndex:99999}} src={require("../../../assets/logoSuffrage.png")} alt="logoSuffrage"/>
                            <div style={{display:"flex",width:"100%", justifyContent:"flex-end", alignItems:"center", marginRight:40, zIndex:9999}}>
                                {this.state.user === "none" ? (
                                    <>
                                        <Button variant={"contained"} style={{backgroundColor:"#6600ff",cursor:"pointer", right:50 ,position:"absolute", marginBottom:10, marginTop:10, textTransform:"none"}} onClick={()=>{this.setState({displayConnexion:true})}}>
                                            <p style={{display:"flex", justifyContent:"center",alignItems:"center",margin:3, fontSize:16, color:"#FFFFFF"}}>CONNEXION</p>
                                        </Button>
                                    </>
                                ):(
                                    <>
                                        <MenuIcon onClick={()=>{this.setState({displayMenuMobile:!this.state.displayMenuMobile})}} style={{transform:"scale(1.5)",cursor:"pointer", transition : "all .5s cubic-bezier(.25,.8,.25,1)", zIndex:99999}} />
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>

            </>
        )
    }
}

export default withRouter(SharedHeaderSuffrage)