import React, {Component} from 'react';
import {sha256} from 'js-sha256';
import {
    Button,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import GavelIcon from '@mui/icons-material/Gavel';
import Axios from "axios";
import {withRouter} from '../shared/NavigationUtils';
import config from "../json/config.json";

import Swal from "sweetalert2";
import tooltipContenu from "../json/contenu.json";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const columns = [
    { id: 'action', label: "Action", minWidth: 20 },
    { id: 'EmpreinteInitiateur', label: "Empreinte de l'initiateur", minWidth: 40 },
    { id: 'TypeToken', label: 'Type', minWidth: 40 },
    { id: 'ValueToken', label: 'Valeur', minWidth: 75 },
    { id: 'IDToken', label: 'Identification', minWidth: 100 },
    { id: 'ValueTrade', label: 'Echange', minWidth: 40 },
];
const columnsTAI = [
    { id: 'action', label: "Action", minWidth: 20 },
    { id: 'EmpreinteRecepteur', label: "Empreinte du récepteur", minWidth: 40 },
    { id: 'TypeToken', label: 'Type', minWidth: 40 },
    { id: 'ValueToken', label: 'Valeur', minWidth: 75 },
    { id: 'IDToken', label: 'Identification', minWidth: 100 },
    { id: 'ValueTrade', label: 'Echange', minWidth: 40 },
];

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


class TransfertsEnAttente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phase:1,
            transferAnswered:"",
            empreinteRecepteur:"",
            stateDrawer:true
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        let jwtDecoded = sessionStorage.getItem("SPURO_jwt") ? jwt_decode(sessionStorage.getItem("SPURO_jwt")) : {}
        global.emitter.emit("onRouteChanged",{
            pageName:'Transferts en attente',
            pseudo:jwtDecoded.pseudo,
        })
    }

    handleValidation(clef,pseudo){ //Fonction servant a la vérification des données avant l'envoi du formulaire
        if(clef !== "" && pseudo !== ""){
            return true
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Les données saisies sont incorrectes.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return false
        }
    }
    handleChange(event){ //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }
    handleSubmitGetAllTokensAttente(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        const data= new FormData(event.target);
        let pseudo = data.get('champsPseudo'), clef = data.get('champsClef'),hash;
        this.setState({
            pseudoRender:pseudo
        })
        event.preventDefault();
        if(()=>{this.handleValidation(clef, pseudo)}){
            hash = sha256(pseudo.concat(clef)) //Hashage avec protocole sha256
            this.setState({empreinteRecepteur : hash})
            Axios.post(url+"getAllTransfertEnAttente", {
                empreinte:hash,
            }).then(response => {
                if (response.data.exist === "true") {
                    this.setState({
                        listTAR: response.data.allTAR,
                        listTAI: response.data.allTAI,
                        phase:2,
                        pseudoAffichage:pseudo
                    })
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Ce compte n'existe pas.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }
    openAcceptOrRefuse(row){
        Swal.fire({
            title: 'Répondez à ce transfert !',
            text: "Vous ne pourrez pas revenir en arrière après votre réponse.",
            icon: 'warning',
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            denyButtonColor: '#d33',
            confirmButtonText: 'Accepter',
            denyButtonText:"Refuser"
        }).then((result) => {
            if(!result.isDismissed){
                this.setState({
                    transferAnswered:row
                })
                if (result.isConfirmed) {
                    this.answerToTransfertEnAttente(true)
                }else{
                    this.answerToTransfertEnAttente(false)
                }
            }
        })
    }

    answerToTransfertEnAttente(accept){
        Axios.post(url+"validateTransfert", {
            accept:accept,
            empreinteRecepteur:this.state.empreinteRecepteur,
            transfert:this.state.transferAnswered,
        }).then(response => {
            let title, icon;
            if (response.data.resultat === "Vous n'avez pas assez de STC pour réaliser cette transaction."){
                title = "Erreur!"; icon = "error"
            }else{
                title = "Succès!"; icon = "success"
            }
            Swal.fire({
                title: title,
                text: response.data.resultat,
                icon: icon,
                confirmButtonText: 'Ok'
            }).then(()=>{
                if(title === "Succès!"){
                    let remainingTA = this.state.listTAR.slice()
                    remainingTA.splice(remainingTA.indexOf(this.state.transferAnswered),1)
                    this.setState({listTAR:remainingTA})
                }
            })
        }).catch(error => {
            console.log(error);
        });
    }

    deleteTransfert(row){
        Axios.post(url+"cancelTransfer", {
            empreinte :this.state.empreinteRecepteur,
            idToken :row.token.idToken,
        }).then(response => {
            if (response.data.resultat === "Le transfert a bien été annulé."){
                let newListTAI = this.state.listTAI.splice()
                newListTAI.forEach((TAI,index) => {
                    if (TAI.token.idToken === row.token.idToken){
                        this.state.listTAI.splice(index,1)
                    }
                })
                this.setState({listTAI:newListTAI})
                Toast.fire({
                    icon: 'success',
                    title: response.data.resultat
                })
            }else{
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    {this.state.phase === 1 ? (
                        <div style={{overflowY:"auto", padding:30,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                            <h1>Connectez vous à votre compte</h1>
                            <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitGetAllTokensAttente(e)}} noValidate autoComplete="off">
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsPseudo"} className={"champsTexte"} value={this.state.value} placeholder={"Entrez votre pseudonyme."} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Identifiant" variant="outlined"/>
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsClef"} type={this.state.showPassword ? "text":"password"} className={"champsTexte"} value={this.state.value} placeholder={"Entrez votre Clef Unique."} onChange={(e)=>{this.handleChange(e)}} id="champsClef" label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }}
                                    />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button type={"submit"} variant="contained">Suivant</Button>
                            </form>
                        </div>
                    ):("")}
                    {this.state.phase === 2 ? (
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div style={{width:"100%",backgroundColor:"white",display:"flex",marginBottom:30,flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                                <div>
                                    <h3 className={"flexCenter"}> <AccountCircleRoundedIcon style={{transform:"scale(2)",marginRight:25}}/> Connecté en tant que : {this.state.pseudoRender}</h3>
                                </div>
                            </div>
                            <div style={{overflowY:"auto", padding:30,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                                <h3>Liste des transferts reçus en attente d'acceptation</h3>
                                <Paper style={{margin:10, maxHeight:400, overflowY:"scroll"}}>
                                    <TableContainer>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                        >
                                                            {column.label === "Echange" ? (
                                                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                    {column.label}
                                                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltiptroischiffre}>
                                                                        <HelpOutlineIcon style={{marginLeft:5}} className={"helpIcon"} />
                                                                    </Tooltip>
                                                                </div>
                                                            ):(column.label)}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(this.state.listTAR).map((row) => {
                                                    return (
                                                        <TableRow onClick={()=>{this.openAcceptOrRefuse(row)}} style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            {columns.map((column) => {
                                                                return (
                                                                    <TableCell key={column.id}>
                                                                        <>
                                                                            {column.id === "EmpreinteInitiateur" ? (
                                                                                <>
                                                                                    {(row.empreinteInitiateur).slice(0,4) + "..." + (row.empreinteInitiateur).slice(-4)}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {column.id === "TypeToken" ? (
                                                                                        <>
                                                                                            {row.token.type}
                                                                                        </>
                                                                                    ):(
                                                                                        <>
                                                                                            {column.id === "ValueToken" ? (
                                                                                                <>
                                                                                                    {(row.token.value).slice(0,4) + "..." + (row.token.value).slice(-4)}

                                                                                                </>
                                                                                            ):(
                                                                                                <>
                                                                                                    {column.id === "IDToken" ? (
                                                                                                        <>
                                                                                                            {row.token.type === "fichier" ? (
                                                                                                                <>
                                                                                                                    {row.token.fileName}
                                                                                                                </>
                                                                                                            ):(
                                                                                                                <>
                                                                                                                    {row.token.idToken}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </>
                                                                                                    ):(
                                                                                                        <>
                                                                                                            {column.id === "action" ? (
                                                                                                                    <>
                                                                                                                        <Button onClick={()=>{this.openAcceptOrRefuse(row)}}>
                                                                                                                            <GavelIcon/>
                                                                                                                        </Button>
                                                                                                                    </>
                                                                                                                ):(
                                                                                                                    <>
                                                                                                                        {row.valueTrade.replace(".", ',') + " STC"}
                                                                                                                    </>
                                                                                                            )}

                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                            {this.state.listTAR.length === 0 && <p>Vous n'avez pas de demande de transferts en attente...</p>}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                            <div style={{overflowY:"auto",marginTop:40, padding:30,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                                <h3>Liste des transferts envoyés en attente d'acceptation</h3>
                                <Paper style={{margin:10, maxHeight:400, overflowY:"scroll"}}>
                                    <TableContainer>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {columnsTAI.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                        >
                                                            {column.label === "Echange" ? (
                                                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                    {column.label}
                                                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltiptroischiffre}>
                                                                        <HelpOutlineIcon style={{marginLeft:5}} className={"helpIcon"} />
                                                                    </Tooltip>
                                                                </div>
                                                            ):(column.label)}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(this.state.listTAI).map((row) => {
                                                    return (
                                                        <TableRow style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            {columnsTAI.map((column) => {
                                                                return (
                                                                    <TableCell key={column.id}>
                                                                        <>
                                                                            {column.id === "EmpreinteRecepteur" ? (
                                                                                <>
                                                                                    {row.empreinteRecepteur.slice(0,4) + "..." + row.empreinteRecepteur.slice(-4)}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {column.id === "TypeToken" ? (
                                                                                        <>
                                                                                            {row.token.type}
                                                                                        </>
                                                                                    ):(
                                                                                        <>
                                                                                            {column.id === "ValueToken" ? (
                                                                                                <>
                                                                                                    {(row.token.value).slice(0,4) + "..." + (row.token.value).slice(-4)}

                                                                                                </>
                                                                                            ):(
                                                                                                <>
                                                                                                    {column.id === "IDToken" ? (
                                                                                                        <>
                                                                                                            {row.type === "fichier" ? (
                                                                                                                <>
                                                                                                                    {row.token.fileName}
                                                                                                                </>
                                                                                                            ):(
                                                                                                                <>
                                                                                                                    {row.token.idToken}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </>
                                                                                                    ):(
                                                                                                        <>
                                                                                                            {column.id === "action" ? (
                                                                                                                    <>
                                                                                                                        <Button onClick={()=>{this.deleteTransfert(row)}}>
                                                                                                                            <CancelIcon/>
                                                                                                                        </Button>
                                                                                                                    </>
                                                                                                                ):(
                                                                                                                    <>
                                                                                                                        {row.valueTrade + " STC"}
                                                                                                                    </>
                                                                                                            )}

                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                            {this.state.listTAI.length === 0 && <p>Vous n'avez pas de demande de transferts en attente...</p>}
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </div>
                    ):("")}
                </div>
            </div>
        );
    }
}

export default withRouter(TransfertsEnAttente)