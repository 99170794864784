import React, {Component} from 'react';
import {sha256} from 'js-sha256';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Axios from "axios";
import {withRouter} from '../shared/NavigationUtils';
import config from "../json/config.json";

import Swal from "sweetalert2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Dropzone from "react-dropzone";
import tooltipContenu from "../json/contenu.json";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const columns = [
    { id: 'ValeurToken', label: 'Valeur du token', minWidth: 75 },
    { id: 'TypeToken', label: 'Type', minWidth: 75 },
    { id: 'IDToken', label: 'Identification', minWidth: 100 },
];

class TransfertToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phase:1,
            typeToken:"",
            checkedDonation:false,
            empreinteInitiateur:"",
            hashFile:"",
            fileName:"",
            stateDrawer:true
        };
    }
    _onOpenDrawerModif;

    componentWillUnmount() {
        if(this._onOpenDrawerModif){this._onOpenDrawerModif.remove()}
    }

    componentDidMount() {
        this._onOpenDrawerModif = global.emitter.addListener("openDrawerModif",(data)=>{
            this.setState({stateDrawer:data.stateDrawer})
        })
        let jwtDecoded = sessionStorage.getItem("SPURO_jwt") ? jwt_decode(sessionStorage.getItem("SPURO_jwt")) : {}
        global.emitter.emit("onRouteChanged",{
            pageName:'Transférer des tokens',
            pseudo:jwtDecoded.pseudo,
        })
    }

    handleValidation(clef,pseudo){ //Fonction servant a la vérification des données avant l'envoi du formulaire
        if(clef !== "" && pseudo !== ""){
            return true
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Les données saisies sont incorrectes.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return false
        }
    }
    handleChange(event){ //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }
    handleSubmitPhase1(event){ //Lorsque l'on envois le formulaire, on récupère les données des inputs, on transforme le tout, sauf l'email en hash, et on envoie l'email et le Hash à l'smartContracts grâce a une requete axios
        const data= new FormData(event.target);
        let pseudo = data.get('champsPseudo'), clef = data.get('champsClef'),hash;
        event.preventDefault();
        if(()=>{this.handleValidation(clef, pseudo)}){
            hash = sha256(pseudo.concat(clef)) //Hashage avec protocole sha256
            Axios.post(url+"getAllToken", {
                hash:hash,
            }).then(response => {
                if (response.data.resultat !== "Ce client n'existe pas."){
                    sessionStorage.setItem("SPURO_jwtClient",response.data.jwt)
                    this.setState({
                        phase:2,
                        allToken:response.data.resultat,
                        empreinteInitiateur:hash,
                        pseudoAffichage:pseudo,
                    })
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: "Erreur ce compte n'existe pas.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    hashFile(file){
        let hash = sha256(sha256(file))
        this.setState({hashFile:hash})
    }

    goToPhase3(token){
        this.setState({
            phase:3,
            tokenChosen:token
        })
    }

    handleSubmitPhase3(e){
        e.preventDefault();
        const data= new FormData(e.target);
        let empreinteRecepteur = data.get('champsEmpreinteRecepteur'), valueToReceive = parseFloat(data.get('champsValueToReceive')).toFixed(3),valueToTransfert = data.get('champsValueToTransfert');
        if(this.state.tokenChosen.type === "fongible" && valueToTransfert <= 0){
            Swal.fire({
                title: 'Erreur!',
                text: "Veuillez entrer une valeur de token à transférer correcte.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return false
        }else if (this.state.tokenChosen.type !== "fongible" && this.state.checkedDonation === false && valueToReceive <= 0){
            Swal.fire({
                title: 'Erreur!',
                text: "Veuillez entrer une valeur de token à reçevoir correcte.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
            return false
        }else{
            if (this.state.tokenChosen.type === "fongible" || this.state.checkedDonation){
                valueToReceive = false
            }

            Axios.post(url+"transferToken", {
                empreinteRecepteur:empreinteRecepteur,
                empreinteInitiateur:this.state.empreinteInitiateur,
                valueToReceive:valueToReceive,
                valueToTransfert:valueToTransfert,
                tokenChosen:this.state.tokenChosen,
            }).then(response => {
                if(response.data.resultat === "Votre demande de transfert a été envoyé au récepteur." || response.data.resultat === "Votre transfert a été pris en compte. Il sera effectif dans les plus brefs délais."){
                    Swal.fire({
                        title: 'Succès!',
                        text: response.data.resultat,
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        window.location.reload()
                    })
                }else{
                    Swal.fire({
                        title: 'Erreur!',
                        text: response.data.resultat,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }).catch(() => {
                Swal.fire({
                    title: 'Erreur!',
                    text: "Une erreur est survenue.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            });
        }
    }
    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <div className={"flexCenter"} style={{marginTop:70,marginLeft:this.state.stateDrawer ? 250:65,height:"calc(100% - 70px)" ,width:this.state.stateDrawer ? "calc(100% - 250px)":"calc(100% - 65px)",transition: "margin 300ms, width 300ms",backgroundColor:"#72AACCFF"}}>
                    <div style={{overflowY:"auto", padding:30,backgroundColor:"white",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:8,boxShadow:'0px 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)'}}>
                        <h1>Transférer des tokens {this.state.phase === 2 ? (<>{this.state.pseudoAffichage}</>):("")}</h1>
                        {this.state.phase === 1 ? (
                            <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase1(e)}} autoComplete="off">
                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsPseudo"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez votre identifiant."} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic champsPseudo" label="Identifiant" variant="outlined"/>
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipPseudo}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>

                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                    <TextField name={"champsClef"} type={this.state.showPassword ? "text":"password"} className={"champsTexte"} required={true} value={this.state.value} placeholder={"Entrez votre Clef Unique."} onChange={(e)=>{this.handleChange(e)}} id="champsClef" label="Clef Unique" variant="outlined"
                                               InputProps={{
                                                   endAdornment:
                                                       <InputAdornment position="end">
                                                           <IconButton
                                                               aria-label="toggle password visibility"
                                                               onClick={()=>{this.setState({showPassword:!this.state.showPassword})}}
                                                           >
                                                               {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                           </IconButton>
                                                       </InputAdornment>
                                               }}
                                    />
                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipIDPass}>
                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                    </Tooltip>
                                </div>
                                <Button type={"submit"} variant="contained">Suivant</Button>
                            </form>
                        ):("")}
                        {this.state.phase === 2 ? (
                            <>
                                <h4 style={{margin:0}}>Mes tokens :</h4>
                                <Paper style={{margin:10, maxWidth:"115vh", maxHeight:400, overflowY:"scroll"}}>
                                    <TableContainer>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                        >
                                                            {column.label === "Valeur du token" ? (
                                                                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                    {column.label}
                                                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltiptroischiffre}>
                                                                        <HelpOutlineIcon style={{marginLeft:5}} className={"helpIcon"} />
                                                                    </Tooltip>
                                                                </div>
                                                            ):(column.label)}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(this.state.allToken).map((row) => {
                                                    return (
                                                        <TableRow onClick={()=>{this.goToPhase3(row)}} style={{cursor:"pointer"}} hover role="checkbox" tabIndex={-1} key={row.code}>
                                                            {columns.map((column) => {
                                                                return (
                                                                    <TableCell key={column.id}>
                                                                        <>
                                                                            {column.id === "ValeurToken" ? (
                                                                                <>
                                                                                    {row.type === "fichier" ? ((row.value).slice(0,4) + "..." + (row.value).slice(-4)):(
                                                                                        <>
                                                                                            {row.type === "fongible" ? (row.value.toFixed(3).replace(".", ',')):(row.value)}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {column.id === "TypeToken" ? (
                                                                                        <>
                                                                                            {row.type}
                                                                                        </>
                                                                                    ):(
                                                                                        <>
                                                                                            {row.type === "fichier" ? (
                                                                                                <>
                                                                                                    {row.fileName}
                                                                                                </>
                                                                                            ):(
                                                                                                <>
                                                                                                    {row.idToken}
                                                                                                </>
                                                                                            )}

                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                <Accordion style={{marginBottom:10, width:'100%'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <div style={{ display:"flex", justifyContent:"start", width:'100%',transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                            <Typography style={{marginLeft:15}}>Vérifier le hash de mon fichier ...</Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{display:"flex",justifyContent:"center"}}>
                                        <div style = {{display:"flex", flexDirection:"column", width:"100%"}}>
                                            <Dropzone onDrop={(acceptedFiles) => {
                                                this.setState({acceptedFile:acceptedFiles})
                                                acceptedFiles.forEach((file) => {
                                                    this.setState({fileName:file.name})
                                                    const reader = new FileReader()
                                                    reader.onload = () => {
                                                        // Do whatever you want with the file contents
                                                        const binaryStr = reader.result
                                                        this.hashFile(binaryStr)
                                                    }
                                                    reader.readAsArrayBuffer(file)
                                                })}}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()} style={{padding:20,borderRadius:5, border:"solid black 1px", cursor:"pointer", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                                                            <input {...getInputProps()} />
                                                            {this.state.hashFile === '' ? (
                                                                <p>Déposez votre fichier ici.</p>
                                                            ):(
                                                                <p>Empreinte formulée.</p>
                                                            )}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                            {(this.state.fileName === "" ? (""):(
                                                <>
                                                    <p><span style={{fontWeight:"bold"}}>Nom du fichier :</span> {this.state.fileName}</p>
                                                    <p><span style={{fontWeight:"bold"}}>Double Hash du fichier :</span> {this.state.hashFile}</p>
                                                </>
                                            ))}


                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        ):("")}
                        {this.state.phase === 3 ? (
                            <>
                                <form style={{display:"flex", flexDirection:"column", justifyContent:"center",alignItems:"center"}} onSubmit={(e)=>{this.handleSubmitPhase3(e)}}  autoComplete="off">
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                        <TextField name={"champsEmpreinteRecepteur"} required={true} className={"champsTexte"} placeholder={"Entrez l'empreinte du récepteur."} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} id="outlined-basic" label="Empreinte du récepteur" variant="outlined"/>
                                        <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipEmpreinteRecepteur}>
                                            <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                        </Tooltip>
                                    </div>
                                    {this.state.tokenChosen.idToken ? (
                                        <>
                                            <div style={{display:"flex"}}>
                                                <Checkbox
                                                    checked={this.state.checkedDonation}
                                                    onChange={()=>{this.setState({checkedDonation:!(this.state.checkedDonation)})}}
                                                    color={"primary"}
                                                /><p>Ce transfert est une donation.</p>
                                            </div>

                                            {this.state.checkedDonation ? (
                                                ""
                                            ):(
                                                <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}  >
                                                    <TextField name={"champsValueToReceive"} required={true} className={"champsTexte"} value={this.state.value} placeholder={"Entrez le nombre de STCs que vous souhaitez en échange."} onChange={(e)=>{this.handleChange(e)}} label="STCs à recevoir" variant="outlined" />
                                                    <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipSTCEchange}>
                                                        <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </>
                                    ):(
                                        <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>
                                            <TextField name={"champsValueToTransfert"} required={true} className={"champsTexte"} value={this.state.value} placeholder={"Entrez le nombre de STCs que vous voulez transférer."} onChange={(e)=>{this.handleChange(e)}} label="STCs à transférer" variant="outlined" />
                                            <Tooltip arrow={true} title={tooltipContenu.tooltipInfos.tooltipSTCDonation}>
                                                <HelpOutlineIcon style={{marginTop:"-13px", marginLeft:5}} className={"helpIcon"} />
                                            </Tooltip>
                                        </div>
                                    )}
                                    <Button type={"submit"} variant="contained">Valider le transfert</Button>
                                </form>
                            </>
                        ):("")}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(TransfertToken)