import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import {AddCircleOutline, Image, InsertDriveFile, KeyboardReturn, List} from "@mui/icons-material";
import Delete from '@mui/icons-material/Delete';
import Download from '@mui/icons-material/Download';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import Dropzone from "react-dropzone";
import {CircularProgress, Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";

const sizeLimitFile = 52428800 // 50MB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class EchangeFichierSTO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{
            fileToSend:null,
            sendingFile:false,
            allFiles:[],
            retour:false,
            openDialogActio:false,
            listActionnaires:[],
            numberParts:"",
            prenomValue:"",
            nomValue:""
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        const retour = new URLSearchParams(window.location.search).get("r")
        if(retour !== null){
            this.setState({retour})
        }
        const td = new URLSearchParams(window.location.search).get("td"),
            jwt = new URLSearchParams(window.location.search).get("jwt");
        if(td !== null){
            this.setState({td})
        }
        window.addEventListener('resize', this.resizePage)
        new Promise(async resolve => {
            if(jwt !== null){
                this.setState({jwt}, ()=>{resolve()})
            }else{
                this.setState({jwt:sessionStorage.getItem("SMSTO_jwt")},()=> {resolve()})
            }
        }).then(()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedStockMarketSTO", {
                        jwt: this.state.jwt,
                    })
                    this.setState({admin:jwtDecoded.admin})
                    this.getCompanyProof()
                    this.getRealInvestors()
                }else{
                    Swal.fire({
                        title: 'Veuillez vous reconnecter',
                        text: "Votre session a expiré, veuillez vous reconnecter.",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        sessionStorage.removeItem("SMSTO_jwt")
                        global.emitter.emit("onRouteChangedStockMarketSTO", {
                            jwt: null,
                        })
                        window.location.replace("/StockMarketSTO/Accueil")
                    })
                }
            })
        })
    }

    addCompanyProof(){
        Axios.post(url+"addCompanyProof",{siren:this.state.td,fileName:this.state.fileToSend.fileName,buffer:this.state.fileToSend.buf},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let allFilesTemp = this.state.allFiles.slice()
                const ext = response.data.newName.split(".")[response.data.newName.split(".").length-1]
                let type = "fichier"
                const listImages = ["png","jpg","jpeg","gif"]
                if (listImages.includes(ext)){
                    type = "image"
                }else if(ext === "pdf"){
                    type = "pdf"
                }else{
                    type = "fichier"
                }
                allFilesTemp.push({fileName:response.data.newName,type:type})
                this.setState({allFiles:allFilesTemp, fileToSend:null, sendingFile:false})
                Toast.fire({
                    icon: 'success',
                    title: 'Fichier envoyé !'
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: 'Une erreur est survenue.'
                })
                this.setState({fileToSend:null, sendingFile:false})
            }
        })
    }

    getCompanyProof(){
        Axios.post(url+"getCompanyProof",{siren:this.state.td},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                if(!response.data.isAuthorized){
                    this.props.navigate("/StockMarketSTO/Accueil")
                    Swal.fire({
                        title: 'Erreur',
                        text: "Vous n'êtes pas autorisé à accéder à cette page",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }else{
                    let allFilesTemp = response.data.allFiles
                    let allFilesObj = []
                    allFilesTemp.forEach(elt=>{
                        const ext = elt.split(".")[elt.split(".").length-1]
                        let type = "fichier"
                        const listImages = ["png","jpg","jpeg","gif"]
                        if (listImages.includes(ext)){
                            type = "image"
                        }else if(ext === "pdf"){
                            type = "pdf"
                        }else{
                            type = "fichier"
                        }
                        allFilesObj.push({type:type,fileName:elt})
                    })
                    this.setState({allFiles:allFilesObj,canUpload:response.data.canUpload})
                }
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    getRealInvestors(){
        Axios.post(url+"getRealInvestors",{siren:this.state.td},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                this.setState({listActionnaires:response.data.realInvestors})
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        })
    }

    downloadFile(file){
        window.open(url+"smsto/"+this.state.td+"/"+file+"?jwt="+this.state.jwt+"&siren="+this.state.td)
    }

    deleteFile(file){
        Swal.fire({
            title: 'Attention!',
            text: "Êtes-vous certain de vouloir supprimer ce fichier ?",
            icon: 'info',
            confirmButtonText: "Oui",
            showCancelButton:true,
            cancelButtonText:"Annuler",
            cancelButtonColor:"red"
        }).then((result)=> {
            if (result.isConfirmed) {
                Axios.post(url+"deleteCompanyProof",{siren:this.state.td,toDelete:file},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === "done"){
                        let allFilesTemp = this.state.allFiles.slice()
                        allFilesTemp.forEach(elt => {
                            if (elt.fileName === file){
                                allFilesTemp.splice(allFilesTemp.indexOf(elt),1)
                            }
                        })
                        this.setState({allFiles:allFilesTemp})
                        Toast.fire({
                            icon: 'success',
                            title: 'Fichier supprimé !'
                        })
                    }else{
                        Toast.fire({
                            icon: 'error',
                            title: response.data.resultat
                        })
                    }
                })
            }
        })

    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    deleteActionnaire(investor){
        Axios.post(url+"deleteInvestors",{siren:this.state.td,idToken:investor.idToken},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let listeActionnairesTemp = this.state.listActionnaires.slice()
                listeActionnairesTemp.splice(listeActionnairesTemp.findIndex(elt => elt.idToken === investor.idToken),1)
                this.setState({listActionnaires:listeActionnairesTemp})
                Toast.fire({
                    icon: 'success',
                    title: 'Investisseur retiré !'
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: response.data.resultat
                })
            }
        })
    }

    addActionnaire(){
        Axios.post(url+"addInvestors",{siren:this.state.td,nom:this.state.nomValue, prenom:this.state.prenomValue, adresse:this.state.adresseValue, numberActions:this.state.partsValue},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SMSTO_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "done"){
                let listeActionnairesTemp = this.state.listActionnaires.slice()
                listeActionnairesTemp.push(response.data.newInvestor)
                this.setState({listActionnaires:listeActionnairesTemp})
                Toast.fire({
                    icon: 'success',
                    title: 'Investisseur ajouté !'
                })
            }else{
                Toast.fire({
                    icon: 'error',
                    title: response.data.resultat
                })
            }
        })
        this.setState({
            partsValue:"",
            prenomValue:"",
            nomValue:"",
            adresseValue:""
        })
    }

    render() { //Affichage sur la page
        return (
            <div id={"mesVideosFirstDiv"} style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.openDialogActio}
                    onClose={()=>{this.setState({openDialogActio:false})}}
                    style={{padding:11}}
                >
                    <DialogTitle>Liste des anciens actionnaires :</DialogTitle>
                    <DialogContent style={{height:"100%"}}>
                        {this.state.listActionnaires.length === 0 && (
                            <div className={"flexCenter"} style={{width:"100%", height:100}}>
                                <p className={"flexCenter"} style={{margin:0, justifyContent:"flex-start", flex:1, fontStyle:"italic"}}>Aucun actionnaire n'a été recensé ici</p>
                            </div>
                        )}
                        <div className={"flexCenter"} style={{maxHeight:350, justifyContent:"flex-start", overflow:"auto", flexDirection:"column"}}>
                            {this.state.listActionnaires.map((elt,index)=>(
                                <>
                                    <div className={"flexCenter"} style={{width:"100%", height:100, marginTop:5,marginBottom:5}}>
                                        <h4 className={"flexCenter"} style={{margin:0, justifyContent:"flex-start", flex:2}}>
                                            {elt.prenom + " " + elt.nom}
                                        </h4>
                                        <h4 className={"flexCenter"} style={{margin:0, justifyContent:"flex-start", flex:1}}>
                                            {elt.boughtActions}
                                        </h4>
                                        <h4 className={"flexCenter"} style={{margin:0, justifyContent:"flex-start", flex:3}}>
                                            {elt.adresse}
                                        </h4>
                                        <div style={{display:"flex", justifyContent:"flex-end", flex:1, width:"fit-content", flexDirection:"column"}}>
                                            <button onClick={()=>{this.deleteActionnaire(elt)}} style={{marginLeft:20,color:"#FFF",backgroundImage: "linear-gradient(to right, #ff0000 0%, #d20000 51%, #ff0000 100%)"}} className={"flexCenter divButtonBuyResale"}><Delete/></button>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                        <div style={{width:"100%", marginBottom:15, display:"flex", alignItems:"center", justifyContent:"space-between", flexDirection:!this.state.showBrowserView && "column"}}>
                            <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%", marginBottom: !this.state.showBrowserView && 15}} name={"prenomValue"} value={this.state.prenomValue} onChange={(e)=>{this.handleChange(e)}} label="Prénom" variant="outlined"/>
                            <TextField required={true} style={{width:this.state.showBrowserView ? "49%":"100%"}} name={"nomValue"} value={this.state.nomValue} onChange={(e)=>{this.handleChange(e)}} label="Nom" variant="outlined"/>
                        </div>
                        <TextField required={true} style={{width:"100%", marginBottom:15}} name={"partsValue"} value={this.state.partsValue} onChange={(e)=>{this.handleChange(e)}} label="Nombre de parts" variant="outlined"/>
                        <TextField required={true} style={{width:"100%", marginBottom:15}} name={"adresseValue"} value={this.state.adresseValue} onChange={(e)=>{this.handleChange(e)}} label="Adresse" variant="outlined"/>
                        <button onClick={()=>{this.addActionnaire()}} style={{width:"100%"}} className={"divButtonBuyResale"}>Ajouter actionnaire</button>
                    </DialogContent>
                </Dialog>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                            <div style={{width:"100%", display:"flex", alignItems:"flex-start", justifyContent:"center", flexDirection:"column"}}>
                                <h1 style={{marginBottom:10}} className={"textShadowBorder"}>Echange de fichiers avec la modération :</h1>
                                <div style={{background: "linear-gradient(49deg, rgba(42, 140, 199,1) 0%, rgba(151, 194, 219,1) 100%)", width:600, height:3, borderRadius:100, marginBottom:10}}></div>
                                {this.state.admin && (
                                    <button style={{padding:8, fontSize:14, marginRight:10, marginBottom:10}} onClick={()=>{this.setState({openDialogActio:true})}} className={"divButtonBuyResale flexCenter"}><List style={{marginRight:10, color:"#FFFFFF"}}/> Liste des anciens actionnaires</button>
                                )}
                                {this.state.retour && (
                                    <button style={{padding:8, fontSize:14, marginRight:10, marginBottom:10}} onClick={()=>{this.props.navigate(-1)}} className={"divButtonBuyResale flexCenter"}><KeyboardReturn style={{marginRight:10, color:"#FFFFFF"}}/> Retour</button>
                                )}
                            </div>
                            <p style={{width:"100%", textAlign:"left"}}>Fichiers obligatoires : Kbis - Bilan ...</p>
                            <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-start", flexWrap:"wrap"}}>
                                <Dropzone multiple={false} onDrop={(acceptedFiles) => {
                                    acceptedFiles.forEach((file)=>{
                                        if(file.size >= sizeLimitFile){
                                            Toast.fire({
                                                icon: 'error',
                                                title: 'Le fichier est trop lourd (>50MB) !'
                                            })
                                        }else{
                                            this.setState({sendingFile:true})
                                            let self = this;
                                            const reader = new FileReader()
                                            reader.onload = () => {
                                                self.setState({fileToSend: {buf:Buffer.from(reader.result,"base64"),fileName:file.name}},()=>{
                                                    self.addCompanyProof()
                                                })
                                            }
                                            reader.readAsArrayBuffer(file)
                                        }
                                    })
                                }}>
                                    {({getRootProps, getInputProps}) => (
                                        <section style={{textAlign:"center"}}>
                                            <div className={"divUploadFileEchange flexCenter"} style={{cursor:((this.state.sendingFile || !this.state.canUpload)&& this.state.admin === false) && "default"}} {...getRootProps()}>
                                                {this.state.fileToSend === null ? (
                                                    <>
                                                        <input disabled={(this.state.sendingFile || !this.state.canUpload) && this.state.admin === false} {...getInputProps()} />
                                                        <div style={{color:((this.state.sendingFile || !this.state.canUpload) && this.state.admin === false) && "gray"}}>
                                                            <h3>Ajouter un fichier</h3>
                                                            <AddCircleOutline style={{width:60, height:60}}/>
                                                        </div>
                                                    </>
                                                ):(
                                                    <>
                                                        <h3>Fichier en cours d'ajout</h3>
                                                        <CircularProgress style={{color:"#000", width:60, height:60}}/>
                                                    </>
                                                )}

                                            </div>

                                        </section>
                                    )}
                                </Dropzone>
                                {this.state.allFiles.map(elt=>(
                                    <div className={"divUploadFileEchange flexCenter"} style={{flexDirection:"column", justifyContent:"space-between"}}>

                                        <p style={{width:"95%",margin:"5%", textAlign:"center",wordWrap: "break-word", overflow:"hidden", maxHeight:63}}>{elt.fileName}</p>
                                        {elt.type === "image" && (
                                            <Image style={{width:"25%", height:"25%"}}/>
                                        )}
                                        {elt.type === "pdf" && (
                                            <PictureAsPdf style={{width:"25%", height:"25%"}}/>
                                        )}
                                        {elt.type === "fichier" && (
                                            <InsertDriveFile style={{width:"25%", height:"25%"}}/>
                                        )}
                                        <div className={"flexCenter"} style={{height:"30%", width:"100%"}}>
                                            <div onClick={()=>{this.downloadFile(elt.fileName)}} className={"flexCenter darkenHover"} style={{width:"50%", height:"100%"}}>
                                                <Download/>
                                            </div>
                                            <div onClick={()=>{this.deleteFile(elt.fileName)}} className={"flexCenter darkenHover"} style={{width:"50%", height:"100%"}}>
                                                <Delete/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(EchangeFichierSTO)