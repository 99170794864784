import React, {Component} from 'react';
import {Button} from '@mui/material';
import {withRouter} from '../../../../shared/NavigationUtils';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {isBrowser} from "react-device-detect";

let drawerWidth;

class SharedDrawerEkocoOR extends Component {

    constructor(props) {
        super(props);
        this.state={
            openDrawer : false,
            pseudo:'',
            AccueilBG:'', AccueilColor:'#b0a9a8',
            GestionTokensBG:'', GestionTokensColor:'#b0a9a8',
            MonCompteBG:'', MonCompteColor:'#b0a9a8',
            fontSize:20,
            showBrowserView:true
        }
    }

    _onRouteChangedSubscription;

    resizePage = () => {
        if(document.body.offsetWidth <= 600 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false,fontSize:"5.3vw"})
        }else if(document.body.offsetWidth > 600 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,fontSize:20})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 600 || !isBrowser){
            this.setState({openDrawer: false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            AccueilBG:'', AccueilColor:'#b0a9a8',
            GestionTokensBG:'', GestionTokensColor:'#b0a9a8',
            MonCompteBG:'', MonCompteColor:'#b0a9a8',
            drawerHeight:document.body.scrollHeight
        })
        if (!isBrowser){
            this.setState({
                fontSize:"5.3vw",
                openDrawer:false
            })
        }
    }

    componentWillUnmount(){
        if(this._onRouteChangedSubscription){this._onRouteChangedSubscription.remove()}
    }

    handleDisplayDrawer(){
        this.setState({
            openDrawer : !(this.state.openDrawer)
        })
    }
    decoFonction(){
        sessionStorage.removeItem('EKOCO_pseudo')
        sessionStorage.removeItem('EKOCO_email')
        sessionStorage.removeItem('EKOCO_hash')

        this.props.navigate('/Ekoco/OctobreRose/Connexion')
    }

    render() {
        
        return(
            <div style={{width:this.state.openDrawer ? (this.state.showBrowserView ? 400:"100%"):100, backgroundColor:this.state.openDrawer ? "#FFF":"transparent",zIndex:3,height:this.state.openDrawer ? "100%":100,position:"absolute",display:"flex", overflowY:"auto",overflowX:"hidden",transition : "all .5s cubic-bezier(.25,.8,.25,1)", flexDirection:"column", justifyContent:"start", alignItems:"end", boxShadow:this.state.openDrawer ? '0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 1px 1px rgba(0,0,0,0.12),0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)':"0px 0px 0px"}}>
                {this.state.openDrawer ?(
                    <div>
                        <div>
                            <div style={{width:'100%', display:"flex", justifyContent:"flex-end"}}>
                                <Button style={{width:50}} onClick={()=>{this.handleDisplayDrawer()}}><ChevronLeftIcon style={{color:"#b0a9a8",width:35,height:35}}/></Button>
                            </div>
                            <div style={{width:"100%", display:"flex", justifyContent:"center",alignItems:"center"}}>
                                <img style={{width:"55%", textAlign:"center"}} src={require("../../assetsEkoco/logo_fulltext_Rose.png")} alt="LogoEkoco"/>
                            </div>
                        </div>
                        <div style={{opacity:0.98}}>
                            <hr style={{color:"#b0a9a8", margin:0, marginTop:5}}/>
                            <Button style={{width:"100%", height:50, padding:0, margin:0, textTransform:"none", borderRadius:0,marginTop:-1}} onClick={()=>{this.props.navigate("/Ekoco/OctobreRose/Home")}}>
                                <div style={{display:"flex", width:"100%", alignItems:"center"}}>
                                    <div style={{display:"flex", justifyContent:"flex-start", width:"100%"}}>
                                        <p style={{margin:0,marginLeft:7,fontSize:this.state.fontSize, color:"#b0a9a8", whiteSpace:"nowrap"}}>Accueil</p>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"flex-end", width:"100%"}}>
                                        <ChevronRightIcon style={{color:"#b0a9a8"}} />
                                    </div>
                                </div>
                            </Button>
                            <hr style={{color:"#b0a9a8", margin:0}}/>
                            <Button style={{width:"100%", height:50, padding:0, margin:0, textTransform:"none", borderRadius:0, marginTop:-1}} onClick={()=>{this.props.navigate("/Ekoco/OctobreRose/MesParcours")}}>
                                <div style={{display:"flex", width:"100%", alignItems:"center"}}>
                                    <div style={{display:"flex", justifyContent:"flex-start", width:"100%"}}>
                                        <p style={{margin:0,marginLeft:7,fontSize:this.state.fontSize, color:"#b0a9a8", whiteSpace:"nowrap"}}>Mes parcours</p>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"flex-end", width:"100%"}}>
                                        <ChevronRightIcon style={{color:"#b0a9a8"}}/>
                                    </div>
                                </div>
                            </Button>
                            <hr style={{color:"#b0a9a8", margin:0}}/>
                            <Button style={{width:"100%", height:50, padding:0, textTransform:"none", borderRadius:0, marginTop:-1}} onClick={()=>{this.props.navigate("/Ekoco/OctobreRose/Donation")}}>
                                <div style={{display:"flex", width:"100%", alignItems:"center"}}>
                                    <div style={{display:"flex", justifyContent:"flex-start", width:"100%"}}>
                                        <p style={{margin:0,marginLeft:7,fontSize:this.state.fontSize, color:"#b0a9a8", whiteSpace:"nowrap"}}>Donation</p>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"flex-end", width:"100%"}}>
                                        <ChevronRightIcon style={{color:"#b0a9a8"}}/>
                                    </div>
                                </div>
                            </Button>
                            <hr style={{color:"#b0a9a8", margin:0}}/>
                            <Button style={{width:"100%", height:50, padding:0, textTransform:"none", borderRadius:0, marginTop:-1}} onClick={()=>{this.decoFonction()}}>
                                <div style={{display:"flex", width:"100%", alignItems:"center"}}>
                                    <div style={{display:"flex", justifyContent:"flex-start", width:"100%"}}>
                                        <p style={{margin:0,marginLeft:7,fontSize:this.state.fontSize, color:"#ff615d", whiteSpace:"nowrap"}}>Me déconnecter</p>
                                    </div>
                                    <div style={{display:"flex", justifyContent:"flex-end", width:"100%"}}>
                                        <ChevronRightIcon style={{color:"#b0a9a8"}}/>
                                    </div>
                                </div>
                            </Button>
                            <hr style={{color:"#b0a9a8", margin:0}}/>
                        </div>
                    </div>
                ):(
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center",height:100}}>
                        <Button onClick={()=>{this.handleDisplayDrawer()}} style={{marginLeft:10}}>
                            <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column"}}>
                                <MenuIcon style={{color:"#b0a9a8", width:50,height:50}}/>
                                <p style={{color:"#b0a9a8", margin:"0px"}}>MENU</p>
                            </div>
                        </Button>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(SharedDrawerEkocoOR)