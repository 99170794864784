import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import {isMobile} from "react-device-detect";
import CloseIcon from '@mui/icons-material/Close';
import Axios from "axios";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const columns = [
    { id: 'Identifiant', label: 'Identifiant', minWidth: 75 },
    { id: 'Email', label: 'Email', minWidth: 150 },
    { id: 'Action', label: 'Action', minWidth: 20 }
];

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let jwtDecoded;

class Validateurs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDrawer : false,
            displayAdministration : false,
            rows : [],
            disabled:false,
            dialogAddValidateur:false,
            connected:false,
            showBrowserView:true,
            displaySkeleton:true
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("KAGESECUR_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwtAdmin",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.authorized !== true){
                    Swal.fire({
                        text: "Vous n'êtes pas autorisé à accéder à cette page. Veuillez vous rendre sur votre node.",
                        icon: 'info',
                        confirmButtonText: "Ok",
                    }).then(()=> {
                        this.props.navigate('/KageSecur/Accueil')
                    })
                }else{
                    global.emitter.emit("onRouteChangedKageSecur",{
                        user:"gestionnaire",
                        pseudo:jwtDecoded.pseudo
                    })
                    this.getListeValidateurs()
                }
            })
        })
    }

    handleOpenDialogAddValidateur(){
        this.setState({dialogAddValidateur:true})
    }

    handleClickValidateurs(e){
        e.preventDefault()
        const data= new FormData(e.target);
        Axios.post(url+"emailToNewApprobateur", {
            email:data.get('champsEmail'),
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            this.handleCloseDialog()
            Swal.fire({
                title: 'Succès!',
                text: "Un mail va être envoyé au futur signataire !",
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
        })
    }

    linkGestionnaireToValidateur(){
        const empreinte = jwtDecoded.empreinte
        Axios.post(url+"fromGtoV", {
            empreinte:empreinte,
        },{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if (response.data.resultat === "Modifié"){
                Toast.fire({
                    icon: "success",
                    title: "Vous avez bien été ajouté parmis les signataires."
                })
                let listPseudoValidateur = this.state.rows
                listPseudoValidateur.push({Identifiant : jwtDecoded.pseudo, Email : response.data.email})
                this.setState({rows:listPseudoValidateur})
            }else{
                Swal.fire({
                    title: "Erreur!",
                    text: response.data.resultat,
                    icon: "error",
                    confirmButtonText: "Ok"
                })
            }
        })
    }

    deleteValidateurs(e){
        e.preventDefault()
        let identifiant = e.currentTarget.name
        Swal.fire({
            title: 'Attention!',
            text: "Êtes-vous certain de vouloir supprimer cet signataire ?",
            icon: 'info',
            confirmButtonText: "Oui",
            showCancelButton:true,
            cancelButtonText:"Annuler",
            cancelButtonColor:"red"
        }).then((result)=>{
            if(result.isConfirmed){
                Axios.post(url+"deleteValidateur", {
                    Identifiant:identifiant,
                    empreinte:jwtDecoded.empreinte,
                },{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                    if(response.data.jwt){
                        sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                        this.setState({
                            jwt:response.data.jwt
                        },()=>{
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if(response.data.resultat === "non admin"){
                        this.handleCloseDialog()
                        Toast.fire({
                            icon: "error",
                            title: "Vous n'êtes pas autorisé à supprimer des signataires, pour ce faire veuillez contacter un administrateur."
                        })
                    }else{
                        let newValidateurList = []
                        response.data.newValidateurList.forEach(validateur => {
                            if(validateur.thisSrv === true){
                                newValidateurList.push({Identifiant : validateur.Identifiant, Email : validateur.Email})
                            }
                        })
                        this.setState({
                            rows:newValidateurList
                        }, ()=> {
                            this.handleCloseDialog()
                            Toast.fire({
                                icon: "success",
                                title: "Vous avez correctement supprimé un signataire."
                            })
                        })
                    }
                })
            }
        })
    }

    getListeValidateurs(){
        Axios.post(url+"getListeValidateurs",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            let listPseudoValidateur = []
            response.data.resultat.forEach(validateur => {
                if(validateur.thisSrv === true){
                    listPseudoValidateur.push({Identifiant : validateur.Identifiant, Email : validateur.Email})
                }
            })
            if(response.data.jwt){
                sessionStorage.setItem("KAGESECUR_jwt",response.data.jwt)
                this.setState({
                    jwt: response.data.jwt
                },()=>{
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            this.setState({
                rows:listPseudoValidateur,
                displaySkeleton:false
            })

        }).catch((err)=>{
            console.log(err)
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }
    handleCloseDialog(){
        this.setState({dialogAddValidateur:false, dialogConnexion:false})
    }

    render() { //Affichage sur la page
        return (
            <div style={{display:"flex" ,height:"100vh",width:"100%", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Dialog
                    open={this.state.dialogAddValidateur}
                    onClose={()=>{this.handleCloseDialog()}}
                    fullScreen={!this.state.showBrowserView}
                    style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                >
                    <DialogTitle>Inscrire un signataire :</DialogTitle>
                    <form onSubmit={(e)=>{this.handleClickValidateurs(e)}} style={{display:"flex", flexDirection:"column",alignItems:"center", justifyItems:"center"}}>
                        <DialogContent>
                            <div className={"inputHelpDiv"}>
                                <TextField name={"champsEmail"} type={"email"} className={this.state.showBrowserView&&"champsTexte"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}}  id="outlined-basic champsPrenom" required={true} label="Email du signataire" variant="outlined" />
                            </div>
                        </DialogContent>
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                            <Button style={{backgroundColor:"#52a8d9",color:"white", margin:5}} variant={"outlined"} type={"submit"}>Inscrire</Button>
                            {!this.state.showBrowserView&&(
                                <Button style={{backgroundColor:"#8c8c8c",color:"white", margin:5}} variant={"contained"} onClick={()=>{this.setState({dialogAddValidateur: false})}}>Retour</Button>
                            )}
                        </div>
                    </form>
                </Dialog>
                {this.state.showBrowserView ? (
                    <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", overflowY:"scroll"}}>
                        <img style={{textAlign:"center", height:100, position:"absolute", left:50,top:110}} src={require("../../assets/approbateursOrange.png")} alt="imageDocument"/>
                        <img style={{textAlign:"center", height:100, position:"absolute",right:50,bottom:20}} src={require("../../assets/listBlue.png")} alt="imageAuction"/>
                        <h1 style={{ marginBottom:60}}>Gérer <span style={{color:"#52a8d9"}}>les comptes </span> de vos <span style={{color:"#f18f29"}}>signataires</span></h1>
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column", width:"60%", padding:"3vw", paddingTop:"1vw", paddingBottom:"1vw",marginBottom:100 ,minWidth:500,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                            <Paper style={{margin:10, width:"92%", maxHeight:400, overflowY:"scroll"}}>
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(this.state.rows).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                        {columns.map((column) => {
                                                            return (
                                                                <TableCell key={column.id}>
                                                                    {column.id === "Action" ? (<Button name={row.Identifiant} onClick={(e)=>{this.deleteValidateurs(e)}}> <CloseIcon/> </Button>):(row[column.id])}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    {this.state.displaySkeleton && (
                                        <div style={{width:"100%"}}>
                                            <Skeleton style={{margin:20,marginTop:40}}/>
                                            <Skeleton style={{margin:20}} />
                                            <Skeleton style={{margin:20}} />
                                        </div>
                                    )}
                                </TableContainer>
                            </Paper>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <Button onClick={()=>{this.handleOpenDialogAddValidateur()}} variant={"contained"} style={{backgroundColor:"white"}}>Inscrire un signataire</Button>
                                <Button onClick={()=>{this.linkGestionnaireToValidateur()}} variant={"contained"} style={{backgroundColor:"white", marginTop:10}}>Devenir signataire avec mon compte gestionnaire</Button>
                            </div>
                        </div>
                    </div>
                ):(
                    <div style={{marginTop:90,height:"calc(100%-90px)",width:"100%",display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", overflowY:"scroll"}}>
                        <h2 style={{textAlign:"center",marginBottom:60}}>Gérer <span style={{color:"#52a8d9"}}>les comptes </span> de vos <span style={{color:"#f18f29"}}>signataires</span></h2>
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column", width:"95%",marginBottom:100 ,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                            <Paper style={{margin:10, width:"92%", maxHeight:400, overflowY:"scroll"}}>
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth, fontWeight:"bold"}}
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(this.state.rows).map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                        {columns.map((column) => {
                                                            return (
                                                                <TableCell key={column.id}>
                                                                    {column.id === "Action" ? (<Button name={row.Identifiant} onClick={(e)=>{this.deleteValidateurs(e)}}> <CloseIcon/> </Button>):(row[column.id])}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                    {this.state.displaySkeleton && (
                                        <div style={{width:"100%"}}>
                                            <Skeleton style={{margin:20,marginTop:40}}/>
                                            <Skeleton style={{margin:20}} />
                                            <Skeleton style={{margin:20}} />
                                        </div>
                                    )}
                                </TableContainer>
                            </Paper>
                            <div style={{display:"flex", flexDirection:"column"}}>
                                <Button onClick={()=>{this.handleOpenDialogAddValidateur()}} variant={"contained"} style={{backgroundColor:"white"}}>Inscrire un signataire</Button>
                                <Button onClick={()=>{this.linkGestionnaireToValidateur()}} variant={"contained"} style={{backgroundColor:"white", marginTop:10}}>Devenir signataire avec mon compte gestionnaire</Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>


        );
    }
}

export default withRouter(Validateurs)