import React, {Component} from 'react';
import {Backdrop, CircularProgress} from '@mui/material';
import Axios from "axios";
import {withRouter} from '../../../shared/NavigationUtils';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import config from "../../../json/config.json";
import {isBrowser} from "react-device-detect";
import jwt_decode from "jwt-decode";
import SharedDrawerEkocoOR from "./components/SharedDrawerEkocoOR";
import SharedFooterEkocoOR from "./components/SharedFooterEkocoOR";
import Swal from "sweetalert2";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded

class HomeOR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            openLoad:true
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        if(document.body.offsetWidth <= 950 || !isBrowser){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)

        let jwtURL = new URLSearchParams(window.location.search).get("jwt")
        if(jwtURL){
            sessionStorage.setItem("EKOCO_jwt", jwtURL)
        }
        let jwt = jwtURL ? jwtURL:sessionStorage.getItem("EKOCO_jwt")

        if(jwt){
            this.setState({
                jwt: jwt
            }, () => {
                jwtDecoded = jwt_decode(this.state.jwt)
                Axios.post(url + "verifyJwt", {}, {headers: {Authorization: "Bearer " + jwt}}).then(response => {
                    this.setState({openLoad:false})
                    if (response.data.jwt) {
                        sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                        this.setState({
                            jwt: response.data.jwt
                        }, () => {
                            jwtDecoded = jwt_decode(this.state.jwt)
                        })
                    }
                    if (response.data.resultat === true) {
                        this.setState({
                            pseudo: jwtDecoded.pseudo
                        }, () => {
                            this.getGlobalUserStats();
                        })
                    } else {
                        Swal.fire({
                            title: 'Erreur',
                            text: "Connexion impossible, veuillez relancer ce site depuis l'application Ekoco ou connectez-vous directement sur le site.",
                            icon: 'error',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#ec5ba1'
                        }).then(()=>{
                            sessionStorage.removeItem("EKOCO_jwt")
                            this.props.navigate("/Ekoco/OctobreRose/Connexion")
                        })
                    }
                })
            })
        }else{
            this.setState({openLoad:false})
            Swal.fire({
                title: 'Erreur',
                text: "Connexion impossible, veuillez relancer ce site depuis l'application Ekoco ou connectez-vous directement sur le site.",
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#ec5ba1'
            }).then(()=>{
                sessionStorage.removeItem("EKOCO_jwt")
                this.props.navigate("/Ekoco/OctobreRose/Connexion")
            })
        }
    }

    getGlobalUserStats(){
        Axios.post(url + "getGlobalUserStats", {}, {headers: {Authorization: "Bearer " + this.state.jwt}}).then(response => {
            if (response.data.jwt) {
                sessionStorage.setItem("EKOCO_jwt", response.data.jwt)
                this.setState({
                    jwt: response.data.jwt
                }, () => {
                    jwtDecoded = jwt_decode(this.state.jwt)
                })
            }
            if(response.data.resultat){
                this.setState({
                    distance:response.data.totalDistance,
                    duree:response.data.estimatedTime,
                    parcours:response.data.nbParcours,
                    donation:response.data.ORdonation
                })
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    displayContact(){
        this.setState({
            displayed:true,
        })
    }

    handleCloseDialog = () => {
        this.setState({
            displayed : false,
            displayed2 : false
        });
    };
    handleOpenDialog = () => {
        this.setState({
            displayed2 : true
        });
    };

    handleChange=(event) => { //handleChange permet de mettre a jour la valeur des inputs en temps réel, a chaque appuis d'une touche dans un input
        const value = event.target.value;
        this.setState({
            [event.target.name]:value
        });
    }

    handleValidation=()=>{ //Fonction servant a la vérification des données avant l'envoi du formulaire
        return true
    }

    render(){ //Affichage sur la page
        return (
            <div style={{display:"flex",height:'100%', width:'100%', flexDirection:"column"}}>
                <SharedDrawerEkocoOR/>

                {/*HEADER*/}
                <div style={{display:"flex", alignItems:"center", width:"100%",height:100, justifyContent:"flex-end"}}>
                    <p style={{color:'#b0a9a8', marginRight:5, fontSize:20, fontWeight:"bold", zIndex:2}}>{this.state.pseudo}</p>
                    <AccountCircleIcon style={{color:"#b0a9a8", marginRight:50}}/>
                </div>
                {/*DEBUT RENDER STATIQUE*/}
                <div style={{display:"flex", background: "linear-gradient(#ededee,#ffffff,#ffffff)", height:"80vh"}}>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute",left:50, top:"25vh"}}>
                            <img style={{width:350}} src={require("../assetsEkoco/telephoneFemme_rose.png")} alt={"Reporting"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", zIndex:1,position:"absolute", left:0, top:100}}>
                            <img style={{width:"80", objectFit:"contain"}} src={require("../assetsEkoco/topleft_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                    <div style={{marginTop:50,display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column",marginBottom:0,width:"100%", overflow:"hidden"}}>
                        <div style={{backgroundColor:"#ec5ba1", width:this.state.showBrowserView ? 500:"95%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", paddingBottom:30, paddingTop:30, borderRadius:10, zIndex:2, boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px"}}>
                            <p style={{color:"white",fontWeight:"bold", fontSize:30, margin:0, marginBottom:10, textAlign:"center", whiteSpace:"pre-line"}}>Bonjour {this.state.pseudo}</p>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex",flex:1, width:50}}>
                                    <img style={{width:40, height:40, margin:5}} src={require("../assetsEkoco/marker_darkblue.png")} alt="parcours"/>
                                </div>
                                <div style={{display:"flex",flex:5, flexDirection:"column"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold"}}>Parcours terminés</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                        <p style={{color:"#ec5ba1", fontWeight:"bold", fontSize:30, margin:0}}>{this.state.parcours}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex", flex:1}}>
                                    <img style={{width:50, height:50}} src={require("../assetsEkoco/DUREE_ACTIVITE_APPLI MOBILE.png")} alt="Temps"/>
                                </div>
                                <div style={{display:"flex", flex:5,flexDirection:"column"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold"}}>Durée d'activité</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                        <p style={{color:"#ec5ba1", fontWeight:"bold", fontSize:30, margin:0}}>{this.state.duree}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex",flex:1, width:50}}>
                                    <img style={{width:50, height:50}} src={require("../assetsEkoco/distance_darkblue.png")} alt="DISTANCE"/>
                                </div>
                                <div style={{display:"flex",flex:5, flexDirection:"column"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold"}}>Distance parcourue</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                        <p style={{color:"#ec5ba1", fontWeight:"bold", fontSize:30, margin:0}}>{this.state.distance}km</p>
                                    </div>
                                </div>
                            </div>

                            <div style={{backgroundColor:"white",height:100, borderRadius:5,width:"80%", display:"flex", marginBottom:10}}>
                                <div style={{display:"flex",flex:1, width:50}}>
                                    <img style={{width:50, height:50}} src={require("../assetsEkoco/donation_darkblue.png")} alt="donation"/>
                                </div>
                                <div style={{display:"flex", flex:5,flexDirection:"column"}}>
                                    <div style={{display:"flex",flex:1, height:50,}}>
                                        <p style={{color:"#b0a9a8", fontWeight:"bold"}}>Argent donné</p>
                                    </div>
                                    <div style={{display:"flex",flex:1, height:50, justifyContent:"flex-end", marginRight:5}}>
                                        <p style={{color:"#ec5ba1", fontWeight:"bold", fontSize:30, margin:0}}>{this.state.donation}€</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.showBrowserView ? (
                        <div style={{display:"flex",justifyContent:"center", alignItems:"center", zIndex:1,position:"absolute", right:50, top:"25vh"}}>
                            <img style={{width:350, objectFit:"contain"}} src={require("../assetsEkoco/homme_rose.png")} alt={"Statistiques"}/>
                        </div>
                    ):(
                        <div style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end" , zIndex:1,position:"absolute", right:0, bottom:0}}>
                            <img style={{width:"80%", objectFit:"contain"}} src={require("../assetsEkoco/bottomright_background_ekoco.png")} alt={"Statistiques"}/>
                        </div>
                    )}
                </div>
                {/*FIN RENDER STATIQUE*/}
                {/*FOOTER*/}
                <SharedFooterEkocoOR/>
                <Backdrop style={{zIndex:"9989",display:"flex", flexDirection:"column", color: '#ffffff'}} className={"backDropLoading"} open={this.state.openLoad}>
                    <h2 style={{color:"white"}}>Chargement en cours ...</h2>
                    <CircularProgress style={{zIndex:"9999"}} size={80} color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

export default withRouter(HomeOR);