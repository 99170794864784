import React, {Component} from 'react';
import {withRouter} from '../../shared/NavigationUtils';

import Axios from "axios";

import config from "../../json/config.json";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import {Backdrop, CircularProgress} from "@mui/material";

const sizeLimitFile = 10737418240 // 10GB

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const categories= [
    "Design","Développement","Marketing","Informatique et logiciels","Développement personnel","Business","Photographie","Musique","Divers"
]
const {baseUrl, suffix} = config;
const url = baseUrl + suffix

let jwtDecoded;

class PaiementStatutBTOB extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.setInitialStates(),
            showBrowserView:true
        };
    }

    setInitialStates(){
        return{
            showBackdrop:true,
            statut:"",
            price:0,
            boughtActions:null,
            nomEntreprise:"",
            nomVendeur:""
        }
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentDidMount() {
        let token = new URLSearchParams(window.location.search).get("token"),
            statut = new URLSearchParams(window.location.search).get("statut")
        if(token !== null) {
            this.setState({
                token,statut
            })
        }else{
            Swal.fire({
                title: 'Erreur!',
                text: "Aucun paiement n'a été trouvé, redirection vers le catalogue.",
                icon: 'error',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.props.navigate("/BtoBDesign/Catalogue")
            })
        }
        if(document.body.offsetWidth <= 950){
            this.setState({showBrowserView: false})
        }
        window.addEventListener('resize', this.resizePage)
        this.setState({
            jwt:sessionStorage.getItem("BTOBDESIGN_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedBtoBDesign", {
                        jwt: this.state.jwt,
                    })
                    this.finaliseInvestment()
                }else{
                    Swal.fire({
                        title: 'Veuillez vous reconnecter',
                        text: "Votre session a expiré, veuillez vous reconnecter.",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        sessionStorage.removeItem("BTOBDESIGN_jwt")
                        global.emitter.emit("onRouteChangedBtoBDesign", {
                            jwt: null,
                        })
                        window.location.replace("/BtoBDesign/Accueil")
                    })
                }
            })
        })
    }

    finaliseInvestment(){
        Axios.post(url+"finaliseConceptPayment",{resource:{id:this.state.token}},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("BTOBDESIGN_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                })
            }
            if (response.data.resultat === "done"){
                this.setState({nomConcept:response.data.title,price:parseFloat(response.data.price),showBackdrop:false})
            }else{
                this.setState({showBackdrop:false})
                Swal.fire({
                    title: 'Erreur!',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    this.props.navigate("/BtoBDesign/MesConcepts")
                })
            }
        })
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeOnlyNum(event){
        let onlyNums = event.target.value.replace(/[^0-9]/g, '');
        if (onlyNums < 0){
            onlyNums = 0
        }
        this.setState({
            [event.currentTarget.name] : onlyNums
        })
    }

    render() { //Affichage sur la page
        return (
            <div style={{height:"100%",width:"100%",display:"flex", flexDirection:"column",transform : "all .5s cubic-bezier(.25,.8,.25,1)", transition : "all .5s cubic-bezier(.25,.8,.25,1)"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.showBackdrop}
                >
                    <div className={"flexCenter"} style={{flexDirection:"column"}}>
                        <CircularProgress color="inherit" />
                        <h2 className={"loadingText"} style={{fontWeight:100}}>Chargement en cours </h2>
                    </div>
                </Backdrop>
                <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center" ,minHeight:"calc(100vh - 90px)",width:"100%",flexDirection:"column", marginTop:90}}>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center" ,width:"90%", minHeight:"95%", margin:50, marginLeft:100, marginRight:100,marginTop:!this.state.showBrowserView&&10, flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flex:1, width:"100%", flexDirection:"column"}}>
                                {this.state.showBackdrop !== true && (
                                    <div className={"divReceipt flexCenter"} style={{width:!this.state.showBrowserView&&"95%", padding:!this.state.showBrowserView&&"2%", marginTop:90}}>
                                        <h2 style={{margin:0, marginBottom:20, fontWeight:100, fontSize:34}} className={"flexCenter"}>Paiement {this.state.statut === "success" ? (
                                            <>
                                                Validé
                                                <div style={{marginLeft:25}} className="sa">
                                                    <div className="sa-success">
                                                        <div className="sa-success-tip"></div>
                                                        <div className="sa-success-long"></div>
                                                        <div className="sa-success-placeholder"></div>
                                                        <div className="sa-success-fix"></div>
                                                    </div>
                                                </div>
                                            </>
                                        ):(
                                            <>
                                                Échoué
                                                <div style={{marginLeft:25}} className="sa">
                                                    <div className="sa-error">
                                                        <div className="sa-error-x">
                                                            <div className="sa-error-left"></div>
                                                            <div className="sa-error-right"></div>
                                                        </div>
                                                        <div className="sa-error-placeholder"></div>
                                                        <div className="sa-error-fix"></div>
                                                    </div>
                                                </div>
                                            </>
                                        )}</h2>
                                        <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                            <div className={"flexCenter"} style={{width:'95%', justifyContent:"space-between"}}>
                                                <h2 style={{margin:0, fontWeight:100}}>x1</h2>
                                                <h2 style={{margin:0, fontWeight:100}}>{this.state.nomConcept}</h2>
                                            </div>

                                            <div style={{width:"95%", height:2, margin:30, marginBottom:20,borderBottom:"1px dashed #969696",}}/>

                                        </div>
                                        <div className={"flexCenter"} style={{justifyContent:"flex-end", width:"100%"}}>
                                            <h2 style={{fontSize:34, margin:0}}>Total : {this.state.price.toFixed(2)}€</h2>
                                        </div>
                                        <div className={"flexCenter"} style={{marginTop:20, width:"100%", flexDirection:"column"}}>
                                            {this.state.statut === "success" ? (
                                                <button style={{width:"100%"}} className={"divButtonBtoBDesign"} onClick={()=>{this.props.navigate("/BtoBDesign/MesConcepts")}}>Voir mes concepts</button>
                                            ):(
                                                <>
                                                    <p style={{fontWeight:100, textAlign:"justify", fontSize:15, marginBottom:0}}>Une erreur est survenue lors du paiement, vous n'avez pas été débité. Veuillez réessayer ultérieurement.</p>
                                                    <p style={{fontWeight:100, textAlign:"justify", fontSize:15, marginTop:0}}>Si le problème persiste, contactez nous au (+33)6 08 82 67 53 ou  <a href="https://btobdesign.art/contact/" target={"_blank"}>par mail</a>.</p>
                                                    <button style={{width:"100%"}} className={"divButtonBtoBDesign"} onClick={()=>{this.props.navigate("/BtoBDesign/Catalogue")}}>Voir le catalogue</button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PaiementStatutBTOB)