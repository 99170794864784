import Axios from "axios";
import React, {Component} from "react";
import {
    Breadcrumbs,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Menu,
    MenuItem,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
    ArrowDownward,
    ArrowUpward,
    AutoGraph,
    Check,
    CheckCircle,
    Close,
    CloudUpload,
    CreateNewFolder,
    Delete,
    Description,
    DoNotDisturb,
    DriveFolderUpload,
    Edit,
    FolderShared,
    FolderZip,
    Menu as MenuIcon,
    PermIdentity,
    Refresh,
    Verified,
    Visibility
} from "@mui/icons-material";
import GetAppIcon from "@mui/icons-material/GetApp";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import PdfIcon from '../../assets/PDFIcon.png';
import DescriptionIcon from "@mui/icons-material/Description";
import config from "../../json/config.json";
import Swal from "sweetalert2";
import {isMobile} from "react-device-detect";
import Dropzone from "react-dropzone";
import socketInstance from "../../Components/SocketClient";
import {createFilterOptions} from '@mui/material/Autocomplete';
import jwt_decode from "jwt-decode";
import {askPermToUpload, uploadChunk} from "./SharedUploadFunctions";
import {withRouter} from '../../shared/NavigationUtils';
import {uploadEnded} from "../KageSecur/SharedUploadFunctions";
import SharedFooterSK from "./components/SharedFooterSK";
import CryptoJS from "crypto-js";

const sizeLimitFile = 10737418240 // 10GB

const columnsListePreuveViaMail = [
    { id: 'identifiant', label: 'Identifiant', minWidth: 75 },
    { id: 'email', label: 'Email', minWidth: 150 },
    { id: 'Action', label: '', minWidth: 20 }
]

const {baseUrl, suffix} = config;
const url = baseUrl + suffix
const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


let socketIO, oldResearch = "";

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreCase: true,
    ignoreAccents: true,
    stringify: (option) => `${option.pseudo} ${option.email}`,
    trim: true,
});

let jwtDecoded = {}

class EspaceDossierSK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            archiFiles:[],
            toDisplay:[],
            recherche:'',
            rechercheDisplay:"",
            breadcrumbsDisplay:[],
            selectedFiles:[],
            clear:"none",
            searchFinished:true,
            downloadFinished:true,
            listNodes:[],
            previsuFile:null,
            dialogPrevisuOpen:false,
            displayDialogInfo:false,
            showBrowserView:true,
            emailProofClient:"",
            nomProofClient:"",
            passSearchAnyway:false,
            anchorEl: null,
            allValidateurs:[],
            validateursOthersNodes:[],
            messageNewSignature:"",
            displayCreateFolderLine:false,
            newFolderName:"",
            newFolderRename:"",
            ctrlPressed:false,
            xPressed:false,
            vPressed:false,
            fileSelectedIsFolder:false,
            displayMoveFileChoice:false,
            fileClipboardToMove:{},
            fileClipboardToRename:{},
            fileClipboardToShare:{},
            displayShareFolder:false,
            allClients:[],
            clientChosenForShare:null,
            contentOfSharedFolder:null,
            freeClient:false,
            displaySkeleton:true,
            recepteurProofEmail:"",
            recepteurProofName:"",
            displayDialogSendProof:false,
            sendPub:false,
            arrayBufferPiecesJointes:[],
            totalSizePiecesJointes:0,
            disableMenu:false,
            disableButtonMenu:false,
            progressDownloadFile:0,
            showSaveFilePickerCanceled:false,
            allClient:[],
            notifications:[],
            notReadNotif:0,
            showNotifDiv:false,
            lastDisplayOfNotif:0,
            clientChosenForSignataire:null,
            listRecepteurPreuveViaMail:[],
            phasePreuveViaMail:1,
            displayDialogUploadArchivage:false,
            reUploadingFile:false,
            uploadingFile:false,
            reUploadingSignatureFile:false,
            resultatReSend:"",
            resultatSignatureReSend:"",
            fileToResend:null,
            acceptedFileArchive:null,
            acceptedFile:[],
            dialogDemandeSignaturePhase:1,
            sortStatus:"nom-down",
            firstVisibleIndex:0,
            virtualListHeight:0,
            displayDialogAddFiles:false,
            processusDone:false,
            folderName:"",
            isAdmin:true,
            displayDialogChangePermFolder:null,
            previousFolderPerms:null
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    handleContextMenu = (e) => {
        e.preventDefault()
        const contextMenu = document.getElementById("menuRightClick")
        const {clientX : mouseX, clientY:mouseY} = e
        let trueX, trueY
        // e.view.innerHeight / innerWidth = taille de l'écran
        // contextMenu.offsetHeight / offsetWidth = taille du menu
        trueY = mouseY+contextMenu.offsetHeight > e.view.innerHeight ? mouseY-(mouseY+contextMenu.offsetHeight-e.view.innerHeight):mouseY
        trueX = mouseX+contextMenu.offsetWidth > e.view.innerWidth ? mouseX-(mouseX+contextMenu.offsetWidth-e.view.innerWidth):mouseX
        //On vérifie qu'on clique bien sur une ligne
        if(typeof e.target.className === "string" && e.target.className.includes("divClickDeselect")){
            this.state.selectedFiles.forEach(elt=>{
                this.setState({
                    ["selected"+elt.path]:false
                })
            })
            if(e.target.offsetParent !== contextMenu){
                contextMenu.style.transform = `scale(0)`
                contextMenu.style.visibility = `hidden`
            }
            this.setState({selectedFiles:[],fileSelectedIsFolder:false})
        }
        if(e.target.closest(".selectableLineTDB") !== null || e.target.id.includes("divScroll")){
            contextMenu.style.transition = `transform .3s cubic-bezier(.25,.8,.25,1);`
            contextMenu.style.top = `${trueY}px`
            contextMenu.style.left = `${trueX}px`
            contextMenu.style.transform = `scale(1)`
            contextMenu.style.visibility = `visible`
        }
    }

    handleAllClick = (e) => {
        const contextMenu = document.getElementById("menuRightClick")
        if(contextMenu){
            if(e.target.offsetParent !== contextMenu){
                contextMenu.style.transform = `scale(0)`
                contextMenu.style.visibility = `hidden`
            }
            if(typeof e.target.className === "string" && e.target.className.includes("divClickDeselect")){
                this.state.selectedFiles.forEach(elt=>{
                    this.setState({
                        ["selected"+elt.path]:false
                    })
                })
                this.setState({selectedFiles:[],fileSelectedIsFolder:false})
            }
        }

    }

    updateJwt(jwt){
        jwtDecoded = jwt_decode(jwt)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizePage)
        window.removeEventListener("click", this.handleAllClick)
        window.removeEventListener("contextmenu", this.handleContextMenu);
        window.removeEventListener("dragenter", this.handleWindowDragEnter);
    }

    componentDidMount() {
        let pf = new URLSearchParams(window.location.search).get("pf");
        let td = new URLSearchParams(window.location.search).get("td");
        if(!!td){
            if(!!pf){
                pf = pf.replaceAll("%d%","/")
            }
            this.setState({td,pf:pf ? pf:undefined},()=>{
                let self = this;
                let divScroll = document.getElementById("divScroll");
                // let allDisplayedFiles
                divScroll.addEventListener("scroll",function (scroll){
                    let firstVisibleIndex = parseInt(scroll.target.scrollTop / 54)
                    if(self.state.firstVisibleIndex !== firstVisibleIndex){
                        self.setState({firstVisibleIndex})
                    }
                })
                if(isMobile || document.body.offsetWidth <= 1150){
                    this.setState({showBrowserView:false})
                }
                window.addEventListener('resize', this.resizePage)
                window.addEventListener("contextmenu", this.handleContextMenu); //Right click
                window.addEventListener("click", this.handleAllClick); //Left click
                window.addEventListener("dragenter", this.handleWindowDragEnter); //Drag and drop


                //Gestion de la touche ctrl pour selectionner des fichiers
                let oldKey = null
                document.addEventListener('keydown', (event) => {
                    if(event.keyCode === 16){
                        if(oldKey !== event.keyCode){
                            this.setState({shiftPressed:true})
                            oldKey = event.keyCode
                        }
                    }
                    if(event.keyCode === 17){
                        if(oldKey !== event.keyCode){
                            this.setState({ctrlPressed:true})
                            oldKey = event.keyCode
                        }
                    }
                    if(event.keyCode === 88){
                        if(oldKey !== event.keyCode){
                            this.setState({xPressed:true})
                            oldKey = event.keyCode
                        }
                    }
                    if(event.keyCode === 86){
                        if(oldKey !== event.keyCode){
                            this.setState({vPressed:true})
                            oldKey = event.keyCode
                        }
                    }
                    if(this.state.ctrlPressed && this.state.xPressed){ //CTRL X
                        if(!(this.state.selectedFiles.length===0 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || !this.state.canUpload )){
                            this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles})
                            Toast.fire({
                                title: "Naviguez dans le dossier de votre choix (double clic) et cliquez sur 'Déplacer ici'.",
                                icon: 'info',
                            })
                        }
                    }
                    if(this.state.ctrlPressed && this.state.vPressed){ //CTRL V
                        if(this.state.displayMoveFileChoice){
                            this.moveFileOrFolder()
                        }
                    }
                })
                document.addEventListener('keyup', (event) => {
                    if(event.keyCode === 16){
                        oldKey=null
                        this.setState({shiftPressed:false})
                    }
                    if(event.keyCode === 17){
                        oldKey=null
                        this.setState({ctrlPressed:false})
                    }
                    if(event.keyCode === 88){
                        oldKey=null
                        this.setState({xPressed:false})
                    }
                    if(event.keyCode === 86){
                        oldKey=null
                        this.setState({vPressed:false})
                    }
                })

                this._emitConnectedSK = global.emitter.addListener("emitConnectedSK",()=>{ //On prévient que le client s'est connecté
                    this.setState({jwt:sessionStorage.getItem("SK_jwt")}, ()=>{
                        this.setState({
                            jwtDecoded : jwt_decode(this.state.jwt),
                            connected:true
                        })
                        global.emitter.emit("onRouteChangedSK", {
                            jwt: this.state.jwt,
                        })
                        this.connexion()
                    })
                })
                this.connexion()

            })
        }else{
            this.props.navigate("/Sekuroom/EspaceDossier?td="+this.state.td)
        }
    }

    connexion(){
        this.setState({
            jwt:sessionStorage.getItem("SK_jwt"),
        },()=>{
            jwtDecoded = this.state.jwt ? jwt_decode(this.state.jwt) : {}
            Axios.post(url+"verifyJwt",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
                if(response.data.jwt){
                    sessionStorage.setItem("SK_jwt",response.data.jwt)
                    this.setState({
                        jwt:response.data.jwt
                    },()=>{
                        jwtDecoded = jwt_decode(this.state.jwt)
                    })
                }
                if (response.data.resultat === true){
                    jwtDecoded = jwt_decode(this.state.jwt)
                    global.emitter.emit("onRouteChangedSK", {
                        jwt: this.state.jwt,
                    })
                    if(jwtDecoded.gestionnaire || jwtDecoded.admin){
                        this.setState({isGestionnaire:true})
                    }
                    this.setState({connected:true})
                    socketIO = socketInstance.connectionSocket(this.state.jwt)
                    this.getListeAllFiles()
                }else{
                    global.emitter.emit("askConnectionSK",{disableOutsideClick:true})
                }
            })
        })
    }

    sortListFiles(){
        return function(a, b) {
            if (a.type === "dossier" && b.type === "dossier"){ // 2 dossiers
                return (a.name).localeCompare(b.name)
            }else if (a.type === "dossier"){ // a = dossier et b = fichier
                return -1
            }else if (b.type === "dossier"){ // b = dossier et a = fichier
                return 1
            }else{ //2 fichiers
                let compareA = a.name.includes("##") ? a.name.split("##")[1]:a.name
                let compareB = b.name.includes("##") ? b.name.split("##")[1]:b.name
                return compareA.localeCompare(compareB)
            }
        }
    }

    getListeAllFiles (breadcrumbsNow){
        Axios.post(url+"getArchiSK",{idTokenFolder:this.state.td},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SK_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })
            }
            if(response.data.resultat === "done"){
                let breadcrumbsDisplay = [], toDisplay = []
                toDisplay = response.data.archi
                let base = null
                let rememberPathFound = false
                if(breadcrumbsNow){
                    breadcrumbsNow.forEach((bread, index)=>{
                        breadcrumbsNow[index] = bread.split("##").pop()
                    })
                    breadcrumbsNow = breadcrumbsNow.length===0 ? undefined:breadcrumbsNow.join("/")
                }
                let rememberPath = ""
                if(!!this.state.pf || !!breadcrumbsNow){
                    base= toDisplay
                    rememberPath = !!breadcrumbsNow ? breadcrumbsNow : this.state.pf
                    rememberPath.split("/").forEach(sub=>{
                        if(base[base.findIndex(elt => elt.name === sub)]){
                            rememberPathFound=true
                            base = base[base.findIndex(elt => elt.name === sub)].content
                        }
                    })
                    if(!rememberPathFound){
                        base = null
                        if(!!this.state.pf){
                            this.setState({pf:""})
                            this.props.navigate("/Sekuroom/EspaceDossier?td="+this.state.td, { replace: true })
                        }
                    }
                }
                this.setState({isAdmin:response.data.isAdmin,folderName:response.data.folderName,canUpload:response.data.canUpload,displaySkeleton:false,breadcrumbsDisplay:base ? breadcrumbsDisplay.concat(rememberPath.split("/")):breadcrumbsDisplay,archiFiles : response.data.archi,toDisplay:base ? base.sort(this.sortListFiles()):toDisplay.sort(this.sortListFiles())},()=>{
                    this.getOtherInfos()
                })
            }else{
                Swal.fire({
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.props.navigate("/Sekuroom/MesDossiers")
            }
        })
    }

    getOtherInfos(){
        Axios.post(url+"getFoldersSK",{},{headers:{Authorization:"Bearer " + this.state.jwt}}).then(response=>{
            if(response.data.resultat){
                let currentFolder
                response.data.createdFolders.forEach(folder=>{
                    if(folder.idToken === this.state.td){
                        currentFolder = folder
                    }
                })
                this.setState({isAdmin:currentFolder.gestionnaire, listUsersInFolder:currentFolder.listSharedUsers})
            }
        })
    }

    clickDownload(zip) {
        if(zip){
            let filePath;
            if (this.state.selectedFiles[0].path.includes("ciphered##")){
                filePath = this.state.selectedFiles[0].path
                filePath = filePath.replaceAll("ciphered##","").replaceAll("##", "pourcentdiese")
            }else{
                filePath = this.state.selectedFiles[0].path.split("/")
                filePath.splice(0,4)
                filePath[filePath.length-1] = filePath[filePath.length-1].replaceAll("##","pourcentdiese")
                filePath = filePath.join("/")
            }
            filePath = filePath.replaceAll(" ","%20").replaceAll("#","%23").replaceAll("%","%25").replaceAll("&","%26").replaceAll("/","%2F").replaceAll(":","%3A").replaceAll(";","%3B").replaceAll("=","%3D").replaceAll("?","%3F").replaceAll("@","%40").replaceAll("+","%2B")

            window.open(`${url}downloadFolderAsZipSK?tokenJwt=${this.state.jwt}&path=${filePath}&idTokenFolder=${this.state.td}`)
        }else{
            this.state.selectedFiles.forEach(elt=>{
                if(elt.type !== "folder"){
                    let filePath;
                    if (elt.path.includes("ciphered##")){
                        filePath = elt.path
                        filePath = filePath.replaceAll("ciphered##","").replaceAll("##", "pourcentdiese")
                    }else{
                        filePath = elt.path.split("/")
                        filePath.splice(0,4)
                        filePath = filePath.join("/")
                        filePath = filePath.replaceAll("##","pourcentdiese")
                    }

                    Toast.fire({
                        title: "Téléchargement démarré",
                        icon: 'info',
                    })
                    filePath = filePath.replaceAll(" ","%20").replaceAll("#","%23").replaceAll("%","%25").replaceAll("&","%26").replaceAll("/","%2F").replaceAll(":","%3A").replaceAll(";","%3B").replaceAll("=","%3D").replaceAll("?","%3F").replaceAll("@","%40").replaceAll("+","%2B")

                    window.open(url+"downloadFileSK?path="+filePath+"&tokenJwt="+this.state.jwt+"&idTokenFolder="+this.state.td+"&previsu=false","_blank")
                }
            })
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleChangeFolder(event){
        let newValue = event.currentTarget.value
        /*prevent replaceAll forbidden characters like #, { etc... with ""*/

        newValue = newValue.replaceAll("#","").replaceAll("{","").replaceAll("}","").replaceAll("\\","").replaceAll("^","").replaceAll("~","").replaceAll("[","").replaceAll("]","").replaceAll("|","").replaceAll("<","").replaceAll(">","").replaceAll("*","").replaceAll("%","").replaceAll("&","").replaceAll("/","").replaceAll(":","").replaceAll(";","").replaceAll("=","").replaceAll("?","").replaceAll("@","").replaceAll("+","").replaceAll("$","").replaceAll("€","").replaceAll("£","").replaceAll("¤","").replaceAll("µ","").replaceAll("§","").replaceAll("`","").replaceAll("°","").replaceAll("¨","").replaceAll("²","").replaceAll("�","")

        this.setState({
            [event.currentTarget.name] : newValue.replaceAll("/","")
        })
    }

    getContentTypeByExtension(extension) {
        extension= extension.toLowerCase()
        const contentType = {
            aac:"audio/aac",
            abw:"application/x-abiword",
            arc:"application/octet-stream",
            avi:"video/x-msvideo",
            azx:"application/vnd.amazon.ebook",
            bin:"application/octet-stream",
            bmp:"image/bmp",
            bz:"application/x-bzip",
            bz2:"application/x-bzip2",
            csh:"application/x-csh",
            css:"text/css",
            csv:"text/csv",
            doc:"application/msword",
            docx:"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            eot:"application/vnd.ms-fontobject",
            epub:"application/epub+zip",
            gif:"image/gif",
            htm:"text/html",
            html:"text/html",
            ico:"image/x-icon",
            ics:"text/calendar",
            jar:"application/java-archive",
            jpg:"image/jpeg",
            jpeg:"image/jpeg",
            js:"application/javascript",
            json:"application/json",
            mid:"audio/midi",
            midi:"audio/midi",
            mpeg:"video/mpeg",
            mpkg:"application/vnd.apple.installer+xml",
            odp:"application/vnd.oasis.opendocument.presentation",
            ods:"application/vnd.oasis.opendocument.spreadsheet",
            odt:"application/vnd.oasis.opendocument.text",
            oga:"audio/ogg",
            ogv:"video/ogg",
            ogx:"application/ogg",
            otf:"font/otf",
            png:"image/png",
            pdf:"application/pdf",
            ppt:"application/vnd.ms-powerpoint",
            pptx:"application/vnd.openxmlformats-officedocument.presentationml.presentation",
            rar:"application/x-rar-compressed",
            rtf:"application/rtf",
            mp4:"video/mp4",
            sh:"application/x-sh",
            svg:"image/svg+xml",
            swf:"application/x-shockwave-flash",
            tar:"application/x-tar",
            tif:"image/tiff",
            tiff:"image/tiff",
            ts:"application/typescript",
            ttf:"font/ttf",
            txt:"text/plain",
            vsd:"application/vnd.visio",
            wav:"audio/x-wav",
            weba:"audio/webm",
            webm:"video/webm",
            webp:"image/webp",
            woff:"font/woff",
            woff2:"font/woff2",
            xhtml:"application/xhtml+xml",
            xls:"application/vnd.ms-excel",
            xlsx:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            xml:"application/xml",
            xul:"application/vnd.mozilla.xul+xml",
            zip:"application/zip",
            "3gp":"video/3gpp",
            "3gp2":"video/3gpp2",
            "7z":"application/x-7z-compressed",
        }
        return contentType[extension] || 'application/octet-stream';
    }
    getIconForFileType = (fileName) => {
        const extension = fileName.split('.')[fileName.split('.').length - 1];
        const contentType = this.getContentTypeByExtension(extension).split('/')[0];

        if(extension === "zip"){
            return <FolderZip />;
        }else if(extension === "pdf"){
            return <img src={PdfIcon} style={{width:23}} alt="PDF Icon" />;
        }else{
            switch (contentType) {
                case 'image':
                    return <ImageIcon />;
                case 'video':
                    return <VideoIcon />;
                default:
                    return <DescriptionIcon />;
            }
        }
    }

    async openFileOrFolder(fileClicked){
        /*On vérifie que l'utilisateur ne soit pas en train de renommer le fichier pour lui permettre de double click pour sélectionner le nom du fichier*/
        if(this.state.fileClipboardToRename.path !== fileClicked.path){
            let mimeType = this.getContentTypeByExtension(fileClicked.name.split(".")[fileClicked.name.split(".").length-1])
            this.setState({fileSelectedIsFolder:false})
            if(fileClicked.type === "dossier"){
                let breadcrumbsDisplay = this.state.breadcrumbsDisplay.slice()
                breadcrumbsDisplay.push(fileClicked.fullname)
                if(!!this.state.pf){
                    this.setState({pf:undefined})
                    this.props.navigate("/Sekuroom/EspaceDossier?td="+this.state.td, { replace: true });
                }
                if(fileClicked !== this.state.fileClipboardToMove){
                    this.setState({
                        breadcrumbsDisplay:breadcrumbsDisplay,
                        toDisplay:fileClicked.content.sort(this.sortListFiles()),
                        rechercheDisplay:"",
                        sortStatus:"nom-down",
                    },()=>{
                        this.state.selectedFiles.forEach(elt=>{
                            this.setState({
                                ["selected"+elt.path]:false
                            })
                        })
                        this.setState({selectedFiles:[],virtualListHeight:this.state.toDisplay.length*54})
                        this.setState({disableButtonMenu:false},()=>{
                            if(fileClicked.locked){
                                this.handleLockedFolder(true,fileClicked)
                            }
                        })
                        let divScroll = document.getElementById("divScroll");
                        divScroll.scrollTo({top: 0})
                    })
                }else{
                    Toast.fire({
                        title: "Vous ne pouvez pas ouvrir un dossier en cours de déplacement.",
                        icon: 'warning',
                    })
                }
            }else if (fileClicked.type === "zip"){
                Toast.fire({
                    title: "L'ouverture des fichiers zip est indisponible pour le moment.",
                    icon: 'info',
                })
            }else if (mimeType.split("/")[0] ==="image" ||fileClicked.name.split(".")[fileClicked.name.split(".").length-1] ==="pdf" ||mimeType.split("/")[0] ==="video" || fileClicked.name.split(".")[fileClicked.name.split(".").length-1] ==="txt"){
                let filePath;
                if (fileClicked.path.includes("ciphered##")){
                    filePath = fileClicked.path
                    filePath = filePath.replaceAll("ciphered##","").replaceAll("##", "pourcentdiese")
                }else{
                    filePath = fileClicked.path.split("/")
                    filePath.splice(0,4)
                    filePath = filePath.join("/")
                    filePath = filePath.replaceAll("##","pourcentdiese")
                }
                filePath = filePath.replaceAll(" ","%20").replaceAll("#","%23").replaceAll("%","%25").replaceAll("&","%26").replaceAll("/","%2F").replaceAll(":","%3A").replaceAll(";","%3B").replaceAll("=","%3D").replaceAll("?","%3F").replaceAll("@","%40").replaceAll("+","%2B")

                window.open(url+"downloadFileSK?path="+filePath+"&tokenJwt="+this.state.jwt+"&idTokenFolder="+this.state.td+"&previsu=true","_blank")
            }else{
                Toast.fire({
                    title: "Pour visualiser ce fichier, cliquez sur le bouton télécharger en haut à droite.",
                    icon: 'info',
                })
            }
        }
    }

    selectFileOrFolder(fileClicked,click){
        // console.log(fileClicked)
        // console.log("previousFolderPerms",this.state.previousFolderPerms)
        // if(this.state.previousFolderPerms!==null && this.state.breadcrumbsDisplay.length!==0){
        //     console.log("index",this.state.breadcrumbsDisplay.length-1)
        //     console.log("previousFolderPerms2",this.state.previousFolderPerms[this.state.breadcrumbsDisplay.length-1])
        // }
        let selectedFiles = this.state.selectedFiles.slice() //Pour ne pas que les fichiers restent sélectionnés même après changement de fichier
        //Si on click droit sur un fichier alors qu'on en a déjà selectionné d'autre alors ça déselectionne rien sauf si on clique sur un fichier pas déjà inclu dans la liste
        if(!(selectedFiles.length>=1 && click ==="rightClick") || selectedFiles.findIndex(file=>file.name === fileClicked.name) === -1){
            if(this.state.ctrlPressed){ //ctrl pressé ?
                let indexOfClickedFile = selectedFiles.findIndex(file=>file.name === fileClicked.name)
                if(indexOfClickedFile !== -1){
                    selectedFiles.splice(indexOfClickedFile, 1)
                }else{
                    selectedFiles.push(fileClicked)
                }
                this.setState({
                    ["selected"+fileClicked.path]:this.state["selected" + fileClicked.path] !== true,
                })
            }else if (this.state.shiftPressed && selectedFiles.length===1){ //Click shift avec au moins un fichier déjà selectionné
                /*select all files in toDisplay from the previous selectefile to the fileClicked*/
                let indexOfClickedFile = this.state.toDisplay.findIndex(file=>file.name === fileClicked.name)
                let indexOfLastSelectedFile = this.state.toDisplay.findIndex(file=>file.name === selectedFiles[selectedFiles.length-1].name)
                if(indexOfClickedFile !== -1 && indexOfLastSelectedFile !== -1){
                    let filesToSelect = this.state.toDisplay.slice(Math.min(indexOfClickedFile,indexOfLastSelectedFile),Math.max(indexOfClickedFile,indexOfLastSelectedFile)+1)
                    filesToSelect.forEach(file=>{
                        this.setState({
                            ["selected"+file.path]:true,
                        })
                    })
                    selectedFiles = filesToSelect
                }else{
                    this.setState({
                        ["selected"+fileClicked.path]:this.state["selected" + fileClicked.path] !== true,
                    })
                    selectedFiles = [fileClicked]
                }
            } else{ //click normal
                if(this.state.selectedFiles.length ===0){
                    this.setState({
                        ["selected"+fileClicked.path]:this.state["selected" + fileClicked.path] !== true,
                    })
                }else{
                    this.state.selectedFiles.forEach(elt=>{
                        this.setState({
                            ["selected"+elt.path]:false
                        },()=>{
                            this.setState({
                                ["selected"+fileClicked.path]:this.state["selected" + fileClicked.path] !== true,
                            })
                        })
                    })
                }
                selectedFiles = [fileClicked]
            }
            let fileSelectedIsFolder
            if(selectedFiles.length === 1){
                fileSelectedIsFolder = selectedFiles[0].type === "dossier" || selectedFiles[0].type === "sharedFolder"
            }else if (selectedFiles.length > 1){
                /*vérifier si un des selectedFile est de type fichier*/
                fileSelectedIsFolder = selectedFiles.findIndex(file=>file.type === "dossier" || file.type === "sharedFolder") !== -1
            }
            this.setState({
                selectedFiles:selectedFiles,
                fileSelectedIsFolder
            })
        //     if(selectedFiles.length > 1 && selectedFiles.findIndex(file => file.type === "dossier") !== -1){ //A modifier si déplacement de plusieurs dossiers
        //         this.setState({disableMenu :!this.state.gestionnaire})
        //     }else{
        //         this.setState({disableMenu :false})
        //     }
        }
    }

    selectBreadcrumbs(breadcrumbs, content, breadcrumbsList){
        this.setState({sortStatus:"nom-down"})
        if(!!this.state.pf){
            this.setState({pf:undefined})
            this.props.navigate("/Sekuroom/EspaceDossier?td="+this.state.td, { replace: true });
        }
        for (let i = 0; i<content.length; i++){
            if ((content[i].fullname ? content[i].fullname:content[i].name) === breadcrumbsList[0]){
                content = content[i].content
            }
        }
        this.setState({disableButtonMenu:false})

        let indexBreadcrumb = this.state.breadcrumbsDisplay.indexOf(breadcrumbs)+1
        let previousFolderPerms

        if (breadcrumbsList[0] === breadcrumbs){
            let newBreadcrumbsDisplay = this.state.breadcrumbsDisplay.slice()
            newBreadcrumbsDisplay.length = indexBreadcrumb
            console.log("newBreadcrumbsDisplay",newBreadcrumbsDisplay)
            console.log("this.state.previousFolderPerms",this.state.previousFolderPerms)
            if(!!this.state.previousFolderPerms && (this.state.previousFolderPerms.length >= newBreadcrumbsDisplay.length)){
                previousFolderPerms = this.state.previousFolderPerms.slice()
                console.log("indexBreadcrumb",indexBreadcrumb-1)
                /*delete element from previousFolderPerms at index indexBreadcrumb*/
                console.log("previousFolderPerms.length-indexBreadcrumb",previousFolderPerms.length-indexBreadcrumb)
                previousFolderPerms.splice(indexBreadcrumb,previousFolderPerms.length-indexBreadcrumb)
                console.log("previousFolderPermsSelectBreadcrumbs",previousFolderPerms)
            }
            this.setState({
                toDisplay:content.sort(this.sortListFiles()),
                breadcrumbsDisplay:newBreadcrumbsDisplay,
                recherche:"",
                previousFolderPerms,
            },()=>{
                this.state.selectedFiles.forEach(elt=>{
                    this.setState({
                        ["selected"+elt.path]:false
                    })
                })
                this.setState({selectedFiles:[],virtualListHeight:this.state.toDisplay.length*54})
                let divScroll = document.getElementById("divScroll");
                divScroll.scrollTo({top: 0})
            })
        }else{
            if(breadcrumbs === undefined){
                this.setState({
                    toDisplay: this.state.archiFiles.sort(this.sortListFiles()),
                    breadcrumbsDisplay:[],
                    previousFolderPerms:null
                },()=>{
                    this.setState({virtualListHeight:this.state.toDisplay.length*54})
                    let divScroll = document.getElementById("divScroll");
                    divScroll.scrollTo({top: 0})
                })
            }else{
                breadcrumbsList.shift()
                this.selectBreadcrumbs(breadcrumbs, content, breadcrumbsList)
            }
        }
    }

    goToRoot(){
        this.setState({
            toDisplay:this.state.archiFiles.sort(this.sortListFiles()),
            breadcrumbsDisplay:[],
            rechercheDisplay:"",
            disableButtonMenu:false,
            sortStatus:"nom-down",
            previousFolderPerms:null,
        },()=>{

            this.state.selectedFiles.forEach(elt=>{
                this.setState({
                    ["selected"+elt.path]:false
                })
            })
            // if(!!this.state.pf){
            //     this.props.navigate("/Kagesecur/TableauDeBord");
            // }
            this.setState({selectedFiles:[],clear:"none",virtualListHeight:this.state.toDisplay.length*54})
            let divScroll = document.getElementById("divScroll");
            divScroll.scrollTo({top: 0})
        })
    }
    clearFilterAndGoRoot(){
        oldResearch = ""
        // if(!!this.state.pf){
        //     this.props.navigate("/Kagesecur/TableauDeBord");
        // }
        this.setState({
            rechercheDisplay:"",
            recherche:"",
            clear:"none",
            searchFinished:true,
            disableButtonMenu:false,
        },()=>{this.goToRoot()})
    }

    handleClickRecherche(e){
        e.preventDefault();
        this.setState({disableButtonMenu:true})
        if(this.state.recherche !== ""){
            if(oldResearch !== this.state.recherche.toLowerCase()||this.state.passSearchAnyway){
                if(this.state.searchFinished){
                    this.setState({
                        rechercheDisplay:this.state.recherche.toLowerCase(),
                        clear:"flex",
                        searchFinished:false,
                        toDisplay:[],
                    },()=>{
                        let pathRequested = this.state.breadcrumbsDisplay.join("/")||""
                        Axios.post(url+"searchInFolderSK",{idTokenFolder:this.state.td, path:pathRequested,searchedWord:this.state.rechercheDisplay},{headers:{Authorization:"Bearer "+ this.state.jwt}}).then((response)=>{
                            oldResearch = this.state.recherche.toLowerCase()
                            if(response.data.jwt){
                                sessionStorage.setItem("SK_jwt",response.data.jwt)
                                this.setState({
                                    jwt:response.data.jwt
                                },()=>{
                                    this.updateJwt(response.data.jwt)
                                })
                            }
                            this.state.selectedFiles.forEach(elt=>{
                                this.setState({
                                    ["selected"+elt.path]:false
                                })
                            })
                            this.setState({selectedFiles:[],toDisplay:response.data.resultat.sort(this.sortListFiles()),searchFinished:true},()=>{
                                this.setState({virtualListHeight:this.state.toDisplay.length*54})
                            })
                            setTimeout(()=>{this.setState({passSearchAnyway:true})},3000)//On attend 3s avant de laisser l'utilisateur effectuer la même recherche (sécurité anti spam)
                        }).catch(err=>{
                            console.log(err)
                        })
                    })
                }else{
                    Toast.fire({
                        title: "Veuillez patienter, une recherche est déjà en cours...",
                        icon: 'warning',
                    })
                }
            }else{
                Toast.fire({
                    title: "Veuillez patienter quelques instants avant de relancer une recherche.",
                    icon: 'warning',
                })
            }
        }
    }

    handleMenu(event){
        this.setState({ anchorEl: event.currentTarget });
    }

    handleCloseMenu (){
        this.setState({ anchorEl: null });
        const contextMenu = document.getElementById("menuRightClick")
        contextMenu.style.transform = `scale(0)`
        contextMenu.style.visibility = `hidden`
    };

    CreateFolder(){
        if (!this.state.canUpload){
            Swal.fire({
                text: "Vous n'avez pas la permission de créer un nouveau dossier.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.setState({
                    displayCreateFolderLine: false,
                    newFolderName: "",
                })
            })
        }else {
            let path = this.state.breadcrumbsDisplay.join("/")||""
            Axios.post(url+"createSubfolderSK", {
                subfolderName: this.state.newFolderName,
                idTokenFolder:this.state.td,
                path
            }, {headers: {Authorization: "Bearer "+ this.state.jwt}}).then((response) => {
                if (response.data.jwt) {
                    sessionStorage.setItem("SK_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    },()=>{
                        this.updateJwt(response.data.jwt)
                    })
                }
                if (response.data.resultat === "done") {
                    if (response.data.userFolder) { //Si le dossier client vient de se créer : on redemande au back l'archi
                        this.setState({displayCreateFolderLine: false, newFolderName: ""})
                        this.getListeAllFiles()
                    } else {
                        let objectFolder = {
                            type: "dossier",
                            name: this.state.newFolderName,
                            fullname: this.state.newFolderName,
                            locked: false,
                            content: [],
                            contentSize:0,
                            path: "./Q_file/Sekuroom/"+this.state.td+"##"+this.state.folderName+ "/" + ( path ? path + "/" : "") + this.state.newFolderName
                        }
                        let self = this
                        let archiFileTemp = this.state.archiFiles.slice()
                        let splittedPath = path.split("/")
                        if(splittedPath[0]===""){
                            splittedPath.splice(0,1)
                        }

                        function editArchi(archi, splittedPath, tableauIndex) {
                            if (splittedPath.length === 0) { //Si on est a la racine d'un utilisateur (seulement possible dans le cas d'un client)
                                archi.push(objectFolder)
                                self.setState({toDisplay: archi.sort(self.sortListFiles())})
                            } else {
                                archi.forEach((elt, index) => {
                                    if ((elt.fullname ? elt.fullname:elt.name) === splittedPath[0]) {
                                        tableauIndex.push(index)
                                        splittedPath.shift()
                                        if (splittedPath.length !== 0) {
                                            archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                        } else {
                                            archi[index].content.unshift(objectFolder)
                                        }
                                    }
                                })
                            }
                            return archi
                        }
                        Toast.fire({
                            title: "Nouveau dossier créé !",
                            icon: 'success',
                        })

                        this.setState({
                            displayCreateFolderLine: false,
                            newFolderName: "",
                            archiFiles: editArchi(archiFileTemp, splittedPath, [])
                        })
                    }
                }else{
                    Swal.fire({
                        text: "Une erreur est survenue lors de la création du dossier.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    }).then(()=>{
                        this.setState({
                            displayCreateFolderLine: false,
                            newFolderName: "",
                        })
                    })
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    RenameFolder(){
        if (!this.state.canUpload){
            Swal.fire({
                text: "Vous n'avez pas la permission de renommer des documents.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.setState({
                    displayRenameFolder: false,
                    fileClipboardToRename:{},
                    newFolderRename:""
                })
            })
        }else{
            let path = this.state.fileClipboardToRename.path.split("/")
            let oldName = (this.state.fileClipboardToRename.fullname ? this.state.fileClipboardToRename.fullname:this.state.fileClipboardToRename.name)
            path.pop()
            path.splice(0,4)
            path = path.join("/")

            let newFolderRename = this.state.newFolderRename

            let isFolder = this.state.fileClipboardToRename.type !== "fichier"

            if(!isFolder){
                if(newFolderRename !== ""){
                    newFolderRename = newFolderRename.split(".")
                    if(newFolderRename.length === 1){
                        newFolderRename.push("."+oldName.split("##")[1].split(".")[oldName.split("##")[1].split(".").length-1])
                        newFolderRename=newFolderRename.join("")
                    }else{
                        newFolderRename=newFolderRename.join(".")
                    }
                }
            }

            Axios.post(url+"renameFileSK", {
                newName: newFolderRename,
                oldName,
                idTokenFolder:this.state.td,
                isFolder,
                path: path,
            }, {headers: {Authorization: "Bearer "+ this.state.jwt}}).then((response) => {
                if (response.data.jwt) {
                    sessionStorage.setItem("SK_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    },()=>{
                        this.updateJwt(response.data.jwt)
                    })
                }
                if (response.data.resultat === "done") {
                    let self = this
                    let archiFileTemp = this.state.archiFiles.slice()
                    let splittedPath = path.split("/")
                    if(splittedPath[0]===""){
                        splittedPath.splice(0,1)
                    }
                    function editArchi(archi, splittedPath, tableauIndex) {
                        if (splittedPath.length === 0) { //Si on est à la racine d'un utilisateur (seulement possible dans le cas d'un client)
                            //Dans ce cas on va directement chercher à la racine le fichier à modifier, donc on findIndex sur archi et on modifie
                            //Cette fonction est identique dans le fonctionnement à celle du else
                            let i=archi.findIndex(elt => (elt.fullname ? elt.fullname:elt.name) === oldName)
                            if(isFolder){
                                archi[i].name = response.data.newName.split("##").pop()
                                archi[i].fullname = response.data.newName
                            }else{
                                archi[i].name = response.data.newName
                            }
                            /*replace the last element of archi[index].content[i].path.split("/") by response.data.entityName*/
                            let pathSplitted = archi[i].path.split("/")
                            pathSplitted.splice(-1)
                            pathSplitted.push(response.data.newName)
                            archi[i].path = pathSplitted.join("/")

                            if(archi[i].infos){
                                archi[i].infos.fileName = response.data.newName
                            }
                            if(response.data.folderContent){
                                archi[i].content = response.data.folderContent
                            }
                            self.setState({toDisplay: archi.sort(self.sortListFiles())})
                        } else {
                            archi.forEach((elt, index) => {
                                if ((elt.fullname ? elt.fullname:elt.name) === splittedPath[0]) {
                                    tableauIndex.push(index)
                                    splittedPath.shift()
                                    if (splittedPath.length !== 0) {
                                        archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                    } else {
                                        let i=archi[index].content.findIndex(elt => (elt.fullname ? elt.fullname:elt.name) === oldName)
                                        if(isFolder){
                                            archi[index].content[i].name = response.data.newName.split("##").pop()
                                            archi[index].content[i].fullname = response.data.newName
                                        }else{
                                            archi[index].content[i].name = response.data.newName
                                        }
                                        /*replace the last element of archi[index].content[i].path.split("/") by response.data.entityName*/
                                        let pathSplitted = archi[index].content[i].path.split("/")
                                        pathSplitted.splice(-1)
                                        pathSplitted.push(response.data.newName)
                                        archi[index].content[i].path = pathSplitted.join("/")

                                        if(archi[index].content[i].infos){
                                            archi[index].content[i].infos.fileName = response.data.newName
                                        }
                                        if(response.data.folderContent){
                                            archi[index].content[i].content = response.data.folderContent
                                        }
                                    }
                                }
                            })
                        }
                        return archi
                    }
                    Toast.fire({
                        title: "Dossier renommé !",
                        icon: 'success',
                    })

                    this.setState({
                        displayRenameFolder: false,
                        newFolderRename: "",
                        archiFiles: editArchi(archiFileTemp, splittedPath, []),
                        fileClipboardToRename: {},
                    })
                }else{
                    Toast.fire({
                        title: response.data.resultat,
                        icon: 'warning',
                    })
                }
            }).catch(err => {
                console.log(err)
                Swal.fire({
                    title:"Erreur",
                    text: "Une erreur est survenue lors du renommage du dossier.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
                this.setState({fileClipboardToRename: {}})
            })
        }
    }

    deleteFolder(){
        let path = this.state.breadcrumbsDisplay.join("/")||""
        path += ("/"+ (this.state.selectedFiles[0].fullname ? this.state.selectedFiles[0].fullname:this.state.selectedFiles[0].name))
        if (!this.state.canUpload){
            Swal.fire({
                text: "Vous n'avez pas la permission de supprimer un dossier.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else {
            let self = this
            function getContent(archi, splittedPath, content) {
                if (splittedPath.length === 0) { //Si on est à la racine d'un utilisateur (seulement possible dans le cas d'un client)
                    content = archi[archi.findIndex(elt => elt.path === self.state.selectedFiles[0].path)]
                } else {
                    archi.forEach((elt, index) => {
                        if ((elt.fullname ? elt.fullname:elt.name) === splittedPath[0]) {
                            splittedPath.shift()
                            if (splittedPath.length !== 0) {
                                content = getContent(elt.content, splittedPath, content)
                            } else {
                                content = archi[index]
                            }
                        }
                    })
                }
                return content
            }

            if (this.state.selectedFiles.length === 1 && this.state.selectedFiles[0].type === "dossier") {
                let splittedPath = path.split("/")
                if(splittedPath[0]===""){
                    splittedPath.splice(0,1)
                }
                let fileToDelete = getContent(this.state.archiFiles.slice(), splittedPath, [])
                if (fileToDelete.content.length !== 0) {
                    Swal.fire({
                        text: "Vous ne pouvez pas supprimer un dossier contant un ou plusieurs fichiers.",
                        icon: 'warning',
                        confirmButtonText: "Ok",
                    })
                } else {
                    Axios.post(url+"deleteSubfolderSK", {
                        path,
                        idTokenFolder:this.state.td
                    }, {headers: {Authorization: "Bearer "+this.state.jwt}}).then((response) => {
                        if (response.data.jwt) {
                            sessionStorage.setItem("SK_jwt", response.data.jwt)
                            this.setState({
                                jwt: response.data.jwt
                            },()=>{
                                this.updateJwt(response.data.jwt)
                            })
                        }
                        if (response.data.resultat === "done") {
                            let archiFileTemp = this.state.archiFiles.slice()
                            let splittedPath = path.split("/")
                            splittedPath.pop()
                            if(splittedPath[0]===""){
                                splittedPath.splice(0,1)
                            }
                            function editArchi(archi, splittedPath, tableauIndex) {
                                if (splittedPath.length === 0) { //Si on est a la racine d'un utilisateur (seulement possible dans le cas d'un client)
                                    archi.splice(archi.findIndex(elt => elt.path === self.state.selectedFiles[0].path), 1)
                                    self.setState({toDisplay: archi.sort(self.sortListFiles())})
                                } else {
                                    archi.forEach((elt, index) => {
                                        if ((elt.fullname ? elt.fullname:elt.name) === splittedPath[0]) {
                                            tableauIndex.push(index)
                                            splittedPath.shift()
                                            if (splittedPath.length !== 0) {
                                                archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                            } else {
                                                archi[index].content.splice(archi[index].content.findIndex(elt => elt.name === fileToDelete.name), 1)
                                                self.setState({toDisplay: archi[index].content.sort(self.sortListFiles())})
                                            }
                                        }
                                    })
                                }
                                return archi
                            }

                            this.state.selectedFiles.forEach((elt) => {
                                this.setState({
                                    ["selected" + elt.path]: false
                                })
                            })
                            this.setState({
                                selectedFiles: [],
                                fileSelectedIsFolder: false,
                                archiFiles: editArchi(archiFileTemp, splittedPath, [])
                            })
                        }else{
                            Swal.fire({
                                title:"Erreur",
                                text: "Une erreur est survenue lors de la suppression du dossier.",
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            }
        }
    }

    moveFileOrFolder(){
        if (!this.state.canUpload){
            Swal.fire({
                text: "Vous n'avez pas la permission de déplacer un élément de la cette dataroom.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            }).then(()=>{
                this.setState({
                    displayMoveFileChoice: false
                })
            })
        }else {
            let path = this.state.breadcrumbsDisplay.join("/")||""
            let filesToMove = []
            this.setState({movingFile:true})
            this.state.fileClipboardToMove.forEach(file=>{
                filesToMove.push(file.name)
            })
            let oldPath = this.state.fileClipboardToMove[0].path.split("/").slice(4)
            oldPath.pop()
            oldPath = oldPath.join("/")

            Axios.post(url+"moveFileSK", {
                oldPath,
                newPath:path,
                idTokenFolder: this.state.td,
                filesToMove,
            }, {headers: {Authorization: "Bearer " + this.state.jwt}}).then((response) => {
                this.setState({movingFile:false})
                if (response.data.jwt) {
                    sessionStorage.setItem("SK_jwt", response.data.jwt)
                    this.setState({
                        jwt: response.data.jwt
                    },()=>{
                        this.updateJwt(response.data.jwt)
                    })
                }
                if (response.data.resultat === "done") {
                    let self = this
                    function updateArchi(archi, splittedPath, content) {
                        if (splittedPath.length === 0) { //Si on est a la racine d'un utilisateur (seulement possible dans le cas d'un client)
                            content = archi
                        } else {
                            archi.forEach((elt, index) => {
                                if ((elt.fullname ? elt.fullname:elt.name) === splittedPath[0]) {
                                    splittedPath.shift()
                                    if (splittedPath.length !== 0) {
                                        content = updateArchi(elt.content, splittedPath, content)
                                    } else {
                                        content = archi[index]
                                    }
                                }
                            })
                        }
                        return !!content.content ? content.content : content
                    }

                    let splittedPath = path.split("/")
                    if(splittedPath[0]===""){
                        splittedPath.splice(0,1)
                    }
                    this.setState({
                        displayMoveFileChoice: false,
                        archiFiles: response.data.archi
                    }, () => {
                        this.setState({
                            toDisplay: updateArchi(response.data.archi, splittedPath, []).sort(self.sortListFiles())
                        })
                    })
                }else{
                    Swal.fire({
                        title:"Erreur",
                        text: "Une erreur est survenue lors du déplacement du fichier.",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                }
            }).catch(err => {
                this.setState({movingFile:false})
                console.log(err)
            })
        }
    }

    changeSortStatus(toChange){
        let newValueSort = toChange
        let sortStatusTemp = this.state.sortStatus.split("-")
        if(sortStatusTemp[0]===toChange){
            newValueSort += sortStatusTemp[1]==="down" ? "-up":"-down"
        }else{
            newValueSort +="-down"
        }
        this.setState({sortStatus:newValueSort})
        let toDisplayTemp = this.state.toDisplay.slice()
        if(toChange === "date"){
            toDisplayTemp.sort(compareDate)
            function compareDate( a, b ) {
                let compareA = a.type==="fichier" ? parseInt(a.name.substring(0,13)):0
                let compareB = b.type==="fichier" ? parseInt(b.name.substring(0,13)):0
                if(newValueSort.split("-")[1] === "up"){
                    return compareB - compareA
                }else{
                    return compareA - compareB
                }
            }
            this.setState({toDisplay:toDisplayTemp})
        }
        if(toChange === "nom"){
            toDisplayTemp.sort(compareAlphabetical)
            function compareAlphabetical( a, b ) {
                //On sépare l'id token du nom si besoin
                let compareA = a.name.includes("##") ? a.name.split("##")[1]:a.name
                let compareB = b.name.includes("##") ? b.name.split("##")[1]:b.name
                if(newValueSort.split("-")[1] === "up"){
                    return compareB.localeCompare(compareA)
                }else{
                    return compareA.localeCompare(compareB)
                }
            }
            this.setState({toDisplay:toDisplayTemp})
        }
        if(toChange === "poids"){
            toDisplayTemp.sort(compareSize)
            function compareSize( a, b ) {
                //non-archivé = 0 | dossier = -1
                let compareA = a.type==="fichier" ? (a.size ? a.size:0):-1
                let compareB = b.type==="fichier" ? (b.size ? b.size:0):-1
                if(newValueSort.split("-")[1] === "up"){
                    return (compareB - compareA)
                }else{
                    return (compareA - compareB)
                }
            }
            this.setState({toDisplay:toDisplayTemp})
        }
    }

    displayFileCreation(){
        this.setState({displayCreateFolderLine:true})
        let divScroll = document.getElementById("divScroll");
        divScroll.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    handleWindowDragEnter = (e) => {
        console.log("isDragging")
        if (!this.state.isDragging) {
            this.setState({ isDragging: true });
        }
    };

    handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.isDragging) {
            this.setState({ isDragging: false });
        }
    }

    verifyFileName(fileName){
        let canAppend = true
        this.state.acceptedFile.forEach(elt => {
            if (elt.name === fileName){
                canAppend = false
            }
        })
        return canAppend
    }

    processDroppedFiles(acceptedFiles) {
        let acceptedFilesArray = []
        if(this.state.displayDialogVerifFile && acceptedFiles.length > 1){
            Toast.fire({
                icon: 'error',
                title: 'Vous ne pouvez pas vérifier plusieurs fichiers simultanément.',
                target:"#dialogUploadNewFile"
            })
            this.setState({isDragging: false})
        }else{
            acceptedFiles.forEach((file)=>{
                if(file.size >= sizeLimitFile){
                    Toast.fire({
                        icon: 'error',
                        title: 'Le fichier est trop lourd (>10GB) !',
                        target:"#dialogUploadNewFile"
                    })
                }else if(file.name.includes("##")){
                    Toast.fire({
                        icon: 'error',
                        title: 'Le nom du fichier ne doit pas comporter "##" !',
                        target:"#dialogUploadNewFile"
                    })
                }else if (file.name.includes(".zip")){
                    Toast.fire({
                        icon: 'error',
                        title: "L'enregistrement de zip n'est pas autorisé.",
                        target:"#dialogUploadNewFile"
                    })
                }else if (this.verifyFileName(file.name)){
                    acceptedFilesArray.push(file)
                    if(!this.state.displayDialogVerifFile){
                        this.setState({displayDialogAddFiles:true})
                        Toast.fire({
                            icon: 'success',
                            title: "Fichier(s) correctement déposé(s), prêt à l'enregistrement.",
                            target:"#dialogUploadNewFile"
                        })
                    }
                }
            })
            let finalAcceptedFiles = [...this.state.acceptedFile.slice(),...acceptedFilesArray]
            this.setState({acceptedFile:finalAcceptedFiles, isDragging: false},()=>{
                if(this.state.displayDialogVerifFile){
                    console.log("finalAcceptedFiles",finalAcceptedFiles)
                    let stream = finalAcceptedFiles[0].stream()
                    let re = stream.getReader()
                    let SHA = CryptoJS.algo.SHA256.create()
                    let self = this
                    new ReadableStream({
                        start(controller) {
                            function push() {
                                re.read().then( ({done, value}) => {
                                    if (done) {
                                        const finalHash = SHA.finalize().toString()
                                        console.log("finalHash",finalHash)
                                        self.getFileStats(finalAcceptedFiles[0],finalHash)
                                        controller.close();
                                        return;
                                    }
                                    let wordBuffer = CryptoJS.lib.WordArray.create(value);
                                    SHA.update(wordBuffer);
                                    setTimeout(()=>{
                                        push();
                                    },10)
                                })
                            }
                            push();
                        }
                    });
                }
            })
        }
    }

    sendEmpreinte(){
        let self = this
        let divScroll = document.getElementById("divScroll")
        /*scroll to the top of this div*/
        divScroll.scrollTop = 0
        self.setState({uploadingFile:true,cancelUpload:false,totalFileDone:0})
        let updateJWT = true /*On évite de surcharger l'update de jwt*/

        let acceptedFiles = self.state.acceptedFile.slice()
        let displayProblemToast=false
        let userDisconnected = false
        let simultaneousUpload = []
        acceptedFiles.forEach((file,index) => {
            self.setState({["progressUpload"+file.name]:0, ["packetDebit" + file.name]:0, ["fileSent"+file.name]:"inProgress", ["cancel"+file.name]:false}, function startUploadProcess(){
                let path = this.state.breadcrumbsDisplay.length > 0 ? this.state.breadcrumbsDisplay.join("/") : ""
                const data = {
                    idTokenFolder:self.state.td,
                    path:path,
                    fileName:file.name,
                    bearer:self.state.jwt
                }
                let codeUpload
                /*Système de gestion de l'upload "synchrone"*/
                if(simultaneousUpload.findIndex(value => value === index) === -1 && simultaneousUpload.length <= 5 && !self.state.cancelUpload){
                    simultaneousUpload.push(index)
                }
                if(simultaneousUpload.findIndex(value => value === index) !== -1){
                    askPermToUpload(socketIO,"askPermToUploadSK",data).then(response => {
                        if(response.isAuthorized){
                            codeUpload = response.codeUpload
                            let dataFile = {
                                empreinte:jwtDecoded.empreinte,
                                fileName: file.name,
                                node:baseUrl.split("/")[2],
                                savedPath:codeUpload,
                                idToken:response.idToken
                            }
                            let stream = file.stream()
                            let re = stream.getReader()
                            // let SHA = CryptoJS.algo.SHA256.create()
                            let estimatedTime, totalPacketTimesDuration =0, packetTimeDuration = 0, totalSizeAlreadySent = 0
                            let count = 0
                            let initializeHandles = true
                            let handleErrorSocket,handleConnectSocket,handleDisconnectSocket

                            new ReadableStream({
                                start(controller) {

                                    if(initializeHandles){
                                        initializeHandles = false
                                        handleErrorSocket = (error) => {
                                            console.error("Socket error:", error);
                                        }

                                        handleConnectSocket = () => {
                                            // Ne log que quand l'user s'est reconnecté puisque ce listener n'est activé que ici
                                            console.log("Reconnected to socket");
                                            Toast.fire({
                                                title: 'Reconnexion au serveur.',
                                                icon: 'success',
                                            })
                                            if(userDisconnected){
                                                userDisconnected = false
                                                const data = {
                                                    bearer:self.state.jwt,
                                                    idTokenFolder:self.state.td,
                                                    codeUpload,
                                                }
                                                askPermToUpload(socketIO,"askPermToUploadSK", data).then(response => {
                                                    if (response.isAuthorized){
                                                        //Vider le tableau chunksToSend avant de reprendre la lecture

                                                        function sendMissingChunks(){
                                                            return new Promise(async resolve => {
                                                                if (chunksToSend.length === 0){
                                                                    resolve()
                                                                }else{
                                                                    const data = {
                                                                        buffer:chunksToSend[0],
                                                                        status:"upload",
                                                                        bearer:self.state.jwt
                                                                    }
                                                                    uploadChunk(socketIO, "sendChunkSK##"+codeUpload, data).then(responseSC => {
                                                                        chunksToSend.shift()
                                                                        resolve(sendMissingChunks())
                                                                    })
                                                                }
                                                            })
                                                        }
                                                        sendMissingChunks().then(()=>{
                                                            push()
                                                        })
                                                    }
                                                })
                                            }
                                        }

                                        handleDisconnectSocket = (reason) => {
                                            console.log("Disconnected from the server:", reason);
                                            userDisconnected = true
                                            self.setState({userDisconnected:true})
                                            Toast.fire({
                                                title: 'Problème réseau, reconnexion dès que possible.',
                                                icon: 'error',
                                            })
                                        }

                                        socketIO.on("error", handleErrorSocket);
                                        socketIO.on("connect",handleConnectSocket);
                                        socketIO.on("disconnect",handleDisconnectSocket);
                                    }

                                    let chunksToSend = []

                                    function push() {
                                        const packetStartTime = Date.now()
                                        re.read().then( ({done, value}) => {
                                            chunksToSend.push(value)
                                            //Lecture du fichier et appelle de la bonne fonction
                                            if (done){
                                                endOfUpload()
                                            }else{
                                                processUpload()
                                            }

                                            function processUpload(){
                                                const data = {
                                                    buffer:value,
                                                    status:"upload",
                                                    bearer:self.state.jwt
                                                }

                                                uploadChunk(socketIO, "sendChunkSK##"+codeUpload, data).then(responseSC => {
                                                    count++
                                                    if(response.jwt){
                                                        sessionStorage.setItem("SK_jwt",response.jwt)
                                                        self.setState({
                                                            jwt:response.jwt
                                                        },()=>{
                                                            jwtDecoded = jwt_decode(self.state.jwt)
                                                        })
                                                    }
                                                    if(responseSC.isAuthorized){
                                                        chunksToSend.splice(chunksToSend.indexOf(value),1)

                                                        /*total of bytes sent for now*/
                                                        totalSizeAlreadySent += value.length;
                                                        // let wordBuffer = CryptoJS.lib.WordArray.create(value);
                                                        // SHA.update(wordBuffer);

                                                        /*The amount of time it took to send this chunk (in s)*/
                                                        const packetTime = (Date.now() - packetStartTime) / 1000;
                                                        /*débit d'upload en Mbytes/s*/
                                                        let packetDebit = ((value.length / packetTime) / (1024 * 1024))
                                                        /*the estimated time of the upload in s)*/
                                                        const estimatedTime = Math.floor(((file.size - totalSizeAlreadySent) / (1024 * 1024)) / packetDebit);

                                                        const progressUploadEnCours = Math.round((totalSizeAlreadySent / file.size) * 100);
                                                        if (estimatedTime !== self.state["uploadEstimatedTime" + file.name]) {
                                                            self.setState({
                                                                ["uploadEstimatedTime" + file.name]: estimatedTime
                                                            });
                                                        }
                                                        if (packetDebit !== self.state["packetDebit" + file.name]) {
                                                            self.setState({
                                                                ["packetDebit" + file.name]: packetDebit
                                                            });
                                                        }
                                                        if (progressUploadEnCours !== self.state["progressUpload" + file.name]) {
                                                            self.setState({
                                                                ["progressUpload" + file.name]: progressUploadEnCours
                                                            });
                                                        }

                                                        if (!self.state["cancel"+file.name] && !self.state.cancelUpload){
                                                            push();
                                                        }else{
                                                            socketIO.off("error",handleErrorSocket)
                                                            socketIO.off("disconnect",handleDisconnectSocket)
                                                            socketIO.off("connect",handleConnectSocket)
                                                            simultaneousUpload.splice(simultaneousUpload.indexOf(index),1)
                                                            let divScroll = document.getElementById("divScroll")
                                                            divScroll.scrollTop = 0
                                                            socketIO.emit("sendChunkSK##"+codeUpload, {status:"cancel", bearer:self.state.jwt}, () => {
                                                                if(response.jwt){
                                                                    sessionStorage.setItem("SK_jwt",response.jwt)
                                                                    self.setState({
                                                                        jwt:response.jwt
                                                                    },()=>{
                                                                        jwtDecoded = jwt_decode(self.state.jwt)
                                                                    })
                                                                }
                                                                Toast.fire({
                                                                    icon: 'info',
                                                                    title: 'Upload annulé !'
                                                                })
                                                            })
                                                        }
                                                    }else{
                                                        Swal.fire({
                                                            title: 'Erreur!',
                                                            text: "Vous n'êtes pas autorisé à envoyer un fichier.",
                                                            icon: 'error',
                                                            confirmButtonText: 'Ok'
                                                        })
                                                    }
                                                })

                                            }

                                            function endOfUpload(){
                                                const data = {
                                                    status:"ended", bearer:self.state.jwt
                                                }
                                                /*remove codeUpload from simultaneousUpload*/

                                                simultaneousUpload.splice(simultaneousUpload.indexOf(index),1)
                                                socketIO.off("error",handleErrorSocket)
                                                socketIO.off("disconnect",handleDisconnectSocket)
                                                socketIO.off("connect",handleConnectSocket)

                                                uploadEnded(socketIO, "sendChunkSK##"+codeUpload, data).then(response => {
                                                    if(response.jwt){
                                                        sessionStorage.setItem("SK_jwt",response.jwt)
                                                        self.setState({
                                                            jwt:response.jwt
                                                        },()=>{
                                                            jwtDecoded = jwt_decode(self.state.jwt)
                                                        })
                                                    }

                                                    const data = {
                                                        allDatas: [dataFile],
                                                        idTokenFolder:self.state.td,
                                                        empreinte:jwtDecoded.empreinte,
                                                    }

                                                    self.setState({
                                                        ["progressUpload"+file.name]:100,
                                                        ["uploadEstimatedTime"+file.name]:0,
                                                        ["packetDebit"+file.name]:0,
                                                    })
                                                    sendAxios(data)
                                                })
                                            }

                                            function sendAxios(data){
                                                const acceptedFileClone = self.state.acceptedFile.slice();
                                                Axios.post(url + "addFileInFolderSK", data, {headers: {Authorization: "Bearer " + self.state.jwt}}).catch((err) => {
                                                    console.log(err)
                                                }).then((response) => {
                                                    self.setState({
                                                        acceptedFile:acceptedFileClone,
                                                        ["fileSent"+file.name]:response.data.resultat[file.name],
                                                    },()=>{
                                                        let countAF = 0

                                                        acceptedFileClone.forEach((elt,index)=>{
                                                            console.log(self.state["fileSent" + elt.name])
                                                            if(self.state["fileSent" + elt.name] !== "inProgress"){
                                                                countAF++
                                                            }
                                                            if (self.state["fileSent" + elt.name] === "Ce document est déjà enregistré." && displayProblemToast!=="done") {
                                                                displayProblemToast=true
                                                            }
                                                            console.log(index , self.state.acceptedFile.length-1)
                                                            console.log(countAF,self.state.acceptedFile.length)
                                                            if(index === self.state.acceptedFile.length-1){
                                                                if(countAF===self.state.acceptedFile.length){
                                                                    self.setState({
                                                                        processusDone:true
                                                                    },()=>{
                                                                        self.getListeAllFiles(self.state.breadcrumbsDisplay)
                                                                    })
                                                                }
                                                            }
                                                        })
                                                        self.setState({totalFileDone:countAF})
                                                        if(displayProblemToast === true){
                                                            displayProblemToast = "done"
                                                            Swal.fire({
                                                                icon: 'error',
                                                                title:"Attention !",
                                                                text: self.state.acceptedFile.length === 1 ? 'Le fichier envoyé est déjà présent dans notre blockchain':'Au moins un fichier (couleur rouge) parmi ceux envoyés est déjà présent dans notre blockchain',
                                                                target:"#dialogUploadNewFile"
                                                            }).then(()=>{
                                                                if(!!self.state.pf && self.state.processusDone){
                                                                    let link = "/Kagesecur/TableauDeBord?pf="
                                                                    self.props.history.push(link+self.state.pf.replaceAll("/","%d%") )
                                                                }
                                                            })
                                                        }
                                                        controller.close();
                                                    })
                                                }).catch((err) => {
                                                    console.log(err)
                                                    Swal.fire({
                                                        title: 'Erreur!',
                                                        text: "Une erreur est survenue.",
                                                        icon: 'error',
                                                        confirmButtonText: 'Ok',
                                                        target:"#dialogUploadNewFile"
                                                    })
                                                })
                                            }
                                        })
                                    }
                                    push();
                                }
                            });
                        }else{
                            Swal.fire({
                                title: 'Erreur!',
                                text: "Vous n'êtes pas autorisé à envoyer un fichier.",
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                            this.setState({displayDialogAddFiles: false,acceptedFile:[], uploadingFile:false,isDragging:false, totalFileDone:0,processusDone:false, canUpload:false})
                        }
                    })

                }else{
                    if(!self.state.cancelUpload){
                        setTimeout(()=>{
                            startUploadProcess()
                        },1000)
                    }
                }
            })
        })
    }

    deleteFromAcceptedFile(file){
        let acceptedFileClone = this.state.acceptedFile.slice();
        acceptedFileClone.splice(acceptedFileClone.indexOf(file),1)
        this.setState({
            acceptedFile:acceptedFileClone,
            ["cancel"+file.name]:true,
        })
    }

    getFileStats(file,hashFile){
        let path = this.state.breadcrumbsDisplay.join("/")||""
        Axios.post(url+"getFileInformationsSK",{idTokenFolder:this.state.td,path, filename:file.name, hashFile:(hashFile ? hashFile:false)},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SK_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })
            }
            console.log("response.data.allInfos",response.data.allInfos)
            if(response.data.resultat === "done"){
                if(this.state.displayDialogVerifFile){
                    this.setState({fileInformations:response.data.allInfos})
                }else{
                    this.setState({displayDialogInfo:response.data.allInfos})
                }
            }else{
                this.setState({displayDialogVerifFile:false, acceptedFile:[]})
                Swal.fire({
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }).catch((e)=>{
            console.log(e)
            this.setState({displayDialogVerifFile:false, acceptedFile:[]})
            Swal.fire({
                text: "Une erreur est survenue.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    editLockedSubfolder (userToEdit, action){
        let folder = this.state.displayDialogChangePermFolder
        Axios.post(url+"editLockedSubfolder",{idTokenFolder:this.state.td, lockedFolderName:folder.fullname, action, empreinte:userToEdit.empreinte, folderPath:folder.path},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
            if(response.data.jwt){
                sessionStorage.setItem("SK_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt
                },()=>{
                    this.updateJwt(response.data.jwt)
                })
            }
            if(response.data.resultat === "done"){
                /*Change dynamically the state*/
                if(action === "add"){
                    folder.listUserAuthorized.push(userToEdit.empreinte)
                }else{
                    folder.listUserAuthorized.splice(folder.listUserAuthorized.indexOf(userToEdit.empreinte),1)
                }
                this.setState({displayDialogChangePermFolder:folder})

            }else{
                Swal.fire({
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
        }).catch((e)=>{
            console.log(e)
            Swal.fire({
                text: "Une erreur est survenue.",
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    handleLockedFolder(justOpen,folder){
        return new Promise((resolve,reject)=>{
            if(this.state.isAdmin){
                if(!folder.listUserAuthorized){
                    folder.listUserAuthorized = []
                    /*Axios post to get list user*/
                    if(justOpen){
                        this.setState({loadingLockedFolder:true})
                    }
                    Axios.post(url+"getUserInLockedSubfolder",{idTokenFolder:this.state.td,lockedFolderName:folder.fullname},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                        if(response.data.jwt){
                            sessionStorage.setItem("SK_jwt",response.data.jwt)
                            this.setState({
                                jwt:response.data.jwt
                            },()=>{
                                this.updateJwt(response.data.jwt)
                            })
                        }
                        this.setState({loadingLockedFolder:false})
                        if(response.data.resultat === "done"){
                            folder.listUserAuthorized = response.data.empreintes

                            let self = this
                            let archiFileTemp = this.state.archiFiles.slice()
                            let path = this.state.breadcrumbsDisplay.join("/")||""
                            path += ("/"+(folder.fullname ? folder.fullname:folder.name))
                            let splittedPath = path.split("/")
                            if(splittedPath[0]===""){
                                splittedPath.splice(0,1)
                            }

                            function editArchi(archi, splittedPath, tableauIndex) {
                                if (splittedPath.length === 0) { //Si on est a la racine d'un utilisateur (seulement possible dans le cas d'un client)
                                    archi.listUserAuthorized=folder.listUserAuthorized
                                    self.setState({toDisplay: archi.sort(self.sortListFiles())})
                                } else {
                                    archi.forEach((elt, index) => {
                                        if ((elt.fullname ? elt.fullname:elt.name) === splittedPath[0]) {
                                            tableauIndex.push(index)
                                            splittedPath.shift()
                                            if (splittedPath.length !== 0) {
                                                archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                            } else {
                                                archi[index].listUserAuthorized=folder.listUserAuthorized
                                            }
                                        }
                                    })
                                }
                                return archi
                            }

                            this.setState({
                                archiFiles: editArchi(archiFileTemp, splittedPath, [])
                            })

                            if(!justOpen){
                                this.setState({displayDialogChangePermFolder: folder})
                            }

                        }else{
                            Swal.fire({
                                text: response.data.resultat,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            })
                        }
                    }).catch((e)=>{
                        console.log(e)
                        Swal.fire({
                            text: "Une erreur est survenue.",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                    })
                }
                if(!justOpen){
                    this.setState({displayDialogChangePermFolder: folder})
                }
                /*get listUserAuthorized from the previous folder if breadcrumbs.length >0*/
                // console.log(this.state.previousFolderPerms, this.state.breadcrumbsDisplay)
                if(this.state.breadcrumbsDisplay.length>0 && (this.state.previousFolderPerms !== null ? (this.state.previousFolderPerms.length < this.state.breadcrumbsDisplay.length):true)){
                    let path = this.state.breadcrumbsDisplay.join("/")
                    let splittedPath = path.split("/")
                    if(splittedPath[0]===""){
                        splittedPath.splice(0,1)
                    }
                    let self = this
                    function getContent(archi, splittedPath, content) {
                        if (splittedPath.length === 0) { //Si on est à la racine d'un utilisateur (seulement possible dans le cas d'un client)
                            content = archi[archi.findIndex(elt => elt.path === self.state.selectedFiles[0].path)]
                        } else {
                            archi.forEach((elt, index) => {
                                if ((elt.fullname ? elt.fullname:elt.name) === splittedPath[0]) {
                                    splittedPath.shift()
                                    if (splittedPath.length !== 0) {
                                        content = getContent(elt.content, splittedPath, content)
                                    } else {
                                        content = archi[index]
                                    }
                                }
                            })
                        }
                        return content
                    }
                    //ORACLE
                    if(justOpen){
                        this.setState({loadingLockedFolder:true})
                    }
                    setTimeout(()=>{
                        let newEntrypreviousFolder = getContent(this.state.archiFiles.slice(), splittedPath, [])
                        if(newEntrypreviousFolder.locked){
                            let previousFolder = this.state.previousFolderPerms
                            let newlistUserAuthorized = newEntrypreviousFolder.listUserAuthorized
                            if(newlistUserAuthorized === null){
                                newlistUserAuthorized = []
                            }
                            if(previousFolder){
                                previousFolder.push(newlistUserAuthorized)
                            }else{
                                previousFolder = [newlistUserAuthorized]
                            }
                            // console.log("previousFolder", previousFolder)
                            this.setState({previousFolderPerms:previousFolder,loadingLockedFolder:false },()=>{
                                resolve()
                            })
                        }else{
                            resolve()
                        }
                    },500)


                }else{
                    resolve()
                }
            }
        })
    }

    createCertificate(){
        if (this.state.freeClient){
            Swal.fire({
                text: "En tant que client sans abonnement, vous n'avez pas accès à cette fonctionnalité.",
                icon: 'warning',
                confirmButtonText: 'Ok'
            })
        }else {
            if (this.state.selectedFiles.length === 1) {
                if (this.state.selectedFiles[0].type === "dossier") {
                    Swal.fire({
                        text: "Vous ne pouvez pas créer un certificat pour un dossier !",
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                } else {
                    /*Ouvrir la page pour télécharger le certificats (requete en get)*/
                    const idTokenFolder = this.state.td,
                        path = this.state.breadcrumbsDisplay.join("/").replaceAll("##", "pourcentdiese").replaceAll(" ","%20").replaceAll("#","%23").replaceAll("%","%25").replaceAll("&","%26").replaceAll("/","%2F").replaceAll(":","%3A").replaceAll(";","%3B").replaceAll("=","%3D").replaceAll("?","%3F").replaceAll("@","%40").replaceAll("+","%2B"),
                        filename = this.state.selectedFiles[0].name.replace("##", "pourcentdiese")

                    window.open(url+"createCertificateSK?idTokenFolder="+idTokenFolder+"&path="+path+"&filename="+filename+"&tokenJwt="+this.state.jwt)
                }
            } else {
                Swal.fire({
                    text: "Vous ne pouvez pas créer plusieurs certificats en même temps !",
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                })
            }
        }
    }

    render(){ //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-SK flexCenter"}>
                    {(this.state.isDragging && !this.state.uploadingFile) && (
                        <Dropzone multiple={true} onDrop={(acceptedFiles) => this.processDroppedFiles(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section style={{textAlign:"center", backgroundColor:"red"}}>
                                    <div {...getRootProps()} onDragLeave={this.handleDragLeave} className="full-screen-message" style={{textAlign:"center"}}>
                                        <input {...getInputProps()} />
                                        {['top-left', 'top-right', 'bottom-left', 'bottom-right'].map(pos=>(
                                            <div style={{pointerEvents:"none"}} key={pos} className={"corner "+pos}/>
                                        ))}
                                        <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                            <p style={{fontSize:55, fontWeight:"bold",pointerEvents:"none", marginBottom:10}}>Déposez vos fichiers n'importe où sur l'écran</p>
                                            <p style={{fontSize:25, fontWeight:"bold",pointerEvents:"none"}}>(Dépôt d'un dossier dépose tous les fichiers à l'intérieur)</p>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    )}
                    <div className={"flexCenter zone-fileManager-SK"} style={{height:"calc(100vh - 110px)", marginTop:70, width:"100%", flexDirection:"column", overflow:"hidden", justifyContent:"center"}}>
                        <div className={"flexCenter divClickDeselect"} style={{height:"100%",width:"100%",flexDirection:"column"}}>
                            <div id={"menuRightClick"}>
                                {this.state.selectedFiles.length===0 ? (
                                    <>
                                        <button disabled={!this.state.canUpload} className={"options"} onClick={()=>{this.handleCloseMenu();this.setState({displayDialogAddFiles:true})}}>&nbsp;<CloudUpload style={{marginRight:2}}/>&nbsp;Enregistrer un document dans ce dossier</button>
                                    </>
                                ):(
                                    this.state.fileSelectedIsFolder ? (
                                        <>
                                            <button className={"options"} disabled={!this.state.canUpload} onClick={()=>{
                                                this.handleCloseMenu();
                                                this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles})
                                                Toast.fire({
                                                    title: "Naviguez dans le dossier de votre choix (double clic) et cliquez sur 'Déplacer ici'.",
                                                    icon: 'info',
                                                })
                                            }}>
                                                &nbsp;<DriveFolderUpload style={{marginRight:2}}/>&nbsp;Déplacer
                                            </button>
                                            <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || !this.state.canUpload} onClick={()=>{
                                                this.handleCloseMenu();
                                                this.setState({displayRenameFolder:true,fileClipboardToRename:this.state.selectedFiles[0],newFolderRename:this.state.selectedFiles[0].name})
                                            }}>
                                                &nbsp;<Edit style={{marginRight:2}}/>&nbsp;Renommer
                                            </button>
                                            {this.state.isAdmin && (
                                                <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || !this.state.isAdmin} onClick={()=>{
                                                    this.handleCloseMenu();
                                                    this.handleLockedFolder(false,this.state.selectedFiles[0])
                                                }}>
                                                    &nbsp;<PermIdentity style={{marginRight:2}}/>&nbsp;Permissions
                                                </button>
                                            )}
                                            <button className={"options"} disabled={this.state.selectedFiles.length!==1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip")} onClick={()=>{
                                                this.handleCloseMenu();
                                                this.clickDownload(true)
                                            }}>
                                                &nbsp;<GetAppIcon style={{marginRight:2}}/>&nbsp;Télécharger (.zip)
                                            </button>
                                        </>
                                    ):(
                                        <>
                                            <button className={"options"} disabled={this.state.selectedFiles.length>1} onClick={()=>{this.handleCloseMenu();this.openFileOrFolder(this.state.selectedFiles[0])}}>&nbsp;<Visibility style={{marginRight:2}}/>&nbsp;Prévisualiser</button>
                                            <button className={"options"} disabled={false} onClick={()=>{this.handleCloseMenu();this.clickDownload()}}>&nbsp;<GetAppIcon style={{marginRight:2}}/>&nbsp;Télécharger</button>
                                            <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip")} onClick={()=>{this.handleCloseMenu();this.getFileStats(this.state.selectedFiles[0],false)}}>&nbsp;<AutoGraph style={{marginRight:2}}/>&nbsp;Statistiques</button>
                                            <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip")} onClick={()=>{this.handleCloseMenu();this.createCertificate()}}>&nbsp;<Description style={{marginRight:2}}/>&nbsp;Créer un certificat</button>
                                            {/*<button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || !this.state.canUpload} onClick={()=>{this.handleCloseMenu();this.goToTraceability()}}>&nbsp;<AccountTree style={{marginRight:2}}/>&nbsp;Traçabilité</button>*/}
                                            <button className={"options"} disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || !this.state.canUpload} onClick={()=>{
                                                this.handleCloseMenu();
                                                this.setState({displayRenameFolder:true,fileClipboardToRename:this.state.selectedFiles[0],newFolderRename:this.state.selectedFiles[0].name.split("##")[1]})
                                            }}>
                                                &nbsp;<Edit style={{marginRight:2}}/>&nbsp;Renommer
                                            </button>
                                            <button className={"options"} disabled={!this.state.canUpload} onClick={()=>{
                                                this.handleCloseMenu();
                                                this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles})
                                                Toast.fire({
                                                    title: "Naviguez dans le dossier de votre choix et cliquez sur 'Déplacer ici'.",
                                                    icon: 'info',
                                                })
                                            }}>&nbsp;<DriveFolderUpload style={{marginRight:2}}/>&nbsp;Déplacer</button>
                                        </>
                                    )
                                )}
                            </div>
                            <Dialog
                                open={this.state.displayDialogInfo}
                                onClose={()=>{this.setState({displayDialogInfo: null, fileInfo:null})}} //Dans le callback pour bien être sûr de fermer le dialog avant de faire disparaitre l'image
                                fullScreen={!this.state.showBrowserView}
                                style={{height:"calc(100% - 110px)",width:"98%",marginLeft:"1%",marginRight:"1%",marginTop:100,overflow:"auto"}}
                            >
                                <div style={{position:"absolute",width:"100%", textAlign:"right"}}>
                                    <Close onClick={()=>{this.setState({displayDialogInfo: false, fileInfo:null})}} style={{margin:5,cursor:"pointer"}}/>
                                </div>
                                <DialogTitle>
                                    <h2 className={"SKFont"} style={{fontWeight:600, margin:0}}>Statistiques du document :</h2>
                                </DialogTitle>
                                <DialogContent>
                                    {this.state.displayDialogInfo && (
                                        <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                            <h3 className={"SKFont"} style={{marginTop:0}}>Document déposé par <span style={{fontWeight:600}}>{this.state.displayDialogInfo.pseudoOwner}</span>  le <span style={{fontWeight:600}}>{new Date(parseInt(this.state.displayDialogInfo.uploadDate)).toLocaleString().replaceAll(" "," à ")}</span> </h3>
                                            <div className={"flexCenter"} style={{flexDirection:"column", maxHeight:300, overflowY:"auto",width:"100%", justifyContent:"flex-start"}}>
                                                {this.state.displayDialogInfo.downloads.length ===0 ? (
                                                    <React.Fragment>
                                                        <p className={"SKFont"} style={{marginTop:10, fontWeight:"bold"}}>Aucun utilisateur n'a visionné ce fichier pour le moment</p>
                                                    </React.Fragment>
                                                ):(
                                                    <React.Fragment>
                                                        <p className={"SKFont"} style={{marginTop:10, fontWeight:"bold"}}>Les utilisateurs suivants ont visionné le fichier :</p>
                                                        {this.state.displayDialogInfo.downloads.map((dl,index)=>(
                                                            <div className={"flexCenter"} style={{flexDirection:"column",width:"80%"}}>
                                                                <div className={"flexCenter"} style={{ justifyContent:"space-between",width:"100%"}}>
                                                                    <p>{new Date(parseInt(dl.date)).toLocaleString()}</p>
                                                                    <p>{dl.pseudo}</p>
                                                                    <Tooltip title={"Ce fichier a été visionné par l'utilisateur "+dl.pseudo+"."}>
                                                                        <div className={"flexCenter"} style={{borderRadius:"50%", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", width:38,height:38, backgroundImage:"linear-gradient(45deg, rgba(75,181,67,1) 0%, rgba(150,247,140,1) 100%)"}}>
                                                                            <Visibility style={{width:20, height:20, color:"#FFF"}} />
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                                <hr style={{width:"100%", margin:2, display:(this.state.displayDialogInfo.downloads.length === 1 || index === this.state.displayDialogInfo.downloads.length-1) && "none"}}/>
                                                            </div>

                                                        ))}
                                                    </React.Fragment>
                                                )}

                                            </div>

                                        </div>
                                    )}
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                open={this.state.displayDialogChangePermFolder}
                                onClose={()=>{this.setState({displayDialogChangePermFolder: null})}}
                                fullScreen={!this.state.showBrowserView}
                                style={{height:!this.state.showBrowserView&&"calc(100% - 90px)",marginTop:!this.state.showBrowserView&&90}}
                                id={"dialogPermDossier"}
                            >
                                <div style={{position:"relative", width:"100%"}}>
                                    <Close onClick={()=>{this.setState({displayDialogChangePermFolder: null})}} style={{position:"absolute",right:0,cursor:"pointer"}} />
                                </div>

                                <DialogTitle>
                                    <div className={"flexCenter"}>
                                        <h2 style={{margin:0}} className={"SKFont"}>Permissions du dossier :</h2>
                                    </div>
                                </DialogTitle>
                                {this.state.displayDialogChangePermFolder && (
                                    <DialogContent style={{display:this.state.showBrowserView&&"flex",flexDirection:"column", justifyContent: "center", alignItems:"center"}}>

                                        <div className={"flexCenter"} style={{marginBottom:!this.state.displayDialogChangePermFolder.locked && 10}}>
                                            <h3 style={{margin:0}} className={"SKFont"}>Dossier à accès restreint :</h3>
                                            <label style={{marginLeft:5}} className="switchKS">
                                                <input checked={this.state.displayDialogChangePermFolder.locked === true} onChange={()=>{
                                                    let newLocked = !this.state.displayDialogChangePermFolder.locked
                                                    let path = this.state.breadcrumbsDisplay.join("/")||""
                                                    path += ("/"+(this.state.selectedFiles[0].fullname ? this.state.selectedFiles[0].fullname:this.state.selectedFiles[0].name))
                                                    let splittedPath = path.split("/")
                                                    if(splittedPath[0]===""){
                                                        splittedPath.splice(0,1)
                                                    }
                                                    let axiosPath = path.split("/")
                                                    axiosPath.pop()
                                                    if(axiosPath[0]===""){
                                                        axiosPath.splice(0,1)
                                                    }
                                                    if(axiosPath.length===1){
                                                        axiosPath = axiosPath[0]
                                                    }else{
                                                        axiosPath = axiosPath.join("/")
                                                    }

                                                    console.log("idTokenFolder",this.state.td,"folderPath",axiosPath ,"folderToLock",this.state.displayDialogChangePermFolder.fullname,"action",(newLocked ? "lock":"unlock"))
                                                    Axios.post(url+"lockSubfolder",{idTokenFolder:this.state.td,folderPath:axiosPath ,folderToLock:this.state.displayDialogChangePermFolder.fullname,action:newLocked ? "lock":"unlock"},{headers:{Authorization:"Bearer " + this.state.jwt}}).then((response)=>{
                                                        if(response.data.jwt){
                                                            sessionStorage.setItem("SK_jwt",response.data.jwt)
                                                            this.setState({
                                                                jwt:response.data.jwt
                                                            },()=>{
                                                                this.updateJwt(response.data.jwt)
                                                            })
                                                        }
                                                        if(response.data.resultat === "done"){
                                                            /*edit archi to lock folder*/

                                                            let self = this
                                                            let archiFileTemp = this.state.archiFiles.slice()
                                                            function editArchi(archi, splittedPath, tableauIndex) {
                                                                if (splittedPath.length === 0) { //Si on est a la racine d'un utilisateur (seulement possible dans le cas d'un client)
                                                                    archi.locked=newLocked
                                                                    archi.fullname=response.data.fullname
                                                                    self.setState({toDisplay: archi.sort(self.sortListFiles())})
                                                                } else {
                                                                    archi.forEach((elt, index) => {
                                                                        if ((elt.fullname ? elt.fullname:elt.name) === splittedPath[0]) {
                                                                            tableauIndex.push(index)
                                                                            splittedPath.shift()
                                                                            if (splittedPath.length !== 0) {
                                                                                archi[index].content = editArchi(elt.content, splittedPath, tableauIndex)
                                                                            } else {
                                                                                archi[index].locked=newLocked
                                                                                archi[index].fullname=response.data.fullname

                                                                            }
                                                                        }
                                                                    })
                                                                }
                                                                return archi
                                                            }

                                                            this.setState({
                                                                archiFiles: editArchi(archiFileTemp, splittedPath, [])
                                                            })
                                                            Toast.fire({
                                                                title: "Permissions du dossier modifiées !",
                                                                target:"#dialogPermDossier",
                                                                icon: 'success',
                                                            })
                                                        }else{
                                                            Swal.fire({
                                                                text: response.data.resultat,
                                                                icon: 'error',
                                                                target:"#dialogPermDossier",
                                                                confirmButtonText: 'Ok'
                                                            })
                                                        }
                                                    }).catch((e)=>{
                                                        console.log(e)
                                                        Swal.fire({
                                                            text: "Une erreur est survenue.",
                                                            icon: 'error',
                                                            target:"#dialogPermDossier",
                                                            confirmButtonText: 'Ok'
                                                        })
                                                    })


                                                }} type="checkbox"/>
                                                <span className="sliderKS round"/>
                                            </label>
                                        </div>
                                        {this.state.displayDialogChangePermFolder.locked && (
                                            <div style={{display:"flex",flexDirection:"column", justifyContent: "center", alignItems:"center", width:"100%"}}>
                                                <hr style={{width:"90%", color:"black"}}/>
                                                <h3 style={{margin:0, marginBottom:10, fontWeight:600}} className={"SKFont"}>Liste des utilisateurs :</h3>
                                                <TableContainer style={{maxHeight:300,overflowY:"auto"}}>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableHead>
                                                            <TableRow style={{lineHeight:0}}>
                                                                {columnsListePreuveViaMail.map((column) => (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        align={column.align}
                                                                        style={{ minWidth: column.minWidth, margin:0,lineHeight:0}}
                                                                    >
                                                                        <h3 className={"SKFont"} style={{margin:5, fontWeight:100}}>{column.label}</h3>
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.listUsersInFolder.map((row) => {
                                                                if((row.identifiant !== null && (this.state.previousFolderPerms!==null ? this.state.previousFolderPerms[this.state.previousFolderPerms.length-1].includes(row.empreinte):true))){
                                                                    return (
                                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                            {columnsListePreuveViaMail.map((column) => {
                                                                                return (
                                                                                    <TableCell key={column.id}>
                                                                                        {column.id === "Action" ? (
                                                                                            <React.Fragment>
                                                                                                <Tooltip arrow={true} title={this.state.displayDialogChangePermFolder.listUserAuthorized.includes(row.empreinte) ? "Cet utilisateur peut accéder au contenu du dossier.":"Cet utilisateur n'a pas la permission d'accéder au fichier"}>
                                                                                                    <Button style={{backgroundColor:(!row.creator && (this.state.displayDialogChangePermFolder.listUserAuthorized.includes(row.empreinte) ? "#4BB543":"#FF4136FF")), color:!row.creator && "#FFFFFF"}} disabled={row.creator} onClick={()=>{this.editLockedSubfolder ({...row, canUpload:!row.canUpload}, (this.state.displayDialogChangePermFolder.listUserAuthorized.includes(row.empreinte) ? "delete":"add"))}} name={row.identifiant}> {this.state.displayDialogChangePermFolder.listUserAuthorized.includes(row.empreinte) ? <CheckCircle/>:<DoNotDisturb/>} </Button>
                                                                                                </Tooltip>
                                                                                            </React.Fragment>

                                                                                        ):(
                                                                                            <React.Fragment>
                                                                                                {column.id === "identifiant" ? (
                                                                                                    <p className={"SKFont"} style={{margin:5, fontWeight:100,color:row[column.id] === null&&"#ff7928"}}>
                                                                                                        {`${row[column.id] === null ? "En attente" : row[column.id]}`}
                                                                                                    </p>
                                                                                                ):(
                                                                                                    <p className={"SKFont"} style={{margin:5, fontWeight:100,color:row[column.id] === null&&"#ff7928"}}>{row[column.id]}</p>
                                                                                                )}
                                                                                            </React.Fragment>
                                                                                        )}
                                                                                    </TableCell>
                                                                                );
                                                                            })}
                                                                        </TableRow>
                                                                    );
                                                                }else{
                                                                    return null
                                                                }

                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        )}
                                    </DialogContent>
                                )}
                            </Dialog>

                            <Dialog
                                open={this.state.displayDialogVerifFile}
                                onClose={()=>{this.setState({displayDialogVerifFile: false})}}
                                id={"dialogUploadNewFile"}
                                fullScreen={!this.state.showBrowserView}
                                disableBackdropClick={true}
                                style={{height:"calc(100% - 110px)",width:"98%",marginLeft:"1%",marginRight:"1%",marginTop:100,overflow:"auto"}}
                            >
                                <div style={{position:"absolute",width:"100%", textAlign:"right"}}>
                                    <Close onClick={()=>{
                                        this.setState({displayDialogVerifFile: false, fileInformations:null, acceptedFile:[]})
                                    }} style={{margin:5,cursor:"pointer", height:30, width:30}}/>
                                </div>
                                <DialogTitle>
                                    <h2 className={"SKFont"} style={{fontWeight:600, margin:0}}>Vérifier mon fichier :</h2>
                                </DialogTitle>
                                <DialogContent className={"flexCenter"} style={{flexDirection:"column"}}>
                                    <div className={"flexCenter"}>
                                        {this.state.fileInformations ? (
                                            <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                                <h3 className={"SKFont"} style={{marginTop:0}}> Le document <span style={{fontWeight:600}}>{this.state.acceptedFile[0].name}</span> est bien présent dans la blockchain. </h3>
                                                <h3 className={"SKFont"} style={{marginTop:0}}>Il a été déposé par <span style={{fontWeight:600}}>{this.state.fileInformations.pseudoOwner}</span>  le <span style={{fontWeight:600}}>{new Date(parseInt(this.state.fileInformations.uploadDate)).toLocaleString().replaceAll(" "," à ")}</span> </h3>
                                                <div className={"flexCenter"} style={{flexDirection:"column", maxHeight:300, overflowY:"auto",width:"100%", justifyContent:"flex-start"}}>
                                                    {this.state.fileInformations.downloads.length ===0 ? (
                                                        <React.Fragment>
                                                            <p className={"SKFont"} style={{marginTop:10, fontWeight:"bold"}}>Aucun utilisateur n'a visionné ce fichier pour le moment</p>
                                                        </React.Fragment>
                                                    ):(
                                                        <React.Fragment>
                                                            <p className={"SKFont"} style={{marginTop:10, fontWeight:"bold"}}>Les utilisateurs suivants ont visionné le fichier :</p>
                                                            {this.state.fileInformations.downloads.map((dl,index)=>(
                                                                <div className={"flexCenter"} style={{flexDirection:"column",width:"80%"}}>
                                                                    <div className={"flexCenter"} style={{ justifyContent:"space-between",width:"100%"}}>
                                                                        <p>{new Date(parseInt(dl.date)).toLocaleString()}</p>
                                                                        <p>{dl.pseudo}</p>
                                                                        <Tooltip title={"Ce fichier a été visionné par l'utilisateur "+dl.pseudo+"."}>
                                                                            <div className={"flexCenter"} style={{borderRadius:"50%", boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", width:38,height:38, backgroundImage:"linear-gradient(45deg, rgba(75,181,67,1) 0%, rgba(150,247,140,1) 100%)"}}>
                                                                                <Visibility style={{width:20, height:20, color:"#FFF"}} />
                                                                            </div>
                                                                        </Tooltip>
                                                                    </div>
                                                                    <hr style={{width:"100%", margin:2, display:(this.state.fileInformations.downloads.length === 1 || index === this.state.fileInformations.downloads.length-1) && "none"}}/>
                                                                </div>

                                                            ))}
                                                        </React.Fragment>
                                                    )}

                                                </div>

                                            </div>
                                        ):(
                                            <div className={"flexCenter"} style={{width:this.state.acceptedFile.length > 0 ?"30%":"100%"}}>
                                                <Dropzone disabled={this.state.uploadingFile} multiple={true} onDrop={(acceptedFiles) => this.processDroppedFiles(acceptedFiles)}>
                                                    {({getRootProps, getInputProps}) => (
                                                        <section style={{textAlign:"center"}}>
                                                            <div {...getRootProps()} id={this.state.uploadingFile ? "nohover":"hoverMoving"} style={{padding:this.state.acceptedFile.length > 0 ? 50:"100px 50px",borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold", cursor:this.state.uploadingFile ? "default":"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent", border:"2px solid black"}}>
                                                                <input {...getInputProps()} />
                                                                <>
                                                                    <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFile && "#bbbbbb", minWidth:110}}>Déposez votre fichier ici.</h3>
                                                                    <p style={{textAlign:"center", color:this.state.uploadingFile && "#bbbbbb", fontSize:12, margin:0, fontWeight:"normal", fontStyle:"italic"}}>(Taille maximum d'un fichier : 10GB)</p>
                                                                </>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </div>
                                        )}
                                    </div>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                open={this.state.displayDialogAddFiles}
                                onClose={()=>{this.setState({displayDialogAddFiles: false})}}
                                id={"dialogUploadNewFile"}
                                fullScreen={!this.state.showBrowserView}
                                disableBackdropClick={true}
                                style={{height:"calc(100% - 110px)",width:"98%",marginLeft:"1%",marginRight:"1%",marginTop:100,overflow:"auto"}}
                            >
                                <div style={{position:"absolute",width:"100%", textAlign:"right"}}>
                                    <Close onClick={()=>{
                                        this.setState({displayDialogAddFiles: false})
                                        if(this.state.processusDone){
                                            this.setState({acceptedFile:[], uploadingFile:false,isDragging:false, totalFileDone:0,processusDone:false})
                                        }
                                    }} style={{margin:5,cursor:"pointer", height:30, width:30}}/>
                                </div>
                                <DialogTitle>
                                    <h2 className={"SKFont"} style={{fontWeight:600, margin:0}}>Uploader des documents :</h2>
                                </DialogTitle>
                                <DialogContent className={"flexCenter"} style={{flexDirection:"column"}}>
                                    <div className={"flexCenter"}>
                                        <div className={"flexCenter"} style={{width:this.state.acceptedFile.length > 0 ?"30%":"100%"}}>
                                            <Dropzone disabled={this.state.uploadingFile} multiple={true} onDrop={(acceptedFiles) => this.processDroppedFiles(acceptedFiles)}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section style={{textAlign:"center"}}>
                                                        <div {...getRootProps()} id={this.state.uploadingFile ? "nohover":"hoverMoving"} style={{padding:this.state.acceptedFile.length > 0 ? 50:"100px 50px",borderRadius:5,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", color:"black",fontWeight:"bold", cursor:this.state.uploadingFile ? "default":"pointer", transition : "all 1.2s cubic-bezier(.25,.8,.25,1)", backgroundColor:"transparent", border:"2px solid black"}}>
                                                            <input {...getInputProps()} />
                                                            <>
                                                                <h3 style={{textAlign:"center", margin:0, color:this.state.uploadingFile && "#bbbbbb", minWidth:110}}>Déposez vos fichiers ici.</h3>
                                                                <p style={{textAlign:"center", color:this.state.uploadingFile && "#bbbbbb", fontSize:12, margin:0, fontWeight:"normal", fontStyle:"italic"}}>(Taille maximum d'un fichier : 10GB)</p>
                                                            </>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                        {this.state.acceptedFile.length > 0 && (
                                            <div className={"flexCenter"} style={{width:"70%", flexDirection:"column"}}>
                                                <h3 className={"SKFont"} style={{fontWeight:600}}>Liste des fichiers à ajouter :</h3>
                                                <div className={"flexCenter"} style={{width:"100%", flexDirection:"column", maxHeight:300, minHeight:200, justifyContent:"flex-start", overflow:"auto"}}>
                                                    {this.state.acceptedFile.map((file,index)=>(
                                                        <div className={"flexCenter"} style={{flexDirection:"column",padding:10,width:"90%"}}>
                                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:this.state.uploadingFile ? "center":"flex-end"}}>
                                                                <div style={{marginRight:5}}>
                                                                    {this.getIconForFileType(file.name)}
                                                                </div>
                                                                <p className={"SKFont"} style={{margin:0}}>{file.name} <span style={{marginBottom:0,fontSize:11}}>({(file.size/1000000).toFixed(2)}MB)</span></p>
                                                                {!this.state.uploadingFile && (
                                                                    <Tooltip placement="left" arrow={true} title={"Retirer ce document"}>
                                                                        <Delete onClick={()=>{this.deleteFromAcceptedFile(file)}} style={{cursor:"pointer",marginLeft:10}}/>
                                                                    </Tooltip>
                                                                )}
                                                            </div>
                                                            {this.state.uploadingFile && (
                                                                <div className={"flexCenter"} style={{width:'100%'}}>
                                                                    <div style={{display:"flex",justifyContent:"center",flexDirection:"column",width:"100%"}}>
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: this.state["fileSent" + file.name] === true ? '#9de88c' : (this.state["fileSent" + file.name] !== "inProgress" ? (this.state["fileSent" + file.name] === undefined ? '#87CEFA' : '#ec6f6f') : '#87CEFA'),
                                                                                margin: 25,
                                                                                marginTop: 5,
                                                                                marginBottom: 10,
                                                                                height: 20,
                                                                                borderRadius: 10,
                                                                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                                                overflow: 'hidden'
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: `${Math.round(parseFloat(this.state["progressUpload" + file.name])) || 0}%`,
                                                                                    height: '100%',
                                                                                    backgroundColor: this.state["fileSent" + file.name] === true ? '#2ed000' : (this.state["fileSent" + file.name] !== "inProgress" ? (this.state["fileSent" + file.name] === undefined ? '#4a5cc2' : '#ff0f0f') : '#4a5cc2'),
                                                                                }}
                                                                            />
                                                                        </div>                                                        {/*<p style={{fontSize:12,fontStyle:"italic",marginTop:0}}>Temps restant ≈ {this.state["fileSent" + file.name] === undefined ? "?":this.state["uploadEstimatedTime"+file.name]}</p>*/}
                                                                        {this.state["uploadEstimatedTime"+file.name] === undefined ? (
                                                                            <p style={{fontSize:12,fontStyle:"italic",marginTop:0, textAlign:"center"}}>L'upload va bientôt démarrer...</p>
                                                                        ):(
                                                                            <>
                                                                                {(this.state["uploadEstimatedTime"+file.name] === 0 && this.state["packetDebit"+file.name] === 0) ? (
                                                                                    <p style={{fontSize:12,fontStyle:"italic",marginTop:0, textAlign:"center"}}>Upload du fichier terminé {this.state["fileSent" + file.name] === "inProgress" && ("- Vérification en cours...")}</p>
                                                                                ):(
                                                                                    <div className={"flexCenter"}>
                                                                                        <p style={{fontSize:12,fontStyle:"italic",marginTop:0}}>Temps restant ≈ {this.state["uploadEstimatedTime"+file.name]>60 ? (parseInt(this.state["uploadEstimatedTime"+file.name]/60)+"m"):this.state["uploadEstimatedTime"+file.name]+"s"}</p>
                                                                                        <p style={{fontSize:12,fontStyle:"italic",marginTop:0, marginLeft:5}}>Vitesse d'upload ≈ {(this.state["packetDebit"+file.name]*10).toFixed(2) } MB/s</p>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <p style={{margin:25, marginTop:5, marginLeft:5,marginRight:5}} >{Math.round(parseFloat(this.state["progressUpload" + file.name])) || 0}%</p>
                                                                    {this.state["fileSent" + file.name] ?
                                                                        <>
                                                                            {this.state["fileSent" + file.name] === "inProgress" ?
                                                                                <CircularProgress variant={"indeterminate"} disableShrink={true} style={{margin:25, marginTop:5,marginLeft:5, display:"inherit"}} size={30}/>
                                                                                :
                                                                                <>
                                                                                    {this.state["fileSent" + file.name] === true ?
                                                                                        <Tooltip placement="left"  arrow={true} title={"Votre fichier à bien été enregistré dans la blockchain."}>
                                                                                            <Check style={{margin:25, marginTop:5,marginLeft:5, color:"#00E331FF"}}/>
                                                                                        </Tooltip>
                                                                                        :
                                                                                        <Tooltip placement="left" arrow={true} title={this.state["fileSent"+file.name]}>
                                                                                            <Close style={{margin:25, marginTop:5,marginLeft:5, color:"#e30000FF"}}/>
                                                                                        </Tooltip>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <CircularProgress variant={"indeterminate"} disableShrink={true} style={{margin:25, marginTop:5,marginLeft:5, display:"inherit"}} size={30}/>
                                                                    }
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={"flexCenter"}>
                                        {this.state.uploadingFile ? (
                                            <React.Fragment>
                                                {this.state.processusDone ?
                                                    <button disabled={this.state.acceptedFile.length===0} style={{marginTop:10}} onClick={()=>{this.setState({acceptedFile:[], uploadingFile:false,isDragging:false, totalFileDone:0,processusDone:false})}} className={"buttonSK"}>Ajouter de nouveaux fichiers</button>
                                                    :
                                                    <button disabled={this.state.acceptedFile.length===0 || (this.state.acceptedFile.findIndex(elt => (this.state["uploadEstimatedTime"+elt.name] === 0 && this.state["packetDebit"+elt.name] === 0 && this.state["fileSent" + elt.name] === "inProgress")) !== -1)} style={{marginTop:10}} onClick={()=>{this.setState({cancelUpload:true, uploadingFile:false,isDragging:false, totalFileDone:0})}} className={"buttonSK"}>Arrêter le processus</button>
                                                }
                                            </React.Fragment>
                                        ):(
                                            <button disabled={this.state.acceptedFile.length===0} style={{marginTop:10}} onClick={()=>{this.sendEmpreinte()}} className={"buttonSK"}>Uploader</button>
                                        )}
                                    </div>
                                </DialogContent>
                            </Dialog>

                            <div className={"flexCenter"} style={{justifyContent:"flex-start", width:"90%"}}>
                                <button disabled={this.state.refreshingData} onClick={()=>{
                                    this.setState({refreshingData: true});
                                    this.getListeAllFiles(this.state.breadcrumbsDisplay)

                                    setTimeout(()=>{
                                        this.setState({refreshingData:false})
                                    },1000)
                                }} style={{padding:5, width:"fit-content", display:"flex", marginLeft:10, backgroundColor:"transparent"}} className={"SKFont buttonSK flexCenter"}>
                                    <Refresh className={this.state.refreshingData && "animLoadingCircleGNFT"} style={{color:"#FFF"}} />
                                </button>
                                <h1 className={"SKFont"} style={{color:"#000", fontSize:40, width:"90%", margin:10}}>Espace <span style={{fontWeight:600, color:"#f19028"}}>{this.state.folderName}</span> :</h1>
                            </div>
                            <div className={"containerFileManager"} style={{height:"80%", width:"90%",backgroundColor:"white",display:"flex",flexDirection:"column",borderRadius:8,boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}>
                                <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between", alignItems:"center"}}>
                                    {this.state.showBrowserView && (
                                        <>
                                            {this.state.rechercheDisplay !== "" ? (
                                                <Breadcrumbs maxItems={4} style={{margin:0,marginLeft:15}}>
                                                    <p onClick={()=>{this.clearFilterAndGoRoot()}} style={{cursor:"pointer", fontWeight:"bold"}}>...</p>
                                                    <p style={{cursor:"pointer", fontWeight:"bold"}}>recherche:{this.state.rechercheDisplay}</p>
                                                </Breadcrumbs>
                                            ):(
                                                <Breadcrumbs maxItems={4} style={{margin:0,marginLeft:15}}>
                                                    <p onClick={()=>{this.clearFilterAndGoRoot()}} style={{cursor:"pointer", fontWeight:"bold"}}>...</p>
                                                    {this.state.breadcrumbsDisplay.map((elt,index)=>(
                                                        <div key={"breadcrumsID_"+index}>
                                                            <p onClick={()=>{this.selectBreadcrumbs(elt,this.state.archiFiles.slice(), this.state.breadcrumbsDisplay.slice())}} className={"noselect"} style={{cursor:"pointer", color:(this.state.breadcrumbsDisplay.indexOf(elt) === this.state.breadcrumbsDisplay.length-1) ? "black":"grey"}}>{elt.length>64 ? elt.slice(0,7) + "..." + elt.slice(-5):(elt.includes("##") ? elt.split("##")[1]:elt)}</p>
                                                        </div>
                                                    ))}
                                                </Breadcrumbs>
                                            )}
                                        </>
                                    )}

                                    <div className={"flexCenter"} style={{marginRight:this.state.showBrowserView && 30, width:!this.state.showBrowserView&&"100%", justifyContent:!this.state.showBrowserView&&"space-between", flexWrap:"wrap"}} >
                                        <div className={"flexCenter"} style={{width:"fit-content"}}>
                                            <Tooltip arrow={true} title={"Vérifier mon fichier"}>
                                                <Button className={"flexCenter"} disabled={!this.state.canUpload} style={{height:"56px", textTransform:"none", marginRight:5, color:!this.state.canUpload ? "#e2e2e2":"#000", borderColor:!this.state.canUpload ? "#e2e2e2":"#000"}} onClick={(e)=>{this.setState({displayDialogVerifFile:true})}} variant={"outlined"}> <p style={{fontSize:18,margin:0 , marginRight:5}} className={"SKFont"}>{this.state.showBrowserView&& "Vérifier mon fichier"}</p> <Verified/> </Button>
                                            </Tooltip>
                                            <Tooltip arrow={true} title={"Ajouter des fichiers au dossier"}>
                                                <Button className={"flexCenter"} disabled={!this.state.canUpload} style={{height:"56px", textTransform:"none", color:!this.state.canUpload ? "#e2e2e2":"#000", borderColor:!this.state.canUpload ? "#e2e2e2":"#000"}} onClick={(e)=>{this.setState({displayDialogAddFiles:true})}} variant={"outlined"}> <p style={{fontSize:18,margin:0 , marginRight:5}} className={"SKFont"}>{this.state.showBrowserView&& "Ajouter des fichiers"}</p> <CloudUpload/> </Button>
                                            </Tooltip>
                                        </div>
                                        <div className={"flexCenter"} style={{margin:5,marginRight:this.state.showBrowserView && 40}}>
                                            <form onSubmit={(e)=>{
                                                e.preventDefault()
                                                this.state.rechercheDisplay !== "" ?
                                                    this.clearFilterAndGoRoot()
                                                :this.handleClickRecherche(e)}}
                                                  className={"flexCenter"} style={{width:"100%"}}>
                                                <TextField style={{borderRadius:5, width:this.state.showBrowserView ?"20vw":"30vw"}} name={"recherche"} value={this.state.recherche} onChange={(e)=>{this.handleChange(e)}} label="Recherche" variant={"outlined"} />
                                                {this.state.rechercheDisplay !== "" ?
                                                    <>
                                                        <Button style={{height:"56px"}} type={"submit"} variant={"outlined"}> {this.state.searchFinished ? <ClearIcon/> : <CircularProgress size={20}/>}   </Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Tooltip arrow={true} title={"Attention la recherche peut prendre quelques minutes car l'application (pour des raisons de sécurité) doit déchiffrer les contenus afin de vous les afficher"}>
                                                            <Button style={{height:"56px", color:"#000", borderColor:"#000"}} type={"submit"} variant={"outlined"}> <SearchIcon/> </Button>
                                                        </Tooltip>
                                                    </>
                                                }
                                            </form>
                                        </div>
                                        <div className={"flexCenter"} style={{width:"fit-content"}}>
                                            {this.state.fileSelectedIsFolder ? (
                                                <Tooltip arrow={true} title={"Supprimer le dossier"}>
                                                    {(this.state.disableButtonMenu || this.state.selectedFiles.length >1) ? (
                                                        <Delete style={{color:"grey"}}/>
                                                    ):(
                                                        <Delete onClick={()=>{this.state.canUpload && this.deleteFolder()}} style={{margin:3,cursor:this.state.canUpload ? "pointer":"default", color:this.state.canUpload ? "black":"grey"}}/>
                                                    )}
                                                </Tooltip>
                                            ):(
                                                <Tooltip arrow={true} title={"Créer un nouveau dossier"}>
                                                    {this.state.disableButtonMenu ? (
                                                        <CreateNewFolder style={{color:"grey"}}/>
                                                    ):(
                                                        <CreateNewFolder onClick={()=>{this.state.canUpload && this.displayFileCreation()}} style={{margin:3,cursor:this.state.canUpload ? "pointer":"default", color:this.state.canUpload ? "black":"grey"}}/>
                                                    )}
                                                </Tooltip>
                                            )}
                                            {this.state.downloadFinished ? (
                                                <Tooltip disableHoverListener={this.state.selectedFiles.length>0 && this.state.selectedFiles[0].type === "sharedFolder"} arrow={true} title={"Cliquez pour afficher le menu"}>
                                                    <MenuIcon onClick={(event)=>{(!this.state.disableMenu && this.handleMenu(event))}} style={{margin:3,cursor:this.state.disableMenu? "default":"pointer", color:this.state.disableMenu? "grey":"black"}} />
                                                </Tooltip>
                                            ):(
                                                <div  className={"flexCenter"}>
                                                    <CircularProgress style={{margin:5}} size={30}/>
                                                    <p style={{position:"absolute",fontSize:10}}>{this.state.progressDownloadFile}%</p>
                                                </div>
                                            )}
                                            <Menu
                                                keepMounted
                                                open={Boolean(this.state.anchorEl)}
                                                anchorEl={this.state.anchorEl}
                                                getContentAnchorEl={null}
                                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                                                onClose={()=>{this.handleCloseMenu()}}
                                            >
                                                {/*<MenuItem style={{position:"fixed",display:"flex",justifyContent:"center",margin:0,marginTop:218,width:222,zIndex:1,height:24,backgroundColor:"white"}}> <ArrowDownward style={{width:18}}/></MenuItem>*/}

                                                {this.state.selectedFiles.length===0 ? (
                                                    <div>
                                                        <MenuItem disabled={!this.state.canUpload} onClick={()=>{this.handleCloseMenu();this.setState({displayDialogAddFiles:true})}}><CloudUpload style={{marginRight:2}}/>Enregistrer un document dans ce dossier</MenuItem>
                                                    </div>
                                                ):(
                                                    this.state.fileSelectedIsFolder ? (
                                                        <div>
                                                            <div>
                                                                <MenuItem disabled={!this.state.canUpload} onClick={()=>{
                                                                    this.handleCloseMenu();
                                                                    this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles})
                                                                    Toast.fire({
                                                                        title: "Naviguez dans le dossier de votre choix (double clic) et cliquez sur 'Déplacer ici'.",
                                                                        icon: 'info',
                                                                    })
                                                                }}>
                                                                    <DriveFolderUpload style={{marginRight:2}}/>Déplacer
                                                                </MenuItem>
                                                                <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || !this.state.canUpload} onClick={()=>{
                                                                    this.handleCloseMenu();
                                                                    this.setState({displayRenameFolder:true,fileClipboardToRename:this.state.selectedFiles[0],newFolderRename:this.state.selectedFiles[0].name})
                                                                }}>
                                                                    <Edit style={{marginRight:2}}/>Renommer
                                                                </MenuItem>
                                                                {this.state.isAdmin && (
                                                                    <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || !this.state.isAdmin} onClick={()=>{
                                                                        this.handleCloseMenu();
                                                                        this.handleLockedFolder(false,this.state.selectedFiles[0])
                                                                    }}>
                                                                        <PermIdentity style={{marginRight:2}}/>Permissions
                                                                    </MenuItem>
                                                                )}
                                                                <MenuItem disabled={this.state.selectedFiles.length!== 1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip" )} onClick={()=>{
                                                                    this.handleCloseMenu();
                                                                    this.clickDownload(true)
                                                                }}>
                                                                    <GetAppIcon style={{marginRight:2}}/>Télécharger (.zip)
                                                                </MenuItem>
                                                            </div>
                                                        </div>
                                                    ):(
                                                        <div>
                                                            <MenuItem disabled={this.state.selectedFiles.length>1} onClick={()=>{this.handleCloseMenu();this.openFileOrFolder(this.state.selectedFiles[0])}}><Visibility style={{marginRight:2}}/>Prévisualiser</MenuItem>
                                                            <MenuItem disabled={false} onClick={()=>{this.handleCloseMenu();this.clickDownload()}}><GetAppIcon style={{marginRight:2}}/>Télécharger</MenuItem>
                                                            <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip")} onClick={()=>{this.handleCloseMenu();this.getFileStats(this.state.selectedFiles[0],false)}}><AutoGraph style={{marginRight:2}}/>Statistiques</MenuItem>
                                                            <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip")} onClick={()=>{this.handleCloseMenu();this.createCertificate()}}><Description style={{marginRight:2}}/>Créer un certificat</MenuItem>
                                                            {/*<MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip") || !this.state.canUpload} onClick={()=>{this.handleCloseMenu();this.goToTraceability()}}><AccountTree style={{marginRight:2}}/>Traçabilité</MenuItem>*/}
                                                            <MenuItem disabled={this.state.selectedFiles.length>1 || (this.state.selectedFiles[0] && this.state.selectedFiles[0].status === "inZip" ) || !this.state.canUpload} onClick={()=>{
                                                                this.handleCloseMenu();
                                                                this.setState({displayRenameFolder:true,fileClipboardToRename:this.state.selectedFiles[0],newFolderRename:this.state.selectedFiles[0].name.split("##")[1]})
                                                            }}>
                                                                <Edit style={{marginRight:2}}/>Renommer
                                                            </MenuItem>
                                                            <MenuItem disabled={!this.state.canUpload} onClick={()=>{
                                                                this.handleCloseMenu();
                                                                this.setState({displayMoveFileChoice:true,fileClipboardToMove:this.state.selectedFiles})
                                                                Toast.fire({
                                                                    title: "Naviguez dans le dossier de votre choix et cliquez sur 'Déplacer ici'.",
                                                                    icon: 'info',
                                                                })
                                                            }}><DriveFolderUpload style={{marginRight:2}}/>Déplacer</MenuItem>
                                                        </div>
                                                    )
                                                )}
                                            </Menu>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{width:"99.8%",margin:0}}/>
                                {/*Système de tri*/}
                                <div style={{width:"100%", margin:0, display:"flex",justifyContent:"space-around",userSelect: "none", marginLeft:"5vw"}}>
                                    <div onClick={()=>{this.changeSortStatus("nom")}} style={{cursor:"pointer"}} className={"flexCenter"}>
                                        <p className={"sortP"} style={{margin:0, color:this.state.sortStatus.split("-")[0] === "nom" && "#000000"}}>Nom </p>
                                        {this.state.sortStatus === "nom-down" ? <ArrowDownward/>:this.state.sortStatus === "nom-up" && <ArrowUpward/>}
                                    </div>
                                    <div onClick={()=>{this.changeSortStatus("date")}} style={{cursor:"pointer"}} className={"flexCenter"}>
                                        <p className={"sortP"} style={{margin:0, color:this.state.sortStatus.split("-")[0] === "date" && "#000000"}}>Date </p>
                                        {this.state.sortStatus === "date-down" ? <ArrowDownward/>:this.state.sortStatus === "date-up" && <ArrowUpward/>}
                                    </div>
                                    <div onClick={()=>{this.changeSortStatus("poids")}} style={{cursor:"pointer"}} className={"flexCenter"}>
                                        <p className={"sortP"} style={{margin:0, color:this.state.sortStatus.split("-")[0] === "poids" && "#000000"}}>Poids</p>
                                        {this.state.sortStatus === "poids-down" ? <ArrowDownward/>:this.state.sortStatus === "poids-up" && <ArrowUpward/>}
                                    </div>
                                </div>
                                <div style={{display:this.state.breadcrumbsDisplay.length ===0 ? "none":"flex",alignItems:"center"}} onClick={()=>{this.state.breadcrumbsDisplay.length>1 ? this.selectBreadcrumbs(this.state.breadcrumbsDisplay[this.state.breadcrumbsDisplay.length-2],this.state.archiFiles.slice(), this.state.breadcrumbsDisplay.slice()):this.selectBreadcrumbs(this.state.breadcrumbsDisplay[this.state.breadcrumbsDisplay.length-2],this.state.archiFiles.slice(), this.state.breadcrumbsDisplay.slice())}}>
                                    <Tooltip arrow={true} placement="left" title={"Retour arrière"}>
                                        <div className={"flexCenter"} style={{width:"fit-content"}}>
                                            <KeyboardReturnIcon style={{marginTop:5, marginLeft:20, cursor:"pointer"}}/>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div id={"divScroll"} style={{overflowY:"auto", width:"100%", height:"100%"}}>
                                    <div style={{minHeight:this.state.virtualListHeight === 0 ? (this.state.toDisplay.length*54):(this.state.virtualListHeight), position:"relative", width:"100%"}}>
                                        {this.state.displaySkeleton && (
                                            <div style={{width:"100%"}}>
                                                <Skeleton style={{margin:10,marginTop:20}}/>
                                                <Skeleton style={{margin:10}} />
                                                <Skeleton style={{margin:10}} />
                                            </div>
                                        )}
                                        {this.state.displayCreateFolderLine && (
                                            <div style={{padding:10,display:"flex",alignItems:"center",justifyContent:"space-between" ,paddingLeft:30,border:0, backgroundColor:"transparent"}}>
                                                <div style={{cursor:"pointer", display:"flex",alignItems:"center", margin:0}}>
                                                    <FolderIcon/>
                                                    <TextField name={"newFolderName"} value={this.state.newFolderName} defaultValue={this.state.newFolderName} onChange={(e)=>{this.handleChangeFolder(e)}} style={{margin:0,marginLeft:5}} label="Nom du dossier" variant="outlined" />
                                                    <Button disabled={!this.state.newFolderName || this.state.newFolderName.length < 2} onClick={()=>{this.CreateFolder()}} style={{height:"56px", color:(!this.state.newFolderName || this.state.newFolderName.length < 2) ? "#e2e2e2":"#000", borderColor:(!this.state.newFolderName || this.state.newFolderName.length < 2) ? "#e2e2e2":"#000"}} type={"submit"} variant={"outlined"}> <Check/> </Button>
                                                    <Button onClick={()=>{this.setState({displayCreateFolderLine:false,newFolderName:""})}} style={{height:"56px", color:"#000", borderColor:"#000"}} type={"submit"} variant={"outlined"}> <Close/> </Button>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.loadingLockedFolder ? (
                                            <div style={{width:"100%"}}>
                                                <Skeleton style={{margin:10,marginTop:20}}/>
                                                <Skeleton style={{margin:10}} />
                                                <Skeleton style={{margin:10}} />
                                            </div>
                                        ):(
                                            <React.Fragment>
                                                {this.state.toDisplay.map((folder,index)=>(
                                                    <>
                                                        {this.state.firstVisibleIndex >= index-12 && this.state.firstVisibleIndex <= index+2 && (
                                                            <div key={"toDisplayItem_"+index} className={"selectableLineTDB"} onAuxClick={(e)=>{if(e.button === 2){this.selectFileOrFolder(folder,"rightClick")}}} onClick={()=>{this.selectFileOrFolder(folder)}} onDoubleClick={()=>{this.openFileOrFolder(folder)}}
                                                                 style={{border:this.state["selected"+folder.path] === true ? "solid blue 2px":"0px", backgroundColor:this.state["selected"+folder.path] === true ? "#c3e2ff":"transparent",width:this.state["selected"+folder.path] === true ? "calc(100% - 44px)":"calc(100% - 40px)",zIndex:this.state["selected"+folder.path] === true && 99,
                                                                     top: (index*54)+ (this.state.displayCreateFolderLine ? 76:0)}}> {/*Condition pour ajoute 76px pour la création de dossiers*/}
                                                                <div style={{cursor:"pointer", display:"flex",alignItems:"center", margin:0, position:"relative"}}>
                                                                    {folder.type ==="dossier" ? (
                                                                        <React.Fragment>
                                                                            {folder.locked ? (
                                                                                <FolderShared style={{margin:"5px 0 5px 0"}}/>
                                                                            ):(
                                                                                <FolderIcon style={{margin:"5px 0 5px 0"}}/>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ):(
                                                                        <>
                                                                            {this.getIconForFileType(folder.name)}
                                                                        </>
                                                                    )}
                                                                    {folder.path.includes("ciphered##") ? (
                                                                        <Tooltip arrow={true} placement="bottom" title={folder.name.split("##")[1]}>
                                                                            <p style={{margin:0,marginLeft:5}} >{folder.name.split("##")[1].slice(0, 4) + "..." + folder.name.split("##")[1].slice(-10)}</p>
                                                                        </Tooltip>
                                                                    ):(
                                                                        <>
                                                                            {this.state.displayRenameFolder && this.state.fileClipboardToRename.name === folder.name ? (
                                                                                <div style={{padding:10,display:"flex",alignItems:"center",justifyContent:"space-between" ,paddingLeft:30,border:0, backgroundColor:"transparent"}}>
                                                                                    <div style={{cursor:"pointer", display:"flex",alignItems:"center", margin:0, minWidth:250}}>
                                                                                        <TextField inputProps={{ style: { padding: '8px' } }} name={"newFolderRename"} value={this.state.newFolderRename} defaultValue={folder.name.toString()} onChange={(e)=>{this.handleChangeFolder(e)}} style={{margin:0,marginLeft:5, backgroundColor:"#FFF"}} label={`Nom du ${this.state.fileClipboardToRename && (this.state.fileClipboardToRename.type==="dossier" ? "dossier":"fichier")}`} variant="outlined" />
                                                                                        <button disabled={!this.state.newFolderRename || this.state.newFolderRename.length <2} className={"flexCenter buttonGNFT"} onClick={()=>{this.RenameFolder()}} style={{height:35, width:35, padding:0, backgroundColor:"#FFF", border:"1px solid lightgrey"}} type={"submit"}> <Check style={{width:23}}/> </button>
                                                                                        <button className={"flexCenter buttonGNFT"} onClick={()=>{this.setState({displayRenameFolder:false,fileClipboardToRename:{},newFolderRename:""})}} style={{height:35, width:35, padding:0, backgroundColor:"#FFF", border:"1px solid lightgrey"}} type={"submit"}> <Close style={{width:23}}/> </button>
                                                                                    </div>
                                                                                </div>
                                                                            ):(
                                                                                <div style={{width:"fit-content",margin:0,marginLeft:5}}>
                                                                                    <p style={{margin:0,maxHeight:38, maxWidth:!this.state.showBrowserView&&"20vw", overflow:"hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}} >{folder.name.includes("##") ? folder.name.split("##")[1]:folder.name}</p>
                                                                                </div>
                                                                                // <p style={{margin:0,marginLeft:5, maxHeight:42, position:"absolute", overflow:"hidden"}} >Ceci est un long nom de fichier qui risque de poser problème éventuellement je sais pas trop</p>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className={"flexCenter"} style={{margin:0,marginRight:20, cursor:"pointer", width:"60%", justifyContent:"space-between"}}>
                                                                    <div/>
                                                                    {(folder.type !== "dossier") && (
                                                                        <p className={"SKFont"} style={{fontSize:12,margin:0, marginRight:20}}>{new Date(parseInt(folder.name.split("##")[0].substring(0,10))*1000).toLocaleString()} </p>
                                                                    )}
                                                                    <p className={"SKFont"} style={{fontSize:12,margin:0, marginRight:20}} >{this.state.rechercheDisplay !== "" &&((folder.path.split("/").slice(4)).join("/").slice(0,40)+"...")}</p>
                                                                    <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                                        {(folder.type !== "dossier") && (
                                                                            <p className={"noselect"} style={{margin:0}} >{(folder.size/1000000).toFixed(3)+" MB"}</p>
                                                                        )}
                                                                        {folder.type ==="dossier" && (
                                                                            <p className={"flexCenter"} style={{margin:0, fontStyle:"italic"}}>{folder.content.length} élément{folder.content.length>1 && "s"}</p>
                                                                        )}
                                                                        {folder.type !== "dossier" && (
                                                                            <>
                                                                                <div
                                                                                    onClick={()=>{this.getFileStats(folder,false)}}
                                                                                    style={{cursor:"pointer"}}
                                                                                >
                                                                                    <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:17,
                                                                                        borderColor:"#336480FF", color:"#FFFFFF", marginLeft:10}}>
                                                                                        <div className={"flexCenter"} style={{width:0, height:0,borderStyle: "solid",borderWidth:15,
                                                                                            borderColor:"#52A8D9FF", color:"#FFFFFF"}}>
                                                                                            <Tooltip arrow={true} placement="bottom" title={"Afficher les informations du fichier."}>
                                                                                                <div className={"flexCenter"}>
                                                                                                    <AutoGraph style={{color:"#FFFFFF",width:18,marginTop:0}} />
                                                                                                </div>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {this.state.displayMoveFileChoice && (
                                <div style={{position:"relative",width:"100%",display:"flex", justifyContent:"flex-end", zIndex:999}}>
                                    <div style={{position:"absolute", display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"flex-end",width:"100%",height:"100%",top:-25, right:20}}>
                                        <div style={{maxHeight:200, overflow:"auto",position:"absolute", height:"fit-content", bottom:28}}>
                                            {this.state.fileClipboardToMove.map((fol,index)=>(
                                                <p style={{display:index > 100 ? "none":"flex",alignItems:"center",backgroundColor:"white",padding:5,borderRadius:5,margin:2}}> <DriveFolderUpload/> {fol.name.includes("##") ? fol.name.split("##")[1]:fol.name}</p>
                                            ))}
                                        </div>
                                        <div style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
                                            <Button disabled={!this.state.displayMoveFileChoice || this.state.movingFile} variant={"contained"} style={{width:130,backgroundColor:this.state.movingFile ? "grey":"#FF3629FF", textTransform:"none"}} onClick={()=>{this.setState({displayMoveFileChoice:false,fileClipboardToMove:[]})}}>
                                                <p className={"flexCenter"} style={{margin:3, fontSize:16, color:"#FFFFFF"}}>
                                                    Annuler
                                                </p>
                                            </Button>
                                            <Button disabled={!this.state.displayMoveFileChoice || this.state.movingFile} variant={"contained"} style={{width:130,backgroundColor:this.state.movingFile ? "grey":"#52a8d9", marginLeft:10,textTransform:"none"}} onClick={()=>{this.moveFileOrFolder()}}>
                                                <p className={"flexCenter"} style={{margin:3, fontSize:16, color:"#FFFFFF"}}>
                                                    Déplacer ici
                                                </p>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <SharedFooterSK/>
            </>
        );
    }
}
export default withRouter(EspaceDossierSK);