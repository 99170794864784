import React, {Component} from 'react';
import {withRouter} from '../../../shared/NavigationUtils';
import config from "../../../json/config.json";

import Swal from "sweetalert2";

const {baseUrl, suffix} = config;
const url = baseUrl + suffix

const ToastGNFT = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFontTitleToast',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    },
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let SwalDefault = Swal.mixin({
    background:"linear-gradient(90deg, rgba(221,222,217,1) 0%, rgb(199, 195, 181) 100%)",
    confirmButtonColor: "#3b3b3b",
    /*Modifier la classe des textes*/
    customClass: {
        title: 'ChampFont ChampFontTitle',
        content: 'ChampFont ChampFontContent',
        confirmButton: 'ChampFont ChampFontButton',
        cancelButton: 'ChampFont ChampFontButton',
        container: "ToastGNFT-container",
        icon: "SwalGNFT-icon",
    }
    // confirmButtonTextColor: "#3b3b3b",
})

const allAcceptedPath = [
    "/grape-nft/home", "/grape-nft/catalog","/grape-nft/signup",
    "/grape-nft/admin/addbottles","/grape-nft/myaccount","/grape-nft/paymentstatus",
    "/grape-nft/legalnotices"
]

class SharedHeaderGNFT extends Component {

    constructor(props) {
        super(props);
        this.state={
            showBrowserView:true,
        };
    }

    _onRouteChangedGNFT; _emitInscrisGNFT;
    _askConnectionGNFT;
    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizePage)
        this.resizePage()
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    goToPage(clickedPage){
        this.setState({displayMenuMobile:false, displayConnexion:false},()=>{
            window.location.pathname !== clickedPage ? this.props.navigate(clickedPage):window.location.reload()
        })
    }

    render() {
        return(
            <>
                <div className={"footer-frontpage-GNFT"}>
                    <div style={{display:"flex", justifyContent:this.state.showBrowserView ? "flex-end":"center", alignItems:"center", width:"100%"}}>
                        <div style={{width:this.state.showBrowserView ? "fit-content":"100%", justifyContent:"space-around"}} className={"flexCenter"}>
                            <h3 onClick={()=>{this.goToPage("/Grape-NFT/Contact")}} style={{color:"#FFF", cursor:"pointer",marginRight:20}} className={"ChampFont"}>Contact</h3>
                            <h3 onClick={()=>{this.goToPage("/Grape-NFT/LegalNotices")}} style={{color:"#FFF", cursor:"pointer",marginRight:10}} className={"ChampFont"}>Legal Notices</h3>
                        </div>
                        {/*<img onClick={()=>{this.props.navigate("/Grape-NFT/Home")}} style={{width:30, height:30, marginLeft:20, cursor:"pointer"}} src={require("../assets/grapelogowinenft.png")} alt="grapelogowinenft"/>*/}
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(SharedHeaderGNFT)